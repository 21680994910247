<template>
<div class="newPlanPart">
  <div class="clearfix">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item label="推广目的" prop="name">
        <el-radio-group v-model="ruleForm.name">
          <el-radio border  v-for="(item,index) in tabsListNewPlan"
          :key="index"
          :label="item.name"
          :name="item.name"
          @change="changeName">{{item.label}}
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="推广计划预算"  class="clearfix">
        <el-form-item :rules = 'numberlimitM' >
          <el-checkbox-group v-model="ruleForm.budget" class="fL">
            <el-checkbox label="无" name="budget"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :rules = 'numberlimitM' label="日预算" class="fL" :prop="ruleForm.budget1">
          <el-input v-model="ruleForm.budget1" placeholder="请输入日预算" :disabled="ruleForm.budget||false"></el-input>
        </el-form-item>
      </el-form-item>
      
      <el-form-item label="推广计划名称" prop="planName">
        <el-input v-model="ruleForm.planName"></el-input>
      </el-form-item>
      <el-form-item class="btnPart">
        <el-button type="primary" size="small" @click="saveForm(creatNewPart,'ruleForm')">保存并新建</el-button>
        <el-button type="primary" size="small" @click="submitForm('ruleForm')">完成</el-button>
        <el-button type="info" plain size="small" @click="goPlanPage">取消</el-button>
      </el-form-item>
    </el-form>
    <!-- <adTeamHeaderPart :newPubVue="newPubVue" v-if="teamShowIs"></adTeamHeaderPart>
    <adCreativity :newPubVue="newPubVue" v-if="creatShowIs"></adCreativity> -->
  </div>
</div>
  
</template>
<script>
// import adTeamHeaderPart from "./adTeampart/adTeamHeaderPart";//应用推广组件
// import adCreativity from "./adcreativity";//广告创意
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
export default {
  data() {
    return {
      newPubVue: this,//空vue，用于子组件之间传值
      creatNewPart:false,//是否加载广告组
      activeName:'applications',
      // tabsListNewPlan:''
      tabsListNewPlan:[{label:"应用推广",name:"2"},//1 :网页推广 2:应用推广 3:快应用推广
        {label:"网页推广",name:"1"}],//tabs动态加载的组件内容
        ruleForm: {
          budget: '',//推广计划预算
          budget1:'',
          planName: '',//推广计划名称
          name:'2'//推广目的
        },
      rules: {
        budget1: [
          // { required: true, message: '请输入日预算', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        planName: [
          { required: true, message: '请输入推广计划名称', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请选择', trigger: 'change' }
        ]
      },
      numberlimitM: { validator: this.validateLastCompanyUnit, trigger: 'change' },

      ownerId:null,
      nameList:['网页推广','应用推广'],
      creatTime:'',
      creatShowIs:false,//创意是否显示
      teamInfo:{
        planId:null,//计划ID
        extensionType:null,//推广目的ID
        extensionTypeName:'',//推广目的名称
      },//接收计划id跟推广目的id和名称
    }
  },
  props:{
    newPlanVue:Object
  },
  // components:{
  //   adTeamHeaderPart,//广告组
  //   adCreativity//广告创意
  // },
  mounted(){
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.ownerId = Number(this.$route.query.ownerId)
      this.ownerName = this.$route.query.ownerName
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.creatTime = this.dateFormat()
      this.ruleForm.planName = '应用推广'+this.creatTime
      console.log(this.newPlanVue)
  },
  methods: {
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },
    goPlanPage(){
      this.$router.push({
        path: '/plan-management/advertisingPlan',
        query: {
          id: this.ownerId,
          name:this.ownerName,
        }
      })
    },
    validateLastCompanyUnit(rule, value, callback){
      let thisField = rule.field.split(".")[1];
        if(this.ruleForm.ruleForm[thisField].budget != true && value==""){
          callback(new Error("日预算不能为空"));
      }
      callback();
    },
    dateFormat(){
      const dt = new Date(new Date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },
    changeName(){
      console.log(this.ruleForm.label)
      let num = Number(this.ruleForm.name)-1
      this.ruleForm.planName = this.nameList[num]+this.creatTime
    },
    handleClick(tab, event) {
      // this.$store.commit('handleClick', tab.name)//
    },
    saveForm(creatNewPart,formName){
      this.creatNewPart=true;
      this.submitForm(formName)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.saveAdPlanFn()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // "channel": "",
    // "dayBudget": 0,
    // "dayLimit": 0,0:不限预算 1:限制预算
    // "extensionType": 0,
    // "ownerId": 0,
    // "planName": "",
    // "userId": 0,
    // "userName": ""
    saveAdPlanFn(){
      let _this = this,params = {}
      params = {
        'channel':3,
        'dayBudget':_this.ruleForm.budget? 0:Number(_this.ruleForm.budget1)*100,
        'dayLimit':_this.ruleForm.budget?0:1,
        'extensionType':Number(_this.ruleForm.name),
        'ownerId':_this.ownerId,//1000092281->小鹿中医账号不可以用别的账号
        'planName':_this.ruleForm.planName,
        'userId':_this.userId,
        'userName':_this.userName,
      }
      _this.teamInfo.extensionType = Number(_this.ruleForm.name)
      _this.teamInfo.planName = _this.ruleForm.planName
      _this.teamInfo.extensionTypeName = _this.nameList[_this.teamInfo.extensionType-1]
      $http.axios.post("/api/advertisement/adPlan/v1/saveAdPlan",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          if(res.data.content != null){
            // planInfo.planName = res.data.content.planId
            _this.teamInfo.planId = res.data.content.planId
            _this.newPlanVue.activeName = 'hadPlan'
            _this.newPlanVue.teamInfo = _this.teamInfo
            _this.newPlanVue.teamShowIs = true
            _this.newPlanVue.$refs.child[0].getPlanListFn(_this.ownerId)

            
          }
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .newPlanPart label{
    line-height:2px;
    font-size: 14px;
      i{
        color:red;
    }
  }
  .el-form-item{
    display: block;
  }
  .el-tabs__header.is-top{
    margin: 0;
  }
  .btnPart{
    margin-top: 15px;
  }
   .el-form-item--mini .el-form-item__content{
    /* // 需要跟这个宽度一致label-width="90px" */
    // float: left;//对dayLimitDialog页面有影响
    margin-left: 0!important;
  }
  .el-radio--mini.is-bordered .el-radio__inner{
    display: none;
  }
  .newPlanPart .el-radio__label{
    padding:0!important;
  }
  
</style>