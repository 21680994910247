<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
              <el-breadcrumb-item>关键词</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" style="float:right;" @click="addNegKeyword">添加否定关键词</el-button>
          </div>
        </div>
        <div class="chanelTab">
          <span class="channel_oppo" @click="clickChangeChannel(1)">广告系列</span>
          <span class="channel_vivo" @click="clickChangeChannel(2)">广告组</span>
          <span class="channel_vivo channelActive">关键词</span> 
          <span class="channel_vivo" @click="clickChangeChannel(4)">广告</span>
        </div>
        <div class="chanelTabSecondary">
          <span @click="keyWords(1)">关键词</span>
          <span class="channeSelActive">否定关键词</span>
          <span @click="keyWords(3)">搜索词</span>
        </div>

          <div class="materialHead">
            <div class="materialHBtns clearfix queryLeft">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <el-input v-model = "kwNegSearch" style="width:240px;" type="number" clearable placeholder="请输入否定关键词ID(精确搜索)"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" class="newQuery" size="small" @click = "getNegkwList(1)">查询</el-button>
                  <el-button type="info" size="small" plian @click="resetForm">重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
            <div class="listDetail queryRight">
              <span>
                计划系列名称：
                <el-select 
                  :placeholder="campaignName"
                  @change="changeAdplanLabel"
                  value-key="id"
                  v-model="adplanLabel">
                  <el-option
                    v-for="(item,i) in adplanModelOption"
                    :key="i"
                    :label="item.name"
                    :value="item">
                    <span style="float: left">{{ item.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{ item.id }}</span>
                  </el-option>
                </el-select>
              </span>
              <span>
                <span v-if="changeSupplySources == ''">
                  <i v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else>投放位置：搜索标签</i>
                </span>
                <span v-else>
                  <i v-if="changeSupplySources == 'APPSTORE_SEARCH_RESULTS'">投放位置：搜索结果</i>
                  <i v-else>投放位置：搜索标签</i>
                </span>
              </span>
            </div>
          </div>
          <div class="clearfix">
            <!-- 广告系列添加否定关键词弹框 -->
            <el-dialog 
              title="添加否定关键词" 
              width="820px"
              class="kwWrap"
              center
              :visible.sync="dialogFormVisible">
              <div class="kwIcon"><img :src="kwIcon"></div>


              <!-- 头部联动 -->
              <div class="linkage">
                <div class="linkage-left">
                  <el-radio v-model="adTeamRadio" label="1" @change="adTeamRadioBox1">添加至当前广告系列</el-radio>
                  <el-radio v-model="adTeamRadio" label="2" @change="adTeamRadioBox2">添加至广告组</el-radio>
                </div>
                
                <!-- <div class="adTeamRadio adTeamRadio1" v-if="adTeamRadio1">
                  <div class="linkageType">
                  </div>
                </div> -->
                <div class="adTeamRadio2 linkage-right" v-if="adTeamRadio2">
                  <div class="linkageType">
                    <label>广告组</label>
                    <el-select v-model="adTeamModel" placeholder="请选择广告组">
                      <el-option
                        v-for="(item,i) in adTeamModelOption"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

              <div class="negKeywordWrap">
                <div class="addKwTeam">
                  <span class="addNegKeywords">已添加（<i>{{negKeywordNum}}</i>）</span>
                </div>
                <div class="batchBtns addKwTeamBtn">
                  <el-button type="primary" size="mini" @click="batchMatchType">批量修改匹配类型</el-button>
                  <el-button type="primary" size="mini" @click="batchDel">批量删除</el-button>
                </div>
                <div class="enterKeywords addKwInput">
                  <el-input v-model="enterKeywords" class="enterKeywordsInput" placeholder="添加否定关键词，多词用逗号分隔"></el-input>
                  <el-button type="primary" class="enterKeywordsBtn newDialog" size="mini" @click="addNegKw">添加</el-button>
                </div>
                <!-- 批量修改匹配类型 -->
                <el-dialog 
                  title="批量修改匹配类型" 
                  width="330px"
                  center
                  class="smallDialog"
                  append-to-body
                  :visible.sync="dialogEditMatchType">
                  <div class="editTypeChoose">
                    <el-select v-model="batchMateType" placeholder="请选择匹配类型">
                      <el-option
                        v-for="(item,i) in mateTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="editTypeBtns">
                    <el-button class="newDialogCancel" type="primary" @click="editMatchTypeFalse">取消保存</el-button>
                    <el-button class="newDialog" type="primary" @click="editMatchType">确定保存</el-button>
                  </div>
                </el-dialog>
                <el-table
                  ref="multipleTable"
                  :data="negkwTableData"
                  class="inner"
                  @selection-change="handleSelectionChange"
                  tooltip-effect="dark">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="text"
                    label="否定关键词">
                  </el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="匹配类型"
                    width="150">
                    <template slot-scope="scope" >
                      <el-select v-model="scope.row.matchType">
                        <el-option
                          v-for="(item,i) in mateTypeOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    center
                    width="100">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini"><i class="el-icon-delete"></i>删除</el-button>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="negKeywordsLast">
                  已过滤重复否定关键词 <i>{{negKeywordsLast01}}</i> 个
                  过滤特殊字符否定关键词 <i>{{negKeywordsLast02}}</i> 个
                </div> -->
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button class="newDialogCancel" type="primary" size="middle" @click="dialogFormVisible = false" style="margin-right:20px;">取消保存</el-button>
                <el-button class="newDialog" type="primary" size="middle" @click="negKeyWordSure">确定保存</el-button>
              </div>
            </el-dialog>


            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="kwName"
                  width="220"
                  label="否定关键词"
                  fixed>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="状态"
                  fixed>
                  <template slot-scope="scope" >
                    <el-switch
                      v-model="scope.row.status"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      active-value="ACTIVE"
                      inactive-value="PAUSED"
                      @change="changeStatus(scope.row.status,scope)">
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="类型"
                  fixed>
                  <template slot-scope="scope">
                    <span v-if="adGroupId != ''">广告组</span>
                    <span v-else>广告系列</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop=""
                  label="匹配类型"
                  fixed>
                  <template slot-scope="scope">
                    <span v-if="scope.row.matchType == 'EXACT'">完全匹配</span>
                    <span v-else>广泛匹配</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="kwId"
                  width="120"
                  label="否定关键词ID">
                </el-table-column>
                <el-table-column
                  prop=""
                  width="120"
                  label="广告系列ID">
                  <template slot-scope="scope">{{campaignId}}</template>
                </el-table-column>
                <el-table-column
                  prop=""
                  width="220"
                  label="广告系列名称">
                  <template slot-scope="scope">{{campaignName}}</template>
                </el-table-column>
                <el-table-column
                  prop=""
                  width="220"
                  label="广告组名称">
                  <template slot-scope="scope">
                    <span v-if="adGroupName != null">
                      {{adGroupName}}
                    </span>
                    <span v-else>-</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="adGroupId"
                  label="广告组ID"
                  width="120">
                  <template slot-scope="scope">
                    <span v-if="scope.row.adGroupId == null">-</span>
                    <span v-else>
                      {{scope.row.adGroupId}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='100'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deleteNegKwFn(scope.row)">
                      <template #reference>
                        <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>
          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <!-- <zdyApple :visible.sync="showzdyDialog" ref = 'zdyApple' :pubVue="pubVue"></zdyApple> -->
  </div>
</template>

<script>
import kwIcon from '@/assets/img/kw.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
// import zdyApple from './zdyApple'

export default {
  // components: {
  //   zdyApple
  // },
  data() {
    return {
      kwIcon,
      pubVue: this,//空vue，用于子组件之间传值
      showzdyDialog:false,
      showDialog: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showSingleDialog:false,
      nowIs : false,
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      nameId:'',//请输入计划ID查找
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      tableData:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:'',
      planType:30,
      dialogTitle:'批量调价',
      userContent:{},//登录用户信息&弹框默认
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',

      //苹果渠道广告组
      formLabelWidth:'120px',
      adSeries:'',
      adSeriesOption:[{
        label:'广告系列1',
        value:1
      },{
        label:'广告系列2',
        value:2
      }],
      adGroupApple:'',
      adGroupAppleOption:[{
        label:'广告组1',
        value:1
      },{
        label:'广告组2',
        value:2
      }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateValue:'',//搜索时间
      adplanStatu:'',//搜索状态
      adplanStatuOption:[{//状态选项
        value:'BROAD',
        label:'广泛匹配'
      },{
        value:'EXACT',
        label:'完全匹配'
      }],
      adplanUseStatu:'',//搜索启用状态
      adplanUseStatuOption:[{//启用状态选项
        value:'false',
        label:'已启用'
      },{
        value:'true',
        label:'已移除'
      }],
      budgetRuleForm:{
        condition:{
          min:'',
          max:''
        }
      },
      budgetRules:{

      },
      radio: '1',
      priceRadio:'3',
      pricetof:true,
      pricetof01:true,
      pricetof02:true,
      topAndDown:'',
      topAndDownOption:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum:'',
      topAndDown02:'',
      topAndDownOption02:[{
        value:1,
        label:'上调'
      },{
        value:2,
        label:'下调'
      }],
      topAndDownNum02:'',
      materialIdsPara:[],//选中的否定关键词
      dialogFormVisible:false,//否定关键词弹框
      negKeywordNum:0,//添加否定关键词数量
      enterKeywords:'',//添加否定关键词-输入框
      negkwTableData:[],//添加否定关键词-表格
      // {
      //   text:'',
      //   matchType:''
      // }
      mateType:'',//匹配类型
      batchMateType:'',//批量匹配类型
      mateTypeOption:[{
        value:'BROAD',
        label:'广泛匹配'
      },{
        value:'EXACT',
        label:'完全匹配'
      }],
      negKeywordsTable:'',
      negKeywordsLast01:0,
      negKeywordsLast02:0,
      materialIdsPara:[],//批量选择
      dialogEditMatchType:false,//批量修改匹配类型
      batchMateTypeAll:[],//选择否定关键词集合
      negKw:[],//广告系列选中的否定关键词
      adTeamRadio:'1',
      adTeamRadio1:true,//添加至广告系列
      adTeamRadio2:false,//添加至广告组
      adplanModel:'',//广告系列的联动
      adplanModelOption:[{
        value:1,
        label:'全部广告系列'
      },{
        value:2,
        label:'钢构宝'
      }],
      adTeamModel:'',//广告组的联动
      adTeamModelOption:[],
      adplanModelOnly:'',
      listParams:{},//关键词列表的入参
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      kwNegSearch:'',//搜索否定关键词
      ownerId:'',
      ownerName:'',
      campaignId:'',
      campaignName:'',
      adGroupId:'',
      adGroupName:'',
      supplySources:'',
      appId:'',
      budget:'',
      adplanLabel:'',//搜索广告系列下的关键词
      loadingDialogVisible:true,//正在载入
      adplanLabelId:'',
      changeSupplySources:'',//change计划名称改变投放位置


    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)

    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    this.campaignId = this.$route.query.campaignId
    this.campaignName = this.$route.query.campaignName
    this.adGroupId = this.$route.query.adGroupId
    this.adGroupName = this.$route.query.adGroupName
    this.supplySources = this.$route.query.supplySources
    this.appId = this.$route.query.appId
    this.budget = this.$route.query.budget

    let nowTimes = Date.now();
    let oldTimes = nowTimes - (86400000 * 7);
    let oldDate = this.getDate(oldTimes); //一个月前的时间
    let nowDate = this.getDate(nowTimes);//今天的时间
    this.dateValue = [oldDate, nowDate];

    this.adplanLabel = this.campaignName
    this.getAdplanList()
    this.getNegkwList()
  },
  methods: {
    //tab切换
    clickChangeChannel(val){
      if(val == 1){
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName }})
      }else if(val == 2){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/adTeamApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,position:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
        
      }else if(val == 4){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.supplySources,appId:this.appId,
          budget:this.budget}})
        }else{
          this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.changeSupplySources,appId:this.appId,
          budget:this.budget}})
        }
        
      }
    },

    //关键词、否定关键词、搜索词的切换
    keyWords(val){
      if(val == 1){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget
          }})
        }else{
          this.$router.push({path:'/plan-management/adKeywordApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget
          }})
        }
       
      }else if(val == 3){
        if(this.changeSupplySources == ''){
          this.$router.push({path:'/plan-management/adKeywordSelectApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.supplySources,appId:this.appId,budget:this.budget
          }})
        }else{
          this.$router.push({path:'/plan-management/adKeywordSelectApple', query: { id: this.ownerId, name:this.ownerName ,
            campaignId:this.campaignId,campaignName:this.campaignName,
            adGroupId:this.adGroupId,adGroupName:this.adGroupName,
            supplySources:this.changeSupplySources,appId:this.appId,budget:this.budget
          }})
        }
        
      }
    },

    //格式化时间 返回年月日 
    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
        let h = val.getHours() < 10 ? '0' + val.getHours() : val.getHours();
        let m = val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes();
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //change广告计划切换不同的否定关键词
    changeAdplanLabel(item){
      this.campaignId = item.id
      console.log(this.campaignId)
      this.loadingDialogVisible = true
      let _this = this
      item.supplySources.forEach(function(val,i){
        _this.changeSupplySources = val
      })
      console.log(_this.changeSupplySources)
      _this.listParams = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        'adPlanId':item.id,
        'pageIndex':_this.page,
        'pageSize':_this.pageCount,
        'orderBy':'id',
        'asc':'DESCENDING',
        'query':_this.kwNegSearch
      }
      if(_this.adGroupId != undefined){
        _this.listParams.adGroupId = _this.adGroupId
      }
      $http.axios.post("/api/apple/kw/negative/list",_this.listParams).then(res=> {
        if(res.data.code==200){
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
          _this.loadingDialogVisible = false
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })    
        }
      })
    },

    //否定关键词列表
    getNegkwList(page){
      this.loadingDialogVisible = true
      let _this = this
      _this.listParams = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'ownerId':_this.ownerId,
        'adPlanId':_this.campaignId,
        // 'pageIndex':_this.page,
        'pageSize':_this.pageCount,
        'orderBy':'id',
        'asc':'DESCENDING',
        'query':_this.kwNegSearch
      }
      if(page == 1){
        _this.listParams.pageIndex = 1
      }else{
        _this.listParams.pageIndex = _this.page
      }
      if(_this.adGroupId != undefined){
        _this.listParams.adGroupId = _this.adGroupId
      }
      $http.axios.post("/api/apple/kw/negative/list",_this.listParams).then(res=> {
        if(res.data.code==200){
          console.log(this.campaignId)
          _this.tableData = res.data.data.list
          _this.totalRow = res.data.data.pageInfo.rowCount
          _this.loadingDialogVisible = false
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })    
        }
      })
    },

    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.getNegkwList()
    },

    //重置
    resetForm(){
      this.$router.go(0)
    },

    //预警预算
    changePriceStatus(value){
      if(value== 2){
        this.pricetof = false
        this.pricetof01 = false
        this.pricetof02 = true
      }else{
        this.pricetof = true
        this.pricetof01 = true
        this.pricetof02 = true
      }
    },

    changetod(val){
      if(val == 3){
        this.pricetof01 = false
        this.pricetof02 = true
      }else{
        this.pricetof01 = true
        this.pricetof02 = false
      }
    },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e.id)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选否定关键词',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },

    //否定关键词的弹框部分
    //添加否定关键词-弹框显示
    addNegKeyword(){
      this.dialogFormVisible = true
      console.log(this.campaignId)
      let _this = this
      console.log(_this.adplanLabel)
      if(_this.adplanLabel== _this.campaignName){
        _this.adplanLabelId = _this.campaignId
      }else{
        _this.adplanModelOption.forEach(function(val,i){
          if(val.id == _this.adplanLabel){
            _this.adplanLabelId = val.id
          }
        })
      }
      console.log(_this.adplanLabelId)
      _this.getAdgroupList()//获取广告组下拉列表
    },

    //添加否定关键词弹框中的添加btn
    addNegKw(){
      let enterKeywords = [],_this = this
      enterKeywords = _this.enterKeywords.split(',')
      enterKeywords.forEach(function(obj,index){
        _this.negkwTableData.push({'text':obj,'matchType':'BROAD'})
      })
      //去重
      let obj={}
      this.negkwTableData=this.negkwTableData.reduce((item,next)=>{
        obj[next.text]?'':obj[next.text]=true&&item.push(next)
        return item
      },[])

      _this.enterKeywords = ''
      _this.negKeywordNum =  _this.negkwTableData.length
    },

    //添加否定关键词弹框中的单个删除
    deletePlanFn(row){
      let _this = this
      _this.negkwTableData.forEach(function(obj,i){
        if(obj.text == row.text){
          _this.negkwTableData.splice(i,1)
        }
        // _this.addNegKw()
      })
      _this.negKeywordNum =  _this.negkwTableData.length
    },

    //添加否定关键词弹框中的选择表格
    handleSelectionChange(val){
      this.batchMateTypeAll = val
    },

    //添加否定关键词弹框中的批量修改匹配类型
    batchMatchType(){
      this.chooseTableFn('dialogEditMatchType')
    },

    //添加否定关键词弹框中的批量修改匹配类型-确定
    editMatchType(){
      let _this = this
      _this.batchMateTypeAll.forEach(function(val,i){
        val.matchType = _this.batchMateType
      })
      _this.dialogEditMatchType = false
      _this.batchMateType = ''
      _this.$refs.multipleTable.clearSelection()
    },

    //添加否定关键词弹框中的批量修改匹配类型-取消
    editMatchTypeFalse(){
      this.batchMateType = ''
      this.dialogEditMatchType = false
      this.$refs.multipleTable.clearSelection()
    },

    //批量删除
    batchDel(){
      if(this.$refs.multipleTable.selection.length == 0){
        this.$message({
          message: '请进行勾选',
          type: 'warning'
        });
      }else{
        let _this = this,
        selection = this.$refs.multipleTable.selection,
        data = []
        _this.negkwTableData.map((el, index) => {
          if (!selection.find(item => item.text == el.text)) {
              data.push(_this.negkwTableData[index]);
          }
        })
        _this.negkwTableData = data
        _this.negKeywordNum =  _this.negkwTableData.length
      }
    },

    //否定关键词的头部联动
    adTeamRadioBox1(){
      this.adplanModelOnly = ''
      if(this.adTeamRadio == 1){
        this.adTeamRadio1 = true
        this.adTeamRadio2 = false
      }else{
        this.adTeamRadio1 = false
        this.adTeamRadio2 = true
      }
    },

    adTeamRadioBox2(){
      // this.adplanModel = 1
      // this.adTeamModel = 1
      if(this.adTeamRadio == 2){
        this.adTeamRadio2 = true
        this.adTeamRadio1 = false
      }else{
        this.adTeamRadio2 = false
        this.adTeamRadio1 = true
      }
    },

    //change状态
    changeStatus(event,scope){
      console.log(scope)
      let _this = this,status = ''
      if(event == 'PAUSED'){
        status = 2
      }else{
        status = 1
      }
      $http.axios.get("/api/apple/kw/negative/update/batch?adPlanId="+_this.campaignId+"&kwIds="+scope.row.kwId+"&ownerId="+_this.ownerId+"&status="+status+"&adGroupId="+scope.row.adGroupId+"&userName="+_this.userName).then(function(response) {
        if(response.data.code == 200){
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 删除
    deleteNegKwFn(row){
      let _this = this
      $http.axios.get("/api/apple/kw/negative/del/batch?adGroupId="+row.adGroupId+"&adPlanId="+_this.campaignId+"&kwIds="+row.kwId+"&ownerId="+_this.ownerId+"&userName="+_this.userName).then(res=> {
        if(res.data.code==200){
          _this.getNegkwList()
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //获取广告组数据
    getAdgroupList(){
      let _this = this
      console.log(_this.adplanLabelId)
      $http.axios.get("/api/apple/adGroup/getAllGroup/"+_this.ownerId+"/"+_this.adplanLabelId).then(res=> {
        if(res.data.code==200){
          _this.adTeamModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //否定关键词-确定
    negKeyWordSure(){
      let _this = this,params = {},negativekeywordsDtoList = [],groupId=[],campaignId= []
      _this.negkwTableData.forEach(function(val,index){
        negativekeywordsDtoList.push({
          'matchType':val.matchType,
          'text':val.text
        })
      })
      if(_this.adTeamRadio == '2'){
        groupId.push(_this.adTeamModel)
      }else{
        groupId = []
      }
      // campaignId.push(_this.campaignId)
      campaignId.push(_this.adplanLabelId)
      
      params = {
        'deptId':_this.deptId,
        'userId':_this.userId,
        'userName':_this.userName,
        'campaignIdList':campaignId,
        'negativekeywordsDtoList':negativekeywordsDtoList,
        'orgId':_this.ownerId,
        'type':Number(_this.adTeamRadio),//类型：1 添加到广告系列 2：添加到广告组
        'adgroupIdList':groupId
      }
      $http.axios.post("/api/apple/adGroup/saveNegativekeywords",params).then(res=> {
        if(res.data.code==200){
          _this.getNegkwList()
          _this.dialogFormVisible = false
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },

    //获取计划系列数据
    getAdplanList(){
      let _this = this
      $http.axios.get("/api/apple/adPlan/getCampaignList/"+_this.ownerId).then(res=> {
        if(res.data.code==200){
          _this.adplanModelOption = res.data.data
        }else{
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })  
        }
      })
    },







  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .priceStyle{
    display: block;
    margin:10px 0;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:10px;
    width:100%;
    border-bottom:1px solid #ddd;
    font-size:14px;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#66856d;
    border-bottom:2px solid #66856d;
  }
  .negKeywords{
    width:400px;
    height:30px;
  }
  .addNegKeywords i {
    font-style: normal;
  }
  .enterKeywords{
    width:100%;
    margin:10px 0 20px;
  }
  .enterKeywords .enterKeywordsInput,.enterKeywords .enterKeywordsBtn{
    display: inline-block;
  }
  .enterKeywords .enterKeywordsInput{
    width:58%;
  }
  .enterKeywords .enterKeywordsBtn{
    width:10%;
    height:30px;
    border-radius:30px;
    margin-left:10px!important;
  }
  .negKeywordsLast{
    margin-top:15px;
    font-size:12px;
  }
  .negKeywordsLast i{
    font-style: normal;
  }
  .editTypeChoose{
    text-align:center;
  }
  .editTypeBtns{
    margin-top:20px;
    padding-bottom:20px;
    text-align:center;
  }
  .adTeamRadio{
    margin-top:15px;
  }
  .linkage{
    margin-bottom:20px;
  } 
  .linkageType{
    display: inline-block;
    vertical-align:middle;
    margin-right:30px;
  }
  .linkageType label{
    padding-right:15px;
    font-size:12px;
  }
  /**
  * 解决el-input设置类型为number时，中文输入法光标上移问题
  **/
  .el-input__inner{
    line-height: 1px !important;
  } 
  /**
  * 解决el-input设置类型为number时，去掉输入框后面上下箭头
  **/
  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {  
    -webkit-appearance: none;  
    margin: 0;  
  }
  .listDetail span{
    display: inline-block;
    vertical-align:middle;
    font-size:12px;
    color:#666;
    margin-right:20px;
  }
  .listDetail span i{
    font-style: normal;
  }
  .loading{
    text-align:center;
  }
  .loading span,.loading i{
    display: block;
  }
  .loading span{
    font-size:20px;
    font-weight:700;
    color:#fff;
  }
  .loading i{
    margin-top:10px;
    font-size:60px;
    /* color: #409eff; */
    color:#fff;
  }
  /* .loadingBg{
    margin-top:200px;
  } */
  ::v-deep .loadingBg .el-dialog{
    margin-top:200px;
    background:rgba(255,255,255,0)!important;
    box-shadow: none;
  }
   /*客户端-新版*/
   .createAdverts{
    padding-bottom:10px;
    border-bottom:1px solid #F1F7F5;
    margin-bottom:20px;
  }
  .createAdverts span{
    font-size:14px;
    font-weight:700;
    color:#333;
    padding-left:13px;
    border-left:3px solid #48916F;
  }
  ::v-deep .createAll .el-input--mini .el-input__inner{
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .createAdTeamKeyword .el-input--mini .el-input__inner{
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .createAdTeamKeyword .el-switch.is-checked .el-switch__core{
    background:#48916F!important;
    border:none!important;
  }
  .budgetOrder{
    width:40%;
    height: 40px;
    line-height:40px;
    background: linear-gradient(270deg, #FFFFFF 0%, #F2F8F6 100%);
    padding-left:20px;
    margin-bottom:15px;
  }
  .budgetOrder span{
    font-size:14px;
    color:#65A487;
    font-weight:400;
  }
  ::v-deep .createAll .el-form-item--mini .el-form-item__label{
    color:#333!important;
  }
  .kwIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .kwWrap .el-dialog .el-dialog__header{
    padding-top:40px;
    font-size:18px;
    font-weight:700;
  }
  ::v-deep .kwWrap .el-dialog--center .el-dialog__body{
    padding-top:0;
  }
  .addKwTeam,.addKwTeamBtn{
    display: inline-block;
    vertical-align: middle;
  }
  .addKwTeam{
    width:40%;
    height: 36px;
    line-height:36px;
    background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
    border-radius: 10px 0px 0px 0px;
    padding-left:18px;
    box-sizing: border-box;
  }
  .addKwTeamBtn{
    text-align:right;
    width:60%;
  }
  .addKwInput{
    width:100%;
    padding:15px 0;
    background: #005E3F;
    border-radius: 7px;
    text-align:center;
  }
  ::v-deep .addKwInput .el-input--mini .el-input__inner{
    border-radius:30px;
  }
  ::v-deep .priceMax .el-input__inner{
    width:50px;
  }
  .kwAddNumBtn{
    display: inline-block;
    vertical-align: middle;
  }
  .kwAddNumBtn i{
    font-size:18px;
    font-weight:700;
    color:#48916F;
    cursor: pointer;
    margin-left:10px;
  }
  .kwdelete{
    font-size:16px;
    font-weight:700;
    color:#FF8080;
    cursor: pointer;
  }
  ::v-deep .smallDialog .el-popup-parent--hidden .el-dialog__body, #app .el-dialog__body{
    margin:0!important;
    padding:0!important;
  }
  .addNegKeywords,.addNegKeywords i {
    font-style: normal;
    font-size:14px;
    font-weight:700;
  }
  .addNum{
    font-size:12px!important;
    color:#666;
    float:right;
  }
  .linkage .linkKw{
    display: inline-block;
    vertical-align: middle;
    margin-right:20px;
    font-size:12px;
  }
  .linkKwLeft{
    padding:4px 12px;
    border:1px solid #EBEBEB;
    border-radius:4px;
    color:#999;
  }
  .linkKwLeft i{
    font-style: normal;
    color:#333;
  }
  .linkKwRight{
    padding:4px 12px;
    background:#F5F7F6;
  }
  ::v-deep .linkKwRight .el-input--mini .el-input__inner{
    border:none;
    background:#F5F7F6;
  }
  /*新预警*/
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .queryLeft,.queryRight{
    display: inline-block;
    vertical-align: middle;
  }
  .queryLeft{
    width:65%;
  }
  .queryRight{
    width:35%;
    text-align:right;
  }
  ::v-deep .dateStyle.el-range-editor.el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .dateStyle.el-range-editor .el-range-input{
    background:#F5F7F6!important;
  }
  ::v-deep .queryLeft .el-input--mini .el-input__inner{
    /* width:160px; */
    border:none;
    background:#F5F7F6;
  }
  ::v-deep .materialHead .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .materialHead{
    padding-bottom:20px;
  }
  .queryRight span i{
    font-style:normal;
    color:#999;
  }
  ::v-deep .queryLeft .el-button + .el-button{
    margin-left:0!important;
  }
  .chanelTabSecondary{
    margin-bottom:20px;
  }
  .chanelTabSecondary span{
    display: inline-block;
    vertical-align: middle;
    font-size:12px;
    padding:0 20px;
    height:30px;
    line-height:30px;
    background:#ECF3F0;
    color:#82A69A;
    margin-right:20px;
    cursor: pointer;
    border-radius: 30px;
  }
  .chanelTabSecondary span:hover,.chanelTabSecondary span.channeSelActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .kwPriceInput .el-input--mini .el-input__inner{
    height:40px;
    line-height:40px;
    border:none;
    background:#F5F7F6;
  }
  .linkage-left,.linkage-right{
    display: inline-block;
    vertical-align:middle;
    width:49%;
  }
  .linkage-right{
    text-align:left;
  }
</style>
