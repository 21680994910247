<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    title="Account Application"
  >
    <el-form
      style="width: 480px;margin:0 auto;margin-left:180px;"
      label-position="right"
      label-width="160px"
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules"
    >
      <el-form-item label="Name" prop="name" class="marginB50">
         <el-input
          v-model="formData.name"
          maxlength="100"
          show-word-limit
          :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="E-mail" prop="email" class="marginB50">
        <el-input
          v-model="formData.email"
          maxlength="100"
          show-word-limit
          :disabled="true"
        ></el-input>
      
      </el-form-item>
      <el-form-item label="Brand" prop="brand" class="marginB50">
         <el-select v-model="formData.brand" placeholder style="width:100%;">
          <el-option label="brand1" value="brand1"></el-option>
          <el-option label="brand2" value="brand2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Password" prop="password" class="marginB50">
      <div style="display: flex;justify-content: space-between;">
         <el-button type="primary" style="width:80%">Generate</el-button>
         <span style="display: block; width: 30px;height: 100%;border: 1px solid red;">√</span>
        </div>
      </el-form-item>
    
      <el-form-item style="text-align:right;margin-right:-180px;">
        <el-button type="primary">Save</el-button>
        <el-button type="primary" @click="submitForm('formData')">Send</el-button>
      </el-form-item>
    </el-form>

    <!-- <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm">{{$t('dc:确认')}}</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import Api from "@/api";

export default {
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => []
    },
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    var accountNameRoute = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else if (!new RegExp("[A-Z]").test(value)) {
        callback(new Error(rule.message || "请输入正确的联系人"));
      } else {
        callback();
      }
    };
    var accountCodeRoute = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
       
      } else if (!new RegExp("[A-Z]").test(value)) {
        callback(new Error(rule.message || "请输入正确的联系人"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      show: this.visible,
      currentTypeConfig: {},
      formData: {
        name: "CHen",
        email: "asdasd",
        brand: "",
        password: ""
      },
      rules: {
        productname: [{ required: true, message: this.$t("dc:请输入") }],
        accountName: [{ validator: accountNameRoute, trigger: ["blur", "change"] }],
        module: [{ required: true, message: this.$t("dc:请输入") }],
        accountCode: [ { validator: accountCodeRoute, trigger: ["blur", "change"] }]
      }
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },

  methods: {
    onConfirm() {},
    onCancel() {
      this.show= false;
    },
    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  }
};
</script>
<style lang="scss">
.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
</style>

