<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>首页管理</el-breadcrumb-item>
              <el-breadcrumb-item>账户列表</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="primary" size="small" @click="openDistribution">分配</el-button>
            <div style="display: inline-block;vertical-align:middle;margin-left:10px;margin-right:10px;">
              <el-upload
                class="upload-demo"
                ref="upload"
                :headers="myHeaders"
                action = "#"
                :http-request="autoDistribution" 
                accept=".xlsx"
                :limit='1'
                :show-file-list='false'>
                <el-button slot="trigger" type="primary" size="small" v-if="changeBtn">自动分配账户</el-button>
              </el-upload>
            </div>
            <el-button type="primary" size="small" v-if="directorAndLeader" @click="ownerDialogShow = true">添加账户页面</el-button>
            <div style="display: inline-block;vertical-align:middle;margin-left:10px;margin-right:10px;">
              <el-upload
                class="upload-demo"
                ref="ownerupload"
                :headers="myHeaders"
                action = "#"
                :http-request="ownerbutton" 
                accept=".xlsx"
                :limit='1'
                :show-file-list='false'>
                <el-button slot="trigger" type="primary" size="small" v-if="directorAndLeader">添加账户excel</el-button>
              </el-upload>
            </div>
          </div>
        </div>  
        <div class="chanelTab">
          <span class="channel_oppo" :class="item.id==6?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=6&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>
        <div class="userList">
          <div class="userform">
            <el-form :model="ruleForm" :inline="true" ref="ruleForm" class="demo-form-inline">
              <el-form-item label="" prop="comName">
                <el-autocomplete
                  class="inline-input"
                  v-model="ruleForm.comName"
                  value-key="company"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入公司名称"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="" >
                <el-input v-model = "ruleForm.yybName" clearable placeholder="请输入应用包名"></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model = "ruleForm.owneridSelect" clearable placeholder="请输入账户ID"></el-input>
              </el-form-item>
              <el-form-item label="" prop="fpstatuvalue" class="positionBox">
                <el-select v-model="ruleForm.fpstatuvalue" placeholder="分配状态" clearable @change="changeFpStatus($event)">
                  <el-option
                    v-for="item in fpstatuoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    @change="changeUserStatue($event)">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="dateChoice">
                <el-date-picker
                  v-model="ruleForm.dateChoice"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="" prop="firstName" class="positionBox">
                <el-input v-model = "ruleForm.firstName" clearable placeholder="请输入一级行业"></el-input>
              </el-form-item>
              <el-form-item label="" prop="secondName" class="positionBox">
                <el-input v-model = "ruleForm.secondName" clearable placeholder="请输入二级行业"></el-input>
              </el-form-item>
              <el-form-item label="" prop="customerType" class="positionBox">
                <el-select v-model="ruleForm.customerType" placeholder="请选择客户类型" clearable >
                  <el-option
                    v-for="(item,i) in customerTypeoptions"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="tokenTypes" class="positionBox">
                <el-select v-model="ruleForm.tokenTypes" placeholder="请选择账户类型" clearable >
                  <el-option
                    v-for="(item,i) in tokenTypeOptions"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="newQuery" size="small" @click="getAccountList(1)" style="margin-left:20px;">查询</el-button>
                <el-button type="info" size="small" @click="resetForm('ruleForm')">重置</el-button>
                
              </el-form-item>
            </el-form>
            <!-- 缓冲弹框 -->
            <el-dialog
              title="正在导入"
              :visible.sync="exprtVisible"
              width="30%">
              <span class="el-icon-loading uploading"></span>
            </el-dialog>
            <!-- 添加服务广告主 -->
            <el-dialog title="添加服务广告主" :visible.sync="ownerDialog" width="40%">
              <el-form :model="ownerForm" :rules="ownerRules" ref="ownerForm">
                <el-form-item label="账户ID" :label-width="formLabelWidth" prop="accountID">
                  <el-input v-model="ownerForm.accountID" placeholder="请输入账户ID"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" :label-width="formLabelWidth" prop="companyName">
                  <el-input v-model="ownerForm.companyName" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="创建时间" :label-width="formLabelWidth" prop="createTime">
                  <el-date-picker
                    v-model="ownerForm.createTime"
                    type="date"
                    placeholder="选择创建时间"
                    value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="一级行业" :label-width="formLabelWidth" prop="firstInduName">
                  <el-select v-model="ownerForm.firstInduName" placeholder="请选择一级行业" clearable @change="firstGongnameForm">
                    <el-option
                      v-for="(item,i) in firstNameoptions"
                      :key="i"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="二级行业" :label-width="formLabelWidth" prop="secondInduName">
                  <el-select v-model="ownerForm.secondInduName" placeholder="请选择二级行业" clearable @change="secondGongnameForm">
                    <el-option
                      v-for="item in secondNameoptions"
                      :key="item.value"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="账户名称" :label-width="formLabelWidth" prop="accountNameAdd">
                  <el-input v-model="ownerForm.accountNameAdd" placeholder="请输入账户名称"></el-input>
                </el-form-item>
                <el-form-item label="客户类型" :label-width="formLabelWidth" prop="customerType">
                  <el-select v-model="ownerForm.customerType" placeholder="请选择客户类型" clearable>
                    <el-option
                      v-for="(item,i) in customerTypeoptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="状态" :label-width="formLabelWidth" prop="accountStatus">
                  <el-select v-model="ownerForm.accountStatus" placeholder="请选择状态" clearable @change="changeAccountStatus">
                    <el-option
                      v-for="(item,i) in caccountStatusoptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="tokenID" :label-width="formLabelWidth" prop="tokenName">
                  <el-input v-model="ownerForm.tokenName" placeholder="请输入tokenID"></el-input>
                </el-form-item> 
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addOwnerCancle('ownerForm')">取 消</el-button>
                <el-button type="primary" @click="addOwnerSure('ownerForm')">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 添加账户页面 -->
            <el-dialog 
              title="添加账户页面" 
              :visible.sync="ownerDialogShow" 
              center
              :show-close = "false"
              width="500px">
              <div class="dialogIcon"><img :src='distrbIcon'></div>
              <el-form :model="ownerfwForm" :rules="ownerfwRules" ref="ownerfwForm" label-width="90px">
                <el-form-item label="账户ID" prop="accountID">
                  <el-input v-model="ownerfwForm.accountID" placeholder="请输入账户ID"></el-input>
                </el-form-item>
                
                <el-form-item label="公司名称" prop="companyName">
                  <el-input v-model="ownerfwForm.companyName" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="账户类别" prop="accountType">
                  <el-select v-model="ownerfwForm.accountType" placeholder="请选择账户类别" clearable>
                    <el-option
                      v-for="(item,i) in accountTypeOptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="客户类型" prop="customerType">
                  <el-select v-model="ownerfwForm.customerType" placeholder="请选择客户类型" clearable>
                    <el-option
                      v-for="(item,i) in customerTypeoptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="customerId" :label-width="formLabelWidth" prop="customerId">
                  <el-input v-model="ownerfwForm.customerId" placeholder="请输入customerId"></el-input>
                </el-form-item> -->
                <el-form-item label="客户端ID" prop="khid">
                  <el-input v-model="ownerfwForm.khid" placeholder="请输入客户端ID"></el-input>
                </el-form-item>
                <el-form-item label="密钥" prop="secretKeys">
                  <el-input v-model="ownerfwForm.secretKeys" placeholder="请输入密钥"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="newDialogCancel" @click="addOwnerfwCancle('ownerfwForm')">取消保存</el-button>
                <el-button type="primary" class="newDialog" @click="addOwnerfwSure('ownerfwForm')">确认保存</el-button>
              </div>
            </el-dialog>
            <!-- 二代服务类页面 -->
            <el-dialog title="二代服务类页面" :visible.sync="erdaiDialogShow" width="40%">
              <el-form :model="owneredForm" :rules="owneredRules" ref="owneredForm" label-width="120px">
                <el-form-item label="二代ID" prop="erdaiID">
                  <el-input v-model.number="owneredForm.erdaiID" placeholder="请输入二代ID"></el-input>
                </el-form-item>
                <el-form-item label="signId" prop="signId">
                  <el-input v-model="owneredForm.signId" placeholder="请输入signId"></el-input>
                </el-form-item>
                <el-form-item label="secretKey" prop="secretKey">
                  <el-input v-model="owneredForm.secretKey" placeholder="请输入secretKey"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addedfwCancle('owneredForm')">取 消</el-button>
                <el-button type="primary" @click="addedfwSure('owneredForm')">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 批量分配 -->
            <el-dialog
              title="分配账户"
              :visible.sync="dialogFormVisible"
              center
              :show-close = "false"
              width="600px">
              <div class="dialogIcon"><img :src='distrbIcon'></div>
              <el-form :model="distribution_form" :rules="plrules" class="distributionAccount">
                <el-form-item label="分配渠道" class="distribNewStyle" :label-width="formLabelWidth">
                  <el-input v-model="qdvalueHx" autocomplete="off" :disabled="true"></el-input>
                </el-form-item>
                <div>
                  <div v-for="(item,i) in bigFpParams.userList" class="newAddfpStyle newNetStyle" :key="i">
                    <div class="redstar newNetStyleIn_inline">
                      <!-- <span class="starStyle">*</span> -->
                      <el-form-item label="" :prop="`userList.${i}.dept`" :rules='plrules.dept'>
                        <span class="newRedStar">
                          <i>*</i>
                          <span>分配部门</span>
                        </span>
                        <el-cascader
                          v-model="item.dept"
                          :options="deptTree"
                          :props="props"
                          ref="cascaderAddr"
                          :show-all-levels="false"
                          placeholder="请选择分配部门"
                          @change="deptTreeDetail">
                        </el-cascader>
                      </el-form-item>
                    </div>
                    <div class="redstar newNetStyleIn_inline">
                      <span class="starStyle">*</span>
                      <el-form-item :label="i == 0?'主分配优化师':'辅分配优化师'" :label-width="formLabelWidth">
                        <el-select v-model="fpyhsSingle[i]" value-key="userId" filterable placeholder="请选择分配优化师" @change="bigFpChange($event,i)">
                            <el-option
                            v-for="(item,i) in distributionOptions"
                            :key="i"
                            :label="item.string"
                            :value="item">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <!-- <el-form-item label="" :label-width="formLabelWidth" class="newReduceBtnStyle" >
                      <el-button type="primary" v-if="i>0" size="small" @click="removeRowpl(i)">删除</el-button>
                    </el-form-item> -->
                    <div class="deleteBtn">
                      <div v-if="i>0" @click="removeRowpl(i)">
                        <i class="deleteBtnicon el-icon-error"></i>
                      </div>
                    </div>
                  </div>
                  <!-- <el-button type="primary" size="small" class="newAddBtnStyle" @click="newAddListplBtn">新增</el-button> -->
                  <div @click="newAddListplBtn" class="addConditions">
                    <i class="el-icon-plus"></i>新增分配
                  </div>
                </div>
                <div>
                  <div class="redstar">
                    <!-- <span class="starStyle">*</span> -->
                    <el-form-item label="产品名称" prop="plfenpeiproductName" label-width="70px" class="distribNewStyle tabdistribNewStyle tabProduct">
                      <el-select placeholder="请选择产品名称" v-model="plfenpeiproductName" filterable class="resourcesTab">
                        <el-option
                          v-for="(item,k) in fpProductNameOptions"
                          :key="k"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <div class="tabNew">
                        <el-button type="primary" class="newQuery" size="small" @click="productNameDialog = true">增加产品名称</el-button>
                        <el-dialog 
                          title="新增产品名称" 
                          :visible.sync="productNameDialog" 
                          append-to-body 
                          width="300px">
                          <el-input v-model="addproductNameValue" autocomplete="off" placeholder="请输入新增产品名称"></el-input>
                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="addproductName">新 增</el-button>
                            <el-button @click="cancleProductName">取 消</el-button>
                          </div>
                        </el-dialog>
                      </div>
                    </el-form-item>
                  </div>
                  <div class="redstar">
                    <span class="starStyle tabstarStyle">*</span>
                    <el-form-item label="客户类型" prop="plcostumerType" label-width="70px" class="distribNewStyle tabdistribNewStyle">
                      <el-select v-model="plcostumerType" filterable placeholder="请选择客户类型">
                        <el-option
                          v-for="(item,i) in costumerTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
                <div>
                  <div v-if="ruleForm.qdvalue == 'OPPO'||'vivo'">
                  </div>
                  <div v-else>
                    <el-form-item label="客户名称" :label-width="formLabelWidth">
                      <el-select v-model="distribution_form.userProductName" clearable filterable placeholder="请选择客户名称" @change="changeProduct($event)">
                        <el-option
                          v-for="(item,i) in productNameOptions"
                          :key="i"
                          :label="item.productName"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="项目名称" :label-width="formLabelWidth">
                      <el-select v-model="distribution_form.projectName" clearable filterable placeholder="请选择项目名称" @change="changeProject($event)" @focus="focusProject">
                        <el-option
                          v-for="(item,i) in projectNameOptions"
                          :key="i"
                          :label="item.projectName"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="newDialogCancel" @click="dialogFormVisible = false">取消保存</el-button>
                <el-button type="primary" class="newDialog" @click="bigSureFp">确认保存</el-button>
              </div>
            </el-dialog>     
          </div>
            <div class="userTable">
              <!-- 单个分配 -->
              <el-dialog
                title="分配账户"
                :visible.sync="dialogFormVisible_account"
                :close-on-click-modal="false"
                :show-close = "false"
                center
                width="600px">
                <div class="dialogIcon"><img :src='distrbIcon'></div>
                <el-form :model="account__form" ref="dangefenpei" :rules="rules" class="fpAccounts">
                  <el-form-item label="公司名称" prop="name" class="distribNewStyle" :label-width="formLabelWidth">
                    <el-input v-model="account__form.name" :disabled="true" autocomplete="off" style="width:260px;"></el-input>
                  </el-form-item>
                  <el-form-item label="账户ID" prop="regionID" class="distribNewStyle" :label-width="formLabelWidth">
                    <el-input v-model="account__form.regionID" :disabled="true" autocomplete="off" style="width:260px;"></el-input>
                  </el-form-item>
                  <div>
                    <div v-for="(item,i) in disAccountParams.userList" class="newAddfpStyle newNetStyle" :key="i">
                      <div class="redstar newNetStyleIn_inline">
                        <el-form-item label="" >
                          <span class="newRedStar">
                            <i>*</i>
                            <span>分配部门</span>
                          </span>
                          <el-cascader
                            v-model="item.dept"
                            :options="deptTreefp"
                            :props="props"
                            ref="cascaderAddr"
                            :show-all-levels="false"
                            :placeholder="item.deptName?item.deptName:'请选择分配部门'"
                            @change="deptTreefpDetail">
                          </el-cascader>
                        </el-form-item>
                      </div>
                      <div class="redstar newNetStyleIn_inline">
                        <span class="starStyle">*</span>
                        <el-form-item :label="i==0?'主分配优化师':'辅分配优化师'" :label-width="formLabelWidth">
                          <el-select v-model="fpyhsSingle[item.userId]" value-key="userId" filterable placeholder="请选择分配优化师" @visible-change="$forceUpdate()" @change="changeOptimizer($event,i)">
                              <el-option
                              v-for="(item1,j) in distributionOptionsfp[item.dept]"
                              :key="j"
                              :label="item1.string"
                              :value="item1"
                              :disabled="item1.chkDisabled">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="deleteBtn">
                        <div v-if="i>0" @click="removeRow(i)">
                          <i class="deleteBtnicon el-icon-error"></i>
                        </div>
                      </div>
                        <!-- <el-form-item label="" :label-width="formLabelWidth" class="newReduceBtnStyle" >
                          <el-button type="primary" v-if="i>0" size="small" @click="removeRow(i)">删除</el-button>
                        </el-form-item> -->
                    </div>
                    <!-- <el-button type="primary" size="small" class="newAddBtnStyle" @click="newAddListBtn">新增</el-button> -->
                    <div @click="newAddListBtn" class="addConditions">
                      <i class="el-icon-plus"></i>新增分配
                    </div>
                  </div>
                  <!-- <div>
                    <el-form-item label="素材标签" prop="addmarterialValue" :label-width="formLabelWidth">
                      <div class="addTagsName">
                        <el-button type="primary" plain size="small" @click="addTagDialog = true">增加标签</el-button>
                        <el-dialog title="新增标签" :visible.sync="addTagDialog" append-to-body width="25%">
                          <el-input v-model="addTagsValue" autocomplete="off" placeholder="请输入新增标签名称"></el-input>
                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="addTags">新 增</el-button>
                            <el-button @click="addTagDialog = false">取 消</el-button>
                          </div>
                        </el-dialog>
                      </div>
                      <div class="resourcesBox" style="margin:10px 0;">
                        <el-select placeholder="请选择素材标签" v-model="account__form.addmarterialValue" value-key="id" multiple filterable class="resourcesTab" @change="materialTagIdsSingle">
                          <el-option
                            v-for="(item,k) in resourcesTabPoptions"
                            :key="k"
                            :label="item.tagName"
                            :value="item.id">
                          </el-option>
                        </el-select>
                      </div>
                  </el-form-item>
                </div> -->
                <div>
                  <el-form-item label="产品名称" label-width="80px" class="distribNewStyle tabProduct">
                    <el-select placeholder="请选择产品名称" v-model="account__form.fenpeiproductName" filterable class="resourcesTab">
                      <el-option
                        v-for="(item,k) in fpProductNameOptions"
                        :key="k"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="tabNew">
                      <el-button type="primary" class="newQuery" size="small" @click="productNameDialog = true">增加产品名称</el-button>
                      <el-dialog 
                        title="新增产品名称" 
                        :visible.sync="productNameDialog" 
                        append-to-body 
                        width="300px">
                        <el-input v-model="addproductNameValue" autocomplete="off" placeholder="请输入新增产品名称"></el-input>
                        <div slot="footer" class="dialog-footer">
                          <el-button type="primary" @click="addproductName">新 增</el-button>
                          <el-button @click="cancleProductName">取 消</el-button>
                        </div>
                      </el-dialog>
                    </div>
                </el-form-item>
                <el-form-item label="客户类型" prop="costumerType" label-width="80px" class="distribNewStyle ">
                  <el-select v-model="account__form.costumerType" filterable placeholder="请选择客户类型">
                    <el-option
                      v-for="(item,i) in costumerTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="任务" prop="taskName" :label-width="formLabelWidth">
                  <el-select v-model="account__form.taskName" filterable placeholder="请选择任务">
                    <el-option
                      v-for="(item,i) in taskOptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item> -->
              </div>
                <div>
                  <div v-if="ruleForm.qdvalue == 'OPPO'||'vivo'">
                  </div>
                  <div v-else>
                    <el-form-item label="可查看人员" :label-width="formLabelWidth">
                      <el-select v-model="qdvalueLook" :placeholder="qdvalueLookhx" filterable style="margin-right:10px;" @change="changeChannelBottom($event)">
                        <el-option
                          v-for="(item,i) in qdoptionsLook"
                          :key="i"
                          :label="item.name"
                          :value="item">
                        </el-option>
                      </el-select>
                      <el-select v-model="regionyhsBtomm" :placeholder="regionyhsBtommHx" filterable @change="getLookUser($event)">
                        <el-option
                          v-for="(item,i) in sender_options"
                          :key="i"
                          :label="item.string"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="newDialogCancel" @click="cancel('dangefenpei')">取消保存</el-button>
                <el-button type="primary" class="newDialog" @click="sureTobeAllocatd('dangefenpei')">确认保存</el-button>
              </div>
            </el-dialog>
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                prop="username"
                label="公司名称"
                fixed="left"
                width="240">
                <template slot-scope="scope">{{scope.row.company}}</template>
              </el-table-column>
              <el-table-column
                prop="pacname"
                fixed="left"
                label="应用包名"
                width="240">
              </el-table-column>
              <el-table-column
                prop="userPosition"
                label="账户类别"
                width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.tokenType == 0">全部</span>
                  <span v-else-if="scope.row.tokenType == 1">业绩类</span>
                  <span v-else-if="scope.row.tokenType == 2">服务类</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="userManage"
                label="账户ID"
                width="180">
                <template slot-scope="scope">{{scope.row.advertiserId}}</template>
              </el-table-column>
              <el-table-column
                prop="userHeigher"
                label="分配部门"
                width="180">
                <template slot-scope="scope">
                  <span v-for="item in scope.row.userList" class="deptNameList">{{item.deptName}}</span>                  
                </template>
              </el-table-column>
              <el-table-column
                prop="zqtime"
                label="一级行业"
                width="100">
                <template slot-scope="scope">{{scope.row.firstIndustryName}}</template>
              </el-table-column>
              <el-table-column
                prop="zqtime"
                label="二级行业"
                width="100">
                <template slot-scope="scope">{{scope.row.secondIndustryName}}</template>
              </el-table-column>
              <el-table-column
                prop="zqtime"
                label="客户类型"
                width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.onFlag == '星火'">星火</span>
                  <span v-else-if="scope.row.onFlag == '燎原'">燎原</span>
                  <span v-else-if="scope.row.onFlag == '年框'">年框</span>
                  <span v-else-if="scope.row.onFlag == '飞跃A'">飞跃A</span>
                </template>
              </el-table-column>
              <el-table-column
              prop="pname"
              label="产品名称"
              width="100">
            </el-table-column>
              <el-table-column
                prop="zqtime"
                label="抓取时间"
                width="200">
                <template slot-scope="scope">{{scope.row.createTime | dateFormat}}</template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="数据更新开关"
                width="120">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.status"
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    active-value="0"
                    inactive-value="1"
                    @change="changeDelFlag(scope.row)"
                    >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                fixed="right"
                label="操作"
                width="100px"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button class="solid-tag-success" plain size="mini" @click="tobeAllocatd(scope.row)" v-if="scope.row.userList.length == 0">待分配</el-button>
                  <el-button class="solid-tag-warning" plain size="mini" v-show="changeBtn" @click="editTobeAllocatd(scope.row)" v-else>变更</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>
        </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <accessDialog :visible.sync="showDialog" ></accessDialog>
  </div>
</template>

<script>
import distrbIcon from '@/assets/img/distrbIcon.png';
import accessDialog from '@/views/dialog/accountDialog';
import echarts from  "echarts";
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'accountList',
  components: {
    accessDialog
  },
  data() {
    return {
      distrbIcon,
      showDialog: false,
      channelTitleList:[],
      fpstatuoptions:[{
        value: 0,
        label: '未分配'
      }, {
        value: 1,
        label: '已分配'
      }],
      ruleForm:{
        qdvalue:'',
        comName:'',
        comAccountID:'',
        fpstatuvalue:'',
        dateChoice:[],
        accountName:'',//账户名称
        firstName:'',
        secondName:'',
        tokenTypes:'',
        yybName:'',//应用包
        owneridSelect:'',//广告主id
        customerName:'',//产品名称
        // taskName:'',//任务名称
      },
      tokenTypeOptions:[{
        value:0,
        label:'全部'
      },{
        value:1,
        label:'业绩类'
      },{
        value:2,
        label:'服务类'
      }],
      tableData: [],//账户列表数据
      multipleSelection:[],
      dialogFormVisible: false,
      dialogFormVisible_account:false,
      distribution_form: {
        name: '',
        regionzu: '',
        regionshi: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        userProductName:'',
        projectName:'',
        userList:[{
          dept:''
        }]
      },
      productNameOptions:[],
      projectNameOptions:[],
      account__form: {
        name: '',
        regionID: '',
        regionyhs:'',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        qdvaluefp:'',
        firstHangye:'',
        secondHangye:'',
        costumerType:'',
        addmarterialValue:[],
        fenpeiproductName:'',//分配弹框中的产品名称
        // taskName:'',//任务
      },
      addmarterialplValue:[],
      fpProductNameOptions:[],//分配弹框中的产品名称集合
      plfenpeiproductName:'',//批量分配产品名称
      plcostumerType:'',//批量分配客户名称
      rules: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        regionID: [
          { required: true, message: '请输入账户ID', trigger: 'blur' }
        ],
        firstHangye:[
          { required: true, message: '请选择一级行业', trigger: 'change' }
        ],
        secondHangye:[
          { required: true, message: '请选择二级行业', trigger: 'change' }
        ],
        costumerType:[
          { required: true, message: '请选择客户类型', trigger: 'change' }
        ],
        addmarterialValue:[
          { required: true, message: '请选择素材标签', trigger: 'change' }
        ],
        // fenpeiproductName:[
        //   { required: true, message: '请选择产品名称', trigger: 'change' }
        // ],
        // taskName:[
        //   { required: true, message: '请选择任务', trigger: 'change' }
        // ],
      },
      plrules:{
			  // dept: [
        //   { required: true, message: '请填写类型', trigger: 'change' }
        // ]
			},
      costumerTypeOptions:[{
        value:'星火',
        label:'星火'
      },{
        value:'燎原',
        label:'燎原'
      },{
        value:'年框',
        label:'年框'
      },{
        value:'飞跃A',
        label:'飞跃A'
      }],
      accountTypeOptions:[{
        value:1,
        label:'业绩类'
      },{
        value:2,
        label:'服务类'
      }],
      firstHangyeOptions:[],
      secondHangyeOptions:[],
      qdoptionsfp:[],
      formLabelWidth: '90px',
      // 分页
      pageNum: 1,//当前页
      pageSize: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      distributionOptions:[],//分配优化师
      disAccountParams:{
        advertiserId:'',
        lookChannel:'',
        lookUserDeptId:'',
        lookUser:'',
        tagIds:'',
        userList:[
          {
            dept:'',
            deptName:'',
            userId:'',
          }
        ],
        onFlag:'',
        firstIndustryName:'',
        secondIndustryName:''
      },
      accountParams:{
        company:'',
        id:'',
        name:'',
        customerId:'',
        onFlag:'',
        params:{},
      },
      deptTree:[],
      fpDeptValue:'',
      props:{
        value:'id',
        label:'label',
        children:'children',
        emitPath:false,
        checkStrictly: true
      },
      accountListParams:{
        loginUserId:'',
        channelId:'',
        company:'',
        id:'',
        params:{
          beginTime:'',
          endTime:''
        },
      },
      channelIdChecked:'',
      regionyhsBtomm:'',
      sender_options:[],
      qdoptionsLook:[],
      qdvalueLook:'',
      qdvalueHx:'',
      bigFpParams:{
        advertiserId:'',
        productName:'',
        productId:'',
        projectName:'',
        projectId:'',
        tagIds:'',
        userList:[{
          dept:'',
          deptName:'',
          userId:'',
        }]
      },
      bigFpids:[],
      hxfpzh:true,
      qdvalueLookhx:'',
      regionyhshx:[],
      regionyhsBtommHx:'',
      channelIdNew:'',
      deptIdNew:'',
      deptIdNewfp:'',
      userChannel:'',
      deptTreefp:[],
      channelIdfpNew:'',
      getProductId:'',
      newAddDeptList:[],
      fpyhsSingle:[],
      choiceDept:[],
      choiceDeptId:[],
      cityList:[],
      resourcesTabPoptions:[],//素材标签
      materialListParams:{
        tagType:2,
        userId:'',
        channel:''
      },
      setUserId:'',
      addTagDialog:false,//新增标签弹框
      addTagsValue:'',
      addtagParams:{
        channel:'',
        createUser:'',
        tagName:'',
        tagType:''
      },
      needUserId:'',
      zongjian:true,
      roleKey:[],
      haveTagListParams:{
        channelId:'',
        ownerId:''
      },
      haveTaglist:[],
      dataChange:'',
      changeBtn:true,//普通优化师不显示变更按钮
      zongjianDept:'',
      deptValue:'',
      distributionOptionsfp:{},
      hxyouhuashi:'',
      hxyouhuashiId:'',
      firstNameoptions:[],
      secondNameoptions:[],
      // customerTypeoptions:[{
      //   value:0,
      //   label:'新客'
      // },{
      //   value:1,
      //   label:'老客'
      // }],
      customerTypeoptions:[{
        value:'星火',
        label:'星火'
      },{
        value:'燎原',
        label:'燎原'
      },{
        value:'年框',
        label:'年框'
      },{
        value:'飞跃A',
        label:'飞跃A'
      }],
      caccountStatusoptions:[{
        value:0,
        label:'正常'
      },{
        value:1,
        label:'停用'
      }],
      ownerDialog:false,//添加广告主弹框
      ownerForm:{
        accountID:'',
        companyName:'',
        createTime:'',
        firstInduName:'',
        secondInduName:'',
        accountNameAdd:'',
        customerType:'',
        accountStatus:'',
        tokenName:''
      },
      ownerRules: {
        accountID: [
          { required: true, message: '请输入账户ID', trigger: 'blur' },
        ],
        companyName:[
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        createTime:[
          { required: true, message: '请选择创建日期', trigger: 'change' }
        ],
        firstInduName:[
          { required: true, message: '请选择一级行业', trigger: 'change' }
        ],
        secondInduName:[
          { required: true, message: '请选择二级行业', trigger: 'change' }
        ],
        accountNameAdd:[
          { required: true, message: '请输入账户名称', trigger: 'blur' },
        ],
        customerType:[
          { required: true, message: '请选择客户类型', trigger: 'change' }
        ],
        accountStatus:[
          { required: true, message: '请选择状态', trigger: 'change' }
        ],
        tokenName:[
          { required: true, message: '请输入tokenId', trigger: 'blur' },
        ]
      },
      successDialog:false,
      successle:'',
      tagListDialog:false,//上传素材标签弹框
      myHeaders: {Authorization:''},
      exprtVisible:false,//导出的缓冲弹框
      addOwnerParams:{},
      jingli:false,
      productNameDialog:false,//增加产品名称
      addproductNameValue:'',
      fpcxProductNameOptions:[],
      accountSelectionListShow:true,//用户是否有权限
      directorAndLeader:false,//服务类四个按钮只有运营总监运营经理和admin有权限
      ownerDialogShow:false,//广告主服务类页面
      ownerfwForm:{
        accountID:'',//账户ID
        accountType:'',//账户类别
        // customerId:'',//customerId
        companyName:'',//公司名称
        customerType:'',//客户类型
        khid:'',//客户端id
        secretKeys:'',//密钥
      },
      ownerfwRules: {
        accountID: [
          { required: true, message: '请输入账户ID', trigger: 'blur' },
        ],
        // customerId:[
        //   { required: true, message: '请输入customerId', trigger: 'blur' },
        // ],
        companyName:[
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        accountType:[
          { required: true, message: '请选择账户类别', trigger: 'change' }
        ],
        customerType:[
          { required: true, message: '请选择客户类型', trigger: 'change' }
        ],
        khid:[
          { required: true, message: '请输入客户端ID', trigger: 'blur' },
        ],
        secretKeys:[
          { required: true, message: '请输入密钥', trigger: 'blur' },
        ],

        // firstInduName:[
        //   { required: true, message: '请输入一级行业名称（新版）', trigger: 'blur' }
        // ],
        // secondInduName:[
        //   { required: true, message: '请输入二级行业名称（新版）', trigger: 'blur' }
        // ],      
        // signId:[
        //   { required: true, message: '请输入signId', trigger: 'blur' },
        // ],
        // secretKey:[
        //   { required: true, message: '请输入secretKey', trigger: 'blur' },
        // ]
      },
      erdaiDialogShow:false,//二代服务类页面
      owneredForm:{
        erdaiID:'',//二代ID
        signId:'',//signId
        secretKey:'',//secretKey
      },
      owneredRules:{
        erdaiID: [
          { required: true, message: '请输入二代ID', trigger: 'blur' },
          { type: 'number', message: '二代ID必须为数字'}
        ],
        signId:[
          { required: true, message: '请输入signId', trigger: 'blur' },
        ],
        secretKey:[
          { required: true, message: '请输入secretKey', trigger: 'blur' }
        ],
      },
      taskOptions:[{
        name:'AAC全量',
        id:'AAC全量'
      },{
        name:'AAC算法',
        id:'AAC算法'
      },{
        name:'DAU',
        id:'DAU'
      },{
        name:'RTA',
        id:'RTA'
      },{
        name:'促购',
        id:'促购'
      },{
        name:'促逛',
        id:'促逛'
      },{
        name:'促玩',
        id:'促玩'
      },{
        name:'防流失',
        id:'防流失'
      },{
        name:'激活',
        id:'激活'
      },{
        name:'拉活',
        id:'拉活'
      },{
        name:'拉新',
        id:'拉新'
      },{
        name:'深度召回',
        id:'深度召回'
      },{
        name:'首购',
        id:'首购'
      },{
        name:'卸载召回',
        id:'卸载召回'
      },{
        name:'召回',
        id:'召回'
      }],//任务枚举
      // pltaskName:'',//批量任务model

    }
  },

  mounted() {
    this.setUserId = this.$sessionStorage.getItem(config.USERID) || 1
    this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
    this.getRole()
    this.getChannelList()
    this.getuserChannel()
    this.getIndustrySelect()
    this.fpchaxunAccountProduct()
    this.ownerfwForm.accountType = 1
  },
  
  methods: {
    //获取角色权限
    getRole(){
      let that = this
      $http.axios.get("/api/system/user/getInfo").then(function(response) {
        if(response.data.code == 200){
          that.roleKey = response.data.roles
          that.roleKey.forEach(function(e,i){
            //总监
            if(e.indexOf('director') !=-1){
              that.zongjian = false
            }else{
              that.zongjian = true
            }
            //普通优化师
            if(e.indexOf('person') !=-1){
              that.changeBtn = false
            }else{
              that.changeBtn = true
            }
            //经理
            if(e.indexOf('leader') !=-1 ||e.indexOf('admin') !=-1){
              that.jingli = true
              that.fpAccountProduct()
            }else{
              that.jingli = false
            }
            //经理、总监、admin
            if(e.indexOf('leader') !=-1 || e.indexOf('admin') !=-1 || e.indexOf('director') !=-1){
              that.directorAndLeader = true//三个角色有权限
            }else{
              that.directorAndLeader = false//三个角色有权限
            }
          })
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //头部表单重置
    resetForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
      this.$router.go(0)
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.company.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleSelect(item) {
      console.log(item);
    },

    changePickDate(){
      this.accountParams.params.beginTime = this.ruleForm.dateChoice[0]
      this.accountParams.params.endTime = this.ruleForm.dateChoice[1]
    },

    //获取账户列表
    getAccountList(page){
      let that = this
      if(page == 1){
        that.pageNum = 1
      }
      that.accountParams.company = String(that.ruleForm.comName)
      that.accountParams.customerId = String(that.ruleForm.comAccountID)
      that.accountParams.name = String(that.ruleForm.accountName)
      if(that.ruleForm.dateChoice != null){
        that.accountParams.params.beginTime = String(that.ruleForm.dateChoice[0])
        that.accountParams.params.endTime = String(that.ruleForm.dateChoice[1])
      }else{
        that.accountParams.params.beginTime = ''
        that.accountParams.params.endTime =  ''
      }
      that.accountParams.tokenType = that.ruleForm.tokenTypes
      that.accountParams.onFlag = that.ruleForm.customerType
      that.accountParams.secondName = that.ruleForm.secondName
      that.accountParams.firstName = that.ruleForm.firstName
      that.accountParams.id = that.ruleForm.owneridSelect
      that.accountParams.pacname = that.ruleForm.yybName
      that.accountParams.page = that.pageNum
      that.accountParams.pageSize = that.pageSize
      that.accountParams.proId = that.ruleForm.customerName//经理级别的显示产品名称
      // that.accountParams.task = that.ruleForm.taskName//任务
      $http.axios.post("/api/hwat/account/list",that.accountParams).then(function(response) {
        if(response.data.code == 200){
          that.tableData = response.data.rows
          that.totalRow = response.data.total
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取渠道列表
    getChannelList(){
      let that = this
      $http.axios.get("/api/system/user/getChannelList").then(function(response) {
        if(response.data.code == 200){
          that.qdoptionsLook = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取渠道id
    changeChannel(event){
      this.qdvalue = event.name
      this.ruleForm.qdvalue = event.name
      this.accountListParams.channelId = String(event.id)
      this.channelIdNew = event.id
    },

    //获取分配状态
    changeFpStatus(event){
      this.accountParams.ifUser = String(event)
      this.accountListParams.ifUser = String(event)
    },

    //获取分配状态
    changeUserStatue(event){
      this.accountParams.ifUser = String(event)
      this.accountListParams.ifUser = String(event)
    },

    //修改后的关联到user的channel
    getuserChannel(){
      let that = this
      that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
      $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
        if(response.data.data.length!=0){
          that.channelTitleList = response.data.data
          that.accountSelectionListShow=true//用户是否有权限
          that.ruleForm.qdvalue = response.data.data[0].name
          that.channelIdNew = response.data.data[0].id
          that.accountListParams.channelId = response.data.data[0].id
          that.materialListParams.channel = response.data.data[0].id
          that.addtagParams.channel = response.data.data[0].id
          that.ownerForm.accountStatus = 0
          that.getAccountList()
        }else{
          that.accountSelectionListShow=false
          that.$message({
            showClose: true,
            message: "您的操作权限不足",
            type: 'error'
          })
        }
      })
    },

    //channel的tab切换
    clickChangeChannel(channelId){
      let channelNameList = {7:'YybAccountManagement.AccountListYyb',5:'MiAccountManagement.AccountListMi',4:'VivoAccountManagement.AccountListVivo',3:'authManagement.AccountList'}
      this.$router.push({name:channelNameList[channelId]})
    },

    //获取channel的id
    changeChannelBottom(event){
      this.qdvalueLook = event.name
      this.channelIdChecked = event.id
      this.disAccountParams.lookChannel = event.id
      this.regionyhsBtomm = ''
      this.getLookPerson()
    },

    //可查看的人员
    getLookPerson(){
      let that = this
      $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId="+that.channelIdChecked).then(function(response) {
        if(response.data.code == 200){
          that.sender_options = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取批量分配账户的分配部门id
    deptTreeDetail(event){
      this.deptIdNew = event
      this.getFpList()
    },

    deptTreefpDetail(event){
      this.deptIdNewfp = event
      let that = this
      if(that.zongjian == false){
        that.disAccountParams.userList.forEach(function(e,i){
          if(e.dept == 298){
            e.deptName = '华北'
            e.userId = 282
          }else if (e.dept == 297){
            e.deptName = '华南'
            e.userId = 300
          }
        })
      }
      this.getFpsingleList(event)
    },
    
    //打开批量分配弹框
    openDistribution(){
      let that = this
      that.materialTab()
      that.fpAccountProduct()
      that.bigFpids = []
      that.fpyhsSingle = []
      let modifySelection = that.$refs.multipleTable.selection
      modifySelection.forEach(function(e,i){
        that.bigFpids.push(e.advertiserId)  
      })
      if(that.ruleForm.qdvalue == ''){
        that.$message('请选择渠道名称')
        that.dialogFormVisible = false
      }else{
        that.dialogFormVisible = true
      }
      if(that.bigFpids.length == 0){
        that.$message({
          message: '请勾选账户',
          type: 'warning'
        });
        that.dialogFormVisible = false
      }else{
        that.dialogFormVisible = true
      }
      that.qdvalueHx = '华为'
      that.fpDeptValue = ''
      that.distribution_form.regionshi = ''
      that.getDeptTree()
      that.getProduct()
    },

    changeAccountStatus(val){
      console.log(val)
      this.addOwnerParams.status = val
    },

    //添加服务广告主
    addOwner(){
      this.ownerDialog = true
    },
    addOwnerSure(ownerForm){
      let that = this
      that.$refs[ownerForm].validate((valid) => {
        if (valid) {
          that.addOwnerParams = {
            advertiserId: that.ownerForm.accountID,
            company: that.ownerForm.companyName,
            createTime: that.ownerForm.createTime,
            firstIndustryName: that.ownerForm.firstInduName,
            secondIndustryName: that.ownerForm.secondInduName,
            level:'3',
            name: that.ownerForm.accountNameAdd,
            onFlag: that.ownerForm.customerType,
            status: that.ownerForm.accountStatus,
            tokenId: that.ownerForm.tokenName
          }
          $http.axios.post("/api/hwat/account/insertAccount",that.addOwnerParams).then(function(response) {
            if(response.data.code == 200){
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'success'
              })
              that.ruleForm = {
                qdvalue:'',
                comName:'',
                comAccountID:'',
                fpstatuvalue:'',
                dateChoice:[],
                accountName:'',//账户名称
                firstName:'',
                secondName:'',
                tokenTypes:'',
                customerName:''
              }
              that.ownerDialog = false
              that.$refs[ownerForm].resetFields()
              that.getAccountList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        } 
      })
    },

    //添加账户页面
    addOwnerfwSure(ownerfwForm){
      let that = this,params = {}
      that.$refs[ownerfwForm].validate((valid) => {
        if (valid) {
          params={
            'advertiserId':that.ownerfwForm.accountID,//账户id
            'company':that.ownerfwForm.companyName,//公司名称
            'accountType':that.ownerfwForm.customerType,//客户类别（星火、燎原、年框、飞跃A）
            'tokenType':String(that.ownerfwForm.accountType),//账户类别（业绩、服务）
            'clientId':that.ownerfwForm.khid,//客户端id
            // 'customerId':that.ownerfwForm.customerId,//customerId
            'authorization':that.ownerfwForm.secretKeys,//密钥

          }
          $http.axios.post("/api/hwat/account/add",params).then(function(response) {
            if(response.data.code == 200){
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'success'
              })
              that.ruleForm = {
                qdvalue:'',
                comName:'',
                comAccountID:'',
                fpstatuvalue:'',
                dateChoice:[],
                accountName:'',//账户名称
                firstName:'',
                secondName:'',
                tokenTypes:'',
                customerName:''
              }
              that.ownerDialogShow= false
              that.$refs[ownerfwForm].resetFields()
              that.getAccountList()
            } else {
              that.ownerDialogShow= true
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        } 
      })
    },

    //二代服务类页面
    addedfwSure(owneredForm){
      console.log(owneredForm)
      let that = this,params = {}
      that.$refs[owneredForm].validate((valid) => {
        if (valid) {
          params={
            'agent':that.owneredForm.erdaiID,
            'signId':that.owneredForm.signId,
            'secretKey': that.owneredForm.secretKey
          }
          $http.axios.post("/api/hwat/account/addSign",params).then(function(response) {
            if(response.data.code == 200){
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'success'
              })
              that.ruleForm = {
                qdvalue:'',
                comName:'',
                comAccountID:'',
                fpstatuvalue:'',
                dateChoice:[],
                accountName:'',//账户名称
                firstName:'',
                secondName:'',
                tokenTypes:'',
                customerName:''
              }
              that.erdaiDialogShow= false
              that.$refs[owneredForm].resetFields()
              that.getAccountList()
            } else {
              that.erdaiDialogShow= true
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        } 
      })
    },

    addOwnerCancle(ownerForm){
      this.ownerDialog = false
      this.$refs[ownerForm].resetFields()
    },

    //广告主服务类页面取消
    addOwnerfwCancle(ownerfwForm){
      this.ownerDialogShow = false
      this.$refs[ownerfwForm].resetFields()
    },

    //二代服务类页面取消
    addedfwCancle(owneredForm){
      this.erdaiDialogShow = false
      this.$refs[owneredForm].resetFields()
    },

    //获取批量分配中的分配部门下拉框
    getDeptTree(){
      let that = this
      $http.axios.get("/api/system/dept/treeselect?channelId="+that.channelIdNew).then(function(response) {
        if(response.data.code == 200){
          that.deptTree = response.data.data
          that.deptTreefp = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取批量分配中的分配部门下拉框
    getDeptfpTree(){
      let that = this
      $http.axios.get("/api/system/dept/treeselect?channelId="+that.channelIdfpNew).then(function(response) {
        if(response.data.code == 200){
          that.deptTreefp = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //批量分配新增
    newAddListplBtn(){
      this.bigFpParams.userList.push({})
    },

    //批量分配删除
    removeRowpl(index) {
      if (index >= 0) {
        this.bigFpParams.userList.splice(index, 1);
        this.fpyhsSingle.splice(index, 1)
      }
    },

    //获取分配人员详情
    bigFpChange(event,i){
      let that = this
      that.bigFpParams.userList[i].deptName = String(event.depyName)
      that.bigFpParams.userList[i].userId = String(event.userId)
      that.fpyhsSingle[i] = String(event.string)
      if(i == 0){
        that.bigFpParams.userList[0].main = 1
      }else{
        that.bigFpParams.userList[i].main = 0
      }
    },

    //客户名称下拉框
    getProduct(){
      let that = this
      $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
        if(response.data.code == 200){
          that.productNameOptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //项目名称下拉框
    getProject(){
      let that = this
      $http.axios.get("/api/operationManagement/materialoperation/getallProject?productId="+that.getProductId).then(function(response) {
        if(response.data.code == 200){
          that.projectNameOptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取客户名称详情
    changeProduct(event){
      this.distribution_form.userProductName = event.productName
      this.getProductId = event.id
      this.distribution_form.projectName = ''
      this.bigFpParams.productId = event.id
      this.bigFpParams.productName = event.productName
      if(this.distribution_form.userProductName){
        this.getProject()
      }else{
        this.getProductId = ''
        this.getProject()
      }
    },

    //获取项目名称详情
    changeProject(event){
      this.distribution_form.projectName = event.projectName
      this.bigFpParams.projectId = event.id
      this.bigFpParams.projectName = event.projectName
    },
    focusProject(){
      if(this.getProductId == null){
        this.$message('请选择客户名称');
      }else{
        this.getProject()
      }
    },

    //批量分配---确定
    bigSureFp(){
      let that = this
      that.bigFpParams.advertiserId = String(that.bigFpids)
      that.bigFpParams.proId = that.plfenpeiproductName
      that.bigFpParams.onFlag = that.plcostumerType
      that.bigFpParams.task = that.pltaskName
      that.roleKey.forEach(function(e,i){
        //经理
        if(e.indexOf('leader') !=-1 ||e.indexOf('admin') !=-1){
          that.jingli = true
          that.bigFpParams.proId = that.plfenpeiproductName
          that.bigFpParams.onFlag = that.plcostumerType
          if(that.addmarterialplValue.length == 0){
            that.$message({
              showClose: true,
              message: '请选择素材标签',
              type: 'error'
            })
          }
          // else if(that.plfenpeiproductName == ''){
          //   that.$message({
          //     showClose: true,
          //     message: '请选择产品名称',
          //     type: 'error'
          //   })
          // }
          else{
            $http.axios.post("/api/hwat/account/editBatch",that.bigFpParams).then(function(response) {
              if(response.data.code == 200){
                that.dialogFormVisible = false
                that.getAccountList()
              } else {
                that.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: 'error'
                })
              }
            })
          } 
        }
        else{
          that.jingli = false
          $http.axios.post("/api/hwat/account/editBatch",that.bigFpParams).then(function(response) {
            if(response.data.code == 200){
              that.dialogFormVisible = false
              that.getAccountList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        }
      })
    },

    //获取分配人员列表
    getFpList(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId="+that.deptIdNew).then(function(response) {
        if(response.data.code == 200){
          that.distributionOptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取分配人员列表
    getFpsingleList(deptId){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId="+deptId).then(function(response) {
        if(response.data.code == 200){
          that.distributionOptionsfp[deptId] = response.data.data 
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //点击待分配按钮-回显
    tobeAllocatd(row){
      let that = this
      that.roleKey.forEach(function(e,i){
        that.fpAccountProduct()
        //经理
        if(e.indexOf('leader') !=-1){
          that.fpAccountProduct()
        }
      })
      that.dialogFormVisible_account = true
      that.disAccountParams.userList=[{
        dept:'',
        deptName:'',
        userId:''
      }]
      that.distributionOptionsfp = {}
      that.materialTab()
      that.getIndustry()//一二级行业
      that.hxfpzh = true
      if(that.hxfpzh == true){
        that.account__form.regionyhs = ''
        that.qdvalueLook = ''
        that.regionyhsBtomm = ''         
        that.account__form.name = row.company
        that.account__form.regionID = row.advertiserId
        that.disAccountParams.advertiserId = String(row.advertiserId)
        that.qdvalueLookhx = '请选择渠道名称'
        that.regionyhshx = '请选择分配优化师'
        that.regionyhsBtommHx = '请选择分配优化师'
        that.channelIdfpNew = row.channelId
        that.fpyhsSingle = []
        that.choiceDept = []
        that.account__form.fenpeiproductName = ''
        that.account__form.costumerType = ''
        that.getDeptfpTree()
      }
    },

    //点击变更按钮--回显
    editTobeAllocatd(row){
      let that = this
      that.materialTab()
      that.getIndustry()//一二级行业
      that.getHaveTaglist(row.advertiserId)
      that.hxfpzh = false //判断是变更还是待分配
      that.roleKey.forEach(function(e,i){
        that.fpAccountProduct()
        //经理
        if(e.indexOf('leader') !=-1){
          that.fpAccountProduct()
        }
      })
      that.dialogFormVisible_account = true //分配账户弹框显示
      if(that.hxfpzh == false){
        that.account__form.name = row.company
        that.account__form.regionID = row.advertiserId
        that.qdvalueLookhx = row.channel
        that.regionyhsBtommHx = row.lookUser
        that.disAccountParams.advertiserId = String(row.advertiserId)
        that.disAccountParams.lookUserDeptId = row.lookUserDeptId
        that.disAccountParams.lookChannel = row.channel
        that.channelIdfpNew = row.channelId
        that.getDeptfpTree()
        let userList = row.userList
        that.disAccountParams.userList.length = userList.length
        that.disAccountParams.userList = []
        userList.forEach(function(e,i){
          if(i == 0){
            that.disAccountParams.userList.push({
              dept:e.dept,
              deptName:e.deptName,
              userId:e.userId,
              main:1
            })
          }else{
            that.disAccountParams.userList.push({
              dept:e.dept,
              deptName:e.deptName,
              userId:e.userId,
              main:0
            })
          }
          // if(row.channelId == 6){
            that.fpyhsSingle[e.userId] = '华为'+'-'+e.deptName+'-'+e.username+'-'+e.userId
          // }
          that.choiceDept[i] = e.deptName
          that.getFpsingleList(e.dept)
        })
        
        // if(row.onFlag == '0'){
        //   that.account__form.costumerType = 0
        // }else if(row.onFlag == '1'){
        //   that.account__form.costumerType = 1
        // }

        if(row.onFlag == '星火'){
          that.account__form.costumerType = '星火'
        }else if(row.onFlag == '燎原'){
          that.account__form.costumerType = '燎原'
        }else if(row.onFlag == '年框'){
          that.account__form.costumerType = '年框'
        }else if(row.onFlag == '飞跃A'){
          that.account__form.costumerType = '飞跃A'
        }
        that.account__form.taskName = row.task
        that.disAccountParams.onFlag = row.onFlag
        that.account__form.firstHangye = row.firstIndustryName
        that.account__form.secondHangye = row.secondIndustryName
        that.disAccountParams.firstIndustryName = row.firstIndustryName
        that.disAccountParams.secondIndustryName = row.secondIndustryName
        that.account__form.fenpeiproductName = row.pid//回显产品名称
        that.disAccountParams.proId = row.pid//回显的产品id
      }
    },

    //获取素材标签回显
    getHaveTaglist(ownerId){
      let that = this
      that.haveTagListParams.channelId = 5
      that.haveTagListParams.ownerId = ownerId
      $http.axios.post("/api/operationManagement/accountTag/getView",that.haveTagListParams).then(res=> {
        if(res.data.code == 200){
          that.haveTaglist = res.data.data
          that.account__form.addmarterialValue = []
          let newtagIds = []
          that.haveTaglist.forEach(function(e,i){
            that.account__form.addmarterialValue.push(e.id)
            newtagIds.push(e.id)
          })
          that.disAccountParams.tagIds = String(newtagIds)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change一级行业
    firstGong(val){
      let that = this
      that.account__form.secondHangye = ''
      that.disAccountParams.firstIndustryName = val
      that.firstHangyeOptions.forEach(function(e,i){
        if(e.name == val){
          that.secondHangyeOptions = e.children
        }
      })
    },
    firstGongname(val){
      let that = this
      that.ruleForm.secondName = ''
      that.secondNameoptions = []
      that.firstNameoptions.forEach(function(e,i){
        if(e.name == val){
          that.secondNameoptions = e.children
        }
      })
    },
    firstGongnameForm(val){
      let that = this
      that.ownerForm.secondInduName = ''
      that.secondNameoptions = []
      that.firstNameoptions.forEach(function(e,i){
        if(e.name == val){
          that.secondNameoptions = e.children
        }
      })
    },
    //change二级行业
    secondGong(val){
      this.disAccountParams.secondIndustryName = val
    },
    secondGongname(val){
      // this.accountParams.secondName = val
    },
    secondGongnameForm(val){
      // this.accountParams.secondName = val
    },

    //一级二级行业接口
    getIndustry(){
      let that = this
    },
    getIndustrySelect(){
      let that = this
    },

    //单个分配取消
    cancel(dangefenpei){
      this.dialogFormVisible_account = false
      this.$refs.dangefenpei.resetFields()
    },

    //弹框中的分配优化师获取数据
    changeOptimizer(event,i){
      let that = this
      that.disAccountParams.userList[i].deptName = String(event.depyName)
      that.disAccountParams.userList[i].userId = String(event.userId)
      that.fpyhsSingle[event.userId] = String(event.string)
      that.needUserId = event.userId
      if(i == 0){
        that.disAccountParams.userList[0].main = 1
      }else{
        that.disAccountParams.userList[i].main = 0
      }
    },

    //change客户类型
    changeCustomer(val){
      this.disAccountParams.onFlag = String(val)
    },

    //分配账户弹框点击确定按钮
    sureTobeAllocatd(dangefenpei){
      let that = this
      that.disAccountParams.onFlag = that.account__form.costumerType
      that.disAccountParams.proId = that.account__form.fenpeiproductName
      that.disAccountParams.task = that.account__form.taskName
      that.$refs.dangefenpei.validate((valid) => {
        if (valid) {
          $http.axios.post("/api/hwat/account/edit",that.disAccountParams).then(function(response) {
            if(response.data.code == 200){
              that.dialogFormVisible_account = false
              that.$refs.dangefenpei.resetFields()
              that.getAccountList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          return false;
        }
      })
      // that.roleKey.forEach(function(e,i){
      //   if(e.indexOf('director') !=-1){
      //     console.log('director')
      //     $http.axios.post("/api/hwat/account/edit",that.disAccountParams).then(function(response) {
      //       if(response.data.code == 200){
      //         that.dialogFormVisible_account = false
      //         that.$refs.dangefenpei.resetFields()
      //         that.getAccountList()
      //       } else {
      //         that.$message({
      //           showClose: true,
      //           message: response.data.msg,
      //           type: 'error'
      //         })
      //       }
      //     })
      //   }else if(e.indexOf('leader') != -1){
      //     console.log('leader')
      //     that.disAccountParams.onFlag = that.account__form.costumerType
      //     that.disAccountParams.proId = that.account__form.fenpeiproductName
      //     that.$refs.dangefenpei.validate((valid) => {
      //       if (valid) {   
      //         $http.axios.post("/api/hwat/account/edit",that.disAccountParams).then(function(response) {
      //           if(response.data.code == 200){
      //             console.log(response)
      //             that.dialogFormVisible_account = false
      //             that.$refs.dangefenpei.resetFields()
      //             that.getAccountList()
      //           } else {
      //             that.$message({
      //               showClose: true,
      //               message: response.data.msg,
      //               type: 'error'
      //             })
      //           }
      //         })
      //       } else {
      //         return false;
      //       }
      //     })
      //   }else{
      //     console.log(123456)
      //     that.$refs.dangefenpei.validate((valid) => {
      //       if (valid) {
      //         $http.axios.post("/api/hwat/account/edit",that.disAccountParams).then(function(response) {
      //           if(response.data.code == 200){
      //             that.dialogFormVisible_account = false
      //             that.$refs.dangefenpei.resetFields()
      //             that.getAccountList()
      //           } else {
      //             that.$message({
      //               showClose: true,
      //               message: response.data.msg,
      //               type: 'error'
      //             })
      //           }
      //         })
      //       } else {
      //         return false;
      //       }
      //     })
      //   }
      // })
    },

    //新增分配部门和分配优化师
    newAddListBtn(){
      this.distributionOptionsfp = {}
      this.disAccountParams.userList.push({
        dept:'',
        deptName:'',
        userId:''
      })
    },

    //删除分配部门和分配优化师
    removeRow(index) {
      if (index >= 0) {
        this.disAccountParams.userList.splice(index, 1);
      }
    },

    //获取查看人员信息
    getLookUser(event){
      this.disAccountParams.lookUserDeptId = event.deptId
      this.disAccountParams.lookUser = event.userId
      this.regionyhsBtomm = event.string
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    goToDetail(){
      this.$router.replace('/auth-management/userDetail')
    },

     //素材样式标签列表接口
    materialTab(){
      let that = this
      that.materialListParams.userId = that.setUserId
      $http.axios.post("/api/operationManagement/material/taglist",that.materialListParams).then(res=> {
        if(res.data.code == 200){
          that.resourcesTabPoptions = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //新增标签
    addTags(){
      let that = this
      that.addtagParams.createUser = that.setUserId
      that.addtagParams.tagName = that.addTagsValue      
      that.addtagParams.tagType = 2
      $http.axios.post("/api/operationManagement/material/addtag",that.addtagParams).then(res=> {
        if(res.data.code == 200){
          that.addTagDialog = false
          that.materialTab()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change标签
    materialTagIds(val){
      this.bigFpParams.tagIds = String(val)
    },
    materialTagIdsSingle(val){
      this.disAccountParams.tagIds = String(val)
    },

    //change数据更新开关
    changeDelFlag(val){
      console.log(val)
      let that = this
      $http.axios.get("/api/hwat/account/delSwitch?id="+val.advertiserId+"&type="+val.status).then(res=> {
        if(res.data.code == 200){
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //上传素材标签
    upload(params) {
      this.exprtVisible = true
      let _this = this,data = new FormData();
      $http.axios.defaults.timeout = 500000
      data.append('file',params.file);
      $http.axios.post("/api/operationManagement/material/miTagExcel",data,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        let blob = response.data
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = e => {
          let a = document.createElement('a')
          a.download = "素材标签.xlsx"
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          _this.exprtVisible = false
        }
      })
    },

    //自动分配账户
    autoDistribution(params){
      this.exprtVisible = true
      let _this = this,data = new FormData();
      $http.axios.defaults.timeout = 500000
      data.append('file',params.file);
      $http.axios.post("/api/hwat/account/uploadExclFile",data).then(function(response) {
        _this.$message({
          showClose: true,
          message: response.data,
          type: 'success'
        })
        _this.exprtVisible = false
        _this.$router.go(0)
      })
    },

    //分配账户中的产品名称下拉
    fpAccountProduct(){
      let that = this
      $http.axios.get("/api/hwat/account/productSelect").then(function(response) {
        that.fpProductNameOptions = response.data
      })
    },

    //查询中的产品名称下拉
    fpchaxunAccountProduct(){
      let that = this
      $http.axios.get("/api/hwat/account/productSelect").then(function(response) {
        that.fpcxProductNameOptions = response.data
      })
    },

     //新增产品名称
     addproductName(){
      let that = this
      $http.axios.get("/api/hwat/account/insertProduct?pName="+that.addproductNameValue).then(function(response) {
        if(response.data.code == 200){
          console.log(response)
          that.productNameDialog = false
          that.fpAccountProduct()
          that.fpchaxunAccountProduct()
          that.addproductNameValue = ''
        } else {
          that.$message({
            showClose: true,
            message:'产品名称重复',
            type: 'error'
          })
        }
      })
    },
    cancleProductName(){
      this.addproductNameValue = ''
      this.productNameDialog = false
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      this.getAccountList()
    },

    //广告主服务类Excel
    ownerbutton(params){
      let _this = this,data = new FormData();
      _this.$refs.ownerupload.clearFiles()
      data.append('file',params.file);
      $http.axios.post("/api/hwat/account/uploadAccountExclFile",data).then(function(response) {
        if(response.data == 'upload fail , Please check the format of excel'){
          _this.$message({
            showClose: true,
            message: response.data,
            type: 'error'
          })
        }else{
          _this.$message({
            showClose: true,
            message: response.data,
            type: 'success'
          })
        }
        _this.getAccountList()
        // _this.$router.go(0)
      })
    },

    //二代服务类excel
    secondbutton(params){
      let _this = this,data = new FormData();
      _this.$refs.erdaiupload.clearFiles()
      data.append('file',params.file);
      $http.axios.post("/api/hwat/account/uploadSignExclFile",data).then(function(response) {
        if(response.data == 'upload fail , Please check the format of excel'){
          _this.$message({
            showClose: true,
            message: response.data,
            type: 'error'
          })
        }else{
          _this.$message({
            showClose: true,
            message: response.data,
            type: 'success'
          })
        }
        _this.getAccountList()
        // _this.$router.go(0)
      })
    }





  }
}
</script>

<style lang="scss" scoped>
  .userTable{
    margin-top:25px;
  }
  .userTable .el-table .cell{
    text-align:center;
  }
  .el-table th{
    color:#1b1f23;
    background:$poc-color-primary-deputy;
  }
  .footer_paging{
    margin:20px 0 4px;
  }
  .footer_totalNums{
    font-size:12px;
    color:#666;
    padding-left:5px;
    padding-right:20px;
  }
  .footerPaging {
    float:right;
  }
  .userBtn{
    padding:0 5px;
    cursor:pointer;
  }
  .userBtn_delete{
    color:red;
  }
  .distributionAccount .el-form-item,.fpAccounts .el-form-item{
    display:block;
  }
  .el-form-item--mini.el-form-item{
    margin: 0 5px 18px;
  }
  .deptNameList{
    padding:0 5px;
  }
  .newAddfpStyle{
    position: relative;
  }
  .newReduceBtnStyle{
    position:absolute;
    top:50%;
    left:250px;
    transform: translate(0,-50%);
  }
  .newAddBtnStyle{
    margin-left:60px;
    margin-bottom:20px;
  }
  .redstar{
    position: relative;
  }
  .starStyle{
    position:absolute;
    color:red;
    font-size:10px;
    left:5px;
    top:10px;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
  }
  .channel_oppo,.channel_vivo{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_vivo{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }
  .uploading{
    display:block;
    text-align:center;
    font-size:50px;
    margin-bottom:35px;
  }
  /* 新版 */
  .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    ::v-deep .userList .el-dialog__header{
      padding-top:45px!important;
      font-size:14px!important;
      font-weight:500!important;
    }
    .distribNewStyle{
      background:#F5F7F6;
      padding:10px;
      box-sizing: border-box;
    }
    ::v-deep .distribNewStyle .el-input__inner{
      border:none!important;
      width: 420px;
    }
    .newNetStyle{
      position: relative;
      box-sizing: border-box;
      border:1px dashed #DCEEE8;
      padding:10px;
      border-radius:4px;
      margin:10px 0;
    }
    .newNetStyleIn_inline{
      display:inline-block;
      vertical-align: middle;
      width:50%;
      background:#F5F7F6;
      padding:10px;
      box-sizing: border-box;
    }
    .newNetStyleIn_inline1{
      background:#F5F7F6;
      padding:10px;
      box-sizing: border-box;
    }
    ::v-deep .newNetStyle .el-form-item{
      margin:0!important;
    }
    .newRedStar{
      display: inline-block;
      vertical-align: middle;
      width:65px;
    }
    .newRedStar i,.newRedStar span{
      display: inline-block;
      vertical-align: middle;
      font-size:12px;
      color:#330000;
    }
    .newRedStar i{
      color:red;
      padding-right:5px;
    }
    .addConditions{
      width:100%;
      height: 27px;
      line-height:27px;
      text-align:center;
      border-radius: 4px;
      border: 1px dashed #DCEEE8;
      font-size:12px;
      color:#48916F;
      margin-bottom:15px;
      cursor: pointer;
    }
    .deleteBtn{
      position: absolute;
      top:-10px;
      right:-10px;
      cursor: pointer;
    }
    .deleteBtnicon{
      color:#f12323;
      font-size:20px;
    }
    .tabdistribNewStyle{
      padding-left:27px;
    }
    .tabstarStyle{
      left:35px;
    }
    .tabNew{
      display: inline-block;
    }
    ::v-deep .tabdistribNewStyle .el-input__inner{
      width:335px!important;
    }
    ::v-deep .tabProduct .el-input__inner{
      width:310px!important;
    }
    .topDistance{
      padding-top:400px;
    }
</style>
