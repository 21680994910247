<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>计划管理</el-breadcrumb-item>
              <el-breadcrumb-item>账户选择</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>  
        <div class="chanelTab">
          <span class="channel_mi" :class="item.id==7?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=7&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="" prop="">
                  <el-input v-model="customerName" placeholder="请输入账户名称"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-input v-model="accountUserName" placeholder="请输入广告主ID"></el-input>
                </el-form-item>
              </el-form> 
              <el-button type="primary" class="newQuery" size="small" @click="userStatusFn" >查询</el-button>
              <el-button type="info" size="small" plian @click="resetForm">重置</el-button>
            </div>
          </div>
          <div class="materiaContent">
             <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%;cursor: pointer;"
              fit
              @cell-click="clickcell"
              :cell-class-name="tableCellClassName"
              :cell-style="setRowStyle"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55"
                fixed>
              </el-table-column>
              <el-table-column
                prop="accountName"
                label="账户名称"
                width="240"
                fixed="left">
              </el-table-column>
              <el-table-column
                prop="advertiserId"
                label="广告主ID"
                width="240"
                fixed="left">
              </el-table-column>
              <el-table-column
                align='center'
                v-for="(item,index) in titleList" :key="index"
                :prop="item.prop"
                :label="item.name"
                width="200"
                show-overflow-tooltip>
                <template v-if='item.prop=="type"' v-slot="scope">
                  <el-switch
                    v-model="scope.row.type"
                    class="switchStyle"
                    :active-value="3"  
                    :inactive-value="2"
                    active-text=""
                    inactive-text=""
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    @change="updateAccountSwitchFn(scope.row)">
                  </el-switch>
                </template>
                
                <template v-slot="scope" v-else-if='item.prop=="cashCost"'>
                  <span >
                    {{scope.row.cashCost}}
                  </span>
                </template>
                <template v-slot="scope" v-else-if='item.prop=="cashDayBudget"'>
                  <span >
                    {{scope.row.cashDayBudget}}
                    
                  </span>
                </template>

              </el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                :show-overflow-tooltip="true"
                label="操作"
                width="240px">
                <template>
                  <div>
                    <el-button class="solid-tag-success" plain size="mini" data-type = 'planPage' >计划</el-button>
                    <el-button class="solid-tag-warning" plain size="mini" data-type = 'tiao' >调价</el-button>
                    <el-button class="solid-tag-harmful" plain size="mini" data-type = 'startStop' >启停</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="clearfix">
            <div class="allOperation fL">
            </div>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
            
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
      <!-- 预警设置弹框 -->
      <costSetDialog  v-if="costSetDialogIs" :visible.sync="costSetDialogIs" ref = 'costSetDialogIs' :maxNum = "maxNum" :warningFieldType = "warningFieldType" :costTitle = "costTitle" :switchValuePrps = "switchValuePrps" :yulanList = "yulanList"  :userContent = 'userContent' :planType = "planType" :pubVue="pubVue"></costSetDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import Qs from 'qs';
import { $http } from '@/api/http';
import costSetDialog from '@/views/dialog/costSetAppDialog'//账户消耗预警设置

export default {
  components: {
    costSetDialog,
  },
  data() {
    return {
      tokenTypeList:[],//判断是否有服务类
      pubVue:this,
      costSetDialogIs:false,//消耗设置弹框
      userContent:{},//登录用户信息&弹框默认
     
      titleList:[
        {name:'公司名称',prop:'company'},
        {name:'今日消耗',prop:'cashCost'},
        {name:'自定义预算',prop:'cashDayBudget'},
        {name:'账户日预算',prop:'realDayBudget'},
        {name:'自定义预算开关',prop:'type'},
        // {name:'账户预警',prop:'type'}
      ],//title名称
      channel:'',
      channeln:'',
      customerName:'',//搜索框--客户名称
      tableData:[],//查询有权限的所有帐号接口回显
      show:false,
      switchValuePrps:'',//账户选择列表自定义消耗弹框状态值
      
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      userId:'',//用户ID
      userName:'',
      deptId:'',
      ownerId:'',
      ownerIdList:[],//选中的用户
      yulanList:[],
      planType:10,
      dialogTitle:'批量日限额',
      myHeaders: {Authorization:''},
      accountParams:{},
      tokenTypes:'',//查询条件：账户类型
      storeTokenType:[],//??
      accountUserName:'',//列表搜索中的用户名
      accountSelectionListShow:true,//用户是否有权限
      channelTitleList:[],//权限名称列表
      channelIdNew:'',//用户权限ID:3=>OPPO;4=>VIVO;5=>小米
      DN:'yyb',//域名:Domain Name的缩写
      routerList:'/YybAccount-management',//
      costTitle:'',
      maxNum:'',//日预算回显
    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.getId()//获取token
    this.getuserChannel()//获取用户的权限
  },
  methods: {
    //channel的tab切换=>3:OPPO;4:VIVO;5:小米;6:华为;7:应用宝
    clickChangeChannel(channelId){
      let channelNameList = {3:'planManagement.AccountSelection',4:'VivoAccountManagement.AccountSelectionVivo',5:'MiAccountManagement.AccountSelectionMi',6:'HwAccountManagement.AccountSelectionHw',8:'planManagement.AccountSelectionApple'}
      this.$router.push({name:channelNameList[channelId]})
    },
    getId(){//获取token
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
    //重置
      resetForm(){
        let _this = this
        _this.customerName = ''
        _this.listFn()
      },     
      //获取用户的权限
    getuserChannel(){
      let that = this
      $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userId).then(function(response) {
        if(response.data.data!=undefined&&response.data.data.length!=0){
          that.channelTitleList = response.data.data
          that.accountSelectionListShow = true
          that.channelIdNew = response.data.data[0].id
          that.listFn()
        }else{
          that.accountSelectionListShow = false
          that.$message({
          showClose: true,
          message: "您的操作权限不足",
          type: 'error'
        })
        }
      })
    },

   

  
    //获取查询有权限的所有帐号接口
    listFn(pageNum,name){
      let _this = this,pageSize
      _this.accountParams={
        name : name || '',
        accId : _this.accountUserName
      }
      pageNum = pageNum|| 1
      pageSize= _this.pageCount,
      $http.axios.post("/api/ybat/account/accountCostList?pageSize="+pageSize+'&pageNum='+pageNum,_this.accountParams).then(res=> {
        if(res.data.code==200){
          // res.data.rows = [{
          //   "accountName": "L普育吧科教技术(北京)有限公司",
          //   "advertiserId": "24245054",
          //   //"appid": "C102521543",
          //   "budgetType": 1,
          //   "cashCost": "0.00",
          //   "cashDayBudget": "0.00",
          //   "channelId": "7",
          //   //"cname": "客户名称，此版暂不展示",
          //   "company": "L普育吧科教技术(北京)有限公司",
          //   "costType": 1,
          //   "remType": 1,
          //   // "tokenType": "1",
          //   "type": 1
          // }]

          // 数据修改，余额、消耗、日预算初始值如果为空的状态
          res.data.rows.forEach(element => {
            element.channelId = '应用宝'
           
            if(element.cashCost== null){
              element.cashCost = 0.00
            }else if(isNaN(element.cashCost)){
              element.cashCost = '--'
            }else{
              element.cashCost = Number(element.cashCost).toFixed(2)
            }
            if(element.cashDayBudget== null){
              element.cashDayBudget = "未设置"
            }else{
              element.cashDayBudget = Number(element.cashDayBudget).toFixed(2)
            }
            if(element.realDayBudget == null){
              element.realDayBudget = "未设置"
            }else{
              element.realDayBudget = Number(element.realDayBudget).toFixed(2)
            }
            if(element.tokenType == '0'){//业绩类：1；服务类：2服务类隐藏计划、调价、启停按钮
              element.tokenTypeName = "全部"
            }else if(element.tokenType == '1'){
              element.tokenTypeName = "业绩类"
            }else if(element.tokenType == '2'){
              element.tokenTypeName = "服务类"
            }
            if(element.channelId == null){
              _this.titleList.forEach(function(e,i){
              })
            }
          });
          _this.tableData = res.data.rows
          _this.totalRow = res.data.total
          _this.storeTokenType = []//??
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },

    // 选择账户状态
    userStatusFn(){
      let _this = this
      _this.listFn(1,_this.customerName,)
    },
    
    tableCellClassName({row, column, rowIndex, columnIndex}){
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index=rowIndex;
      column.index=columnIndex;
    },
    
    // 点击一个单元格
    clickcell(row, column, cell, event){
      let _this = this
      let type = event.target.parentNode.dataset.type,routerList = _this.routerList
      _this.maxNum = ''
      if(column.property=='cashDayBudget'){
        _this.costSetDialogIs = true
         row.type==3?_this.switchValuePrps = '0':_this.switchValuePrps = '1'
        _this.userContent.ownerId = row.advertiserId//账户ID/广告主ID
        _this.userContent.ownerName = row.accountName
        _this.ownerIdList = [] 
        _this.ownerIdList.push({ 'ownerId':row.advertiserId,'ownerName':row.accountName})

        _this.costTitle = '设置消耗自定义预算'
        _this.warningFieldType = 0
        row.cashDayBudget == '未设置'?_this.maxNum = '':_this.maxNum = Number(row.cashDayBudget)//获取日预算的值

        console.log(_this.switchValuePrps)
      }else if(column.property=='realDayBudget'){//日预算缺字段
        _this.costSetDialogIs = true
         row.type==3?_this.switchValuePrps = '0':_this.switchValuePrps = '1'
        _this.userContent.ownerId = row.advertiserId//账户ID/广告主ID
        _this.userContent.ownerName = row.accountName
        _this.ownerIdList = [] 
        _this.ownerIdList.push({ 'ownerId':row.advertiserId,'ownerName':row.accountName})

        _this.costTitle = '设置日预算'
        _this.warningFieldType = 1

        row.realDayBudget == '未设置'?_this.maxNum = '':_this.maxNum = Number(row.realDayBudget)//获取日预算的值
      }else if(column.label == '操作'){
        if(type == 'planPage'){
          _this.goPlan(routerList+'/adPlanYyb',row.advertiserId,row.accountName)
        }else if(type =='tiao'){
          _this.goPlan(routerList+'/PriceModifyAdvertYyb',row.advertiserId,row.accountName)
        }else if(type == 'startStop'){
          _this.goPlan(routerList+'/StartStopYyb',row.advertiserId,row.accountName)
        }
      }
      if(event.target.parentNode.dataset.type=="selection"){//event.target.parentNode.dataset.type
          this.$refs.multipleTable.toggleRowSelection(row)
      }
      
    }, 
    // 分页
    handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.listFn(val)
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let ownerIdList = [], yulanList = []
      _this.tokenTypeList = []
      val.forEach(obj=>{
        ownerIdList.push({ 'ownerId':obj.advertiserId,'ownerName':obj.accountName})
        _this.tokenTypeList.push(obj.tokenType)
        yulanList.push({ 'ownerId':obj.advertiserId,'ownerName':obj.accountName,'price':obj.cashDayBudget,'upPriceStatus':0})
      })
      _this.ownerIdList = ownerIdList
      _this.yulanList = yulanList
    },
    // 账户列表页账户预警开关修改
    updateAccountSwitchFn(row){
      let _this = this,DN = this.DN
      let params = {ownerId:row.advertiserId,totalSwitch:row.type,userId:this.userId,userName:this.userName,deptId:this.deptId} 

      $http.axios.post("/api/"+DN+"/accountWarning/updateAccountSwitch",params).then(res=> {
        if(res.data.code == 200){

        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })

    },  
    
    // 计划、调价、启停页面跳转
    goPlan(url,id,name){//
      this.$router.push({
        path: url,
        query: {
          id: id,
          name:name,
        }
      })
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 4){
        return 'color: #6495ed' 
      }else if(row.columnIndex == 5){
        return 'color: #6495ed' 
      }else if(row.columnIndex == 6){
        return 'color: #6495ed' 
      }else{
        return 'color: #606266' 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .el-button--small{
    margin-right: 10px;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
  }
  .channel_mi{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_mi{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }
  .showOrNo{
    display: inline-block;
  }
  .statusOtherBtnwei{
    padding:3px 8px;
    background:rgba(230,162,60,.1);
    color:#E6A23C;
  }
  /*新版*/
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
 
</style>