import moment from 'moment'
export default function dateValidator(rule, value, callback) {
   const dateType = rule.dateType || 'days'
   const num = rule.num || 31
   const dateString = rule.dateString || '天'
   const message = rule.message || `时间跨度不得超过${num}${dateString}`
   // 校验规则
   if (value && value.length === 2) {
    const start = value[0]
    const end = value[1]
    // 比较两个时间差 http://momentjs.cn/docs/displaying/difference.html
    if (moment(end).diff(moment(start), dateType, true) > num) {
     callback(new Error(message))
     return
    }
   }
   callback()
  }