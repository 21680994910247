import Apply from '@/views/user/Apply';
import Password from '@/views/user/Password'

export default {
  routes: [
    {
      path: 'apply',
      name: 'user.Apply',
      component: Apply,
    },
    {
      path: 'password',
      name: 'user.Password',
      component: Password,
    },
  ],
};
