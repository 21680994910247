<template>
    <div class="authority common-page">
      <PocCardTable>
        <template slot="headerTitle">
          <div class="chanelTab">
            <!-- <span class="channel_oppo" @click="clickChangeChannel">OPPO</span>
            <span class="channel_vivo channelActive">VIVO</span> -->
<!-- 
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="OPPO" name="first">OPPO</el-tab-pane>
              <el-tab-pane label="VIVO" name="second">VIVO</el-tab-pane>
              <el-tab-pane label="MI" name="third">MI</el-tab-pane>
            </el-tabs> -->
            <!-- <el-tabs v-model="editableTabsValue" type="card">
              <el-tab-pane
                :key="item.name"
                v-for="item in editableTabs"
                :label="item.title"
                :name="item.name"
              >
              </el-tab-pane>
            </el-tabs> -->
          <span class="channel_vivo" :class="item.id==channelId?'channelActive':''" v-for="(item,i) in editableTabs" :key="i" @click="item.id!=channelId&& clickChangeChannel(typeId,item.id)">{{item.name.toUpperCase()}}</span>

          </div>
          <div class="faqWrap">
            <h2 class="faq">FAQ问题列表</h2>
            <el-container>
              <el-aside>
                <el-menu :default-openeds="['1']" :default-active="menuActive">
                  <el-submenu index="1">
                    <template slot="title"><i class="el-icon-message"></i><span style="font-weight:700;">功能模块</span></template>
                    <div class="addfenlei"><el-button type="primary" size="small" v-show="roleKeys.indexOf('admin') != -1" @click="addType">新建分类</el-button></div>
                    <el-menu-item-group v-for="(item,i) in totalFaqList" :key="i">
                      <el-menu-item class="faqbtnFather" @click="enterTypeList(item.id)" :index="item.id">
                        <div class="faqbtnleft">{{item.name}}</div>
                        <div class="faqbtn" v-show="roleKeys.indexOf('admin') != -1">
                          <span class="el-icon-edit" @click="updatesType(item)"></span>
                          <span class="el-icon-delete" @click="deleteType(item.id)"></span>
                        </div>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                </el-menu>
              </el-aside>
              <el-container>             
                <el-main>
                  <div class="createQuestion" >
                    <el-form ref="form" :model="form" :inline="true" class="demo-form-inline" >
                      <el-form-item label="">
                        <el-input v-model="form.keyword" placeholder="请输入关键字" style="width:200px;"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="small" @click="searchKeyword">查询</el-button>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="small" v-show="roleKeys.indexOf('admin') != -1" @click="addQuestion">新建问题</el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-table
                    :data="tableData"
                    style="width: 100%"
                    :show-header="status"
                    default-expand-all>
                    <el-table-column type="expand">
                      <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand">
                          <el-form-item label="">
                            <!-- <p v-html="props.row.answer" style="font-size:16px;">{{props.row.answer}}</p> -->
                            <p v-html="props.row.answer" style="font-size:16px;"></p>
                          </el-form-item>
                        </el-form>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label=""
                      prop="name">
                      <template slot-scope="props">
                        <span style="font-size:16px;font-weight:700;">{{props.row.question}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label=""
                      prop="name"
                      width="80">
                      <template slot-scope="props">
                        <el-button type="primary" size="mini" v-show="roleKeys.indexOf('admin') != -1" @click="deleteQuestion(props.row.id)">删除</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-main>
              </el-container>
            </el-container>

            <!-- 新建分类弹框 -->
            <el-dialog title="新建分类" :visible.sync="addTypeVisible" width="40%">
              <el-input v-model="addTypeName" placeholder="请输入新建分类的名称"></el-input>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="addTypeSure">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 修改分类弹框 -->
            <el-dialog title="修改分类" :visible.sync="updateTypeVisible" width="40%">
              <el-input v-model="updateTypeName" placeholder="请输入新建分类的名称"></el-input>
              <div slot="footer" class="dialog-footer">
                <el-button @click="updateTypeVisible = false">取 消</el-button>
                <el-button type="primary" @click="updateTypeSure">确 定</el-button>
              </div>
            </el-dialog>
            

          </div>
        </template>
      </PocCardTable>
    </div>
  </template>
  
 
<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'faq',
  data() {
    return {
      editableTabs:[{
          id: '3',
          name: 'OPPO',
        }, {
          id: '4',
          name: 'VIVO',
        }, {
          id: '5',
          name: 'MI',
        }, {
          id: '6',
          name: '华为',
        }],
      totalFaqList:[],//列表集合
      addTypeVisible:false,//新建分类的弹框
      addTypeName:'',//新建分类的value
      updateTypeVisible:false,//修改分类的弹框
      updateTypeName:'',//修改分类的value
      updateTypeId:'',//修改分类的id获取
      tableData: [],
      
      typeId:'',
      status:false,
      roleKeys:'',
      form:{
        keyword:''
      },
      idPart:'',
      menuActive: '1-1',
      channelId:'',
      urlList : {3:'system',4:'viat',5:'xmat',6:'hwat'}//OPPO->system;VIVO->viat;MI->xmat
      // ,7:'apple'

    }
  },
  mounted() {
    this.roleKeys = String(this.$sessionStorage.getItem(config.ROLES))
    this.typeId = this.$route.query.id
    this.channelId = this.$route.query.channelId
    if(this.channelId == undefined){
      this.channelId = 3
    } 
    if(this.typeId == undefined){
    this.enterTypeList(3)
    }else{
    this.enterTypeList(this.typeId)
    }
    console.log(this.typeId)
  },
  methods: {
    //channel的tab切换
    clickChangeChannel(typeId,channelId){
      console.log(typeId)
      this.channelId = channelId
      this.$router.push({ path: '/auth-management/FaqVivo', query: {  id: Number(typeId) ,channelId:channelId} });   
      this.enterTypeList(this.typeId)
      this.form.keyword = ''
    },

    //新建分类
    addType(){
      this.addTypeVisible = true
    },

    //确定新建分类
    addTypeSure(){
      let that = this
      $http.axios.get("/api/"+that.urlList[that.channelId]+"/faq/createType?name="+that.addTypeName).then(res=> {
        if(res.data.code == 200){
          that.addTypeVisible = false
          that.enterTypeList(this.typeId)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改分类
    updatesType(val){
      this.updateTypeVisible = true
      this.updateTypeId = val.id
      this.updateTypeName = val.name
    },

    //修改分类的确定
    updateTypeSure(){
      let that = this
      $http.axios.get("/api/"+that.urlList[that.channelId]+"/faq/updateType?id="+that.updateTypeId+"&name="+that.updateTypeName).then(res=> {
        if(res.data.code == 200){
          that.updateTypeVisible = false
          that.enterTypeList(this.typeId)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //删除分类
    deleteType(val){
      let that = this
      $http.axios.get("/api/"+that.urlList[that.channelId]+"/faq/delType?id="+val).then(res=> {
        if(res.data.code == 200){
          that.enterTypeList(this.typeId)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    enterTypeList(val){
      console.log(666)
      let that = this
      // that.isActive = true
      that.typeId = val
      $http.axios.get("/api/"+that.urlList[that.channelId]+"/faq/listfaq?channelId="+that.channelId+"&type="+val).then(res=> {
        that.totalFaqList = res.data
        res.data.forEach(function(e,i){
          if(val == e.id){
            that.tableData = e.faqList
          }
        })
      })
    },

    //删除问题
    deleteQuestion(id){
      console.log(id)
      let that = this
      $http.axios.get("/api/"+that.urlList[that.channelId]+"/faq/delfaq?id="+id).then(res=> {
        if(res.data.code == 200){
          that.$router.go(0)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //新建问题
    addQuestion(){
      this.$router.push({name:'authManagement.FaqQuestionsVivo',query:{id:this.typeId,channelId:this.channelId}})
    },

    //查询
    searchKeyword(){
      let that = this
      console.log(that.typeId)
      $http.axios.get("/api/"+that.urlList[that.channelId]+"/faq/listfaq?channelId="+that.channelId+"&type="+that.typeId+"&keyWord="+that.form.keyword).then(res=> {
        res.data.forEach(function(e,i){
          if(that.typeId == e.id){
            that.tableData = e.faqList
          }
        })
        console.log(that.tableData)
      })
    },

  }
}
</script>

<style lang="scss" scoped>
  .faqWrap{
    width:100%;
    height:100%;
    position: relative;
    overflow-y: scroll;
  }
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
  }
  .el-aside {
    color: #333;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .faq{
    font-size:18px;
    font-weight:700;
  }
  .faqbtnFather{
    width:300px;
    position:relative;
  }
  .faqbtnleft,.faqbtn{
    display:inline-block;
    vertical-align:middle;
  }
  .faqbtnleft{
    width:180px;
    padding-right:20px;
    box-sizing: border-box;
  }
  .faqbtn{
    position:absolute;
    top:0;
    right:10px;
    width:100px;
    text-align:right;
    text-align:center;
  }
  .faqbtn span{
    margin:0;
    padding:0;
    display:inline-block;
    vertical-align:middle;
    font-size:12px;
    width:20px;
    height:20px;
    line-height:20px;
    border-radius: 20px;
    color:#fff;
    margin:0 2px;
    background-color: #66856d;
  }
  .addfenlei{
    text-align:right;
    padding:0 10px;
  }
  .createQuestion{
    padding-top:15px;
    text-align:right;
  }
  .demo-table-expand .el-form-item{
    width:100%!important;
  }
  html,body,#app,.el-container{
    width:100%;
    /*设置内部填充为0，几个布局元素之间没有间距*/
    padding: 0px;
      /*外部间距也是如此设置*/
    margin: 0px;
    /*统一设置高度为100%*/
    height: 100%;
  }
  .el-submenu {
    text-align: left;

    &.is-active .el-submenu__title {
      color: $--color-primary;
    }

    .el-menu-item.is-active {
      /* background: $poc-color-primary-deputy; */
      border-right: 4px solid $--color-primary;
      background:rgba(102,133,109,0.2);

      a {
        color: $--color-primary;
      }
    }
  }
  /* .el-menu { width: 100%; border-right: none !important; } .el-menu-item [class^="el-icon-"] { color: #333; } .el-menu .el-submenu__title i { color: #333; } .el-menu-item.is-active { background: #f8e8ea; border-right: 3px solid #bb162b; } .el-submenu.is-opened .el-menu { background: #fafafa; } */
  /* oppo和vivo的tab切换 */
  .chanelTab{
      margin-bottom:30px;
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:36px;
      line-height:36px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#66856d;
      border-bottom:2px solid #66856d;
    }
</style>
