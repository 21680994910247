import PublicisCommon from 'publicis-common';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const { Vue, Vuex } = PublicisCommon;
const state = {
  // 用户信息
  userInfo: '',
};

Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,//原始的样子
  // mutations = {
  //   handleClick: (state, tabName) => {
  //     state.tabName = tabName
  //     localStorage.setItem('tabName', tabName)
  //   }
  // }//修改的但是目前有问题，等后期项目接口调整的时候再深研究
});
