
<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class="hl_headers sticky" style="margin-bottom: 0">
          <div class="hl_breadCrumb hl_headerLeft">
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>内容运营</el-breadcrumb-item>
              <el-breadcrumb-item>日报模板库</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-upload
              ref="upload"
              :headers="myHeaders"
              action="/api/dataReport/reportModel/uploadExcel"
              accept=".xlsx"
              :limit="1"
              :on-success="onSuccess"
              :show-file-list="false"
            >
              <el-button slot="trigger" size="small" type="primary"
                >+ 新建模板</el-button
              >
            </el-upload>
            <el-button
              slot="trigger"
              size="small"
              type="primary"
              @click="uploadTip"
              >模板表头字段说明</el-button
            >
          </div>
        </div>
        <!-- 模板表头字段说明弹框 -->
        <el-dialog
          width="600px"
          title="模板表头字段说明"
          class="icon_custom_popup"
          :visible.sync="showTip"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/ZDYLicon.png" />
          <el-collapse v-model="activeNames">
            <el-collapse-item
              v-for="(item, index) in explain"
              :key="index"
              :title="item.name"
              :name="index + 1"
            >
              <div class="table">
                <div
                  class="news"
                  v-for="(items, indexs) in item.list"
                  :key="indexs"
                >
                  {{ items }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div slot="footer">
            <el-button type="primary" @click="showTip = false">关 闭</el-button>
          </div>
        </el-dialog>
        <div class="line_function_contant">
          <div
            :class="item.id == 8 ? 'active' : ''"
            v-for="(item, i) in channelTitleList"
            :key="i"
            @click="item.id != 8 && clickChangeChannel(item.id)"
          >
            {{ item.name.toUpperCase() }}
          </div>
        </div>
        <div class="radius_function_contant">
          <div class="active">时报</div>
          <div @click="goToDaily">日报</div>
        </div>
        <div class="search_container">
          <el-form :inline="true" ref="ruleForm">
            <el-form-item label="">
              <el-input
                v-model="dayName"
                clearable
                placeholder="请输入报表名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="adId"
                clearable
                placeholder="请输入广告主名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="sendName"
                clearable
                placeholder="请输入接收人"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="copyName"
                clearable
                placeholder="请输入抄送人"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                clearable
                placeholder="请选择报表开关状态"
                v-model="channelm"
              >
                <el-option label="开启报表状态" value="0">开</el-option>
                <el-option label="关闭报表状态" value="1">关</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="
                  listModelFn(page, dayName, adId, sendName, copyName, channelm)
                "
                >查询</el-button
              >
              <el-button type="info" size="small" @click="resetForm"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="materiaContent">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :cell-class-name="tableCellClassName"
            @cell-click="clickcell"
          >
            <el-table-column width="170px" show-overflow-tooltip label="编号">
              <template v-slot="scope">
                {{ scope.row.id }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right-end"
                  v-if="scope.row.fileSizeWarnStatus"
                >
                  <div
                    slot="content"
                    v-html="
                      scope.row.fileSizeWarnStatus == 1
                        ? '文件大小目前已超过10MB，请尽快处理！'
                        : '文件大小目前已超过18MB，目前已进行强制停用！'
                    "
                  ></div>
                  <i
                    class="el-icon-warning"
                    style="font-size: 14px"
                    :style="{
                      color:
                        scope.row.fileSizeWarnStatus == 1
                          ? '#F3920B'
                          : '#F12323',
                    }"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              min-width="120px"
              prop="name"
              show-overflow-tooltip
              label="名称"
            >
            </el-table-column>
            <el-table-column
              min-width="140px"
              prop="title"
              show-overflow-tooltip
              label="文件名"
            >
            </el-table-column>
            <el-table-column width="80px" label="状态">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="0"
                  :inactive-value="1"
                  @change="getBrandList(scope.row, 'status')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="90px" label="Excel">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.excelFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="getBrandList(scope.row, 'excelFlag')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="80px" label="图片">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.imgFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="getBrandList(scope.row, 'imgFlag')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="110px" label="零点推送">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.zeroFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="getBrandList(scope.row, 'zeroFlag')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              min-width="240px"
              v-for="(item, index) in titleList"
              :key="index"
              :prop="item.prop"
              :label="item.name"
              show-overflow-tooltip
            >
              <template v-if="item.prop == 'copySenderName'" v-slot="scope">
                <span>{{ scope.row.copySenderName || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="110px"
              prop="fileSize"
              show-overflow-tooltip
              label="文件大小(kb)"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              min-width="180px"
              label="广告主名称"
            >
              <template v-slot="scope">
                <span
                  v-for="(item, index) in scope.row.excelConfig"
                  :key="index"
                >
                  <span v-for="(items, indexs) in item" :key="indexs">
                    <span v-for="(itemd, indexd) in items.owners" :key="indexd">
                      {{ itemd.ownerName }}
                    </span>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="370" label="操作">
              <template slot-scope="scope">
                <!-- <el-button class="solid-tag-success" data-type="infoBtn">详情</el-button> -->
                <el-button class="solid-tag-instructions" data-type="xiuGai"
                  >修改</el-button
                >
                <el-button class="solid-tag-error" data-type="xiazai"
                  >下载</el-button
                >
                <el-button class="solid-tag-warning" data-type="bufa"
                  >补发</el-button
                >
                <el-dropdown @command="handleCommand($event, scope.row)">
                  <el-button class="solid-tag-warning" data-type="sendBtn"
                    >重发</el-button
                  >
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">全部发送</el-dropdown-item>
                    <el-dropdown-item command="2">接收人</el-dropdown-item>
                    <el-dropdown-item command="3">抄送人</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button class="solid-tag-death" data-type="zhuanrang"
                  >转让</el-button
                >
                <el-button class="solid-tag-error" data-type="delBtn"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 转让弹框 -->
        <el-dialog
          width="400px"
          title="转让"
          :close-on-click-modal="false"
          class="icon_custom_popup"
          :visible.sync="transferenceDialog"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form label-width="50px">
            <el-form-item label="转让人">
              <el-select
                v-model="transferencePerson"
                @change="changeTrans"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, i) in warningPersonOption"
                  :key="i"
                  :label="item.string"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="transferenceDialog = false">取 消</el-button>
            <el-button type="primary" @click="transferenceSure"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 补发弹框 -->
        <el-dialog
          width="470px"
          title="补发"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="reissueDialog"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form>
            <el-form-item>
              <el-date-picker
                v-model="reissuevalue"
                type="daterange"
                disabled
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="tips">
            温馨提示：
            <div style="margin-top: 6px">
              由于数据补发时间较长，请您耐心等待，请勿重复补发，造成文件损坏！
            </div>
          </div>
          <div slot="footer">
            <el-button @click="reissueDialog = false">取 消</el-button>
            <el-button type="primary" @click="reissueSure">确 定</el-button>
          </div>
        </el-dialog>
        <div class="footerPaging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleSizeChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageCount"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.totalRow"
          >
          </el-pagination>
        </div>
      </template>
    </PocCardTable>
    <dayTimeDialog
      :pubVue="pubVue"
      :visible.sync="dayTimeDialogIs"
      :path="path"
      :type="type"
      :keywords="keywords"
      :keywordsIs="keywordsIs"
      :dataSource="dataSource"
      :dataSourceIs="dataSourceIs"
      :title="title"
      ref="dayTime"
    ></dayTimeDialog>
    <dayInfoDialog
      :visible.sync="dayInfoDialogIs"
      :type="type"
      :title="title"
      ref="dayInfo"
    ></dayInfoDialog>
    <dayTimeXDialog
      :pubVue="pubVue"
      :visible.sync="dayTimeXDialogIs"
      :type="type"
      :title="title"
      ref="dayTimeXDialog"
      :keywordsIs="keywordsIs"
      :dataSourceIs="dataSourceIs"
    ></dayTimeXDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import formatDate from "@/utils/formatDate";
import dayTimeDialog from "@/views/dialog/dayTimeAsa";
import dayTimeXDialog from "@/views/dialog/dayTimeXAsaDialog";
import dayInfoDialog from "@/views/dialog/dayInfoAsaDialog";
import getUserList from "@/utils/getUserList"; //获取用户列表
export default {
  name: "Daily",
  components: {
    dayTimeDialog,
    dayInfoDialog,
    dayTimeXDialog,
  },
  data() {
    return {
      pubVue: this,
      fileList: [],
      dayTimeDialogIs: false,
      dayInfoDialogIs: false,
      dayTimeXDialogIs: false,
      tableData: [],
      publicPath: process.env.VUE_APP_BASENAME,
      // 分页
      page: 1, //当前页
      pageCount: 10, //每页大小
      totalRow: 0, //总条数
      totalPage: 0, //总页数
      title: "时报",
      dataSource: [],
      keywords: [],
      dataSourceIs: false,
      keywordsIs: false,
      path: "",
      type: 2,
      dayName: "",
      sendName: "",
      copyName: "",
      adId: "",
      channelm: "",
      titleList: [
        { name: "接收人", prop: "senderName" },
        { name: "抄送人", prop: "copySenderName" },
      ],
      myHeaders: { Authorization: "" },
      accountSelectionListShow: true, //用户是否有权限
      channelTitleList: [], //权限名称列表
      copyIds: "",
      showTip: false,
      activeNames: [1],
      transferenceDialog: false,
      warnNine: [],
      warnEigth: [],
      warningPersonOption: [],
      transferencePerson: "",
      getIds: "",
      changeUserId: "",
      changeDeptId: "",
      reissueDialog: false,
      reissuevalue: [],
      reissueId: "",
      explain: [
        {
          name: "账户",
          list: [
            "日期",
            "时段",
            "广告系列组ID",
            "广告系列组名称",
            "国家地区",
            "余额",
            "支出",
            "曝光次数",
            "点击次数",
            "下载次数",
            "新下载",
            "重新下载",
            "开启LAT的安装数",
            "关闭LAT的安装数",
            "点击率",
            "下载率",
            "ECPM均价",
            "CPT均价",
            "CPA均价",
          ],
        },
        {
          name: "广告系列",
          list: [
            "日期",
            "时段",
            "广告系列组ID",
            "广告系列组名称",
            "国家地区",
            // '开始日期', '结束日期', '广告系列剩余天数',
            "生命周期预算",
            "广告系列ID",
            "广告系列名称",
            "广告展示位置",
            "每日预算",
            "支出",
            "曝光次数",
            "点击次数",
            "下载次数",
            "新下载",
            "点击率",
            "下载率",
            "ECPM均价",
            "CPT均价",
            "CPA均价",
          ],
        },
        {
          name: "广告组",
          list: [
            "日期",
            "时段",
            "广告系列组ID",
            "广告系列组名称",
            "国家地区",
            "开始日期",
            "结束日期",
            "广告组ID",
            "广告组名称",
            // '搜索匹配',
            "支出",
            "曝光次数",
            "点击次数",
            "下载次数",
            "新下载",
            "重新下载",
            "开启LAT的安装数",
            "关闭LAT的安装数",
            "点击率",
            "下载率",
            "ECPM均价",
            "CPT均价",
            "CPA均价",
          ],
        },
        {
          name: "关键词",
          list: [
            "日期",
            "时段",
            "广告系列组ID",
            "广告系列组名称",
            "国家地区",
            "广告系列ID",
            "广告组ID",
            "广告组名称",
            "关键词ID",
            "关键词名称",
            "匹配类型",
            "搜索词",
            "点击出价",
            "建议出价范围",
            "支出",
            "曝光次数",
            "点击次数",
            "下载次数",
            "新下载",
            "重新下载",
            "开启LAT的安装数",
            "关闭LAT的安装数",
            "点击率",
            "下载率",
            "CPT均价",
            "CPA均价",
          ],
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
    this.userName = this.$sessionStorage.getItem(config.USERNAME);
    this.deptName = this.$sessionStorage.getItem(config.DEPTNAME);
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
    this.channelTitleList = this.$sessionStorage.getItem("channelTitleList");
    this.getuserChannel();
    this.getFpyjList328();
    // this.getFpyjList329();
    this.getId();
  },
  methods: {
    //获取用户的权限
    getuserChannel() {
      let _this = this;
      if (_this.channelTitleList != undefined && _this.channelTitleList != 0) {
        _this.accountSelectionListShow = true;
        let flag = _this.channelTitleList.every((obj) => {
          if (obj.id == 8) {
            return false;
          } else {
            return true;
          }
        });
        if (flag) {
          let channelNameList = {
            3: "authManagement.Times",
            4: "VivoAccountManagement.TimesVivo",
            5: "MiAccountManagement.TimesMi",
            6: "HwAccountManagement.TimesHw",
          };
          _this.$router.push({
            name: channelNameList[_this.channelTitleList[0].id],
          });
        } else {
          _this.listModelFn();
        }
      } else {
        _this.accountSelectionListShow = false;
        _this.$message({
          showClose: true,
          message: "您的操作权限不足",
          type: "error",
        });
      }
    },
    //获取分配人员列表
    getFpyjList328() {
      let _this = this;
      $http.axios
        .get("/api/system/user/getAccountAppendList?deptId=328")
        .then(function (response) {
          if (response.data.code == 200) {
            _this.warnEigth = response.data.data; //预警人
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
    getFpyjList329() {
      let _this = this;
      $http.axios
        .get("/api/system/user/getAccountAppendList?deptId=329")
        .then(function (response) {
          if (response.data.code == 200) {
            _this.warnNine = response.data.data; //预警人
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
     //获取分配人员列表
     getFpyjList328(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=328").then(function(response) {
        if(response.data.code == 200){
          that.warnEigth = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    getFpyjList329(){
      let that = this
      $http.axios.get("/api/system/user/getAccountAppendList?deptId=329").then(function(response) {
        if(response.data.code == 200){
          that.warnNine = response.data.data//预警人
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    //channel的tab切换
    clickChangeChannel(channelId) {
      let channelNameList = {
        3: "authManagement.Times",
        4: "VivoAccountManagement.TimesVivo",
        5: "MiAccountManagement.TimesMi",
        6: "HwAccountManagement.TimesHw",
      };
      this.$router.push({ name: channelNameList[channelId] });
    },
    getId() {
      this.myHeaders.Authorization =
        "Bearer " + this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
      this.uploadId = this.$route.params && this.$route.params.id;
      console.log(this.uploadId);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    //按钮开关
    getBrandList(row, name) {
      let _this = this,
        params = {};
      params = {
        userName: _this.userName,
        userId: _this.userId,
        deptId: _this.deptId,
        id: row.id,
        status: row.status,
        // 'zeroFlag':row.zeroFlag,
        // 'imgFlag':row.imgFlag,
        // 'excelFlag':row.excelFlag
      };
      params[name] = row[name];
      $http.axios
        .post("/api/dataReport/reportModel/edit", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.listModelFn();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
            row[name] = row[name] == 1 ? 0 : 1;
          }
        })
        .catch(() => {
          row[name] = row[name] == 1 ? 0 : 1;
        });
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let _this = this,
        dataSource = [],
        keywords = [];
      // let video = []
      console.log(response);
      if (response.code == 200) {
        _this.dayTimeDialogIs = true;
        if (response.data.dataSource.length != 0) {
          _this.dataSourceIs = true;
        }
        if (response.data.kwDataSource.length != 0) {
          _this.keywordsIs = true;
        }
        response.data.dataSource.forEach((element, i) => {
          dataSource.push({ sheetName: element, timeZone: "Asia" });
        });
        response.data.kwDataSource.forEach((element, i) => {
          keywords.push({ sheetName: element });
        });
        _this.dataSource = dataSource;
        _this.keywords = keywords;
        _this.path = response.data.path;
        _this.sizeType = response.data.sizeType;
        this.$refs.dayTimeXDialog.cc_options = this.$sessionStorage.getItem(
          "optionSelectCopyUser"
        );
      } else {
        _this.$message({
          duration: 0,
          showClose: true,
          message: response.msg,
          type: "error",
        });
      }
      this.$refs.upload.clearFiles();
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event) {
      let yjType = event.target.parentNode.dataset.type;
      if (yjType == "infoBtn") {
        this.dayInfoDialogIs = true;
        this.$refs.dayInfo.getuserChannel(row.id);
      } else if (yjType == "xiuGai") {
        this.dayTimeXDialogIs = true;
        getUserList();
        this.$refs.dayTimeXDialog.getuserChannel(row.id);
        this.$refs.dayTimeXDialog.cc_options = this.$sessionStorage.getItem(
          "optionSelectCopyUser"
        );
      } else if (yjType == "sendBtn") {
        // this.pushFn(row.id,row.reportNum)
        // this.copyIds = row.id
      } else if (yjType == "xiazai") {
        //下载
        this.downLoad(row.id);
      } else if (yjType == "zhuanrang") {
        //转让
        this.transference(row.id);
      } else if (yjType == "bufa") {
        //补发
        this.reissue(row);
      } else if (yjType == "delBtn") {
        this.deletePlanFn(row.id);
      }
    },

    //转让
    transference(id) {
      this.getIds = id;
      this.warningPersonOption = this.warnEigth.concat(this.warnNine);
      console.log(this.warningPersonOption);
      this.transferenceDialog = true;
    },
    changeTrans(val) {
      console.log(val);
      this.changeUserId = val.userId;
      this.changeDeptId = val.deptId;
    },
    //确认转让弹框
    transferenceSure() {
      let _this = this,
        params = {};
      params = {
        userId: _this.userId,
        userName: _this.userName,
        deptId: _this.deptId,
        id: _this.getIds,
        newUserId: _this.changeUserId,
        newDeptId: _this.changeDeptId,
      };
      $http.axios
        .post("/api/dataReport/reportModel/edit", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.transferenceDialog = false;
            _this.listModelFn();
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //格式化时间 返回年月日
    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M =
          val.getMonth() + 1 < 10
            ? "0" + (val.getMonth() + 1)
            : val.getMonth() + 1;
        let D =
          val.getDate() - 1 < 10
            ? "0" + (val.getDate() - 1)
            : val.getDate() - 1;
        return Y + "-" + M + "-" + D;
      }
      return "";
    },

    //补发
    reissue(item) {
      if (item.status !== 0) {
        this.$alert(
          `<div><img class="icon" src="${require("@/assets/img/warningIcon.png")}" /><div class="text_tip">请确认操作的模板状态为已开启！</div></div>`,
          "提醒",
          {
            center: true,
            // showCancelButton: true,
            dangerouslyUseHTMLString: true,
            customClass: "icon_custom_popup",
            beforeClose: (action, instance, done) => {
              done();
            },
          }
        )
          .then(() => {})
          .catch(() => {});
        return;
      }
      this.reissueDialog = true;
      this.reissueId = item.id;
      this.reissuevalue = [formatDate(new Date()), formatDate(new Date())];
    },
    //确定补发
    reissueSure() {
      let _this = this;
      $http.axios
        .get(
          "/api/dataReport/reportSend/manualReport?ids=" +
            _this.reissueId +
            "&sDate=" +
            _this.reissuevalue[0] +
            "&eDate=" +
            _this.reissuevalue[1]
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.reissueDialog = false;
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //转让
    transference(id){
      this.getIds = id
      this.warningPersonOption = this.warnEigth.concat(this.warnNine)
      console.log(this.warningPersonOption)
      this.transferenceDialog = true
    }, 
    //取消转让弹框
    cancleDialog(){
      this.warningPersonOption = ''
      this.transferenceDialog = false
    },
    changeTrans(val){
      console.log(val)
      this.changeUserId = val.userId
      this.changeDeptId = val.deptId
    },
    //确认转让弹框
    transferenceSure(){
      let _this = this,params = {}
      params = {
        'userId':_this.userId,
        'userName':_this.userName,
        'deptId':_this.deptId,
        'id':_this.getIds,
        'newUserId':_this.changeUserId,
        'newDeptId':_this.changeDeptId
      }
      $http.axios.post("/api/dataReport/reportModel/edit",params).then(res=> {
        if(res.data.code == 200){
          _this.transferenceDialog = false
          _this.listModelFn()
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //格式化时间 返回年月日 
    getDate(time) {
      if (time) {
        let val = new Date(time);
        let Y = val.getFullYear();
        let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
        let D = (val.getDate()-1) < 10 ? '0' + (val.getDate()-1) : (val.getDate()-1);
        return Y + '-' + M + '-' + D;
      }
      return ''
    },

    //补发
    reissue(item){
      if (item.status !== 0) {
        this.$alert('请确认操作的模板状态为已开启！', '提示', {
          confirmButtonText: '确定',
          type:'warning',
        });
        return
      }
      this.reissueDialog = true
      this.reissueId = item.id
        this.reissuevalue = [formatDate(new Date()), formatDate(new Date())];
    },
    //取消补发
    canclereissueDialog(){
      this.reissueDialog = false
    },
    //确定补发
    reissueSure(){
      let _this = this
      $http.axios.get("/api/dataReport/reportSend/manualReport?ids="+_this.reissueId+"&sDate="+_this.reissuevalue[0]+"&eDate="+_this.reissuevalue[1]).then(res=> {
        if(res.data.code == 200){
          _this.reissueDialog = false
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },


    //下载
    downLoad(id) {
      let _this = this;
      $http.axios
        .get("/api/dataReport/reportModel/queryImgUrlById?id=" + id)
        .then((res) => {
          if (res.data.code == 200) {
            window.location.href = res.data.data;
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    handleCommand(e, row) {
      let _this = this,
        params = {};
      params = {
        userName: _this.userName,
        userId: _this.userId,
        deptId: _this.deptId,
        type: Number(e),
        ids: row.id,
      };
      $http.axios
        .post("/api/dataReport/reportSend/rePush", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.$message({
              showClose: true,
              message: "已重新发送，请注意查收",
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    // tab跳转
    goToDaily() {
      this.$router.replace("/auth-management/DailyAsa");
    },
    // 重新发送抄送人
    // pushFn(id,reportNum){
    //   console.log(id)
    //   console.log(reportNum)
    //   let _this = this,params = {}
    //   params = {
    //     'userName':_this.userName,
    //     'userId':_this.userId,
    //     'deptId':_this.deptId,
    //     "type":,
    //     "ids":id
    //   }
    //   $http.axios.post("/api/dataReport/reportSend/rePush",params).then(res=> {
    //     if(res.data.code == 200){
    //       _this.$message({
    //         showClose: true,
    //         message: reportNum+'已重新发送给抄送人，请注意查收',
    //         type: 'success'
    //       })
    //     } else {
    //       _this.$message({
    //         showClose: true,
    //         message: res.data.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },

    //列表 /vivoReport/listModel
    listModelFn(page, dayName, adId, sendName, copyName, channelm) {
      console.log(channelm);
      let _this = this,
        params = {},
        status = "";
      status = channelm;
      params = {
        userName: _this.userName,
        userId: _this.userId,
        deptId: _this.deptId,
        channelId: 8,
        type: _this.type,
        pageIndex: page || 1,
        pageSize: 10,
        ownerName: adId, //查询广告主名称
        name: dayName, //报表名称
        status: status, //报表状态
        senderName: sendName, //发送人姓名
        copySenderName: copyName, //抄送人姓名
      };
      $http.axios
        .post("/api/dataReport/reportModel/listModel", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.tableData = res.data.data.infos;
            _this.totalRow = res.data.data.pageInfo.rowCount; //分页总条
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    //删除按钮
    deletePlanFn(e) {
      let _this = this,
        params = {};
      this.$alert(
        `<div><img class="icon" src="${require("@/assets/img/deleteIcon.png")}" /><div class="text_tip">您是否确认删除报表模板，删除后数据源中数据将不再累加，且无法恢复？</div></div>`,
        "删除",
        {
          center: true,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          customClass: "icon_custom_popup",
          beforeClose: (action, instance, done) => {
            if (action == "confirm") {
              instance.confirmButtonLoading = true;
              params = {
                userName: _this.userName,
                userId: _this.userId,
                deptId: _this.deptId,
                id: e,
                status: 2,
              };

              $http.axios
                .post("/api/dataReport/reportModel/edit", params)
                .then((res) => {
                  if (res.data.code == 200) {
                    _this.listModelFn();
                    done();
                    instance.confirmButtonLoading = false;
                  } else {
                    _this.$message({
                      showClose: true,
                      message: res.data.msg,
                      type: "error",
                    });
                    instance.confirmButtonLoading = false;
                  }
                })
                .catch(() => {
                  instance.confirmButtonLoading = false;
                });
            } else {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },

    //头部表单重置
    resetForm() {
      this.dayName = "";
      this.sendName = "";
      this.copyName = "";
      this.adId = "";
      this.channelm = "";
    },
    // 分页
    handleSizeChange(val) {
      this.findAll(val);
    },

    //分页方法
    findAll(val) {
      //获取数据接口（调用封装的接口）
      this.listModelFn(val);
    },

    //模板说明
    uploadTip() {
      this.showTip = true;
      this.activeNames = this.$options.data().activeNames
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  overflow: visible;
}
</style>
