<template>
  <el-dialog
    :visible.sync="show"
    width="1000px"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    title="批量启停设置"
    center
    :show-close = "false">
    <div class="dialogIcon"><img :src='suCaiIcon'></div>
    <div class="setPrice">
      <el-form :model="ruleForm" :rules="rules" ref='ruleForm'  class="demo-ruleForm">
        <el-form-item label="目标状态" label-width="120px">
          <el-switch
            v-model="planStatus"
            active-value="0"
            inactive-value="1"
            active-color="#48916F"
            inactive-color="#EAEFED"
            @change="editStatus">
          </el-switch>
        </el-form-item>
        <el-form-item label="设置执行日期" label-width="120px" prop="dateChecked">
          <!-- <div class="block"> -->
            <el-date-picker
              v-model="ruleForm.dateChecked"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          <!-- </div> -->
          <div class="setSureDate">{{setDatevalueString}}</div>   
        </el-form-item>
        <el-form-item label="设置时间" label-width="120px" prop="timeChecked">
          <el-checkbox v-model="ruleForm.timeChecked" @change="setCarryTime">设置执行时间</el-checkbox>
        </el-form-item>
        <el-form-item>
          <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('ruleForm')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import suCaiIcon from '@/assets/img/suCaiIcon.png';
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import TimeUnit from '@/views/auth/TimeUnit'

export default {
  components:{
    TimeUnit
  },
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    yulanList : Array,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    pubVue:Object,
    objIdType:Number,
    max: Number
  },
  data() {
    return {
        suCaiIcon,
        show: this.visible,
        loading: false,
        planStatus:'0',
        userId:'',
        userName:'',
        deptId:'',
        ruleForm: {
          customerName:'',
          planName:'',
          dateChecked:'',//设置执行日期
          timeChecked:'',//设置执行时间
          radio:'',//计划执行设置，单选
        },
        rules: {
          dateChecked:[
            { required: true, message: '请设置执行日期', trigger: 'change' }
          ],
          // timeChecked:[
          //   { required: true, message: '请设置执行时间', trigger: 'change' }
          // ],
          // radio:[
          //   { required: true, message: '请选择计划执行设置', trigger: 'change' }
          // ],
        },
        radioOptions:[{//单选
          label:'设置执行日期',
          value:'1'
        },{
          label:'立即执行',
          value:'2'
        }],
        setDateVisible:false,//计划启停的设置执行日期
        setDatevalue:[],//计划启停的设置执行日期----日期选择
        setDatevalueString:'',
        setDatevalueShow:false,//计划启停的设置执行日期----日期选择展示
        setTimeVisible:false,//计划执行设置执行日期
        setTimevalue:'',//计划执行设置执行日期----日期选择
        setTimevalueShow:false,//计划执行设置执行日期----日期选择展示
        setTimevalueString:'',
        planId:'',
        planName:'',
        carryTimeVisible:false,//计划启停设置--设置执行时间
        timeStr:[],
        timeUnitShow:false,
        // /adGroup/v1/addOpenCloseGroup
        // /adPlan/v1/addOpenClosePlan
        url:{'10':'/adPlan/v1/addOpenClosePlan','20':'/adGroup/v1/addOpenCloseGroup'}
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
      

  },
  methods: {
    editStatus(){
      console.log(this.planStatus)
    },
    onConfirm(formName) {
      let _this = this
      let params = []
      _this.setPriceCommon={'userId' : _this.userContent.userId,'userName' : _this.userContent.userName,
      deptId: _this.userContent.deptId,
      ownerName : _this.userContent.ownerName,
      ownerId : _this.userContent.ownerId,
      }
      let timeString =_this.ruleForm.timeChecked? _this.$refs.timeStrArray.timeStr : []
      let addOpenClosePlanDetailDtoList = []
        timeString.forEach(function(e,index){
          if(e!=''){
            addOpenClosePlanDetailDtoList.push(
              {executeHourt:e,week:index+1}
            )
          }
        })
        _this.yulanList.forEach((obj,i)=>{
          params.push({'userId' : _this.userContent.userId,
          'userName' : _this.userContent.userName,
          'deptId': _this.userContent.deptId,
          'ownerName' : _this.userContent.ownerName,
          'ownerId' : _this.userContent.ownerId,
          'adGroupId':obj.adGroupId,
          'adGroupName':obj.adGroupName,
          'planId' : obj.planId,
          'planName' : obj.planName,
          'planStatus':Number(_this.planStatus),//obj.planStatus,//计划开关状态 0:开启 1:关闭->静静说先写死成开启的状态
          'startTime' : _this.ruleForm.dateChecked[0],
          'endTime' : _this.ruleForm.dateChecked[1],
          'objIdType' : _this.objIdType,
          'addOpenClosePlanDetailDtoList' : addOpenClosePlanDetailDtoList
          })
        })
      // _this.setPriceCommon[0].type = _this.userContent.type
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(666)
          _this.carryTimeSure(params)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      
      
    },
    onCancel() {
      this.show= false;
      this.$refs.ruleForm.resetFields();
    },
   

      // //计划启停设置---设置执行日期
      // dateSet(val){
      //   if(val==true){
      //     this.setDateVisible = true
      //     this.setDatevalue = []
      //   }else{
      //     this.setDateVisible = false
      //     this.setDatevalueString = ''
      //   }
      // },

      //计划启停设置---设置执行日期--取消
      setDateCancel(){
        this.setDateVisible = false
        this.ruleForm.dateChecked = false
      },
      //计划启停设置---设置执行日期--确定
      // setDateCommit(){
      //   if(this.setDatevalue.length == 0){
      //     this.$message('请选择日期')
      //   }else{
      //     this.setDateVisible = false
      //     this.setDatevalueShow = true
      //     this.setDatevalueString = String(this.setDatevalue[0]+'至'+this.setDatevalue[1])
      //   } 
      // },

      //计划执行设置--选择
      changeRadio(val){
        console.log(val)
        if(val == 1){
          this.setTimeVisible = true
          this.setTimevalue = ''
        }else{
          this.setTimevalueShow = false
        }
      },
      //计划执行设置---设置执行日期--取消
      setTimeCancel(){
        this.setTimeVisible = false
        this.ruleForm.radio = ''
      },
      //计划执行设置---设置执行日期--确定
      setTimeCommit(){
        if(this.setTimevalue == ''){
          this.$message('请选择日期')
        }else{
          this.setTimeVisible = false
          this.setTimevalueShow = true
          this.setTimevalueString = String(this.setTimevalue[0]+'至'+this.setTimevalue[1])
        }
      },

      //设置执行时间显示弹框
      setCarryTime(){
        let that = this
        if(that.ruleForm.timeChecked == true){
          that.timeUnitShow = true
        }else{
          that.timeUnitShow = false
        }
        
      },

      //设置执行时间显示弹框--确定
      carryTimeSure(params){
        let that = this
        that.$refs.ruleForm.validate((valid) => {
          if (valid) {
            $http.axios.post("/api/advertisement"+that.url[that.objIdType],params).then(res=> {
              if(res.data.code == 200){
                this.show = false
                that.pubVue.$refs.multipleTable.clearSelection();
                that.pubVue.getAccountPlanListFn()
                that.pubVue.adPlanIdList = []
                that.$refs.ruleForm.resetFields();
                that.ruleForm.ruleForm = ''
                that.timeUnitShow = false

              }  else {
                that.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });

        
      },
    
  }
};
</script>
<style lang="scss" scoped>
  .account-detail-dialog .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
.account-detail-dialog .el-form-item__label {
    font-size: 18px;
  }
.account-detail-dialog .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
/* .el-checkbox{
  margin: 15px 8px;
} */
.marginPx{
  margin: 15px 8px;
  font-size:12px;
  color:#330000;
}
.baseTitle{
  font-weight:700;
  display:block;
  font-size:14px;
  margin:10px 0 20px;
}
.setPrice .el-input{
  width:260px;
}
// .errorTip{
//   margin-left:150px;
// }

/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
</style>

