<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="hl_headers sticky">
          <div class="hl_headerLeft">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="hl_headerLeftDrowp"
            >
              <el-breadcrumb-item>运营管理</el-breadcrumb-item>
              <el-breadcrumb-item>素材库</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight" v-if="typeFlags == 1">
            <el-dropdown trigger="click" @command="handleCommand">
              <el-button type="primary" size="small" class="el-dropdown-link"
                >标签
                <span class="triangle_button"></span>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="newTag">新建标签</el-dropdown-item>
                <el-dropdown-item command="addHadTag"
                  >添加已有标签</el-dropdown-item
                >
                <el-dropdown-item command="editTagName" v-if="getchannelId == 5"
                  >修改标签名称</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <el-button type="primary" size="small"
                >权限
                <span class="triangle_button"></span>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="add">
                  <span @click="addjurisdiction">新增权限</span>
                </el-dropdown-item>
                <div v-show="choiceTag">
                  <el-dropdown-item command="emit">
                    <span @click="queryjurisdiction">修改权限</span>
                  </el-dropdown-item>
                  <el-dropdown-item command="delete">
                    <span @click="deletejurisdiction">删除权限</span>
                  </el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="primary" size="small" @click="batchIsPast(0)"
              >运营审核通过</el-button
            >
            <el-button type="primary" size="small" @click="batchIsPast(1)"
              >客户审核通过</el-button
            >
            <el-button type="primary" size="small" @click="batchDownload"
              >批量下载</el-button
            >
            <el-button
              v-if="getchannelId == 3 || getchannelId == 4"
              type="primary"
              size="small"
              @click="batchExport"
              >批量导出</el-button
            >
            <div class="function" v-if="getchannelId == 5">
              <el-button
                type="primary"
                size="small"
                @click="operateRefuse(1, 3)"
                >运营拒绝</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="operateRefuse(1, 4)"
                >客户拒绝</el-button
              >
              <el-button type="primary" size="small" @click="mediaRefuse(2)"
                >媒体拒绝</el-button
              >
              <el-button
                type="primary"
                size="small"
                @click="batchMaterialNameEdit"
                >修改素材名称</el-button
              >
              <el-button type="primary" size="small" @click="pushMaterial"
                >推送素材</el-button
              >
            </div>
            <el-button type="primary" size="small" v-if="getchannelId == 3||getchannelId == 4" @click="mediaRefuse(2)"
              >媒体拒绝</el-button
            >
          </div>
        </div>
        <!-- 创建标签弹框 -->
        <el-dialog
          class="icon_custom_popup"
          width="400px"
          title="创建标签"
          :visible.sync="newTagVisible"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/biaoQianIcon.png" />
          <el-form label-width="66px">
            <el-form-item label="标签名称">
              <el-input v-model="newtagName" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="canclenewTag">取 消</el-button>
            <el-button type="primary" @click="newtagSuccess">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 添加已有标签弹框 -->
        <el-dialog
          class="icon_custom_popup"
          width="400px"
          title="添加已有标签"
          :visible.sync="addHadTagVisible"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/biaoQianIcon.png" />
          <el-form label-width="66px">
            <el-form-item label="添加标签">
              <el-select
                v-model="materialTagList"
                filterable
                placeholder="请选择"
                @change="changeAddhadTag($event)"
              >
                <el-option
                  v-for="(item, i) in materiaTabOptions"
                  :key="i"
                  :label="item.tagName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="addHadTagVisible = false">取 消</el-button>
            <el-button type="primary" @click="addHadTagSuccess"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 修改标签名称弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="修改标签名称"
          :visible.sync="editTagVisible"
          width="400px"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/biaoQianIcon.png" />
          <el-form
            :model="editForm"
            :rules="editrules"
            ref="editForm"
            label-width="80px"
          >
            <el-form-item label="素材标签" prop="changeTags">
              <el-select v-model="editForm.changeTags" placeholder="请选择">
                <el-option
                  v-for="(item, i) in materiaTabOptions"
                  :key="i"
                  :label="item.tagName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="新标签名称" prop="newTagNames">
              <el-input
                v-model="editForm.newTagNames"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="editTagCancle(editForm)">取 消</el-button>
            <el-button type="primary" @click="editTagSuccess(editForm)"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 新增权限弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="新增权限"
          :visible.sync="addTag"
          width="400px"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/quanXianIcon.png" />
          <el-form label-width="66px">
            <el-form-item label="素材标签">
              <el-select
                v-model="materialTagList"
                filterable
                multiple
                placeholder="请选择"
                @change="mTagChange($event)"
              >
                <el-option
                  v-for="(item, i) in materiaTabOptions"
                  :key="i"
                  :label="item.tagName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="人员">
              <el-select
                v-model="peopleTagList"
                filterable
                multiple
                placeholder="请选择"
                @change="pTagChange($event)"
              >
                <el-option
                  v-for="(item, i) in peopleTagOptions"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="addTag = false">取 消</el-button>
            <el-button type="primary" @click="addjurisdictionSure"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 修改权限弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="修改权限"
          :visible.sync="modifyTag"
          width="400px"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/quanXianIcon.png" />
          <div>{{ tagNameTable }}</div>
          <el-form label-width="66px">
            <el-form-item label="素材标签">
              <el-select
                v-model="materiaForm.mTab"
                filterable
                placeholder="请选择"
                @change="modifyTagList($event)"
              >
                <el-option
                  v-for="(item, i) in materiaTabOptions"
                  :key="i"
                  :label="item.tagName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="modifyTag = false">取 消</el-button>
            <el-button type="primary" @click="queryjurisdictionSure"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 删除权限弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="删除权限"
          :visible.sync="deleteTag"
          width="400px"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/deleteIcon.png" />
          <div class="text_tip">{{ tagNameTable }}</div>
          <div slot="footer">
            <el-button @click="deleteTag = false">取 消</el-button>
            <el-button type="primary" @click="deletejurisdictionSure"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 正在下载弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="正在下载，请稍后"
          :visible.sync="exprtVisible"
          width="300px"
        >
          <span class="el-icon-loading loading" v-show="uploadingShow"></span>
        </el-dialog>
        <!-- 批量导出提示弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="批量导出提示"
          :visible.sync="exportVisible"
          width="300px"
          :close-on-click-modal="false"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_reason.png" />
          </div>
          <div class="text_tip">素材正在下载中，下载结果请关注左下角</div>
          <div slot="footer">
            <el-button type="primary" @click="exportVisible = false"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 审核拒绝弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="拒绝原因"
          :visible.sync="dialogFormVisible"
          width="400px"
          :close-on-click-modal="false"
        >
          <div class="icon">
            <img src="@/assets/img/juJueIcon.png" />
          </div>
          <el-form :model="jujueform" :rules="rules" ref="jujueform">
            <el-form-item prop="rejectionReason">
              <el-input
                type="textarea"
                :rows="6"
                v-model="jujueform.rejectionReason"
                placeholder="请输入拒绝理由"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="canclelingwaiFn(jujueform)">取 消</el-button>
            <el-button type="primary" @click="refuselingwaiFn(jujueform)"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 素材名修改弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="修改素材名称"
          :visible.sync="dialogMedit"
          width="400px"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form
            :model="miEditForm"
            :rules="rules"
            ref="miEditForm"
            label-width="20px"
          >
            <el-form-item prop="maNameEdit">
              <el-input
                v-model="miEditForm.maNameEdit"
                placeholder="请输入素材名称"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="dialogMedit = false">取 消</el-button>
            <el-button type="primary" @click="editMaterialName"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 推送素材弹框 -->
        <el-dialog
          class="icon_custom_popup"
          title="推送素材"
          :visible.sync="dialogPushMaterial"
          width="400px"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form
            :model="miPushMaterialForm"
            :rules="rules"
            ref="miPushMaterial"
            label-width="66px"
          >
            <el-form-item prop="pushAccount" label="账户名称">
              <el-select
                v-model="miPushMaterialForm.pushAccount"
                multiple
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, i) in accountNameOptions"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogPushMaterial = false">取 消</el-button>
            <el-button type="primary" @click="pushMaterialSure"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <div class="search_container">
          <el-form :inline="true" :model="materiaForm" ref="materiaForm">
            <el-form-item label="" prop="myOldChannel">
              <el-select
                v-model="materiaForm.myOldChannel"
                placeholder="请选择原上传渠道"
                @change="yuanlaichannelId"
              >
                <el-option
                  v-for="(item, i) in qdoptions"
                  :key="i"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="mStatus">
              <el-select
                v-model="materiaForm.mStatus"
                filterable
                clearable
                placeholder="请选择素材状态"
              >
                <el-option
                  v-for="(item, i) in materiaStatusOptions"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="hideenTheForm" v-if="showTheForm">
              <i class="el-icon-arrow-right" @click="showList"></i>
            </div>
            <div class="showTheForm" v-else>
              <el-form-item label="" prop="searchmaterialId">
                <el-input
                  v-model="materiaForm.searchmaterialId"
                  clearable
                  placeholder="请输入素材ID"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="mTitle">
                <el-autocomplete
                  class="inline-input"
                  v-model="materiaForm.mTitle"
                  value-key="mainTitile"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      multipleFn(queryString, cb, 'wa');
                    }
                  "
                  placeholder="请输入主题文案"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="" prop="materialName">
                <el-autocomplete
                  class="inline-input"
                  v-model="materiaForm.materialName"
                  value-key="mtName"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      multipleFn(queryString, cb, 'sc');
                    }
                  "
                  placeholder="请输入素材名称"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="" prop="demandNameQuery">
                <el-autocomplete
                  class="inline-input"
                  v-model="materiaForm.demandNameQuery"
                  value-key="demandName"
                  :fetch-suggestions="
                    (queryString, cb) => {
                      multipleFn(queryString, cb, 'gd');
                    }
                  "
                  placeholder="请输入工单名称"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="" prop="mTab">
                <el-select
                  v-model="materiaForm.mTab"
                  filterable
                  clearable
                  placeholder="请选择素材标签"
                  @focus="focusTag"
                  @change="getTagList"
                >
                  <el-option
                    v-for="(item, i) in materiaTabOptions"
                    :key="i"
                    :label="item.tagName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="mUser">
                <el-select
                  v-model="materiaForm.mUser"
                  filterable
                  clearable
                  placeholder="请选择制作人"
                  @change="getUserId($event, 'createUserName')"
                >
                  <el-option
                    v-for="(item, i) in materiaUserOptions"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="mUserGroup">
                <el-select
                  v-model="materiaForm.mUserGroup"
                  filterable
                  clearable
                  placeholder="请选择制作组"
                >
                  <el-option
                    v-for="(item, i) in materiaGroupOptions"
                    :key="i"
                    :label="item.groupName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="mCode">
                <el-input
                  v-model="materiaForm.mCode"
                  placeholder="请输入工单编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="mCostStatus">
                <el-select
                  v-model="materiaForm.mCostStatus"
                  clearable
                  filterable
                  placeholder="请选择消耗状态"
                  @change="getUserId($event, 'costStatus')"
                >
                  <el-option
                    v-for="(item, i) in materiaUserCostStatus"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="mMaterialLaiyuan">
                <el-select
                  v-model="materiaForm.mMaterialLaiyuan"
                  clearable
                  filterable
                  placeholder="请选择素材来源"
                >
                  <el-option
                    v-for="(item, i) in mMaterialLaiyuanOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label=""
                :label-width="formLabelWidth"
                v-if="getchannelId == 5 || getchannelId == 3"
              >
                <el-select
                  v-model="materiaForm.selectSpecifications"
                  filterable
                  placeholder="请选择素材规格"
                >
                  <el-option
                    v-for="(item, i) in selectSpecificationsOption"
                    :key="i"
                    :label="item.resourceBits"
                    :value="item.globalSpecId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="mDate">
                <el-date-picker
                  v-model="materiaForm.mDate"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <i class="el-icon-arrow-up" @click="hiddenList"></i>
            </div>
            <el-form-item>
              <el-button type="primary" size="small" @click="getMaterialList(1)"
                >查询</el-button
              >
              <el-button
                type="info"
                size="small"
                @click="resetForm('materiaForm')"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="function_contant">
          <div class="tab_contant">
            <div class="active">视频</div>
            <div @click="materialShow">图文</div>
          </div>
        </div>
        <div class="materiaContent">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :max-height="screenHeight"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" fixed="left">
            </el-table-column>
            <el-table-column
              prop="name"
              label="原上传渠道"
              width="100"
              fixed="left"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.channelId == 1">今日头条</span>
                <span v-else-if="scope.row.channelId == 2">快手</span>
                <span v-else-if="scope.row.channelId == 3">OPPO</span>
                <span v-else-if="scope.row.channelId == 4">VIVO</span>
                <span v-else-if="scope.row.channelId == 5">小米</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="素材id"
              width="180"
              fixed="left"
            >
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column prop="name" label="素材名称" width="180">
              <template slot-scope="scope">
                {{ scope.row.mtName }}
                <i
                  v-if="getchannelId == 5"
                  class="el-icon-edit miEdit"
                  @click="materialNameEdit(scope.row)"
                ></i>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="工单名称" width="180">
              <template slot-scope="scope">
                <span v-if="scope.row.demandName == ''">--</span>
                <span v-else>{{ scope.row.demandName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="资源位" width="180">
              <template slot-scope="scope">
                <div v-for="item in resourceBitsOption">
                  <span v-if="item.globalSpecId == scope.row.materialType">
                    {{ item.resourceBits }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="素材标签" width="180">
              <template slot-scope="scope">{{
                scope.row.showTagNames
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="制作组" width="180">
              <template slot-scope="scope">{{
                scope.row.makerGroupName
              }}</template>
            </el-table-column>
            <el-table-column label="素材内容" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 2" class="picTip">
                  <el-popover placement="right" title="" trigger="hover">
                    <img
                      v-if="scope.row.costStatus != 10"
                      :src="
                        require('../../../assets/img/tip' +
                          scope.row.costStatus +
                          '.png')
                      "
                      style="position: absolute; width: 40%; top: 0; left: 0"
                    />
                    <img
                      :src="scope.row.url.videoUrl + firstWord"
                      style="width: 200px; cursor: pointer"
                    />

                    <el-image
                      slot="reference"
                      fit="cover"
                      style="width: 70px; height: 70px"
                      :src="scope.row.url.videoUrl + firstWord"
                      @click="showVideo(scope.row)"
                    >
                    </el-image>
                    <!-- <img slot="reference" :src="scope.row.url.videoUrl+firstWord" style="width:50%;cursor: pointer"  @click="showVideo(scope.row)"> -->
                    <img
                      slot="reference"
                      v-if="scope.row.costStatus != 10"
                      class="tip"
                      :src="
                        require('../../../assets/img/tip' +
                          scope.row.costStatus +
                          '.png')
                      "
                    />
                  </el-popover>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="costStatus" label="投放状态" width="100">
              <template slot-scope="scope">{{
                scope.row.costStatusName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="主题文案" width="180">
              <template slot-scope="scope">{{ scope.row.mainTitile }}</template>
            </el-table-column>
            <el-table-column prop="name" label="编导" width="80">
              <template slot-scope="scope">{{
                scope.row.directorUserName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="摄影" width="100">
              <template slot-scope="scope">{{
                scope.row.photographyUserName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="剪辑" width="100">
              <template slot-scope="scope">{{
                scope.row.clipUserName
              }}</template>
            </el-table-column>
            <el-table-column prop="name" label="素材状态" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 1">禁用</span>
                <span v-if="scope.row.status == 2">正常</span>
                <span v-if="scope.row.status == 3">待审核</span>
                <span v-if="scope.row.status == 4">运营审核通过</span>
                <span v-if="scope.row.status == 5">运营审核拒绝</span>
                <span v-if="scope.row.status == 6">客户审核拒绝</span>
                <span v-if="scope.row.status == 7">媒体审核拒绝</span>
              </template>
            </el-table-column>
            <el-table-column label="上传时间" width="200">
              <template slot-scope="scope">{{
                scope.row.creatTimeStr
              }}</template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="340">
              <template slot-scope="scope">
                <span v-if="typeFlags == 1">
                  <el-button
                    class="solid-tag-success"
                    plain
                    size="mini"
                    @click="videoDetail(scope.row.id)"
                    >详情</el-button
                  >
                  <el-button
                    class="solid-tag-death"
                    plain
                    size="mini"
                    v-if="scope.row.pushAccounts!=null"
                    style="margin-left: 2px"
                    @click="showAccountDetail(scope.row)"
                    >已推账户</el-button
                  >
                  <span>
                    <el-button
                      class="solid-tag-harmful"
                      plain
                      size="mini"
                      v-if="scope.row.status == 2"
                      @click="refuseFn(scope.row.id)"
                      >媒体拒绝</el-button
                    >
                    <el-button
                      class="solid-tag-harmful"
                      plain
                      size="mini"
                      v-else-if="scope.row.status == 3"
                      @click="refuselingFn(scope.row.id)"
                      >运营拒绝</el-button
                    >
                    <el-button
                      class="solid-tag-harmful"
                      plain
                      size="mini"
                      v-else-if="scope.row.status == 4"
                      @click="refuselingFn(scope.row.id)"
                      >客户拒绝</el-button
                    >
                  </span>
                  <!-- <el-button class="solid-tag-harmful" plain size="mini" @click="refuseFn(scope.row.id)">拒绝</el-button> -->
                  <el-button
                    class="solid-tag-instructions"
                    plain
                    size="mini"
                    @click="videoEdit(scope.row.id)"
                    >修改</el-button
                  >
                  <el-button
                    class="solid-tag-error"
                    plain
                    size="mini"
                    @click="deletematerial(scope.row.id)"
                    >删除</el-button
                  >
                </span>
                <span v-else>
                  <el-button
                    v-if="
                      (scope.row.status == 5 ||
                        scope.row.status == 6 ||
                        scope.row.status == 7) &&
                      (scope.row.channelId == '4' ||
                        scope.row.channelId == '5') &&
                      scope.row.downloadCount == 1
                    "
                    class="solid-tag-instructions"
                    size="mini"
                    @click="
                      materialUploadMateriaPicWait(scope.row.demandDetailId)
                    "
                    >上传素材</el-button
                  >
                  <el-button
                    class="solid-tag-warning"
                    plain
                    size="mini"
                    @click="sjsSendOut(scope.row.id)"
                    >发送</el-button
                  >
                  <el-button
                    class="solid-tag-success"
                    plain
                    size="mini"
                    @click="videoDetail(scope.row.id)"
                    >详情</el-button
                  >
                  <el-button
                    v-if="
                      (scope.row.status == 5 ||
                        scope.row.status == 6 ||
                        scope.row.status == 7) &&
                      (scope.row.channelId == '4' ||
                        scope.row.channelId == '5') &&
                      scope.row.downloadCount == 1
                    "
                    class="solid-tag-error"
                    plain
                    size="mini"
                    @click="stopReUpLoadFn(scope.row.id)"
                    >关闭上传</el-button
                  >
                  <el-button
                    class="solid-tag-error"
                    v-if="scope.row.status == 3 && getchannelId == 5"
                    plain
                    size="mini"
                    @click="deletematerial(scope.row.id)"
                    >删除</el-button
                  >
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 素材库详情弹框 -->
        <el-dialog
          width="785px"
          title="素材库详情"
          class="icon_custom_popup"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          :visible.sync="materialDetailVisible"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <div class="datails_contant between">
            <div class="image_contant">
              <div v-if="detailData.materialType == 1">
                <div class="title">素材内容</div>
                <div
                  :class="[
                    !detailData.url.imgList || detailData.url.imgList.length < 2
                      ? 'just_image_1'
                      : 'just_image_2',
                  ]"
                  v-if="detailData.type == 1"
                >
                  <div
                    class="image_news"
                    v-for="(item, i) in detailData.url.imgList"
                    :key="i"
                  >
                    <el-image
                      v-if="item"
                      :src="item"
                      fit="contain"
                      :preview-src-list="[item]"
                    ></el-image>
                    <div class="empty" v-else>
                      <img src="@/assets/img/noImage.png" />
                      <div>暂无图片</div>
                    </div>
                  </div>
                  <div
                    class="image_news"
                    v-if="
                      !detailData.url.imgList ||
                      detailData.url.imgList.length == 0
                    "
                  >
                    <div class="empty">
                      <img src="@/assets/img/noImage.png" />
                      <div>暂无图片</div>
                    </div>
                  </div>
                </div>
                <div class="just_image_1" v-else>
                  <div class="image_news">
                    <el-image
                      v-if="detailData.url.imgUrl"
                      :src="detailData.url.imgUrl"
                      fit="contain"
                      :preview-src-list="[detailData.url.imgUrl]"
                    ></el-image>
                    <div class="empty" v-else>
                      <img src="@/assets/img/noImage.png" />
                      <div>暂无图片</div>
                    </div>
                  </div>
                </div>
                <div class="just_image_2">
                  <div>
                    <div class="title">封面</div>
                    <div class="image_news">
                      <el-image
                        v-if="detailData.url.imgUrl"
                        :src="detailData.url.imgUrl"
                        fit="contain"
                        :preview-src-list="[detailData.url.imgUrl]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="title">落地页图片</div>
                    <div class="image_news">
                      <el-image
                        v-if="detailData.url.pageUrl"
                        :src="detailData.url.pageUrl"
                        fit="contain"
                        :preview-src-list="[detailData.url.pageUrl]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="title">素材内容</div>
                <!-- 视频 -->
                <div class="just_image_1" v-if="detailData.type == 2">
                  <div class="image_news">
                    <el-image
                      :src="videoUrlLj + firstWord"
                      v-show="loadIn == false"
                      fit="contain"
                      @click="reloadVideo(loadIn)"
                    ></el-image>
                    <video
                      id="myvideo"
                      ref="videoPouset"
                      controls
                      autoplay
                      playsinline
                      x5-playsinline
                      webkit-playsinline
                      v-show="loadIn == true"
                      controlslist="nodownload"
                      style="width: 100%; height: 100%; object-fit: contain"
                    ></video>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  :class="[
                    !imgj || imgj.length < 2 ? 'just_image_1' : 'just_image_2',
                  ]"
                  v-else
                >
                  <div
                    class="image_news"
                    v-for="(item, index) in imgj"
                    :key="index"
                  >
                    <el-image
                      v-if="item"
                      :src="item"
                      v-show="loadIn == false"
                      fit="contain"
                      :preview-src-list="[item]"
                    ></el-image>
                    <div class="empty" v-else>
                      <img src="@/assets/img/noImage.png" />
                      <div>暂无图片</div>
                    </div>
                  </div>

                  <div class="image_news" v-if="!imgj || imgj.length == 0">
                    <div class="empty">
                      <img src="@/assets/img/noImage.png" />
                      <div>暂无图片</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-form label-width="60px" style="width: 250px">
              <div class="title">详情说明</div>
              <el-form-item label="渠道名称">
                <div class="news">
                  {{
                    detailData.channelId == 1
                      ? "今日头条"
                      : detailData.channelId == 2
                      ? "快手"
                      : detailData.channelId == 3
                      ? "OPPO"
                      : detailData.channelId == 4
                      ? "VIVO"
                      : detailData.channelId == 5
                      ? "小米"
                      : ""
                  }}
                </div>
              </el-form-item>
              <el-form-item label="素材名称">
                <div class="news">{{ detailData.mtName }}</div>
              </el-form-item>
              <el-form-item label="工单名称">
                <div class="news">{{ detailData.demandName || "-" }}</div>
              </el-form-item>
              <el-form-item label="上传人员" class="">
                <div class="news">{{ detailData.createUserName }}</div>
              </el-form-item>
              <el-form-item label="制作人">
                <div class="news">{{ detailData.makerUserName }}</div>
              </el-form-item>
              <el-form-item label="工单编号">
                <div class="news">{{ detailData.demandNum || "-" }}</div>
              </el-form-item>
              <el-form-item label="上传时间">
                <div class="news">{{ detailData.creatTimeStr }}</div>
              </el-form-item>
              <el-form-item label="审核状态">
                <div class="news">
                  {{
                    detailData.status == 1
                      ? "禁用"
                      : detailData.status == 2
                      ? "正常"
                      : detailData.status == 3
                      ? "待审核"
                      : detailData.status == 4
                      ? "运营审核通过"
                      : detailData.status == 5
                      ? "运营审核拒绝"
                      : detailData.status == 6
                      ? "客户审核拒绝"
                      : detailData.status == 7
                      ? "媒体审核拒绝"
                      : ""
                  }}
                </div>
              </el-form-item>
              <el-form-item
                label="拒绝原因"
                v-if="
                  detailData.status == 5 ||
                  detailData.status == 6 ||
                  detailData.status == 7
                "
              >
                <div class="news">{{ detailData.subTitle || "-" }}</div>
              </el-form-item>
            </el-form>
          </div>
          <div slot="footer">
            <el-button type="primary" @click="materialDetailVisible = false"
              >确定</el-button
            >
          </div>
        </el-dialog>
        <!-- 发送素材名称和链接弹框 -->
        <el-dialog
          width="600px"
          title="发送素材名称和链接"
          class="icon_custom_popup"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          :visible.sync="sjssendoutVisible"
        >
          <div class="icon">
            <img src="@/assets/img/faSongIcon.png" />
          </div>
          <div class="datails_contant">
            <el-form label-width="66px">
              <div
                class="element_contant"
                v-for="(item, index) in sjslinkData"
                :key="index"
              >
                <el-form-item label="素材名称">
                  <div class="news">{{ item.mtName }}</div>
                </el-form-item>
                <el-form-item label="生成链接">
                  <div class="news">{{ item.url }}</div>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div slot="footer">
            <el-button type="primary" @click="sjssendoutVisible = false"
              >确定</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          width="600px"
          title="发送素材名称和链接"
          class="icon_custom_popup"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          :visible.sync="plsjssendoutVisible"
        >
          <div class="icon">
            <img src="@/assets/img/faSongIcon.png" />
          </div>
          <div class="datails_contant">
            <el-form label-width="66px">
              <div
                class="element_contant"
                v-for="(item, index) in bacthLinkData"
                :key="index"
              >
                <el-form-item label="素材名称">
                  <div class="news">{{ item.mtName }}</div>
                </el-form-item>
                <el-form-item label="生成链接">
                  <div class="news">{{ item.url }}</div>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div slot="footer">
            <el-button type="primary" @click="plsjssendoutVisible = false"
              >确定</el-button
            >
          </div>
        </el-dialog>
        <!-- 查看视频弹框 -->
        <el-dialog
          title=""
          :visible.sync="centerDialogVisible"
          ref="videoBtn"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :before-close="handleDialogClose"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_video.png" />
          </div>
          <div style="display: flex; justify-content: center">
            <video
              style="
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 60vh;
                object-fit: contain;
              "
              :src="videoUrlShow"
              autoplay
              controls
              controlslist="nodownload"
              ref="videoPouse"
            ></video>
          </div>
        </el-dialog>

        <!-- 以下弹框未涉及到，样式未更新 -->
        <el-dialog
          title="发送设置"
          :visible.sync="sendoutVisible"
          :modal-append-to-body="false"
          width="50%"
          class="sendSet"
        >
          <el-select
            v-model="accountName"
            filterable
            placeholder="选择账户名称"
            @change="getAccount($event)"
          >
            <el-option
              v-for="(item, i) in accountNameOptions"
              :key="i"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="addWaterMark"
            placeholder="请选择水印"
            @focus="getYes"
          ></el-input>
          <el-dialog
            width="30%"
            title="水印库"
            :visible.sync="innerVisible"
            append-to-body
            :modal-append-to-body="false"
          >
            <el-radio-group v-model="radio" @change="radioChecked">
              <el-radio
                v-for="(item, i) in waterData"
                :key="i"
                :value="item"
                :label="item"
              >
                <img :src="item.watermarkUrl" class="waterStyle" />
              </el-radio>
            </el-radio-group>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="waterSure">确 定</el-button>
            </div>
          </el-dialog>
          <div>
            <img :src="checkedWaterUrl" style="width: 30%" class="waterStyle" />
          </div>
          <ul class="generateLink" v-show="isShowLink">
            <li style="font-weight: 700">
              <span>账户名称</span>
              <span>生成链接</span>
            </li>
            <li v-for="(item, i) in linkData" :key="i">
              <span>{{ item.mtName }}</span>
              <span>{{ item.url }}</span>
            </li>
          </ul>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="newLink">生成链接</el-button>
            <el-button @click="sendoutVisible = false">关闭</el-button>
          </div>
        </el-dialog>
        <!-- 批量上传水印 -->
        <el-dialog
          title="发送设置"
          :visible.sync="sendoutBatchVisible"
          :modal-append-to-body="false"
          width="50%"
          class="sendSet"
        >
          <el-select
            v-model="accountName"
            filterable
            placeholder="选择账户名称"
            @change="getAccount($event)"
          >
            <el-option
              v-for="(item, i) in accountNameOptions"
              :key="i"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="addWaterMark"
            placeholder="请选择水印"
            @focus="getYes"
          ></el-input>
          <el-dialog
            width="30%"
            title="水印库"
            :visible.sync="innerVisible"
            append-to-body
            :modal-append-to-body="false"
          >
            <el-radio-group v-model="radio" @change="radioChecked">
              <el-radio
                v-for="(item, i) in waterData"
                :key="i"
                :value="item"
                :label="item"
              >
                <img :src="item.watermarkUrl" class="waterStyle" />
              </el-radio>
            </el-radio-group>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click="waterSure">确 定</el-button>
            </div>
          </el-dialog>
          <div>
            <img :src="checkedWaterUrl" style="width: 30%" class="waterStyle" />
          </div>
          <ul class="generateLink" v-show="isShowBacthLink">
            <li style="font-weight: 700">
              <span>账户名称</span>
              <span>生成链接</span>
            </li>
            <li v-for="(item, i) in bacthLinkData" :key="i">
              <span>{{ item.mtName }}</span>
              <span>{{ item.url }}</span>
            </li>
          </ul>
          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="newBatchLink">生成链接</el-button>
            <el-button @click="sendoutBatchVisible = false">关闭</el-button>
          </div>
        </el-dialog>
        <el-dialog
          title="同步今日头条"
          :visible.sync="sendToutiaoVisible"
          width="40%"
        >
          <el-select
            v-model="projecttoutiao"
            filterable
            placeholder="请选择同步项目名称"
            style="width: 260px; margin-right: 20px; margin-bottom: 10px"
            @change="projeactChangeSyn($event)"
          >
            <el-option
              v-for="(item, i) in projecttoutiaoOptions"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="syntoutiao"
            value-key="id"
            filterable
            multiple
            placeholder="请选择同步账户名称"
            style="width: 260px"
            @change="synChanneltt"
          >
            <el-option
              v-for="(item, i) in syntoutiaoOptions"
              :key="i"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <div slot="footer" class="dialog-footer">
            <el-button @click="sendToutiaoVisible = false">取 消</el-button>
            <el-button type="primary" @click="sendToutiaoSuccess"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <el-dialog title="同步快手" :visible.sync="sendKuaishouVisible">
          <el-select
            v-model="projectkuaishou"
            filterable
            placeholder="请选择同步项目名称"
            style="width: 260px; margin-right: 20px; margin-bottom: 10px"
            @change="projeactChangeksSyn($event)"
          >
            <el-option
              v-for="(item, i) in projectkuaishouOptions"
              :key="i"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="synkuaishou"
            value-key="id"
            multiple
            filterable
            placeholder="请选择同步账户"
            style="width: 260px"
            @change="synChannelks"
          >
            <el-option
              v-for="(item, i) in synkuaishouOptions"
              :key="i"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <div slot="footer" class="dialog-footer">
            <el-button @click="sendKuaishouVisible = false">取 消</el-button>
            <el-button type="primary" @click="sendKuaishouSuccess"
              >确 定</el-button
            >
          </div>
        </el-dialog>
       
        <div class="footerPaging">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40, 100]"
            :page-size="pageCount"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.totalRow"
          >
          </el-pagination>
        </div>
        <!-- 已推账户详情 -->
        <el-dialog
          title="已推账户详情"
          :visible.sync="showAccountDialog"
          :show-close = "false"
          :close-on-click-modal = "false"
          center
          width="600px">
          <!-- <div class="dialogIcon"><img :src='distributionIcon'></div> -->
          <div class="">
            <el-table
              ref="multipleTable"
              :data="showAccountData"
              tooltip-effect="dark"
              max-height="400px"
              min-height="100px"
              style="width: 100%">
              <el-table-column
                prop="advertiserId"
                label="账户ID">
              </el-table-column>
              <el-table-column
                prop="name"
                label="账户名称">
              </el-table-column>
            </el-table>
          </div>
          <div slot="footer">
            <el-button type="primary" @click="showAccountDialog = false">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 正在载入 -->
        <el-dialog
          title=""
          :visible.sync="loadingDialogVisible"
          width="20%"
          :destroy-on-close="true"
          :show-close="false"
          :close-on-click-modal="false"
          class="loadingBg">
          <span class="loading">
            <span>正在载入...</span>
            <i class="el-icon-loading"></i>
          </span>
        </el-dialog>
      </template>
      <template slot="headerGroup"> </template>
      <refuseReasonDialog
        :visible.sync="refuseIs"
        :typeName="typeName"
        :id="id"
        :pubVue="pubVue"
      ></refuseReasonDialog>
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import refuseReasonDialog from "@/views/dialog/refuseReasonDialog";
import distributionIcon from '@/assets/img/distributionIcon.png';
export default {
  name: "materiaList",
  components: {
    refuseReasonDialog,
  },
  data() {
    return {
      distributionIcon,
      loadingDialogVisible:true,//正在载入的弹框
      pubVue: this,
      refuseIs: false,
      showTheForm: true, //表头是否显示全部表单
      materiaChannelValue: "", //头部渠道搜索value
      qdoptions: [],
      materiaForm: {
        //头部查询表单的prop
        materiaChannelValue: "",
        mStatus: "",
        mType: "",
        mPosition: "",
        mTitle: "",
        mTab: "",
        mDate: "",
        mUser: "",
        mAccount: "",
        mCode: "",
        demandNameQuery: "",
        mUserGroup: "",
        myOldChannel: "",
        materialName: "",
        mCostStatus: "", //消耗状态
        searchmaterialId: "",
        mMaterialLaiyuan: "",
        selectSpecifications: "",
      },
      selectSpecificationsOption: [],
      myOldChannelOptions: [
        {
          label: "今日头条",
          value: "1",
        },
        {
          label: "快手",
          value: "2",
        },
      ],
      materiaStatusOptions: [
        {
          //查询表单的选择素材状态
          value: "1",
          label: "禁用",
        },
        {
          value: "2",
          label: "正常",
        },
        {
          value: "3",
          label: "待审核",
        },
        {
          value: "4",
          label: "运营审核通过",
        },
        {
          value: "5",
          label: "运营审核拒绝",
        },
        {
          value: "6",
          label: "客户审核拒绝",
        },
        {
          value: "7",
          label: "媒体审核拒绝",
        },
      ],
      materiaTabOptions: [], //查询表单的选择素材标签
      materiaUserOptions: [],
      materiaGroupOptions: [],
      materiaUserCostStatus: [
        { name: "未投放", id: 20 },
        { name: "无消耗", id: 30 },
        { name: "有消耗", id: 40 },
      ], //消耗状态下拉列表内容
      materiaAccountOptions: [],
      tableData: [],
      multipleSelection: [],
      // 分页
      page: 1, //当前页
      pageCount: 40, //每页大小
      totalRow: 0, //总条数
      totalPage: 0, //总页数
      materialParams: {
        //列表参数
        channlId: "",
        pages: "",
        pageCount: "",
        userId: "",
        status: "",
        copyWriting: "",
        endTime: "",
        startTime: "",
        createUserName: "",
        materialTag: "",
        materialType: "",
        demandNum: "",
        demandName: "",
        makerGroupId: "", //制作组
        costStatus: "", //消耗状态参数
        mtChannel: "",
        mtName: "",
      },
      deleteParams: {
        materialId: "",
        userId: "",
        username: "",
      },
      tagListParams: {
        //标签列表参数
        tagType: "",
        userId: "",
        channel: "",
      },
      queryParams: {
        //查询素材库列表
        channlId: "",
        page: "",
        pageCount: "",
        userId: "",
        status: "",
        copyWriting: "",
        endTime: "",
        startTime: "",
        createUserName: "",
        materialTag: "",
        materialType: "",
        demandNum: "",
      },
      formLabelWidth: "120px",
      activeName: "picture",
      pictureTag: "",
      videoTag: "",
      newTagParams: {
        createUser: "",
        tagName: "",
        tagType: 2,
      },
      sendoutVisible: false,
      accountName: "",
      addWaterMark: "",
      accountNameOptions: [],
      radio: "",
      waterMarkParams: {
        //获取列表和查询列表
        channel: "",
        accountId: "", //选择账户名称id
        userId: "", //选择当前用户运营人员id
        searchUserId: "", //选择运营人员id
      },
      innerVisible: false,
      bigpic: false,
      rejectionReason: "",
      editParams: {
        materialId: "",
        operationkey: "",
        tips: "",
        userId: "",
        username: "",
      },
      dialogFormVisible: false,
      imgArray: [],
      waterData: [],
      linkParams: {
        materialId: [],
        userId: "",
        userName: "",
        waterMarkId: "",
      },
      bacthlinkParams: {
        materialId: [],
        userId: "",
        userName: "",
        waterMarkId: "",
      },
      linkData: [],
      bacthLinkData: [],
      isShowLink: false,
      isShowBacthLink: false,
      materialIdsPara: [], //选中素材的materialIds
      channgetagParams: {
        channel: "",
        materialIds: [],
        oldTagId: "",
        userId: "",
        userName: "",
        newTagIds: "",
      },
      modifyTag: false,
      deleteTag: false,
      deletePeopleOptions: [],
      deletePeople: "",
      deltagParams: {
        channel: "",
        materialIds: [],
        oldTagId: "",
        userId: "",
        userName: "",
      },
      addTag: false,
      materialTagList: [],
      peopleTagList: [],
      peopleTagOptions: [],
      addParams: {
        tagIds: [],
        userIds: [],
        userId: "",
        userName: "",
      },
      sendttParams: {
        accountDtoList: [{}],
        materialIds: [],
        userId: "",
        userName: "",
      },
      sendksParams: {
        accountDtoList: [{}],
        materialIds: [],
        userId: "",
        userName: "",
      },
      newTagVisible: false,
      newtagName: "",
      addtagParams: {
        channel: "",
        createUser: "",
        tagName: "",
        tagType: "",
      },
      addHadTagVisible: false,
      addhadtagParams: {
        channel: "",
        materialIds: [],
        newTagIds: "",
        userId: "",
        userName: "",
      },
      sendToutiaoVisible: false,
      syntoutiao: [],
      syntoutiaoOptions: [],
      synkuaishouOptions: [],
      synkuaishou: [],
      projecttoutiao: "", //同步头条--项目select
      projecttoutiaoOptions: [], //同步头条--项目options
      projectkuaishou: "", //同步快手--项目select
      projectkuaishouOptions: [], //同步快手--项目options
      sendKuaishouVisible: false,
      materialImgList: [],
      materialImgListImg: [],
      materialImgListVideo: "",
      userIdAccount: "", //存储在本地的用户id||获取账户名称下拉列表入参
      userNameAccount: "", //存储在本地的用户name
      choiceTag: false,
      centerDialogVisible: false,
      videoUrlShow: "",
      typeFlags: "",
      firstWord: "?x-oss-process=video/snapshot,t_1,m_fast",
      checkedWaterUrl: "",
      sendoutBatchVisible: false,
      tagNameTable: "", //修改/删除素材标签name
      tagIdTable: "", //修改/删除素材标签id
      batchIsPastParams: {
        materialIds: [],
        userId: 0,
        username: "",
      },
      ptojectTTParams: {
        materialIds: [],
      },
      //运营/客户审核通过入参
      batchIsPastUrl: [
        "batchPassMaterialByUser", //运营接口
        "batchPassMaterialByCostmer",
      ], //客户接口
      linksjsParams: {},
      sjslinkData: [],
      sjssendoutVisible: false,
      plsjssendoutVisible: false,
      typeName: "素材",
      id: null,
      getchannelId: "",
      getchannelIdnum: "",
      uploadingShow: true,
      exprtVisible: false,
      screenHeight: window.innerHeight - 336,
      detailData: {},
      videoUrlLj: "",
      imgUrlList: [],
      loadIn: false, //是否加载视频
      imgj: [],
      materialDetailVisible: false,
      mMaterialLaiyuanOption: [
        {
          value: "user",
          label: "运营上传",
        },
        {
          value: "des",
          label: "设计上传",
        },
      ],
      resourceBitsOption: [],
      editTagVisible: false, //修改标签名称弹框
      editForm: {
        changeTags: "",
        newTagNames: "",
      },
      editrules: {
        newTagNames: [
          { required: true, message: "请输入新标签名称", trigger: "blur" },
        ],
      },
      dialogFormVisible: false,
      jujueform: {
        rejectionReason: "",
      },
      rules: {
        rejectionReason: [
          { required: true, message: "请输入拒绝理由", trigger: "blur" },
        ],
      },
      refuseIds: [],
      refuseIndex: "",
      differenceIndex: "",
      exportVisible: false, //批量导出的时候的弹框
      // 修改素材名称
      dialogMedit: false,
      miEditForm: {
        maNameEdit: "",
      },
      dialogPushMaterial: false,
      miPushMaterialForm: {
        pushAccount: [],
      },
      deptId: "",
      showAccountDialog:false,
      showAccountData:[],
    };
  },
  computed: {},
  mounted() {
    this.userIdAccount = this.$sessionStorage.getItem(config.USERID) || 1;
    this.userNameAccount = this.$sessionStorage.getItem(config.USERNAME);
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
    this.getuserChannel();
    this.getStaffDetail();
    this.getAccountList();
  },
  methods: {
    resourceBitsList(getchannelId) {
      let that = this,
        params = {};
      params = {
        channelId: this.getchannelId || getchannelId,
        materialType: 2,
      };
      $http.axios
        .post("/api/operationManagement/materialTypeSize/listByChannel", params)
        .then((res) => {
          if (res.data.code == 200) {
            that.resourceBitsOption = res.data.data;
            that.selectSpecificationsOption = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    refuseFn(id) {
      this.refuseIs = true;
      this.id = id;
    },
    //头部表单重置
    resetForm(materiaForm) {
      this.$refs[materiaForm].resetFields();
      this.$router.go(0);
    },

    //点击按钮不变色
    handleClick(event) {
      let target = event.target;
      if (target.nodeName == "I") {
        target = event.target.parentNode;
      }
      target.blur();
    },

    //修改后的关联到user的channel
    getuserChannel() {
      let that = this;
      let userChannel = String(that.userIdAccount);
      $http.axios
        .get("/api/system/user/getChannelByUser?userId=" + userChannel)
        .then(function (response) {
          console.log(response.data.data);
          if (response.data.data.length == 0) {
            that.getMaterialList();
          } else {
            that.qdoptions = response.data.data;
            that.materiaForm.materiaChannelValue = response.data.data[0].name;
            if (that.$route.query.channelId != undefined) {
              that.materiaForm.myOldChannel = that.$route.query.channelId;
              that.getchannelId = that.$route.query.channelId;
              that.resourceBitsList(that.getchannelId);
            } else {
              that.materiaForm.myOldChannel = response.data.data[0].id;
              that.getchannelId = String(response.data.data[0].id);
              that.resourceBitsList(that.getchannelId);
            }
            if (that.getchannelId == 5) {
              that.materiaForm.mStatus = that.$route.query.mStatus;
            }
            that.materialParams.channlId = response.data.data[0].id;
            that.tagListParams.channel = response.data.data[0].id;
            that.materialParams.channlId = response.data.data[0].id;
            that.waterMarkParams.channel = response.data.data[0].id;
            that.channgetagParams.channel = response.data.data[0].id;
            that.deltagParams.channel = response.data.data[0].id;
            that.addtagParams.channel = response.data.data[0].id;
            that.addhadtagParams.channel = response.data.data[0].id;
            // that.getchannelId = String(response.data.data[0].id)
            that.getchannelIdnum = response.data.data[0].id;
            that.getMaterialList();
            that.produceGroupList();
            that.resourceBitsList();
          }
        });
    },

    //根据id弹出视频
    showVideo(row) {
      this.centerDialogVisible = true;
      this.videoUrlShow = row.url.videoUrl;
      this.$nextTick(() => {
        this.$refs.videoPouse.play();
      });
    },
    handleDialogClose() {
      this.centerDialogVisible = false;
      this.$refs.videoPouse.pause();
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString, name))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    //模糊搜索带参数的版本
    multipleFn(queryString, cb, name) {
      var restaurants = this.tableData;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString, name))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString, name) {
      return (restaurant) => {
        if (name == "wa") {
          // mainTitile主题文案
          return (
            restaurant.mainTitile
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) === 0
          );
        } else if (name == "sc") {
          // mtName素材名称
          return (
            restaurant.mtName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) === 0
          );
        } else if (name == "gd") {
          //demandName工单名称
          return (
            restaurant.demandName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) === 0
          );
        }
      };
    },

    handleSelect(item) {
      console.log(item);
    },

    materialShow() {
      this.$router.push({
        name: "authManagement.MaterialListPic",
        query: { channelId: this.materiaForm.myOldChannel },
      });
      // this.$router.push({name:'authManagement.MaterialListPic',query:{channelId:this.getChannelId}})
    },
    //上传素材按钮
    materialUploadMateriaPicWait(id) {
      if (this.getchannelId == 5) {
        this.$router.push({
          path: "/auth-management/UploadMaterialPicWait/" + id,
          query: { mStatus: this.materiaForm.mStatus, type: 1, status: 222 },
        }); //2是图文
      } else {
        this.$router.push({
          path: "/auth-management/UploadMaterialPicWait/" + id,
        });
      }
    },
    //结束重新上传
    stopReUpLoadFn(id) {
      let _this = this,
        type;
      $http.axios
        .get("/api/operationManagement/material/stopReUpLoad?materialId=" + id)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.getMaterialList(1);
            type = "success";
          } else {
            type = "error";
          }
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: type,
          });
        });
    },
    // 点击按钮进入水印库
    waterMark() {
      this.$router.push("/auth-management/WaterMark");
    },

    // 跳转视频修改页
    videoEdit(e) {
      this.$router.push({ path: "/auth-management/VideoEdit/" + e });
    },
    //表头点击向右三角显示
    showList() {
      this.showTheForm = false;
    },
    //表头点击向上三角隐藏
    hiddenList() {
      this.showTheForm = true;
    },

    //获取渠道id
    getChannelId(event) {
      this.materialParams.channlId = event;
    },
    //获取素材状态
    getStatus(event) {
      this.materialParams.status = event;
    },
    //获取制作人id
    getUserId(event, codeName) {
      this.materialParams[codeName] = event;
    },

    //获取账户名称下拉列表
    getAccountList() {
      let that = this;
      if (that.getchannelId == 3) {
        $http.axios
          .get(
            "/api/channel/accountUser/selectList?userId=" + that.userIdAccount + "&type=1"
          )
          .then(function (response) {
            that.materiaAccountOptions = response.data;
            that.syntoutiaoOptions = response.data;
            that.synkuaishouOptions = response.data;
            that.accountNameOptions = response.data;
            that.accountOptions = response.data;
          });
      } else if (that.getchannelId == 4) {
        $http.axios
          .get("/api/viat/accountUser/selectList?userId=" + that.userIdAccount)
          .then(function (response) {
            that.materiaAccountOptions = response.data;
            that.syntoutiaoOptions = response.data;
            that.synkuaishouOptions = response.data;
            that.accountNameOptions = response.data;
            that.accountOptions = response.data;
          });
      } else if (that.getchannelId == 5) {
        $http.axios
          .get("/api/xmat/accountUser/selectList?userId=" + that.userIdAccount)
          .then(function (response) {
            that.materiaAccountOptions = response.data;
            that.syntoutiaoOptions = response.data;
            that.synkuaishouOptions = response.data;
            that.accountNameOptions = response.data;
            that.accountOptions = response.data;
          });
      }
      // $http.axios.get("/api/channel/accountUser/selectList?userId="+that.userIdAccount).then(function(response) {
      //   that.materiaAccountOptions = response.data
      //   that.syntoutiaoOptions = response.data
      //   that.synkuaishouOptions = response.data
      //   that.accountNameOptions = response.data
      // })
    },

    //制作组无参下拉框
    produceGroupList() {
      let that = this;
      $http.axios
        .get(
          "/api/operationManagement/materialDemand/channelgrouplist?channelId=" +
            that.getchannelId
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.materiaGroupOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    yuanlaichannelId(val) {
      this.getchannelId = String(val);
      this.getchannelIdnum = val;
      this.produceGroupList();
      this.getMaterialList();
    },

    //获取素材库列表
    getMaterialList(page) {
      this.loadingDialogVisible = true
      let that = this;
      if (page == 1) {
        that.page = 1;
      }
      that.materialParams.copyWriting = that.materiaForm.mTitle;
      that.materialParams.demandName = that.materiaForm.demandNameQuery;
      that.materialParams.endTime = that.materiaForm.mDate[1];
      that.materialParams.startTime = that.materiaForm.mDate[0];
      that.materialParams.demandNum = that.materiaForm.mCode;
      that.materialParams.makerGroupId = that.materiaForm.mUserGroup;
      that.materialParams.mtChannel = that.materiaForm.myOldChannel;
      that.materialParams.channlId = that.materiaForm.myOldChannel;
      that.materialParams.mtName = that.materiaForm.materialName;
      that.materialParams.materialType = 2;
      that.materialParams.pages = that.page;
      that.materialParams.pageCount = that.pageCount;
      that.materialParams.userId = that.userIdAccount;
      that.materialParams.materialId = that.materiaForm.searchmaterialId;
      that.materialParams.uploadType = that.materiaForm.mMaterialLaiyuan;
      that.materialParams.globalSpecId = that.materiaForm.selectSpecifications; //搜索素材规格
      that.materialParams.status = that.materiaForm.mStatus;
      $http.axios
        .post("/api/operationManagement/material/list", that.materialParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.loadingDialogVisible = false
            that.tableData = res.data.data.items;
            that.totalRow = res.data.data.totalCount;
            that.tagNameTable = res.data.data.tagName;
            that.tagIdTable = res.data.data.tagId;
            if (that.tagIdTable != null) {
              that.choiceTag = true;
            }
            that.typeFlags = res.data.data.typeFlag;
            that.tableData.forEach((obj) => {
              if (obj.costStatus == 20) {
                obj["costStatusName"] = "未投放";
              } else if (obj.costStatus == 30) {
                obj["costStatusName"] = "无消耗";
              } else if (obj.costStatus == 40) {
                obj["costStatusName"] = "有消耗";
              }
            });
            that.resourceBitsList();
          } else {
            that.loadingDialogVisible = false
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //获取人员信息
    getStaffDetail() {
      let that = this;
      $http.axios
        .get("/api/system/user/optionselect")
        .then(function (response) {
          that.materiaUserOptions = response.data;
          that.peopleTagOptions = response.data;
          that.deletePeopleOptions = response.data;
        });
    },

    //删除素材列表
    deletematerial(e) {
      let that = this;
      this.$alert(
        `<div><img class="icon" src="${require("@/assets/img/deleteIcon.png")}" /><div class="text_tip">您是否确认删除，删除后不可恢复？</div></div>`,
        "删除",
        {
          center: true,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          customClass: "icon_custom_popup",
          beforeClose: (action, instance, done) => {
            if (action == "confirm") {
              instance.confirmButtonLoading = true;
              that.deleteParams.materialId = e;
              that.deleteParams.userId = that.userIdAccount;
              that.deleteParams.username = that.userNameAccount;
              $http.axios
                .post(
                  "/api/operationManagement/material/delmaterial",
                  that.deleteParams
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    that.getMaterialList();
                    done();
                    instance.confirmButtonLoading = false;
                  } else {
                    that.$message({
                      showClose: true,
                      message: res.data.msg,
                      type: "error",
                    });
                    instance.confirmButtonLoading = false;
                  }
                })
                .catch(() => {
                  instance.confirmButtonLoading = false;
                });
            } else {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },

    focusTag() {
      this.getTagList();
    },

    //查询的素材标签下拉框数据
    getTagList(event) {
      let that = this;
      that.materialParams.materialTag = event;
      that.tagListParams.tagType = 2;
      that.tagListParams.userId = that.userIdAccount;
      that.tagListParams.channel = that.materiaForm.myOldChannel;
      $http.axios
        .post("/api/operationManagement/material/taglist", that.tagListParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.materiaTabOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //查询素材库列表
    queryMaterialList() {
      let that = this;
      that.queryParams.pages = that.page;
      that.queryParams.pageCount = that.pageCount;
      that.queryParams.userId = that.userIdAccount;
      that.queryParams.copyWriting = that.materiaForm.mTitle;
      that.queryParams.endTime = that.materiaForm.mDate[1];
      that.queryParams.startTime = that.materiaForm.mDate[0];
      that.queryParams.materialType = 2;
      that.queryParams.demandNum = that.materiaForm.mCode;
      $http.axios
        .post("/api/operationManagement/material/list", that.queryParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.tableData = [];
            that.tableData = res.data.data.items;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    modifyTagList(event) {
      this.channgetagParams.newTagIds = event;
    },
    //修改权限
    queryjurisdiction() {
      let that = this;
      that.chooseTableFn("modifyTag"); //批量选中
    },

    //确定修改权限
    queryjurisdictionSure() {
      let that = this;
      that.channgetagParams.materialIds = that.materialIdsPara;
      that.channgetagParams.oldTagId = that.tagIdTable;
      that.channgetagParams.userId = that.userIdAccount;
      that.channgetagParams.userName = that.userNameAccount;
      that.channgetagParams.channel = that.getchannelIdnum;
      if (that.channgetagParams.newTagIds != "") {
        //如果没有选择素材标签消息提示
        that.modifyTag = false;
        $http.axios
          .post(
            "/api/operationManagement/material/channgetag",
            that.channgetagParams
          )
          .then((res) => {
            if (res.data.code == 200) {
              that.getMaterialList();
            } else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      } else {
        that.$message({
          showClose: true,
          message: "请选择素材标签",
          type: "error",
        });
      }
    },

    //打开删除权限弹框
    deletejurisdiction() {
      let that = this;
      that.chooseTableFn("deleteTag");
    },

    //点击删除权限确定按钮
    deletejurisdictionSure() {
      let that = this;
      that.deleteTag = false;
      that.deltagParams.materialIds = that.materialIdsPara;
      that.deltagParams.oldTagId = that.tagIdTable;
      that.deltagParams.userId = that.userIdAccount;
      that.deltagParams.userName = that.userNameAccount;
      that.deltagParams.channel = that.getchannelIdnum;
      $http.axios
        .post("/api/operationManagement/material/deltag", that.deltagParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.getMaterialList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //点击新增弹出弹框
    addjurisdiction() {
      let that = this;
      that.addTag = true;
      that.getTagList();
    },

    //运营/客户审核通过
    batchIsPast(i) {
      if (this.chooseTableFn("")) {
        this.batchIsPastParams.materialIds = this.materialIdsPara;
        this.batchIsPastParams.userId = this.userIdAccount;
        this.batchIsPastParams.username = this.userNameAccount;
        $http.axios
          .post(
            "/api/operationManagement/material/" + this.batchIsPastUrl[i],
            this.batchIsPastParams
          )
          .then((res) => {
            if (res.data.code == 200) {
              setTimeout(() => {
                this.getMaterialList();
              }, 1000);
            } else {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      }
    },

    //获取标签列表id集合
    mTagChange(event) {
      this.addParams.tagIds = event;
    },

    //获取调用人员id集合
    pTagChange(event) {
      this.addParams.userIds = event;
    },

    //新增权限
    addjurisdictionSure() {
      let that = this;
      that.addTag = false;
      that.addParams.materialIds = that.materialIdsPara;
      that.addParams.userId = that.userIdAccount;
      that.addParams.userName = that.userNameAccount;
      $http.axios
        .post("/api/operationManagement/material/destag", that.addParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.getMaterialList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //发送
    sendOut(e) {
      let that = this;
      that.sendoutVisible = true;
      that.radio = "";
      that.accountName = "";
      that.addWaterMark = "";
      that.isShowLink = false;
      that.linkData = [];
      that.checkedWaterUrl = "";
      that.linkParams.materialId = [];
      that.linkParams.materialId.push(e);
    },
    //选择有水印之后弹出框
    getYes() {
      let that = this;
      if (that.waterData.length == 0) {
        that.innerVisible = false;
        that.$message("请先到水印库添加水印");
      } else {
        that.innerVisible = true;
      }
    },
    getAccount(event) {
      this.waterMarkParams.accountId = event.id;
      this.accountName = event.name;
      this.getWaterMarkList();
    },
    //获取水印库列表
    getWaterMarkList() {
      let that = this;
      that.waterMarkParams.userId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      that.waterMarkParams.searchUserId =
        that.$sessionStorage.getItem(config.USERID) || 1;
      that.waterMarkParams.channel = that.materiaForm.myOldChannel;
      $http.axios
        .post(
          "/api/operationManagement/watermark/v1/getWaterMarkList",
          that.waterMarkParams
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.waterData = response.data.data.data;
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //单选选中水印的id
    radioChecked(val) {
      this.linkParams.waterMarkId = val.id;
      this.bacthlinkParams.waterMarkId = val.id;
      this.checkedWaterUrl = val.watermarkUrl;
    },

    waterSure() {
      this.innerVisible = false;
    },

    //生成链接
    newLink() {
      let that = this;
      that.linkParams.userId = that.userIdAccount;
      that.linkParams.userName = that.userNameAccount;
      $http.axios
        .post(
          "/api/operationManagement/material/getwatervideo",
          that.linkParams
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.linkData = response.data.data;
            that.isShowLink = true;
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //设计师生成链接
    sjsSendOut(val) {
      let that = this;
      console.log(val);
      that.linkParams.materialId.push(val);
      that.linkParams.userId = that.userIdAccount;
      that.linkParams.userName = that.userNameAccount;
      that.linkParams.waterMarkId = 47;
      $http.axios
        .post(
          "/api/operationManagement/material/getwatervideo",
          that.linkParams
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.sjslinkData = response.data.data;
            that.sjssendoutVisible = true;
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //批量上传水印
    batchSenout() {
      let that = this;
      that.radio = "";
      that.accountName = "";
      that.addWaterMark = "";
      that.isShowBacthLink = false;
      that.bacthLinkData = [];
      that.checkedWaterUrl = "";
      that.chooseTableFn("sendoutBatchVisible");
    },

    //批量上传水印--确定   同newLink方法相同
    newBatchLink() {
      let that = this;
      that.bacthlinkParams.materialId = that.materialIdsPara;
      that.bacthlinkParams.userId = that.userIdAccount;
      that.bacthlinkParams.userName = that.userNameAccount;
      $http.axios
        .post(
          "/api/operationManagement/material/getwatervideo",
          that.bacthlinkParams
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.bacthLinkData = response.data.data;
            that.isShowBacthLink = true;
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //设计师批量发送
    sjsbatchSenout() {
      this.chooseTableFn();
      let that = this;
      if (that.materialIdsPara.length != 0) {
        that.bacthlinkParams.materialId = that.materialIdsPara;
        that.bacthlinkParams.userId = that.userIdAccount;
        that.bacthlinkParams.userName = that.userNameAccount;
        that.bacthlinkParams.waterMarkId = 47;
        $http.axios
          .post(
            "/api/operationManagement/material/getwatervideo",
            that.bacthlinkParams
          )
          .then(function (response) {
            if (response.data.code == 200) {
              that.bacthLinkData = response.data.data;
              that.plsjssendoutVisible = true;
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: "error",
              });
            }
          });
      }
    },

    //修改审核状态--通过
    adopt(e) {
      let that = this;
      that.editParams.materialId = e;
      that.editParams.userId = that.userIdAccount;
      that.editParams.username = that.userNameAccount;
      that.editParams.operationkey = "pass";
      $http.axios
        .post("/api/operationManagement/material/changestatus", that.editParams)
        .then((res) => {
          if (res.data.code == 200) {
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //拒绝
    rejection(e) {
      let that = this;
      that.dialogFormVisible = false;
      that.editParams.materialId = e;
      that.editParams.userId = that.userIdAccount;
      that.editParams.username = that.userNameAccount;
      that.editParams.operationkey = "refuse";
      that.editParams.tips = that.rejectionReason;
      $http.axios
        .post("/api/operationManagement/material/changestatus", that.editParams)
        .then((res) => {
          if (res.data.code == 200) {
            this.getMaterialList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //同步今日头条
    sendToutiao() {
      let that = this;
      that.chooseTableFn("sendToutiaoVisible");
      that.getTToptions(); //点击弹框获取项目名称的数据
    },

    //批量同步媒体--点击弹框获取项目名称接口
    getTToptions() {
      let that = this;
      that.ptojectTTParams.materialIds = that.materialIdsPara;
      $http.axios
        .post(
          "/api/operationManagement/material/queryProject",
          that.ptojectTTParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.projecttoutiaoOptions = res.data.data;
            that.projectkuaishouOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //同步媒体时如果选择了同步项目名称，则改变同步账户名称的下拉列表接口数据，如下
    projeactChangeSyn(event) {
      this.syntoutiao = [];
      this.changeSyn(event);
    },
    projeactChangeksSyn(event) {
      this.synkuaishou = [];
      this.changeSyn(event);
    },
    //新数据下拉列表
    changeSyn(val) {
      let that = this;
      let projectId = val;
      $http.axios
        .get(
          "/api/channel/account/queryAccountByProject?projectId=" + projectId
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.syntoutiaoOptions = res.data.data;
            that.synkuaishouOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //选中素材的materialIds
    chooseTableFn(visible) {
      let that = this;
      let sendttSelection = that.$refs.multipleTable.selection;
      that.materialIdsPara = [];
      that.refuseIds = [];
      sendttSelection.forEach(function (e, i) {
        that.materialIdsPara.push(e.id);
        that.refuseIds.push(e.id);
      });
      if (sendttSelection.length == 0) {
        that.$message({
          message: "请勾选素材",
          type: "warning",
        });
        that[visible] = false;
      } else {
        that[visible] = true;
        return true;
      }
    },

    //头条
    synChanneltt(val) {
      let that = this;
      that.sendttParams.accountDtoList = [];
      val.forEach(function (e, i) {
        that.sendttParams.accountDtoList.push({
          accountId: e.id,
          channel: e.channelId,
        });
      });
    },

    // 快手
    synChannelks(val) {
      let that = this;
      that.sendksParams.accountDtoList = [];
      val.forEach(function (e, i) {
        that.sendksParams.accountDtoList.push({
          accountId: e.id,
          channel: e.channelId,
        });
      });
    },
    //同步媒体
    sendToutiaoSuccess() {
      let that = this;
      that.sendToutiaoVisible = false;
      that.sendttParams.materialIds = that.materialIdsPara;
      that.sendttParams.userId = that.userIdAccount;
      that.sendttParams.userName = that.userNameAccount;
      $http.axios
        .post("/api/operationManagement/material/devsendacc", that.sendttParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.$message(
              "系统已开始同步素材至" +
                that.sendttParams.userName +
                "帐号,同步结果稍后将以钉钉消息通知您"
            );
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg || "工单异常",
              type: "error",
            });
          }
        });
    },

    //同步快手
    sendKuaishou() {
      let that = this;
      that.chooseTableFn("sendKuaishouVisible");
      that.getTToptions();
    },
    sendKuaishouSuccess() {
      let that = this;
      that.sendKuaishouVisible = false;
      that.sendksParams.materialIds = that.materialIdsPara;
      that.sendksParams.userId = that.userIdAccount;
      that.sendksParams.userName = that.userNameAccount;
      $http.axios
        .post("/api/operationManagement/material/devsendacc", that.sendksParams)
        .then((res) => {
          if (res.data.code == 200) {
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg || "工单异常",
              type: "error",
            });
          }
        });
    },

    //标签按钮-新建标签和已有标签
    handleCommand(command) {
      let that = this;
      if (command == "newTag") {
        that.newTagVisible = true;
      } else if (command == "editTagName") {
        that.editTagVisible = true;
        that.getTagList();
        // that.chooseTableFn('editTagVisible')
      } else {
        that.getTagList();
        that.chooseTableFn("addHadTagVisible");
      }
    },

    //创建标签点击确定
    newtagSuccess() {
      let that = this;
      that.addtagParams.createUser = that.userIdAccount;
      that.addtagParams.tagName = that.newtagName;
      that.addtagParams.tagType = 2;
      that.addtagParams.channel = that.getchannelIdnum;
      $http.axios
        .post("/api/operationManagement/material/addtag", that.addtagParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.newTagVisible = false;
            that.getMaterialList();
            that.newtagName = "";
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //取消新建标签
    canclenewTag() {
      this.newTagVisible = false;
      this.newtagName = "";
    },

    //获取已有标签要更换标签的id
    changeAddhadTag(event) {
      this.addhadtagParams.newTagIds = event;
    },

    //添加已有标签-确定
    addHadTagSuccess() {
      let that = this;
      that.addHadTagVisible = false;
      that.addhadtagParams.materialIds = that.materialIdsPara;
      that.addhadtagParams.userId = that.userIdAccount;
      that.addhadtagParams.userName = that.userNameAccount;
      that.addhadtagParams.channel = that.getchannelIdnum;
      $http.axios
        .post(
          "/api/operationManagement/material/addmaterialtag",
          that.addhadtagParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            this.getMaterialList();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //修改标签名称
    editTagSuccess(editForm) {
      let that = this,
        params = {};
      that.$refs.editForm.validate((valid) => {
        if (valid) {
          params = {
            id: that.editForm.changeTags,
            tagName: that.editForm.newTagNames,
            updateUser: that.userIdAccount,
          };
          $http.axios
            .post("/api/operationManagement/material/updateTagName", params)
            .then(function (response) {
              if (response.data.code == 200) {
                that.editTagVisible = false;
                that.$refs.editForm.resetFields();
                that.getAccountList();
                that.$router.go(0);
              } else {
                that.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: "error",
                });
              }
            });
        }
      });
    },

    //取消修改标签名称的弹框
    editTagCancle(editForm) {
      this.editTagVisible = false;
      this.$refs.editForm.resetFields();
    },

    //批量下载
    batchDownload() {
      let that = this,
        materialIdsPara = [],
        materialIdsParaString = "";
      let sendttSelection = that.$refs.multipleTable.selection;
      $http.axios.defaults.timeout = 500000;
      sendttSelection.forEach(function (e, i) {
        materialIdsPara.push(e.id);
      });
      if (sendttSelection.length == 0) {
        that.$message({
          message: "请勾选素材",
          type: "warning",
        });
      } else {
        that.exprtVisible = true;
        materialIdsParaString = String(materialIdsPara);
        $http.axios
          .get(
            "/api/operationManagement/material/downLoadMaterial?mids=" +
              materialIdsPara,
            { responseType: "blob", dataType: "json", async: false }
          )
          .then((res) => {
            console.log(res.data);
            let blob = new Blob([res.data], { type: "application/zip" });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download = "批量下载素材"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url); // 释放内存
            that.exprtVisible = false;
          });
      }
    },

    // 批量导出
    batchExport() {
      let that = this,
        materialIdsPara = [],
        materialIdsParaString = "";
      let sendttSelection = that.$refs.multipleTable.selection;
      that.exportVisible = true;
      $http.axios.defaults.timeout = 500000;
      sendttSelection.forEach(function (e, i) {
        materialIdsPara.push(e.id);
      });
      that.materialParams.ids = materialIdsPara;
      console.log(that.materialParams.ids);
      $http.axios
        .post(
          "/api/operationManagement/material/exportVido",
          that.materialParams,
          { responseType: "blob", dataType: "json", async: false }
        )
        .then((res) => {
          //添加{responseType: 'blob'}  导出文件以下代码都是
          let blob = res.data;
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = (e) => {
            let a = document.createElement("a");
            a.download = "视频批量导出";
            a.href = e.target.result;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            that.exportVisible = false;
          };
        });
    },

    // 分页
    handleSizeChange(val) {
      this.pageCount = val;
      var params = {
        page: this.page,
        pageCount: this.pageCount,
      };
      this.findAll(params);
    },
    handleCurrentChange(val) {
      this.page = val;
      var params = {
        page: this.page,
        pageCount: this.pageCount,
      };
      this.findAll(params);
    },
    //分页方法
    findAll(params) {
      //获取数据接口（调用封装的接口）
      this.getMaterialList();
    },
    //表格中选中和未选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //点击详情
    videoDetail(detailId) {
      this.materialDetailVisible = true;
      this.getDetail(detailId);
    },

    getDetail(detailId) {
      let that = this;
      $http.axios
        .post("/api/operationManagement/material/" + detailId)
        .then((res) => {
          if (res.data.code == 200) {
            that.loadIn = false;
            that.detailData = res.data.data;
            that.videoUrlLj = that.detailData.url.videoUrl;
            that.imgj = that.detailData.url.imgList;
            that.imgUrlList = that.detailData.url.imgList;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    copy() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = this.detailData.localUrl; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    },

    reloadVideo(loadIn) {
      let _this = this
      if (loadIn == false) {
        this.$refs.videoPouset.src = this.videoUrlLj;
        this.$refs.videoPouset.play();
        let timer = setTimeout(function () {
          _this.loadIn = true;
          window.clearTimeout(timer);
        }, 1000);
      } else {
        this.loadIn = false;
      }
      console.log(document.readyState);
    },

    refuselingFn(id) {
      this.dialogFormVisible = true;
      this.refuseId = id;
    },

    // //拒绝
    // refuselingwaiFn(jujueform){
    //   let that = this,params={}
    //   that.$refs.jujueform.validate((valid) => {
    //     if (valid) {
    //       params={
    //         'materialId':that.refuseId,
    //         'operationkey':'refuse',
    //         'userId':that.$sessionStorage.getItem(config.USERID) || 1,
    //         'username':that.$sessionStorage.getItem(config.USERNAME),
    //         'tips' : that.jujueform.rejectionReason
    //       }
    //       $http.axios.post("/api/operationManagement/material/changestatus",params).then(res=> {
    //         if(res.data.code == 200){
    //           that.dialogFormVisible = false
    //           that.getMaterialList()
    //         }  else {
    //           that.$message({
    //             showClose: true,
    //             message: res.data.msg,
    //             type: 'error'
    //           })
    //         }
    //       })
    //     } else {
    //       return false;
    //     }
    //   });
    // },
    //拒绝
    refuselingwaiFn(jujueform) {
      let that = this,
        params = {};
      that.$refs.jujueform.validate((valid) => {
        if (valid) {
          if (that.refuseIndex == 1) {
            params = {
              materialIdList: that.refuseIds,
              userId: that.$sessionStorage.getItem(config.USERID) || 1,
              username: that.$sessionStorage.getItem(config.USERNAME),
              tips: that.jujueform.rejectionReason,
              status: that.differenceIndex,
            };
            $http.axios
              .post(
                "/api/operationManagement/material/changeStatusMore",
                params
              )
              .then((res) => {
                if (res.data.code == 200) {
                  that.dialogFormVisible = false;
                  that.getMaterialList();
                } else {
                  that.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
          } else if (that.refuseIndex == 2) {
            params = {
              materialIdList: that.refuseIds,
              tips: that.jujueform.rejectionReason,
              userName: that.userNameAccount,
            };
            $http.axios
              .post("/api/operationManagement/material/mediaRefuseMore", params)
              .then((res) => {
                if (res.data.code == 200) {
                  that.dialogFormVisible = false;
                  that.getMaterialList();
                } else {
                  that.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
          } else {
            params = {
              materialId: that.refuseId,
              operationkey: "refuse",
              userId: that.$sessionStorage.getItem(config.USERID) || 1,
              username: that.$sessionStorage.getItem(config.USERNAME),
              tips: that.jujueform.rejectionReason,
            };
            $http.axios
              .post("/api/operationManagement/material/changestatus", params)
              .then((res) => {
                if (res.data.code == 200) {
                  that.dialogFormVisible = false;
                  that.getMaterialList();
                } else {
                  that.$message({
                    showClose: true,
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
          }
        } else {
          return false;
        }
      });
    },

    //取消拒绝弹框
    canclelingwaiFn(jujueform) {
      this.dialogFormVisible = false;
      this.$refs.jujueform.resetFields();
    },

    //运营和客户批量拒绝
    operateRefuse(index, differenceIndex) {
      this.chooseTableFn("dialogFormVisible");
      this.refuseIndex = index;
      this.differenceIndex = differenceIndex;
    },
    //媒体批量拒绝
    mediaRefuse(index) {
      this.chooseTableFn("dialogFormVisible");
      this.refuseIndex = index;
    },

    //修改素材名称
    materialNameEdit(row) {
      this.materialIdsPara = [];
      this.dialogMedit = true;
      this.miEditForm.maNameEdit = row.mtName;
      this.materialIdsPara.push(row.id);
    },

    //修改素材名称-确定
    editMaterialName() {
      let _this = this,
        params = {};
      params = {
        idList: _this.materialIdsPara,
        mtName: _this.miEditForm.maNameEdit,
        updateUser: _this.userIdAccount,
      };
      $http.axios
        .post("/api/operationManagement/material/updateMtName", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.dialogMedit = false;
            _this.getMaterialList();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //批量修改素材名称
    batchMaterialNameEdit() {
      this.chooseTableFn("dialogMedit");
      this.miEditForm.maNameEdit = "";
    },

    //推送素材
    pushMaterial() {
      this.chooseTableFn("dialogPushMaterial");
      this.getAccountList();
    },

    //推送素材-确定
    pushMaterialSure() {
      console.log(this.materialIdsPara);
      let _this = this,
        params = {};
      $http.axios.defaults.timeout = 500000;
      params = {
        deptId: _this.deptId,
        userId: _this.userIdAccount,
        userName: _this.userNameAccount,
        materialIdIdList: _this.materialIdsPara,
        customerIdList: _this.miPushMaterialForm.pushAccount,
      };
      $http.axios
        .post("/api/operationManagement/miMaterial/getMiMaterialIdVo", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.dialogPushMaterial = false;
            _this.getMaterialList();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    //已推账户详情
    showAccountDetail(row){
      console.log(row.pushAccounts)
      this.showAccountDialog = true
      this.showAccountData = row.pushAccounts
    },

  },
};
</script>
<style scoped>
.el-table .cell {
  transition: all 0.3s ease;
}
.el-table .cell .tip {
  position: absolute;
  top: 6px;
  left: 10px;
}
img {
  width: 20%;
}
</style>

<style lang="scss" scoped>
.materialHead {
  font-size: 0;
}
.materialHchannel,
.materialHBtns {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
}
.materialHchannel {
  width: 30%;
}
.materialHBtns {
  width: 70%;
}
.materiaContent {
  margin-top: 25px;
}
.showPorV {
  text-align: center;
}
.generateLink li {
  font-size: 0;
  margin: 10px 0;
}
.generateLink li span {
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  box-sizing: border-box;
  padding: 0 5px;
  font-size: 14px;
}
.showTheForm,
.hideenTheForm {
  display: inline-block;
  vertical-align: top;
}
.showTheForm {
  position: relative;
}
.showTheForm i {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -11px;
  font-size: 22px;
  color: rgb(36, 132, 168);
  cursor: pointer;
}
.hideenTheForm i {
  font-size: 22px;
  vertical-align: middle;
  color: rgb(36, 132, 168);
  cursor: pointer;
  margin: 0 20px;
}
.sendSet .el-select {
  display: block;
  margin: 10px 0;
}
::v-deep .mineBg {
  .el-dialog {
    background: none !important;
    box-shadow: none !important;
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
      font-size: 24px;
    }
  }
}
.waterStyle {
  width: 50%;
  display: block;
  margin: 10px auto;
}
.returnBtn {
  text-align: center;
}
.returnList {
  padding: 10px 30px;
}
/*编辑*/
.miEdit {
  color: #2484a8;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
}
.el-card {
  overflow: visible;
}
.loading{
  text-align:center;
}
.loading span,.loading i{
  display: block;
}
.loading span{
  font-size:20px;
  font-weight:700;
  color:#fff;
}
.loading i{
  margin-top:10px;
  font-size:60px;
  /* color: #409eff; */
  color:#fff;
}
/* .loadingBg{
  margin-top:200px;
} */
::v-deep .loadingBg .el-dialog{
  margin-top:200px;
  background:rgba(255,255,255,0)!important;
  box-shadow: none;
}


</style>
