<template>
  <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>广告计划</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-button type="primary" size="small" @click="warmIs('dayLimitPrice',url[1],3)">批量调价</el-button>
              <el-button type="primary" size="small" @click="goPlan('/yybAccount-management/priceModifyAdvertyyb')">调价计划列表页面</el-button>
            </div>
          </div>  

          <div class="chanelTab">
              <span class="channel_vivo" @click="clickChangeChannel('adPlanYyb')">计划</span>
              <span class="channel_vivo channelActive">广告</span>
          </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <!-- 创意名称/创意ID -->
                <el-form-item label="">
                  <el-input v-model = "advertId" clearable placeholder="请输入广告名称"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select v-model="taskStatus" clearable placeholder="请选择广告状态" >
                    <el-option
                      v-for="(item,i) in taskStatusOptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <div class="block">
                    <el-date-picker
                    v-model="value1"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" class="newQuery" size="small" @click = "getListFn(page)">查询</el-button>
                  <el-button type="info" size="small" @click = 'resetForm()'>重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
          </div>
          <div class="clearfix">          
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @cell-click="clickcell"
                :cell-style="setRowStyle"
                @selection-change="handleSelectionChange">

                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                
                <template >
                    <el-table-column
                    v-for="(item, index) in titleList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else>
                          <span v-if='scope.column.property == "modifyPricePlanIds"'>
                            {{ showStatusList[scope.column.property][Number(Boolean(scope.row[scope.column.property]))] }}
                            <i v-if = 'scope.column.property =="modifyPricePlanIds" '
                            :class = "{'isSwitch':scope.column.property =='modifyPricePlanIds','active':(scope.column.property =='modifyPricePlanIds')&&scope.row[scope.column.property]!=0}"></i>
                          </span>

                          <span v-else>
                             
                            {{ scope.row[scope.column.property] }}
                          </span>
                        </div> 
                       
                      </template>
                    </el-table-column>
                  </template>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <startLimitSingleDialog ref = 'dayLimitDom' :visible.sync="showSingleDialog" :nowIs = "nowIs" :yulanList = "yulanList" :limitIs = "limitIs" :page = 'page' :userContent = 'userContent' :dayType = "dayType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleDialog>
    <tiaojiaDialog ref = 'tiaojiaDialog' :visible.sync="tiaojiaDialogIs" :pubVue="pubVue"></tiaojiaDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';


import startLimitSingleDialog from '@/views/dialog/startLimitSingleAppDialog'
import tiaojiaDialog from '@/views/dialog/tiaojiaAppDialog'
import TimeUnit from '../TimeUnit.vue';
import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')
export default {
  components: {



    // zdyMi,//自定义页面
    startLimitSingleDialog,//调价页面->批量调价&&批量日限额
    tiaojiaDialog,
    timeUnit:TimeUnit
  },
  props:{
    newVue:Object,
  },
  data() {
    return {
      pubVue: this,//空vue，用于子组件之间传值



      tiaojiaDialogIs:false,
      showSingleDialog:false,//调价页面是否展示
      nowIs : false,
      modifyState:false,//修改状态下拉列表是否展示
     
      titleList:[
        {name:'广告名称',prop:'adName'},
        {name:'广告ID',prop:'adId'},
        {name:'状态',prop:'creativeStatus'},
        {name:'出价',prop:'adPrice'},
        {name:'调价计划',prop:'modifyPricePlanIds'},//1->是；其他->否
      ],
      showStatusList:{
                      modifyPricePlanIds:['否','是'],//调价计划               

                      deliveryStatusDisplayName:['']},               
      value1:[formatDate(new Date()),formatDate(new Date())],//时间
      tableData:[],
      
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      adIds:[],//创意ID集合
      adGroupIds:[],//广告组ID集合
      yulanList:[],
      dayType:null,
      dialogTitle:'批量调价',
      limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
      userContent:{},//登录用户信息&弹框默认
      url:['accountWarning/checkAccountWarning',//校验广告主是否设置过预警信息
          'modifyPrice/valid'],//预警是否开启、是否设置过定时调价计划
      myHeaders: {Authorization:''},
      command:'',//??
      groupName:'',
      advertId:'',//创意ID
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      btnShow:false,//鼠标划到表格上出现修改按钮
      editNameVisible:false,//修改广告名称
      editNameForm:{},
      editName:'',//广告名称修改字段
      editType:'',
      editId:'',
      editDateForm:{},
      activeName:'first',
      firstDate:'',//长期投放的日期
      secondDate:[],//修改开始和结束日期
      getGroupIds:'',//获取批量修改日期和时间段的ids集合
      activeTime:'first',//进弹框显示第一个
      timeUnitShow:true,//设置执行时间
      testAdvertType:{},//声明一个创意类型的对象后期有接口之后删除即可
      sortMode:'',//列表升序降序
      closeAdList:[],//校验是否有存在的启停计划
      sums:{},//合计
      taskStatus:'',//任务状态
       
      creativeStatusList:{
        'STATUS_PENDING':'审核中',
        'STATUS_DENIED':'审核不通过',
        'STATUS_SUSPEND':'暂停中',
        'STATUS_READY':'未到投放时间',
        'STATUS_ACTIVE':'投放中',
        'STATUS_STOP':'投放结束',
        'STATUS_ACTIVE_CAMPAIGN_SUSPEND':'广告被暂停（推广计划暂停）',
        'STATUS_PART_READY':'部分待投放',
        'STATUS_PART_ACTIVE':'部分投放中',
      },
      taskStatusOptions:[{
        value:'STATUS_PENDING',
        label:'审核中'
      },{
        value:'STATUS_DENIED',
        label:'审核不通过'
      },{
        value:'STATUS_SUSPEND',
        label:'暂停中'
      },{
        value:'STATUS_READY',
        label:'未到投放时间'
      },{
        value:'STATUS_ACTIVE',
        label:'投放中'
      },{
        value:'STATUS_STOP',
        label:'投放结束'
      },{
        value:'STATUS_ACTIVE_CAMPAIGN_SUSPEND',
        label:'广告被暂停（推广计划暂停）'
      },{
        value:'STATUS_PART_READY',
        label:'部分待投放'
      },{
        value:'STATUS_PART_ACTIVE',
        label:'部分投放中'
      }],
      DN:'yyb'//域名:Domain Name的缩写

    }
  },
  
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.userContent.ownerId = this.$route.query.id
    this.userContent.ownerName = this.$route.query.name
    this.value1 = [formatDate(new Date()),formatDate(new Date())]
    this.getId()
    
    this.getListFn(1)
  },
  methods: {
    //tab切换
    clickChangeChannel(url){
      this.$router.push({
        path: '/YybAccount-management/'+url,
        query: {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName,
        }
      })
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
   
  
    
     
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      row.ownerId
      let yjType = event.target.parentNode.dataset.type
      
      let className = event.target.className
      this.adIds = [row.id]//点击当前单元格添加创意ID
      
      
      if(column.property=="campaignName"){//点击所属计划名称跳转到相应的计划列表页
        this.goPlan('/miAccount-management/adPlanMi',{
          kw:row.campaignId,
        })
      }
      if(column.property=="groupName"){//点击所属广告组名称跳转到相应的广告组列表页
        this.goPlan('/miAccount-management/adTeamMi',{
          kw:row.groupId,
        })
      }
      
       if(column.property =="modifyPricePlanIds"){
          if(row.modifyPricePlanIds!=0){
            this.tiaojiaDialogIs = true
            this.$refs.tiaojiaDialog.getTimeSlotDetail(row.modifyPricePlanIds,this.userContent)
          }
      }
      
    },
  
    // 页面跳转
    goPlan(url,query){//
      let _this = this,query1 =  {
      id: this.userContent.ownerId,
      name:this.userContent.ownerName}
      query = Object.assign({},_this.clone(query), query1)
      this.$router.push({
        path: url,
        query: query
      })
    }, 
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },

    // 分页
     handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
        _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(this.page)
     
    }, 
    //分页方法
    findAll (page) {
      //获取数据接口（调用封装的接口）
      this.page = page
      this.getListFn(page)
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let adIds = [],adGroupIds = [],yulanList = [],objList = [],startSwitch = [],num = 0
      _this.limitIs = false
      val.forEach((obj,i,arr)=>{
        let cur = obj.pauseState
        adIds.push(obj.adId)
        adGroupIds.push(obj.adGroupId)
        startSwitch.push(obj.startSwitch)
        yulanList.push({'ownerId':_this.userContent.ownerId,'ownerName':_this.userContent.ownerName,'adGroupId':obj.adGroupId,'adId':obj.adId,'adName':obj.adName,'price':obj.adPrice})
        // num++
        objList[cur] = cur
      })
        // _this.dayLimitIs = true
        // if(val.length == num){
        //     _this.dayLimitIs = true
        //   }else{
        //     _this.dayLimitIs = false
        //   }
      _this.adIds = adIds
      _this.adGroupIds = adGroupIds
      // _this.startSwitch = startSwitch
      _this.yulanList = yulanList
      if(val.length == 0){
        _this.modifyState = false

      }else{
        _this.modifyState = true
      }
    },
    //重置
    resetForm(){
      let _this = this
      _this.clearFn()
      _this.getListFn()
      _this.value1 = [formatDate(new Date()),formatDate(new Date())]//时间
      
    },
    clearFn(){
      let _this = this
      _this.advertId = ''
      _this.taskStatus = ''//

    },

    //创意列表
    getListFn(page){
      let _this = this,params,pageTxt,response
      pageTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : page || 1,
          'sDate':  _this.value1[0],
          'eDate':  _this.value1[1],
          //搜索项
          'name':_this.advertId,//广告名称
          'creativeStatus':_this.taskStatus,//投放状态
        }
      params = Object.assign({},_this.clone(_this.userContent), pageTxt)      
      $http.axios.post("/api/yyb/ad/list",params).then(res=> {
        if(res.data.code==200){
          response = res.data
          if(response.data.infos != null){
              _this.tableData = response.data.infos
              _this.tableData.forEach((obj,i)=>{
                _this.$set(obj,'creativeStatus',_this.creativeStatusList[obj.creativeStatus])
                
             
                if(obj.modifyPricePlanIds==''||obj.modifyPricePlanIds==null){
                  _this.$set(obj,'modifyPricePlanIds',0)
                }
                if(obj.warningSwitch == null){
                  obj.warningSwitch = '未设置'
                }else if(obj.warningSwitch == 0){
                  obj.warningSwitch = '开'
                }else if(obj.warningSwitch == 1){
                  obj.warningSwitch = '关'
                }
              })
            _this.totalRow = response.data.pageInfo.rowCount
          }else{
            _this.tableData = []
            _this.totalRow = 0

          }
          _this.sums = response.total
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
    updateSet(type){
      let _this = this
      let adIds = []//声明一个空数组
        _this.adIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          adIds.push(v)
        }
      })
      
      if(adIds.length!=0){
        _this.adIds = adIds
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
    //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs(type,url,dayType){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      let _this = this
      let isTrue = _this.updateSet(type)
      _this.modifyState = false
      _this.dayType = dayType
      if(isTrue){
          if(type == 'dayLimit'|| type == "dayLimitPrice"){
            _this.yulanList.forEach(obj=>{
              if(type == 'dayLimit'){
                obj.price = obj.dayBudget
              }                
            })
            
            _this.dayLimitCheckFn(dayType,url)
          }
      }     
    },
    dayLimitCheckFn(dayType,url){
      let _this = this,idList = [],DN = this.DN
        _this.adGroupIds.forEach(obj=>{
          idList.push(obj)
        }) 
        $http.axios.get("/api/"+DN+"/"+url+"?ids="+idList+"&type="+dayType).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.data
          if(content == null){
              this.showSingleDialog = true
              this.nowIs = false
            }else{
              let text = content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                this.showSingleDialog = true
                this.nowIs = true
              }).catch(()=>{
                
              })
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 1){
        return 'color: #2F674D' 
      }else{
        return 'color: #606266' 
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  
  .timeTab .el-tabs__item{
    padding:0!important;
    padding:10px!important;
    border:1px solid #ccc!important;
  }
  .statusBtn{
    padding:3px 8px;
    background:rgba(103,194,58,.1);
    color:#67c23a;
  }
  .statusOtherBtn{
    padding:3px 8px;
    background:rgba(245,108,108,.1);
    color:#f56c6c;
  }
  .statusBtnFinish{
    padding:3px 8px;
    background:rgba(144,147,153,.1);
    color:#909399;
  }
  .uploadIcon{
    font-size:40px;
    display:block;
    text-align:center;
  }
  .isStyle i{
    font-style:normal;
    padding-right:10px;
  }
  /* tab切换当前样式 */
  .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }

// 投放状态按钮样式
  .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .statusOtherBtnwei{
      padding:3px 8px;
      background:rgba(230,162,60,.1);
      color:#E6A23C;
    }
    /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
</style>
