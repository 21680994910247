<template>
  <el-dialog
    :visible.sync="show"
    width="640px"
    class="icon_custom_popup"
    :close-on-click-modal="false"
    @close="onCancel"
    :title="title + '模板详情'"
  >
    <img class="icon" src="@/assets/img/suCaiIcon.png" />
    <div class="datails_contant">
      <el-form :label-width="type == 2 ? '116px' : '78px'" class="between">
        <el-form-item label="模板名称">
          <div class="news">{{ fileList.reportName }}</div>
        </el-form-item>
        <el-form-item label="广告主名称">
          <div class="news">{{ fileList.account }}</div>
        </el-form-item>
        <el-form-item label="报表名称" style="width: 59%">
          <div class="news">{{ fileList.title }}</div>
        </el-form-item>
        <el-form-item label="报表维度" style="width: 39%">
          <div class="news">{{ adTypeList[fileList.adType] || "-" }}</div>
        </el-form-item>
        <div
          class="width_100"
          v-if="
            fileList.excelConfig &&
            fileList.excelConfig.dataSource &&
            fileList.excelConfig.dataSource.length > 0
          "
        >
          <div class="titles">数据源sheet</div>
          <el-form-item
            v-for="(item, i) in fileList.excelConfig.dataSource"
            :key="i"
            :label="item.name"
          >
            <div class="news" v-for="(ele, j) in item.owners" :key="j">
              <span v-if="j > 0">、</span>{{ ele.ownerId }}
            </div>
          </el-form-item>
        </div>
        <div
          class="width_100"
          v-if="
            fileList.excelConfig &&
            fileList.excelConfig.keyword &&
            fileList.excelConfig.keyword.length > 0
          "
        >
          <div class="titles">关键词</div>
          <el-form-item
            v-for="(item, i) in fileList.excelConfig.keyword"
            :key="i"
            :label="item.name"
          >
            <div class="news" v-for="(ele, j) in item.owners" :key="j">
              <span v-if="j > 0">、</span>{{ ele.ownerId }}
            </div>
          </el-form-item>
        </div>
        <div class="titles">其他</div>
        <el-form-item label="接收人">
          <div class="news">{{ fileList.senderName }}</div>
        </el-form-item>
        <el-form-item label="抄送人" style="width: 49%">
          <div class="news">{{ fileList.copyUserName }}</div>
        </el-form-item>
        <el-form-item label="接收方式" style="width: 49%">
          <div class="news">{{ fileList.senderF }}</div>
        </el-form-item>
        <el-form-item
          label="接收时间"
          :style="{ width: type == 2 ? '49%' : '' }"
        >
          <div class="news">{{ fileList.time }}</div>
        </el-form-item>
        <el-form-item
          label="是否拉取零时数据"
          style="width: 49%; margin-bottom: 10px"
          v-if="type == 2"
        >
          <div class="news">
            <el-switch
              disabled
              v-model="fileList.isNeedYesterday"
              :active-value="2"
              :inactive-value="1"
            >
            </el-switch>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="primary" @click="onCancel">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";

export default {
  props: {
    title: String,
    type: Number,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      adTypeList: {
        10: "计划",
        20: "广告组",
        30: "广告",
        40: "账户",
        50: "创意",
      },
      needList: ["否", "是"],
      fileList: {
        // accout:"LX_Test_10006(1100018015)",
        // adType:10,
        // channelId:3,
        // copyUser:"",
        // copyUserName:"",
        // deptId:270,
        // excelConfig:{"dataSource":{"数据源":[1100018015],"数据源 (2)":[1100018015]}},
        // excelSwitch:2,
        // id:40,
        // imgSwtich:1,
        // isNeedYesterday:2,
        // path:"/home/hljy/op/report/sourceModel/76cb3b23-4de6-49cb-bd00-1e943f74d9cd.xlsx",
        // reportName:"人",
        // reportNum:"H1639470406778",
        // reportSwitch:2,
        // sender:"315",
        // senderName:"OPPO-开发组-测试优化师01-315",
        // status:2,
        // time:["10.5"],
        // title:"{渠道}{时段}",
        // type:2,
        // userId:315,
      },
      tableData: [],
    };
  },

  watch: {
    show(v) {
      this.$emit("update:visible", v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted() {
    console.log(555);
  },
  methods: {
    //user的channel渠道接口
    getuserChannel(id) {
      let _this = this,
        time = [];
      console.log(333);
      $http.axios
        .get("/api/vivoReport/reportModel/toModify?id=" + id)
        .then(function (res) {
          if (res.data.code == 200) {
            _this.fileList = res.data.data;
            _this.fileList.excelConfig.dataSource =
              res.data.data.excelConfig.dataSource;
            _this.fileList.excelConfig.keyword =
              res.data.data.excelConfig.keyword;
            _this.fileList.time = res.data.data.time;
            if (res.data.data.imgSwtich == 2) {
              _this.fileList.senderF = "图片";
            }
            if (res.data.data.excelSwitch == 2) {
              _this.fileList.senderF = "Excel";
            }
            if (
              res.data.data.excelSwitch == 2 &&
              res.data.data.imgSwtich == 2
            ) {
              _this.fileList.senderF = "Excel、图片";
            }
            if (_this.type == 2 && _this.fileList.time != null) {
              _this.fileList.time.forEach((obj) => {
                if (obj % 1 === 0) {
                  time.push(obj + ":00");
                } else {
                  time.push(Math.floor(obj) + ":30");
                }
              });
              _this.fileList.time = time.toString();
            }
          }
        });
    },
    onCancel() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

