<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>计划管理</el-breadcrumb-item>
              <el-breadcrumb-item  :to="{ path: '/miAccount-management/AccountSelectionMi' }">账户选择</el-breadcrumb-item>
              <el-breadcrumb-item>启停展示</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div> 
        <ul class="modifyTab">
          <li @click="startStopPlan">计划</li>
          <li class="modifyActive">广告组</li>
        </ul>
          <div class="channelFlowHead">
              <el-form :inline="true" :model="priceForm" ref="priceForm" class="demo-form-inline">
                  <el-form-item label="" prop="planName">
                      <el-autocomplete
                          class="inline-input"
                          v-model="priceForm.planName"
                          value-key="planName"
                          :fetch-suggestions="querySearch"
                          placeholder="请输入广告组ID"
                          @select="handleSelect"
                      ></el-autocomplete>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" class="newQuery" size="small" @click="startStopList">查询</el-button>
                    <el-button type="info" size="small" @click="resetForm('priceForm')">重置</el-button>
                  </el-form-item>
              </el-form> 
          </div>
          <div class="channelFlowBody">
              <div class="channelTable">
                  <el-table
                    ref="multipleTable"
                    :data="tableData"
                    border
                    style="width: 100%">
                    <el-table-column
                    type="selection"
                    width="50">
                    </el-table-column>
                    <el-table-column
                      prop="groupName"
                      label="计划名称">
                      <template slot-scope="scope">{{ scope.row.adPlanName }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="groupeID"
                      label="计划ID"
                      width="200">
                      <template slot-scope="scope">{{ scope.row.adPlanId }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="groupName"
                      label="广告组名称">
                      <template slot-scope="scope">{{ scope.row.adGroupName }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="groupeID"
                      label="广告组ID"
                      width="200">
                      <template slot-scope="scope">{{ scope.row.adGroupId }}</template>
                    </el-table-column>
                    <el-table-column
                      prop="priceTime"
                      label="执行内容">
                      <template slot-scope="scope">
                        <span class="priceTimes" @click="timeSlot(scope.row)">{{(scope.row.planIds).split(",").length}}个时间计划</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="operation"
                      label="操作">
                      <template slot-scope="scope">
                        <el-button class="solid-tag-success" plain size="mini" @click="addPriceList(scope.row)">广告组</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- 时间段弹框 -->
                  <el-dialog title="" :visible.sync="dialogTableVisible" width="100%">
                      <el-form :inline="true" :model="timeForm" class="demo-form-inline">
                          <el-form-item label="" prop="">
                              <el-select v-model="implementStatus" placeholder="请选择执行状态" filterable clearable @change="implementStatusChange">
                                  <el-option
                                    v-for="(item,i) in implementOptions"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.id">
                                  </el-option>
                                </el-select>
                          </el-form-item>
                          <el-form-item label="" prop="">
                              <el-date-picker
                                  v-model="dateChoice"
                                  type="daterange"
                                  format="yyyy-MM-dd"
                                  value-format="yyyy-MM-dd"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期"
                                  @change="changeDate">
                              </el-date-picker>
                          </el-form-item>
                      </el-form> 
                      <el-table 
                        :data="gridData"
                        ref="multipleTable">
                          <el-table-column
                            type="selection"
                            width="50">
                          </el-table-column>
                          <el-table-column
                            property="dateRange"
                            label="启停日期"
                            width="200">
                          </el-table-column>
                          <el-table-column
                            property="priceDateSlot"
                            label="启停时间段"
                            width="200">
                            <template slot-scope="scope">
                              <div v-for="(time,i) in scope.row.details" :key="i">
                                <span>
                                  <strong v-if="time.week == 1">周一</strong>
                                  <strong v-if="time.week == 2">周二</strong>
                                  <strong v-if="time.week == 3">周三</strong>
                                  <strong v-if="time.week == 4">周四</strong>
                                  <strong v-if="time.week == 5">周五</strong>
                                  <strong v-if="time.week == 6">周六</strong>
                                  <strong v-if="time.week == 7">周日</strong>
                                </span>
                                <span>：{{time.timeRange}}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="priceDateSlot"
                            label="当前时段是否执行">
                            <template slot-scope="scope">
                              <div v-for="(time,i) in scope.row.details" :key="i">
                                <span>{{time.excludeFlag}}</span>
                              </div>
                            </template>
                          </el-table-column>
                          <!-- <el-table-column
                            property="executeFlag"
                            label="今日是否执行">
                          </el-table-column> -->
                          <el-table-column
                            property="implementStatus"
                            label="目标状态">
                            <template slot-scope="scope">
                              <span v-if="scope.row.switchFlag == 1">开启</span>
                              <span v-else>暂停</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="implementStatus"
                            label="执行状态">
                            <template slot-scope="scope">
                              <span v-if="scope.row.status == 1">待执行</span>
                              <span v-else-if="scope.row.status == 2">执行中</span>
                              <span v-else-if="scope.row.status == 3">执行完成</span>
                              <span v-else-if="scope.row.status == 4">异常</span>
                              <span v-else-if="scope.row.status == 5">弃用</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            property="operation"
                            label="操作">
                            <template slot-scope="scope">
                              <div class="opBtn">
                                <span v-if="scope.row.status == 1">
                                </span>
                                <span v-else></span>
                              </div>
                              <div class="opBtn">
                                <span v-if="scope.row.status == 1 || scope.row.status == 2">
                                  <el-popconfirm
                                    title="确定要弃用吗？"
                                    @confirm="stopUse(scope.row.id)">
                                    <template #reference>
                                      <el-button class="newBtn_garyWhite" plain size="mini" style="margin-left:10px;">弃用</el-button>
                                    </template>
                                  </el-popconfirm>
                                </span>
                                <span v-else></span>
                              </div>
                              <div class="opBtn">
                                <span  v-if="scope.row.status == 5">
                                  <el-popconfirm
                                    title="您是否确认删除，删除后不可恢复？"
                                    @confirm="delUse(scope.row.id)">
                                    <template #reference>
                                      <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                                    </template>
                                  </el-popconfirm>
                                </span>
                                <span v-else></span>
                              </div>
                            </template>
                          </el-table-column>
                      </el-table>
                      <div class="pageWrap">
                          <div class="pageBatch">
                            <el-button @click="allTimeDelete" size="small">批量删除</el-button>
                          </div>
                          <el-dialog
                            title=""
                            :visible.sync="timeVisible"
                            width="30%"
                            append-to-body
                            >
                            <span style="display:block;width:100%;text-align:center;">确定要删除吗</span>
                            <span slot="footer" class="dialog-footer">
                              <el-button @click="delTimeCancel">取 消</el-button>
                              <el-button type="primary" @click="delTimeSure">确 定</el-button>
                            </span>
                          </el-dialog>
                          <div class="paging">
                            <el-pagination
                              @size-change="handleSizeChange"
                              @current-change="handleCurrentChange"
                              :current-page="detailpageNum"
                              :page-sizes="[10, 20, 30, 40]"
                              :page-size="detailpageSize"
                              layout="total, sizes, prev, pager, next, jumper"
                              :total="this.detailtotalRow">
                            </el-pagination>
                          </div>
                        </div>
                  </el-dialog>
                  <!-- 时间段弹框结束 -->
              </div>
              <div class="pageWrap">
                <div class="pageBatch">
                </div>
                  <div class="paging">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="pageNum"
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.totalRow">
                    </el-pagination>
                  </div>
              </div>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import Qs from 'qs';
export default {
  name: 'priceModify',
  data() {
    return {
      userId:'',
      userName:'',
      deptId:'',
      priceForm:{
          planName:'',//搜索计划名称
      },
      sandsStatusOptions:[{
        name:'全部',
        id:1
      },{
        name:'未设置',
        id:2
      },{
        name:'已设置',
        id:3
      }],
      adStatusOptions:[{
          name:'全部',
          id:1
      },{
          name:'启动中',
          id:2
      },{
          name:'暂停中',
          id:3
      },{
          name:'不在推广时段',
          id:4
      },{
          name:'计划暂停',
          id:5
      }
      ],
      tableData: [],//启停列表数据
      // 分页
      pageNum: 1,//当前页
      pageSize: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      detailpageNum: 1,//当前页
      detailpageSize: 10,//每页大小
      detailtotalRow: 0,//总条数
      dialogTableVisible:false,//时间段弹框
      gridData: [],//时间段表格数据
      timeForm:{},//时间段弹框查询form
      implementStatus:'',//时间段弹框执行状态
      implementOptions:[{//时间段弹框执行状态选项
          name:'待执行',
          id:1
      },{
          name:'执行中',
          id:2
      },{
          name:'已执行完成',
          id:3
      },{
          name:'异常',
          id:4
      },{
          name:'弃用',
          id:5
      }],
      dateChoice:'',
      batchOperation:'',
      batchOperations:'',
      ssParams:{//启停列表入参
        userName:'',
        userId:'',
        deptId:'',
        pageIndex:'',
        pageSize:'',
        ownerId:'',//上一级带过来的id
        adType:'',
        name:'',//搜索条件，非必传
        id:''
      },
      ssDetailParams:{//启停页面点击执行内容，详情入参
        userId:'',
        userName:'',
        deptId:'',
        pageIndex:'',
        pageSize:'',
        planIds:'',
        type:'',
        startDate:'',
        endDate:''
      },
      stopParams:{//弃用入参
        // deptId:'',
        // userId:'',
        // userName:'',
        // id:''
        ids:[],
        type:''
      },
      dialogVisible:false,//批量删除弹框显示
      delParams:{
        ids:[],
        type:''
      },
      timeVisible:false,//执行内容弹框中的批量删除弹框
      listId:'',//列表的id
      SSownerId:'',//上级获取的ownerId
      SSownerName:'',//上级获取的ownerName
      ownerName:'',
      ownerId:''

    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.SSownerId = this.$route.query.id
    this.SSownerName = this.$route.query.name
    this.ssParams.ownerId = this.SSownerId
    this.ownerId = this.$route.query.id
    this.ownerName = this.$route.query.name
    this.startStopList()//启停展示页列表
  },
  methods: {
    //头部表单重置
    resetForm(priceForm) {
      this.$refs[priceForm].resetFields();
      this.$router.go(0)
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
          return (restaurant.planName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },

    handleSelect(item) {
      console.log(item);
    },

    //跳转到广告组启停列表
    startStopPlan(){
      this.$router.push({name:'MiAccountManagement.StartStopMi',query:{id:this.ownerId,name:this.ownerName}})
    },

    //启停展示列表
    startStopList(){
      let that = this
      that.ssParams.userName = that.userName
      that.ssParams.userId = that.userId
      that.ssParams.deptId = that.deptId
      that.ssParams.pageIndex = that.pageNum
      that.ssParams.pageSize = that.pageSize
      that.ssParams.adType = 2    //计划
      // that.ssParams.id = that.priceForm.planName
      $http.axios.post("/api/mi/openStop/list",that.ssParams).then(res=> {
        if(res.data.code == 200){
          that.tableData = res.data.data.infos
          that.totalRow = res.data.data.pageInfo.rowCount
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //时间段点击显示弹框--详情
    timeSlot(row){
      this.dialogTableVisible = true
      this.ssDetailParams.planIds = row.planIds
      this.timeSlotDetail()
      this.listId = row.id
    },

    //点击时间段详情接口
    timeSlotDetail(){
      let that = this
      that.ssDetailParams.userId = that.userId
      that.ssDetailParams.userName = that.userName
      that.ssDetailParams.deptId = that.deptId
      that.ssDetailParams.pageIndex = that.detailpageNum
      that.ssDetailParams.pageSize = that.detailpageSize
      that.ssDetailParams.type = that.implementStatus
      that.ssDetailParams.startDate = that.dateChoice[0]
      that.ssDetailParams.endDate = that.dateChoice[1]
      
      $http.axios.post("/api/mi/openStop/infoList",that.ssDetailParams).then(res=> {
        if(res.data.code == 200){
          that.gridData = res.data.data.infos
          that.detailtotalRow = res.data.data.pageInfo.rowCount
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //点击添加按钮跳转到调价设置页面
    addPriceList(row){
      console.log(row)
      this.$router.push({path:'/miAccount-management/adTeamMi',query:{id:this.ownerId,name:this.ownerName}})
    },

    //弃用
    stopUse(id){
      let that = this,ids = []
      ids.push(id)
      that.stopParams.ids = String(ids)
      that.stopParams.type = 2
      $http.axios.post("/api/mi/openStop/updateStatus",Qs.stringify(that.stopParams),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code == 200){
          that.timeSlotDetail()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //详情弹框中的查询--时间
    changeDate(){
      this.timeSlotDetail()
    },

    //详情弹框中的查询--执行状态
    implementStatusChange(val){
      this.ssDetailParams.type = val
      this.timeSlotDetail()
    },

    //选中的选项
    chooseTableFn(visible){
      let that = this,ids = [],status=''
      let sendttSelection = that.$refs.multipleTable.selection
      sendttSelection.forEach(function(e,i){
        ids.push(e.id)
        status = e.status
        // that.delParams.ids.push(e.id)
      })
      that.delParams.ids = String(ids)
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选要删除的模板',
          type: 'warning'
        });
        that[visible]=false
      }else if(status != 5){
        that.$message({
          message: '请勾选执行状态为弃用的行',
          type: 'warning'
        })
        that[visible]=false
      }else{
        that[visible]=true
      }
    },

    //批量删除
    allDelete(){
      this.chooseTableFn('dialogVisible')
      // this.startStopList()
    },

    //取消删除
    delCancel(){
      this.dialogVisible = false
      this.$router.go(0)
    },

    //执行内容中的批量删除
    allTimeDelete(){
      this.chooseTableFn('timeVisible')
    },

     //执行内容中的批量删除--取消删除
     delTimeCancel(){
      this.timeVisible = false
      // this.$router.go(0)
    },

    //执行内容中的批量删除--确定全部删除
    delTimeSure(){
      let that = this 
      that.delParams.type = 1
      $http.axios.post("/api/mi/openStop/updateStatus",Qs.stringify(that.delParams),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code == 200){
          that.timeSlotDetail()
          that.startStopList()
          that.timeVisible = false
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //执行内容弹框中的单个删除
    delUse(i){
      let that = this,ids = []
      ids.push(i)
      that.delParams.ids = String(ids)
      that.delParams.type = 1
      $http.axios.post("/api/mi/openStop/updateStatus",Qs.stringify(that.delParams),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code == 200){
          that.timeSlotDetail()
          that.startStopList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      this.detailpageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        detailpageNum: this.detailpageNum,
        detailpageSize: this.detailpageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.detailpageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        detailpageNum: this.detailpageNum,
        detailpageSize: this.detailpageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.startStopList()
      this.timeSlotDetail()
    },
  }
}
</script>

<style lang="scss" scoped>
  .priceTimes{
    color:cornflowerblue;
    cursor: pointer;
  }
  .pageWrap{
    clear:both;
    margin-top:15px;
    font-size:0;
  }
  .pageBatch,.paging{
      display:inline-block;
      vertical-align:middle;
      width:50%;
      font-size:12px;
  }
  .pageBatch{
    /* float:left; */
  }
  .paging{
    text-align:right;
  }
  .tabList{
    padding: 0px;
    margin-left: -8px;
  }
  .tabList li{
    padding: 0 20px;
    height: 40px;
    box-sizing: border-box;
    line-height: 40px;
    display: inline-block;
    list-style: none;
    font-size: 14px;
    font-weight: 500;
    color: #303133; 
    cursor: pointer;   
  }
  .tabList li.active,.tabList li:hover{
    color: #66856d;
  }
   /* 新版 */
   .modifyTab{
    padding:0;
    margin:0;
    font-size:0;
    margin-bottom:20px;
    margin-top:20px;
    border-bottom:1px solid #F5F7F6;
  }
  .modifyTab li {
    display:inline-block;
    vertical-align:middle;
    width:80px;
    font-size:14px;
    text-align:center;
    cursor: pointer;
    background:#F5F7F6;
    color:#2F674D;
    padding:3px 8px;
    border-radius: 8px 8px 0px 0px;
    margin-right:10px;
    font-weight:500;
  }
  .modifyTab .modifyActive{
    background:#48916F;
    color:#fff;
  }
  ::v-deep .channelFlowHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
</style>
