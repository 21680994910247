
<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="添加抄送人"
  >
<!-- <el-dialog title="添加抄送人" :visible.sync="dialogFormVisible_add"> -->
  <el-form :model="addForm">
    <el-form-item label="人员名称" :label-width="formLabelWidth">
      <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入添加人员姓名" style="width:100%!important;"></el-input>
    </el-form-item>
    <el-form-item label="邮箱地址" :label-width="formLabelWidth">
      <el-input v-model="addForm.email" autocomplete="off" placeholder="请输入添加邮箱地址" style="width:100%!important;"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible_add = false">取 消</el-button>
    <el-button type="primary" @click="csrSure">确 定</el-button>
  </div>
</el-dialog>
</template>
<script>
export default {
  data(){
    return{
      show: this.visible,
      addForm: {
          name: '',
          email:''
        },
      formLabelWidth: '120px',
    }
  },
  methods:{
    onCancel() {
      this.show= false;
      this.addForm = {
          name: '',
          email:''
        }

      console.log('关闭')
    },
    //添加抄送人
    csrSure(){
      let that = this
      that.dialogFormVisible_add = false
      // that.csrParams.accountId = '71754670438'
      // that.csrParams.accountId = String(that.userDetailId)
      // that.csrParams.email = that.addForm.email
      // that.csrParams.userName = that.addForm.name
      let userName = that.addForm.name
      let email = that.addForm.email
      $http.axios.get("/api/system/user/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
        if(response.data.code == 200){
          console.log(response)
          that.getUserList()
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
  }
}
</script>