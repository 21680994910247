<template>
  <el-dialog
    :visible.sync="show"
    width="400px"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    @close = 'onCancel'
    :title=costTitle
    center
    :show-close = "false">
    <div class="dialogIcon"><img :src='accountCost'></div>
    <el-form
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules">
      <el-form-item  v-if="(warningFieldType == 0)" :label="label" prop="">
        <el-switch
          v-model="switchValue"
          active-color="#48916F"
          inactive-color="#EAEFED"
          active-value="0"
          inactive-value="1">
        </el-switch>
      </el-form-item>
      <div class="addList">
        <el-form-item label="预算" prop="maxNum" label-width="75px">
          <el-input
            v-model="formData.maxNum" 
            placeholder="请输入限制金额"   
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            clearable>
          </el-input>
        </el-form-item> 
      </div>
    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import accountCost from '@/assets/img/accountCost.png';
  import { $http } from '@/api/http';
  import Qs from 'qs';
export default {
  
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    costTitle:String,
    warningFieldType:Number,
    switchValuePrps:String,
    nowIs:Boolean,
    maxNum:String,
    pubVue:Object,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      accountCost,
      formData: {
          maxNum: '',
        },
      rules: {
        maxNum: [
          { required: true, message: '请输入最高值', trigger: 'blur' },//required: false-显示小红星；true-隐藏小红星
        ],
      },
      label:'消耗预警开关',//预警设置label文案
      loading: false,
      show: this.visible,
      switchValue:this.switchValuePrps,

      DN:'yyb',
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    console.log(this.switchValue)
    this.formData.maxNum = this.maxNum
        
  },
  methods: {
    onConfirm(formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
            _this.addAndUpdtateAccountWarningSetFn()
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onCancel() {
      this.show= false;
      this.formData={maxNum : ''}
      this.switchValue = '1'

    },
    
   




  
  
    //添加||修改账户余额(cashBal)、今日消耗(cashCost)、计划日预算(cashDayBudget)的设置
    //  { 
        // "id": 0,
        // "ownerId": "",
        // "ownerName": "",
        // "top": 0,
        // "warningFieldType": 0,
        // "warningSwitch": 0,
        // "userId": 0,
        // "userName": "",
        // "deptId": 0
      // }


  
    addAndUpdtateAccountWarningSetFn(){
      let _this = this,params,DN = this.DN
      
        params = {"ownerId": _this.userContent.ownerId,
              "ownerName": _this.userContent.ownerName,
              "warningFieldType":_this.warningFieldType,
              "top":Number(_this.formData.maxNum),
              "warningSwitch": _this.switchValue,
              "userId": _this.userContent.userId,
              "userName": _this.userContent.userName,
              "deptId": _this.userContent.deptId}
      

      $http.axios.post("/api/"+DN+"/accountWarning/addAndUpdtateAccountWarningSet",params).then(res=> {
        if(res.data.code==200){
          _this.pubVue.$refs.multipleTable.clearSelection();
          _this.pubVue.listFn()
          _this.show= false;
          let messageContent =res.data.msg|| '添加成功'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'success'
          })
          _this.onCancel()
          // _this.$router.go(0)
        
        }else{
          let messageContent =res.data.msg|| '添加失败'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
/* .el-checkbox{
  margin: 15px 0;
} */
.el-form-item.el-tooltip.is-no-asterisk.el-form-item--mini .el-form-item__content{
  text-align: left!important;
  margin: 0;
}
.el-checkbox.is-checked,.el-checkbox{
  float: left;
}
.el-form-item__content .nowLimit{
  display: inline-block;
  text-align: right;
  width: 150px;
  font-size: 12px;
  margin-right: 10px;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
::v-deep .el-dialog{
  margin:0!important;
  margin:0 auto 50px!important;
  top:200px!important;
  transform: none!important;
}
.costWrap{
  position: relative;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  margin:10px 0;
  padding:10px;
  box-sizing: border-box;
}
.costAdd{
  width:100%;
  height:26px;
  line-height:26px;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  color:#48916F;
  font-size:12px;
  text-align:center;
  cursor: pointer;
}
.minMaxList{
  display: inline-block;
  vertical-align: middle;
  width:48%;
}
.minMaxListF{
  margin-right:19px;
}
::v-deep .addList .el-form-item{
  margin-bottom: 0;;
}
.deleteBtn{
  position: absolute;
  font-size:18px;
  top:-13px;
  right:-11px;
  color:rgb(212, 24, 24);
  cursor: pointer;
}
.addList{
  box-sizing: border-box;
  padding:10px;
  background:#F5F7F6;
  margin:5px 0;
}
</style>

