<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>广告计划</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-button type="primary" size="small" @click="warmIs('dayLimit',url[1],2,'批量日限额')">批量日限额</el-button>
                <el-button type="primary" size="small" @click="warmIs('cjWarning',url[1],3,'批量任务调价')">批量任务调价</el-button>
                <el-dropdown trigger="click" @command = "changeStatusList">
                  <el-button type="primary" size="small" @click="updateSet('planStatus')">修改状态</el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='item.openId'>{{item.name}}</el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown trigger="click" @command = "upAdPlanWarningSwitchFn">
                <el-button type="primary" size="small" @click="warmIs('warningOpen',url[0])">
                    预警开关
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> 
              <el-button type="primary" size="small" @click="updateSet('startStop')">批量启停设置</el-button>
              <!-- <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/StartStopHw')">启停计划列表页面</el-button>
              <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/PriceModifyPlanHw')">调价计划列表页面</el-button> -->
              <el-button type="primary" size="small" @click = "zdyFn">自定义列</el-button>
              <el-button type="primary" size="small" @click = "exportPlan">下载报表</el-button>
            </div>
          </div>  

          <div class="chanelTab">
            <span class="channel_vivo channelActive" >任务</span>
            <span class="channel_vivo" @click="clickChangeChannel('adTeamHw')">子任务</span>
          </div>
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="ruleForm" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "kw" clearable placeholder="请输入任务名称"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select v-model="taskStatus" clearable placeholder="请选择任务状态" >
                      <el-option
                        v-for="(item,i) in taskStatusOptions"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select v-model="chargingType" clearable placeholder="请选择计费类型" >
                      <el-option
                        v-for="(item,i) in chargingTypeOptions"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select v-model="deliveryMode" clearable placeholder="请选择投放模式" >
                      <el-option
                        v-for="(item,i) in deliveryModeOptions"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-date-picker
                      v-model="releaseDate"
                      type="daterange"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="投放开始日期"
                      end-placeholder="投放结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "getAccountPlanListFn(page)">查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                    <span class="moreBtns">
                      <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/StartStopHw')">启停计划列表页面</el-button>
                      <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/PriceModifyPlanHw')">调价计划列表页面</el-button>
                    </span>
                  </el-form-item>
                </el-form> 
              </div>
            </div>
            <div class="clearfix">
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  style=" cursor:pointer;"
                  :data="tableData"
                  tooltip-effect="dark"
                  @cell-click="clickcell"
                  @sort-change = "changeSort"
                  :cell-style="setRowStyle"
                  @selection-change='handleSelectionChange'
                  >
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <template >
                    <el-table-column
                    v-for="(item, index) in titleList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :sortable = "item.prop == 'bid'||item.prop == 'budget'||item.prop == 'price'||item.prop == 'cost'||item.prop == 'exposure'?true:false"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else :disabled = "scope.row.disabledIs">
                          <span v-if=' scope.column.property =="openStopPlanIds" || scope.column.property == "modifyPricePlanIds"|| scope.column.property == "modifyBudgetPlanIds"'>
                            {{ showStatusList[scope.column.property][Number(Boolean(scope.row[scope.column.property]))] }}
                            <i v-if = 'scope.column.property =="openStopPlanIds"||scope.column.property =="modifyPricePlanIds" || scope.column.property == "modifyBudgetPlanIds"'
                              :class = "{'isSwitch':scope.column.property =='openStopPlanIds'||scope.column.property =='modifyPricePlanIds'||scope.column.property == 'modifyBudgetPlanIds','active':(scope.column.property =='openStopPlanIds'||scope.column.property =='modifyPricePlanIds'||scope.column.property == 'modifyBudgetPlanIds')&&scope.row[scope.column.property]!=0}"></i>
                          </span>
                          <span v-else-if='item.prop=="statusTypeIs"'>
                            <template>
                              <el-switch
                                v-model="scope.row.statusTypeIs"
                                class="switchStyle"
                                :active-value="1"  
                                :inactive-value="0"
                                :disabled = "scope.row.status=='暂停'||scope.row.status=='执行'?false:true"
                                active-color="#48916F"
                                inactive-color="#EAEFED"
                                @change = "changeStatus(scope.row.statusTypeIs,scope)">
                              </el-switch>
                            </template>
                          </span>
                          <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </div> 
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column 
                    fixed = 'right'
                    align="center"
                    :show-overflow-tooltip="true"
                    label="操作"
                    width ="100">
                    <template>
                      <el-button class="solid-tag-success" data-type = 'yjBtn' plain size="mini">出价预警</el-button> 
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
              <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="280px">
              <span class="el-icon-loading uploading uploadIcon" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>

            <!-- 修改广告组名称 -->
            <el-dialog title="修改计划" :visible.sync="editNameVisible" width="40%">
              <el-form :model="editNameForm">
                <el-form-item label="计划名称" label-width="80px">
                  <el-input v-model="editName"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editNameVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditName">确 定</el-button>
              </div>
            </el-dialog>

            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <earlyWarningSetDialog ref = 'earlyWarningDialog' :visible.sync="showEarlyWarningSetDialog" :userContent = 'userContent' :page = "page" :pubVue = 'pubVue'></earlyWarningSetDialog>
      <startStopSetDialog :visible.sync="startStopSetDialog" :objIdType = 1 :userContent = 'userContent' :yulanList = "yulanList" :page = "page" :pubVue="pubVue"></startStopSetDialog>
      <zdyHw :visible.sync="showzdyDialog" ref = 'zdyHw' :pubVue="pubVue" :typeName = "typeName"></zdyHw>
      <dayLimitSingleDialog :visible.sync="showSingleDialog" :userContent = 'userContent' :yulanList = 'yulanList' :limitIs = "limitIs" :pubVue="pubVue"></dayLimitSingleDialog>
      <startLimitSingleDialog :visible.sync="showDialog" :nowIs="nowIs" :yulanList = "yulanList" :dialogTitle="dialogTitle" :page = 'page' :limitIs = "limitIs" :userContent = 'userContent' :dayType = "dayType" :pubVue="pubVue"></startLimitSingleDialog>
      <startStopDialog ref = 'startStopDialogIs' :visible.sync="startStopDialog" :page = "page" :pubVue="pubVue"></startStopDialog>
      <tiaojiaDialog ref = 'tiaojiaDialog' :visible.sync="tiaojiaDialogIs" :planType = "planType" :pubVue="pubVue"></tiaojiaDialog>
      <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :planName = "planName" :pubVue="pubVue" :command = "command+''" :scope = "scope" :typeName = "'plan'"></alertStartStopDialog>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import dayLimitDialog from '@/views/dialog/dayLimitDialog'
  import earlyWarningSetDialog from '@/views/dialog/earlyWarningSetHwDialog'
  import dayLimitSingleDialog from '@/views/dialog/dayLimitSingleDialog'
  import startLimitSingleDialog from '@/views/dialog/startLimitSingleHwDialog'
  import zdyHw from './zdyHw'
  import startStopSetDialog from '@/views/dialog/startStopSetHwDialog'
  import startStopDialog from '@/views/dialog/startStopHwDialog'//列表->启停计划->是：点击查看启停详情列表弹框
  import tiaojiaDialog from '@/views/dialog/tiaojiaHwDialog'
  import alertStartStopDialog from '@/views/dialog/alertStartStopMiDialog'
  import Qs from 'qs';
  import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')

  export default {
    components: {
      earlyWarningSetDialog,
      zdyHw,
      dayLimitSingleDialog,
      startLimitSingleDialog,
      startStopSetDialog,//批量启停设置
      startStopDialog,//启停计划列表弹框
      tiaojiaDialog,
      alertStartStopDialog,
    },
    props:{
      newVue:Object,
    },
    data() {
      return {
        pubVue: this,//空vue，用于子组件之间传值
        showDialog:false,
        nowIs:false,
        showEarlyWarningSetDialog:false,
        showSingleDialog:false,
        showzdyDialog:true,
        startStopSetDialog:false,
        startStopDialog:false,
        alertStartStopDialogIs:false,
        tiaojiaDialogIs:false,
        planStatus:false,//计划状态下拉列表是否展示
        exprtVisible:false,//
        uploadingShow:false,//确认导出弹框
        planType:20,
        dayType:null,//批量日限额：2->计划
        dialogTitle:'',//2-批量调价；3-批量日限额;
        orderByList:{'cost':'cost','budget':'budget','price':'price','exposure':'exposure'},//排序字段
        warningOpenList:[],//['开启','关闭']
        // value1:[new Date(new Date() - 3600 * 1000 * 24 * 29),new Date().substr(0, 11) ],//开始结束时间
        tableOption:[],
        title:'',
        titleList:[
        {name:'任务名称',prop:'taskName'},
        {name:'任务ID',prop:'taskId'},
        {name:'日预算',prop:'budget'},
        {name:'通用出价',prop:'price'},
        {name:'投放开关',prop:'statusTypeIs'},// 0-未暂停 1-已暂停
        {name:'出价计划',prop:'modifyPricePlanIds'},//是否调价启停:1-是 0-否
        {name:'日限额计划',prop:'modifyBudgetPlanIds'},
        {name:'启停计划',prop:'openStopPlanIds'},//是否启停启停:1-是 0-否
        {name:'预警状态',prop:'warningSwitch'},//预警开关 0-开 1-关 null-未设置
        ],
        mediaChannelList://推荐位类型
          {'243728192482956951':'精选推荐','243728192482999989':'全域推荐','ANDROID_ENCOURAGE':'激励推荐',
          'STARCOUPON':'耀星推荐','SEARCH':'应用搜索','SPARKLESEARCH':'焦点展台',
          '609388172356808219':'创意推广','609388172198768735':'激励视频','609388166390025910':'图文合约-套餐',
          '609388166345521113':'图文合约-单资源','243728192431670031':'图文合约-单资源（二叶草）','243728192430492145':'榜单合约',
          '609388166675156141':'品牌推荐区','243728192460783726':'卸载召回-推荐','243728192460784109':'卸载召回-搜索'},//
        taskStatusList://任务状态
          {'DRAFT':'草稿','AUDIT PENDING':'待审核','AUDITED':'审核通过',
          'REJECTED':'驳回','RUN':'执行','SUSPENDED':'暂停',
          'DONE':'完成','CANCELED':'取消','TERMINATE':'强制取消',
          'DELETE':'删除','BIDDING PENDING':'竞价中-待处理','BIDDING LEAD':'竞价中-领先',
          'BIDDING BACKWARD':'竞价中-落后','WIN THE BID':'竞得资源','LOSS THE BID':'未竞得资源',
          'FROZEN':'冻结','DONE DELETE':'完成状态被删除'},
        showStatusList:{
          inDelivery:[''],//0开启1关闭
          openStopPlanIds:['否','是'],//启停
          modifyPricePlanIds:['否','是'],//出价
          modifyBudgetPlanIds:['否','是'],},//日限额计划
        planName:'',//请输入计划名称查找
        kw:'',//请输入计划名称/计划ID查找
        planId:'',//请输入查找
        channel:'',//请选择推广目的
        channeln:'',//请选择状态
        channelm:'',//请选择预警状态
        tableData:[],
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dataList:'',
        userId:'',//用户ID
        userName:'',//用户名
        deptId:'',//
        params:{},//入参
        select:false,//判断是否选中列表中的任务
        userContent:{},//登录用户信息&弹框默认
        yulanList:[],//批量日限额预览
        disabledIs: false,//switch是否可操作
        warmType:false,//判断是否设置过预警消息推送
        url:['accountWarning/checkAccountWarning',//校验广告主是否设置过预警信息
            'modifyPrice/valid'],//预警是否开启、是否设置过定时调价计划
        msgContent:'',//确认添加新计划
        msgContent1:'',//确认投放开关关闭/计划状态关闭
        limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
        itemValue:'',
        startSwitch:'',
        ssDetailParams:[],
        typeName:1,
        adPlanIdList:[],
        dayLimitIs:false,
        closePlanList:[],//校验是否有存在的启停计划
        myHeaders: {Authorization:''},
        fileList:{},
        command:'',
        scope:{},
        mediaChannel:[],//任务类型：不同任务类型不能批量修改日限额
        //vivo
        senceType:'',//商店非商店等类型model
        senceTypeOptions:[{//所有类型
          value:1,
          label:'商店'
        },{
          value:2,
          label:'非商店'
        },{
          value:3,
          label:'米盟'
        }],
        adPlanStatus:[],
        adPlanStatusOption:[{label:'投放中',value:'201'},
          {label:'未投放',value:'202',
            children:[{label:'账户余额不足',value:'31'},
              {label:'账户达限',value:'32'},
              {label:'推广计划预算达限',value:'41'},
              {label:'推广计划已暂停',value:'42'},
              {label:'不在推广周期内',value:'43'},
              {label:'代理商账户被冻结',value:'11'},
              {label:'代理商账户被驳回',value:'12'},
              {label:'代理商账户审核中',value:'13'},
              {label:'账户被冻结',value:'21'},
              {label:'账户被驳回',value:'22'},
              {label:'账户审核中',value:'23'},
          ]},
          {label:'已删除',value:'0'}],//全部广告组状态
        statusType:'',//状态类型
        statusTypeOptions:[{//所有状态类型
          value:0,
          label:'所有数据'
        },{
          value:1,
          label:'已删除 '
        },{
          value:2,
          label:'已暂停'
        },{
          value:3,
          label:'余额不足'
        },{
          value:4,
          label:'到达账户日限额'
        },{
          value:5,
          label:'到达计划日限额'
        },{
          value:6,
          label:'投放中'
        }],
        reportType:'1',
        editNameVisible:false,//修改广告计划名称
        editNameForm:{},
        editName:'',
        editId:'',
        planIdSearch:'',
        validDate:null,//当前时间
        ownerId:'',//ownerId
        unDeliveryReasonList:'',
        dateValue:'',
        value1: [formatDate(new Date()),formatDate(new Date())],//时间
        sumData:{},
        adPlanStatusShow:'',
        sums:{},//合计
        orderBy: "",//排序依据的字段
        sortMode: "",//列表升序降序
        taskStatus:'',//任务状态
        taskStatusOptions:[{
          value:'RUN',
          label:'运行状态'
        },{
          value:'DRAFT',
          label:'草稿状态'
        },{
          value:'DELETE',
          label:'删除状态'
        }],
        chargingType:'',//计费类型
        chargingTypeOptions:[{
          value:'CPD',
          label:'CPD-实际下载量计费'
        },{
          value:'CPT',
          label:'CPT-时长计费'
        },{
          value:'OCPD',
          label:'OCPD-系统智能调价,按下载量计费'
        },{
          value:'CPM',
          label:'CPM-曝光量计费'
        },{
          value:'CPC',
          label:'CPC-按点击计费'
        }],
        deliveryMode:'',//投放模式
        deliveryModeOptions:[{
          value:'SYSTEM',
          label:'系统投放'
        },{
          value:'BIDFOLLOW',
          label:'竞争追随'
        }
        // ,{
        //   value:'Booster plan',
        //   label:'助推计划'
        // },{
        //   value:'Large card search',
        //   label:'搜索大卡'
        // },{
        //   value:'RTA',
        //   label:'RTA'
        // },{
        //   value:'Contract',
        //   label:'合约'
        // },{
        //   value:'Content recommendation',
        //   label:'内容推荐'
        // }
      ],
        releaseDate:[],//投放开始结束时间
        dailyBudgetValue:'',
       




      }
    },
    // watch: {
    //     // tableData是el-table绑定的数据
    //     tableData: {
    //         // 解决表格显示错位问题
    //         handler () {
    //             this.$nextTick(() => {
    //                 // tableRef是el-table绑定的ref属性值
    //                 this.$refs.multipleTable.doLayout() // 对 Table 进行重新布局
    //             })
    //         },
    //         deep: true
    //     }
    // },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.params.userId  = this.userId
      this.userContent.userId = this.userId
      this.params.userName  = this.userName
      this.userContent.userName = this.userName
      this.params.deptId = this.deptId
      this.userContent.deptId = this.deptId
      this.params.ownerId = this.$route.query.id
      this.ownerId = this.$route.query.id
      this.userContent.ownerId = this.$route.query.id
      this.userContent.ownerName = this.$route.query.name
      if(this.$route.query.kw!=undefined){
        this.kw = this.$route.query.kw
      }
      // this.taskStatus = 'RUN'
      this.getAccountPlanListFn()
      this.$refs.zdyHw.allWords(1)
      this.value1= [formatDate(new Date()),formatDate(new Date())]//时间
      // this.showSummariesPosition()
      //强制渲染，解决操作栏固定后出现错位的情况
      // this.$nextTick(() => { 
      //   this.$refs.multipleTable.doLayout()
      // })
      console.log(this.$route.query.name)
      

    },
    methods: {
      //列表排序
      changeSort(val){
        console.log(val)
        if(val.order == 'descending'){
          this.sortMode = 'ASC'
        }else{
          this.sortMode = 'DESC'
        }
        this.orderBy = this.orderByList[val.prop]//获取排序需要的字段
        this.getAccountPlanListFn(this.page)
        console.log('排序')

      },
      //tab切换
      clickChangeChannel(url){
        this.$router.push({
          path: '/HwAccount-management/'+url,
          query: {
            id: this.userContent.ownerId,
            name:this.userContent.ownerName,
          }
        })
      },

      //oppo
      getId(){
        this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
        this.uploadId = this.$route.params && this.$route.params.id
      },
      zdyFn(){
        this.$refs.zdyHw.generateTimes(1)
      },
      exportPlan(){
        this.exprtVisible = true
      },
      //文件上传成功时的钩子
      onSuccess(response, file, fileList, i) {
        let that = this,dataSource =[],keywords = []
        if(response.code==200){
          that.getAccountPlanListFn()
        }else{
          that.$message({
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }
        this.$refs.upload.clearFiles()
      },
      //下载报表
      downLoadTable(){
        let _this = this,params ={},pagetTxt = {},myDate = formatDate(new Date())
        
        pagetTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : _this.page || 1,
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.ownerId,
          'startDate':  _this.value1[0],
          'endDate':  _this.value1[1],
          'deliverStartTime':_this.releaseDate[0],//投放开始时间
          'deliverEndTime':_this.releaseDate[1],//投放结束时间
          // 搜索条件
          'filter' : {
            'taskName':_this.kw,//任务名称
            'status':_this.taskStatus,//任务状态
            'pricingType':_this.chargingType,//计费类型
            'deliveryMode':_this.deliveryMode,//投放模式
          },
        }
        if(_this.value1 != undefined&&_this.value1 != []){
          pagetTxt.startDate = _this.value1[0]
          pagetTxt.endDate = _this.value1[1]
          pagetTxt.deliverStartTime = _this.releaseDate[0]
          pagetTxt.deliverEndTime = _this.releaseDate[1]
        }
        params = Object.assign({},_this.clone(_this.userContent), pagetTxt)//之前是params，因为里面有groupId所以换成了userContent
        _this.uploadingShow = true
        $http.axios.post("/api/hw/adTask/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
          _this.exprtVisible = false 
          //添加{responseType: 'blob'}  导出文件以下代码都是
          let blob = response.data
          let reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = e => {
            let a = document.createElement('a')
            a.download = _this.userContent.ownerName+"_推广任务_"+myDate+".xls"
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            _this.uploadingShow = false
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
      },
      //弱提示
      alertMsgFn(msg,type){
        Vue.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
       // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
       clickcell(row, column, cell, event){
        let yulanList = []
        let yjType = event.target.parentNode.dataset.type
        let className = event.target.className
        this.adPlanIdList = [{'taskId':row.taskId,'status':row.status}]

        if(yjType == 'yjBtn'){
          yulanList.push({'taskName':row.taskName,'taskId':row.taskId,'price':row.budget})
          this.yjCheck('yjBtn',this.url[0],row.taskId,row.taskName,row.budget) //预警为什么有 budget？
        }
        if(className=="adTeamName"){
          this.goPlan('/miAccount-management/adTeamMi',{
            type:row.taskId,
          })
        }
        if(column.label == "日预算"){
          this.dayLimitCheckFn('dayLimit',this.url[1],3)
          yulanList.push({'ownerId':this.userContent.ownerId,'taskName':row.taskName,'taskId':row.taskId,'price':row.budget})
        }
        if(column.label == "通用出价"){
          this.dayLimitCheckFn('dayLimit',this.url[1],2)
          yulanList.push({'ownerId':this.userContent.ownerId,'taskName':row.taskName,'taskId':row.taskId,'price':row.budget})
        }
        if(column.type=="selection"){
          this.$refs.multipleTable.toggleRowSelection(row)
        }
        if(column.property=="openStopPlanIds"){//openStopPlanIds//列表->启停计划->是：点击查看启停详情列表弹框
          this.ssDetailParams = {}
          if(row.openStopPlanIds != 0){
            this.ssDetailParams={userId:this.userId,
                                userName:this.userName,
                                deptId:this.deptId,
                                pageIndex:1,
                                pageSize:50,
                                planIds:row.openStopPlanIds.toString()}
            this.startStopDialog = true
            this.$refs.startStopDialogIs.timeSlotDetail(this.ssDetailParams,'hw')
          }
        }
        if(column.property =="modifyPricePlanIds"&&row.modifyPricePlanIds!=0){
            this.tiaojiaDialogIs = true
            this.$refs.tiaojiaDialog.getTimeSlotDetail(row.modifyPricePlanIds,this.userContent)
      }else if(column.property == "modifyBudgetPlanIds"&&row.modifyBudgetPlanIds!=0){
            this.tiaojiaDialogIs = true
            this.$refs.tiaojiaDialog.getTimeSlotDetail(row.modifyBudgetPlanIds,this.userContent)
          }
        this.yulanList = yulanList
      }, 
       // 分页
      handleSizeChange(val) {
        let _this = this
        _this.pageCount = val
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        this.findAll(val)
      },
      //分页方法
      findAll (page) {
        //获取数据接口（调用封装的接口）
        this.page = page
        this.getAccountPlanListFn(page)
      },
     
      // 选择行
      handleSelectionChange(val,row){
        let _this = this
        let  yulanList = [],adPlanIdList = [],mediaChannel = [],objList = [],startSwitch = [],numStr =0 ,num = 0
        _this.limitIs = false
        
        val.forEach((obj,i,arr)=>{
          let cur = obj.statusTypeIs
          adPlanIdList.push({'taskId':obj.taskId,'status':obj.status})
          startSwitch.push(obj.openStopPlanIds)
          mediaChannel.push(obj.mediaChannel)

          
          if(obj.budget =='不限'){
            _this.limitIs = true
            numStr++
          }else{
            num++
          }
          yulanList.push({ 'ownerId':this.userContent.ownerId,'taskName':obj.taskName,'taskId':obj.taskId,'upPriceStatus':0,'budget':obj.budget,'price':obj.price})//upPriceStatus:调价状态;status2:计划状态
          objList[cur] = cur
        })
        _this.adPlanIdList = adPlanIdList
        _this.mediaChannel = mediaChannel

        _this.dayLimitIs = true
        if(val.length == num || val.length ==numStr){
            _this.dayLimitIs = true
          }else{
            _this.dayLimitIs = false
          }
        _this.startSwitch = startSwitch
        _this.yulanList = yulanList
        if(val.length == 0){
          _this.warningOpenList = []
          _this.planStatus = false
        }else{
          _this.planStatus = true
        }
        
      },
      
      // 批量日限额取消limitIs为false
      // 批量启停设置
      updateSet(type){
        let _this = this
        let adPlanIdList = [],statusList = []//声明一个空数组
        _this.adPlanIdList.forEach(function(v,i,arr){
          let bool = arr.indexOf(v.taskId,i+1)
          if(bool === -1){
            adPlanIdList.push(v.taskId)
          }
          if(v.status != '执行'&&v.status != '暂停'){
            statusList.push(v.status)
          }
        })
        if(_this.adPlanIdList.length!=0){
          _this.warningOpenList = []
          if(statusList.length!=0&&type=='planStatus'){
          _this.$message({
              showClose: true,
              message: "被选中的任务/子任务状态不符",
              type: 'error'
            })
          }else{
            _this.params.adPlanIdList = adPlanIdList
          
            _this.btnIsFn(_this.params,type)
            return true
          }
        }else{
          _this.$message({
              showClose: true,
              message: "请勾选数据",
              type: 'error'
            })
          }
          
      },
      // 按钮判断
      btnIsFn(params,type){
        let _this = this
        if(type == 'startStop'){
          _this.startStopSetDialog = true

        }else if(type == 'planStatus'&&_this.planStatus){
            _this.checkOpenCloseFn(params.adPlanIdList)
            _this.warningOpenList = [{name:'开启',openId:1},{name:'关闭',openId:0}]
        }else if(type == 'dayLimt'){
        }
      },
      //开关按钮启停计划校验
      changeStatus(value,scope) {
        console.log(value)
        // console.log(this.tableData[scope.$index].inDelivery)
        //先把值赋为原来的值，保证swich状态不变
        let _this = this,params,time
        // _this.adPlanIdList = [{'taskId':scope.row.taskId,'status':scope.row.status}]
        // params = {'ids': adPlanIdList,'type':1}//类型（1-推广任务 2-推广子任务）
        this.tableData[scope.$index].statusTypeIs = value == 0?1:0//需求更改暂时注释
        // this.tableData[scope.$index].inDelivery = value == 1?false:true//需求更改暂时注释
        // console.log(this.tableData[scope.$index].inDelivery)
        console.log(value)
        $http.axios.get("/api/hw/openStop/valid?ids="+scope.row.taskId+"&type=1").then(res=> {
          if(res.data.code==200){
           if(res.data.data!= null){
              // _this.planName = res.data.data
              _this.command = value
              _this.scope = scope
              _this.alertStartStopDialogIs = true
              _this.validDate = formatDate(new Date())
           }else{
            this.tableData[scope.$index].statusTypeIs = value
            this.updateStatusFn(scope.row.statusTypeIs,scope,'继续')//需求更改暂时打开
           }
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //校验当日是否存在启停计划
    checkOpenCloseFn(adPlanIdList){
      adPlanIdList=adPlanIdList.join(',')
        let _this = this,params = {'ids': adPlanIdList,'type':1},planName
        _this.closePlanList = []
        $http.axios.get("/api/hw/openStop/valid?ids="+adPlanIdList+"&type=1").then(res=> {
        if(res.data.code==200){
          if(res.data.data!= null){
            _this.planName = res.data.data
            _this.validDate = formatDate(new Date())
            planName = res.data.data.split(',')
            planName.forEach(obj=>{
              _this.closePlanList.push(obj)
            })
          }else{
          _this.closePlanList = []
          }
        }
      }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },

      changeStatusList(command){
        let _this = this,planName = [],text = ''
          _this.closePlanList.forEach((obj,i)=>{
              planName.push(obj)
          })
          if(planName.length>0){
            _this.planName = planName.join(',')
            _this.command = command
            _this.alertStartStopDialogIs = true     
          }else{
            this.updateStatusFn(command,'','继续')
          }
      },      
      creatPlan(url,type){
        this.$router.push({
          path: url,
          query: {
            ownerId: this.userContent.ownerId,
            ownerName:this.userContent.ownerName,
            type:type
          }
        })
      },
      
      //重置
      resetForm(){
        let _this = this
        _this.value1 = [formatDate(new Date()),formatDate(new Date())],//时间
        _this.senceType = ''
        _this.statusType = ''
        _this.planName = ''
        _this.planIdSearch = ''
        _this.adPlanStatus = ''
        _this.getAccountPlanListFn()
        _this.$router.go(0)
      },

      //change计划状态
      adPlanStatusChange(value){
        this.adPlanStatusShow = Number(value.slice(-1))
        console.log(this.adPlanStatusShow)
      },

      // changeDailyBudget(val){
      //   this.sortBudget = val
      // },

      //任务列表
      getAccountPlanListFn(page){
        //获取查询有权限的所有帐号接口
        let _this = this,params,pagetTxt
        pagetTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : page || 1,
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':_this.ownerId,
          'deliverStartTime':_this.releaseDate[0],//投放开始时间
          'deliverEndTime':_this.releaseDate[1],//投放结束时间
          // 搜索条件
          'filter' : {
            'taskName':_this.kw,//任务名称
            'status':_this.taskStatus,//任务状态
            'pricingType':_this.chargingType,//计费类型
            'deliveryMode':_this.deliveryMode,//投放模式
          },
          'sortField':_this.orderBy,
          'sort':_this.sortMode,
        }
        if(_this.value1 != undefined&&_this.value1 != []){
          pagetTxt.startDate = _this.value1[0]
          pagetTxt.endDate = _this.value1[1]
          pagetTxt.deliverStartTime = _this.releaseDate[0]
          pagetTxt.deliverEndTime = _this.releaseDate[1]
        }
        
        params = Object.assign({},_this.clone(_this.userContent), pagetTxt)//之前是params，因为里面有groupId所以换成了userContent
        $http.axios.post("/api/hw/adTask/list",params).then(res=> {
          _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
          if(res.data.code==200){
            console.log(res.data.data.list)
            if(res.data.data.list.length != 0){
              // 数据修改，余额、消耗、日预算初始值如果为空的状态
              res.data.data.list.forEach((obj,i) => {                
                _this.$set(obj,'mediaChannel',_this.mediaChannelList[obj.mediaChannel])
                if(obj.status!="RUN"){
                  _this.$set(obj,'statusTypeIs',0)
                }else if((obj.status!="SUSPEND")){
                  _this.$set(obj,'statusTypeIs',1)
                }
                //任务状态
                _this.$set(obj,'statusType',obj.status)
                _this.$set(obj,'status',_this.taskStatusList[obj.status])

                // //投放模式
                if(obj.deliveryMode == 'SYSTEM'){
                  obj.deliveryMode = '系统投放'
                }else if(obj.deliveryMode == 'BIDFOLLOW'){
                  obj.deliveryMode = '竞争追随'
                }else if(obj.deliveryMode == 'Booster plan'){
                  obj.deliveryMode = '助推计划'
                }else if(obj.deliveryMode == 'Large card search'){
                  obj.deliveryMode = '搜索大卡'
                }else if(obj.deliveryMode == 'RTA'){
                  obj.deliveryMode = 'RTA'
                }else if(obj.deliveryMode == 'Contract'){
                  obj.deliveryMode = '合约'
                }else if(obj.deliveryMode == 'Content recommendation'){
                  obj.deliveryMode = '内容推荐'
                }
                
                let hour = obj.convertDeliveryHours,timeList = ''
                if(hour != null){
                  hour.forEach(function(e,i){
                    timeList += e.day+':'+e.startHMs+'~'+e.endHMs
                  })
                  obj.convertDeliveryHours = timeList
                }else{
                  obj.convertDeliveryHours = '不限'
                }
                
                if(obj.warningSwitch == null){
                  obj.warningSwitch = '未设置'
                }else if(obj.warningSwitch == 0){
                  obj.warningSwitch = '开'
                }else if(obj.warningSwitch == 1){
                  obj.warningSwitch = '关'
                }
                // if(obj.budget == '-1'){
                //   obj.budget = '不限'
                // }
                // if(obj.openStopPlanIds==''||obj.openStopPlanIds==null){
                //   _this.$set(obj,'openStopPlanIds','0')
                // }
                //出价计划
                if(obj.modifyPricePlanIds==''||obj.modifyPricePlanIds==null){
                  _this.$set(obj,'modifyPricePlanIds','0')
                }
                // 日限额计划
                if(obj.modifyBudgetPlanIds==''||obj.modifyBudgetPlanIds==null){
                  _this.$set(obj,'modifyBudgetPlanIds','0')
                }
                
                // _this.unDeliveryReasonList = obj.unDeliveryReasonList.toString()
            });
           
            _this.tableData = res.data.data.list
            _this.totalRow = res.data.data.pageInfo.rowCount
            _this.sumData = res.data.data.total
            console.log(_this.tableData)
            }else{
              _this.tableData = []
              _this.totalRow = 0
            }
            _this.sums = res.data.data.total
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })    
          }

        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })

      },
      
      //预警开关
      upAdPlanWarningSwitchFn(command){
        let _this = this,type,params,params1,adPlanIdList = []
        _this.adPlanIdList.forEach(obj=>{
          adPlanIdList.push(obj.taskId)
        })
        params1={'adPlanIdList':adPlanIdList,
          'warningSwitch' : command,}
        params = Object.assign({},_this.clone(_this.userContent), params1)//之前是params，因为里面有groupId所以换成了userContent
        $http.axios.post("/api/hw/adPlanWarning/batchUpdateAdPlanWarningSwitch",params).then(res=> {
          _this.warningOpenList = []
          _this.planStatus = false
          if(res.data.code==200){
            _this.adPlanIdList = []
            _this.$refs.multipleTable.clearSelection();          
            _this.getAccountPlanListFn(this.page)
            type = "success"
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },

      // "userId": 0,
      // "userName": "",
      // "deptId": 0,
      // "validDate": "",校验时间 yyyy-MM-dd HH:mm:ss
      // "ownerId": "",
      // "extendFlag": 0,设置排除日期 1-是
      // "status": "",启停状态 ON-启动 SUSPEND-暂停
      // "taskIds": "",推广任务IDs,逗号分割
      // "subTaskIds": "",推广子任务IDs,逗号分割
      // "creativeIds": ""创意IDs,逗号分割(暂无)

      open(html){
        this.$alert(html, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          type:'warning',
        });
      },
      //计划状态设置
      updateStatusFn(command,scope,status){
        console.log(command+1)
        let commandType = command,adPlanIdList = []
        Number(commandType)?commandType="ON":commandType="SUSPEND"
        let _this = this,type,params,planIdsString,msg
        _this.adPlanIdList.forEach(obj=>{
          adPlanIdList.push(obj.taskId)
        })
        planIdsString = adPlanIdList.join(',')
        if(status == '继续'){
          params = {
            'ownerId':this.$route.query.id,
            'taskIds':planIdsString,
            'status':commandType,
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'validDate':new Date().getTime()//当前时间
          }
        }else{
          params = {
            'ownerId':this.$route.query.id,
            'taskIds':planIdsString,
            'status':commandType,
            // 'validDate':_this.validDate,//后续不继续执行校验的时间
            'extendFlag':1,//不继续的时候传此参数
            'userId':_this.userId,
            'userName':_this.userName,
            'deptId':_this.deptId,
            'validDate':new Date().getTime()//当前时间
          }      
        }
        $http.axios.post("/api/hw/adTask/batch/openStop",params).then(res=> {
        // $http.axios.post("/api/hw/advertisingPlan/startAndStop",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          _this.warningOpenList = []
          _this.planStatus = false
          _this.$refs.multipleTable.clearSelection();          
          _this.adPlanIdList = []
          if(res.data.code==200){
            let messageContent =res.data.msg
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'success'
            }) 

          }else{
            if(res.data.data!=null){
              errorNum = eval('(' + res.data.data.failedTasks + ')').length
              totalNum = res.data.data.subTaskIds.split(',').length
              successNum = totalNum-errorNum
              if(errorNum>0&&errorNum==1){
                _this.open('<p>操作失败</p><p>您所选择的任务，当前任务状态下不支持启用。</p>')
              }else{
                _this.open('<p>批量处理任务结果</p><p>成功：'+successNum+'，失败：'+errorNum+'</p><p>操作失败的原因是由于您所选择的任务，当前任务状态下不支持启用。</p>')
              }
            }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type:  'error'
              })
            }
          }
          _this.getAccountPlanListFn(_this.page)

        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      
      deletePlanFn(id){
        let _this = this,params = {},type = ''
        params = {
          'ids':String(id),
          'ownerId':_this.$route.query.id,
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId
        }
        // $http.axios.post("/api/hw/adPlan/batch/del",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        $http.axios.post("/api/hw/adPlan/batch/del",params).then(res=> {
          if(res.data.code==200){
            type = "success"
            _this.getAccountPlanListFn(this.page)
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
      warmIs(type,url,dayType,dialogTitle){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
        let _this = this,flag
        let isTrue = _this.updateSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        _this.dayType = dayType
        if(isTrue){
          if(type=='cjWarning'){
            
            _this.dayLimitCheckFn(type,url,dayType,dialogTitle)
          }else if(type == 'dayLimit'){
            _this.mediaChannel.forEach((obj,i,arr)=>{
              if(arr[0]!=obj){
                flag = true
              }else{
                flag = false
              }
            }) 
            if(flag){
              _this.open('<p>请选择同一任务类型的任务，再进行批量操作。</p>')
            }else{
              _this.dayLimitCheckFn(type,url,dayType,dialogTitle)
            }         
          }else{
          _this.yjCheck(type,url)
          }   
        }
      },
      //校验当日是否存在日限额
      dayLimitCheckFn(type,url,dayType,dialogTitle){
        let _this = this,idList = []
            _this.adPlanIdList.forEach(obj=>{
            idList.push(obj.taskId)
          }) 
          
          
          $http.axios.get("/api/hw/"+url+"?ids="+idList.join(',')+"&type="+dayType).then(res=> {
          let data = []
          if(res.data.code==200){
            data = res.data.data
            if(data == null){
              // if(type == 'dayLimit'){
                  _this.dayType = dayType
                  _this.dialogTitle = dialogTitle
                // }else{
                  _this.showDialog = true
                // }
                this.nowIs = false
            }else{

              let text
              switch(type){
                case 'dayLimit':
                text = data.toString()+'任务已经设置过出价执行任务是否确认添加新任务'
                break;
                case 'cjWarning':
                text = data.toString()+'任务已经设置过出价执行任务是否确认添加新任务'
              }
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                _this.showDialog = true
                _this.nowIs = true
              }).catch(()=>{
              })
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      yjCheck(type,url,adPlanId,adPlanName,dayBudget){
        let _this = this,messageContent,params = null,idList = []
        if(type == 'warningOpen' || type =='yjBtn'){
          params = []
          params.push(_this.userContent.ownerId)
          console.log(_this.userContent.ownerId)
        }
      $http.axios.post("/api/hw/"+url,params).then(res=> {
        let data = []
        if(res.data.code==200){
          data = res.data.data
          if(data == null){
            if(type == 'dayLimit'){
              if(dayBudget == 'dayLimit'){
                this.showSingleDialog = true
              }else{
                _this.showDialog = true
              }
              this.nowIs = false

            }else{
              messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }
            }else{
              if(type == 'dayLimit'){

                let text = data.toString()+'计划2已经设置过日限额执行计划是否确认添加新计划'
                this.$confirm(text,"提示",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(()=>{
                  _this.showDialog = true
                  _this.nowIs = true
                }).catch(()=>{
                  
                })
              }else if(type == 'warningOpen'){
                _this.warningOpenList = [{name:'开启',id:1},{name:'关闭',id:2}]
              }else if(type == 'yjBtn'){
                _this.showEarlyWarningSetDialog = true
                _this.$refs.earlyWarningDialog.setDialogContentFn(adPlanId,adPlanName,3)//广告计划id&&预警字段->计划是3
              }
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      // 启停页面跳转
      goPlan(url,query){//
        let _this = this,query1 =  {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName}
        query = Object.assign({},_this.clone(query), query1)
        this.$router.push({
          path: url,
          query: query
        })
      },
      getOpenCloseIdFn(params){
        let _this = this, type = ''
        $http.axios.post("/api/hw/openClose/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code==200){
            if(res.data.data!=null){
              _this.startStopDialog = true
              _this.$refs.startStopDialogIs.timeSlotDetail(_this.ssDetailParams,'hw')
              _this.validDate = formatDate(new Date())
            }
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
      
      setRowStyle(row, column, rowIndex, columnIndex) {
        if(row.columnIndex == 1){
          return 'color: #2F674D' 
        }else{
          return 'color: #606266' 
        }
      },

      //修改广告组名称
      editAdTeamName(item){
        console.log(item)
        this.editId = String(item.row.taskId)
        if(item.column.label == '广告计划名称'){
          this.editNameVisible = true
          this.editName = item.row.taskName
        }
      },

      //修改广告计划名称
      sureEditName(){
        let that = this, params = {}
        params = {
          info:{
            campaignId:that.editId,
            name:that.editName
          },
          ownerId:that.ownerId,
          userId:that.userId,
          userName:that.userName,
          deptId:that.deptId
        }
        $http.axios.post("/api/hw/adPlan/edit",params).then(res=> {
          if(res.data.code==200){
            that.editNameVisible = false
            that.getAccountPlanListFn(that.page)
          }else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //计划列表总计
      getSummaries(param) {
        let that = this
        const { columns, data } = param
        const sums =[]
        // console.log(column.property)
        columns.forEach((column, index) => {
          // console.log(column.property)
          if (index === 1) {
            sums[index] = '整体数据';
            return;
          }
          if(index>7){
            if(column.property == null ||column.property == ''){
              sums[index] = '-';
            }else{
              sums[index] = that.sums[column.property] 
            }
          }
        });
        return sums
      },
    }
  }
  </script>
  <style lang="scss" scoped>
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */
     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
      font-style: initial;
    }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */
    .MBOTTOM{
      margin-bottom:10px;
    }
    .el-button--small{
      margin-left: 10px;
    }
    .line.el-col.el-col-2{
      text-align: center;
    }
    .isSwitch{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background-color: #ccc;
      vertical-align:middle;
      margin-left: 5px;
    }
    .active{
      background-color: #13ce66;
    }
    #uploadBtn{
      float: right;
    }
    .el-button.el-button--primary{
      margin: 0 10px 0 0!important;
    }
    .el-table .el-table__row {
      cursor: pointer!important;
    }
    /* tab切换 */
    .daily_library{
      margin-bottom:20px;
    }
    .daily_library span{
      padding-right:20px;
      font-size:14px;
      cursor: pointer;
    }
    .daily_library .timesTab{
      font-size:16px;
      font-weight:700;
      color:#6e7c7c;
    }
    .adTeamEditBtn{
      padding-left:10px;
      color:#333;
      cursor: pointer;
      display:none;
    }
    .el-table .el-table__body tr:hover td .adTeamEditBtn{
      display:inline-block;
    }
    .editDayLimit{
      width:80%;
      margin:0 auto;
    }
    .editDayLimit .editDayLimitText,.editDayLimit span{
      display: inline-block;
      vertical-align:middle;
    }
    .editDayLimit .editDayLimitText{
      width:80%;
    }
    .editDayLimit span{
      width:18%;
      margin-left:1%;
    }
    .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .statusOtherBtnwei{
      padding:3px 8px;
      background:rgba(230,162,60,.1);
      color:#E6A23C;
    }
    .uploadIcon{
      font-size:40px;
      display:block;
      text-align:center;
    }
    /* tab切换当前样式 */
    .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }
  .warningIcon{
    font-size:14px;
  }
  /* ::v-deep为深度操作符，可以穿透到子组件*/
  
  ::v-deep .el-table {
    display: flex;
    flex-direction: column;
  }
  ::v-deep .el-table th > .cell{
    width:100px;
  }
  /*新版*/
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  .moreBtns{
    padding-left:20px;
  }
  </style>
