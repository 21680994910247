<template>
  <el-dialog
    :visible.sync="show"
    width="440px"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="设置出价预警"
    center
    :show-close = "false">
    <div class="dialogIcon"><img :src='warningIcon'></div>
    <el-form
      hide-required-asterisk
      :inline="true" 
      ref="formData"
      class="formDatastyle"
      :model="formData">
      <el-form-item label="人工阀值(单位:元)">
        <el-form-item label="最高值"  prop="priceTop" label-width="100px">
          <el-input  clearable v-model="formData.priceTop" placeholder='请输入限制金额-最高值' ></el-input> 
        </el-form-item>
        <el-form-item label='最低值' prop="priceLow" label-width="100px">
          <el-input  clearable v-model="formData.priceLow" placeholder='请输入限制金额-最低值' ></el-input> 
        </el-form-item>
      </el-form-item>
      <el-form-item label="人工转化阀值(单位:元)">
        <el-form-item label='最高值' prop="ocpcTop" label-width="100px">
          <el-input  clearable v-model="formData.ocpcTop" placeholder='请输入限制金额-最高值' ></el-input> 
        </el-form-item>
        <el-form-item label='最低值' prop="ocpcLow" label-width="100px">
          <el-input  clearable v-model="formData.ocpcLow" placeholder='请输入限制金额-最低值' ></el-input> 
        </el-form-item>
      </el-form-item>
      <el-form-item label="人工深度转化阀值(单位:元)">
        <el-form-item label='最高值' prop="deepOcpcTop" label-width="100px">
          <el-input  clearable v-model="formData.deepOcpcTop" placeholder='请输入限制金额-最高值' ></el-input> 
        </el-form-item>
        <el-form-item label='最低值' prop="deepOcpcLow" label-width="100px">
          <el-input  clearable v-model="formData.deepOcpcLow" placeholder='请输入限制金额-最低值' ></el-input> 
        </el-form-item>
      </el-form-item>
    </el-form>
    <div class="footerBottom">
      <span slot="footer" >
        <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
      </span>
      <span slot="footer" >
        <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm">确认保存</el-button>
      </span> 
    </div>
  </el-dialog>
</template>

<script>
  import warningIcon from '@/assets/img/warningIcon.png';
  import { $http } from '@/api/http';
export default {
  name: "setCjWarningDialog",
  props: {
    pubVue: {
      type: Object
    },
    groupsIds:Array,
    visible: {
      type: Boolean,
      default: false
    },
    yulanList:Array,
    // type: Number,
    // ownerId:Number,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      warningIcon,
      formData: {
        priceTop:null,
        priceLow:null,
        ocpcTop:null,
        ocpcLow:null,
        deepOcpcTop:null,
        deepOcpcLow:null,
        id:null,
      },
      show:this.visible,
      loading: false,
      numberlimit: { validator: this.checkMoneyM, trigger: 'blur'},
      // priceTop:1,
      // priceLow:0,
      // ocpcTop:0,
      // ocpcLow:0,
      // deepOcpcTop:0,
      // deepOcpcLow:0,
      addIs:true,//false-修改；true-添加
      num:0

    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    //  this.pubVue.$on('SHOW_DIALOG', data => {
    //   console.log('方法监听', data)
    //   this.show = data.showDialog
    // })
    // this.getAdGroupWarningDetailFn()
     
    console.log(this.pubVue)
  },
  methods: {
    checkMoneyM (rule, value, callback)  {
      if (!value) {
        this.num++
        if(this.num==6){
          return callback(new Error('不能全部为空'))
        }
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)&& value != null) {
          callback(new Error('请输入数字值'))
        } else {

          callback()
        }
      }, 1000)
    },
    onConfirm() {
      console.log(this.pubVue.warmType)
      console.log(this.pubVue.warmTypeBtn)
      // _this.pubVue.warmType = ''
      let _this = this
      if(_this.formData.priceTop == null&&_this.formData.priceLow == null&&_this.formData.ocpcLow == null&&_this.formData.ocpcTop == null&&_this.formData.deepOcpcLow == null&&_this.formData.deepOcpcTo == null){
        _this.$message({
          showClose: true,
          message: '请填写至少一项',
          type: 'error'
        }) 
      }else{
        if((Number(_this.formData.priceLow)>=Number(_this.formData.priceTop))&&(Number(_this.formData.priceLow)!=0&&Number(_this.formData.priceTop)!=0)){
          _this.$message({
            showClose: true,
            message: '最低值不能大于等于最高值',
            type: 'error'
          }) 
        }else if((Number(_this.formData.ocpcLow)>=Number(_this.formData.ocpcTop))&&(Number(_this.formData.ocpcLow)!=0&&Number(_this.formData.ocpcTop)!=0)){
          _this.$message({
            showClose: true,
            message: '最低值不能大于等于最高值',
            type: 'error'
          }) 
        }else if((Number(_this.formData.deepOcpcLow)>=Number(_this.formData.deepOcpcTop))&&(Number(_this.formData.deepOcpcLow)!=0&&Number(_this.formData.deepOcpcTop)!=0)){
          _this.$message({
            showClose: true,
            message: '最低值不能大于等于最高值',
            type: 'error'
          }) 
        }else{
          if(_this.pubVue.warmType == 'warningPrice'){
            _this.addAdGroupWarningFn()
          }else if(_this.pubVue.warmTypeBtn == 'warningPriceSingle'){
            if(_this.addIs){
              _this.addAdGroupWarningFn()
            }else{
              _this.updateAdGroupWarningFN()
            }  
          }
                    
        }
      }
      //  _this.$refs[formName].validate((valid) => {
          // if (valid) {
            // if(Number(_this.formData.priceLow)>=Number(_this.formData.priceTop) || Number(_this.formData.ocpcLow)>=Number(_this.formData.ocpcTop) || Number(_this.formData.deepOcpcLow)>=Number(_this.formData.deepOcpcTop)){
            //   _this.$message({
            //     showClose: true,
            //     message: '最低值不能大于等于最高值',
            //     type: 'error'
            //   }) 
            // }
            // if(Number(_this.formData.priceLow)>=Number(_this.formData.priceTop)){
            //   _this.$message({
            //     showClose: true,
            //     message: '最低值不能大于等于最高值',
            //     type: 'error'
            //   }) 
            // }else if(Number(_this.formData.ocpcLow)>=Number(_this.formData.ocpcTop)){
            //   _this.$message({
            //     showClose: true,
            //     message: '最低值不能大于等于最高值',
            //     type: 'error'
            //   }) 
            // }else if(Number(_this.formData.deepOcpcLow)>=Number(_this.formData.deepOcpcTop)){
            //   _this.$message({
            //     showClose: true,
            //     message: '最低值不能大于等于最高值',
            //     type: 'error'
            //   }) 
            // }else{
            //   if(_this.addIs){
            //     _this.addAdGroupWarningFn()
            //   }else{
            //     _this.updateAdGroupWarningFN()
            //   }            
            // }
          // }
          //  else {
          //   console.log('error submit!!');
          //   return false;
          // }
        // });
      
    },
    onCancel() {
      this.show= false;
      this.tableYL= false;
      this.formData =  {
        priceTop:null,
        priceLow:null,
        ocpcTop:null,
        ocpcLow:null,
        deepOcpcTop:null,
        deepOcpcLow:null,
      },
      this.dayLimitF = ''
      this.tableData = []
      this.changeDayLimitArr =[]
      this.numInArr = []
      this.value1 = []
      this.pubVue.$refs.multipleTable.clearSelection();
      this.pubVue.grouplistFn()
      this.pubVue.adPlanIdList = []

      console.log('关闭')
    },
    //出价预警回显
    getAdGroupWarningDetailFn(){// adGroupId
      let _this = this,params = {},type = ''
      // params = 'adGroupId':_this.userContent.adGroupId
      $http.axios.post("/api/advertisement/adGroup/v1/getAdGroupWarningDetail?adGroupId="+_this.groupsIds).then(res=> {
        // _this.warningOpenList = []
        if(res.data.code==200){
          if(res.data.content != null){
            _this.addIs = false
            _this.formData = res.data.content
          }else{
            _this.addIs = true
          }
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
    //出价预警添加
    addAdGroupWarningFn(){
      // /adGroup/v1/addAdGroupWarning
      // [{
      //   "accountWarningMesgId": 0,-预警信息表id
      //   "adGroupId": 0,
      //   "adGroupName": "",
      //   "adPlanId": 0,
      //   "adPlanName": "",
      //   "deepOcpcLow": 0,-深度转化出价预警最低值
      //   "deepOcpcTop": 0,-深度转化出价预警最高值
      //   "deptId": 0,
      //   "ocpcLow": 0,-转化出价预警最低值
      //   "ocpcTop": 0,-转化出价预警最高值
      //   "ownerId": 0,
      //   "ownerName": "",
      //   "priceLow": 0,-基础出价预警最低值
      //   "priceTop": 0,-基础出价预警最高值
      //   "userId": 0,
      //   "userName": "",
      //   "warningLow": 0-预警最低值
      // }]
      let _this = this,params = [],type = ''
      _this.yulanList.forEach(obj=>{
        params.push({
        "adGroupId": obj.adGroupId,
        "adGroupName":obj.adGroupName,
        "adPlanId": obj.planId,
        "adPlanName": obj.planName,
        "deepOcpcLow": Number(_this.formData.deepOcpcLow),//-深度转化出价预警最低值
        "deepOcpcTop": Number(_this.formData.deepOcpcTop),//-深度转化出价预警最高值
        "deptId":_this.userContent.deptId,
        "ocpcLow": Number(_this.formData.ocpcLow),//-转化出价预警最低值
        "ocpcTop": Number(_this.formData.ocpcTop),//-转化出价预警最高值
        "ownerId": _this.userContent.ownerId,
        "ownerName":_this.userContent.ownerName,
        "priceLow": Number(_this.formData.priceLow),//-基础出价预警最低值
        "priceTop": Number(_this.formData.priceTop),//-基础出价预警最高值
        "userId": _this.userContent.userId,
        "userName": _this.userContent.userName})
      })
      console.log(params)
      $http.axios.post("/api/advertisement/adGroup/v1/addAdGroupWarning",params).then(res=> {
        // _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.onCancel()
          // _this.getAdGroupWarningDetailFn()
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    }, 
    // 出价预警修改
    updateAdGroupWarningFN(){
      // "deepOcpcLow": 0,
      // "deepOcpcTop": 0,
      // "deptId": 0,
      // "id": 0,
      // "ocpcLow": 0,
      // "ocpcTop": 0,
      // "priceLow": 0,
      // "priceTop": 0,
      // "userId": 0,
      // "userName": "",
      // "warningType": 0
      let _this = this,params = {},type = ''
      params = {"deepOcpcLow": Number(_this.formData.deepOcpcLow),//-深度转化出价预警最低值
        "deepOcpcTop": Number(_this.formData.deepOcpcTop),//-深度转化出价预警最高值
        "deptId":_this.userContent.deptId,
        "id":_this.formData.id,
        "ocpcLow": Number(_this.formData.ocpcLow),//-转化出价预警最低值
        "ocpcTop": Number(_this.formData.ocpcTop),//-转化出价预警最高值
        "priceLow": Number(_this.formData.priceLow),//-基础出价预警最低值
        "priceTop": Number(_this.formData.priceTop),//-基础出价预警最高值
        "userId": _this.userContent.userId,
        "userName": _this.userContent.userName}
      $http.axios.post("/api/advertisement/adGroup/v1/updateAdGroupWarning",params).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.onCancel()
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
  width: 80px;
  padding: 8px 10px!important;
  text-align: center;
  margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
.el-input{
  width: 240px;
  margin-bottom: 8px;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
.formDatastyle .el-form-item--mini.el-form-item{
  margin:0;
}
.footerBottom{
  margin-top:20px;
  margin-bottom:20px;
  text-align: center;
}
</style>

