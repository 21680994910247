<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="批量启停"
  >
    <el-table 
    :data="tableData"
    ref="multipledetailTable">
      <el-table-column
        property="priceDate"
        label="启停日期">
        <template slot-scope="scope">
          {{ scope.row.dateRange}}
        </template>
      </el-table-column>
      <el-table-column
        property="priceDateSlot"
        label="启停时间段">
        <template slot-scope="scope">
          <div v-for="(time,i) in scope.row.details" :key="i">
            <span>
              <strong v-if="time.week == 1">周一</strong>
              <strong v-if="time.week == 2">周二</strong>
              <strong v-if="time.week == 3">周三</strong>
              <strong v-if="time.week == 4">周四</strong>
              <strong v-if="time.week == 5">周五</strong>
              <strong v-if="time.week == 6">周六</strong>
              <strong v-if="time.week == 7">周日</strong>
            </span>
            <span>：{{time.timeRange}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        property="priceDateSlot"
        label="当前时段是否执行">
        <template slot-scope="scope">
          <div v-for="(time,i) in scope.row.details" :key="i">
            <span>{{time.excludeFlag}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        property="implementStatus"
        label="执行状态">
        <template slot-scope="scope">
          <!-- 1-待执行 2-执行中 3-执行完成 4-异常 5-弃用 -->
          <span v-if="scope.row.status == 1">待执行</span>
          <span v-else-if="scope.row.status == 2">执行中</span>
          <span v-else-if="scope.row.status == 3">执行完成</span>
          <span v-else-if="scope.row.status == 4">异常</span>
          <span v-else-if="scope.row.status == 5">弃用</span>
        </template>
      </el-table-column>
    </el-table>    
    <!-- <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('formData')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';
export default {
  
  name: "startStopDialog",

  props: {
    pubVue:Object,
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      titleList:[{name:'广告组名称',prop:'adGroupName'},
        {name:'广告组ID',prop:'adGroupId'},// 0开启 1关闭
        {name:'执行内容',prop:'groupCount'},
        {name:'计划名称',prop:'planName'}],
      tableData:[],
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    
    
  },
  methods: {
   
    onConfirm(formName) {
      let _this = this
     },
    onCancel() {
      this.show= false;
    },
    addOrSetFn(type){
      
    },

      

   //点击时间段详情接口
      timeSlotDetail(params){
        let that = this,response
        console.log(params)
        $http.axios.post("/api/yyb/openStop/infoList",params).then(res=> {
        if(res.data.code == 200){
          response = res.data
          that.tableData = response.data.infos
          that.pubVue.$refs.multipleTable.clearSelection();
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
</style>

