<template>
  <div class="sso-management">
    <poc-container class="sm-container">
      <div class="navigation" ref="navBar"  >
        <div class="nav_logo">
          <div class="logoEd" @click="goHome(navList.name)"><img :src="logoImg" /></div>
          <!-- <div class="product-name">北京亨利</div> -->
        </div>
        <div class="nav_centerList">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="#fff"
            text-color="#777"
            active-text-color="#48916F">
              <!-- <el-menu-item index="1">首页管理</el-menu-item> -->
              <el-submenu index="1">
                <template #title>首页管理</template>
                <!-- <el-menu-item index="1-1" style="text-align:center;" @click="goHome(navList[0].name)">{{navList[0].navItem}}</el-menu-item> -->
                <el-menu-item index="1-1" style="text-align:center;font-weight:700!important;" @click="goHome(navList[15].name)">{{navList[15].navItem}}</el-menu-item>

                <span>
                  <el-menu-item v-if="userId == 559" index="1-5" style="text-align:center;font-weight:700!important;" @click="goHome(navList[25].name)">{{navList[25].navItem}}</el-menu-item>
                  <el-menu-item v-else index="1-5" style="text-align:center;font-weight:700!important;" @click="goHome(navList[12].name)">{{navList[12].navItem}}</el-menu-item>
                </span>
                <span v-show="userName == 'jinnan'">
                  <el-menu-item index="1-6" style="text-align:center;font-weight:700!important;" @click="goHome(navList[17].name)">{{navList[17].navItem}}</el-menu-item>
                </span>
                <span v-show="userId == 382">
                  <el-menu-item index="1-6" style="text-align:center;font-weight:700!important;" @click="goHome(navList[22].name)">{{navList[22].navItem}}</el-menu-item>
                </span>
                <!-- 608,553 -->
                <!-- {{userId}} -->
                <el-menu-item index="1-6" v-if="userId == 346||userId == 608||userId == 553" style="text-align:center;font-weight:700!important;" @click="goHome(navList[27].name)">{{navList[27].navItem}}</el-menu-item>
                <!-- <el-menu-item index="1-6" v-if="userId == 341" style="text-align:center;font-weight:700!important;" @click="goHome(navList[27].name)">{{navList[27].navItem}}</el-menu-item> -->
                
                <!-- <span v-show="userId == 559">
                  <el-menu-item index="1-7" style="text-align:center;" @click="goHome(navList[25].name)">{{navList[25].navItem}}</el-menu-item>
                </span> -->
                <span v-show="userId == 559">
                  <el-menu-item index="1-7" style="text-align:center;font-weight:700!important;" @click="goHome(navList[23].name)">{{navList[23].navItem}}</el-menu-item>
                </span>
                <span v-show="userId == 644">
                  <el-menu-item index="1-7" style="text-align:center;font-weight:700!important;" @click="goHome(navList[29].name)">{{navList[29].navItem}}</el-menu-item>
                </span>
                <span v-show="userId == 559">
                  <el-menu-item index="1-8" style="text-align:center;font-weight:700!important;" @click="goHome(navList[24].name)">{{navList[24].navItem}}</el-menu-item>
                </span>

                <el-menu-item index="1-2" v-if="apple" style="text-align:center;font-weight:700!important;" @click="goHome(navList[0].name)">{{navList[0].navItem}}</el-menu-item>
                <el-menu-item index="1-3" v-if="apple" style="text-align:center;font-weight:700!important;" @click="goHome(navList[1].name)">{{navList[1].navItem}}</el-menu-item>
                <!-- <el-menu-item index="1-4" style="text-align:center;" @click="goHome(navList[2].name)">{{navList[2].navItem}}</el-menu-item> -->
              </el-submenu>

              <el-submenu index="3">
                <template #title>运营管理</template>
                <div v-if="apple">
                  <el-menu-item index="3-1" v-if="deptId == '313'" style="text-align:center;font-weight:700!important;" @click="goHome(navList[26].name)">{{navList[26].navItem}}</el-menu-item>
                  <el-menu-item index="3-1" v-else style="text-align:center;font-weight:700!important;" @click="goHome(navList[3].name)">{{navList[3].navItem}}</el-menu-item>
                </div>
                <!-- <el-menu-item index="3-2" v-if="guoqian" style="text-align:center;" @click="goHome(navList[19].name)">{{navList[19].navItem}}</el-menu-item> -->
                <!-- <span v-for="item in qdoptions">
                  <span v-if="item.id == 5 || item.id == 6 || item.id == 8|| item.id == 9">
                  </span>
                  <span v-else>
                    <el-menu-item index="3-3" v-if="xmguoqian" style="text-align:center;font-weight:700!important;" @click="goHome(navList[4].name)">{{navList[4].navItem}}</el-menu-item>

                  </span>
                </span> -->
                <el-menu-item index="3-3" v-if="xmguoqian" style="text-align:center;font-weight:700!important;" @click="goHome(navList[4].name)">{{navList[4].navItem}}</el-menu-item>

                <el-menu-item index="3-4" v-if="apple" style="text-align:center;font-weight:700!important;" @click="goHome(navList[5].name)">{{navList[5].navItem}}</el-menu-item>
                <el-menu-item index="3-5" v-if="apple" style="text-align:center;font-weight:700!important;" @click="goHome(navList[11].name)">{{navList[11].navItem}}</el-menu-item>
                <!-- 小米：deptId == 316，oppo：deptId == 300，vivo：deptId == 307，华为：deptId == 325，应用宝：deptId == 324，admin:deptId = 103 -->
                <el-menu-item index="3-6" style="text-align:center;font-weight:700!important;" v-if="deptId == 316||deptId == 300||deptId == 307||deptId == 324||deptId == 325||deptId == 103" @click="goHome(navList[21].name)">{{navList[21].navItem}}</el-menu-item>
                <div v-if="appleElse">
                  <!-- <el-menu-item index="3-7" v-if="appleElse" style="text-align:center;" >敬请期待</el-menu-item> -->
                  <el-menu-item index="3-1" style="text-align:center;font-weight:700!important;" @click="goHome1">{{navList[3].navItem}}</el-menu-item>
                  <el-menu-item index="3-3" style="text-align:center;font-weight:700!important;" @click="goHome1">{{navList[4].navItem}}</el-menu-item>
                  <el-menu-item index="3-4" style="text-align:center;font-weight:700!important;" @click="goHome1">{{navList[5].navItem}}</el-menu-item>
                  <el-menu-item index="3-5" style="text-align:center;font-weight:700!important;" @click="goHome(navList[11].name)">{{navList[11].navItem}}</el-menu-item>
                  <el-menu-item index="3-6" v-if="roleKeyqx == 'optiperson-17'" style="text-align:center;font-weight:700!important;" @click="goHome(navList[21].name)">{{navList[21].navItem}}</el-menu-item>
                  <!-- <el-menu-item index="3-5" style="text-align:center;" @click="goTimeDaily">日报模板库</el-menu-item> -->
                </div>


              </el-submenu> 
              <el-submenu index="4">
                <template #title>计划管理</template>
                <el-menu-item index="4-1" style="text-align:center;font-weight:700!important;" @click="goHome(navList[6].name)">{{navList[6].navItem}}</el-menu-item>
                <span v-if="gaode">
                  <el-menu-item index="4-2" style="text-align:center;font-weight:700!important;" @click="goHome(navList[18].name)">{{navList[18].navItem}}</el-menu-item>
                </span>
              </el-submenu>
             
              <el-submenu index="5">
                <template #title>系统管理</template>
                <div >
                  <div v-if="appleElse">
                    <el-menu-item index="5-1" style="text-align:center;font-weight:700!important;" @click="goEditPW">修改密码</el-menu-item>
                  </div>
                  <div v-else>
                    <el-menu-item index="5-1" style="text-align:center;font-weight:700!important;" @click="goHome(navList[7].name)">{{navList[7].navItem}}</el-menu-item>
                  </div>
                </div>
                <!-- <el-menu-item index="5-1" style="text-align:center;" @click="goHome(navList[7].name)">{{navList[7].navItem}}</el-menu-item> -->
                <!-- <el-menu-item index="5-4" style="text-align:center;"><a href="http://power.hljyer.com/" target="_blank">{{navList[13].navItem}}</a></el-menu-item> -->
                <div>
                  <el-menu-item v-if="appleElse" index="5-4" style="text-align:center;font-weight:700!important;" @click="quanxian">{{navList[13].navItem}}</el-menu-item>
                  <el-menu-item v-else index="5-4" style="text-align:center;font-weight:700!important;"><a href="http://power.hljyer.com/" target="_blank">{{navList[13].navItem}}</a></el-menu-item>
                </div>
                <el-menu-item v-show="userName == 'admin'" index="5-4" style="text-align:center;font-weight:700!important;"  @click="goHome(navList[28].name)">{{navList[28].navItem}}</el-menu-item>

                <div>
                  <div v-if="appleElse">
                    <!-- 归因文档 -->
                    <el-menu-item index="5-3" style="text-align:center;font-weight:700!important;" @click="goAscribe">归因文档</el-menu-item>

                  </div>
                  <div v-else>
                     <el-menu-item index="5-5" style="text-align:center;font-weight:700!important;" @click="goHome(navList[14].name)">{{navList[14].navItem}}</el-menu-item>
                     <el-menu-item index="5-6" style="text-align:center;font-weight:700!important;" @click="goHome(navList[20].name)">{{navList[20].navItem}}</el-menu-item>
                  </div>
                </div>
                <!-- <el-menu-item index="5-5" style="text-align:center;" @click="goHome(navList[14].name)">{{navList[14].navItem}}</el-menu-item>
                <el-menu-item index="5-6" style="text-align:center;" @click="goHome(navList[20].name)">{{navList[20].navItem}}</el-menu-item> -->
                
                <span v-show="userName == 'emma'||userName == 'jinnan'||userName == 'liuzhihui'||userName == 'admin'">
                  <el-menu-item index="5-7" style="text-align:center;font-weight:700!important;" @click="goHome(navList[16].name)">{{navList[16].navItem}}</el-menu-item>
                </span>

                <span v-if="roleKeyqx == 'develop'">
                  <el-menu-item index="5-2" style="text-align:center;font-weight:700!important;" @click="goHome(navList[8].name)">{{navList[8].navItem}}</el-menu-item>
                  <el-menu-item index="5-3" style="text-align:center;font-weight:700!important;" @click="goHome(navList[9].name)">{{navList[9].navItem}}</el-menu-item>
                </span>
              </el-submenu>
              
            </el-menu>
        </div>
        <div class="nav_rightGroup">
          <HeaderRightGroup slot="rightGroup" />
        </div>
      </div>
        <div class="sso-management-body" ref="obtain">
        <router-view />
      </div>
    </poc-container>
  </div>
</template>

<script>
import logoImg from '@/assets/img/hl_logo.png';
import HeaderRightGroup from '@/components/HeaderRightGroup';
// import NavigationTop from '@/components/NavigationTop';
// import MenuLeft from '@/components/MenuLeft';
import { $http } from '@/api/http';
import Api from "@/api";
import config from '@/api/config';

export default {
  name: 'Home',

  components: {
    HeaderRightGroup
    // MenuLeft
  },

  data() {
    return {
      logoImg,
      // nav
      activeIndex: '1-1',
      navList:[
        // {name:'/auth-management/HomePage',navItem:''},
        {name:'/auth-management/UserList',navItem:'用户列表'},//0
        {name:'/auth-management/AccountList',navItem:'账户列表'},//1
        {name:'/auth-management/LoginJournal',navItem:'登陆日志'},//2
        {name:'/auth-management/materialListPic',navItem:'素材库'},//3
        {name:'/auth-management/ConsumptionSumOppo',navItem:'消耗汇总'},//4
        {name:'/auth-management/DemandOrder',navItem:'需求工单'},//5
        {name:'/plan-management/AccountSelection',navItem:'账户选择'},//6
        {name:'/auth-management/StaffManage',navItem:'人员管理'},//7
        {name:'/auth-management/RoleManage',navItem:'角色管理'},//8
        {name:'/auth-management/JournalManage',navItem:'系统日志'},//9
        {name:'/auth-management/ChannelDataFlow',navItem:'数据流量'},//10
        {name:'/auth-management/reportForms',navItem:'日报模板库'},//11
        {name:'/auth-management/dataSjkb',navItem:'数据看板'},//12
        {name:'',navItem:'权限系统'},//13
        {name:'/auth-management/FaqVivo',navItem:'FAQ'},//14
        {name:'/auth-management/Notice',navItem:'系统公告'},//15
        {name:'/auth-management/DataDisplay',navItem:'培训看板'},//16
        {name:'/auth-management/zzdataSjkb',navItem:'总助看板'},//17
        {name:'/plan-management/accountSelectionGaode',navItem:'二代账户'},//18
        {name:'/plan-management/MaterialClassify',navItem:'素材分类排行榜'},//19
        {name:'/auth-management/OPPOFaq',navItem:'媒体FAQ'},//20
        {name:'/auth-management/DailyKpi',navItem:'KPI日报数据维护'},//21
        {name:'/auth-management/DataSjkbOppo',navItem:'OPPO数据看板'},//22
        {name:'/auth-management/dataSjkbVivo',navItem:'VIVO数据看板'},//23
        {name:'/auth-management/dataSjkbAsa',navItem:'ASA数据看板'},//24
        {name:'/auth-management/dataSjkboppo2',navItem:'OPPO数据看板'},//25
        {name:'/auth-management/materialList',navItem:'素材库'},//26
        {name:'/auth-management/DataSjkbVivoQu',navItem:'声趣系专用看板'},//27
        {name:'/auth-management/StaffManageList',navItem:'人员系统管理'},//28
        {name:'/auth-management/databordVivo',navItem:'VIVO数据看板'},//29

      ],
      roleKeys:[],
      roleKeyqx:'',
      navShow:false,
      userName:'',
      gaode:true,
      guoqian:false,//特定的人
      deptId:'',
      userId:'',
      xmguoqian:'',
      apple:false,//苹果部门的导航显示
      appleElse:true,
      qdoptions:[]

    };
  },
  mounted() {
    this.getuserChannel()
    this.authority()
    this.getRole()
  },
  methods: {
    //判断权限
    authority(){
      this.roleKeys = this.$sessionStorage.getItem(config.ROLES)
      this.roleKeyqx = this.roleKeys.toString()
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
    },

    handleSelect(key, keyPath) {
      
    },
    goHome(page){
      this.$router.push(page)
    },
    //asa暂未开放的页面
    goHome1(){
      this.$message({
        showClose: true,
        message: '暂未开放，敬请期待',
        type: 'error'
      })
    },
    //ASA时报日报
    goHome2(){
      // this.$router.push(page)
    },
    //asa权限访问提示
    quanxian(){
      this.$message({
        showClose: true,
        message: '无访问权限',
        type: 'error'
      })
    },
    //asa归因文档
    goAscribe(){
      this.$router.push('/auth-management/ascribe')
    },
    //asa进入员工修改密码
    goEditPW(){
      this.$router.push('/auth-management/StaffManagepw')
    },
    getuserChannel(){
        let that = this,userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId=" + userChannel).then(function(response) {
          if(response.data.code == 200){
            if(response.data.data.length!=0){
              that.qdoptions = response.data.data
            }else{
              that.$message({
                showClose: true,
                message: "您的操作权限不足",
                type: 'error'
              })
            }
          }
        })
      },
    //获取角色权限
    getRole(){
      let that = this,guoqian
      $http.axios.get("/api/system/user/getInfo").then(function(response) {
        if(response.data.code == 200){
          that.roleKey = response.data.roles
          if(that.roleKey.includes('optiperson-12')){
            that.gaode = true
          }else{
            that.gaode = false
          }
          //guoqian
          guoqian = response.data.user.userId
          that.userId = response.data.user.userId
          if(guoqian == 483){
            that.guoqian = false
          }else{
            that.guoqian = true
          }

          //获取部门id
          that.deptId = response.data.user.deptId
          //苹果部门的id
          if(that.deptId == 328 ||that.deptId == 329){
            that.apple = false
            that.appleElse = true
          }else{
            that.apple = true
            that.appleElse = false
          }
          
          if(guoqian == 483){
            that.xmguoqian = false
          }else if(that.deptId == 326){
            that.xmguoqian = false
          }else if(that.deptId == 328||that.deptId == 329){
            that.xmguoqian = false
          }else{
            that.xmguoqian = true
          }
          console.log(that.xmguoqian)
          
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">
  /*原导航隐藏*/
  .poc-container .poc-header{
    display:none;
  }
  .sso-management {
    height: 0%;
    .sm-container {
      background: $base-bg-color;
    }
    .sso-management-body {
      padding: 0 $MP16;
    }
    .product-name {
      font-family: "tradeGothicLHExtended" !important;
      font-weight: bold;
      font-size: 20px;
    }
  }
  .poc-container .poc-header .poc-header-logo img {
    height:70%;
  }
  .common-page {
    padding: 20px 30px;
  }
  .hl_breadCrumb {
    /* margin: $MP20 0 $MP4; */
    padding: 0 10px;
  }
  /*hl导航*/
  .navigation{
    width:100%;
    height:60px;
    background:#fff;
    padding:0 20px;
    box-sizing:border-box;
    z-index:100;
  }
  .nav_logo,.nav_centerList,.nav_rightGroup{
    display:inline-block;
    vertical-align:middle;
    height:60px;
  }
  .nav_logo{
    width:15%;
    cursor:pointer;
  }
  .logoEd,.product-name{
    display:inline-block;
    vertical-align:middle;
  }
  .logoEd{
    width:96px;
    margin-top:15px;
  }
  .product-name{
    width:40%;
    color:#fff;
  }
  .logoEd img{
    width:100%;
  }
  .nav_rightGroup{
    width:200px;
    text-align:right;
    float:right;
  }
  .nav_centerList{
    /* width:70%; */
    width:800px;
  }
  .nav_centerList .el-submenu__title i{
    color:#fff;
  }
  .nav_centerList ul li{
    padding:0 3.5%;
  }
  .nav_rightGroup{
    text-align:right;
    line-height:60px;
  }
  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title{
    font-weight: 500;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow{
    width: 0;
    height: 0;
    border-top: 4px solid #777;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    margin-top:-1px;
  } 
  .el-icon-arrow-down:before{
    display: none;
  }
  .el-menu--horizontal > .el-submenu .el-submenu__title{
    border:none!important;
  }
  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title .el-submenu__icon-arrow{
    border-top: 4px solid #48916F;
  }
  .el-menu .el-submenu{
    position: relative;
  }
  .el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
    position: absolute;
    min-width: 120px!important;
    left:24px;
  }
  

</style>
