<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    class="product-detail-dialog"
    title="Product Register"
  >
    <el-form
      style="width: 480px;margin:0 auto;margin-left:180px;"
      label-position="left"
      label-width="160px"
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules"
    >
      <el-form-item label="Product Name" prop="productname" class="marginB50">
        <el-input
          :class="{'redFont':!showProductName}"
          v-model="formData.productName"
          maxlength="100"
          show-word-limit
        ></el-input>
        <span
          v-if="showProductName"
          class="el-form-item__error fontS12"
        >Only hyphens and letters can be used xxxx_xxxx</span>
      </el-form-item>
      <el-form-item label="Product Code" prop="productcode" class="marginB50">
        <el-input
          :class="{'redFont':!showProductCode}"
          v-model="formData.productCode"
          maxlength="100"
          show-word-limit
        ></el-input>
        <span
          v-if="showProductCode"
          class="el-form-item__error fontS12"
        >Only hyphens and letters can be used xxxx_xxxx</span>
      </el-form-item>
     
      <el-form-item label="Product URL" prop="producturl" class="marginB50">
        <el-input
          type="textarea"
          :rows="6"
          :class="{'redFont':!showProductURL}"
          v-model="formData.productURL"
          maxlength="100"
          show-word-limit
        ></el-input>
        <span
          v-if="showProductURL"
          class="el-form-item__error fontS12"
        >Only hyphens and letters can be used xxxx_xxxx</span>
      </el-form-item>
    
      <el-form-item style="text-align:right;margin-right:-180px;">
        <el-button type="primary" @click="submitForm('formData')">Confirm</el-button>
      </el-form-item>
    </el-form>

    <!-- <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm">{{$t('dc:确认')}}</el-button>
    </span>-->
    <el-dialog
      width="30%"
      title="Confirm"
      :visible.sync="innerVisible"

      :append-to-body="true">
      <div>
        Do you confirm to register this product?
      </div>
      <div slot="footer">
        <el-button type @click="show=false" class="marginR20">No</el-button>
        <el-button type="primary" >YES</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import confromProductDialog from '@/views/dialog/usuallyDialog'
import Api from "@/api";

export default {
  name: "productDialog",
  components: {
    confromProductDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: String,
    typeOptions: {
      type: Array,
      default: () => []
    },
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    var productCodeRoute = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
        this.showProductCode = false;
      } else if (!new RegExp("[A-Z]").test(value)) {
        this.showProductCode = false;
        callback(new Error(rule.message || "请输入正确的联系人"));
      } else {
        this.showProductCode = true;
        callback();
      }
    };
    var productURLRoute = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
        this.productURL = false;
      } else if (!new RegExp("[A-Z]").test(value)) {
        this.productURL = false;
        callback(new Error(rule.message || "请输入正确的联系人"));
      } else {
        this.productURL = true;
        callback();
      }
    };
    return {
      loading: false,
      show: this.visible,
      innerVisible: false,
      showProductName: true,
      showProductCode: true,
      showProductURL: true,
      currentTypeConfig: {},
      formData: {
        productName: "",
        productCode: "",
        productURL: "",
      },
      rules: {
        productname: [{ required: true, message: this.$t("dc:请输入") }],
        productcode: [{ validator: productCodeRoute, trigger: ["blur", "change"] }],
        producturl: [ { validator: productURLRoute, trigger: ["blur", "change"] }]
      }
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },

  methods: {
    onConfirm() {},
    onCancel() {
      this.show= false;
    },
    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.innerVisible = true
      // this.$refs[formName].validate(valid => {
      //   if (valid) {
      //     alert("submit!");
      //   } else {
      //     console.log("error submit!!");
      //     return false;
      //   }
      // });
    },
  }
};
</script>
<style lang="scss">
.product-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
</style>

