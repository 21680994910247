<template>
  <!-- 原来的home页 -->
    <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页管理</el-breadcrumb-item>
        <!-- <el-breadcrumb-item>首页信息</el-breadcrumb-item> -->
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
        <div class="homePlatform">
          <div class="platformData">
            <div class="platformData_head">
              <span class="platformTitle">平台数据</span>
              <div class="materialDateQuery">
                <el-date-picker
                  v-model="materialDate"
                  type="daterange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="消耗开始日期"
                  end-placeholder="消耗结束日期"
                  placeholder="请选择素材消耗的开始结束日期"
                  :default-time="['00:00:00', '23:59:59']"
                  @change="changeData">
                </el-date-picker>
              </div>
            </div>
            <div class="platformDate">
              <table>
                <thead>
                  <th width="30%">渠道名称</th>
                  <th width="70%">素材消耗</th>
                </thead>
                <tbody>
                  <tr>
                    <td>今日头条</td>
                    <td>{{materialNum.ttcostNmu}}</td>
                  </tr>
                  <tr>
                    <td>快手</td>                      
                    <td>{{materialNum.kscostNmu}}</td>
                  </tr>
                  <tr>
                    <td>总计</td>                   
                    <td>{{materialNumSum}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="platformUpdate">
              <span class="platformTitle">素材上传数据</span>
              <ul class="platformScList">
                <li>
                  <span>今日上传</span><span>{{upDateMaterial.todayUploadNums}}</span>
                </li>
                <li>
                  <span>待上传</span><span>{{upDateMaterial.touploadNums}}</span>
                </li>
                <li>
                  <span>上传数</span><span>{{upDateMaterial.uploadNums}}</span>
                </li>
                <li>
                  <span>上传失败</span><span>{{upDateMaterial.failed}}</span>
                </li>
                <!-- <li>
                  <span>审核失败</span><span>{{upDateMaterial.todayUploadNums}}</span>
                </li> -->
              </ul>
            </div>
            
          </div>
          <div class="platformInfo platformInfoNum platformInfoNumTest">
            <div class="platformInfoScroll">
              <span class="platformInfoTitle">信息公告</span>
              <p>2021年11月15日，OPPO投放系统上线公测，公测后，技术中心会对使用过程中产生的BUG及时进行修复，因为有新的开发任务，对新的需求会进行收集并酌情排期实现，在使用过程中发现问题，请及时通过钉钉提交到技术中心产品经理-褚鹏飞处，技术中心会尽快解决，谢谢配合！！！</p>
              <!-- <p>2021年8月18日最后一版更新的内容是：</p>
              <p class="platformInfoNum2"><b></b>优化了产品名称变更为客户名称；</p>
              <p class="platformInfoNum2"><b></b>优化了现客户和项目之间的层级关系为，一个客户对应多个项目，一个项目对应多个账户；</p>
              <p class="platformInfoNum2"><b></b>优化消耗汇总数据的展示维度；</p>
              <p class="platformInfoNum3"><i></i>数据展示维度为本平台上传的素材在第三方（快手、头条）平台消耗的总和；</p>
              <p class="platformInfoNum3"><i></i>渠道支持多选，如：渠道选了头条和快手，那么素材消耗数据为头条和快手的总和；</p>
              <p class="platformInfoNum3"><i></i>可根据消耗产生的时间进行搜索，消耗为搜索时间的总和；</p>
              <p class="platformInfoNum3"><i></i>关联计划和创意展示为当前在第三方平台（快手、头条）开启中的计划和创意数量；</p>
              <p class="platformInfoNum3"><i></i>数值未做精确到百分位操作，原因为会影响最终运算结果的准确性。</p> -->
            </div>
          </div>
        </div>
        <div class="homeStatistics">
          <div class="homeStatistics_head">
            <span class="platformTitle">数据统计</span>
            <div class="homeStatistics_select">
              <el-select v-model="statisticsvalue" placeholder="请选择渠道" @change="changeChannel($event)">
                <el-option
                  v-for="item in qdoptions"
                  :key="item.value"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-date-picker
                v-model="statistics_seValue"
                type="daterange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                @change="cahngeDate">
              </el-date-picker>
              
            </div>
          </div>
          <div class="statistics_pic">
            <div id="statistics_main" style="width:100%;height:300px;"></div> 
            <!-- <div ref="main" :style="{width: '100%', height: '500px'}"></div> -->
          </div>
          
        </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <accessDialog :visible.sync="showDialog" ></accessDialog>
  </div>
  </template>
  
 
<script>
import accessDialog from '@/views/dialog/accountDialog';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'authority',
  components: {
    accessDialog
  },
  data() {
    const generateData = _ => {
      const data = [];
      const xhData = ['资金属性', '资金消耗', '账户消耗', '虚拟金消耗', '素材消耗'];
      xhData.forEach((xhData, index) => {
        data.push({
          label: xhData,
          key: index
        });
      });
      return data;
    };
    return {
      // oderFormOption: {
      //   title: {
      //     text: "商品分析图",
      //   },
      //   tooltip: {
      //     trigger: "axis",
      //   },

      //   grid: {
      //     left: "3%",
      //     right: "4%",
      //     bottom: "3%",
      //     containLabel: true,
      //   },
      //   toolbox: {
      //     feature: {
      //       saveAsImage: {},
      //     },
      //   },
      //   xAxis: {
      //     type: "category",
      //     splitLine: { show: false },
      //     data: [],
      //     axisLabel: {
      //       interval: 10,
      //     },
      //     boundaryGap: false,
      //   },
      //   yAxis: [
      //     {
      //       type: "value",
      //     },
      //     {
      //       type: "value",
      //       name: "商品转化率%",
      //       position: "right",
      //       axisLine: {
      //         show: false,
      //       },
      //       min: "0",
      //       max: "100",
      //       axisTick: { show: false },
      //       axisLabel: {
      //         show: true,
      //         interval: "auto",
      //         formatter: "{value}%",
      //       },
      //       show: true,
      //     },
      //   ],
      //   series: [
      //     {
      //       name: "商品访问数",
      //       type: "line",
      //       smooth: true,
      //       color: ["#175EDE"],
      //       data: [],
      //     },
      //     {
      //       name: "商品成功支付数",
      //       type: "line",
      //       smooth: true,
      //       color: ["#F3D024"],

      //       data: [],
      //     },
      //     {
      //       name: "商品成功支付金额",
      //       type: "line",
      //       smooth: true,
      //       color: ["#19B955"],

      //       data: [],
      //     },
      //     {
      //       name: "商品成功支付积分",
      //       type: "line",

      //       smooth: true,
      //       color: ["#FF6C6A"],

      //       data: [],
      //     },
      //     {
      //       name: "商品转化率(%)",
      //       type: "line",
      //       yAxisIndex: 1,
      //       color: ["#85D4D3"],
      //       smooth: true,
      //       data: [],
      //     },
      //   ],
      // },
      showDialog: false,
      qdoptions: [],
      platformvalue: '',
      statisticsvalue:'',
      statistics_seValue:'',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      data: generateData(),
      platform__value:[],
      upDateMaterial:[],
      upChannel:'',
      upUserId:'',
      materialNum:{},
      materialNumSum:'',
      grain:'1',
      // getbeginTime:'2021-05-02 00:00:00',
      // getendTime:'2021-07-02 00:00:00',
      getbeginTime:'',
      getendTime:'',
      ksData:[],
      ttData:[],
      sevenData:[],
      ttsevenData:[],
      kssevenData:[],
      
      countData:[],
      countDateRq:[],
      countDateSj:[],
      ksDateRq:[],
      ksDateSj:[],
      ttDateRq:[],
      ttDateSj:[],
      changeChannelqd:'',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      materialDate:'',







    }
  },
  computed: {
     
  },
  mounted() {
    this.$nextTick(function() {
      this.drawLine('',7)
    })
    this.upUserId = String(this.$sessionStorage.getItem(config.USERID) || 1)

    this.getuserChannel()
    this.getIndexMaterial()
  },
  methods: {
    
    //修改后的关联到user的channel
    getuserChannel(){
      let that = this
      let userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
      $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
        that.qdoptions = response.data.data
        // that.statisticsvalue = response.data.data[0].name
        if(response.data.data.length!=0){
          that.upChannel = response.data.data[0].id
          // that.queryDemandParams.channel = response.data.data[0].id
          that.getMaterialUpdate()
        }
      })
    },


    changeChannel(event){
      let that = this
      that.changeChannelqd = event
      if(that.changeChannelqd == 1){
        that.drawLine('tt')
      }else{
        that.drawLine('ks')
      }
    },

    cahngeDate(){
      this.drawLine()
    },
    // 时间戳转换器
    formatDate(now) { 
      var year=now.getFullYear(); 
      var month=now.getMonth()+1; 
      var date=now.getDate(); 
      var hour=now.getHours(); 
      var minute=now.getMinutes(); 
      var second=now.getSeconds(); 
      return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second; 
    } ,
    // 折线图
    drawLine(channelName,day){
      let that = this
      //获取折线图---日
      if(day == 7|| String(that.statistics_seValue[0]) == 'undefined'){
        const dateNew = new Date();
        const start = new Date(dateNew.getTime());// start.getTime() - 3600 * 1000 * 24 * 7
        const end = new Date(dateNew.getTime()- 3600 * 1000 * 24 * 7);// start.getTime() - 3600 * 1000 * 24 * 7
        that.getbeginTime = that.formatDate(end)
        that.getendTime = that.formatDate(start)
      }else{
        that.getbeginTime = String(that.statistics_seValue[0])
        that.getendTime = String(that.statistics_seValue[1])
      }
      that.countData = []
      $http.axios.get("/api/channel/index/indexCountLine?userId="+that.upUserId+"&grain="+that.grain+"&beginTime="+that.getbeginTime+"&endTime="+that.getendTime).then(function(response) {
        if(channelName == 'tt'){
          that.countData.push(response.data.ttList)
        }else if(channelName == 'ks'){
          that.countData.push(response.data.ksList)
        }else{
          that.countData.push(response.data.countList)
        }
        that.countDateRq = []
        that.countDateSj = []
        that.countData.forEach(function(valcount,index){
          valcount.forEach(function(itemcount,index01){
            that.countDateRq.push(itemcount.dateTime)
            that.countDateSj.push(itemcount.costNmu)
          })   
        }) 
        that.charts.setOption({
          xAxis: {
            type: 'category',
            data:that.countDateRq
          },
          yAxis: {
            type: 'value'
          },
          series: [{
            data: that.countDateSj,
            type: 'line'
          }],
          tooltip : {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          }
        })
      })
    },

    
    //改变表格第一行颜色
    getRowClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return "background:#ddd";
      } else {
        return "";
      }
    },

    //获取素材上传数据
    getMaterialUpdate(){
      let that = this
      // that.upChannel = '1'
      that.upUserId = String(that.$sessionStorage.getItem(config.USERID) || 1)
      
      $http.axios.post("/api/operationManagement/index/materialData?channel="+that.upChannel+"&userId="+that.upUserId).then(function(response) {
        if(response.data.code == 200){
          that.upDateMaterial = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //平台数据-素材消耗
    getIndexMaterial(){
      let that = this
      let jqUserid = that.$sessionStorage.getItem(config.USERID) || 1
      $http.axios.get("/api/channel/index/indexCount?userId="+jqUserid).then(function(response) {
        that.materialNum = response.data
        that.materialNumSum = Math.round(that.materialNum.kscostNmu*100)/100 + Math.round(that.materialNum.ttcostNmu*100)/100
      })
    },

    //平台数据按照日期查询
    changeData(){
      let that = this
      let jqUserid = that.$sessionStorage.getItem(config.USERID) || 1
      let beginTime = that.materialDate[0]
      let endTime = that.materialDate[1]
      console.log(that.materialDate[0])
      $http.axios.get("/api/channel/index/indexCount?userId="+jqUserid+"&beginTime="+beginTime+"&endTime="+endTime).then(function(response) {
        that.materialNum = response.data
        that.materialNumSum = Math.round(that.materialNum.kscostNmu*100)/100 + Math.round(that.materialNum.ttcostNmu*100)/100
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .homePlatform{
    font-size:0;
  }
  .platformData,.platformInfo{
    font-size:14px;
    display:inline-block;
    vertical-align:top;
  }
  .platformTitle{
    font-size:16px;
    font-weight:700;
    color:#6e7c7c;
    padding-bottom:14px;
    float:left
  }
  .platformData{
    width:60%;
  }
  .platformInfo{
    width:35%;
    margin-left:5%;
    margin-top:40px;
    padding:20px 20px;
    box-sizing:border-box;
    background:#fffdef;
    color:#1b1f23;
    border-radius:10px;
  }
  .platformData_select{
    float:right;
  }
  .platformData_table{
    position: relative;
  }
  .platformData_table .el-table__header, .platformData_table .el-table__body, .platformData_table .el-table__footer{
    width:100% !important;
  }
  .platformData_table .el-table tr{
    font-size:14px;
    color:#333;
  }
  .platformUpdate{
    padding-top:30px;
  }
  .platformScList{
    font-size:0;
    clear:both;
    padding:0;
    margin:0;
  }
  .platformScList li{
    display:inline-block;
    vertical-align:middle;
    font-size:14px;
    width:20%;
  }
  .platformScList li span:last-child{
    padding-left:15px;
    color:#185adb;
    font-size:16px;
    font-weight:700;
  }
  .platformInfoTitle{
    display:block;
    margin-bottom:10px;
    font-size:16px;
    color:#1b1f23;
    text-align:center;
  }
  .platformInfo p{
    font-size:14px;
    color:#fff;
    text-indent: 2em;
    line-height:22px;
  }
  .homeStatistics_head{
    margin-top:50px;
  }
  .homeStatistics_select{
    margin-left:100px;
  }
  .homeStatistics_select .el-select{
    margin-right:20px;
  }
  .statistics_pic{
    clear:both;
    padding-top:25px;
  }
  .platformCustom{
    position:absolute;
    top:45px;
    right:15px;
  }
  .platformDate{
    width:100%;
    margin-top:40px;
  }
  .platformDate table{
    width:100%;
    text-align:center;
    border-collapse:collapse;
    border:1px solid #c8e1ff;
  }
  .platformDate table thead{
    height:40px;
    line-height:40px;
    background:#f1f8ff;
    color:#1b1f23;
  }
  .platformDate table tbody,.platformDate table tr{
    border:1px solid #c8e1ff;
  }
  .platformDate table tr{
    height:36px;
    line-height:36px;
  }
  .platformDate table thead th{
    border:1px solid #c8e1ff;
  }
  .platformDate table td{
    border:1px solid #c8e1ff;
  }
  /* .platformInfoNum{
    height:165px;
    overflow: scroll;
  } */
  .platformInfoNum i{
    display:inline-block;
    vertical-align: middle;
    margin-right:4px;
    width:5px;
    height:5px;
    border-radius:5px;
    background:rgba(255,255,255,0.7);
  }
  .platformInfoNum b{
    display:inline-block;
    vertical-align: middle;
    margin-right:5px;
    width:8px;
    height:8px;
    transform: rotate(45deg);
    background:rgba(255,255,255,0.7);
  }
  .platformInfoNum p{
    color:#1b1f23;
    opacity: 0.7;
    text-indent: 1em;
  }
  .platformInfoNum .platformInfoNum2{
    text-indent: 2em;
  }
  .platformInfoNum .platformInfoNum3{
    text-indent: 3em;
  }
  .platformInfoNum{
    width:35%;
    height: 200px;
    overflow: auto;
    border: none;
  }
  .platformInfoScroll{
    /* height: 300px; */
    margin: 0 auto;
    padding-bottom:10px;
  }
  .platformInfoNumTest::-webkit-scrollbar {/*滚动条整体样式*/
    width: 10px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .platformInfoNumTest::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #6e7c7c;
  }
  .platformInfoNumTest::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    /* background: #EDEDED; */
  }
  .materialDateQuery{
    margin-left:100px;
    margin-bottom:-15px;
  }
</style>
