<template>
  <div class="roleList common-page">
    <PocCardTable>
       <template slot="headerTitle">Role List</template>
      <template slot="headerGroup">
        <el-button type="primary" @click="onAdd">{{$t('dc:Add Role')}}</el-button>
        <!-- <el-button type="primary" icon="el-icon-refresh">{{$t('dc:刷新')}}</el-button> -->
      </template>
      <template slot="queryList">
        <el-select
          v-model="roleName"
          filterable
          :placeholder="$t('dc:请选择')"
          @change="onChange"
          class="">
          <el-option
            v-for="item in roleListOptions"
            :key="item.id"
            :label="item.industry"
            :value="item.id"></el-option>
        </el-select>
      </template>

      <PocTable
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        :border="false"
        @size-change="pageSizeChange"
        @page-current-change="pageChange">
        <el-table-column
          v-for="item in columnOptions"
          :key="item.key"
          :prop="item.chlidren ? null : item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sorter="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip>
          <el-table-column
            v-for="chlid in item.chlidren"
            v-if="chlid.visible"
            :key="chlid.key"
            :prop="chlid.key"
            :label="chlid.name"
            :fixed="chlid.fixed"
            :sorter="chlid.sortable"
            :min-width="item.width"
            show-overflow-tooltip></el-table-column>
        </el-table-column>

        <el-table-column :label="$t('dc:操作')" width="130" fixed="right" align="center">
          <template slot-scope="scope">
            <div class="action-btn-group">
              <el-tooltip class="item" effect="dark" :content="$t('dc:编辑')" placement="top-start">
                <i class="poc-icon-primary poc-size12 iconfont icon-oneui-mapping marginR24"></i>
              </el-tooltip>
               <el-tooltip class="item" effect="dark" :content="$t('dc:删除')" placement="top-start">
                <i class="poc-icon-primary poc-size12 iconfont icon-oneui-delete"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </PocTable>
    </PocCardTable>
    <roleDetailDialog :visible.sync="showDialog"></roleDetailDialog>
  </div>
</template>
<script>
import roleDetailDialog from '@/views/dialog/roleDetailDialog'
export default {
  name: 'RoleList',
  components: {
    roleDetailDialog
  },
  data() {
    return{
      roleName: '',
      pageConfig: {
        pageNum: 1,
        total: 20
      },
      loading: false,
      showDialog: true,
      roleListOptions: [],
      tableData:[{
        no: 1,
        roleName: '宝马',
        description: '钱币',
        roleName: '通用',
        URL: 'A',
      }],
       
    }
    
  },
  computed: {
    columnConfig() {
      return [
        {
          key: 'no',
          name: 'No.',
          dataType: 'string', // 字段的数值类型，字符串（string）、数字（number）、日期（date）、布尔（boolean）。
          visible: true, // 是否展示在表格列中
          sortable: false, // 是否可以排序
          fixed: false, // 固定列的位置(left, right)
          width: 60, // 默认宽度，像素
        },
        {
          key: 'roleName',
          name: this.$t('dc:产品名'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: false,
          width: 120,
        },
        {
          key: "description",
          name: this.$t('dc:描述'),
          dataType: 'string',
          visible: true,
          sortable: false,
          fixed: null,
          width: 120,
        }
      ];
    },
     columnOptions() {
      return this.columnConfig.filter(n => n.visible);
    },
  },
  methods:{
   
    pageChange() {},
    pageSizeChange() {},
    onChange() {},
    onAdd() {
      console.log(this.showDialog)
      this.showDialog = true
      // this.$router.push({ name: 'OperationAccess.RoleList' });
    }
  }
}
</script>
