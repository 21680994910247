import PublicisCommon from 'publicis-common';
import App from './App.vue'
import i18nMsg from './i18n/index';
import router from './router';
import store from './store';
import vueWaves from '@/utils/waves';
import config from './api/config';

import '@/assets/css/main.scss';
import '@/assets/css/common.scss';
import '@/assets/css/jtCommon.scss';
import 'publicis-common/packages/assets/fonts/fonts.css';
import echarts from 'echarts';
// import ECharts from 'echarts/components/ECharts'

// Vue.prototype.$echarts = echarts;
import VueQuillEditor from 'vue-quill-editor'

// import 'quill/dist/quill.core.css' // import styles
// import 'quill/dist/quill.snow.css' // for snow theme
// import 'quill/dist/quill.bubble.css' // for bubble theme

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'


require('echarts');

const { OneUI, Vue, i18n } = PublicisCommon;

i18n.mergeLocaleMessage('zh-cn', i18nMsg['zh-cn']);
i18n.mergeLocaleMessage('en-us', i18nMsg['en-us']);
Vue.use(OneUI);
Vue.use(vueWaves);
Vue.use(VueQuillEditor /* { default global options } */)

// response状态码
Vue.prototype.$RESPONSE_STATUS = config.RESPONSE_STATUS;
Vue.prototype.$PROJECT_CODE = config.PROJECT_CODE;
Vue.prototype.clone = function (o) {
  if (!o) return ''
  return JSON.parse(JSON.stringify(o))
}
Vue.filter('dateFormat', function(originVal) {
  // const dt = new Date(originVal*1000) 毫秒时间戳 可自行判断
  const dt = new Date(originVal)
   const y = dt.getFullYear()
   const m = (dt.getMonth() + 1 + '').padStart(2, '0')
   const d = (dt.getDate() + '').padStart(2, '0')
  
  //  const hh = (dt.getHours() + '').padStart(2, '0')
  //  const mm = (dt.getMinutes() + '').padStart(2, '0')
  //  const ss = (dt.getSeconds() + '').padStart(2, '0')
  
  return `${y}-${m}-${d}`
  // return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

Vue.filter('dateTimeFormat', function(originVal) {
  // const dt = new Date(originVal*1000) 毫秒时间戳 可自行判断
  const dt = new Date(originVal)
   const y = dt.getFullYear()
   const m = (dt.getMonth() + 1 + '').padStart(2, '0')
   const d = (dt.getDate() + '').padStart(2, '0')
  
   const hh = (dt.getHours() + '').padStart(2, '0')
   const mm = (dt.getMinutes() + '').padStart(2, '0')
   const ss = (dt.getSeconds() + '').padStart(2, '0')
  
  // return `${y}-${m}-${d}`
  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
