<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    class="set-dialog"
    title="第三方监测链接批量设置"
  >
    <el-form
      style="width: 480px;margin:0 auto;"
      label-position="right"
      label-width="auto"
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules"
    >
      <span>将该广告组下的所有广告规格的监测链接设置为同一个</span>
      <el-form-item :label="LinkPart.name" >
        <el-input v-model="linkValue" placeholder="请输入链接" clearable @blur="IsURLFn(linkValue,LinkPart.id)"></el-input>
        <span class="wrong" v-if="urlIs">*请输入正确的网址</span>
        <span v-else></span>      
      </el-form-item>
    </el-form>

    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm">{{$t('dc:确认')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="info" plain :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";


export default {
name: "setDialog",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    LinkPart: {
      type: Object
    },
    childrenVue:Object,
    mSpecNameList:Array,
    type: String,
    typeOptions: {
      type: Array,
      default: () => []
    },
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },  
  data() {
    return {
      optionsm:{channelList:[{text:'oppo',id:'0'},{text:'vivo',id:'2'},{text:'应用宝',id:'3'},{text:'华为',id:'4'},{text:'小米',id:'5'},{text:'今日头条',id:'6'}]},//选择状态
      loading: false,
      setDialogData: this.visible,
      currentTypeConfig: {},
      formData: {
        name: "CHen",
        email: "asdasd",
        brand: "",
        password: ""
      },
      show:false,//test
      channel:'',
      value:'100',
      rules: {},
      urlIs:false,
      linkValue:'',
      linkUrlList:[],
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted(){
    console.log(this.childrenVue.addCreat)
  },
  methods: {
   
    handlePaste(e){
      var clip = e.clipboardData.getData('Text');
      this.text = clip;
    },
    onConfirm() {
      console.log(this.childrenVue.addCreat)
      let _this = this
      _this.childrenVue.addCreat.forEach(element => {
        _this.childrenVue[_this.LinkPart.id+'List'].push(_this.linkValue)
        _this.linkUrlList.push(_this.linkValue)
      });
      _this.childrenVue.creativeForm[_this.LinkPart.id] = _this.linkUrlList[0] || ''

      this.show= false;
    },
    onCancel() {
      this.show= false;
    },
    IsURLFn (url,type) {
      let _this = this
      var strRegex = /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
      if (!strRegex.test(url)&&url !='') {
          this.urlIs = true
          return false;
      } else {
        this.urlIs = false
        // if(type == 'clickLinkPart'){
        //   _this.clickLinkPartList.push(url)
        // }else if(type == 'LinkPart'){
        //   _this.LinkPartList.push(url)
        // }
        return true;
      }
      
    },    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.el-dialog__header{
  text-align: center;
  color: #fff;
  background-color: #6e7c7c;
  padding: 10px 0;
}
.el-form.el-form--label-right{
  margin: 0 auto;
}
.el-form-item.is-no-asterisk.el-form-item--mini{
  margin: 18px 0;
}
.el-dialog__headerbtn{
  font-size: 30px;
}
 #app .el-dialog__header .el-dialog__headerbtn{
  top:8px
}
.el-dialog__header .el-dialog__headerbtn .el-dialog__close,.el-dialog__header .el-dialog__title,.el-dialog__headerbtn:hover .el-dialog__close:hover{
  color: #fff;
}
.wrong{
  color: red;
  font-size: 12px;
}
</style>

