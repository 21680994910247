<template>
  <div class="apply">
    <el-card class="box-card">
      <div slot="header" class="box-card-header">
        <h4 class="fontS18">Account Application</h4>
        <div>
          <ul class="stepBox">
            <li class="step">
              <span>提交申请</span>
            </li>
            <li class="step1">
              <span>人工审核</span>
            </li>
            <li class="step2">
              <span>邮件信息</span>
            </li>
            <li class="step3">
              <span>完成</span>
            </li>
          </ul>
        </div>
      </div>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        :rules="rules"
        hide-required-asterisk
        label-width="80px"
        label-position="left"
        class="demo-dynamic"
        style="width: 360px;margin:40px auto;margin-left:240px;"
      >
        <el-form-item label="Name" prop="name" class="marginB50">
          <el-input :class="{'redFont':!showName}" :disabled="editable" v-model="dynamicValidateForm.name" maxlength="100" show-word-limit></el-input>
          <span
            v-if="showName"
            class="el-form-item__error fontS12"
          >Please input your name as exampled: MurielLiu</span>
        </el-form-item>
        <el-form-item prop="email" label="E-mail" class="marginB50">
          <el-input v-model="dynamicValidateForm.email" :disabled="editable"  maxlength="100" show-word-limit></el-input>
          <span v-if="showEmail" class="el-form-item__error fontS12">xxxxxxx@xxxx.com</span>
        </el-form-item>
        <el-form-item prop="brand" label="Brand" class="marginB50">
          <el-select v-model="dynamicValidateForm.brand" :disabled="editable"  placeholder style="width:100%;">
            <el-option label="brand1" value="brand1"></el-option>
            <el-option label="brand2" value="brand2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="text-align:right;margin-right:-220px;">
          <el-button type="primary" @click="submitForm('dynamicValidateForm')">Submit</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <applyDialog :visible.sync="showDialog" :title="title" :content="content" @confrom="confrom"></applyDialog>
  </div>
</template>

<script>
import applyDialog from '@/views/dialog/usuallyDialog'
export default {
  name: "Apply",
  components: {
    applyDialog
  },
  data() {
    var nameRoute = (rule, value, callback) => {
      if (value == "") {
        this.showName = false;
        callback(new Error(rule.message ||"Please input your name as exampled: MurielLiu"));
      } else if (!new RegExp("[A-Z]").test(value)) {
        this.showName = false;
        callback(new Error(rule.message || "Please input your name as exampled: MurielLiu"));
      } else if(value.length>100){
        this.showName = false;
        callback(new Error("Only support 100 characters;"));
      } else {
        this.showName = true;
        callback();
      }
    };
    var emailRoute = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (value === "") {
        callback(new Error("Please input valid format;"));
        this.showEmail = false;
      }else if(value.length>100) {
        this.showEmail = false;
        callback(new Error("Only support 100 characters;"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          this.showEmail = true;
          callback();
        } else {
          this.showEmail = false;
          callback(new Error("Please input valid format;"));
        }
      }, 100);
    };
    return {
      dynamicValidateForm: {
        name: "",
        email: "",
        brand: ""
      },
      rules: {
        name: [{ validator: nameRoute, trigger: ["blur", "change"] }],
        email: [{ validator: emailRoute, trigger: "blur" }],
        brand: [
          {
            required: true,
            message: "Brand cannot be empty;",
            trigger: ["blur", "change"]
          }
        ]
      },
      showDialog: true,
      title: 'Submit',
      content: 'Do you confirm to submit this form?',
      showName: true,
      showEmail: true,
      editable: false
    };
  },
  methods: {
    submitForm(formName) {
      this.$router.push({ name: "user.Password" });
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
          this.$router.push({ name: "password" });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    confrom() {
      console.log('confrom')
    }
  }
};
</script>

<style lang="scss">
.apply {
  // card 卡片样式
  .box-card {
    width: 930px;
    margin: 2% auto;
    &-header {
      display: flex;
      justify-content: space-between;
    }
    .el-card__header {
      border: none;
      padding: 30px 40px 0;
    }
    .el-form-item__label {
      font-size: 18px;
    }
    .redFont .el-input__inner,.redFont .el-input__count-inner{
      color: red;
    }
  }
  
  // 提示步骤样式
  .stepBox {
    display: flex;
    justify-content: center;
    font-size: 13px;
    .step {
      width: 90px;
      height: 32px;
      text-align: center;
      color: #fff;
      background-color: $--color-primary;
      position: relative;
      line-height: 32px;
      &::after {
        content: " ";
        height: 0;
        top: 0px;
        right: -14px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid $--color-primary;
        border-bottom: 16px solid transparent;
        z-index: 100;
      }
    }

    .step1,
    .step2 {
      width: 90px;
      height: 32px;
      text-align: center;
      color: #181818;
      background-color: #eee;
      position: relative;
      line-height: 32px;
      margin-left: 4px;
      &::before {
        content: " ";
        height: 0;
        top: 0px;
        left: 0px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid #fff;
        border-bottom: 16px solid transparent;
        z-index: 9;
      }
      &::after {
        content: " ";
        height: 0;
        top: 0px;
        right: -14px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid #eee;
        border-bottom: 16px solid transparent;
        z-index: 99;
      }
    }
    .step3 {
      width: 90px;
      height: 32px;
      text-align: center;
      color: #181818;
      background-color: #eee;
      position: relative;
      line-height: 32px;
      margin-left: 4px;
      &::before {
        content: " ";
        height: 0;
        top: 0px;
        left: 0px;
        position: absolute;
        width: 0;
        display: inline-block;
        border-top: 16px solid transparent;
        border-left: 14px solid #fff;
        border-bottom: 16px solid transparent;
        z-index: 9;
      }
    }
    .active {
      background-color: $--color-primary;
      color: #fff;
      &::after {
        border-left-color: $--color-primary;
      }
    }
  }
 
}
.fontS12 {
  font-size: 12px;
}
.fontS18 {
  font-size: 18px;
}
.marginR20{
  margin-right: 20px; 
}


</style>
