<template>
  <el-dialog
    :visible.sync="show"
    width="600px"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    title="账户今日消耗"
    center
    :show-close = "false">
    <div class="dialogIcon"><img :src='accountCost'></div>
    <el-form
      :inline="false"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules">
      <el-tooltip :content="'Switch value:' + switchValue" placement="top">
        <el-form-item label="消耗速率预警开关" prop="">
          <el-switch
            v-model="switchValue"
            active-color="#48916F"
            inactive-color="#EAEFED"
            active-value="0"
            inactive-value="1">
          </el-switch>
        </el-form-item>
      </el-tooltip>

      <div class="addList">
        <div v-for="(item,i) in timeList" :key="i" class="costWrap">
          <h5 class="addNewBtn">新增{{i+1}}</h5>
          <div>
            <el-form-item label="时间点"  label-width="80px">
              <el-select  clearable  placeholder="选择时间段" v-model="item.checkCostDate"  style="width:100%" @change="changeDayLimit($event,'addTime',i)">
                <el-option
                  v-for="(item1,j) in timeLimitList"
                  :key="j"
                  :label="item1.time"
                  :value="item1.time"
                  :disabled = item1.timeLimitIs>
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="minMax">
            <div class="minMaxList minMaxListF">
              <el-form-item label="消耗最高值" label-width="80px">
                <el-input
                  v-model="item.costTop" 
                  placeholder="请输入限制金额"   
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  clearable>
                </el-input>
              </el-form-item>
            </div>
            <div class="minMaxList">
              <el-form-item label="消耗最低值" prop="minNum" label-width="80px">
                <el-input
                  v-model="item.costLow" 
                  placeholder="请输入限制金额"   
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  clearable>
                </el-input>
              </el-form-item>  
            </div>
          </div>

          <div>
            <!-- <el-button class="delBtn" type="primary" v-if="i>0" @click="deleTimeFn(i,item.checkCostDate)">删除</el-button> -->
            <div class="deleteBtn" v-if="i>0" @click="deleTimeFn(i,item.checkCostDate)">
              <i class="el-icon-error"></i>
            </div>
          </div>

        </div>
        <div>
          <div class="costAdd" @click="addTimeFn"><i class="el-icon-plus"></i>新增</div>
          <!-- <el-button type="primary"  @click="addTimeFn">新增</el-button> -->
        </div>
        
      </div> 

    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <!-- {{$t('dc:取消')}} -->
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import accountCost from '@/assets/img/accountCost.png';
  import { $http } from '@/api/http';
export default {
  
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    nowIs:Boolean,
    pubVue:Object,
    // type: Number,
    // ownerId:Number,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number,
    yulanList:Array,
  },
  data() {
    return {
      accountCost,
      nowIsValue:true,
      timeList:[{'checkCostDate':'','costTop':'','costLow':''}],
      timeLimitList:[{'time':'00:00:00','timeLimitIs':false,'id':'0'},{'time':'01:00:00','timeLimitIs':false,'id':'1'},{'time':'02:00:00','timeLimitIs':false,'id':'2'},{'time':'03:00:00','timeLimitIs':false,'id':'3'},
      {'time':'04:00:00','timeLimitIs':false,'id':'4'},{'time':'05:00:00','timeLimitIs':false,'id':'5'},{'time':'06:00:00','timeLimitIs':false,'id':'6'},{'time':'07:00:00','timeLimitIs':false,'id':'7'},
      {'time':'08:00:00','timeLimitIs':false,'id':'8'},{'time':'09:00:00','timeLimitIs':false,'id':'9'},{'time':'10:00:00','timeLimitIs':false,'id':'10'},{'time':'11:00:00','timeLimitIs':false,'id':'11'},
      {'time':'12:00:00','timeLimitIs':false,'id':'12'},{'time':'13:00:00','timeLimitIs':false,'id':'13'},{'time':'14:00:00','timeLimitIs':false,'id':'14'},{'time':'15:00:00','timeLimitIs':false,'id':'15'},
      {'time':'16:00:00','timeLimitIs':false,'id':'16'},{'time':'17:00:00','timeLimitIs':false,'id':'17'},{'time':'18:00:00','timeLimitIs':false,'id':'18'},{'time':'19:00:00','timeLimitIs':false,'id':'19'},
      {'time':'20:00:00','timeLimitIs':false,'id':'20'},{'time':'21:00:00','timeLimitIs':false,'id':'21'},{'time':'22:00:00','timeLimitIs':false,'id':'22'},{'time':'23:00:00','timeLimitIs':false,'id':'23'}],
      
      formData: {},
      rules: {
        costTop: [
          { required: true, message: '请输入消耗值', trigger: 'blur' },
        ],
        
        
      },
      // titleList:['vivo账户余额','vivo账户今日消耗','vivo账户日预算'],
      // optionsm:{channelList:[{text:'oppo',id:'0'},{text:'vivo',id:'2'},{text:'应用宝',id:'3'},{text:'华为',id:'4'},{text:'小米',id:'5'},{text:'今日头条',id:'6'}]},//选择状态
      optionsm:{},//部门名称
      loading: false,
      show: this.visible,
      currentTypeConfig: {},
      channel:'',
      switchValue:'0',
      setData:{},//回显值
      setPriceCommon:{
        "ownerId": 0,
        "ownerName": "",
        "type": 0,
        "userId": 0,
        "userName": "",
        "deptId": 0,
      },//addAccountBalanceSet公共入参
      accounParams:{
        "moneySwitch": 0,
        "warningMoneyLow": 0,
	      "warningMoneyTop": 0
      },//addAccountBalanceSet入参账户部分
      costParams:{
        "costWarning":{
          "costSwitch": 0,
          "costWarningSetInfoList":[{
            "checkCostDate": 0,
            "costTop": 0, 
          }
                   
          ]
        }
        
      },//addAccountBalanceSet入参消耗部分
      dayParams:{
        "budgetSwitch": 0,
        "dayBudgetLow": 0,
        "dayBudgetTop": 0,
      },//addAccountBalanceSet入参日预算部分
      // setP:[['moneySwitch','warningMoneyTop','warningMoneyLow'],
      //       ['costSwitch','costTop','checkCostDate'],
      //       ['budgetSwitch','dayBudgetTop','dayBudgetLow']],//添加接口的入参名整理
      setP:['costSwitch','costTop','checkCostDate'],//添加接口的入参名整理
      // addOrSet:false,//判断是添加还是修改
      infoId:[],//详情接口的id
      accDayBudget:'',//设置日预算
      dayDisabled:true,//日预算是否可操作
      checked:true,
      upCode:'',
      addCode:'',
      setCode:'',
      choosedTime:[],
      timeEvent:'',
      getIs:false,//今日消耗是否有回显，判断是添加还是修改
      // formType:null,//区分账户余额，消耗，日预算
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  created(){
    this.timeList.push({})
  },
  mounted(){
    console.log(this.timeList)
  },
  methods: {
    addTimeFn(){
      let _this = this
      _this.timeList.push({})
      console.log(this.timeLimitV)
      console.log(this.timeList)
      // // _this.choosedTime.push(_this.timeEvent)
      // _this.choosedTime.forEach(obj=>{
      //   // _this.timeLimitList.forEach((ele,i,arr)=>{
      //     _this.timeLimitList[obj].timeLimitIs = true
      //   // })
      // })
    },
    deleTimeFn(i,time){
      if (i >= 0) {
        this.timeList.splice(i, 1);
        this.choosedTime.splice(i, 1);
        this.timeLimitList.forEach(obj=>{
          if(obj.time == time){
            obj.timeLimitIs = false
          }
        })
        // this.timeLimitList[]
        
        // this.formData[i] = []
      }
    },  
    
    
    changeDayLimit(event,type,index){
      let _this = this,num
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
        //获取对象在数组的索引
          num = _this.timeLimitList.indexOf(_this.timeLimitList.filter(d => d.time ===event)[0])
          _this.choosedTime[index] = num
          _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
             
            }
             _this.choosedTime.forEach(obj=>{
              _this.timeLimitList[obj].timeLimitIs = true
            })
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
    changeDayLimitFn(event,type,index){
      let _this = this
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
          // _this.choosedTime.forEach(obj=>{
          //   _this.timeLimitList[obj].timeLimitIs = true
          // })
          _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
              _this.choosedTime.forEach(obj=>{
                _this.timeLimitList[obj].timeLimitIs = true
              })
            }
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      // _this.showYu()
    },  
    onConfirm(formName) {
      let _this = this,msg 
      _this.setPriceCommon.userId = _this.userContent.userId
      _this.setPriceCommon.userName = _this.userContent.userName
      _this.setPriceCommon.deptId = _this.userContent.deptId
      _this.setPriceCommon.ownerName = _this.userContent.ownerName
      _this.setPriceCommon.ownerId = _this.userContent.ownerId
      _this.setPriceCommon.type = _this.userContent.type
      console.log(_this.timeList)
      let flag = _this.timeList.length!=0
      flag = _this.timeList.every(obj=>{
          if(obj.checkCostDate == ''||obj.checkCostDate == undefined){
            _this.$message({
            showClose: true,
            message: '请选择时间段',
            type: 'error'
          })
            return false
          }else if(obj.costTop == undefined || obj.costTop.toString() == ''){
            _this.$message({
            showClose: true,
            message: '请填写最高值',
            type: 'error'
          })
            return false
          }else if(obj.costLow == undefined || obj.costLow.toString() == ''){
            _this.$message({
            showClose: true,
            message: '请填写最低值',
            type: 'error'
          })
            return false
          }else if(obj.costTop<obj.costLow||obj.costTop==obj.costLow){
            _this.$message({
              showClose: true,
              message: '最低值不能高于最高值',
              type: 'error'
            })
            return false
          }else{
            return true
          }
      })
      if(flag){
        _this.addOrSetFn(_this.setPriceCommon.type,_this.infoId)
      }

          
    },
    onCancel() {
      this.show= false;
      this.timeList=[
        {
          'checkCostDate':'',
          'costTop':'',
          'costLow':''

        }
      ],
      this.pubVue.$refs.multipleTable.clearSelection();
      // this.pubVue.grouplistFn()
      console.log('关闭')
      this.timeLimitList.forEach(obj=>{
        obj.timeLimitIs = false
      })

    },
    //
    addOrSetFn(type){
      let _this = this,params ,params1,costWarning,costWarningSetInfoAddList=[],costWarningSetInfoSetList = []
        _this.timeList.forEach((obj,i)=>{
          if(obj.id!=undefined){
            costWarningSetInfoSetList.push({'checkCostDate' : obj.checkCostDate,'costTop':obj.costTop,'costLow':obj.costLow,'id':obj.id})
          }else{
            costWarningSetInfoAddList.push({'checkCostDate' : obj.checkCostDate,'costTop':obj.costTop,'costLow':obj.costLow,'id':obj.id})
          }
        })
        console.log(params)
        console.log(_this.setPriceCommon)
        console.log(params1)
        if(costWarningSetInfoAddList.length!=0){
          costWarning = {'costWarning':{
            "costSwitch": parseInt(_this.switchValue),
            "costWarningSetInfoList":costWarningSetInfoAddList
          }
        },
          params = Object.assign({},_this.clone(_this.setPriceCommon), costWarning)
          _this.addAccountBalanceSetFn(params)
        }
        if(costWarningSetInfoSetList.length!=0&&_this.getIs){
          // {
          //   "costSwitch": 0,
          //   "costWarningSetInfo": [
          //     {
          //       "checkCostDate": "",
          //       "costTop": 0,
          //       "id": 0
          //     }
          //   ],
          //   "ownerId": "",
          //   "userId": 0,
          //   "userName": "",
          //   "deptId": 0
          // }  
           
           
          costWarning = { 
            "costSwitch": parseInt(_this.switchValue),
            "costWarningSetInfoList":costWarningSetInfoSetList}     
          params = Object.assign({},_this.clone(_this.setPriceCommon), costWarning)
          _this.upAccountBalanceSetFn(params)
        }
        // if(type == 3&&!_this.checked){
        //   _this.setAccDayBudgetFn(Number(!_this.nowIsValue))
        // }
      // }
    },
    
    //最高最低值改变
    salaryChange(num,e){
        this[num] = e.target.value
        if(e.target.value == ''){
          let messageContent = '今日消耗不能为空'
          this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
        console.log(num,e)
    },
    // 获取部门名称列表***后台回显页面先不写
    getNameList(){
      let _this = this
      $http.axios.get("/api/system/dept/list").then(res=> {
        if(res.data.code==200){
          console.log(res)
        }
          

      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //获取消耗回显接口名称：获取账户余额预警设置详情
    setDialogContentFn(ownerIdList,type){
      let _this = this
          _this.timeList = []

      $http.axios.post("/api/vivo/account/v1/getAccountBalanceSetDetail",{'ownerId':ownerIdList,'type':type}).then(res=> {
        if(res.data.code==200){
          if(res.data.data.costWarningSetInfoList.length!=0){
            _this.getIs = true
             _this.timeList = res.data.data.costWarningSetInfoList
            res.data.data.costWarningSetInfoList.forEach((obj,index)=>{
              // _this.timeList.push({'checkCostDate':obj.checkCostDate,'costTop':obj.costTop,'costLow':obj.costLow})
              _this.infoId.push(obj.id)
              _this.timeLimitList.forEach((ele,i)=>{
                if(obj.checkCostDate == ele.time){
                  ele.timeLimitIs = true
                  _this.choosedTime[index] = i
                }
              })
            })
            _this.setData = res.data.data
            // _this.infoId = _this.setData.id
            if(_this.setData.objectSwitch!=null&&_this.setData.objectSwitch==0){
              _this.setData.objectSwitch = 0
            }else{
              _this.setData.objectSwitch = 1
            }
          //  _this.setData.objectSwitch!=null&&_this.setData.objectSwitch==0?_this.setData.objectSwitch = 0:_this.setData.objectSwitch=1
            _this.userContent.switchValue = _this.switchValue = _this.setData.objectSwitch.toString()          
            }else{
            _this.getIs = false
        }
         
        }
        console.log(_this.userContent.switchValue)
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //添加账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)的设置
    addAccountBalanceSetFn(params){
      let _this = this
      $http.axios.post("/api/vivo/account/v1/addAndUpdtateAccountBalanceSet",params).then(res=> {
          if(res.data.code==200){
            _this.pubVue.$refs.multipleTable.clearSelection();
            // _this.pubVue.getAccountPlanListFn()
            // _this.pubVue.adPlanIdList = []
            if(params.type == 3){
              if(_this.checked){
                _this.show= false;
              }
            }else{
              _this.show= false;
            }
            let messageContent =res.data.msg|| '添加成功'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'success'
            })
            _this.onCancel()
          
        }else{
          let messageContent =res.data.msg|| '添加失败'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //修改账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)的设置
    upAccountBalanceSetFn(params){
      let _this = this
      $http.axios.post("/api/vivo/account/v1/updtateAccountCostSet",params).then(res=> {
        if(res.data.code==200){
          // _this.show= false;
          if(params.type == 3){
            if(_this.checked){
              _this.show= false;
            }
          }else{
            _this.show= false;
          }
          let messageContent =res.data.msg|| '修改成功'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'success'
          })
            _this.onCancel()
        }else{
          let messageContent =res.data.msg|| '修改失败'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
    //重置
    resetFormFn(){
      _this.$refs.formData.resetFields();//重置表单
    },
   
    /**
     * 批量更新数据
     */
    batchUpdate() {},
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeDay(event){
      console.log(event)
        this.dayDisabled = event

        if(event){
          this.accDayBudget = ''
        }
    },
  }
};
</script>
<style lang="scss" scoped>
.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
.el-form-item.el-tooltip.is-no-asterisk.el-form-item--mini .el-form-item__content{
  text-align: left!important;
  margin: 0;
}
.el-checkbox.is-checked,.el-checkbox{
  float: left;
}
.el-form-item__content .nowLimit{
  display: inline-block;
  text-align: right;
  width: 150px;
  font-size: 12px;
  margin-right: 10px;
}
.el-form .el-form-item--mini.el-form-item{
  margin-bottom: 8px;
}
.addNewBtn{
  color: #000;
  margin:0;
  color:#48916F;
  font-weight:700;
  margin-bottom:10px;
}
.el-button.el-button--primary.el-button--mini.delBtn{
  float: right;
  margin-right: -60px;
  margin-top: -35px;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
::v-deep .el-dialog{
  margin:0!important;
  margin:0 auto 50px!important;
  top:200px!important;
  transform: none!important;
}
.costWrap{
  position: relative;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  margin:10px 0;
  padding:10px;
  box-sizing: border-box;
}
.costAdd{
  width:100%;
  height:26px;
  line-height:26px;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  color:#48916F;
  font-size:12px;
  text-align:center;
  cursor: pointer;
}
.minMaxList{
  display: inline-block;
  vertical-align: middle;
  width:48%;
}
.minMaxListF{
  margin-right:19px;
}
::v-deep .addList .el-input--mini .el-input__inner{
  /* width:160px;
  height:32px;
  line-height:32px; */
  background:#F5F7F6;
  border:none;
}
.deleteBtn{
  position: absolute;
  font-size:18px;
  top:-13px;
  right:-11px;
  color:rgb(212, 24, 24);
  cursor: pointer;
}
</style>

