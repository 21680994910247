<template>
  <el-dialog
    title="自定义列"
    :visible.sync="columnVisible"
    center
    :show-close = "false"
    width="820px">
    <div class="dialogIcon"><img :src='ZDYLicon1'></div>
    <div class="columnWrap">
      <div class="column_left">
        <template>
          <div class="zdylStyle">
            <span>接口信息</span>
            <el-checkbox :indeterminate="isIndeterminateInfo" class="zdylAll" v-model="checkAllSystem" @change="(val) => handleCheckAllChange(val,'system')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedSystem" @change = handleCheckedCitiesChange>
              <el-checkbox v-for="(item,i) in system" :label="item" :key="i">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdylStyle">
            <span>基础信息</span>
            <el-checkbox :indeterminate="isIndeterminateSystem" class="zdylAll" v-model="checkAllInfo" @change="(val) => handleCheckAllChange(val,'info')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedInfo" @change = handleCheckedCitiesChange>
              <el-checkbox v-for="(item,i) in info" :label="item" :key="i">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdylStyle">
            <span>基础数据</span>
            <el-checkbox :indeterminate="isIndeterminateData" class="zdylAll" v-model="checkAllData" @change="(val) => handleCheckAllChange(val,'data')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedData" @change = handleCheckedCitiesChange>
              <el-checkbox v-for="(item,i) in data" :label="item" :key="i">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdylStyle">
            <span>转化数据</span>
            <el-checkbox :indeterminate="isIndeterminateConversion" class="zdylAll" v-model="checkAllConversion" @change="(val) => handleCheckAllChange(val,'conversion')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedConversion" @change = handleCheckedCitiesChange>
              <el-checkbox v-for="(item,i) in conversion" :label="item" :key="i">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" class="newDialogCancel" @click="columnVisibleFn">取消保存</el-button>
      <el-button type="primary" class="newDialog" @click="generateTemplate">确认保存</el-button>
    </span>
  </el-dialog>  
</template>
<script>
  import ZDYLicon1 from '@/assets/img/ZDYLicon1.png';
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import alertMsgFn from '@/assets/js/alert';
  export default {
    name: 'Daily',
    props:{
       pubVue: {
      type: Object
    },
    typeName:Number
    },
    data() {
      return {
        ZDYLicon1,
        userId:'',
        userName:'',
        deptId:'',
        deptName:'',
        ruleForm:{//查询条件
          channelName:'',
          templateName:'',
          dateChoice:''
        },
        channelOptions:[],//渠道选项
        tableData: [],//时报列表数据
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        batchOperation:'',
        columnVisible:this.visible,//生成模版弹框隐藏显示
        checkedCities: [],//生成模版选择了多少选项
        checkedInfo: [],//
        checkedSystem: [],//
        checkedData: [],//
        checkedConversion: [],//
        newWords:'',//生成新的时报模板字段
        timeParams:{//时报列表入参
          templateType:'',
          page:'',
          pageCount:'',
          channelId:'',
          templateName:'',
          startDate:'',
          endDate:''
        },
        addTemplateParams:{//生成模版入参
          channel:'',
          channelId:'',
          deptId:'',
          deptName:'',
          tempName:'',
          keywords:[],
          tempType:'',
          userId:'',
          userName:''
        },
        delParams:{//删除接口入参
          channel:'',
          userId:'',
          username:'',
          ids:[]
        },
        system:[],
        info:[],
        data:[],
        conversion:[],//点击生成按钮返回的关键字段
        infoList:[],//选中的基础信息
        dataList:[],//选中的基础数据
        conversionList:[],//选中的转化数据
        deleteWords:false,//删除手动添加字段的按钮
        addWordsParams:{//添加自定义字段入参
          name:'',
          userId:'',
          userName:''
        },
        deleteParams:{//删除手动添加关键字的入参
          ids:[],
          userId:'',
          userName:''
        },
        newTempName:'',//模版名称
        dialogVisible:false,
        isIndeterminateInfo:false,
        checkAllInfo:false,
        isIndeterminateSystem:false,
        checkAllSystem:false,
        isIndeterminateData:false,
        checkAllData:false,
        isIndeterminateConversion:false,
        checkAllConversion:false,
        titleListAll:{10:[
          // {name:'广告计划名称',prop:'planName'},
              {name:'投放开关',prop:'status2'},// 0开启 1关闭
              {name:'广告计划ID',prop:'planId'},
              {name:'计划日限额',prop:'dayBudget'},
              // {name:'已设置启停',prop:'startSwitch'},//0是，1否
              {name:'计划状态',prop:'showStatusName'},//启动中-0 暂停中-1 广告组暂停-2 计划暂停-3
              // {name:'预警状态',prop:'warningSwitch'},//null未设置 0开启 1关闭
              // {name:'预警日预算',prop:'dayWarning'}
            ],
              // 20:[{name:'广告组名称',prop:'adGroupName'},
              //   {name:'投放开关',prop:'status2'},// 0开启 1关闭
              //   {name:'广告组ID',prop:'adGroupId'},
              //   {name:'已设置调价',prop:'priceSwitch'},
              //   {name:'已设置启停',prop:'startSwitch'},//0是，1否
              //   {name:'预警状态',prop:'warningSwitch'},
              //   {name:'预警出价',prop:'warningPrice'},
              //   {name:'广告组状态',prop:'showStatusName'},
              //   {name:'基础出价',prop:'price'}],
              //   30:[{name:'广告名称',prop:'adName'},
              //   {name:'投放开关',prop:'status2'},
              //   {name:'广告ID',prop:'adId'},
              //   {name:'广告组状态',prop:'showStatusName'},
              //   {name:'预警状态',prop:'warningSwitch'}] 
              }
  
  
      };
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptName = this.$sessionStorage.getItem(config.DEPTNAME) 
      this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
      this.getuserChannel()//渠道接口
      console.log(666)
    },
    methods: {
      //弱提示
       alertMsgFn(msg,type){
        this.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
      columnVisibleFn(){
        let _this = this
        _this.columnVisible = false
      },
      handleCheckAllChange(val,type) {
        console.log(this.checkedCities,this.info)
        let _this = this, infoList = []
        if(type == 'info'){
          _this.checkedInfo = val ? _this.info : [];
          _this.isIndeterminateInfo = false;
        }else if(type == 'data'){
          _this.checkedData = val ? _this.data : [];
          _this.isIndeterminateData = false;

        }else if(type == 'conversion'){
          _this.checkedConversion = val ? _this.conversion : [];
          _this.isIndeterminateConversion = false;

        }else if(type == 'system'){
          _this.checkedSystem = val ? _this.system : [];
          _this.isIndeterminateSystem = false;

        }
        _this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkedSystem)

      },
      handleCheckedCitiesChange(value) {
        console.log(value)
        let checkedCountInfo = 0,checkedCountData = 0,checkedCountConversion = 0,checkedCountSystem = 0;
        let _this = this, infoList = [],type = ''
        // type = value[0].type
        if(value.length!=0){
          type = value[0].type
          if(type == 'info'){
            _this.checkedInfo = value 
            checkedCountInfo = value.length
            this.checkAllInfo = checkedCountInfo === this.info.length;
            this.isIndeterminateInfo = checkedCountInfo > 0 && checkedCountInfo < this.info.length;
          }else if(type == 'data'){
            _this.checkedData = value
            checkedCountData = value.length
            this.checkAllData = checkedCountData === this.data.length;
            this.isIndeterminateData = checkedCountData > 0 && checkedCountData < this.data.length;

          }else if(type == 'conversion'){
            _this.checkedConversion = value 
            checkedCountConversion = value.length;
            this.checkAllConversion = checkedCountConversion === this.conversion.length;
            this.isIndeterminateConversion = checkedCountConversion > 0 && checkedCountConversion < this.conversion.length;
          }else if(type == 'system'){
            // _this.checkeSystem = value
            _this.checkedSystem = value
            checkedCountSystem = value.length
            this.checkAllSystem = checkedCountSystem === this.data.length;
            this.isIndeterminateSystem = checkedCountSystem > 0 && checkedCountSystem < this.data.length;

          }
          // this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkAllSystem)
          this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkedSystem)
        
          console.log(this.checkAllData,this.isIndeterminateData,this.checkAllInfo,this.isIndeterminateInfo,this.checkAllConversion,this.isIndeterminateConversion)
        }
      },
      
  
      //生成时报模板弹框显示
      generateTimes(num){
        this.columnVisible = true

        this.allWords(num)
      },
  
      //user的channel渠道接口
      getuserChannel(){
        let that = this
        that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
        if(response.data.data!=undefined&&response.data.data.length!=0){
          that.channelOptions = response.data.data
          that.timeParams.channelId = response.data.data[0].id
          that.delParams.channel = response.data.data[0].id
          that.addTemplateParams.channel = response.data.data[0].name
          that.addTemplateParams.channelId = response.data.data[0].id
          }
        })
      },
  
      //查询全部关键字接口
      allWords(type){
        let that = this,params = {},checkedList = [],checkedCities = [],titleList = [],titleList1 = [],checkedInfo = [],checkedData = [],checkedConversion = [],checkedSystem = []
            
        params = {'type': type,'userId':this.userId}
        $http.axios.post("/api/advertisement/keyword/list",params).then(res=> {
          if(res.data.code == 200){
            that.conversion = res.data.content.conversion
            that.info = res.data.content.info
            that.data = res.data.content.data
            that.system = res.data.content.system
            checkedList = that.info.concat(that.data,that.conversion,that.system)
            checkedList.forEach((obj,i,arr)=>{
              if(obj.isSelect == 0){
                // that.getzdyData.forEach(function(v,i,arr){
                  titleList.push({'name':obj.name,'prop':obj.cspFieldName})
                if(obj.type == 'info'){
                  checkedInfo.push(obj)
                }else if(obj.type == 'data'){
                  checkedData.push(obj)
                }else if(obj.type == 'conversion'){
                  checkedConversion.push(obj)
                }else if(obj.type == 'system'){
                  checkedSystem.push(obj)
                }
                checkedCities.push(obj)
              }
            })
            
            that.checkedCities = checkedCities
            that.pubVue.titleList = that.titleListAll[that.typeName].concat(titleList)
            // titleList.forEach((obj,i)=>{
            //   that.pubVue.titleList.forEach((v,i,arr)=>{
            //     let bool = arr.indexOf(v.name,i+1)
            //     if(bool === -1){
            //       titleList1.push(v)
            //     }
            //   // })
              
            // })
            that.checkedInfo = checkedInfo
            that.checkedData = checkedData
            that.checkedConversion = checkedConversion
            that.checkedSystem = checkedSystem

            
            if(that.checkedInfo.length>0){
              that.isIndeterminateInfo = true
              if(that.checkedInfo.length == that.info.length){
                that.checkAllInfo = true
                that.isIndeterminateInfo = true
              }
            }else{
              that.isIndeterminateInfo = false
              that.checkAllInfo = false
            }
            if(that.checkedData.length>0){
              that.isIndeterminateData = true
               if(that.checkedData.length == that.data.length){
                that.checkAllData = true
                that.isIndeterminateData = true
              }
            }else{
              that.checkAllData = false
              that.isIndeterminateData = false
            }
            if(that.checkedConversion.length>0){
              that.isIndeterminateConversion = true
              if(that.checkedConversion.length == that.conversion.length){
                that.checkAllConversion = true
                that.isIndeterminateConversion = true
              }
            }else{
              that.checkAllConversion = false
              that.isIndeterminateConversion = false
            }
            if(that.checkedSystem.length>0){
              that.isIndeterminateSystem = true
              if(that.checkedSystem.length == that.system.length){
                that.checkAllSystem = true
                that.isIndeterminateSystem = true
              }
            }else{
              that.checkAllSystem = false
              that.isIndeterminateSystem = false
            }
            
           
            
            console.log(that.checkAllData,that.isIndeterminateData)
          }  else {
            that.alertMsgFn(res.data.msg,'error')
          }
        })
      },
  
      //清空自定义列弹框选中项
      clearWrap(){
        this.checkedCities = []
        this.checkedInfo = []
        this.checkedSystem = []
        this.checkedData = []
        this.checkedConversion = []
        this.isIndeterminateInfo=false
        this.checkAllInfo=false
        this.isIndeterminateSystem = false
        this.checkAllSystem = false
        this.isIndeterminateData=false
        this.checkAllData=false
        this.isIndeterminateConversion=false
        this.checkAllConversion=false
      },
  
      //自定义弹框右侧选中的项-----删除
      deletClumnChecked(i,val){
        let _this = this
        if(val.type=='info'){
          _this.checkedInfo.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedInfo.splice(j,1)
              if(_this.checkedInfo.length == 0){
                _this.isIndeterminateInfo=false
                _this.checkAllInfo=false                
              }
            }
          })
        }else if(val.type=='system'){
          _this.checkedSystem.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedSystem.splice(j,1)
              if(_this.checkedSystem.length == 0){
                _this.isIndeterminateSystem=false
                _this.checkAllSystem=false                
              }
            }
          })
        }else if(val.type=='data'){
          _this.checkedData.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedData.splice(j,1)
              if(_this.checkedData.length == 0){
                _this.isIndeterminateData=false
                _this.checkAllData=false
              }
            }
          })        
          }else if(val.type=='conversion'){
            _this.checkedConversion.forEach((obj,j)=>{
            if(obj.name == val.name){
                _this.checkedConversion.splice(j,1)
                if(_this.checkedConversion.length == 0){
                  this.isIndeterminateConversion=false
                  this.checkAllConversion=false
                }
            }
          })
        }
        _this.checkedCities.splice(i,1);

      },
      
  
      
  
      //生成模版
      generateTemplate(){
        let that = this,params = {},titleList = []
        
        let allwordsArr = []
        if(that.checkedCities.length == 0){
          if(that.typeName == 10){
              that.pubVue.titleList=[
                // {name:'广告计划名称',prop:'planName'},
              {name:'投放开关',prop:'status2'},// 0开启 1关闭
              {name:'广告计划ID',prop:'planId'},
              {name:'计划日限额',prop:'dayBudget'},
              // {name:'已设置启停',prop:'startSwitch'},//0是，1否
              {name:'计划状态',prop:'showStatusName'},//启动中-0 暂停中-1 广告组暂停-2 计划暂停-3
              // {name:'预警状态',prop:'warningSwitch'},//null未设置 0开启 1关闭
              // {name:'预警日预算',prop:'dayWarning'}
            ]
            }
            else if(that.typeName == 20){
              that.pubVue.titleList=[{name:'广告组名称',prop:'adGroupName'},
                {name:'投放开关',prop:'status2'},// 0开启 1关闭
                {name:'广告组ID',prop:'adGroupId'},
                {name:'已设置调价',prop:'priceSwitch'},
                {name:'已设置启停',prop:'startSwitch'},//0是，1否
                {name:'预警状态',prop:'warningSwitch'},
                {name:'预警出价',prop:'warningPrice'},
                {name:'广告组状态',prop:'showStatusName'},
                {name:'基础出价',prop:'price'}]           
            }else if(that.typeName == 30){
              that.pubVue.titleList=[{name:'广告名称',prop:'adName'},
                {name:'投放开关',prop:'status2'},
                {name:'广告ID',prop:'adId'},
                {name:'广告组状态',prop:'showStatusName'},
                {name:'预警状态',prop:'warningSwitch'}]          
              }
            }
            else{
              that.checkedCities.forEach(function(e,i,arr){
                //去重
                // that.getzdyData.forEach(function(v,i,arr){
                let bool = arr.indexOf(e,i+1)
                if(bool === -1){
                  
                titleList.push({name:e.name,prop:e.cspFieldName})
                allwordsArr.push(e.id)
                }
              }) 
              that.pubVue.titleList = that.titleListAll[that.typeName].concat(titleList)

            }
        
  
          // params.keys = that.newWords
          params.keys = allwordsArr
          params.userId = that.userId
          params.type = that.typeName
          // "keys": [],
          // "type": "",类型 10计划 20广告组 30广告
          // "userId": ""
          $http.axios.post("/api/advertisement/keyword/add",params).then(res=> {          
            if(res.data.code == 200){
            that.columnVisible = false
            if(that.typeName == 10){
              that.pubVue.getAccountPlanListFn()
            }else if(that.typeName == 20){
              that.pubVue.grouplistFn()()
            }else if(that.typeName == 30){
              that.pubVue.v3groupListFn()
            }
          }  else {
            that.alertMsgFn(res.data.msg,'error')
          }
        })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .daily_library{
      margin-bottom:20px;
    }
    .daily_library span{
      padding-right:20px;
      font-size:14px;
      cursor: pointer;
    }
    .daily_library .timesTab{
      font-size:16px;
      font-weight:700;
      color:#6e7c7c;
    }
    .pageWrap{
      clear:both;
      margin-top:15px;
    }
    .pageBatch{
      float:left;
    }
    .paging{
      float:right;
    }
    .columnWrap{
      font-size:0;
    }
    .column_left,.column_right{
      display:inline-block;
      vertical-align:top;
      font-size:14px;
      box-sizing:border-box;
    }
    .column_right ul,.column_right li{
      margin:0;
      padding:0;
      list-style:none;
    }
    .column_right ul li i{
      font-weight:700;
      cursor:pointer;
    }
    .column_right .el-icon-download{
      transform: rotate(180deg);
      padding-right:10px;
    }
    .column_right .el-icon-remove-outline{
      color:brown;
      padding-left:5px;
    }
    .column_clear_showNum i{
      font-style:normal;
      color:#6e7c7c;
      padding:0 3px;
    }
    .column_right_clear{
      border:1px solid #ddd;
      padding:0 5px;
      border-radius:5px;
      margin-bottom:5px;
    }
    .column_clear{
      color:rgba(36, 132, 168, 1);
      float:right;
      cursor: pointer;
    }
    .newWordsBorn{
      margin-bottom:20px;
    }
    .newWordsBorn .el-input{
      width:300px;
      margin-left:10px;
    }
    .handAdd{
      border-top:1px solid #ddd;
      padding-bottom:20px;
      padding-top:20px;
      margin-top:20px;
    }
    .handAddwords{
      margin-top:20px;
      border-top:1px solid #ddd;
      /* padding:20px 0; */
    }
    .handAddwords h3{
      font-size:12px;
    }
    .timeCondition{
      display:inline-block;
      margin:0 3px;
    }
    .newWordsDel{
      float:right;
    }
    .tempName{
      margin-bottom:20px;
      padding-bottom:20px;
      border-bottom:1px solid #ddd;
    }
    .tempName label{
      display:inline-block;
      vertical-align:middle;
      margin-right:10px;
    }
    .tempnameWrite{
      width:200px;
    }
    .byted-weektime .calendar .calendar-table tr,.byted-weektime .calendar .calendar-table td,.byted-weektime .calendar .calendar-table th{
      min-width:3px;
    }
    /*新版*/
    .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    .zdylStyle{
      margin-bottom:10px;
      position: relative;
    }
    .zdylAll{
      position: absolute;
      top:0;
      right:20px;
    }
    .zdylStyle span{
      display: block;
      font-weight: 500;
      padding:10px 20px;
      box-sizing: border-box;
      background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
      border-radius: 10px 0px 0px 0px;
    }
    .zdylChecked{
      margin-top:10px;
      box-sizing: border-box;
      padding-left:10px;
    }
    .zdylChecked .el-checkbox{
      width:20%;
      margin:3px 0;
    }
  </style>
  