<template>
  <div class="directionalPart">
    <span class="title fW">定向</span>
    <el-form-item label="定向设置">
      <!-- <el-radio-group v-model="sizeForm.resourceFlows" @change="changeResource">
        <el-radio-button border v-for="(item,index) in directionalListPart"
        :key="index"
        :label="item"
        :name="item.name">
        {{item.label}}
        </el-radio-button>
      </el-radio-group> -->
      <el-radio-group v-model="resourceFlows" @change="changeResource">
        <el-radio-button label="1">选择已有定向包</el-radio-button>
        <el-radio-button label="2">新建定向</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <div class="creatNewDir choosedDir" v-if="resourceTwo">
      <el-form-item label="选择定向包" >
        <el-select v-model="sizeForm.choosedDirRegion" v-if="resourceTwohaha" placeholder="请选择已有定向包" @change="resourceTwoChange">
          <el-option
            v-for="(item,i) in choosedDirRegionOption"
            :key="i"
            :label="item.targetName"
            :value="item.targetId">
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="creatNewDir" v-if="resourceOne">
      <div v-if="renkou">
        <span class="title fW">定向设置-自定义人群</span>
        <el-form-item label="人群包定向">
          <el-select v-model="sizeForm.directe" value-key="targetName" placeholder="请选择" clearable @change="peopleDirection">
            <el-option
              v-for="(item,i) in directeOption"
              :key="i"
              :label="item.targetName"
              :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人群包排除">
          <el-select v-model="sizeForm.feDirecte" value-key="targetName" placeholder="请选择" clearable @change="peopleDirectionPc">
            <el-option
              v-for="(item,i) in directeOption1"
              :key="i"
              :label="item.targetName"
              :value="item"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div>
        <span class="title fW">定向设置-人口属性</span>
        <el-form-item label="地域">
          <el-radio-group v-model="sizeForm.address" @change="changeAddress">
            <el-radio-button border v-for="(item,index) in localListPart"
            :key="index"
            :label="item"
            :name="item.name">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
          <!-- 按照城市 -->
          <div class="tree_transfer" v-if="treeShow">
            <div style="margin-bottom:15px;">
              <el-radio-group v-model="addressRadio" @change="changeAddressType">
                <el-radio label="1">当前地（当前在该地区的人）</el-radio>
                <el-radio label="2">常驻所在地（最近15天住在该地区的人）</el-radio>
              </el-radio-group>
            </div>
            <div>
              <el-cascader
                placeholder="请输入城市名称"
                :options="placeData"
                :props="props"
                @change="changeCity"
                filterable>
              </el-cascader>
            </div>
          </div>   
        </el-form-item>
        <el-form-item label="性别" v-if="renkou">
          <el-radio-group v-model="sizeForm.sex" @change="changeSexes">
            <el-radio-button border v-for="(item,index) in sexListPart"
            :key="index"
            :label="item"
            :name="item.name">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="年龄" v-if="renkou">
          <el-radio-group v-model="sizeForm.ages" @change="changeAge">
            <el-radio-button border v-for="(item,index) in ageListPart"
            :key="index"
            :label="item"
            :name="item.name">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
          <div style="margin-top: 20px" v-if="ageShow">
            <el-checkbox-group v-model="sizeForm.checkboxGroupYear" @change="checkedAge">
              <el-checkbox-button v-for="(item,i) in directes.yearOptions" :key="i" :label="item.value">{{item.label}}</el-checkbox-button>
            </el-checkbox-group>            
          </div>
        </el-form-item>
      </div>
      <div v-if="renkou">
        <span class="title fW">定向设置-设备定向</span>
        <el-form-item label="网络">
          <el-radio-group v-model="sizeForm.network" @change="changeNet">
            <el-radio-button border v-for="(item,index) in networkListPart"
            :key="index"
            :label="item"
            :name="item.name">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
          <div style="margin-top: 20px" v-if="netShow">
            <el-checkbox-group v-model="sizeForm.checkboxGroupNetwork" size="mini" @change="changeNetType">
              <el-checkbox-button v-for="(item,i) in directes.netOptions" :key="i" :label="item.value">{{item.label}}</el-checkbox-button>
            </el-checkbox-group>
          </div>        
        </el-form-item>
        <el-form-item label="机型价格">
          <el-radio-group v-model="sizeForm.modelPrice" @change="changePrice">
            <el-radio-button border v-for="(item,index) in modelPriceListPart"
            :key="index"
            :label="item"
            :name="item.name">
              {{item.label}}
            </el-radio-button>
          </el-radio-group>
          <div style="margin-top: 20px" v-if="priceShow">
            <el-checkbox-group v-model="sizeForm.checkboxGroupPrice" size="mini" @change="sjPrice">
              <el-checkbox-button v-for="(item,i) in directes.priceOptions" :key="i" :label="item.value">{{item.label}}</el-checkbox-button>
            </el-checkbox-group>
          </div>        
        </el-form-item>          
      </div>
      <div>
        <span class="title fW">定向设置-用户行为</span>
        <el-form-item label="App安装">
          <el-radio-group v-model="sizeForm.appInstall" @change="changeApp">
            <div  v-if="one">
              <el-radio-button border v-for="(item,index) in appInstallListPart"
              :key="index"
              :label="item"
            
              :name="item.name">
                {{item.label}}
              </el-radio-button>
            </div>
            <div v-if="two">
              <el-radio-button border v-for="(item,index) in appInstallListPart1"
              :key="index"
              :label="item"
              
              :name="item.name">
                {{item.label}}
              </el-radio-button>
            </div>
            
          </el-radio-group>
          <div class="appTransfer" v-if="allTransferShow">
            <el-transfer
              filterable
              ref="transferCom"
              filter-placeholder="搜索名称"
              v-model="appInstallValue"
              :data="appInstallData"
              class="newtransfer"
              v-if="appTransferShow"
              @keyup.enter.native="enterName"
              @change="getObject1">
              <span slot-scope="{ option }">{{ option.label }}</span>
            </el-transfer>
            <el-transfer
              filterable
              filter-placeholder="搜索名称"
              v-model="appInstallValue"
              ref="transferCom1"
              @keyup.enter.native="enterName1"
              :data="appInstallData1"
              class="newtransfer"
              v-if="appTransferShow1"
              @change="getObject2">
              <span slot-scope="{ option }">{{ option.label }}</span>
            </el-transfer>
            <el-transfer
              filterable
              filter-placeholder="搜索名称"
              v-model="appInstallValue"
              :data="appInstallData2"
              class="newtransfer"
              ref="transferCom2"
              @keyup.enter.native="enterName2"
              v-if="appTransferShow2"
              @change="getObject3">
              <span slot-scope="{ option }">{{ option.label }}</span>
            </el-transfer>
          </div>
        </el-form-item>
        <el-form-item label="用户活跃" v-if="renkou">
          <el-radio-group v-model="sizeForm.userActive" @change="changeUserHy">
            <el-radio-button border v-for="(item,index) in userActiveListPart"
            :key="index"
            :label="item"
            :name="item.name">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
          <div class="appTransfer appTransferUser" v-if="transferAppShow">
            <div class="radioActiveType">
              <el-radio-group v-model="activeRadio" @change="activeRadioChange">
                <el-radio label="1" >
                  <span>距离今天</span>
                  <el-input v-model="radioActiveDay" style="width:100px;margin:0 10px;"></el-input>
                  <span>天内，该APP的活跃用户</span>
                </el-radio>
                <el-radio label="2" style="margin-top:10px;">
                  <span>距离今天</span>
                  <el-input v-model="radioUnActiveDay" style="width:100px;margin:0 10px;"></el-input>
                  <span>天内，该APP的非活跃用户</span>
                </el-radio>
              </el-radio-group>
              <el-transfer
                filterable
                ref="transferComApp"
                filter-placeholder="搜索名称"
                v-model="appInstallValueApp"
                :data="appInstallDataApp"
                class="newtransfer"
                v-if="acRadioShow"
                @keyup.enter.native="enterNameApp"
                @change="getObjectApp">
                <span slot-scope="{ option }">{{ option.label }}</span>
              </el-transfer>
              <el-transfer
                filterable
                ref="untransferComApp"
                filter-placeholder="搜索名称"
                v-model="unappInstallValueApp"
                :data="unappInstallDataApp"
                class="newtransfer"
                v-if="unacRadioShow"
                @keyup.enter.native="unenterNameApp"
                @change="ungetObjectApp">
                <span slot-scope="{ option }">{{ option.label }}</span>
              </el-transfer>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="用户行为兴趣" v-if="renkou">
          <el-radio-group v-model="sizeForm.userActiveInterest"  @change="changeUserActive">
            <el-radio-button border v-for="(item,index) in userActiinterestListPart"
            :key="index"
            :label="item"
            :name="item.name">
            {{item.label}}
            </el-radio-button>
          </el-radio-group>
          <!-- <treePart></treePart> -->
          <ul class="userActive" v-if="userActiveShow">
            <li>
              <span>行为兴趣</span>
              <b>匹配在APP、资讯、搜索、广告等场景上有特定行为的用户</b>
              <strong @click="openInterest">点击展开</strong>
            </li>
            <div class="tree_transfer userActivelist" v-if="activeListShow1">
              <el-cascader
                placeholder="搜索"
                ref="interestCascader"
                :options="interestingData1"
                :props="interprops"
                @change="changeinteresting1"
                filterable>
              </el-cascader>
            </div>
            <li>
              <span>行业精选</span>
              <b>寻找对某类产品或服务有意向转化的用户</b>
              <strong @click="openIndustry">点击展开</strong>
            </li>
            <div class="tree_transfer userActivelist" v-if="activeListShow2">
              <el-cascader
              placeholder="搜索"
              :options="interestingData2"
              :props="interprops"
              @change="changeinteresting2"
              filterable>
            </el-cascader>
          </div>
          </ul>
        </el-form-item>           
      </div>
      <!-- 应用推广 -->
      <div>
        <span class="title fW" v-if="bxqk">定向设置-用户转化</span>
        <div v-if="bxqk">
          <el-form-item label="屏蔽已转化用户">
            <el-radio-group v-model="sizeForm.shieldChange" @change="changePingbi">
              <el-radio-button border v-for="(item,index) in shieldChangeListPart"
              :key="index"
              :label="item"
              :name="item.name">
              {{item.label}}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>
        <el-form-item>
          <el-checkbox v-model="sizeForm.shieldChecked" @change="changeChecked">另存为定向包</el-checkbox>
        </el-form-item>
      </div>
      <div v-if="deriectNameShow">
        <span class="title fW">定向设置-定向包名称</span>
        <el-form-item label="定向包名称" prop="deriectName">
          <el-input v-model="sizeForm.deriectName"></el-input>
        </el-form-item>
      </div>
    </div>
    <!-- <div class="creatNewDir choosedDir" v-if="resourceTwo">
      <el-form-item label="选择定向包">
        <el-select v-model="sizeForm.choosedDirRegion" v-if="resourceTwohaha" placeholder="请选择已有定向包" @change="resourceTwoChange">
          <el-option
            v-for="(item,i) in choosedDirRegionOption"
            :key="i"
            :label="item.targetName"
            :value="item.targetId">
          </el-option>
        </el-select>
      </el-form-item>
    </div> -->
    <!-- <el-button type="primary" @click="submitDirection">保存</el-button> -->
  </div>
</template>
<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

  const directionalOptions = {yearOptions:[{label:'<18',value:1},{label:'18-24',value:2},{label:'25-29',value:3},{label:'30-34',value:4},{label:'35~49',value:5},{label:'>=50',value:6}],
                      netOptions:[{label:'WIFI',value:'WIFI'},{label:'2G',value:'2G'},{label:'3G',value:'3G'},{label:'4G',value:'4G'},{label:'5G',value:'5G'}],
                      priceOptions:[{label:'<1000',value:1},{label:'1000-1999',value:2},{label:'2000-2999',value:3},{label:'>3000',value:4}]};
  export default {
  props:{
    pubVue:Object,
    extensionFlow :Number,
    flowScene :Number,
    extensionType :Number,
    sizeForm:{
      type:Object,
    }
  },  
  
  data() {
    return {
      directionalListPart:[
      {label:'选择已有定向包',name:'chooseDirectional',value:'1'},
      {label:'新建定向',name:'newDirectional',value:'2'}
                          ],//定向设置
      localListPart:[{label:'不限',name:'localUnlimited',value:'999'},
                          {label:'按城市',name:'cityList',value:'1'}],//地域
      localTypePart:[{label:'当前地（当前在该地区的人）',value:'1'},
                          {label:'常驻所在地（最近15天住在该地区的人）',value:'2'}],//常驻地
      sexListPart:[{label:'不限',name:'localUnlimited1',value:'999'},
                          {label:'女',name:'female',value:'1'},
                          {label:'男',name:'man',value:'2'}],//性别
      ageListPart:[{label:'不限',name:'localUnlimited2',value:'999'},
                          {label:'按年龄',name:'ageList',value:'0'}],//年龄
      networkListPart:[{label:'不限',name:'localUnlimited3',value:'999'},
                          {label:'按网络',name:'networkList',value:'0'}],//网络
      modelPriceListPart:[{label:'不限',name:'localUnlimited4',value:'999'},
                          {label:'按价格',name:'modelPriceList',value:'0'}],//机型价格
      appInstallListPart:[{label:'不限',name:'localUnlimited5',value:'999'},
                          {label:'已安装',name:'installedList',value:'1'},
                          {label:'当前未安装',name:'unInstall',value:'2'},
                          {label:'历史未安装',name:'historyUnInstall',value:'3'}],//APP安装
      appInstallListPart1:[{label:'不限',name:'localUnlimited5',value:'999'},
                          {label:'已安装',name:'installedList',value:'1'},
                          ],//APP安装
      userActiveListPart:[{label:'不限',name:'localUnlimited6',value:'999'},
                          {label:'按APP',name:'userActiveList',value:'1'}],//用户活跃
      // userActivechosePart:[{label:'距离今天',value:'1'},
      //                     {label:'按APP',value:'2'}],//用户活跃-按app天数
      userActiinterestListPart:[{label:'不限',name:'localUnlimited7',value:'999'},
                          {label:'自定义',name:'userActiveList',value:'1'},
                          {label:'系统推荐',name:'SystemRecomList',value:'2'}],//用户行为兴趣  
      shieldChangeListPart:[{label:'不限',name:'localUnlimited8',value:'999'},
                          {label:'广告',name:'shieldAdList',value:'1'},
                          {label:'广告组',name:'shieldAdTeamList',value:'2'},
                          {label:'广告计划',name:'shieldAdPlanList',value:'3'},
                          {label:'广告主',name:'shieldHostList',value:'4'}],//屏蔽已转化用户  
                          // {label:'不限',name:'localUnlimited8',value:'999'},//楚莨说没有不限
      // data: generateData(),
      boxCard:false,//选择定向包
      directes: directionalOptions,   
      resourceOne:false,
      resourceTwo:true,
      choosedDirRegionOption:[],//已有定向包数据
      // extensionType:'',
      ownerId:'',
      resourceDetail:{},//定向详情
      directeOption:[],//新建定向的人群包定向
      directeOption1:[],//新建定向的人群包排除
      ageShow:false,//按年龄的展示
      netShow:false,//按网络展示
      priceShow:false,//按照价格展示
      treeShow:false,//按照城市
      appInstallValue:[],//app安装
      appInstallValue1:[],//app安装
      appInstallValue2:[],//app安装
      appInstallData:[],//app安装，穿梭
      appInstallData1:[],//app安装，穿梭
      appInstallData2:[],//app安装，穿梭
      appTransferShow:false,//app安装的穿梭框显示问题
      appTransferShow1:false,//app安装的穿梭框显示问题
      appTransferShow2:false,//app安装的穿梭框显示问题
      allTransferShow:false,//app安装的大div
      allAppInstallData:[],
      transferAppShow:false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      placeData:[],
      placeDataList:[],
      filterText:'',
      props: { 
        multiple: true ,
        label:'name',
        value:'code',
        children:'children'
      },
      interprops:{
        multiple: true ,
        label:'name',
        value:'id',
        children:'children',
        checkStrictly: true 
      },
      userActiveShow:false,//用户兴趣行为-自定义
      activeListShow1:false,//用户兴趣行为-自定义-行为兴趣
      activeListShow2:false,//用户兴趣行为-自定义-行业精选
      interestingData1:[],
      interestingData2:[],
      appName:'',
      appInstallValueApp:[],
      unappInstallValueApp:[],
      appInstallDataApp:[],
      unappInstallDataApp:[],
      submitDparams:{
        accountId: '',
        age: '',
        appActive: '',
        appActiveDays: '',
        // appCatActive: '',
        appNotActive: '',
        appNotActiveDays: '',
        audience: '',
        // audienceCount: '',
        // imeiType: '',
        immutable: '',
        installAppList: '',
        interestTags: '',
        // mobileModel: '',
        mobilePrice: '',
        network: '',
        neverInstallAppList: '',
        notInstallAppList: '',
        oppAudienceId: '',
        permanentRegion: '',
        // ptargetId: '',
        region: '',
        sex: '',
        // targetDesc: '',
        targetName: '',
        type: '',
        filterConvertedAd:'',
        targetDesc:'',
      },
      copyVisible:false,//复制已有定向包
      copyDirRegion:'',//复制已有定向包的v-model
      addressType:'',//按城市--常驻地和当前地
      userActivechose:'',
      activeRadio: '',//用户活跃，按照app中的距离今天的天数（活跃非活跃）
      radioUnActiveDay:'',
      radioActiveDay:'',
      deriectNameShow:false,
      acRadioShow:true,
      unacRadioShow:false,
      arrinteresting1:[],
      arrinteresting2:[],
      cityObj:[],
      deriectId:'',
      addressRadio:'',
      fzzj:{},
      // deriectName:'',
      xchange:'',
      xtargetId:'',
      bxqk:true,//定向设置-用户转化的现实问题
      renkou:true,
      one:true,
      two:false,
      resourceTwohaha:true,
      resourceFlows:'',//已有定向和新建定向的切换
    }
  },
 
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted(){
    this.ownerId = this.$route.query.ownerId
    console.log("流量场景"+this.flowScene)
    console.log("推广流量"+this.extensionFlow)
    console.log("推广目的"+this.extensionType)
    if(this.flowScene == 5){
      this.resourceTwohaha = false
    }
    this.resourceFlows = '1'
    this.getPersonal()
    // this.submitDparams.region = '999'
    // this.submitDparams.permanentRegion = '999'
    // this.submitDparams.sex = '999'
    // this.submitDparams.age = '999'
    // this.submitDparams.network = '999'
    // this.submitDparams.mobilePrice = '999'
    // this.submitDparams.installAppList = '999'
    // this.submitDparams.neverInstallAppList = '999'
    // this.submitDparams.notInstallAppList = '999'
    // this.submitDparams.appActive = '999'
    // this.submitDparams.appNotActive = '999'
    // this.submitDparams.interestTags = '999'
    // this.submitDparams.filterConvertedAd = '999'
    this.getResourceList()
    // this.kindOfStyle()
    // this.kindofstyle2()
    this.xchange = 1
    // this.sizeForm.address = '999'
  },
  methods: {
    kindOfStyle(){
      if(this.extensionType == 2 && this.extensionFlow == 3 && this.flowScene ==12){
        this.bxqk = false
      }else if(this.extensionType == 2 && this.extensionFlow == 3 && this.flowScene ==7){
        this.bxqk = false
      }else if(this.extensionType == 2 && this.extensionFlow == 3 && this.flowScene ==9){
        this.bxqk = false
      }else if(this.extensionType == 2 && this.extensionFlow == 3 && this.flowScene ==8){
        this.bxqk = false
      }else if(this.extensionType == 2 && this.extensionFlow == 3 && this.flowScene ==10){
        this.bxqk = false
      }else if(this.extensionType == 2 && this.extensionFlow == 3 && this.flowScene ==11){
        this.bxqk = false
      }else if(this.extensionType == 2 && this.extensionFlow == 2 && this.flowScene ==6){
        this.bxqk = true
      }
    },
    kindofstyle2(){
      if(this.extensionType == 1 && this.extensionFlow == 2 && this.flowScene ==5){
        this.renkou = false
        this.bxqk = false
        this.one = false
        this.two = true
        this.resourceTwohaha = false
        this.resourceOne = true
      }
      if(this.extensionType == 1 && this.extensionFlow == 3 && this.flowScene ==12){
        this.bxqk = false
        this.resourceTwohaha = true
      }else if(this.extensionType == 1 && this.extensionFlow == 3 && this.flowScene ==7){
        this.bxqk = false
        this.resourceTwohaha = true
      }else if(this.extensionType == 1 && this.extensionFlow == 3 && this.flowScene ==9){
        this.bxqk = false
        this.resourceTwohaha = true
      }else if(this.extensionType == 1 && this.extensionFlow == 3 && this.flowScene ==8){
        this.bxqk = false
        this.resourceTwohaha = true
      }else if(this.extensionType == 1 && this.extensionFlow == 3 && this.flowScene ==10){
        this.bxqk = false
        this.resourceTwohaha = true
      }else if(this.extensionType == 1 && this.extensionFlow == 3 && this.flowScene ==11){
        this.bxqk = false
        this.resourceTwohaha = true
      }else if(this.extensionType == 1 && this.extensionFlow == 2 && this.flowScene ==6){
        this.bxqk = true
        this.resourceTwohaha = true
        this.renkou = true
        this.one = true
        this.two = false
      }
    },
    toggleCard(){
      if(this.boxCard==false){
        this.boxCard=true
      }else{
        this.boxCard=false
      }
    },

    //change新建定向和已有定向
    changeResource(val){
      this.resourceFlows = val
      if(val == 2){
        this.xchange = 2
        this.resourceOne = true
        this.resourceTwo = false
        this.kindOfStyle()
        this.kindofstyle2()
      }else{
        this.xchange = 1
        this.resourceOne = false
        this.resourceTwo = true
        // if(this.extensionType == 1 && this.extensionFlow == 2 && this.flowScene ==5){
        //   this.resourceTwohaha = false
        // }else{
        //   this.resourceTwohaha = true
        // }
        this.sizeForm.choosedDirRegion = ''
      }
    },

    //已有定向包的定向列表
    getResourceList(){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/targetlist?flowScene="+that.flowScene+"&ownerId="+that.ownerId+"&extensionType="+that.extensionType).then(res=> {
        if(res.data.code == 0){
          that.choosedDirRegionOption = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //已有定向包选择之后的定向详情接口
    changeHave(val){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/targetDetail?ownerId="+that.ownerId+"&targetDetail="+val).then(res=> {
        if(res.data.code == 0){
          that.resourceDetail = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //change年龄
    changeAge(val){
      if(val.value == 0){
        this.ageShow = true
      }else{
        this.ageShow = false
        this.submitDparams.age = val.value //保存新建定向-如果年龄点击不限的时候传999
      }
    },
    checkedAge(){
      this.submitDparams.age = String(this.sizeForm.checkboxGroupYear) //保存新建定向-如果按年龄时根据不同的年龄选择数字
    },

    //change网络
    changeNet(val){
      if(val.value == 0){
        this.netShow = true
      }else{
        this.netShow = false
        this.submitDparams.network = val.value
      }
    },

    //change网络的类型
    changeNetType(){
      this.submitDparams.network = String(this.sizeForm.checkboxGroupNetwork)
    },

    //change价格
    changePrice(val){
      if(val.value == 0){
        this.priceShow = true
      }else{
        this.priceShow = false
        this.submitDparams.mobilePrice = val.value
      }
    },

    //change手机价格
    sjPrice(){
      this.submitDparams.mobilePrice = String(this.sizeForm.checkboxGroupPrice)
    },

    //按性别
    changeSexes(val){
      this.submitDparams.sex = val.value
    },

    //人群包列表
    getPersonal(){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/peoplelist?ownerId="+that.ownerId).then(res=> {
        if(res.data.code == 0){
          that.directeOption = res.data.data
          that.directeOption1 = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //选择人群包定向
    peopleDirection(val){
      let that = this
      that.directeOption1.forEach(function(e,i){
        if(e.targetId == val.targetId){
          e.disabled = true
        }else{
          e.disabled = false
        }
      })
      this.submitDparams.audience = val.targetId
    },

    //人群包定向排除
    peopleDirectionPc(val){
      let that = this
      that.directeOption.forEach(function(e,i){
        if(e.targetId == val.targetId){
          e.disabled = true
        }else{
          e.disabled = false
        }
      })
      this.submitDparams.oppAudienceId = val.targetId
    },

    //change地域
    changeAddress(val){
      if(val.value == 1){
        this.getPcList()
        this.treeShow = true
      }else{
        this.treeShow = false
      }
    },

    //按地域
    getPcList(){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/provinceAndCityMapping?ownerId="+that.ownerId).then(res=> {
        if(res.data.code == 0){
          that.placeData = res.data.data
          // that.placeData = that.getTreeData(res.data.data);
          that.placeData.forEach(function(val,index){
            if(val.children.length == 0){
              val.children = undefined
            }
          })
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //选中tree
    changeCheckedTree(event){
      let that = this
      if(event.children.length == 0){
        that.placeDataList.push(event.name)
      } else if (event.children.length>0){
        that.placeDataList.push(event.name)
        event.children.forEach(function(item,index){
          that.placeDataList.push(item.name)
        })
      }
    },

    //按照app
    getAppList(){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/metadataapplist?ownerId="+that.ownerId+"&name="+that.appName).then(res=> {
        if(res.data.code == 200){
          const allData = res.data.content
          const data = [];
          for(let i = 0; i < allData.length; i++) {
            data.push({
              key: allData[i].id,
              label: allData[i].name,
            });
          }
          that.appInstallData = data
          that.appInstallData1 = data
          that.appInstallData2 = data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    getObject(val){
      // console.log(val)
    },

    enterName(){
      this.appName = this.$refs.transferCom.$refs.leftPanel.query
      this.getAppList()
    },
    enterName1(){
      this.appName = this.$refs.transferCom1.$refs.leftPanel.query
      this.getAppList()
    },
    enterName2(){
      this.appName = this.$refs.transferCom2.$refs.leftPanel.query
      this.getAppList()
    },

    //change App安装
    changeApp(val){
      if(val.value == 1){
        this.allTransferShow = true
        this.appTransferShow = true
        this.appTransferShow1 = false
        this.appTransferShow2 = false
        this.appName = ''
        this.getAppList()
      }else if(val.value == 2){
        this.allTransferShow = true
        this.appTransferShow1 = true
        this.appTransferShow = false
        this.appTransferShow2 = false
        this.appName = ''
        this.getAppList()
      }else if(val.value == 3){
        this.allTransferShow = true
        this.appTransferShow2 = true
        this.appTransferShow = false
        this.appTransferShow1 = false
        this.appName = ''
        this.getAppList()
      }else{
        this.allTransferShow = false
        this.appTransferShow = false
        this.appTransferShow2 = false
        this.appTransferShow1 = false
      }
    },

    //change已安装
    getObject1(val){
      this.submitDparams.installAppList = String(val)
    },
    //change未安装
    getObject2(val){
      this.submitDparams.notInstallAppList = String(val)
    },
    //change从未安装
    getObject3(val){
      this.submitDparams.neverInstallAppList = String(val)
    },

    //change用户行为兴趣
    changeUserActive(val){
      if(val.value == 1){
        this.userActiveShow = true
        this.getIntrustry()
      }else{
        this.userActiveShow = false
      }
    },

    //点击行为兴趣
    openInterest(){
      this.activeListShow1 = true
      this.getIntrustry()
      this.interestingData1.forEach(function(val,index){
        if(val.children.length == 0){
          val.children = undefined
        }
      })  
    },

    //点击行业精选
    openIndustry(){
      this.activeListShow2 = true      
    },

    //用户行为兴趣的接口-tagIntention=1是行为兴趣tagIntention=2是行业精选
    getIntrustry(){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/userInterestTagV2?ownerId="+that.ownerId).then(res=> {
        if(res.data.code == 200){
          let interesting = []
          interesting = res.data.content
          that.interestingData1 = []
          that.interestingData2 = []
          let level1 = []
          let level2 = []
          interesting.forEach(function(e,i){
            if(e.tagIntention == 1){
              level1.push(e)       
            }else{
              level2.push(e)
            }
          })
          that.interestingData1 = level1
          that.interestingData2 = level2
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //按照app
    getAppListHy(){
      let that = this
      $http.axios.get("/api/advertisement/preaddgroup/metadataapplist?ownerId="+that.ownerId+"&name="+that.appName).then(res=> {
        if(res.data.code == 200){
          const allData = res.data.content
          const data = [];
          for(let i = 0; i < allData.length; i++) {
            data.push({
              key: allData[i].id,
              label: allData[i].name,
            });
          }
          that.appInstallDataApp = data
          that.unappInstallDataApp = data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //用户活跃
    changeUserHy(val){
      if(val.value == 1){
        this.transferAppShow = true
        this.appName = ''
        this.getAppListHy()
        this.activeRadioChange()
      }else{
        this.transferAppShow = false
      }
    },

    activeRadioChange(){
      if(this.activeRadio == 1){
        this.unacRadioShow = false
        this.acRadioShow = true
      }else{
        this.unacRadioShow = true
        this.acRadioShow = false
      }
    },

    enterNameApp(){
      this.appName = this.$refs.transferComApp.$refs.leftPanel.query
      this.getAppListHy()
    },

    //用户非活跃
    unenterNameApp(){
      this.appName = this.$refs.untransferComApp.$refs.leftPanel.query
      this.getAppListHy()
    },

    //change app活跃用户
    getObjectApp(val){
      this.submitDparams.appActive = String(val)
    },

    //change app非活跃用户
    ungetObjectApp(val){
      this.submitDparams.appNotActive = String(val)
    },

    //change兴趣
    changeinteresting1(val){
    let that = this
    let obj = []
    val.forEach(function(e,i){
      obj.push(e[e.length-1])
    })
    that.arrinteresting1 = obj
    },
    //change兴趣
    changeinteresting2(val){
      let that = this
      let obj = []
      val.forEach(function(e,i){
        obj.push(e[e.length-1])
      })
      that.arrinteresting2 = obj
    },

    //change已有定向
    resourceTwoChange(val){
      this.xtargetId = val
    },

    //保存新建定向
    submitDirection(){
      let that = this,targetId
      that.submitDparams.accountId = that.ownerId //ownerId
      let interest = []
      interest = that.arrinteresting1.concat(that.arrinteresting2)
      that.submitDparams.interestTags = String(interest) //行为兴趣的集合
      that.submitDparams.type = '1' //固定传1,楚莨说的
      that.submitDparams.appActiveDays = that.radioActiveDay //保存新建定向--活跃用户的天数
      that.submitDparams.appNotActiveDays = that.radioUnActiveDay //保存新建定向--非活跃用户的天数
      that.submitDparams.targetDesc = '新建定向'//楚莨说随便传一个就行
      if(that.submitDparams.immutable == 0){
        that.submitDparams.targetName = that.sizeForm.deriectName //如果点击了另存为才会必填
      }else{
        that.submitDparams.targetName = '定向名称必填' //楚莨说oppo这么要的，改不了，0或1都传，0传活得，1传死的
      }

      $http.axios.post("/api/advertisement/adGroup/v1/addTarget",that.submitDparams).then(res=> {
        if(res.data.code == 200){
          // that.fzzj = res.data.content
          targetId = res.data.content.targetId
          that.pubVue.onSubmit(targetId)
        }else if(res.da){

        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //复制已有定向包
    // clickCopy(){
    //   this.getResourceList()
    //   this.copyVisible = true
    //   this.copyDirRegion = ''
    // },

    changeDeriect(){
      this.deriectId = this.copyDirRegion
    },

    //复制弹框--确定
    copySure(){
      this.submitDparams.ptargetId = this.deriectId
      this.copyVisible = false
    },

    //change城市添加
    changeCity(val){
      let that = this
      let obj = []
      val.forEach(function(e,i){
        obj.push(e[e.length-1])
      })
      that.cityObj = obj
      that.changeAddressType()
    },

     //按城市--当前地和常驻地切换
     changeAddressType(){
      if(this.addressRadio == 1){
        this.submitDparams.region = String(this.cityObj)
      }else{
        this.submitDparams.permanentRegion = String(this.cityObj)
      }
    },

    //change选择定向包
    changeChecked(val){
      if(val == true){
        this.deriectNameShow = true
        this.submitDparams.immutable = 0
      }else{
        this.deriectNameShow = false
        this.submitDparams.immutable = 1
      }
    },

    //用户转化
    changePingbi(val){
      this.submitDparams.filterConvertedAd = val.value
    }
    

  }
}
</script>
<style lang="scss">
  .choosedList{
    font-size: 12px;
    strong{
      margin-right: 15px;
    }
  }
  .creatNewDir{
    width:91%;
    margin-left:110px;
    box-sizing: border-box;
    padding:20px 30px;
    background: #F7F8FB;
  }
  .creatNewDir .el-form-item--mini .el-form-item__content{
    width: 50%;
  }
  .appTransfer{
    margin:30px 0 10px;
  }
  .appTransfer .el-transfer{
    width:700px!important;
  }
  /* .appTransferUser .el-form-item{
    display:inline-block;
    vertical-align:middle;
    box-sizing: border-box;
    padding:20px;
    width:50%;
  } */
  .tree_transfer{
    width:1000px;
    margin-top:20px;
  }
  .tree_transfer .el-cascader{
    width:500px!important;
  }
  .tree_transfer .el-input__inner{
    max-height:200px!important;
  }
  .tree_transfer .el-cascader__tags{
    height:100px!important;
    overflow-y: scroll!important;
  } 
  .tree_transfer .el-cascader__search-input{
    display: none!important;
  }
  .userActive{
    margin-top:15px;
  }
  .userActive li{
    margin:10px 0;
    padding:5px 10px;
    background:#eee;
    width:600px;
    cursor: pointer;
  }
  .userActive li span{
    font-weight:700;
    padding:0 15px 0 10px;
  }
  .userActive li b{
    font-weight:400;
  }
  .userActive li strong{
    font-weight:400;
    color:#2fc29b;
    float:right;
  }
  .copyHaveDirection{
    font-size:14px;
    color:#2fc29b;
    cursor: pointer;
    margin-bottom:20px;
  }

</style>