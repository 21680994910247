<template>
    <div class="authority common-page">
       <PocCardTable>
         <!-- 新建广告系列 -->
        <template slot="headerTitle" v-if="showCreatePlan">
          <div class='hl_header'>
            <div class='hl_breadCrumb hl_headerLeft'>
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
                <el-breadcrumb-item >广告系列</el-breadcrumb-item>
                <el-breadcrumb-item >创建</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <div class="createAll">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
              <h2 class="createTitle createAdverts">
                <span>创建广告系列</span>
              </h2>
              <el-form-item label="选取一个App" prop="appName">
                <el-select
                  v-model="ruleForm.appName"
                  filterable
                  remote
                  reserve-keyword
                  no-data-text="加载中"
                  placeholder="请输入AppID或者App名称查找"
                  :remote-method="remoteMethod"
                  :loading="loading">
                  <el-option
                    v-for="(item,i) in appDetail"
                    :key="i"
                    :label="item.appName"
                    :value="item.adamId">
                    <span style="float: left">{{ item.appName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.developerName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择广告投放位置" prop="adPlanPosition">
                <el-select v-model="ruleForm.adPlanPosition" placeholder="请选择广告投放位置" @change="changePosition">
                  <el-option
                    v-for="(item,i) in adPlanPositionOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="选择国家/地区" prop="adPlanCountry">
                <el-select v-model="ruleForm.adPlanCountry" placeholder="请选择选择国家/地区">
                  <el-option
                    v-for="(item,i) in adPlanCountryOption"
                    :key="i"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="广告系列名称" prop="adPlanName">
                <el-input v-model="ruleForm.adPlanName" placeholder="请输入广告系列名称"></el-input>
              </el-form-item>
              <!-- <el-form-item label="预算" prop="adPlanBudget">
                <el-input v-model="ruleForm.adPlanBudget" placeholder="设置后只可增加，不能减少">¥</el-input>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" class="iconTipContent">
                    你愿意在广告系列上花费的金额，这是广告系列初始设置过程中必须完成的一个步骤。你可以随时增加你的预算。在你的广告系列启动日期之后，将无法减少预算。但你可以将其暂停，用较低的预算创建一个新的广告系列。 提示：包含每日预算。 你可以设置每日预算，在广告系列投放期间合理支出预算。
                  </div>
                  <i class="el-icon-question iconTip"></i>
                </el-tooltip>
              </el-form-item> -->
              <div class="budgetOrder">
                <span>预算与订单</span>
              </div>
              <el-form-item label="每日预算" prop="adPlanEveryBudget">
                <el-input v-model="ruleForm.adPlanEveryBudget" placeholder="请输入金额"></el-input>
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content" class="iconTipContent">
                    每日预算是你希望在一个月内每天花费在广告系列上的平均金额。你的每月支出不会超过每日预算乘以一个日历月的平均天数。在有机会获得更多下载次数的日子里，你的支出可能会超出每日预算。
                    你的广告系列会持续根据每日预算金额进行每月支出，除非你暂停、移除广告系列或设置结束日期。如果你设置了结束日期，则你支出的费用不会超过广告系列总天数乘以每日预算。
                    你可以随时调整每日预算。
                  </div>
                  <i class="el-icon-question iconTip"></i>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="结算选项" prop="adPlanSettlement">
                <el-radio v-model="ruleForm.adPlanSettlement" label="1">标准账单</el-radio>
              </el-form-item>
              <el-form-item label="广告主或产品" prop="adPlanProduct">
                <el-input v-model="ruleForm.adPlanProduct" placeholder="请输入广告主或产品"></el-input>
              </el-form-item>
              <el-form-item label="订单号" prop="adPlanOrder">
                <el-input v-model="ruleForm.adPlanOrder" placeholder="AppID(广告系列组)"></el-input>
              </el-form-item>
              <div class="budgetOrder">
                <span>联系方式</span>
              </div>
              <el-form-item label="主要联系人姓名" prop="adPlanContactName">
                <el-input v-model="ruleForm.adPlanContactName" placeholder="请输入主要联系人姓名"></el-input>
              </el-form-item>
              <el-form-item label="主要联系人电子邮箱" prop="adPlanContactEmail">
                <el-input v-model="ruleForm.adPlanContactEmail" placeholder="请输入主要联系人电子邮箱"></el-input>
              </el-form-item>
              <el-form-item label="结算联系人电子邮箱" prop="adPlanSetContactEmail">
                <el-input v-model="ruleForm.adPlanSetContactEmail" placeholder="请输入结算联系人电子邮箱"></el-input>
              </el-form-item>
              <div class="budgetOrder">
                <span>日期设置</span>
              </div>
              <el-form-item label="开始日期" prop="setStartDateTime">
                <el-date-picker
                  v-model="ruleForm.setStartDateTime"
                  type="datetime"
                  placeholder="请选择开始日期时间"
                  align="right"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="结束日期（可选）" prop="">
                <el-date-picker
                  v-model="ruleForm.setEndDateTime"
                  type="datetime"
                  placeholder="请选择结束日期时间"
                  align="right"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>

              <el-form-item label="广告系列否定关键词(可选)" prop="" v-if="changePosition2">
                <el-button type="primary" plain size="small" class="negKeywords" @click="addNegKeyword">
                  <i class="el-icon-plus"></i>添加
                </el-button>
                <div class="negWrap" v-if="negWrapShow">
                  已添加{{negKeywordNum}}个否定关键词
                </div>
              </el-form-item>
            </el-form>

            

            <!-- 广告系列添加否定关键词弹框 -->
            <el-dialog 
              title="添加否定关键词" 
              width="50%"
              :visible.sync="dialogFormVisible">
              <div class="negKeywordWrap">
                <span class="addNegKeywords">已添加（<i>{{negKeywordNum}}</i>）</span>
                <div class="enterKeywords">
                  <el-input v-model="enterKeywords" class="enterKeywordsInput" placeholder="添加否定关键词，多词用逗号分隔"></el-input>
                  <el-button type="primary" class="enterKeywordsBtn" size="mini" @click="addNegKw">添加</el-button>
                </div>
                <div class="batchBtns">
                  <el-button type="primary" size="mini" @click="batchMatchType">批量修改匹配类型</el-button>
                  <el-button type="primary" size="mini" @click="batchDel">批量删除</el-button>
                </div>
                <!-- 批量修改匹配类型 -->
                <el-dialog 
                  title="批量修改匹配类型" 
                  width="300px"
                  center
                  class="smallDialog"
                  append-to-body
                  :visible.sync="dialogEditMatchType">
                  <div class="editTypeChoose">
                    <el-select v-model="batchMateType" placeholder="请选择匹配类型">
                      <el-option
                        v-for="(item,i) in mateTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="editTypeBtns">
                    <el-button class="newDialogCancel" type="primary" @click="editMatchTypeFalse">取 消</el-button>
                    <el-button class="newDialog" type="primary" @click="editMatchType">确 定</el-button>
                  </div>
                </el-dialog>
                <el-table
                  ref="multipleTable"
                  :data="tableData"
                  class="inner"
                  @selection-change="handleSelectionChange"
                  tooltip-effect="dark">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="text"
                    label="否定关键词">
                  </el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="匹配类型"
                    width="150">
                    <template slot-scope="scope" >
                      <el-select v-model="scope.row.matchType">
                        <el-option
                          v-for="(item,i) in mateTypeOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row)">
                        <template #reference>
                          <i class="el-icon-delete-solid"></i>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="negKeywordsLast">
                  已过滤重复否定关键词 <i>{{negKeywordsLast01}}</i> 个
                  过滤特殊字符否定关键词 <i>{{negKeywordsLast02}}</i> 个
                </div> -->
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button class="newDialogCancel" type="primary" size="middle" @click="dialogFormVisible = false">取消保存</el-button>
                <el-button class="newDialog" type="primary" size="middle" @click="negKeyWordSure">确定保存</el-button>
              </div>
            </el-dialog>

            <div class="createAdvertBtns">
              <el-button class="newDialogCancel" type="primary" size="middle" @click="cancelCreateAdvert">取消保存</el-button>
              <el-button class="newDialog" type="primary" size="middle" @click="createPlan">下一步</el-button>
            </div>
          </div>
        </template>

        <!-- 新建广告组 -->
        <template slot="headerTitle" v-if="showCreateTeam">
          <div class="haveAdPlan">
            <!-- <div class="haveAdPlanList">
              <label>投放APP：</label>
              <span>钢构宝-钢结构钢材建筑建材做工程神器</span>
            </div> -->
            <div class="haveAdPlanList haveAdPlanList02">
              <div v-if="tab == 1">
                <span>广告系列名称：{{campaignName}}</span> 
                <span class="interval">|</span>
                <span>广告展示位置：
                  <i v-if="supplySources == 'APPSTORE_SEARCH_RESULTS'">搜索结果</i>
                  <i v-else-if="supplySources == 'APPSTORE_SEARCH_TAB'">搜索标签</i>
                </span>
              </div>
              <div v-else>
                <span>广告系列名称：{{getPlanData.name}}</span>
                <span class="interval">|</span>
                <span>广告展示位置：
                  <span v-for="item in getPlanData.supplySources">
                    <i v-if="item == 'APPSTORE_SEARCH_RESULTS'">搜索结果</i>
                    <i v-else-if="item == 'APPSTORE_SEARCH_TAB'">搜索标签</i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="createAdTeamKeyword">
            <el-form :model="teamRuleForm" :rules="teamRules" ref="teamRuleForm" label-width="240px" class="demo-ruleForm">
              <div>
                <h2 class="createTitle createAdverts">
                  <span>创建广告组</span>
                </h2>
                <el-form-item label="广告组名称" prop="groupName">
                  <el-input v-model="teamRuleForm.groupName" placeholder="请输入广告组名称"></el-input>
                </el-form-item>
                <el-form-item label="默认最高出价" prop="groupDefault">
                  <el-input v-model="teamRuleForm.groupDefault" placeholder="不能超过每日预算"></el-input>
                  <span v-if="tab == 1" class="budgetStyle">不可超过预算：{{budget}}</span>
                  <span v-else class="budgetStyle">不可超过预算：{{ruleForm.adPlanEveryBudget}}</span>
                </el-form-item>
                <el-form-item label="每次转化费用上限" v-if="changePosition2" prop="">
                  <el-input v-model="teamRuleForm.groupCost" placeholder="每次转化费用上限"></el-input>
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content" class="iconTipContent">
                      该可选设置指定了你愿意为每次转化支付的金额，并将为你广告组中所有关键词设置出价上限。该上限的计算方式如下：每次转化费用上限 x 转化率
如果关键词的最高每次点击付费出价低于上述等式的结果，则系统会应用该最高每次点击付费出价。例如，如果每次转化费用上限为 5 美元，转化率为 65%，则系统为广告组中所有关键词应用的最高出价为 3.25 美元。如果最高每次点击付费设置为 4 美元，则系统会应用的最高出价仍为 3.25 美元。
每次转化费⽤上限会限制展示次数和转化次数，你可以自行决定是否进行相关设置。
                    </div>
                    <i class="el-icon-question iconTip"></i>
                  </el-tooltip>
                </el-form-item>
                <el-form-item label="匹配" prop="" v-if="changePosition2">
                  <div class="" >
                    <span style="padding-right:180px;font-size:12px;">自动将我的广告与相关搜索相匹配</span>
                    <el-switch v-model="teamRuleForm.groupMate"></el-switch>
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content" class="iconTipContent">
                        搜索匹配使用多种资源将你的广告与 App Store 中的相关搜索进行自动匹配，其中包括你的 App Store 详情中的元数据、同一类型中类似 app 的相关信息以及其他搜索数据。
                      </div>
                      <i class="el-icon-question iconTip"></i>
                    </el-tooltip>
                  </div>
                </el-form-item>
              </div>
              <div class="groupKeyword" v-if="changePosition2">
                <div class="budgetOrder">
                  <span>关键词</span>
                </div>
                <el-form-item label="广告组关键词" prop="">
                  <!-- <el-button type="primary" plain size="small" class="negKeywords" @click="addKeywordShow">
                    <i class="el-icon-plus"></i>添加
                  </el-button> -->
                  <div class="kwAddNum">
                    <el-button type="primary" size="small">已添加({{teamKeywordNum}})</el-button>
                    <div class="kwAddNumBtn" @click="addKeywordShow"><i class="el-icon-circle-plus-outline"></i></div>
                  </div>
                </el-form-item>
                <el-form-item label="广告组否定关键词" prop="">
                  <div class="kwAddNum">
                    <el-button type="primary" size="small">已添加({{negGroupKeywordNum}})</el-button>
                    <div class="kwAddNumBtn" @click="addTeamNegKeyword"><i class="el-icon-circle-plus-outline"></i></div>
                  </div>
                  <!-- <el-button type="primary" plain size="small" class="negKeywords" @click="addTeamNegKeyword">
                    <i class="el-icon-plus"></i>添加
                  </el-button> -->
                </el-form-item>
              </div>
              <div class="addTeamAudience">
                <div class="budgetOrder">
                  <span>
                    受众（可选）
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content" class="iconTipContent">使用可选的高级功能优化广告组的受众。</div>
                      <i class="el-icon-question iconTip"></i>
                    </el-tooltip>
                  </span>
                </div>
                <el-form-item label="选择受众" prop="teamRuleForm">
                  <el-radio v-model="teamRuleForm.audienceRadio" label="1" @change="changeAudience">选择特定受众</el-radio>
                  <el-radio v-model="teamRuleForm.audienceRadio" label="2" @change="changeAllAudience">触达所有符合条件的用户<el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content" class="iconTipContent">为所有符合条件的用户自动优化你的广告，以触达最广泛的受众。</div>
                    <i class="el-icon-question iconTip"></i>
                  </el-tooltip></el-radio>
                </el-form-item>
                <div v-if="adAudience">
                  <el-form-item label="设备" prop="">
                    <el-select v-model="teamRuleForm.groupDevice" disabled placeholder="请选择设备">
                      <el-option
                        v-for="(item,i) in groupDeviceOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="客户类型" prop="">
                    <el-select v-model="teamRuleForm.customType" @change="changecustomType">
                      <el-option
                        v-for="(item,i) in customTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="性别" prop="">
                    <el-select v-model="teamRuleForm.sexType">
                      <el-option
                        v-for="(item,i) in sexTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="年龄段" prop="">
                    <span class="ageage">
                      <el-select v-model="teamRuleForm.ageSlot" @change="changeAgeGroup">
                        <el-option
                          v-for="(item,i) in ageSlotOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </span>
                    <span class="ageage" v-if="ageShow">
                      -
                      <el-select v-model="teamRuleForm.maxAgeSlot">
                        <el-option
                          v-for="(item,i) in maxAgeSlotOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </span>
                  </el-form-item>
                  <div class="budgetOrder">
                    <span>广告投放时间设置</span>
                  </div>
                  <el-form-item label="开始时间" prop="">
                    <!-- <div class="startEnd">开始时间</div> -->
                    <el-date-picker
                      v-model="teamRuleForm.setStartDateTime"
                      type="datetime"
                      placeholder="请选择日期时间"
                      align="right"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content" class="iconTipContent">选择何时向用户展示广告。投放时间设置基于用户的本地时区。</div>
                      <i class="el-icon-question iconTip"></i>
                    </el-tooltip>
                  </el-form-item>
                  <el-form-item label="结束时间" prop="">
                    <div class="startEnd">
                      <el-checkbox v-model="checkedEndDateTime" @change="changeShowEnd">是否开启结束时间</el-checkbox>
                    </div>
                    <el-date-picker
                      v-model="teamRuleForm.setEndDateTime"
                      type="datetime"
                      placeholder="请选择日期时间"
                      align="right"
                      style="margin-top:15px;"
                      v-if="setEndDateTimeShow"
                      :picker-options="pickerOptions">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item v-if="changePosition2">
                    <timeUnitApple ref="timeStrArray"></timeUnitApple>
                  </el-form-item>
                </div>
                <div v-if="adAllAudience"></div>
              </div>
              <div class="addTeamAudience" v-if="changePosition2">
                <div class="budgetOrder">
                  <span>广告素材</span>
                </div>
                <el-form-item label="广告" prop="">
                  <el-radio v-model="advertRadio" label="1" @change="changeAdDefault">默认产品页面</el-radio>
                  <el-radio v-model="advertRadio" v-if="changePosition2" label="2" @change="changeAdCustom">自定义</el-radio>
                  <el-tooltip class="item" effect="dark" placement="bottom">
                    <div slot="content" class="iconTipContent">
                      使用 App Store 产品页面中的元数据和广告素材资源自动为你创建默认图片或文字广告。带图片的广告使用前几个截屏和 app 预览。 除了任何广告素材集以外，默认广告随时会投放，以确保获得最佳效果。
                    </div>
                    <i class="el-icon-question iconTip"></i>
                  </el-tooltip>
                  <div class="showAdCustom" v-if="showCustom">
                    <h3>没有可以使用的自定产品页面</h3>
                    <p>要创建广告变体，请首先在 App Store Connect 中创建自定产品页面。</p>
                  </div>
                  <div class="showAdCustom" v-if="showAdCustom">
                    <h3>{{advertDataLength}}个自定产品页面可以使用</h3>
                    <p>点击"创建广告"按钮，即可从你的自定产品页面选项中进行选择，并查看广告预览</p>
                    <el-button type="primary" size="middle" @click="createAdvert">创建广告</el-button>
                  </div>
                </el-form-item>
              </div>
            </el-form>

             <!-- 创建广告 -->
             <el-dialog
              title="创建广告"
              :visible.sync="createAdVisible"
              center
              width="1200px"
              class="advertDialog">
              <!-- <div class="kwIcon"><img :src="kwIcon"></div> -->
              <div class="createAdName">
                <el-input v-model="adName" placeholder="请输入广告名称"></el-input>
              </div>
              <div class="createAdList">
                <div class="createAdLength">可以使用的自定义产品页面：{{advertDataLength}}</div>

                  <div class="radioList">
                    <el-radio-group class="radioListAdvert" v-model="queryWord">
                      <el-radio
                        class="createAdDetailList"
                        v-for="(item,i) in advertData" :key="i"
                        :label="item"
                        @change="changeRadio"
                        border>
                        <div class="adimgList">
                          <div class="createAdimgList">
                            <div v-for="val in item.detail.appPreviewDeviceWithAssets.iphone_6_5.screenshots" class="createAdimg"> 
                              <img :src="val.assetUrl" />
                            </div>
                          </div>
                        </div>
                
                        <div class="advertName">
                          <h3>{{item.name}}</h3>
                          <p class="describe">{{item.detail.shortDescription}}</p>
                        </div>
  
                      </el-radio>
                    </el-radio-group>
                  </div>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button class="newDialogCancel" type="primary" @click="createAdVisible = false">取消保存</el-button>
                <el-button class="newDialog" type="primary" @click="createAdSure">确定保存</el-button>
              </div>
            </el-dialog>

            <!-- 广告组添加关键词弹框 -->
            <el-dialog 
              title="添加投放关键词" 
              width="820px"
              class="kwWrap"
              center
              :visible.sync="teamKeywordsVisible">
              <div class="kwIcon"><img :src="kwIcon"></div>
              <div class="teamKeyword teamKeywordRight">
                <div class="addKwTeam">
                  <span class="addNegKeywords">已添加（<i>{{teamKeywordNum}}/5000</i>）</span>
                  <span class="addNum">还可添加{{residueKeywordNum}}个关键词</span>
                </div>
                <div class="batchBtns addKwTeamBtn">
                  <el-button type="primary" size="mini" @click="batchKwMatchType">批量修改匹配类型</el-button>
                  <el-button type="primary" size="mini" @click="batchKwPrice">批量修改默认最高出价</el-button>
                  <el-button type="primary" size="mini" @click="batchKwDel">批量删除</el-button>
                </div>
                <div class="enterKeywords addKwInput">
                  <el-input v-model="addKeywords" class="enterKeywordsInput" placeholder="添加关键词，多个词用逗号分隔"></el-input>
                  <el-button type="primary" class="enterKeywordsBtn newDialog" size="mini" @click="addKw">添加</el-button>
                </div>
                <!-- 批量修改匹配类型 -->
                <el-dialog 
                  title="批量修改匹配类型" 
                  width="300px"
                  center
                  class="smallDialog"
                  append-to-body
                  :visible.sync="dialogEditkwMatchType">
                  <div class="editTypeChoose">
                    <el-select v-model="batchkwMateType" placeholder="请选择匹配类型">
                      <el-option
                        v-for="(item,i) in mateTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="editTypeBtns">
                    <el-button class="newDialogCancel" type="primary" @click="editkwMatchTypeFalse">取 消</el-button>
                    <el-button class="newDialog" type="primary" @click="editkwMatchType">确 定</el-button>
                  </div>
                </el-dialog>
                <!-- 批量修改默认最高出价 -->
                <el-dialog 
                  title="批量修改默认最高出价" 
                  width="300px"
                  center
                  class="smallDialog"
                  append-to-body
                  :visible.sync="dialogEditPrice">
                  <div class="editTypeChoose">
                    <el-input v-model="editPrice" placeholder="请输入修改后的出价金额"></el-input>
                  </div>
                  <div class="editTypeBtns">
                    <el-button class="newDialogCancel" type="primary" @click="editPriceFalse">取 消</el-button>
                    <el-button class="newDialog" type="primary" @click="editPriceSure">确 定</el-button>
                  </div>
                </el-dialog>
                <div class="teamKwRight">
                  <el-table
                    ref="multipleTable"
                    :data="kwRighttableData"
                    class="inner innerKw"
                    @selection-change="handleSelectionChangekw"
                    tooltip-effect="dark">
                    <el-table-column
                      type="selection"
                      width="55">
                    </el-table-column>
                    <el-table-column
                      prop="text"
                      label="关键词"
                      fixed>
                    </el-table-column>
                    <el-table-column
                      prop="chujia"
                      label="默认最高出价"
                      width="150">
                      <template slot-scope="scope" >
                        <span style="padding-right:5px;">¥</span>
                        <el-input class="priceMax" v-model="scope.row.chujia"></el-input>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="matchType"
                      label="匹配类型"
                      width="150">
                      <template slot-scope="scope" >
                        <el-select v-model="scope.row.matchType">
                          <el-option
                            v-for="(item,i) in mateTypeOption"
                            :key="i"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop=""
                      label="操作"
                      center
                      width="50">
                      <template slot-scope="scope">
                        <el-popconfirm
                          title="您是否确认删除，删除后不可恢复？"
                          @confirm="deleteTeamFn(scope.row)">
                          <template #reference>
                            <i class="el-icon-delete kwdelete"></i>
                          </template>
                        </el-popconfirm>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div slot="footer" class="dialog-footer" style="text-align:center;margin-top:20px;">
                  <el-button class="newDialogCancel" type="primary" size="middle" @click="teamKeywordsVisible = false">取消保存</el-button>
                  <el-button class="newDialog" type="primary" size="middle" @click="keyWordSure">确定保存</el-button>
                </div>
              </div>
            </el-dialog>

            <!-- 广告组添加否定关键词弹框 -->
            <el-dialog 
              title="添加否定关键词" 
              width="820px"
              class="kwWrap"
              center
              :visible.sync="dialogFormTeamVisible">
              <div class="kwIcon"><img :src="kwIcon"></div>
              <div class="negKeywordWrap">
                <div class="addKwTeam">
                  <span class="addNegKeywords">已添加（<i>{{negGroupKeywordNum}}</i>）</span>
                </div>
                <div class="batchBtns addKwTeamBtn">
                  <el-button type="primary" size="mini" @click="batchMatchType">批量修改匹配类型</el-button>
                  <el-button type="primary" size="mini" @click="batchDel">批量删除</el-button>
                </div>
                <div class="enterKeywords addKwInput">
                  <el-input v-model="enterGroupKeywords" class="enterKeywordsInput" placeholder="添加否定关键词，多词用逗号分隔"></el-input>
                  <el-button type="primary" class="enterKeywordsBtn newDialog" size="mini" @click="addGroupNegKw">添加</el-button>
                </div>
                <!-- 批量修改匹配类型 -->
                <el-dialog 
                  title="批量修改匹配类型" 
                  width="300px"
                  center
                  class="smallDialog"
                  append-to-body
                  :visible.sync="dialogEditMatchType">
                  <div class="editTypeChoose">
                    <el-select v-model="batchMateType" placeholder="请选择匹配类型">
                      <el-option
                        v-for="(item,i) in mateTypeOption"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="editTypeBtns">
                    <el-button class="newDialogCancel" type="primary" @click="editMatchTypeFalse">取 消</el-button>
                    <el-button class="newDialog" type="primary" @click="editMatchType">确 定</el-button>
                  </div>
                </el-dialog>
                <el-table
                  ref="multipleTable"
                  :data="negTableData"
                  class="inner innerKw"
                  @selection-change="handleSelectionChange"
                  tooltip-effect="dark">
                  <el-table-column
                    type="selection"
                    width="55">
                  </el-table-column>
                  <el-table-column
                    prop="text"
                    label="否定关键词">
                  </el-table-column>
                  <el-table-column
                    prop="matchType"
                    label="匹配类型"
                    width="150">
                    <template slot-scope="scope" >
                      <el-select v-model="scope.row.matchType">
                        <el-option
                          v-for="(item,i) in mateTypeOption"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop=""
                    label="操作"
                    center
                    width="50">
                    <template slot-scope="scope">
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deleteGroupFn(scope.row)">
                        <template #reference>
                          <i class="el-icon-delete kwdelete"></i>
                        </template>
                      </el-popconfirm>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div class="negKeywordsLast">
                  已过滤重复否定关键词 <i>{{negKeywordsLast01}}</i> 个
                  过滤特殊字符否定关键词 <i>{{negKeywordsLast02}}</i> 个
                </div> -->
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button class="newDialogCancel" type="primary" size="middle" @click="dialogFormTeamVisible = false">取消保存</el-button>
                <el-button class="newDialog" type="primary" size="middle" @click="negGroupKeyWordSure">确认保存</el-button>
              </div>
            </el-dialog>
          </div>
          <div class="createAdvertBtns">
            <el-button class="newDialogCancel" type="primary" size="middle" @click="backCreatePlan">取消保存</el-button>
            <el-button class="newDialog" type="primary" size="middle" @click="nextCreate">确认保存</el-button>
          </div>

          <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog>
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import kwIcon from '@/assets/img/kw.png';
  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import TimeUnitApple from '@/views/auth/planManage/TimeUnitApple'

  export default {
    components:{
      TimeUnitApple
    },
    data() {
      return {
        kwIcon,
        ruleForm:{//表单
          adPlanGroup:'',//广告系列组
          appName:'',//选取一个app
          adPlanPosition:'',//投放位置
          adPlanCountry:'',//国家/地区
          adPlanName:'',//广告系列名称
          // adPlanBudget:'',//预算
          adPlanEveryBudget:'',//每日预算
          adPlanSettlement:'1',//结算选项
          adPlanProduct:'',//广告主或产品
          adPlanOrder:'',//订单号
          adPlanContactName:'',//主要联系人姓名
          adPlanContactEmail:'',//主要联系人电子邮箱
          adPlanSetContactEmail:'',//结算联系人电子邮箱
          setStartDateTime:'',//开始日期
          setEndDateTime:'',//结束日期
        },
        rules:{//验证规则
          adPlanGroup: [
            { required: true, message: '请选择广告组系列', trigger: 'change' }
          ],
          appName: [
            { required: true, message: '请输入AppID或者App名称', trigger: 'blur' },
          ],
          adPlanPosition: [
            { required: true, message: '请选择广告投放位置', trigger: 'change' }
          ],
          adPlanCountry: [
            { required: true, message: '请选择国家/地区', trigger: 'change' }
          ],
          adPlanName: [
            { required: true, message: '请输入广告系列名称', trigger: 'blur' },
          ],
          // adPlanBudget: [
          //   { required: true, message: '总预算不能为空', trigger: 'blur' },
          //   { pattern: /^\d+(\.\d+)?$/, required: true, message: "总预算只能是数字", trigger: "blur" },
          //   { 
          //     pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
          //     required: true,
          //     message: "总预算至少为¥0.01 且不超过¥140,000,000.00",
          //     trigger: "blur" 
          //   }
          // ],
          adPlanEveryBudget: [
            { required: true, message: '总预算不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "总预算只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "总预算至少为¥0.01 且不超过总预算",
              trigger: "blur" 
            }
          ],
          adPlanProduct: [
            { required: true, message: '请输入广告主或产品', trigger: 'blur' },
          ],
          adPlanOrder: [
            { required: true, message: '请输入订单号', trigger: 'blur' },
          ],
          adPlanContactName: [
            { required: true, message: '请输入主要联系人姓名', trigger: 'blur' },
          ],
          adPlanContactEmail: [
            { pattern:  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              required: true, message: '请输入主要联系人电子邮箱', trigger: 'blur' },
          ],
          adPlanSetContactEmail: [
            { pattern:  /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              required: true, message: '请输入结算联系人电子邮箱', trigger: 'blur' },
          ],
          setStartDateTime:[
            { required: true, message: '请选择开始时间', trigger: 'change' },
          ],
        },
        adPlanPositionOption:[{
          // value:'SEARCH ',
          value:'APPSTORE_SEARCH_RESULTS',
          label:'搜索结果'
        },{
          // value:'DISPLAY',
          value:'APPSTORE_SEARCH_TAB',
          label:'"搜索"标签'
        }],//广告投放位置
        adPlanCountryOption:[{
          value:'CN',
          label:'中国大陆'
        }],//国家/地区
        dialogFormVisible:false,//添加否定关键词弹框
        negKeywordNum:0,//添加否定关键词数量
        negGroupKeywordNum:0,
        enterKeywords:'',//添加否定关键词-输入框
        enterGroupKeywords:'',//添加否定关键词-输入框-广告组
        tableData:[],//添加否定关键词-表格
        mateType:'',//匹配类型
        batchMateType:'',//批量匹配类型
        mateTypeOption:[{
          value:'BROAD',
          label:'广泛匹配'
        },{
          value:'EXACT',
          label:'完全匹配'
        }],
        negKeywordsTable:'',
        negKeywordsLast01:0,
        negKeywordsLast02:0,
        materialIdsPara:[],//批量选择
        dialogEditMatchType:false,//批量修改匹配类型
        batchMateTypeAll:[],//选择否定关键词集合
        negKw:[],//广告系列选中的否定关键词
        advertRadio:'1',//广告计划的广告单选
        showCustom:false,//自定义的没有操作
        showAdCustom:false,//自定义的有创建广告的操作
        createAdVisible:false,//创建广告弹框
        createAdForm:{//创建广告表单
          adName:'',//创建广告名称
          adSelect:'',//搜索
        },
        adName:'',//创建广告名称
        adSelect:'',//创建广告搜索
        createAdRule:{//创建广告验证

        },
        showCreatePlan:true,//新建广告系列
        //广告组
        showCreateTeam:false,//新建广告组
        AdPlanAppName:'',//新建广告组中显示的投放app
        haveBudget:'',//新建广告组中显示的预算
        haveEveryBudget:'',//新建广告组中显示的每日预算
        haveNegKeyword:'',//新建广告组中显示的否定关键词
        teamRuleForm:{//新建广告组的表单
          groupName:'',//广告组名称
          groupDefault:'',//默认最高出价
          groupCost:'',//目标每次获客成本
          groupMate:false,//自动将我的广告与相关搜索相匹配，苹果默认开
          audienceRadio:'1',//广告组选择受众
          groupDevice:'',//设备
          customType:'',//客户类型
          sexType:'',//性别
          ageSlot:'',//年龄段min
          maxAgeSlot:'',//年龄段max
          groupPosition:[],//地理位置
          setStartDateTime:'',//开始时间
          setEndDateTime:'',//结束时间
        },
        teamRules:{//新建广告组的验证规则
          groupName: [
            { required: true, message: '请输入广告组名称', trigger: 'blur' },
          ],
          groupDefault:[
            { required: true, message: '默认最高出价不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "默认最高出价只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "默认点击最大出价最少¥0.01 且不超过每日预算",
              trigger: "blur" 
            }
          ],
          groupCost:[
            // { required: true, message: '默认最高出价不能为空', trigger: 'blur' },
            { pattern: /^\d+(\.\d+)?$/, required: true, message: "目标每次获客成本只能是数字", trigger: "blur" },
            { 
              pattern: /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0.01{1,3})\.\d{0,7})|(\d|[1-9]\d|1[0-7]\d|0.01{1,3})|140000000\.0{0,6}|140000000)$/,
              required: true,
              message: "最大出价至少为¥0.01",
              trigger: "blur" 
            }
          ],
        },
        dialogFormTeamVisible:false,//广告组的否定关键词
        adAudience:true,//选择特定受众
        adAllAudience:false,//触达所有符合条件的用户
        groupDeviceOption:[{//广告组设备
          value:'IPHONE',
          label:'IPHONE'
        }],
        customTypeOption:[{
          value:null,
          label:'不限'
        },{
          // value:1399350773,
          value:1,
          label:'新用户'
        }],
        sexTypeOption:[{
          value:null,
          label:'不限'
        },{
          value:'M',
          label:'男'
        },{
          value:'F',
          label:'女'
        }],
        ageSlotOption:[
          {value:null,label:'不限'},{value:18,label:'18'},{value:19,label:'19'},{value:20,label:'20'},{value:21,label:'21'},
          {value:22,label:'22'},{value:23,label:'23'},{value:24,label:'24'},{value:25,label:'25'},{value:26,label:'26'},
          {value:27,label:'27'},{value:28,label:'28'},{value:29,label:'29'},{value:30,label:'30'},{value:31,label:'31'},
          {value:32,label:'32'},{value:33,label:'33'},{value:34,label:'34'},{value:35,label:'35'},{value:36,label:'36'},
          {value:37,label:'37'},{value:38,label:'38'},{value:39,label:'39'},{value:40,label:'40'},{value:41,label:'41'},
          {value:42,label:'42'},{value:43,label:'43'},{value:44,label:'44'},{value:45,label:'45'},{value:46,label:'46'},
          {value:47,label:'47'},{value:48,label:'48'},{value:49,label:'49'},{value:50,label:'50'},{value:51,label:'51'},
          {value:52,label:'52'},{value:53,label:'53'},{value:54,label:'54'},{value:55,label:'55'},{value:56,label:'56'},
          {value:57,label:'57'},{value:58,label:'58'},{value:59,label:'59'},{value:60,label:'60'},{value:61,label:'61'},
          {value:62,label:'62'},{value:63,label:'63'},{value:64,label:'64'}
        ],
        maxAgeSlotOption:[
          {value:19,label:'19'},{value:20,label:'20'},{value:21,label:'21'},
          {value:22,label:'22'},{value:23,label:'23'},{value:24,label:'24'},{value:25,label:'25'},{value:26,label:'26'},
          {value:27,label:'27'},{value:28,label:'28'},{value:29,label:'29'},{value:30,label:'30'},{value:31,label:'31'},
          {value:32,label:'32'},{value:33,label:'33'},{value:34,label:'34'},{value:35,label:'35'},{value:36,label:'36'},
          {value:37,label:'37'},{value:38,label:'38'},{value:39,label:'39'},{value:40,label:'40'},{value:41,label:'41'},
          {value:42,label:'42'},{value:43,label:'43'},{value:44,label:'44'},{value:45,label:'45'},{value:46,label:'46'},
          {value:47,label:'47'},{value:48,label:'48'},{value:49,label:'49'},{value:50,label:'50'},{value:51,label:'51'},
          {value:52,label:'52'},{value:53,label:'53'},{value:54,label:'54'},{value:55,label:'55'},{value:56,label:'56'},
          {value:57,label:'57'},{value:58,label:'58'},{value:59,label:'59'},{value:60,label:'60'},{value:61,label:'61'},
          {value:62,label:'62'},{value:63,label:'63'},{value:64,label:'64'},{value:null,label:'65+'}
        ],
        groupPositionOption:[{
          value:1,
          label:'1'
        },{
          value:2,
          label:'2'
        }],
        groupPosition:'',//选中的地理位置展示
        pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          }, {
            text: '昨天',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          }, {
            text: '一周前',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }]
        },
        checkedEndDateTime:false,//是否选择结束时间
        setEndDateTimeShow:false,//结束时间是否显示
        teamKeywordsVisible:false,//广告组的添加关键词
        keywordNum:0,//广告组添加关键词左侧的推荐数
        teamKeywordNum:0,//广告组添加关键词右侧的添加数
        residueKeywordNum:5000,//广告组添加关键词右侧的剩余数
        kwLefttableData:[],//广告组添加关键词左侧的推荐表格
        kwRighttableData:[],//广告组添加关键词右侧的添加表格
        recommendKeywords:'',//搜索推荐关键词
        addKeywords:'',//添加关键词
        dialogEditPrice:false,//批量修改默认最高出价
        editPrice:'',//批量修改默认最高出价的价格
        batchkwInfo:[],//关键词批量修改的时候选择行的集合
        dialogEditkwMatchType:false,//关键词批量修改匹配类型
        batchkwMateType:'',//关键词批量修改匹配类型
        sureKw:[],//广告组选中的关键词
        changePosition2:false,//投放位置为搜索标签的时候隐藏
        userId:'',
        userName:'',
        deptId:'',
        pageIndex:1,
        pageCount:10,
        ownerId:'',
        ownerName:'',
        appDetail:[],//查找app详情
        adamId:'',//appId
        negWrapShow:false,//广告系列添加了多少否定关键词
        searchResult:'',//搜索结果
        searchTab:'',//搜索标签
        loading:false,
        appList:[],
        campaignId:'',
        campaignName:'',
        typeStyle:'',
        ageShow:false,//年龄段max不限的时候隐藏
        negTableData:[],
        negGroupKw:[],
        groupCreatePlanPosition:'',
        supplySources:'',//带过来的投放位置
        appId:'',
        groupParams:{
          saveAdDto:{
            // adamId:'',
            // name:'',
            // productPageId:'',
            // type:''
          }
        },
        getPlanData:{},//从创建广告系列得到的data，跳转到创建广告组
        tab:'',//从创建广告系列或者新建广告组进来的区别
        budget:'',//广告系列的每日预算
        // totalBudget:'',//广告系列的总预算
        nowDate:'',//当前日期
        advertData:[],//获取创意列表详情
        advertDataLength:'',//获取创意列表详情length
        // dialogAdvert:false,//创建广告弹框
        imgData:[],
        queryWord:'',
        advertDataDetail:{},//创建广告的时候选中的信息
        loadingDialogVisible:false,


      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.ownerName = this.$route.query.name
      this.campaignId = this.$route.query.campaignId
      this.campaignName = this.$route.query.campaignName
      this.typeStyle = this.$route.query.type
      this.supplySources = this.$route.query.supplySources
      this.appId = this.$route.query.appId
      this.tab = this.$route.query.tab
      this.budget = this.$route.query.budget
      // this.totalBudget = this.$route.query.totalBudget

      let nowTimes = Date.now();
      // let oldTimes = nowTimes - (86400000 * 7);
      // let oldDate = this.getDate(oldTimes); //一个月前的时间
      this.nowDate = this.getDate(nowTimes);//今天的时间
      console.log(this.nowDate)
      
      this.mateType = 'BROAD'
      this.teamRuleForm.groupDevice = 'IPHONE'
      this.teamRuleForm.customType = null
      this.teamRuleForm.sexType = null
      this.teamRuleForm.ageSlot = null
      if(this.typeStyle == 1){
        this.showCreateTeam = true
        this.showCreatePlan = false
      }
      
      this.getCreateGroup()
    },
    
    methods: {
      //选中素材的materialIds
      chooseTableFn(visible){
        let that = this
        let sendttSelection = that.$refs.multipleTable.selection
        that.materialIdsPara = []
        sendttSelection.forEach(function(e,i){
          that.materialIdsPara.push(e.id)
        })
        if(sendttSelection.length == 0){
          that.$message({
            message: '请进行勾选',
            type: 'warning'
          });
          that[visible]=false
        }else{
          that[visible]=true
          return true
        }
      },

      //添加否定关键词-弹框显示
      addNegKeyword(){
        this.dialogFormVisible = true
      },

      //添加否定关键词弹框中的添加btn
      addNegKw(){
        let enterKeywords = [],_this = this
        enterKeywords = _this.enterKeywords.split(',')
        _this.tableData = []
        enterKeywords.forEach(function(obj,index){
          _this.tableData.push({'text':obj,'matchType':'BROAD'})
        })
        //去重
        let obj={}
        this.tableData=this.tableData.reduce((item,next)=>{
          obj[next.text]?'':obj[next.text]=true&&item.push(next)
          return item
        },[])

        _this.enterKeywords = ''
        _this.negKeywordNum =  _this.tableData.length
      },

      addGroupNegKw(){
        let enterGroupKeywords = [],_this = this
        enterGroupKeywords = _this.enterGroupKeywords.split(',')
        _this.negTableData = []
        enterGroupKeywords.forEach(function(obj,index){
          _this.negTableData.push({'text':obj,'matchType':'BROAD'})
        })
        //去重
        let obj={}
        this.negTableData=this.negTableData.reduce((item,next)=>{
          obj[next.text]?'':obj[next.text]=true&&item.push(next)
          return item
        },[])

        _this.enterGroupKeywords = ''
        _this.negGroupKeywordNum =  _this.negTableData.length
      },

      //添加否定关键词弹框中的单个删除
      deletePlanFn(row){
        let _this = this
        _this.tableData.forEach(function(obj,i){
          if(obj.text == row.text){
            _this.tableData.splice(i,1)
          }
          // _this.addNegKw()
        })
        _this.negKeywordNum =  _this.tableData.length
      },
      deleteGroupFn(row){
        let _this = this
        _this.negTableData.forEach(function(obj,i){
          if(obj.text == row.text){
            _this.negTableData.splice(i,1)
          }
        })
        _this.negGroupKeywordNum =  _this.negTableData.length
      },

      //添加否定关键词弹框中的选择表格
      handleSelectionChange(val){
        this.batchMateTypeAll = val
      },

      //添加否定关键词弹框中的批量修改匹配类型
      batchMatchType(){
        this.chooseTableFn('dialogEditMatchType')
      },

      //添加否定关键词弹框中的批量修改匹配类型-确定
      editMatchType(){
        let _this = this
        _this.batchMateTypeAll.forEach(function(val,i){
          val.matchType = _this.batchMateType
        })
        _this.dialogEditMatchType = false
        _this.batchMateType = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //添加否定关键词弹框中的批量修改匹配类型-取消
      editMatchTypeFalse(){
        this.batchMateType = ''
        this.dialogEditMatchType = false
        this.$refs.multipleTable.clearSelection()
      },

      //批量删除
      batchDel(){
        if(this.$refs.multipleTable.selection.length == 0){
          this.$message({
            message: '请进行勾选',
            type: 'warning'
          });
        }else{
          let _this = this,
          selection = this.$refs.multipleTable.selection,
          data = []
          _this.tableData.map((el, index) => {
            if (!selection.find(item => item.text == el.text)) {
                data.push(_this.tableData[index]);
            }
          })
          _this.tableData = data
        }
      },

      //否定关键词-确定
      negKeyWordSure(){
        this.negKw = this.tableData
        this.dialogFormVisible = false
        this.negWrapShow = true
      },
      
      //广告change自定义
      changeAdCustom(){
        this.loadingDialogVisible = true
        let _this = this,ownerId = '',appId=''
        console.log(_this.getPlanData)
        if(_this.tab == 1){
          ownerId = _this.ownerId
          appId = _this.appId
        }else{
          ownerId = _this.getPlanData.orgId
          appId =  _this.getPlanData.adamId
        }
        $http.axios.get("/api/apple/adGroup/getproductPage/"+ownerId+'/'+appId).then(res=> {
          if(res.data.code==200){
            _this.loadingDialogVisible = false
            _this.showCustom = false
            _this.showAdCustom = true//有创建创意的入口
            _this.advertData = res.data.data
            if(res.data.data != null){
              _this.advertDataLength = res.data.data.length
            }
            if(res.data.data == null){
              _this.showCustom = true//没有创建创意的入口
              _this.showAdCustom = false
              if(_this.showCustom == true){
                _this.groupParams.saveAdDto = null
              }
            }
          }
          else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            }) 
          }   
        })
      },

      //创建广告
      createAdvert(){
        this.createAdVisible = true
        let _this = this,imgData=[]
        _this.advertData.forEach(function(val,index){
          _this.imgData = val.detail.appPreviewDeviceWithAssets.iphone_6_5.screenshots
          console.log(_this.imgData)
        })
      },

      //change
      changeRadio(val){
        this.advertDataDetail = val
      },

      // 保存创建广告
      createAdSure(){
        let _this = this,params = {} 
        // if(_this.showCustom == true){
        //   _this.groupParams.saveAdDto = null
        // }else{
          console.log(_this.advertDataDetail)
          _this.groupParams.saveAdDto.adamId = _this.advertDataDetail.adamId
          _this.groupParams.saveAdDto.name = _this.advertDataDetail.name
          _this.groupParams.saveAdDto.productPageId = _this.advertDataDetail.id
          _this.groupParams.saveAdDto.type = 'CUSTOM_PRODUCT_PAGE'
          _this.createAdVisible = false
        // }
        
      },

      //广告change默认
      changeAdDefault(){
        //自定义中的信息都隐藏
        this.showAdCustom = false
        this.showCustom = false
        this.groupParams.saveAdDto = null
      },

      //取消新建广告计划
      cancelCreateAdvert(){
        // this.$router.push({path:'/plan-management/adPlanApple'})
        this.$router.push({path:'/plan-management/adPlanApple', query: { id: this.ownerId, name:this.ownerName}})
      },

      //新建广告组
      //广告组的否定关键词
      addTeamNegKeyword(){
        this.dialogFormTeamVisible = true
      },

      //广告组否定关键词-确定
      negGroupKeyWordSure(){
        this.negGroupKw = this.negTableData
        this.dialogFormTeamVisible = false
        // this.negWrapShow = true
      },

      //change广告组的受众
      changeAudience(){
        this.adAudience = true
        this.adAllAudience = false
      },

      //change广告组所有符合条件用户受众
      changeAllAudience(){
        this.adAudience = false
        this.adAllAudience = true
      },

      //change结束时间是否显示
      changeShowEnd(){
        if(this.checkedEndDateTime == true){
          this.setEndDateTimeShow = true
        }else{
          this.setEndDateTimeShow = false
          this.teamRuleForm.setEndDateTime = ''
        }
      },

      //广告组中添加关键词
      addKeywordShow(){
        this.teamKeywordsVisible = true
      },

      //添加否定关键词弹框中的添加btn
      addKw(){
        let addKeywords = [],_this = this
        addKeywords = _this.addKeywords.split(',')
        addKeywords.forEach(function(obj,index){
          _this.kwRighttableData.push({'text':obj,'matchType':'BROAD','chujia':1})
        })
        //去重
        let obj={}
        this.kwRighttableData=this.kwRighttableData.reduce((item,next)=>{
          obj[next.text]?'':obj[next.text]=true&&item.push(next)
          return item
        },[])

        _this.addKeywords = ''
        _this.teamKeywordNum =  _this.kwRighttableData.length
        _this.residueKeywordNum = 5000-(_this.kwRighttableData.length)
      },

      //change批量修改关键词的匹配类型
      batchKwMatchType(){
        this.chooseTableFn('dialogEditkwMatchType')
      },

      //关键词的批量删除
      batchKwDel(){
        if(this.$refs.multipleTable.selection.length == 0){
          this.$message({
            message: '请进行勾选',
            type: 'warning'
          });
        }else{
          let _this = this,
          selection = this.$refs.multipleTable.selection,
          data = []
          _this.kwRighttableData.map((el, index) => {
            if (!selection.find(item => item.text == el.text)) {
                data.push(_this.kwRighttableData[index]);
            }
          })
          _this.kwRighttableData = data
        }
      },

      //批量修改默认最高出价
      batchKwPrice(){
        this.chooseTableFn('dialogEditPrice')
      },

      //change关键词的表格
      handleSelectionChangekw(val){
        this.batchkwInfo = val
      },

      //批量修改默认出价
      editPriceSure(){
        let _this = this
        _this.batchkwInfo.forEach(function(obj,index){
          obj.chujia = _this.editPrice
        })
        _this.dialogEditPrice = false
        _this.editPrice = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //批量修改默认最高值取消
      editPriceFalse(){
        this.dialogEditPrice = false
        this.editPrice = ''
        this.$refs.multipleTable.clearSelection()
      },

      //批量修改关键词的匹配类型
      editkwMatchType(){
        let _this = this
        _this.batchkwInfo.forEach(function(val,i){
          val.matchType = _this.batchkwMateType
        })
        _this.dialogEditkwMatchType = false
        _this.batchkwMateType = ''
        _this.$refs.multipleTable.clearSelection()
      },

      //批量修改关键词的匹配类型
      editkwMatchTypeFalse(){
        this.batchkwMateType = ''
        this.dialogEditkwMatchType = false
        this.$refs.multipleTable.clearSelection()
      },

      //添加关键词弹框中的单个删除
      deleteTeamFn(row){
        let _this = this
        _this.kwRighttableData.forEach(function(obj,i){
          if(obj.text == row.text){
            _this.kwRighttableData.splice(i,1)
          }
        })
        _this.teamKeywordNum =  _this.kwRighttableData.length
      },

      //广告组中的关键词确定
      keyWordSure(){
        this.sureKw = this.kwRighttableData
        this.teamKeywordsVisible = false
      },

      //change投放位置的显示隐藏
      changePosition(val){
        this.groupCreatePlanPosition = val
        this.searchTab = val
        if(val == 'APPSTORE_SEARCH_RESULTS'){
          this.changePosition2 = true
          this.searchResult = 'SEARCH'
        }else if(val == 'APPSTORE_SEARCH_TAB'){
          this.changePosition2 = false
          this.searchResult = 'DISPLAY'
        }
      },

      //查询app--创建广告系列中的查找app名称用到
      getAppDetail(query){
        let _this = this,params = {}
        params = {
          'ownerId':_this.ownerId,
          // 'query':_this.ruleForm.appName,//App名称/APP ID
          'query':query,//App名称/APP ID
          'pageIndex':_this.pageIndex,
          'pageSize':_this.pageCount
        }
        $http.axios.post("/api/apple/adPlan/searchApps",params).then(function(response) {
          if(response.data.code == 200){
            _this.appDetail = response.data.data
            _this.appDetail.forEach(function(val,index){
              _this.adamId = val.adamId
            })
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      remoteMethod(query) {
        this.getAppDetail(query)
        if (query !== '') {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.appDetail = this.appList.filter(item => {
              return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.appDetail = [];
        }
      },

      //保存新建计划系列
      createPlan(){
        let _this = this,params = {},negativekeywordsDtoList = [],countriesOrRegions = [],supplySources = []
        if(_this.searchTab == 'APPSTORE_SEARCH_RESULTS'){
          _this.negKw.forEach(function(val,index){
            negativekeywordsDtoList.push({'matchType':val.matchType,'text':val.text})
          })
        }else{
          negativekeywordsDtoList.push({'matchType':'','text':''})
        }
        countriesOrRegions.push(_this.ruleForm.adPlanCountry)
        supplySources.push(_this.ruleForm.adPlanPosition)

        params = {
          'deptId':_this.deptId,
          'userId':_this.userId,
          'userName':_this.userName,
          'adamId':Number(_this.adamId),//app id
          // 'budgetAmount':{
          //   'amount':_this.ruleForm.adPlanBudget,//预算
          //   'currency':'RMB'
          // },
          'countriesOrRegions':countriesOrRegions,//国家CN
          'dailyBudgetAmount':{
            'amount':_this.ruleForm.adPlanEveryBudget,//每日预算
            'currency':'RMB'
          },
          'startTime':_this.ruleForm.setStartDateTime,
          'endTime':_this.ruleForm.setEndDateTime,
          'locInvoiceDetails':{
            'billingContactEmail':_this.ruleForm.adPlanSetContactEmail,//结算联系人电子邮箱
            'buyerEmail':_this.ruleForm.adPlanContactEmail,//主要联系人电子邮箱
            'buyerName':_this.ruleForm.adPlanContactName,//主要联系人姓名
            'clientName':_this.ruleForm.adPlanProduct,//广告主或产品
            'orderNumber':_this.ruleForm.adPlanOrder,//订单号
          },
          'name':_this.ruleForm.adPlanName,//广告系列名称
          'orgId':_this.ownerId,//账户id
          'paymentModel':'PAYG',// 标准账单 传 PAYG 预算订单 传 LOC
          'status':'PAUSED',//状态 ENABLED：启用 PAUSED：暂停 建议传暂停
          'negativekeywordsDtoList':negativekeywordsDtoList,//否定关键词
          'billingEvent':'TAPS',//市场活动的结算事件类型： 当广告投放位置为搜索结果/搜索标签时填（TAPS：每次点击） IMPRESSIONS：每次投放展示
          'supplySources':supplySources,//广告展示位置 搜索结果：APPSTORE_SEARCH_RESULTS 搜索标签：APPSTORE_SEARCH_TAB //array
          'adChannelType':_this.searchResult,//渠道类型-广告投放位置
        }

        $http.axios.post("/api/apple/adPlan/saveCampaign",params).then(res=> {
          if(res.data.code==200){
            _this.showCreateTeam = true
            _this.showCreatePlan = false
            _this.getPlanData = res.data.data
            let supplySources = _this.getPlanData.supplySources
            supplySources.forEach(function(val,item){
              if(val == 'APPSTORE_SEARCH_RESULTS'){
                _this.changePosition2 = true
              }else if(val == 'APPSTORE_SEARCH_TAB'){
                _this.changePosition2 = false
              }
            })
        
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            }) 
          }   
        })
      },

      //change年龄段
      changeAgeGroup(val){
        if(val == ''){
          this.ageShow = false
        }else{
          this.ageShow = true
        }
      },

      //change客户类型
      changecustomType(val){
        if(val == 1){
          if(this.tab == 1){
            this.groupParams.appDownloaders = Number(this.appId)
          }else{
            this.groupParams.appDownloaders = Number(this.getPlanData.adamId)
          }
          
        }else{
          this.groupParams.appDownloaders = null
        }
      },

      getCreateGroup(){
        let _this = this
        if(_this.tab == 1){
          if(_this.supplySources == 'APPSTORE_SEARCH_RESULTS'){
            _this.changePosition2 = true
          }else if(_this.supplySources == 'APPSTORE_SEARCH_TAB'){
            _this.changePosition2 = false
          }
        }
      },

      //格式化时间 返回年月日 
      getDate(time) {
        if (time) {
          let val = new Date(time);
          let Y = val.getFullYear();
          let M = (val.getMonth() + 1) < 10 ? '0' + (val.getMonth() + 1) : (val.getMonth() + 1);
          let D = val.getDate() < 10 ? '0' + val.getDate() : val.getDate();
          let h = (val.getHours() < 10 ? '0' + val.getHours() : val.getHours()) ;
          let m = ':' + (val.getMinutes() < 10 ? '0' + val.getMinutes() : val.getMinutes()) ;
          let s = ':' + (val.getSeconds() < 10 ? '0' + val.getSeconds() : val.getSeconds());
          // return Y + '-' + M + '-' + D + h + m + s;
          return Y + '-' + M + '-' + D +'T00:00:00.000Z';
          // 2023-10-08T02:38:41.359Z
        }
        return ''
      },

      //广告组的保存
      nextCreate(){
        let _this = this,params = {},negativekeywordsDtoList = [],
        groupkeywordsDtoList = [],cpaGoal={},timeStr = [],daypart =[]
        _this.groupParams.deptId = _this.deptId
        _this.groupParams.userId = _this.userId
        _this.groupParams.userName = _this.userName
        if(_this.tab == 1){
          _this.groupParams.campaignId = Number(_this.campaignId)//系列id
          _this.groupParams.orgId = _this.ownerId



        }else{
          _this.groupParams.campaignId = _this.getPlanData.id
          _this.groupParams.orgId = _this.getPlanData.orgId




        }
        //触达所有符合条件的用户
        if(_this.adAllAudience == true){
          _this.groupParams.appDownloaders = null
          _this.groupParams.deviceClass = ''//设备 IPAD IPHONE(默认)
        }else{
          _this.groupParams.deviceClass = _this.teamRuleForm.groupDevice//设备 IPAD IPHONE(默认)
        }

        if(_this.teamRuleForm.audienceRadio == '2'){
          _this.groupParams.gender = null
          _this.groupParams.minAge = null
          _this.groupParams.maxAge = null
        }else{
          _this.groupParams.gender = _this.teamRuleForm.sexType//性别 男:F 女：M
          _this.groupParams.minAge = _this.teamRuleForm.ageSlot//年龄最小值
          if(_this.ageShow == true){
            _this.groupParams.maxAge = _this.teamRuleForm.maxAgeSlot//年龄最大值
          }else{
            _this.groupParams.maxAge = null//年龄最大值
          }
        }

        if(_this.advertRadio == '1'){
          _this.groupParams.saveAdDto = null
        }

        _this.negGroupKw.forEach(function(item,index){
          negativekeywordsDtoList.push({
            'matchType':item.matchType,
            'text':item.text
          })
        })
        _this.sureKw.forEach(function(item,index){
          groupkeywordsDtoList.push({
            'budgetAmount':{
              'amount':item.chujia,
              'currency':'RMB'
            },
            'matchType':item.matchType,
            'text':item.text
          })
        })
        _this.groupParams.status = 'PAUSED'//状态 ENABLED：启用 PAUSED：暂停 建议传暂停
        _this.groupParams.name = _this.teamRuleForm.groupName//广告组名称
        if(_this.budget >= _this.teamRuleForm.groupDefault){//从广告组列表进来的判断
          _this.groupParams.defaultBidAmount = {//默认最高每次点击付费出价
            'amount':_this.teamRuleForm.groupDefault,
            'currency':'RMB'
          }
        }
        else if(_this.ruleForm.adPlanEveryBudget >= _this.teamRuleForm.groupDefault){//从创建广告洗系列进来的判断
          _this.groupParams.defaultBidAmount = {//默认最高每次点击付费出价
            'amount':_this.teamRuleForm.groupDefault,
            'currency':'RMB'
          }
        }
        else{
          _this.$message({
            showClose: true,
            message: '默认最高出价不能大于系列预算，请重新输入',
            type: 'error'
          })
        }
        if(_this.teamRuleForm.setStartDateTime == ''){
          _this.groupParams.startTime = _this.nowDate//如果不选择开始时间，默认当天的凌晨
          // 2023-10-08T02:38:41.359Z
        }else{
          _this.groupParams.startTime = _this.teamRuleForm.setStartDateTime//开始时间
        }
        _this.groupParams.endTime = _this.teamRuleForm.setEndDateTime//结束时间
        _this.groupParams.pricingModel = 'CPC'//出价的定价模型 CPC

        if(_this.tab == 1){
          if(_this.supplySources == 'APPSTORE_SEARCH_RESULTS'){
            if(_this.teamRuleForm.groupCost != ''){
              _this.groupParams.cpaGoal = {
                'amount':_this.teamRuleForm.groupCost,
                'currency':'RMB'
              }
            }else{
              _this.groupParams.cpaGoal = null
            }
            _this.groupParams.automatedKeywordsOptIn = _this.teamRuleForm.groupMate//搜索匹配: 开：true 关：false
            _this.groupParams.negativekeywordsDtoList = negativekeywordsDtoList//否定关键词
            _this.groupParams.groupkeywordsDtoList = groupkeywordsDtoList//关键词
            //时间段
            if(_this.adAllAudience == false){
              timeStr = _this.$refs.timeStrArray.timeSection
              console.log(timeStr)
              timeStr.forEach(function(val,i){
                console.log(val,i)
                val.forEach(function(ele,j){
                  ele.forEach(function(obj,k){
                    daypart.push(24*i+obj)
                  })
                })
              })
              _this.groupParams.daypart = daypart//时间段入参
            }


          }else{
            _this.groupParams.automatedKeywordsOptIn = false
          }
          // _this.groupParams.negativekeywordsDtoList = negativekeywordsDtoList//否定关键词
          // _this.groupParams.groupkeywordsDtoList = groupkeywordsDtoList//关键词
          // //时间段
          // if(_this.adAllAudience == false){
          //   timeStr = _this.$refs.timeStrArray.timeSection
          //   console.log(timeStr)
          //   timeStr.forEach(function(val,i){
          //     console.log(val,i)
          //     val.forEach(function(ele,j){
          //       ele.forEach(function(obj,k){
          //         daypart.push(24*i+obj)
          //       })
          //     })
          //   })
          //   _this.groupParams.daypart = daypart//时间段入参
          // }
        }else{
          let supplySources = ''
          _this.getPlanData.supplySources.forEach(function(val,i){
            supplySources = val
          })
          if(supplySources == 'APPSTORE_SEARCH_RESULTS'){
            if(_this.teamRuleForm.groupCost != ''){
              _this.groupParams.cpaGoal = {
                'amount':_this.teamRuleForm.groupCost,
                'currency':'RMB'
              }
            }else{
              _this.groupParams.cpaGoal = null
            }
            _this.groupParams.automatedKeywordsOptIn = _this.teamRuleForm.groupMate//搜索匹配: 开：true 关：false
            _this.groupParams.negativekeywordsDtoList = negativekeywordsDtoList//否定关键词
            _this.groupParams.groupkeywordsDtoList = groupkeywordsDtoList//关键词
            //时间段
            if(_this.adAllAudience == false){
              timeStr = _this.$refs.timeStrArray.timeSection
              console.log(timeStr)
              timeStr.forEach(function(val,i){
                console.log(val,i)
                val.forEach(function(ele,j){
                  ele.forEach(function(obj,k){
                    daypart.push(24*i+obj)
                  })
                })
              })
              _this.groupParams.daypart = daypart//时间段入参
            }
          }else{
            _this.groupParams.automatedKeywordsOptIn = false
          }
          
        }
        //时间段
        // if(_this.adAllAudience == false){
        //     timeStr = _this.$refs.timeStrArray.timeSection
        //     console.log(timeStr)
        //     timeStr.forEach(function(val,i){
        //       console.log(val,i)
        //       val.forEach(function(ele,j){
        //         ele.forEach(function(obj,k){
        //           daypart.push(24*i+obj)
        //         })
        //       })
        //     })
        //     _this.groupParams.daypart = daypart//时间段入参
        //   }
        //   _this.groupParams.negativekeywordsDtoList = negativekeywordsDtoList//否定关键词
        //   _this.groupParams.groupkeywordsDtoList = groupkeywordsDtoList//关键词

        $http.axios.post("/api/apple/adGroup/saveGroup",_this.groupParams).then(res=> {
          if(res.data.code==200){
            if(_this.tab == 1){
              _this.$router.push({path:'/plan-management/adTeamApple', 
                query: { id: _this.ownerId, name:_this.ownerName ,campaignId:_this.campaignId,
                campaignName:_this.campaignName,position:_this.supplySources,appId:_this.appId,budget:_this.budget}
              })
            }else{
              let supplySources = ''
              _this.getPlanData.supplySources.forEach(function(val,i){
                supplySources = val
              })
              _this.$router.push({path:'/plan-management/adTeamApple', 
                query: { id: _this.ownerId, name:_this.ownerName ,campaignId:_this.getPlanData.id,
                campaignName:_this.getPlanData.name,position:supplySources,appId:_this.getPlanData.adamId,budget:_this.budget}
              })
            }
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            }) 
          }   
        })
      },
      // 2023-10-16T16:00:00.000Z

      //广告组最后的上一步
      backCreatePlan(){
        if(this.tab == 1){
          this.$router.push({path:'/plan-management/adTeamApple', 
            query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
            campaignName:this.campaignName,position:this.supplySources,appId:this.appId,budget:this.budget}
          })
        }else{
          let supplySources = ''
          this.getPlanData.supplySources.forEach(function(val,i){
            supplySources = val
          })
          this.$router.push({path:'/plan-management/adTeamApple', 
            query: { id: this.ownerId, name:this.ownerName ,campaignId:this.getPlanData.id,
            campaignName:this.getPlanData.name,position:supplySources,appId:this.getPlanData.adamId,budget:this.budget}
          })
        }
        // this.$router.push({path:'/plan-management/adTeamApple', 
        //   query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
        //   campaignName:this.campaignName,position:this.supplySources,appId:this.appId}
        // })
      },







      

    }
  }
  </script>
  
  <style lang="scss" scoped>
    .createTitle{
      font-size:16px;
    }
    ::v-deep .createAll .el-input__inner,::v-deep .createAll .el-input--mini{
      width:500px!important;
    }
    ::v-deep .createAll .el-form-item__label{
      width:240px!important;
      padding-left:100px;
    }
    ::v-deep .createAll .el-form-item__error{
      left:200px;
    }
    .iconTip{
      padding-left:10px;
    }
    .iconTipContent{
      width:240px;
      padding:10px;
      line-height:22px;
    }
    .negKeywords{
      width:400px;
      height:30px;
    }
    .addNegKeywords,.addNegKeywords i {
      font-style: normal;
      font-size:14px;
      font-weight:700;
    }
    .addNum{
      font-size:12px;
      color:#666;
      float:right;
    }
    .enterKeywords{
      width:100%;
      margin:20px 0;
    }
    .enterKeywords .enterKeywordsInput,.enterKeywords .enterKeywordsBtn{
      display: inline-block;
    }
    .enterKeywords .enterKeywordsInput{
      width:90%;
    }
    .enterKeywords .enterKeywordsBtn{
      width:10%;
      height:30px;
      border-radius: 30px;;
    }
    .negKeywordsLast{
      margin-top:15px;
      font-size:12px;
    }
    .negKeywordsLast i{
      font-style: normal;
    }
    ::v-deep .createAll .inner .el-input__inner, ::v-deep .createAll .inner .el-input--mini{
      width:100px!important;
    }
    /* .batchBtns{
      margin-bottom:15px;
    } */
    .editTypeChoose{
      text-align:center;
      margin-top:-36px;
    }
    .editTypeBtns{
      margin-top:30px;
      text-align:center;
    }
    .showAdCustom{
      width:370px;
      padding:50px 35px;
      margin-top:20px;
      background-color: #F6FAF9;
      box-sizing: border-box;
      text-align:center;
      color:#65A487;
    }
    :v-deep .createAll .createAdvertStyle .el-input__inner,::v-deep .createAll .createAdvertStyle .el-input--mini{
      width:100%!important;
    }
    .createAdvertBtns{
      padding-left:140px;
      margin-top:50px;
    }
    /* 新建广告组 */
    .haveAdPlan{
      background: #F1F7F5;
      padding:15px;
      font-size:14px;
      color:#999;
    }
    .haveAdPlanList02 .interval{
      padding:0 20px;
    }
    .haveAdPlanList02 i{
      font-style: normal;
      font-size:14px;
    }
    ::v-deep .createAdTeamKeyword .el-input__inner,::v-deep .createAdTeamKeyword .el-input--mini{
      width:400px;
    }
    ::v-deep .createAdTeamKeyword .ageage .el-input__inner,::v-deep .createAdTeamKeyword .ageage .el-input--mini{
      width:190px;
    }
    ::v-deep .createAdTeamKeyword .el-form-item__label{
      width:240px!important;
      padding-left:100px;
      color:#333;
    }
    ::v-deep .createAdTeamKeyword .el-form-item__error{
      left:20px;
    }
    ::v-deep .startEnd .el-checkbox__label{
      font-size:12px;
    }
    .teamKeyword .el-icon-search{
      font-size:16px;
    }
    .teamKeywordLeft{
      width:40%;
      box-sizing: border-box;
      border-right:1px solid #ddd;
      padding-right:20px;
    }
    ::v-deep .teamKeywordLeft .el-input__inner,::v-deep .teamKeywordLeft .el-input--mini{
      width:300px;
    }
    ::v-deep .innerKw .el-input__inner,::v-deep .innerKw .el-input--mini{
      width:110px;
    }
    .budgetStyle{
      font-size:12px;
      color:#999;
      padding-left:15px;
    }
    ::v-deep .createAdName .el-input__inner,::v-deep .createAdName .el-input--mini{
      width:100%;
      height:40px!important;
      line-height:40px!important;
      font-size:16px;
    }
    .createAdName{
      margin-bottom: 15px;
    }
    .createAdList{
      border:1px solid #efefef;
      padding:10px;
      box-sizing: border-box;
    }
    .createAdLength{
      font-size:12px;
      color:#999;
      margin:10px 0;
    }
    .createAdDetailList{
      width:100%;
      margin:10px 0;
    }
    .adimgList{
      overflow: hidden;
      padding:15px 20px 10px;
      background:#fff;
      border-radius:10px;
    }
    .createAdimgList{
      overflow: hidden;
      width:100%;
    }
    .createAdimg{
      width:100px;
      display:inline-block;
      vertical-align:top;
    }
    .createAdimg img{
      width: 100%;
    }
    .describe{
      width:300px;
      display:block;
      white-space:nowrap;
      overflow:hidden;
      text-overflow:ellipsis;
      font-size:12px;
      color:#999;
      margin-top:0;
    }
    .advertName h3{
      font-size:14px;
      font-weight:700;
      margin-bottom:0;
    }
    /* .radioList .el-radio.is-bordered + .el-radio.is-bordered{
      margin-left:0;
    } */
    /*客户端-新版*/
    .createAdverts{
      padding-bottom:10px;
      border-bottom:1px solid #F1F7F5;
      margin-bottom:20px;
    }
    .createAdverts span{
      font-size:14px;
      font-weight:700;
      color:#333;
      padding-left:13px;
      border-left:3px solid #48916F;
    }
    ::v-deep .createAll .el-input--mini .el-input__inner{
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .createAdTeamKeyword .el-input--mini .el-input__inner{
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .createAdTeamKeyword .el-switch.is-checked .el-switch__core{
      background:#48916F!important;
      border:none!important;
    }
    .budgetOrder{
      width:40%;
      height: 40px;
      line-height:40px;
      background: linear-gradient(270deg, #FFFFFF 0%, #F2F8F6 100%);
      padding-left:20px;
      margin-bottom:15px;
    }
    .budgetOrder span{
      font-size:14px;
      color:#65A487;
      font-weight:400;
    }
    ::v-deep .createAll .el-form-item--mini .el-form-item__label{
      color:#333!important;
    }
    .kwIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    ::v-deep .kwWrap .el-dialog .el-dialog__header{
      padding-top:40px;
      font-size:18px;
      font-weight:700;
    }
    ::v-deep .kwWrap .el-dialog--center .el-dialog__body{
      padding-top:0;
    }
    .addKwTeam,.addKwTeamBtn{
      display: inline-block;
      vertical-align: middle;
    }
    .addKwTeam{
      width:40%;
      height: 36px;
      line-height:36px;
      background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
      border-radius: 10px 0px 0px 0px;
      padding-left:18px;
      box-sizing: border-box;
    }
    .addKwTeamBtn{
      text-align:right;
      width:60%;
    }
    .addKwInput{
      width:100%;
      padding:15px 0;
      background: #005E3F;
      border-radius: 7px;
      text-align:center;
    }
    ::v-deep .addKwInput .el-input--mini .el-input__inner{
      border-radius:30px;
    }
    ::v-deep .priceMax .el-input__inner{
      width:50px;
    }
    .kwAddNumBtn{
      display: inline-block;
      vertical-align: middle;
    }
    .kwAddNumBtn i{
      font-size:18px;
      font-weight:700;
      color:#48916F;
      cursor: pointer;
      margin-left:10px;
    }
    .kwdelete{
      font-size:16px;
      font-weight:700;
      color:#FF8080;
      cursor: pointer;
    }
    ::v-deep .smallDialog .el-popup-parent--hidden .el-dialog__body, #app .el-dialog__body{
      margin:0!important;
      padding:0!important;
    }
    .radioListAdvert{
      font-size:0;
    }
    ::v-deep .radioListAdvert .el-radio--mini.is-bordered{
      font-size:12px;
      width:31.5%;
      margin:0!important;
      margin:10px!important;
      border:none;
      background:#F5F7F6;
      border-radius:10px;
    }
    /* .advertDialog{
      position: relative;
    } */
    ::v-deep .advertDialog .el-dialog{
      position: relative;
      height:700px;
      overflow: scroll;
    }
    ::v-deep .loadingBg .el-dialog{
      margin-top:200px;
      background:rgba(255,255,255,0)!important;
      box-shadow: none;
    }
    .loading{
      text-align:center;
    }
    .loading span,.loading i{
      display: block;
    }
    .loading span{
      font-size:20px;
      font-weight:700;
      color:#fff;
    }
    .loading i{
      margin-top:10px;
      font-size:60px;
      /* color: #409eff; */
      color:#fff;
    }

  </style>
