<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>系统管理</el-breadcrumb-item>
              <el-breadcrumb-item>人员管理</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <span v-show="['develop','admin'].includes(roleKeyqx)" >
              <el-button type="primary" size="small" @click="staffAddList" >添加人员</el-button>
            </span>
          </div>
        </div>
        <!-- 公司的部门 -->
        <!-- <div class="departmentHeader">
          <span class="role_headerTitles">部门管理</span>
          <div class="staffManageList">
            <div class="staffNames">
              <div class="staffListLeft">
                <span class="el-icon-arrow-left staffbmRetuen" @click="goBack"></span>
                <span class="componyName">{{companyName}}</span>
                <span></span>
              </div>
              <div class="staffAdd_btn">
                <el-button type="primary" size="small" @click="clickShowDept" class="addbtntd" style="float:right;">添加部门</el-button>
                <el-dialog title="添加部门" :visible.sync="dialogFormVisible_staff" width="300px">
                  <el-select v-model="qdvalue" :placeholder="choiceChannel" style="width:100%;" @change="changeChannel($event)">
                    <el-option
                      v-for="(item,i) in qdoptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                  <el-input
                    v-model="params.deptName"
                    placeholder="请输入部门名称"
                    style="margin-top:20px;">
                  </el-input>
                  <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="dialogFormVisible_staff = false">取 消</el-button>
                      <el-button type="primary" @click="bmManages">确 定</el-button>
                    </span>
                  </template>
                </el-dialog>
              </div>
            </div>
            <ul class="staffList">
              <li style="border-bottom:1px solid #eee;padding:10px 0;"></li>
              <li v-for="(item,i) in compony" :key="i" >
                <span class="staffbmName">{{ item.label }}
                  <i v-if="item.children">({{ item.children.length }})</i>
                  <i v-else>({{ 0 }})</i>
                </span>
                <span class="staffbmModify">
                  <span>
                    <span class="el-icon-edit staffxg" @click="xgDeptName(i)" v-if="['develop','admin'].includes(roleKeyqx)"></span>
                    <span class="el-icon-edit staffxg" v-else style="display: none;"></span>
                  </span>
                  <span v-show="['develop','admin'].includes(roleKeyqx)">
                    <el-popconfirm
                      title="确定删除部门吗？"
                      @confirm="deleteCate(i)">
                    <el-button slot="reference" type="text" class="el-icon-delete-solid staffDelete" v-if="item.children" style="display:none;font-size:18px;"></el-button>
                    <el-button slot="reference" type="text" style="font-size:18px;" class="el-icon-delete-solid staffDelete" v-else></el-button>
                  </el-popconfirm>
                </span>
                  <span class="staffNext el-icon-arrow-right" @click="componyList(i,item.id)"></span>
                </span>
              </li>
            </ul>
          </div>
        </div> -->
        <!-- 公司人员 -->
        <div class="staffHeader">
          <h3 class="staffDetailTitle"><i></i><span>人员详情</span></h3>
          <div class="staffheadercx">
            <div class="staffCx">
              <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="" prop="staffNicname">
                  <el-select v-model="ruleForm.staffNicname" filterable clearable placeholder="请选择人员" @change="changeStaff($event)">
                    <el-option
                    v-for="(item,i) in nickNameOptions"
                    :key="i"
                    :label="item.name"
                    :value="item">
                  </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="staffphonenumber">
                  <el-input v-model="ruleForm.staffphonenumber" placeholder="请输入手机号码"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" class="newQuery" size="small" @click="deptUserList(1)">查询</el-button>
                  <el-button type="info" size="small" @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="newAddHeader">
              <el-dialog 
                title="人员添加"
                :visible.sync="dialogFormVisible"
                width="500px"
                center
                :show-close = "false">
                <!-- <div class="dialogIcon"><img :src='xiuGaiIcon'></div> -->
                <el-form :model="stffForm" ref="stffForm" :rules="rules">
                  <!-- <h3 style="font-size:14px;color:#333;">人员基本信息</h3> -->
                  <el-form-item label="人员姓名" :label-width="formLabelWidth" prop="staffSnameValue">
                    <el-input v-model="stffForm.staffSnameValue" autocomplete="off" placeholder="请输入人员姓名"></el-input>
                  </el-form-item>
                  <el-form-item label="手机号码" :label-width="formLabelWidth" prop="staffSphoneValue">
                    <el-input v-model="stffForm.staffSphoneValue" autocomplete="off" placeholder="请输入手机号码"></el-input>
                  </el-form-item>
                  <el-form-item label="E-mail" :label-width="formLabelWidth" prop="staffSemailValue">
                    <el-input v-model="stffForm.staffSemailValue" autocomplete="off" placeholder="请输入邮箱地址"></el-input>
                  </el-form-item>
                  <el-form-item label="部门职位" :label-width="formLabelWidth" prop="staffSpostValue" >
                    <el-select v-model="stffForm.staffSpostValue" filterable placeholder="请选择部门职位" @change="changePost($event)">
                      <el-option
                        v-for="(item,i) in listpostOptions"
                        :key="i"
                        :label="item.postName"
                        :value="item.postId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item 
                    label="所属部门" 
                    prop="fpDeptValue" 
                    filterable 
                    :label-width="formLabelWidth">
                    <el-cascader
                      v-model="stffForm.fpDeptValue"
                      :options="deptTree"
                      :props="props"
                      :show-all-levels="false"
                      placeholder="请选择所属部门"
                      @change="changeDept($event)">
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="角色权限" filterable :label-width="formLabelWidth" prop="staffSroleValue">
                    <el-select v-model="stffForm.staffSroleValue" filterable multiple placeholder="请选择角色名称,可多选" @change="changeRole($event)">
                      <el-option
                        v-for="(item,i) in listRoleOptions"
                        :key="i"
                        :label="item.roleName"
                        :value="item.roleId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="工作地点" :label-width="formLabelWidth">
                    <el-select v-model="staffSaddressValue" filterable placeholder="请选择工作地点" @change="changePlace($event)">
                      <el-option
                        v-for="(item,i) in listAddressOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="入职时间" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="staffStimeValue"
                      type="date"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="请选择入职时间">
                    </el-date-picker>
                  </el-form-item>
                  <!-- <h3 style="font-size:14px;color:#333;">设置平台使用账户</h3> -->
                  <el-form-item label="个人账号" :label-width="formLabelWidth" prop="staffSunValue">
                    <el-input v-model="stffForm.staffSunValue" autocomplete="off" placeholder="请输入平台账号"></el-input>
                  </el-form-item>
                  <el-form-item label="账户密码" :label-width="formLabelWidth">
                    <span style="color:#666;">{{mrPassWord}}(初始密码)</span>
                  </el-form-item>
                </el-form>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button type="primary" class="newDialogCancel" @click="dialogFormVisible = false">取消保存</el-button>
                    <el-button type="primary" class="newDialog" @click="addStaffSure('stffForm')">确认保存</el-button>
                  </span>
                </template>
              </el-dialog>
              <!-- 修改详情 -->
              <el-dialog title="修改人员详情" :visible.sync="dialogFormVisibleEdit">
                <el-form :model="stffForm" v-if="['develop','admin'].includes(roleKeyqx)">
                  <h3 style="font-size:14px;color:#333;">人员基本信息</h3>
                  <el-form-item label="人员姓名" :label-width="formLabelWidth">
                    <el-input v-model="staffSnameValueE" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="手机号码" :label-width="formLabelWidth">
                    <el-input v-model="staffSphoneValueE" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="E-mail" :label-width="formLabelWidth">
                    <el-input v-model="staffSemailValueE" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="部门职位" :label-width="formLabelWidth">
                    <el-select v-model="staffSpostValueE" filterable :placeholder="staffSpostValueEe" @change="changePost($event)">
                      <el-option
                        v-for="(item,i) in listpostOptions"
                        :key="i"
                        :label="item.postName"
                        :value="item.postId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所属部门" filterable :label-width="formLabelWidth">
                    <el-cascader
                      v-model="fpDeptValueE"
                      :options="deptTree"
                      :props="props"
                      :show-all-levels="false"
                      :placeholder="fpDeptValueEw"
                      @change="changeDept($event)"
                      style="color:#333;">
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="角色权限" filterable :label-width="formLabelWidth">
                    <el-select v-model="staffSroleValueE" filterable multiple :placeholder="staffSroleValueEE" @change="changeRoleEdit($event)">
                      <el-option
                        v-for="(item,i) in listRoleOptions"
                        :key="i"
                        :label="item.roleName"
                        :value="item.roleId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="工作地点" :label-width="formLabelWidth">
                    <el-select v-model="staffSaddressValueE" filterable :placeholder="staffSaddressValueEe" @change="changePlace($event)">
                      <el-option
                        v-for="(item,i) in listAddressOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="入职时间" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="staffStimeValueE"
                      type="date"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </el-form-item>
                  <h3 style="font-size:14px;color:#333;">设置平台使用账户</h3>
                  <el-form-item label="个人账号" :label-width="formLabelWidth">
                    <el-input v-model="staffSunValueE" autocomplete="off" placeholder="请输入平台账号"></el-input>
                  </el-form-item>
                  <el-form-item label="账户密码" :label-width="formLabelWidth">
                    <el-input v-model="staffSpwValuedE" autocomplete="off"></el-input>
                  </el-form-item>
                </el-form>
                <el-form :model="stffForm" v-else>
                  <h3 style="font-size:14px;color:#333;">人员基本信息</h3>
                  <el-form-item label="人员姓名" :label-width="formLabelWidth">
                    <el-input v-model="staffSnameValueE" autocomplete="off" :disabled="true"></el-input>
                  </el-form-item>
                  <el-form-item label="手机号码" :label-width="formLabelWidth">
                    <el-input v-model="staffSphoneValueE" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="E-mail" :label-width="formLabelWidth">
                    <el-input v-model="staffSemailValueE" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="部门职位" :label-width="formLabelWidth">
                    <el-select v-model="staffSpostValueE" filterable :placeholder="staffSpostValueEe" :disabled="true" @change="changePost($event)">
                      <el-option
                        v-for="(item,i) in listpostOptions"
                        :key="i"
                        :label="item.postName"
                        :value="item.postId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="所属部门" filterable :label-width="formLabelWidth">
                    <el-cascader
                      v-model="fpDeptValueE"
                      :options="deptTree"
                      :props="props"
                      :disabled="true"
                      :show-all-levels="false"
                      :placeholder="fpDeptValueEw"
                      @change="changeDept($event)"
                      style="color:#333;">
                    </el-cascader>
                  </el-form-item>
                  <el-form-item label="角色权限" filterable :label-width="formLabelWidth">
                    <el-select v-model="staffSroleValueE" :disabled="true" filterable multiple :placeholder="staffSroleValueEE" @change="changeRoleEdit($event)">
                      <el-option
                        v-for="(item,i) in listRoleOptions"
                        :key="i"
                        :label="item.roleName"
                        :value="item.roleId">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="工作地点" :label-width="formLabelWidth">
                    <el-select v-model="staffSaddressValueE" :disabled="true" filterable :placeholder="staffSaddressValueEe" @change="changePlace($event)">
                      <el-option
                        v-for="(item,i) in listAddressOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="入职时间" :label-width="formLabelWidth">
                    <el-date-picker
                      v-model="staffStimeValueE"
                      type="date"
                      :disabled="true"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss">
                    </el-date-picker>
                  </el-form-item>
                  <h3 style="font-size:14px;color:#333;">设置平台使用账户</h3>
                  <el-form-item label="个人账号" :label-width="formLabelWidth">
                    <el-input v-model="staffSunValueE" autocomplete="off" :disabled="true" placeholder="请输入平台账号"></el-input>
                  </el-form-item>
                  <el-form-item label="账户密码" :label-width="formLabelWidth">
                    <el-input v-model="staffSpwValuedE" autocomplete="off"></el-input>
                  </el-form-item>
                </el-form>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button type="primary" @click="editStaffSure">提交</el-button>
                  </span>
                </template>
              </el-dialog>
            </div>
          </div>
          <!-- 表格中的详情弹框 -->
          <el-dialog
            title="人员详情"
            class="personDetail"
            :visible.sync="centerDialogVisible"
            width="500px"
            :show-close = "false"
            center>
            <!-- <div class="dialogIcon"><img :src='xiuGaiIcon'></div> -->
              <div class="staff_details">
                <!-- <h3 class="staff_detail_title">设置平台账户</h3> -->
                <ul class="staff_detail_list newStaffList">
                  <li>
                    <label>人员姓名</label>
                    <label>{{staffDetailData.nickName}}</label>
                  </li>
                  <li>
                    <label>手机号码</label>
                    <label>{{staffDetailData.phonenumber}}</label>
                  </li>
                  <li>
                    <label>E-mail</label>
                    <label>{{staffDetailData.email}}</label>
                  </li>
                  <li>
                    <label>部门职位</label>
                    <label>{{staffPostData}}</label>
                  </li>
                  <li>
                    <label>所属部门</label>
                    <label>{{staffDeptlData.deptName}}</label>
                  </li>
                  <li>
                    <label>角色权限</label>
                    <label>
                      <span v-for="item in staffDetailData.roles">
                        {{item.roleName}}
                      </span>
                    </label>
                  </li>
                  <li>
                    <label>直属上级</label>
                    <label>{{staffDeptlData.leader}}</label>
                  </li>
                  <li>
                    <label>工作地点</label>
                    <label>{{staffDetailData.place}}</label>
                  </li>
                  <li>
                    <label>入职时间</label>
                    <label>{{staffDetailData.createTime}}</label>
                  </li>
                </ul>
                <!-- <h3 class="staff_detail_title">设置平台账号</h3> -->
                <ul class="staff_detail_list">
                  <li>
                    <label>个人帐户</label>
                      <label>{{staffDetailData.userName}}
                        <span class="password_modify" @click="newPassword" v-show="['develop','admin'].includes(roleKeyqx)">密码重置</span>
                      </label>
                  </li>
                  <li v-if="userIdSelf == staffDetailData.userId">
                    <label>修改密码</label>
                    <label>
                      <el-input v-model="changePassword" placeholder="修改密码" style="width:50%"></el-input>
                      <div class="editNewPw" @click="changeSuccess"><i class="el-icon-success"></i></div>
                    </label>
                  </li>
                </ul>
              </div>
            <template #footer>
            <span class="dialog-footer">
              <el-button type="primary" class="newDialog" @click="centerDialogVisible = false">关 闭</el-button>
            </span>
            </template>
          </el-dialog>

          <el-dialog
            title="提示"
            :visible.sync="passwordDialog"
            width="20%">
            <span>密码重置已成功</span>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="passwordDialog = false">确 定</el-button>
            </span>
          </el-dialog>

          <div class="staffTable">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%">
              <el-table-column
                type="selection"
                :disable="isdisable"
                width="70">
              </el-table-column>
              <el-table-column
                prop="staffmz"
                label="人员姓名"
                width="170">
                <template slot-scope="scope">{{scope.row.nickName}}</template>
              </el-table-column>
              <el-table-column
                prop="bmzw"
                label="部门职位"
                width="170">
                <template slot-scope="scope">
                  <span v-for="item in scope.row.posts">{{item.postName}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="phNum"
                label="手机号码"
                width="170">
                <template slot-scope="scope">{{scope.row.phonenumber}}</template>
              </el-table-column>
              <el-table-column
                prop="email"
                label="E-mail"
                width="170">
                <template slot-scope="scope">{{scope.row.email}}</template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="使用状态"
                width="170">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.status"
                    active-value="0"
                    inactive-value="1"
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    v-if="['develop','admin'].includes(roleKeyqx)"
                    @change="editStatus(scope.row)">
                  </el-switch>
                  <el-switch
                    v-model="scope.row.status"
                    active-value="0"
                    inactive-value="1"
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    v-else
                    disabled
                    @change="editStatus(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="操作">
                <template slot-scope="scope">
                  <el-button @click="staffDetail(scope.row.userId)" class="solid-tag-success" plain size="mini">详情</el-button>
                  <span v-show="['develop','admin'].includes(roleKeyqx)">
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deleteStaff(scope.row.userId)">
                      <template #reference>
                        <el-button class="newBtn_delRed" plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="footer_paging" style="clear:both!important;">
          <div class="footer_totalNum" style="clear:both!important;">
            <div class="footerPaging" style="clear:both!important;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
              </el-pagination>
            </div>
          </div>
        </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <accessDialog :visible.sync="showDialog" ></accessDialog>
  </div>
</template>

<script>
import xiuGaiIcon from '@/assets/img/xiuGaiIcon.png';
import accessDialog from '@/views/dialog/accountDialog';
import { $http } from '@/api/http';
import Api from "@/api";
import config from '@/api/config';
export default {
  name: 'authority',
  components: {
    accessDialog
  },
  data() {
    return {
      xiuGaiIcon,
      showDialog: false,
      // 添加部门
      dialogFormVisible: false,
      dialogFormVisible_staff: false,
      dialogFormVisibleEdit:false,
      qdoptions:[],
      qdvalue:'',
      stffForm: {
        staffSnameValue:'',
        staffSphoneValue:'',
        staffSemailValue:'',
        staffSpostValue:'',
        fpDeptValue:'',
        staffSroleValue:[],
        // staffSleadernameValue:'',
        staffSunValue:'',
      },
      rules: {
        staffSnameValue: [
          { required: true, message: '请输入人员姓名', trigger: 'blur' },
        ],
        staffSphoneValue: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
        ],
        staffSemailValue: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
        ],
        staffSpostValue: [
          { required: true, message: '请选择部门职位', trigger: 'change' }
        ],
        fpDeptValue: [
          { required: true, message: '请选择所属部门', trigger: 'change' }
        ],
        staffSroleValue: [
          { required: true, message: '请选择角色名称', trigger: 'change' }
        ],
        // staffSleadernameValue: [
        //   { required: true, message: '请选择直属上级', trigger: 'change' }
        // ],
        staffSunValue: [
          { required: true, message: '请输入账号名称', trigger: 'blur' },
        ]
      },
      add_stffForm:{
        name:''
      },
      formLabelWidth: '90px',
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        conditions:''
      },
      ruleForm:{
        staffNicname:'', // 部门人员姓名
        staffpostIds:'',//部门职位
        staffUseSatus:'', //使用状态
        staffphonenumber:'',//手机号码
      },
      nickNameOptions:[],
      passwordDialog:false,
      
      //部门人员列表
      tableData: [{
        staffmz: '',
        bmzw: '',
        phNum: '',
        email: '',
        status:''
      }],
      isdisable:false,//部门人选复选
      multipleSelection: [],
      // staffvalue: '',
      // 分页
      pageNum: 1,//当前页
      pageSize: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      centerDialogVisible: false,
      params:{
        deptName:'',
        parentId:'',
        parentName:'',
        channelId:''
      },
      compony:[], // 部门管理列表
      companyName:'',
      deleteDept:false, // 部门删除
      deleteParams:{
        deptId:''
      },
      companyId:'',
      listParams:{
        parentId:''
      },
      putParams:{
        deptName:'',
        deptId:'',
        parentId:'',
        channelId:''
      },
      qb:false, //判断确定新增部门还是修改部门
      staffParams:{ //添加人员弹框参数
        nickName:'', // 人员姓名
        phonenumber:'', //手机号码
        email:'', //e-mail
        postId:'', //部门职位
        deptId:'',
        roleIds:[],
        createTime:'',//入职时间
        place:'',//工作地点
        userName:'',//个人帐户
        password:''
      },
      editstaff:{ //修改员弹框参数
        nickName:'', // 人员姓名
        phonenumber:'', //手机号码
        email:'', //e-mail
        postId:'', //部门职位
        deptId:'',
        roleIds:[],
        createTime:'',//入职时间
        place:'',//工作地点
        userName:'',//个人帐户
        password:'',
        userId:''
      },
      // staffSnameValue:'',
      // staffSphoneValue:'',
      // staffSemailValue:'',
      // staffSpostValue:'',
      // staffSdeptValue:'',
      // staffSroleValue:[],
      // staffSleadernameValue:'',
      staffSaddressValue:'',
      staffStimeValue:'',
      // staffSunValue:'',
      staffSpwValued:'',

      staffSnameValueE:'',
      staffSphoneValueE:'',
      staffSemailValueE:'',
      staffSpostValueE:'',
      staffSdeptValueE:'',
      staffSroleValueE:[],
      staffSroleValueEE:'',
      staffSroleValuePlaceholder:[],
      // staffSleadernameValueE:'',
      staffSaddressValueE:'',
      staffStimeValueE:'',
      staffSunValueE:'',
      staffSpwValuedE:'',
      fpDeptValueE:'',
      fpDeptValueEw:'',

      staffdetailParams:{
        userId:''
      },
      staffListParams:{ //部门人员查询参数
        nickName: '',
        // status: '',
        phonenumber: '',
        // postId: '',
        // postName:''
      },
      querySStaff:false,
      pwParams:{//密码重置

      },
      staffDetailData:[],
      staffnameShow:'',
      staffpostShow:'',
      phonenumbershow:'',
      emailShow:'',
      deptNameShow:'',
      jurisdictionShow:'',
      jurisdictionShowArr:[],
      leaderShow:'',
      placeShow:'',
      createTimeShow:'',
      userNameShow:'',
      czParams:{
        userId:''
      },
      postTableData:[],//职位列表
      role_tableData:[],//角色列表
      address_tableData:[],//地点列表
      listDept:{ //获取部门的信息
        deptId:'',
        deptName:'',
        parentId:'',
        parentName:''
      },
      listRole:{
        roleId:'',
        roleName:''
      },
      listAddress:{
        place:'',
        id:''
      },
      listDeptOptions:'',
      listpostTopOptions:[],
      listpostOptions:[],
      listRoleOptions:[],
      listAddressOptions:[],
      sjoptions:[],
      // stffForm:{
      //   fpDeptValue:'',
      // },
      
      props:{
        value:'id',
        label:'label',
        children:'children',
        emitPath:false,
        checkStrictly: true
      },
      deptTree:[],
      userListParams:{
        pageSize:'',
        pageNum:''
      },
      mrPassWord:'Hljy000000',
      flag:0,//部门下一级
      componyParent:[],
      staffDeptlData:[],
      roleValueE:[],
      changeStatusParams:{
        userId:'',
        status:''
      },
      staffSpostValueEe:'',
      staffSaddressValueEe:'',
      staffPostData:'',
      choiceChannel:'',
      roleKeys:[],
      roleKeyqx:'',
      loginUserId:'',
      changePassword:'',//修改密码
      changePasswordParams:{
        userId:'',
        password:'',
        loginName:''
      },
      userIdSelf:''



    }
  },
  computed: {},
  mounted() {
    this.userIdSelf = this.$sessionStorage.getItem(config.USERID) || 1
    this.deptList();//部门列表展示
    this.deptUserList();//  部门人员列表展示
    this.getPostList();//职位列表
    this.getRoleList();//角色列表
    this.getAddressList();//地点列表
    this.getChannelList()
    this.getDeptTree()
    this.getleaderList()
    this.getStaffDetail()
    console.log(this.userIdSelf)
  },
  methods: {
    //返回上一级
    goBack(){
      if(this.flag > 0){
        this.flag--
        console.log(this.flag)
        this.compony = this.componyParent[this.flag]
      } else {
        return
      }   
    },

    //修改使用状态
    editStatus(row){
      console.log(row)
      let that = this
      if(row.status == 1){
        that.changeStatusParams.status = '1'        
      }else{
        that.changeStatusParams.status = '0'
      }
      that.changeStatusParams.userId = row.userId
        $http.axios.put("/api/system/user/changeStatus",that.changeStatusParams).then(function(response) {
          if(response.data.code == 200){
            console.log(response)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
    },

    //获取渠道列表
    getChannelList(){
      let that = this
      $http.axios.get("/api/system/user/getChannelList").then(function(response) {
        if(response.data.code == 200){
          that.qdoptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取职位列表
    getPostList() {
      let that = this
      $http.axios.get("/api/system/post/optionselect").then(function(response) {
        if(response.data.code == 200){
          that.listpostOptions = response.data.data
          that.listpostTopOptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取批量分配中的分配部门下拉框
    getDeptTree(){
        let that = this
        $http.axios.get("/api/system/dept/treeselect").then(function(response) {
          if(response.data.code == 200){
            that.deptTree = response.data.data[0].children
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

    // 获取角色列表
    getRoleList() {
      let that = this
      $http.axios.get("/api/system/role/optionselect").then(function(response) {
        if(response.data.code == 200){
          that.listRoleOptions = response.data.data
        }  else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取地点列表
    getAddressList() {
      let that = this
      $http.axios.get("/api/system/user/getPlaceList").then(function(response) {
        if(response.data.code == 200){
          that.listAddressOptions = response.data.data
        }  else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取直属上级
    getleaderList(){
      let that = this
      $http.axios.get("/api/system/user/getLeaderList").then(function(response) {
        if(response.data.code == 200){
          that.sjoptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取部门人员--请选择人员
    changeStaff(event){
      this.staffListParams.nickName = event.name
      this.ruleForm.staffNicname = event.name
    },

    //部门人员查询的职位详情
    changeTopPost(event){
      // this.staffListParams.postId = event.postId
      // this.staffListParams.postName = event.postName
      this.ruleForm.staffpostIds = event.postName
    },

    //部门人员查询--使用状态
    changeTopStatue(event){
      this.staffListParams.status = String(event)
    },

    // 部门人员查询
    // queryStaff(){
    //   let that = this
    //   let phonenumber = that.ruleForm.staffphonenumber
    //   // that.staffListParams.phonenumber = that.ruleForm.staffphonenumber
    //   let nickName = that.staffListParams.nickName
    //   $http.axios.get("/api/system/user/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum+"&phonenumber="+phonenumber).then(function(response) {
    //     if(response.data.code == 200){
    //       that.totalRow = response.data.total
    //       that.tableData = response.data.rows
    //     } else {
    //       that.$message({
    //         showClose: true,
    //         message: response.data.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },

    //重置
    resetForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
      // this.deptUserList()
      this.$router.go(0)
    },
    
    //点击部门列表
    componyList(i,classId){
      console.log(i)
      console.log(classId)
      this.flag++
      console.log(this.flag)
      this.params.parentId = this.compony[i].id
      this.params.parentName = this.compony[i].label
      // this.params.personNum = this.compony[i].personNumx
      // if(this.compony[i].children){
      //   this.listParams.parentId = this.compony[i].id
      //   this.compony = this.compony[i].children
      // }
      this.listParams.parentId = this.compony[i].id
      this.componyParent.push(this.compony)
      this.compony = this.compony[i].children
    },

    //修改部门名称回显
    xgDeptName(i){
      console.log(i)
      let that = this
      that.qb = false
      let companyWrap = that.compony
      that.deleteParams.deptId = companyWrap[i].id
      
      $http.axios.get("/api/system/dept/"+that.deleteParams.deptId).then(function(response) {
        if(response.data.code == 200){
          that.dialogFormVisible_staff = true
          that.params.deptName = response.data.data.deptName 
          that.choiceChannel = response.data.data.channelName
          that.putParams.channelId = response.data.data.channelId
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    changeChannel(event){
      this.params.channelId = event
      this.putParams.channelId = event
    },

    //增加部门
    bmManages() {
      let that = this
      let deptnames = that.params.deptName
      if(that.qb == false){ //修改的时候false
        that.putParams.deptName = that.params.deptName
        that.putParams.deptId = that.deleteParams.deptId
        that.putParams.parentId = that.params.parentId
        $http.axios.put("/api/system/dept",that.putParams).then(function(response) {
          if(response.data.code == 200){
            that.dialogFormVisible_staff = false
            that.deptList()
          }else{
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        }) 
      } else {
        deptnames = ''
        // $http.put(`/api/system/dept/add`, params);
        $http.axios.put("/api/system/dept/add",that.params).then(function(response) {
          if(response.data.code == 200){
            // that.dialogFormVisible_staff = false
            // that.deptList()
            that.dialogFormVisible_staff = false
            that.deptList()
          }else{
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        }) 
        // Api.user.manageQx(that.params).then(res => {
        //   console.log(res)
        //   if(res.code == 200){
        //     that.dialogFormVisible_staff = false
        //     that.deptList()
        //   }else{
        //     that.$message({
        //       showClose: true,
        //       message: res.msg,
        //       type: 'error'
        //     })
        //   }
        // })
      }  
    },

    //初始化获取部门列表
    deptList(){
      let that = this;
      Api.user.manageHqlist(that.params).then(res => {        
        if(res.code == 200){
          that.compony = res.data[0].children;
          that.params.parentId = res.data[0].id
          that.companyName = that.params.parentName = "亨利嘉业"
          that.listDeptOptions = that.compony
        }else{
          that.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          })
        }
      })
    },

    //删除部门
    deleteCate(i){
      let that = this
      let companyWrap = that.compony
      that.deleteParams.deptId = companyWrap[i].id
      // console.log(that.deleteParams.deptId)
      $http.axios.delete("/api/system/dept/"+that.deleteParams.deptId).then(function(response) {
        that.deptList()
      })
    },

    //点击添加部门弹框
    clickShowDept(){
      this.qb = true
      this.params.deptName = ''
      this.qdvalue = ''
      this.dialogFormVisible_staff = true
      this.choiceChannel = '请选择渠道名称'
    },
    
    //部门人员列表
    deptUserList(page) {
      let that = this
      if(page == 1){
        that.pageNum = 1
      }
      that.roleKeys = that.$sessionStorage.getItem(config.ROLES)
      that.roleKeyqx = that.roleKeys.toString()
      that.loginUserId = that.$sessionStorage.getItem(config.USERID) || 1

      // that.staffListParams.phonenumber = that.ruleForm.staffphonenumber
      let phonenumber = that.ruleForm.staffphonenumber
      let nickName = that.staffListParams.nickName
      // $http.axios.get("/api/system/user/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.staffListParams).then(function(response) {
      $http.axios.get("/api/system/user/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum+"&nickName="+nickName+"&phonenumber="+phonenumber).then(function(response) {
        if(response.data.code == 200){
          that.tableData = response.data.rows
          that.totalRow = response.data.total
        }else{
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //获取人员信息
    getStaffDetail(){
      let that = this
      $http.axios.get("/api/system/user/optionselect").then(function(response) {
        that.nickNameOptions = response.data
      })
    },

    // 部门人员详情
    staffDetail(a){
      this.changePassword = ''
      let that = this
      that.centerDialogVisible = true
      $http.axios.get("/api/system/user/"+a).then(function(response) {
        if(response.data.code == 200){
          that.staffDetailData = response.data.data
          that.staffDeptlData = response.data.data.dept
          // console.log(that.staffDetailData.posts[0])
          if(that.staffDetailData.posts.length == 0){
            that.staffPostData = ''
          }else{
            that.staffPostData = that.staffDetailData.posts[0].postName
          }
          that.czParams.userId = response.data.data.userId
          console.log(that.czParams.userId)
          // that.staffPostData = that.staffDetailData.posts[0]
        }else{
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //人员删除
    deleteStaff(e){
      let that = this
      $http.axios.delete("/api/system/user/"+e).then(function(response) {
        if(response.data.code == 200){
          that.deptUserList()
        }else{
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //点击按钮添加人员
    staffAddList(){
      let that = this
      that.querySStaff = true
      that.dialogFormVisible = true
      that.stffForm.staffSnameValue = ''
      that.stffForm.staffSphoneValue = ''
      that.stffForm.staffSemailValue = ''
      that.stffForm.staffSpostValue = ''
      that.stffForm.fpDeptValue = ''
      that.stffForm.staffSroleValue = []
      // that.stffForm.staffSleadernameValue = ''
      that.staffSaddressValue = ''
      that.staffStimeValue = ''
      that.stffForm.staffSunValue = ''
      that.staffSpwValued = ''
    },
    
    //获取部门职位id
    changePost(event){
      this.staffParams.postId = event
      this.editstaff.postId = event
      console.log(this.editstaff.postId)
    },

    //获取部门id
    changeDept(event){
      this.staffParams.deptId = event
      this.editstaff.deptId  = event
    },

    //获取角色ids
    changeRole(event){
      // this.staffParams.roleIds = []
      this.staffParams.roleIds=event
    },
    changeRoleEdit(event){
      console.log(event)
      // this.editstaff.roleIds = []
      this.editstaff.roleIds=event
    },

    //获取直属上级id
    changeLeader(event){
      this.staffParams.dept.leaderId = event
      this.editstaff.dept.leaderId = event
    },

    //获取工作地点id
    changePlace(event){
      this.staffParams.place = event 
      this.editstaff.place = event
    },

    //部门人员新增
    addStaffSure(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let that = this
          that.staffParams.nickName = that.stffForm.staffSnameValue
          that.staffParams.phonenumber = that.stffForm.staffSphoneValue
          that.staffParams.email = that.stffForm.staffSemailValue
          that.staffParams.createTime = that.staffStimeValue
          that.staffParams.userName = that.stffForm.staffSunValue
          that.staffParams.password = that.mrPassWord
          $http.axios.post("/api/system/user",that.staffParams).then(function(response) {
            if(response.data.code == 200){
              that.dialogFormVisible = false
              that.deptUserList()
              that.deptList()
            }else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })
      // let that = this
      // that.staffParams.nickName = that.stffForm.staffSnameValue
      // that.staffParams.phonenumber = that.stffForm.staffSphoneValue
      // that.staffParams.email = that.stffForm.staffSemailValue
      // that.staffParams.createTime = that.staffStimeValue
      // that.staffParams.userName = that.stffForm.staffSunValue
      // that.staffParams.password = that.mrPassWord
      // $http.axios.post("/api/system/user",that.staffParams).then(function(response) {
      //   if(response.data.code == 200){
      //     that.dialogFormVisible = false
      //     that.deptUserList()
      //     that.deptList()
      //   }else {
      //     that.$message({
      //       showClose: true,
      //       message: response.data.msg,
      //       type: 'error'
      //     })
      //   }
      // })
    },

     //修改部门人员回显
     staffEdit(e){
      let that = this
      that.querySStaff = false
      that.dialogFormVisibleEdit = true
      that.editstaff.userId = e
      $http.axios.get("/api/system/user/"+e).then(function(response) {
        if(response.data.code == 200){
          that.staffSnameValueE = response.data.data.nickName
          that.staffSphoneValueE = response.data.data.phonenumber
          that.staffSemailValueE = response.data.data.email
          console.log(response.data.data.posts)
          if(response.data.data.posts.length == 0){
            that.staffSpostValueEe = ''
            console.log(that.staffSpostValueEe)
            // that.editstaff.postId = ''
          }else{
            that.staffSpostValueEe = response.data.data.posts[0].postName
            console.log(that.staffSpostValueEe)
            // that.editstaff.postId = response.data.data.posts[0].postId
          }
          that.fpDeptValueEw = response.data.data.dept.deptName
          that.roleValueE = response.data.data.roles
          console.log(that.roleValueE)
          that.staffSroleValuePlaceholder = []
          that.editstaff.roleIds = []
          that.roleValueE.forEach(function(e,i){
            that.staffSroleValuePlaceholder.push(e.roleName)
            that.staffSroleValueEE = String(that.staffSroleValuePlaceholder)
            that.editstaff.roleIds.push(e.roleId)
          })
          that.staffSaddressValueEe = response.data.data.place
          that.staffStimeValueE = response.data.data.createTime
          that.staffSunValueE = response.data.data.userName
          if(response.data.data.password){
            that.staffSpwValuedE = '******'
          }
          // that.staffSpwValuedE = response.data.data.password

          if(response.data.data.posts.length == 0){
            that.editstaff.postId = ''
          }else{
            that.editstaff.postId = response.data.data.posts[0].postId
          }
          
          that.editstaff.deptId = response.data.data.deptId
          that.editstaff.place = response.data.data.placeId
          
        }else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改部门人员
    editStaffSure(){
      let that = this
      that.editstaff.nickName = that.staffSnameValueE
      that.editstaff.phonenumber = that.staffSphoneValueE
      that.editstaff.email = that.staffSemailValueE
      that.editstaff.createTime = that.staffStimeValueE
      that.editstaff.userName = that.staffSunValueE
      // that.editstaff.postId = that.stffForm.staffSpostValue
      if(that.staffSpwValuedE == '******'){
        that.editstaff.password = ''
      }else{
        that.editstaff.password = that.staffSpwValuedE
      }
      // that.editstaff.password = that.staffSpwValuedE
      
      $http.axios.put("/api/system/user",that.editstaff).then(function(response) {
        if(response.data.code == 200){
          that.dialogFormVisibleEdit = false
          that.deptUserList()
          that.deptList()
        }else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //密码重置
    newPassword(){
      let that = this
      
      $http.axios.put("/api/system/user/resetPwd",that.czParams).then(function(response) {
        if(response.data.code == 200){
          that.passwordDialog = true
        }else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.deptUserList()
    },

    //修改密码
    changeSuccess(){
      console.log(12345)
      let that = this
      that.changePasswordParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
      that.changePasswordParams.password = that.changePassword
      that.changePasswordParams.loginName = that.$sessionStorage.getItem(config.USERNAME)
      if(that.changePassword == ''){
        that.$message({
          showClose: true,
          message: '请输入要修改的密码',
          type: 'error'
        })
      }else{
        $http.axios.put("/api/system/user/resetPwd",that.changePasswordParams).then(function(response) {
          if(response.data.code == 200){
            that.$message({
              showClose: true,
              message: '密码修改成功',
              type: 'success'
            })
            that.$router.push('/')
          }else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      }
      
    }

    //全部删除
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach(row => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    // handleSelectionChange(val) {
    //   //val 为选中数据的集合
    //   this.multipleSelection = val;

    // },

    // deleteAll(){
    //   this.deleteStaff()
    // }
  }
}
</script>

<style lang="scss" >
.role_headerTitles {
  font-size:16px;
  font-weight:700;
  color:#6e7c7c;
  padding-bottom:14px;
}
.departmentHeader{
  margin-bottom:20px;
}
.staffNames{
  font-size:0;
  padding:10px;
  background:$poc-color-primary-deputy;
}
.staffListLeft,.staffAdd_btn{
  display:inline-block;
  vertical-align:middle;
  font-size:14px;
  color:#333;
}
.componyName{
  font-size:16px;
  font-weight:700;
}
.staffListLeft span{
  padding-right:5px;
}
.staffListLeft{
  width:90%;
}
.staffAdd_btn {
  width:10%;
}
.staffListLeft .staffbmRetuen{
  font-size:18px;
  font-weight:700;
  padding-right:20px;
  cursor:pointer;
}
.staffManageList{
  margin-top:10px;
}
.staffList i{
  font-style:normal;
}
.staffList{
  padding:0;
  font-size:14px;
}
.staffList li{
  padding:10px;
  border-bottom:1px solid #ddd;
}
.staffbmModify{
  float:right;
}
.staffbmModify > .staffxg,.staffbmModify > .staffNext,.staffbmModify > .staffDelete{
  font-size:16px;
  font-weight:700;
  padding:0 10px;
  color:#666;
  cursor:pointer;
}
.role_headerTitles02,.staffCx,.stafftj{
  display:inline-block;
  vertical-align:top;
}
.role_headerTitles .role_headerTitles02{
  width:9%;
  text-align:left;
}
.staffCx{
  width:80%;
}
.stafftj{
  width:14%;
  text-align:right;
}
.staffCx .el-form-item{
  display:inline-block;
  vertical-align:middle;
  width:18%;
  margin:0 1%;
}
.staffCx .el-form-item__content{
  margin:0!important;
}
.staffTable .el-table .cell{
  text-align:center;
}
/* .staffHeader{
  margin-top:50px;
} */
.stafftj .el-dialog__header{
  text-align:left;
}
.staff_details{
  text-align:center;
}
.staff_detail_list{
  padding:0;
  margin:0;
}
.staff_detail_list li{
  font-size:0;
  margin:10px 0;
}
.staff_detail_list li label{
  display:inline-block;
  vertical-align:middle;
  font-size:14px;
  color:#333;
  width:40%;
  box-sizing: border-box;
  padding:0 20px;
}
.staff_detail_list li label:first-child{
  text-align:right;
  padding-right:10px;
}
.staff_detail_list li label:last-child{
  text-align:left;
  padding-left:10px;
}
.staff_detail_title{
  text-align:left;
  padding-left:30%;
}
.password_modify{
  padding-left:40px;
  color:#bb371a;
  cursor:pointer;
}

/* .roleTable .el-table th{
  background:$poc-color-primary-deputy;
  color:$--color-primary;
} */
/* .footer_paging{
  margin:20px 0 4px;
}
.footer_totalNums{
  font-size:12px;
  color:#666;
  padding-left:5px;
  padding-right:20px;
}
.footerPaging {
  float:right;
} */
.choosed{
  background:red;
}
.el-card__header{
  border-bottom:0!important;
}
.addbtntdStaff{
  margin-left:10px;
  display:inline-block;
  vertical-align:middle;
}
/* 新版 */
.staffDetailTitle{
  border-bottom:1px solid #F1F7F5;
  height:50px;
  line-height:50px;
  margin:0;
  margin-bottom:20px;
}
.staffDetailTitle span{
  font-size:14px;
  color:#333;
  font-weight:500;
}
.staffDetailTitle i {
  display:inline-block;
  vertical-align:middle;
  height:18px;
  width:3px;
  background:#48916F;
  margin-right:10px;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
::v-deep .newAddHeader .el-dialog__header{
  padding-top:45px!important;
  font-size:14px!important;
  font-weight:500!important;
}
::v-deep .personDetail .el-dialog .el-dialog__title{
  padding-top:45px!important;
  /* font-size:14px!important;
  font-weight:500!important; */
}
/* .newStaffList{
  font-size:0;
}
.newStaffList li {
  display: inline-block;
  vertical-align: middle;
  font-size:12px;
  color:#333;
  width:50%;
} */
.editNewPw{
  display: inline-block;
  vertical-align: middle;
  font-size:16px;
  color:#48916F;
  cursor: pointer;
  margin-left:10px;
}
</style>

