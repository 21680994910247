<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div
          class="hl_breadCrumb hl_headerLeft"
          style="margin-bottom: 20px; margin-top: 10px"
        >
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="hl_headerLeftDrowp"
          >
            <el-breadcrumb-item :to="{ path: '/auth-management/demandOrder' }"
              >运营管理</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/auth-management/demandOrder' }"
              >需求工单</el-breadcrumb-item
            >
            <el-breadcrumb-item>创建工单</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="deputy_text_title">创建工单</div>
        <div class="submit_form_container">
          <el-form
            ref="setupOrderform"
            :model="setupOrderform"
            label-width="100px"
          >
            <el-form-item prop="demandName">
              <div slot="label"><span class="starStyle">*</span>工单名称</div>
              <el-input
                class="max_width"
                v-model="materialParams.demandName"
                value-key="materialParams.demandName"
                placeholder="请输入工单名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>起止日期</div>
              <el-date-picker
                v-model="setupOrderform.orderDate"
                value-format="yyyy-MM-dd"
                type="daterange"
                :clearable="false"
                range-separator="-"
                :start-placeholder="materialParams.startime || '开始日期'"
                :end-placeholder="materialParams.deadline || '结束日期'"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>需求日期</div>
              <el-select
                class="max_width"
                v-model="materialParams.demandCycle"
                multiple
                :placeholder="
                  demandCycleArrS ? demandCycleArrS : '请选择需求日期，可多选'
                "
              >
                <el-option
                  v-for="item in demandOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>需求人员</div>
              <el-select
                v-model="materialParams.submitUserName"
                value-key="materialParams.submitUserId"
                filterable
                :placeholder="materialParams.submitUserName"
                @change="xqpeople"
              >
                <el-option
                  v-for="(item, i) in demandNameOptions"
                  :key="i"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>账户名称</div>
              <el-select
                v-model="materialParams.acountName"
                value-key="materialParams.acountId"
                filterable
                placeholder="请选择账户名称"
                @change="zhname($event, materialParams.materialType)"
              >
                <el-option
                  v-for="(item, i) in accountOptions"
                  :key="i"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>工单类型</div>
              <el-select
                v-model="materialParams.materialType"
                value-key="materialParams.materialType"
                filterable
                placeholder="请选择工单类型"
                @change="changeGd($event)"
              >
                <el-option
                  v-for="(item, i) in gdOptions"
                  :key="i"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>制作组</div>
              <el-select
                v-model="materialParams.makerGroupId"
                value-key="id"
                filterable
                placeholder="请选择制作组"
              >
                <el-option
                  v-for="(item, i) in produceGroupOptions"
                  :key="i"
                  :label="item.groupName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <div slot="label"><span class="starStyle">*</span>素材样式</div>
              <!-- <el-select multiple placeholder="请选择资源位置"> -->
              <el-select
                class="max_width"
                v-model="resourcesNum"
                filterable
                value-key="id"
                multiple
                placeholder="请选择资源位置"
                @change="resourcesPositionFn"
              >
                <el-option
                  v-for="(item, j) in resourcesPoptions"
                  :key="j"
                  :label="`${item.resourceBits}`"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <div class="addTagsName" v-show="addTagNames">
                <!-- <el-button type="primary" plain size="small" @click="addTagDialog = true">增加标签</el-button> -->
                <el-dialog
                  title="新增标签"
                  :visible.sync="addTagDialog"
                  width="25%"
                >
                  <el-input
                    v-model="addTagsValue"
                    autocomplete="off"
                    placeholder="请输入新增标签名称"
                  ></el-input>
                  <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="addTags">新 增</el-button>
                    <el-button @click="addTagDialog = false">取 消</el-button>
                  </div>
                </el-dialog>
              </div>
              <div
                class="resourcesBox max_width"
                v-for="(value, index) in setupOrderform.resourcesPosition"
                :key="index"
              >
                <div class="resourcesResult">{{ value.typeName }}</div>
                <div class="resourceForm">
                  <el-input
                    placeholder="数量"
                    v-model="value.needNum"
                    class="resourcesNum"
                    @change="forceUpdate(value.needNum, index, 'num')"
                  ></el-input>
                  <!-- <el-select placeholder="素材标签" v-model="resourcesTab[index]" filterable value-key="id" class="resourcesTab" @change="materialTab"> -->
                  <el-select
                    placeholder="素材标签"
                    v-model="value.tagId"
                    value-key="id"
                    filterable
                    class="resourcesTab"
                    @change="forceUpdate(value.tagId, index)"
                  >
                    <el-option
                      v-for="(item, k) in resourcesTabPoptions"
                      :key="k"
                      :label="item.tagName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="素材备注">
              <!-- <el-input type="textarea" v-model="materialParams.materialDesc" placeholder="请输入素材详细说明"></el-input> -->
              <quill-editor
                ref="myQuillEditor"
                v-model="materialParams.materialDesc"
                :options="editorOption"
              />
              <!-- @change="onEditorBlur($event)" -->
              <el-upload
                class="upload-demo"
                ref="upload"
                action="/api/operationManagement/material/uploadTips"
                :headers="myHeaders"
                multiple
                :limit="10"
                :on-success="
                  (response, file, fileList) => {
                    return onSuccess1(response, file, fileList);
                  }
                "
                :show-file-list="false"
              >
                <el-button class="newBtn_darkOrange_deep" size="small"
                  >点击上传图片</el-button
                >
              </el-upload>
              <el-upload
                v-if="judgeChannel == 4 || judgeChannel == 5"
                class="upload-demo"
                ref="upload"
                action="/api/operationManagement/material/uploadTipFile"
                :headers="myHeaders"
                multiple
                :limit="10"
                :on-success="
                  (response, file, fileList) => {
                    return onSuccess2(response, file, fileList);
                  }
                "
                :show-file-list="false"
              >
                <el-button class="newBtn_darkOrange_deep" size="small"
                  >点击上传附件</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item style="margin-top: 40px">
              <el-button
                class="newDialog"
                type="primary"
                size="small"
                @click="submitMaterial(urlArr[urlData])"
                >提交</el-button
              >
              <el-button
                class="newDialogCancel"
                type="primary"
                size="small"
                @click="cancel"
                >取消</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template slot="headerGroup"> </template>
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import { accessUserId } from "@/api/http";

export default {
  name: "setupOrder",
  data() {
    return {
      setupOrderform: {
        channelName: "",
        workOrderType: "",
        workOrderName: "",
        orderDate: [],
        demandDate: [],
        demandName: "",
        accountName: "",
        resourcesType: "",
        materialDesc: "",
        resourcesPosition: [
          // {typeName:'',
          // tagId:'',
          // needNum:''}
        ],
        materialType: "",
        produceGroup: "",
      },
      qdoptions: [],
      workOrderTypeOptions: [
        {
          //工单类型
          value: "1",
          label: "图片",
        },
        {
          value: "2",
          label: "视频",
        },
      ],
      demandOptions: [
        {
          //需求日期
          value: 1,
          label: "周一",
        },
        {
          value: 2,
          label: "周二",
        },
        {
          value: 3,
          label: "周三",
        },
        {
          value: 4,
          label: "周四",
        },
        {
          value: 5,
          label: "周五",
        },
        {
          value: 6,
          label: "周六",
        },
        {
          value: 7,
          label: "周日",
        },
      ],
      makerGroupId: "",
      demandNameOptions: [],
      produceGroupOptions: [],
      accountOptions: [],
      resourcesOptions: [
        {
          //资源类型
          value: "选项1",
          label: "OPPO",
        },
        {
          value: "选项2",
          label: "今日头条",
        },
        {
          value: "选项3",
          label: "快手",
        },
      ],
      resourcesPoptions: [], //素材样式
      resourcesTabPoptions: [], //素材标签
      resourcesNum: [], //素材样式-数量
      resourcesTab: [], //素材样式-标签
      //上传附件
      dialogImageUrl: "",
      dialogVisible: false,
      materialId: [],
      materialTabId: [],
      materialParams: {
        //提交参数
        acountChannel: "",
        // materialType:'',
        demandName: "",
        startime: "",
        deadline: "",
        demandCycle: "",
        submitUserName: "",
        submitUserId: "",
        acountName: "",
        acountId: "",
        demandTypeList: [],
        materialDesc: "",
        userId: "",
        deptId: "",
        materialType: "",
        makerGroupId: "",
      },
      demandCycleArrS: "", // 需求日期
      gdOptions: [
        {
          value: 1,
          label: "图文",
        },
        {
          value: 2,
          label: "视频",
        },
      ],
      materialListParams: {
        tagType: 2,
        userId: "",
        channel: "",
      },
      userIdParams: "",
      produceChannelId: "",
      addTagDialog: false,
      addTagsValue: "",
      addtagParams: {
        channel: "",
        createUser: "",
        tagName: "",
        tagType: "",
      },
      addTagNames: false,
      demandPersonnel: "", //登录用户中文名(nickName)
      setUserId: "", //登录用户ID
      funC: "", // creatFn:创建,reviseFn:修改
      demandId: "", //修改工单回显接口入参
      urlData: 0, //0:add;1:modifyDemand
      urlArr: ["add", "modifyDemand"],
      tagName: [], //子工单标签名称
      fileList: [],
      myHeaders: { Authorization: "" },
      judgeChannel: "",
      editorOption: {},
    };
  },
  computed: {},
  mounted() {
    this.demandPersonnel = this.$sessionStorage.getItem(config.NICKNAME);
    this.setUserId = this.$sessionStorage.getItem(config.USERID) || 1;
    this.funC = this.$route.query.funC;
    this.demandId = this.$route.query.demandId;
    this.judgeChannel = this.$route.query.channel;
    if (this.funC == "reviseFn") {
      this.reviseFn();
    } else if (this.funC == "editFn") {
      this.reviseFn();
    } else {
      this.materialParams.submitUserName = this.demandPersonnel;
      this.materialParams.submitUserId = this.setUserId;
      this.getAccountList(this.materialParams.submitUserId);
    }

    this.getuserChannel();
    this.getStaffDetail();
    // this.produceGroupList();
    this.getId();
  },
  methods: {
    //失去焦点事件
    // onEditorBlur(quill) {
    //   this.faqAnswer = quill.html
    // },

    forceUpdate(update, i, num) {
      // console.log(update, i);
      let that = this;
      // that.resourcesTabPoptions=[]
      if (num == "num") {
        that.setupOrderform.resourcesPosition[i].needNum = update;
      } else {
        that.setupOrderform.resourcesPosition[i].tagId = update;
      }
    },
    getId() {
      this.myHeaders.Authorization =
        "Bearer " + this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
      this.uploadId = this.$route.params && this.$route.params.id;
      // console.log(this.uploadId);
    },

    //文件上传成功时的钩子
    onSuccess1(response, file, fileList) {
      let that = this;
      // let video = []
      if (response.code == 200) {
        if (that.materialParams.materialDesc != "") {
          that.materialParams.materialDesc += "," + response.data;
        } else {
          that.materialParams.materialDesc += response.data;
        }
        // console.log(that.materialParams.materialDesc);
      } else {
        that.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
      }
    },
    //文件上传成功时的钩子
    onSuccess2(response, file, fileList) {
      let that = this;
      // let video = []
      if (response.code == 200) {
        if (that.materialParams.materialDesc != "") {
          that.materialParams.materialDesc += "," + response.data;
        } else {
          that.materialParams.materialDesc += response.data;
        }
        // console.log(that.materialParams.materialDesc);
      } else {
        that.$message({
          showClose: true,
          message: response.msg,
          type: "error",
        });
      }
    },
    onError(err, file, fileList) {
      let that = this;
      that.$message.error(err.msg);
    },
    //修改工单回显接口
    reviseFn() {
      let that = this;
      // console.log(that.demandId);
      $http.axios
        .get("/api/operationManagement/materialDemand/toModifyDemand", {
          params: { demandId: that.demandId },
        })
        .then(function (response) {
          // console.log(response);
          if (response.data.code == 200) {
            that.materialParams = response.data.data;
            if (that.materialParams.startime && that.materialParams.deadline) {
              that.setupOrderform.orderDate = [that.materialParams.startime, that.materialParams.deadline]
            }
            // that.produceGroupList(that.materialParams.acountChannel)//
            that.getAccountList(that.materialParams.submitUserId);
            if (that.funC == "editFn") {
              that.materialParams.demandName = that.materialParams.demandName;
            } else {
              that.materialParams.demandName =
                that.materialParams.demandName + "_副本";
            }
            // that.materialParams.demandName = that.materialParams.demandName+'_副本'
            // that.produceGroupList(1)
            console.log(that.materialParams.materialType)
            console.log(response.data.data.makerGroupId)
            that.materialParams.makerGroupId = response.data.data.makerGroupId
            that.changeMaterial(that.materialParams.materialType);
            that.produceGroupList(that.materialParams.materialType)
            that.materialParams.demandDetails.forEach((item, i) => {
              that.setupOrderform.resourcesPosition.push(item);
              that.resourcesNum.push(item.type);
            });
            that.urlData = 0;
          }
        });
    },

    resourcesPositionFn(value) {
      let that = this;
      let arr7 = [];
      that.resourcesNum.forEach((obj, j) => {
        that.resourcesPoptions.forEach((item, i) => {
          if (obj == item.id) {
            // arr7.push(that.resourcesTabPoptions[i])
            arr7.push({
              typeName: that.resourcesPoptions[i].resourceBits,
              type: that.resourcesPoptions[i].id,
              tagId: that.setupOrderform.resourcesPosition[i]
                ? that.setupOrderform.resourcesPosition[i].tagId
                : "",
              tagName: that.setupOrderform.resourcesPosition[i]
                ? that.setupOrderform.resourcesPosition[i].tagName
                : "",
              needNum: that.setupOrderform.resourcesPosition[i]
                ? that.setupOrderform.resourcesPosition[i].needNum
                : "",
            });
          }
        });
      });
      that.setupOrderform.resourcesPosition = arr7;
      // console.log(value);
      // console.log(that.resourcesPoptions);
      // console.log(that.setupOrderform.resourcesPosition);
    },

    //修改后的关联到user的channel
    getuserChannel() {
      let that = this;
      let userChannel = String(that.setUserId);
      $http.axios
        .get("/api/system/user/getChannelByUser?userId=" + userChannel)
        .then(function (response) {
          if (response.data.data.length != 0) {
            // that.materialListParams.channel = response.data.data[0].id

            that.addtagParams.channel = response.data.data[0].id;
            that.materialTab();
          }
        });
    },

    //取消
    cancel() {
      this.$router.push("/auth-management/DemandOrder");
    },
    //上传附件
    handleRemove(file) {},
    //获取工单类型
    changeGd(event) {
      console.log(event)
      this.changeMaterial(event, true);
      this.materialParams.materialType = Number(event);
      this.resourcesNum = [];
      this.materialParams.makerGroupId = ''
      this.produceGroupList(event)
    },

    //素材样式接口
    changeMaterial(materialType, changeType) {
      let that = this,
        params = {};
      if (changeType == true) {
        that.setupOrderform.resourcesPosition =
          that.materialParams.demandDetails = [];
      }
      // $http.axios.post("/api/operationManagement/materialTypeSize/list?materialType="+materialType).then(res=> {
      //   if(res.data.code == 200){
      //     that.resourcesPoptions = res.data.data
      //     that.materialId = []
      //     that.addTagNames = true
      //   }  else {
      //     that.$message({
      //       showClose: true,
      //       message: res.data.msg,
      //       type: 'error'
      //     })
      //   }
      // })
      // if(that.judgeChannel == 3){
      //   $http.axios.post("/api/operationManagement/materialTypeSize/list?materialType="+materialType).then(res=> {
      //     if(res.data.code == 200){
      //       that.resourcesPoptions = res.data.data
      //       that.materialId = []
      //       that.addTagNames = true
      //     }  else {
      //       that.$message({
      //         showClose: true,
      //         message: res.data.msg,
      //         type: 'error'
      //       })
      //     }
      //   })
      // }else if(that.judgeChannel == 4){
      //   params={
      //     'channelId':that.judgeChannel,
      //     'materialType':materialType
      //   }
      //   $http.axios.post("/api/operationManagement/materialTypeSize/listByChannel" ,params).then(res=> {
      //     if(res.data.code == 200){
      //       if(that.materialParams.acountName !=''&& that.materialParams.materialType != ''){
      //         that.resourcesPoptions = res.data.data
      //       }

      //       that.materialId = []
      //       that.addTagNames = true
      //     }  else {
      //       that.$message({
      //         showClose: true,
      //         message: res.data.msg,
      //         type: 'error'
      //       })
      //     }
      //   })
      // }

      params = {
        channelId: that.judgeChannel,
        materialType: materialType,
      };
      $http.axios
        .post("/api/operationManagement/materialTypeSize/listByChannel", params)
        .then((res) => {
          if (res.data.code == 200) {
            if (
              that.materialParams.acountName != "" &&
              that.materialParams.materialType != ""
            ) {
              that.resourcesPoptions = res.data.data;
            }

            that.materialId = [];
            that.addTagNames = true;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //素材样式标签列表接口
    materialTab() {
      let that = this;
      that.materialListParams.channel = that.judgeChannel;
      that.materialListParams.userId = that.setUserId;
      $http.axios
        .post(
          "/api/operationManagement/material/taglist",
          that.materialListParams
        )
        .then((res) => {
          if (res.data.code == 200) {
            that.resourcesTabPoptions = res.data.data;
            let materialTabValue = that.resourcesTab;
            that.materialTabId = [];
            // console.log(that.setupOrderform.resourcesPosition);
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    produceGroupList(type) {
      console.log(type)
      let that = this
      $http.axios
      .get("/api/operationManagement/materialDemand/allgrouplist/"+type)
        .then((res) => {
          if (res.data.code == 200) {
            that.produceGroupOptions = res.data.data;
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    xqpeople(val) {
      this.materialParams.submitUserName = val.name;
      this.materialParams.submitUserId = val.id;
      this.setupOrderform.demandName = val.name;
      this.getAccountList(val.id);
    },

    zhname(val, type) {
      // console.log(val);
      // console.log(type);
      this.materialParams.acountName = val.name;
      this.materialParams.acountId = val.id;
      this.setupOrderform.accountName = val.name;
      // this.materialParams.acountChannel = val.channelId
      this.materialParams.acountChannel = this.judgeChannel;
      // console.log(this.materialParams.acountChannel);
      if (this.judgeChannel == 4 || this.judgeChannel == 5) {
        this.changeMaterial(type);
        // this.materialParams.acountChannel = this.judgeChannel
      }
      // this.produceGroupList(val.channelId)//需要修改
      //不需要和账户联动了
    },

    //点击提交创建新工单
    submitMaterial() {
      let that = this;
      that.materialParams.startime =
        that.setupOrderform.orderDate[0] || that.materialParams.startime;
      that.materialParams.deadline =
        that.setupOrderform.orderDate[1] || that.materialParams.deadline;
      that.materialParams.userId = that.setUserId;
      that.materialParams.deptId = that.$sessionStorage.getItem(config.DEPTID);
      that.materialParams.demandTypeList = [];
      that.materialParams.tagName = that.tagName;
      that.setupOrderform.resourcesPosition.forEach(function (e, i) {
        that.materialParams.demandTypeList.push({
          needNum: e.needNum,
          type: e.type,
          tagId: e.tagId,
        });
      });
      if (this.funC == "editFn") {
        $http.axios
          .post(
            "/api/operationManagement/materialDemand/" + that.urlArr[1],
            that.materialParams
          )
          .then((res) => {
            if (res.data.code == 200) {
              that.$router.push("/auth-management/DemandOrder");
            } else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      } else {
        $http.axios
          .post(
            "/api/operationManagement/materialDemand/" + that.urlArr[0],
            that.materialParams
          )
          .then((res) => {
            if (res.data.code == 200) {
              that.$router.push("/auth-management/DemandOrder");
            } else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          });
      }
      // $http.axios.post("/api/operationManagement/materialDemand/"+urlDate,that.materialParams).then(res=> {
      //   if(res.data.code == 200){
      //     that.$router.push('/auth-management/DemandOrder')
      //   }  else {
      //     that.$message({
      //       showClose: true,
      //       message: res.data.msg,
      //       type: 'error'
      //     })
      //   }
      // })
    },

    //获取人员信息
    getStaffDetail() {
      let that = this;
      $http.axios
        .get("/api/system/user/optionselect")
        .then(function (response) {
          that.demandNameOptions = response.data;
        });
    },

    //获取账户名称下拉列表
    getAccountList(userIdParams) {
      let that = this;
      // $http.axios.get("/api/channel/accountUser/selectList?userId="+userIdParams).then(function(response) {
      //   that.accountOptions = response.data
      // })
      if (that.judgeChannel == 3) {
        $http.axios
          .get("/api/channel/accountUser/selectList?userId=" + userIdParams +"&type=1")
          .then(function (response) {
            that.accountOptions = response.data;
          });
      } else if (that.judgeChannel == 4) {
        $http.axios
          .get("/api/viat/accountUser/selectList?userId=" + userIdParams)
          .then(function (response) {
            that.accountOptions = response.data;
          });
      } else if (that.judgeChannel == 5) {
        $http.axios
          .get("/api/xmat/accountUser/selectList?userId=" + userIdParams)
          .then(function (response) {
            that.accountOptions = response.data;
          });
      }
    },

    //新增标签
    addTags() {
      let that = this;
      that.addtagParams.createUser = that.setUserId;
      that.addtagParams.tagName = that.addTagsValue;
      that.addtagParams.tagType = 2;
      $http.axios
        .post("/api/operationManagement/material/addtag", that.addtagParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.addTagDialog = false;
            that.materialTab();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.resourcesResult,
.resourceForm,
.resourcesDelete {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1%;
}
.resourcesResult {
  width: 50%;
  padding-right: 5px;
  box-sizing: border-box;
}
.resourceForm {
  width: 45%;
  margin-right: 0;

  .el-select {
    max-width: 100%;
  }
}
.resourcesNum {
  width: 60px;
  margin-right: 5px;
}
.resourcesTab {
  width: 100px;
}
.addTagsName {
  text-align: right;
}
.upload-demo {
  margin-top: 20px;
  display: inline-block;
  margin-right: 20px;
}

.hl_headerLeft {
  width: 100%;
  margin: 0;
}
.submit_form_container {
  width: 100%;
  margin-top: 20px;
  .newBtn_darkOrange_deep {
    padding: 10px 36px;
    border-radius: 17px;
  }
}

.resourcesBox {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .resourcesTab {
    margin-top: 10px;
  }
}
</style>
