<template>
    <div>
      <iframe id="iframe" ref="iframeref" @mouseover="enter" name="mainFrame" src="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=304d0d2c-75b1-499f-8233-495345ef2e23&accessToken=1a2c1cc7f573a39b64ca1a52c4402aa8&dd_orientation=auto" width="100%" height="100%" style="position: absolute;;top:0;left:0;z-index:10;" ></iframe>
      <!-- <iframe v-if="dateIs==false" id="iframe" ref="iframeref" @mouseover="enter" name="mainFrame" :src="url" width="100%" height="100%" style="position: absolute;;top:0;left:0;z-index:10;" allowfullscreen="true"></iframe> -->
      <!-- <iframe id="iframe" ref="iframeref" @mouseover="enter" name="mainFrame" :src="url" width="100%" height="100%" style="position: absolute;;top:0;left:0;z-index:10;" allowfullscreen="true"></iframe> -->
      <!-- <i v-if="dateIs==true" class="el-icon-warning-outline">如果您是OPPO的运营，说明您的报表没有制作，请联系技术中心；
如果您是设计中心的成员，以素材为维度的报表展示正在制作当中，请等待！
如果您是项目中心、媒介中心的成员，我们正在沟通需求，尽快制作上线！</i> -->
    <!-- <i v-if="dateIs==true" class="el-icon-warning-outline">目前所有人员的看板功能都已经上线，如果您能看到这段话，说明您是新用户，您的看板没有配置，请联系技术中心解决！</i> -->
    </div>
    
  </template>
<script>
  import config from '@/api/config';
  import { $http } from '@/api/http';
  export default {
    name: 'Home',
    data() {
      return {
        dateIs:false,
        url:'',
      }
    },
    mounted() {
    },
    methods: {
      enter(event){
        console.log(this.$refs.iframeref.style.top)
        let y=event.pageY
        let y1=event.clientY
        console.log(y)
        console.log(y1)
        if(y > 50){
          this.$refs.iframeref.style.top = 0+'px'
        }else{
          this.$refs.iframeref.style.top = 60+'px'
        }
      },

  }
}
</script>
<style scoped>
.el-icon-warning-outline{
      display: block;
    margin: 200px auto;
    text-align: center;
    font-size: 24px;
}
.notice{
  padding:0 20px;
  font-size:14px;
}
.notice h3{
  text-align:center;
  font-size:22px;
  font-weight:700;
}
.notice p {
  text-indent:2em;
  font-weight:700;
}
.noticeH{
  font-weight:700;
}
.notice ul,.notice li{
  padding:0;
  margin:0;
  font-weight:400;
}
.notice li{
  margin:10px 0;
}
.noticeLast{
  text-align:right;
}
</style>
