import { render, staticRenderFns } from "./AccessList.vue?vue&type=template&id=8434a650&scoped=true&"
import script from "./AccessList.vue?vue&type=script&lang=js&"
export * from "./AccessList.vue?vue&type=script&lang=js&"
import style0 from "./AccessList.vue?vue&type=style&index=0&id=8434a650&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8434a650",
  null
  
)

export default component.exports