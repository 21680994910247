// import PublicisCommon from 'publicis-common';
import Vue from 'vue'

// const { Vue } = PublicisCommon;

export default {//弱提示
 alertMsgFn(msg,type){
  Vue.$message({
    showClose: true,
    message: msg,
    type: type
  })  
},
}