<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="chanelTab">
          <span
            class="channel_vivo"
            :class="item.id == channelId ? 'channelActive' : ''"
            v-for="(item, i) in editableTabs"
            :key="i"
            @click="item.id != channelId && clickChangeChannel(item.id)"
          >
            {{ item.name.toUpperCase() }}
          </span>
        </div>
        <!-- 按钮 -->
        <!-- xiaomi -->
        <div class="uploadBtn" v-if="xiaomi">
          <el-button
            slot="trigger"
            type="primary"
            size="small"
            class="downloadBtn"
            v-if="channelId == 5"
            style="margin-bottom: 20px"
            @click="updateMonth"
            >上传报表月数据</el-button
          >
          <el-dialog
            title="上传报表月数据"
            width="40%"
            center
            :visible.sync="uploadMonthDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择月份">
                <el-date-picker
                  v-model="uploadMonthData"
                  type="month"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  placeholder="请选择月份"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="上传文件" v-if="channelId == 5">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :http-request="autoMonthDistribution"
                  :limit="1"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >上传报表月数据</el-button
                  >
                  <i class="tip" v-if="channelId == 5">
                    文件数据为媒体提供的月账户消耗数据文件，解决账户迁移历史数据无法追溯问题</i
                  >
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="uploadClose">关 闭</el-button>
            </div>
          </el-dialog>
          <el-button
            slot="trigger"
            type="primary"
            size="small"
            class="downloadBtn"
            v-if="channelId == 4 || channelId == 5 || channelId == 6"
            style="margin-bottom: 20px"
            @click="clickBtnUpload"
            >上传报表数据</el-button
          >
          <el-dialog
            title="上传报表数据"
            width="40%"
            center
            :visible.sync="uploadDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择日期">
                <el-date-picker
                  v-model="uploadData"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="上传文件"
                v-if="channelId == 4 || channelId == 5"
              >
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :http-request="autoDistribution" 
                  :limit='1'
                  :show-file-list='false'>
                  <el-button slot="trigger" type="primary" size="small">上传报表数据</el-button>
                  <i class="tip" v-if="channelId == 5"> 请选择核代账户下【数据管理】单日导出的数据文件</i>
                  <i class="tip" v-if="channelId == 4">请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i>
                </el-upload> -->
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :before-upload="handleBeforeUpload"
                  :http-request="autoDistribution"
                  :file-list="fileList"
                  multiple
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >上传报表数据</el-button
                  >
                  <i class="tip" v-if="channelId == 5">
                    请选择核代账户下【数据管理】单日导出的数据文件</i
                  >
                  <i class="tip" v-if="channelId == 4"
                    >请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i
                  >
                </el-upload>
              </el-form-item>
              <!-- 华为 -->
              <el-form-item label="上传文件" v-if="channelId == 6">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :on-change="handleFileChange"
                  :before-upload="handleBeforeUpload"
                  :http-request="hwautoDistribution"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  multiple
                  :limit="10"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >华为上传报表数据</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="uploadClose">关 闭</el-button>
            </div>
          </el-dialog>
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="myHeaders"
            action="#"
            :http-request="yybautoDistribution"
            :limit="1"
            :show-file-list="false"
            :on-change="handleChange"
            style="
              margin-bottom: 20px;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <el-button slot="trigger" type="primary" size="small"
              >上传媒体日报文件</el-button
            >
          </el-upload>
          <el-button
            type="primary"
            size="small"
            class="downloadBtn"
            @click="sureExport"
            >下载媒体日报文件</el-button
          >
        </div>
        <!-- oppo -->
        <div class="uploadBtn" v-if="oppoType">
          <el-button
            slot="trigger"
            type="primary"
            size="small"
            class="downloadBtn"
            v-if="channelId == 4 || channelId == 5 || channelId == 6"
            style="margin-bottom: 20px"
            @click="clickBtnUpload"
            >上传报表数据</el-button
          >
          <el-dialog
            title="上传报表数据"
            width="40%"
            center
            :visible.sync="uploadDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择日期">
                <el-date-picker
                  v-model="uploadData"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="上传文件"
                v-if="channelId == 4 || channelId == 5"
              >
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :http-request="autoDistribution" 
                  :limit='1'
                  :show-file-list='false'>
                  <el-button slot="trigger" type="primary" size="small">上传报表数据</el-button>
                  <i class="tip" v-if="channelId == 5"> 请选择核代账户下【数据管理】单日导出的数据文件</i>
                  <i class="tip" v-if="channelId == 4">请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i>
                </el-upload> -->
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :on-change="handleFileChange"
                  :before-upload="handleBeforeUpload"
                  :http-request="autoDistribution" 
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  multiple
                  :limit='1'
                  :show-file-list='false'>
                  <el-button slot="trigger" type="primary" size="small">上传报表数据</el-button>
                  <i class="tip" v-if="channelId == 5"> 请选择核代账户下【数据管理】单日导出的数据文件</i>
                  <i class="tip" v-if="channelId == 4">请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i>
                </el-upload> -->
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :before-upload="handleBeforeUpload"
                  :http-request="autoDistribution"
                  :file-list="fileList"
                  multiple
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >上传报表数据</el-button
                  >
                  <i class="tip" v-if="channelId == 5">
                    请选择核代账户下【数据管理】单日导出的数据文件</i
                  >
                  <i class="tip" v-if="channelId == 4"
                    >请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i
                  >
                </el-upload>
              </el-form-item>
              <!-- 华为 -->
              <el-form-item label="上传文件" v-if="channelId == 6">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :on-change="handleFileChange"
                  :before-upload="handleBeforeUpload"
                  :http-request="hwautoDistribution"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  multiple
                  :limit="10"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >华为上传报表数据</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="uploadClose">关 闭</el-button>
            </div>
          </el-dialog>
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="myHeaders"
            action="#"
            :http-request="yybautoDistribution"
            :limit="1"
            :show-file-list="false"
            :on-change="handleChange"
            style="
              margin-bottom: 20px;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <el-button slot="trigger" type="primary" size="small"
              >上传媒体日报文件</el-button
            >
          </el-upload>
          <el-button
            type="primary"
            size="small"
            class="downloadBtn"
            @click="sureExport"
            >下载媒体日报文件</el-button
          >
        </div>
        <!-- vivo -->
        <div class="uploadBtn" v-if="vivoType">
          <el-button
            slot="trigger"
            type="primary"
            size="small"
            class="downloadBtn"
            v-if="channelId == 4 || channelId == 5 || channelId == 6"
            style="margin-bottom: 20px"
            @click="clickBtnUpload"
            >上传报表数据</el-button
          >
          <el-dialog
            title="上传报表数据"
            width="40%"
            center
            :visible.sync="uploadDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择日期">
                <el-date-picker
                  v-model="uploadData"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="上传文件"
                v-if="channelId == 4 || channelId == 5"
              >
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :http-request="autoDistribution" 
                  :limit='1'
                  :show-file-list='false'>
                  <el-button slot="trigger" type="primary" size="small">上传报表数据</el-button>
                  <i class="tip" v-if="channelId == 5"> 请选择核代账户下【数据管理】单日导出的数据文件</i>
                  <i class="tip" v-if="channelId == 4">请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i>
                </el-upload> -->
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :before-upload="handleBeforeUpload"
                  :http-request="autoDistribution"
                  :file-list="fileList"
                  multiple
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >上传报表数据</el-button
                  >
                  <i class="tip" v-if="channelId == 5">
                    请选择核代账户下【数据管理】单日导出的数据文件</i
                  >
                  <i class="tip" v-if="channelId == 4"
                    >请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i
                  >
                </el-upload>
              </el-form-item>
              <!-- 华为 -->
              <el-form-item label="上传文件" v-if="channelId == 6">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :on-change="handleFileChange"
                  :before-upload="handleBeforeUpload"
                  :http-request="hwautoDistribution"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  multiple
                  :limit="10"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >华为上传报表数据</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="uploadClose">关 闭</el-button>
            </div>
          </el-dialog>
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="myHeaders"
            action="#"
            :http-request="yybautoDistribution"
            :limit="1"
            :show-file-list="false"
            :on-change="handleChange"
            style="
              margin-bottom: 20px;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <el-button slot="trigger" type="primary" size="small"
              >上传媒体日报文件</el-button
            >
          </el-upload>
          <el-button
            type="primary"
            size="small"
            class="downloadBtn"
            @click="sureExport"
            >下载媒体日报文件</el-button
          >
        </div>
        <!-- huawei -->
        <div class="uploadBtn" v-if="huawei">
          <el-button
            slot="trigger"
            type="primary"
            size="small"
            class="downloadBtn"
            v-if="channelId == 4 || channelId == 5 || channelId == 6"
            style="margin-bottom: 20px"
            @click="clickBtnUpload"
            >上传报表数据</el-button
          >
          <el-dialog
            title="上传报表数据"
            width="40%"
            center
            :visible.sync="uploadDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择日期">
                <el-date-picker
                  v-model="uploadData"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="上传文件"
                v-if="channelId == 4 || channelId == 5"
              >
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :http-request="autoDistribution" 
                  :limit='1'
                  :show-file-list='false'>
                  <el-button slot="trigger" type="primary" size="small">上传报表数据</el-button>
                  <i class="tip" v-if="channelId == 5"> 请选择核代账户下【数据管理】单日导出的数据文件</i>
                  <i class="tip" v-if="channelId == 4">请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i>
                </el-upload> -->
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :before-upload="handleBeforeUpload"
                  :http-request="autoDistribution"
                  :file-list="fileList"
                  multiple
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >上传报表数据</el-button
                  >
                  <i class="tip" v-if="channelId == 5">
                    请选择核代账户下【数据管理】单日导出的数据文件</i
                  >
                  <i class="tip" v-if="channelId == 4"
                    >请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i
                  >
                </el-upload>
              </el-form-item>
              <!-- 华为 -->
              <el-form-item label="上传文件" v-if="channelId == 6">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :on-change="handleFileChange"
                  :before-upload="handleBeforeUpload"
                  :http-request="hwautoDistribution"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  multiple
                  :limit="10"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >华为上传报表数据</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="uploadClose">关 闭</el-button>
            </div>
          </el-dialog>
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="myHeaders"
            action="#"
            :http-request="yybautoDistribution"
            :limit="1"
            :show-file-list="false"
            :on-change="handleChange"
            style="
              margin-bottom: 20px;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <el-button slot="trigger" type="primary" size="small"
              >上传媒体日报文件</el-button
            >
          </el-upload>
          <el-button
            type="primary"
            size="small"
            class="downloadBtn"
            @click="sureExport"
            >下载媒体日报文件</el-button
          >
        </div>
        <!-- yyb -->
        <div class="uploadBtn" v-if="yyb">
          <el-button
            slot="trigger"
            type="primary"
            size="small"
            class="downloadBtn"
            v-if="channelId == 4 || channelId == 5 || channelId == 6"
            style="margin-bottom: 20px"
            @click="clickBtnUpload"
            >上传报表数据</el-button
          >
          <el-dialog
            title="上传报表数据"
            width="40%"
            center
            :visible.sync="uploadDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="选择日期">
                <el-date-picker
                  v-model="uploadData"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="请选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item
                label="上传文件"
                v-if="channelId == 4 || channelId == 5"
              >
                <!-- <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action = "#"
                  :http-request="autoDistribution" 
                  :limit='1'
                  :show-file-list='false'>
                  <el-button slot="trigger" type="primary" size="small">上传报表数据</el-button>
                  <i class="tip" v-if="channelId == 5"> 请选择核代账户下【数据管理】单日导出的数据文件</i>
                  <i class="tip" v-if="channelId == 4">请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i>
                </el-upload> -->
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :before-upload="handleBeforeUpload"
                  :http-request="autoDistribution"
                  :file-list="fileList"
                  multiple
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >上传报表数据</el-button
                  >
                  <i class="tip" v-if="channelId == 5">
                    请选择核代账户下【数据管理】单日导出的数据文件</i
                  >
                  <i class="tip" v-if="channelId == 4"
                    >请选择【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】单日导出的数据文件</i
                  >
                </el-upload>
              </el-form-item>
              <!-- 华为 -->
              <el-form-item label="上传文件" v-if="channelId == 6">
                <el-upload
                  class="upload-demo"
                  ref="upload"
                  :headers="myHeaders"
                  action="#"
                  :on-change="handleFileChange"
                  :before-upload="handleBeforeUpload"
                  :http-request="hwautoDistribution"
                  :on-success="handleSuccess"
                  :file-list="fileList"
                  multiple
                  :limit="10"
                  :show-file-list="false"
                >
                  <el-button slot="trigger" type="primary" size="small"
                    >华为上传报表数据</el-button
                  >
                </el-upload>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="uploadClose">关 闭</el-button>
            </div>
          </el-dialog>
          <el-upload
            class="upload-demo"
            ref="upload"
            :headers="myHeaders"
            action="#"
            :http-request="yybautoDistribution"
            :limit="1"
            :show-file-list="false"
            :on-change="handleChange"
            style="
              margin-bottom: 20px;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <el-button slot="trigger" type="primary" size="small"
              >上传媒体日报文件</el-button
            >
          </el-upload>
          <el-button
            type="primary"
            size="small"
            class="downloadBtn"
            @click="sureExport"
            >下载媒体日报文件</el-button
          >
        </div>
        <div
          class="uploadBtn"
          v-if="xiaomi || oppoType || vivoType || huawei || yyb || apple"
        >
          <el-upload
            class="upload-demo"
            ref="newUpload"
            :headers="myHeaders"
            action="#"
            :http-request="newUpload"
            :limit="1"
            :show-file-list="false"
            :on-change="handleChange"
            style="
              margin-bottom: 20px;
              display: inline-block;
              margin-right: 10px;
            "
          >
            <el-button slot="trigger" type="primary" size="small"
              >上传媒体日报文件(新)</el-button
            >
          </el-upload>
          <el-button
            type="primary"
            size="small"
            class="downloadBtn"
            @click="newDownload"
            >下载媒体日报文件(新)</el-button
          >
        </div>

        <div class="faqWrap" v-if="!apple">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="title" label="title" width="200">
            </el-table-column>
            <el-table-column prop="answer" label="描述"> </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" width="120">
              <template v-slot="scope">
                <span>{{ scope.row.updateTime | dateFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updater" label="修改人" width="120">
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="scope">
                <el-button
                  slot="trigger"
                  type="primary"
                  size="mini"
                  @click="editBtn(scope.row)"
                  >修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-dialog
            title="修改详情"
            width="40%"
            center
            :visible.sync="editDialogIs"
          >
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="描述">
                <el-input
                  type="textarea"
                  v-model="editAnswer"
                  placeholder="请输入具体描述"
                ></el-input>
              </el-form-item>
            </el-form>
            <div style="text-align: center">
              <el-button size="small" @click="editSure">确 定</el-button>
              <el-button size="small" @click="editClose">取 消</el-button>
            </div>
          </el-dialog>
        </div>
      </template>
    </PocCardTable>
  </div>
</template>


<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";

export default {
  name: "faq",
  data() {
    return {
      editableTabs: [
        {
          id: "3",
          name: "OPPO",
        },
        {
          id: "4",
          name: "VIVO",
        },
        {
          id: "5",
          name: "MI",
        },
        {
          id: "6",
          name: "华为",
        },
        {
          id: "7",
          name: "应用宝",
        },
        {
          id: "8",
          name: "苹果",
        },
      ],
      totalFaqList: [], //列表集合
      addTypeVisible: false, //新建分类的弹框
      addTypeName: "", //新建分类的value
      updateTypeVisible: false, //修改分类的弹框
      updateTypeName: "", //修改分类的value
      updateTypeId: "", //修改分类的id获取
      tableData: [],
      typeId: "",
      status: false,
      roleKeys: "",
      form: {
        keyword: "",
      },
      idPart: "",
      menuActive: "1-1",
      channelId: "",
      urlList: {
        3: "report",
        4: "vivoReport",
        5: "miReport",
        6: "hwReport",
        7: "yybReport",
      }, //各个渠道域名
      urlListName: {
        3: "OPPO",
        4: "VIVO",
        5: "小米",
        6: "华为",
        7: "应用宝",
        8: "苹果",
      }, //各个渠道域名
      myHeaders: { Authorization: "" },
      uploadDialogIs: false,
      uploadData: "", //上传数据是的选择日期
      uploadMonthData: "", //上传月数据是的选择日期
      form: {},
      editDialogIs: false,
      editAnswer: "",
      editAnswerContent: "",
      editAnswerId: "",
      kpiDate: "",
      fileList: [],
      files: [],
      fileName: [],
      uploadDataNow: "",
      deptId: "",
      xiaomi: false,
      oppoType: false,
      vivoType: false,
      huawei: false,
      apple: false,
      yyb: false,
      validFileList: [], // 通过校验的文件列表
      uploadMonthDialogIs: false,
    };
  },
  mounted() {
    this.roleKeys = String(this.$sessionStorage.getItem(config.ROLES));
    this.typeId = this.$route.query.id;
    this.channelId = this.$route.query.channelId;
    this.nickName = this.$sessionStorage.getItem(config.NICKNAME);
    this.getCurrentTime();
    this.getDay(-1);
    this.myHeaders.Authorization =
      "Bearer " + this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
    // this.uploadData = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() -1)
    this.uploadData = this.getDay(-1);
    // console.log(this.uploadData)
    this.uploadDataNow =
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate();
    this.uploadMonthData =
      new Date().getFullYear() + "-" + new Date().getMonth();
    this.getRole();
    if (this.channelId == undefined || this.channelId == 3) {
      this.channelId = 3;
    }
    if (this.channelId == 6) {
      this.huawei = true;
    }
    // this.tableList(this.channelId)
  },
  methods: {
    getCurrentTime() {
      // 获取当前时间并打印
      var _this = this;
      const yy = new Date().getFullYear();
      // const mm = new Date().getMonth() + 1 < 10 ? '0' + new Date().getMonth() : new Date().getMonth()
      const mm =
        new Date().getMonth() + 1 < 10
          ? "0" + (new Date().getMonth() + 1)
          : new Date().getMonth() + 1;
      const dd =
        new Date().getDate() < 10
          ? "0" + new Date().getDate()
          : new Date().getDate();
      _this.gettime = yy + "-" + mm + "-" + dd;
    },

    // 获取当前时间，day为number，getDay(-1):昨天的日期;getDay(0):今天的日期;getDay(1):明天的日期;【以此类推】
    getDay(day) {
      var today = new Date();
      var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
      today.setTime(targetday_milliseconds); //注意，这行是关键代码
      var tYear = today.getFullYear();
      var tMonth = today.getMonth();
      var tDate = today.getDate();
      tMonth = this.doHandleMonth(tMonth + 1);
      tDate = this.doHandleMonth(tDate);
      return tYear + "-" + tMonth + "-" + tDate;
    },
    doHandleMonth(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },

    //获取角色权限
    getRole() {
      let that = this;
      $http.axios.get("/api/system/user/getInfo").then(function (response) {
        if (response.data.code == 200) {
          //获取部门id
          that.deptId = response.data.user.deptId;
          if (that.channelId == 3) {
            if (that.deptId == 300) {
              that.oppoType = true;
              that.tableList(that.channelId);
            } else if (that.deptId == 103) {
              that.oppoType = true;
              that.tableList(that.channelId);
              that.$message.close();
            } else {
              that.tableData = [];
              that.$message({
                showClose: true,
                message: "权限不足，请选择相对应渠道",
                type: "error",
              });
            }
            if (that.deptId != 300) {
              that.tableData = [];
            }
          } else {
            that.oppoType = false;
            that.tableList(that.channelId);
          }
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: "error",
          })
        }
      });
    },
    //channel的tab切换
    clickChangeChannel(channelId) {
      // if(channelId == 7 && this.deptId == 103){
      //   this.yyb = true
      //   this.tableList(channelId)
      // }
      this.channelId = channelId;
      // if (this.channelId != 3) {
        // this.$router.push({
        //   path: "/auth-management/DailyKpi",
        //   query: { channelId: channelId },
        // });
      // } else {
      //   this.$router.push({
      //     path: "/auth-management/DaytimeKpi",
      //     query: { channelId: channelId },
      //   });
      // }
      let that = this;
      that.huawei = false;
      //小米
      if (channelId == 5) {
        if (that.deptId == 316) {
          that.xiaomi = true;
          that.tableList(channelId);
        } else if (that.deptId == 103) {
          that.$message.close();
        } else {
          that.tableData = [];
          that.$message({
            showClose: true,
            message: "权限不足，请选择相对应渠道",
            type: "error",
          });
        }
      } else {
        that.xiaomi = false;
      }

      //oppo
      if (channelId == 3) {
        if (that.deptId == 300) {
          that.oppoType = true;
          that.tableList(channelId);
        } else if (that.deptId == 103) {
          that.$message.close();
        } else {
          that.tableData = [];
          that.$message({
            showClose: true,
            message: "权限不足，请选择相对应渠道",
            type: "error",
          });
        }
      } else {
        that.oppoType = false;
      }

      //vivo
      if (channelId == 4) {
        if (that.deptId == 307) {
          that.vivoType = true;
          that.tableList(channelId);
        } else if (that.deptId == 103) {
          that.$message.close();
        } else {
          that.tableData = [];
          that.$message({
            showClose: true,
            message: "权限不足，请选择相对应渠道",
            type: "error",
          });
        }
      } else {
        that.vivoType = false;
      }

      //华为
      if (channelId == 6) {
        // that.huawei = true
        if (that.deptId == 325) {
          that.huawei = true;
          that.tableList(channelId);
        } else if (that.deptId == 103) {
          that.$message.close();
        } else {
          that.tableData = [];
          that.$message({
            showClose: true,
            message: "权限不足，请选择相对应渠道",
            type: "error",
          });
        }
      } else {
        that.huawei = false;
      }

      //应用宝
      if (channelId == 7) {
        if (that.deptId == 324) {
          that.yyb = true;
          that.tableList(channelId);
        } else if (that.deptId == 103) {
          that.$message.close();
        } else {
          that.tableData = [];
          that.$message({
            showClose: true,
            message: "权限不足，请选择相对应渠道",
            type: "error",
          });
        }
      } else {
        that.yyb = false;
      }
      //苹果
      if (channelId == 8) {
        // if(that.deptId == 325){
        //   that.apple = true
        //   that.tableData = []
        // }else if(that.deptId == 103){
        //   that.$message.close()
        // }
        if (that.roleKeys == "optiperson-17") {
          that.apple = true;
          that.tableData = [];
        } else {
          that.tableData = [];
          that.$message({
            showClose: true,
            message: "权限不足，请选择相对应渠道",
            type: "error",
          });
        }
      } else {
        that.apple = false;
      }

      if (channelId == 3 && that.deptId == 103) {
        that.oppoType = true;
        that.tableList(channelId);
        that.$message.close();
      } else if (channelId == 4 && that.deptId == 103) {
        that.vivoType = true;
        that.tableList(channelId);
        that.$message.close();
      } else if (channelId == 5 && that.deptId == 103) {
        that.xiaomi = true;
        that.tableList(channelId);
        that.$message.close();
      } else if (channelId == 6 && that.deptId == 103) {
        that.huawei = true;
        that.tableList(channelId);
        that.$message.close();
      } else if (channelId == 7 && that.deptId == 103) {
        that.yyb = true;
        that.tableList(channelId);
        that.$message.close();
      }
      // else if(channelId == 8 && that.roleKeys == 'optiperson-17'){
      //   that.apple = true
      //   that.tableList(channelId)
      //   that.$message.close()
      // }

      // this.tableList(channelId)
      // 小米：deptId == 316，oppo：deptId == 300，vivo：deptId == 307，华为、苹果：deptId == 325，应用宝：deptId == 324，admin:deptId = 103
    },

    clickBtnUpload() {
      this.uploadDialogIs = true;
      this.getKPIdate();
    },
    uploadClose() {
      // this.uploadData = new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + (new Date().getDate() -1)
      this.uploadData = this.getDay(-1);
      this.uploadDialogIs = false;
      this.uploadMonthData =
        new Date().getFullYear() + "-" + new Date().getMonth();
      this.uploadMonthDialogIs = false;
    },

    // //vivo，小米上传
    // autoDistribution(params){
    //   this.$refs.upload.clearFiles()
    //   let _this = this,data = new FormData();
    //   $http.axios.defaults.timeout = 500000
    //   data.append('file',params.file);
    //   data.append('date',_this.uploadData);
    //   $http.axios.post("/api/"+_this.urlList[_this.channelId]+"/customReport/uploadMediumData",data).then(function(response) {
    //     if(response.data.code == 200){
    //       _this.$message({
    //         showClose: true,
    //         message: response.data.msg,
    //         type: 'success'
    //       })
    //       _this.uploadDialogIs = false
    //     }  else {
    //       _this.$message({
    //         showClose: true,
    //         message: response.data.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },

    //华为不能重复上传
    handleFileChange(file, fileList) {
      let existsAllFileFlag = false;
      for (let i = 0; i < this.fileList.length; i++) {
        if (file.name === this.fileList[i].name) {
          // 如果存在了，赋值true
          existsAllFileFlag = true;
        }
      }
      if (!existsAllFileFlag) {
        this.fileList.push(file);
      } else {
        fileList.pop();
        this.$message({
          showClose: true,
          message: "有重复文件，请刷新页面后重新上传",
          type: "error",
        });
        return;
      }
    },

    // 数组去重
    uniqueArr(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].name == arr[j].name) {
            this.$message.warning("不支持同名文件上传");
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },

    //上传媒体日报不能重复上传
    handleChange(file, fileList) {
      // fileList = this.uniqueArr(fileList);
      // this.$message({
      //   showClose: true,
      //   message: '文件已存在',
      //   type: 'error'
      // })
      //判断文件名是否重复
      // var count = 0
      // var index = 0    // 记录要删除的文件下标
      // fileList.forEach((item, idx) => {
      //   //在此处，对比文件名，将文件名相同的对比次数累加，
      //   // 相同的文件名累加值为 2 时，说明文件名已经重复，直接删掉。
      //   if (file.name == item.name) {
      //     count++
      //     if (count == 1) {
      //       index = idx;
      //     }
      //     if (count === 2) {
      //       this.$message({
      //         message: file.name + '文件已存在',
      //         type: 'info'
      //       })
      //       fileList.splice(index, 1)
      //     }
      //   }
      // })
    },

    //批量上传文件之前的校验
    handleBeforeUpload(file) {
      console.log(file);
      let vm = this;
      return new Promise((resolve, reject) => {
        // 收集文件列表
        this.validFileList.push(file);
        // 校验文件列表
        vm.handleDebounceVarifyFile();
        setTimeout(function () {
          if (!_.isEmpty(vm.validFileList)) {
            resolve(true);
          } else {
            reject(true);
          }
        }, 100);
      });
    },

    //防抖触发文件上传前，保证批量校验
    handleDebounceVarifyFile: _.debounce(function () {
      let excel_file = {};
      if (_.isEmpty(this.validFileList)) {
        return;
      }
      excel_file = this.validFileList.find((item) => {
        return /\.(xls)|\.(xlsx)$/.test(item.name);
      });
      // 如果文件校验不合理，清空合法文件列表，并提示
      if (_.isEmpty(excel_file)) {
        this.validFileList = [];
        this.$message.warning("至少上传一个xls或者xlsx格式的文件！");
      }
    }, 20),

    //vivo，小米上传
    autoDistribution(params) {
      // this.$refs.upload.clearFiles()
      let formData = new FormData(),
        _this = this;
      $http.axios.defaults.timeout = 500000;
      formData.append("date", _this.uploadData);
      formData.append("channelId", _this.channelId);
      formData.append("delFlag", true);
      _this.validFileList.forEach((item_file) => {
        formData.append(`files`, item_file);
      });
      // $http.axios.post("/api/"+_this.urlList[_this.channelId]+"/customReport/uploadMediumData",data).then(function(response) {
      $http.axios
        .post("/api/dataSync/media/uploadMediumData", formData)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "success",
            });
            _this.uploadDialogIs = false;
            _this.validFileList = [];
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //华为上传，批量
    hwautoDistribution(params) {
      this.handleDebounceUpload();
    },

    //防抖触发文件上传前，保证批量上传
    handleDebounceUpload: _.debounce(function () {
      console.log(22222);
      console.log(this.$refs.upload);
      if (_.isEmpty(this.validFileList)) {
        return;
      }
      this.$refs.upload.clearFiles();
      let formData = new FormData(),
        _this = this;
      formData.append("date", this.uploadData);
      formData.append("channelId", this.channelId);
      formData.append("delFlag", true);
      this.validFileList.forEach((item_file) => {
        formData.append(`files`, item_file);
      });
      // $http.axios.post("/api/"+_this.urlList[_this.channelId]+"/customReport/uploadMediumData",formData).then(function(response) {
      $http.axios
        .post("/api/dataSync/media/uploadMediumData", formData)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "success",
            });
            _this.validFileList = [];
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });

      // this.validFileList = []
      // batchUploadApi.uploadXsxx(formData).then(()=> {
      //   this.$message.success('附件已上传成功，请等待数据入库！');
      //   this.validFileList = [];
      // }).catch(err => {
      //   console.log('批量上传附件失败', err);
      // })
    }, 20),

    handleSuccess() {},

    //应用宝上传
    yybautoDistribution(params) {
      this.$refs.upload.clearFiles();
      let _this = this,
        data = new FormData();
      $http.axios.defaults.timeout = 500000;
      data.append("file", params.file);
      data.append("channelId", _this.channelId);
      $http.axios
        .post("/api/dataReport/customReport/mediumKpiReport/upload", data)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "success",
            });
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
      // if(_this.channelId == 3){
      //   // $http.axios.post("/api/report/costReport/uploadKpiReport",data).then(function(response) {
      //   $http.axios.post("/api/dataReport/customReport/mediumKpiReport/upload",data).then(function(response) {
      //     if(response.data.code == 200){
      //       _this.$message({
      //         showClose: true,
      //         message: response.data.msg,
      //         type: 'success'
      //       })
      //     }  else {
      //       _this.$message({
      //         showClose: true,
      //         message: response.data.msg,
      //         type: 'error'
      //       })
      //     }
      //   })
      // }else{
      //   // $http.axios.post("/api/"+_this.urlList[_this.channelId]+"/customReport/uploadKpiReport",data).then(function(response) {
      //   $http.axios.post("/api/dataReport/customReport/mediumKpiReport/upload",data).then(function(response) {
      //     if(response.data.code == 200){
      //       _this.$message({
      //         showClose: true,
      //         message: response.data.msg,
      //         type: 'success'
      //       })
      //     }  else {
      //       _this.$message({
      //         showClose: true,
      //         message: response.data.msg,
      //         type: 'error'
      //       })
      //     }
      //   })
      // }
    },

    //上传（新）
    newUpload(params) {
      this.$nextTick(() => {
        this.$refs.newUpload.clearFiles();
        let _this = this,
          data = new FormData();
        $http.axios.defaults.timeout = 500000;
        data.append("file", params.file);
        data.append("channelId", _this.channelId);
        $http.axios
          .post("/api/dataReport/customReport/mediumKpiReportNew/upload", data)
          .then(function (response) {
            if (response.data.code == 200) {
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: "success",
              });
            } else {
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: "error",
              });
            }
          });
      });
    },

    //列表
    tableList(channelId) {
      let that = this;
      // $http.axios.get("/api/report/costReport/kpiReportFillList?channelId="+channelId).then(function(response) {
      $http.axios
        .get(
          "/api/dataReport/customReport/kpiReportFill/list?channelId=" +
            channelId
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.tableData = response.data.data;
          } else {
            that.$message({
              showClose: true,
              message: response.msg,
              type: "error",
            });
          }
        });
    },

    //修改
    editBtn(val) {
      this.editDialogIs = true;
      this.editAnswer = val.answer;
      this.editAnswerId = val.id;
    },

    //取消修改
    editClose() {
      this.editDialogIs = false;
    },

    //确定修改
    editSure() {
      let that = this,
        editAnswer;
      editAnswer = encodeURIComponent(that.editAnswer);
      // $http.axios.get("/api/report/costReport/kpiReportFillUpdate?id="+that.editAnswerId+"&updater="+that.nickName+"&answer="+editAnswer).then(function(response) {
      $http.axios
        .get(
          "/api/dataReport/customReport/kpiReportFill/update?id=" +
            that.editAnswerId +
            "&updater=" +
            that.nickName +
            "&answer=" +
            editAnswer
        )
        .then(function (response) {
          if (response.data.code == 200) {
            that.tableList(that.channelId);
            that.editDialogIs = false;
          } else {
            that.$message({
              showClose: true,
              message: response.msg,
              type: "error",
            });
          }
        });
    },

    //获取KPI日报需要补充数据的日期
    getKPIdate() {
      let _this = this;
      console.log(_this.channelId);
      // $http.axios.get("/api/"+_this.urlList[_this.channelId]+"/customReport/queryDateForLoseData").then(function(response) {
      $http.axios
        .get(
          "/api/dataReport/customReport/mediumKpiReport/queryDateForLoseData?channelId=" +
            _this.channelId
        )
        .then(function (response) {
          if (response.data.code == 200) {
            if (response.data.msg != "") {
              _this.$message({
                showClose: true,
                message: response.data.msg,
                type: "success",
              });
            }
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    //下载
    sureExport() {
      let that = this;
      $http.axios.defaults.timeout = 500000;
      $http.axios
        .get(
          "/api/dataReport/customReport/mediumKpiReport/download?channelId=" +
            that.channelId,
          { responseType: "blob", dataType: "json", async: false }
        )
        .then((res) => {
          let blob = res.data;
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download =
            that.urlListName[that.channelId] +
            "-媒体日报-" +
            that.uploadDataNow +
            ".xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url); // 释放内存
        });

      // if(that.channelId == 3){
      //   // $http.axios.get("/api/report/costReport/downloadKpiReport",{responseType: 'blob',dataType:"json",async: false}).then(res=> {
      //   $http.axios.get("/api/dataReport/customReport/mediumKpiReport/download",{responseType: 'blob',dataType:"json",async: false}).then(res=> {
      //     let blob = res.data
      //     let url = window.URL.createObjectURL(blob)
      //     const link = document.createElement('a') // 创建a标签
      //     link.href = url
      //     link.download = that.urlListName[that.channelId]+'-媒体日报-'+that.uploadDataNow+'.xlsx' // 重命名文件
      //     link.click()
      //     URL.revokeObjectURL(url) // 释放内存
      //   })
      // }else{
      //   $http.axios.get("/api/"+that.urlList[that.channelId]+"/customReport/downloadKpiReport",{responseType: 'blob',dataType:"json",async: false}).then(res=> {
      //     let blob = res.data
      //     let url = window.URL.createObjectURL(blob)
      //     const link = document.createElement('a') // 创建a标签
      //     link.href = url
      //     link.download = that.urlListName[that.channelId]+'-媒体日报-'+that.uploadDataNow+'.xlsx' // 重命名文件
      //     link.click()
      //     URL.revokeObjectURL(url) // 释放内存
      //   })
      // }
    },
    // 下载（新）
    newDownload() {
      let that = this;
      $http.axios.defaults.timeout = 500000;
      $http.axios
        .get(
          "/api/dataReport/customReport/mediumKpiReportNew/download?channelId=" +
            that.channelId,
          { responseType: "blob", dataType: "json", async: false }
        )
        .then((res) => {
          let blob = res.data;
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement("a"); // 创建a标签
          link.href = url;
          link.download =
            that.urlListName[that.channelId] +
            "-媒体日报-" +
            that.uploadDataNow +
            "（新）.xlsx"; // 重命名文件
          link.click();
          URL.revokeObjectURL(url); // 释放内存
        });
    },

    //小米渠道上传报表月数据
    updateMonth() {
      this.uploadMonthDialogIs = true;
    },

    //小米渠道上传月报表
    autoMonthDistribution(params) {
      this.$refs.upload.clearFiles();
      let _this = this,
        data = new FormData();
      $http.axios.defaults.timeout = 500000;
      data.append("file", params.file);
      data.append("date", _this.uploadMonthData);
      // $http.axios.post("/api/"+_this.urlList[_this.channelId]+"/customReport/uploadMediumDataMonth",data).then(function(response) {
      $http.axios
        .post("/api/dataSync/media/mi/uploadMediumDataMonth", data)
        .then(function (response) {
          if (response.data.code == 200) {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "success",
            });
            _this.uploadMonthDialogIs = false;
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.faqWrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
}
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.faq {
  font-size: 18px;
  font-weight: 700;
}
.faqbtnFather {
  width: 300px;
  position: relative;
}
.faqbtnleft,
.faqbtn {
  display: inline-block;
  vertical-align: middle;
}
.faqbtnleft {
  width: 180px;
  padding-right: 20px;
  box-sizing: border-box;
}
.faqbtn {
  position: absolute;
  top: 0;
  right: 10px;
  width: 100px;
  text-align: right;
  text-align: center;
}
.faqbtn span {
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 20px;
  color: #fff;
  margin: 0 2px;
  background-color: #66856d;
}
.addfenlei {
  text-align: right;
  padding: 0 10px;
}
.createQuestion {
  padding-top: 15px;
  text-align: right;
}
.demo-table-expand .el-form-item {
  width: 100% !important;
}
html,
body,
#app,
.el-container {
  width: 100%;
  /*设置内部填充为0，几个布局元素之间没有间距*/
  padding: 0px;
  /*外部间距也是如此设置*/
  margin: 0px;
  /*统一设置高度为100%*/
  height: 100%;
}
.el-submenu {
  text-align: left;

  &.is-active .el-submenu__title {
    color: $--color-primary;
  }

  .el-menu-item.is-active {
    /* background: $poc-color-primary-deputy; */
    border-right: 4px solid $--color-primary;
    background: rgba(102, 133, 109, 0.2);

    a {
      color: $--color-primary;
    }
  }
}
/* .el-menu { width: 100%; border-right: none !important; } .el-menu-item [class^="el-icon-"] { color: #333; } .el-menu .el-submenu__title i { color: #333; } .el-menu-item.is-active { background: #f8e8ea; border-right: 3px solid #bb162b; } .el-submenu.is-opened .el-menu { background: #fafafa; } */
/* oppo和vivo的tab切换 */
.chanelTab {
  margin-bottom: 30px;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.channel_oppo,
.channel_vivo {
  display: inline-block;
  vertical-align: middle;
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  margin-bottom: -1px;
  cursor: pointer;
}
.channel_vivo {
  margin-left: -1px;
}
.channelActive {
  font-weight: 700;
  color: #66856d;
  border-bottom: 2px solid #66856d;
}
.uploadBtn {
  text-align: right;
}
.tip {
  display: block;
  font-size: 12px;
  color: red;
  font-style: normal;
  margin-top: 15px;
}
.downloadBtn {
  display: inline-block;
  margin-right: 10px;
}
</style>
