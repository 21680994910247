<template>
  <el-dialog
    width="722px"
    @close="onCancel"
    :visible.sync="show"
    class="icon_custom_popup"
    :close-on-click-modal="false"
    :title="'生成' + title + '模板'"
  >
    <img class="icon" src="@/assets/img/suCaiIcon.png" />
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :label-width="type == 2 ? '124px' : '74px'"
    >
      <el-form-item label="名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="文件名" prop="excelName">
        <el-input
          v-model="ruleForm.excelName"
          clearable
          placeholder="请输入文件名"
          @clear="handleClear"
        ></el-input>
        <div class="button_label">
          <div
            v-for="(item, index) in cibaoList"
            :key="index"
            @click="addWordsFn(item)"
          >
            {{ item }}
          </div>
        </div>
      </el-form-item>
      <div v-if="dataSourceIs">
        <div class="titles">数据源sheet</div>
        <div v-for="(item, index) in dataSource" :key="index">
          <el-collapse v-model="activeNames">
            <el-collapse-item :title="item.sheetName" :name="index">
              <el-form-item
                prop="sourceName"
                style="margin-bottom: 0"
              ></el-form-item>
              <div class="between">
                <el-form-item
                  label="报表维度"
                  prop="adType"
                  style="width: 46%"
                  label-width="63px"
                >
                  <el-select v-model="item.adType" placeholder="请选择报表维度">
                    <el-option label="账户" :value="1"></el-option>
                    <el-option label="广告系列" :value="2"></el-option>
                    <el-option label="广告组" :value="3"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="时区"
                  prop="timeZone"
                  style="width: 36%"
                  label-width="63px"
                >
                  <el-select
                    v-model="item.timeZone"
                    placeholder="请选择时区"
                    :disabled="type == 2 ? true : false"
                  >
                    <el-option label="Asia" value="Asia"></el-option>
                    <el-option label="UTC" value="UTC"></el-option>
                  </el-select>
                </el-form-item>
                <el-button
                  style="margin-top: 4px"
                  type="primary"
                  size="small"
                  @click="addBtnIndex(item.sheetName, index)"
                  >批量生成</el-button
                >
              </div>
              <el-transfer
                v-model="ruleForm.sourceName[index]"
                filterable
                :render-content="renderFunc"
                :titles="['未选中', '已选中']"
                @mouseover.native="addTitle"
                :data="tableAllData"
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}',
                }"
                @change="changeFn($event, index, 'data')"
              >
              </el-transfer>
            </el-collapse-item>
          </el-collapse>
        </div>
        <el-dialog
          width="400px"
          append-to-body
          :title="`批量生成${btnTitle}`"
          :visible.sync="innerVisible"
          class="icon_custom_popup"
          :close-on-click-modal="false"
        >
          <img class="icon" src="@/assets/img/ZDYLicon.png" />
          <el-form>
            <el-form-item>
              <el-input
                type="textarea"
                :rows="6"
                v-model="addTextIds"
                placeholder="每个ID之间用英文逗号分割，不要有空格，填写在下方输入框中即可"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="determine(indexNum)"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div>
      <div v-if="keywordsIs">
        <div class="titles">关键词sheet</div>
        <el-form-item
          v-for="(item, index) in keywords"
          :key="index"
          prop="KeyName"
          :label="item.sheetName"
        >
          <el-select
            v-model="ruleForm.KeyName[index]"
            value-key="key"
            multiple
            placeholder="请选择账户"
            filterable
            @change="changeFn($event, index, 'keyword')"
          >
            <el-option
              :label="items.label"
              :value="items"
              v-for="(items, indexs) in tableAllData"
              :key="indexs"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="titles">其他</div>
      <el-form-item label="接收人" prop="sendUser">
        <el-select
          v-model="ruleForm.sendUser"
          value-key="userId"
          multiple
          filterable
          placeholder="请选择接收人"
          @change="changeFn($event)"
          @visible-change="focusFn"
        >
          <el-option
            v-for="(item, index) in distributionOptions"
            :key="index"
            :label="item.string"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抄送人" prop="copyUser">
        <div class="inline">
          <el-select
            v-model="ruleForm.copyUser"
            multiple
            value-key="name"
            filterable
            placeholder="请选择抄送人"
            @change="changeFn($event)"
          >
            <el-option
              v-for="(item, index) in cc_options"
              :key="index"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <i
            class="el-icon-circle-plus-outline"
            @click="dialogFormVisible_add = true"
          ></i>
        </div>
        <el-dialog
          width="400px"
          append-to-body
          title="添加抄送人"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="dialogFormVisible_add"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_specify.png" />
          </div>
          <el-form :model="addForm" label-width="64px">
            <el-form-item label="人员名称">
              <el-input
                v-model="addForm.name"
                autocomplete="off"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱地址">
              <el-input
                v-model="addForm.email"
                autocomplete="off"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="dialogFormVisible_add = false">取 消</el-button>
            <el-button type="primary" @click="csrSure">确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item label="接收方式" prop="type">
        <el-checkbox-group class="middle" v-model="ruleForm.type">
          <el-checkbox label="Excel" name="type"></el-checkbox>
          <el-checkbox label="图片" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="type == 2" label="是否拉取零点数据" prop="resource">
        <el-switch
          class="middle"
          v-model="ruleForm.resource"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item v-if="type == 2" label="时报时间点" prop="timeSlot">
        <el-select
          v-model="ruleForm.timeSlot"
          multiple
          filterable
          placeholder="请选择时报时间点"
        >
          <el-option
            v-for="(item, index) in timeLimitList"
            :key="index"
            :label="item.time"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" @click="onCancel">{{
        $t("dc:取消")
      }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm('ruleForm')"
        >{{ $t("dc:保存") }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import getUserList from "@/utils/getUserList"; //获取用户列表
export default {
  props: {
    pubVue: Object,
    title: String,
    visible: {
      type: Boolean,
      default: false,
    },
    type: Number,
    dataSourceIs: Boolean,
    keywordsIs: Boolean,
    dataSource: Array,
    keywords: Array,
    path: String,
  },
  data() {
    let timeSlotReg = (rule, value, callback) => {
      let _this = this;
      if (_this.type == 2 && (!value || value.length == 0)) {
        callback(new Error("请选择时报时间点"));
      } else {
        callback();
      }
    };
    return {
      // 数据源
      renderFunc(h, option) {
        return (
          <span>
            {option.key} - {option.label}
          </span>
        );
      },
      show: this.visible,
      loading: false,
      ruleForm: {
        name: "",
        excelName: "",
        adType: "",
        type: [],
        resource: "",
        sourceName: [],
        KeyName: [],
        sendUser: [],
        copyUser: [],
        timeSlot: [],
      },
      addForm: {
        name: "",
        email: "",
      },
      timeLimitList: [
        { time: "10:00", id: "10" },
        { time: "10:30", id: "10.5" },
        { time: "11:00", id: "11" },
        { time: "11:30", id: "11.5" },
        { time: "12:00", id: "12" },
        { time: "12:30", id: "12.5" },
        { time: "13:00", id: "13" },
        { time: "13:30", id: "13.5" },
        { time: "14:00", id: "14" },
        { time: "14:30", id: "14.5" },
        { time: "15:00", id: "15" },
        { time: "15:30", id: "15.5" },
        { time: "16:00", id: "16" },
        { time: "16:30", id: "16.5" },
        { time: "17:00", id: "17" },
        { time: "17:30", id: "17.5" },
        { time: "18:00", id: "18" },
        { time: "18:30", id: "18.5" },
        { time: "19:00", id: "19" },
        { time: "19:30", id: "19.5" },
        { time: "20:00", id: "20" },
        { time: "20:30", id: "20.5" },
        { time: "21:00", id: "21" },
        { time: "21:30", id: "21.5" },
        { time: "22:00", id: "22" },
        { time: "22:30", id: "22.5" },
        { time: "23:00", id: "23" },
        { time: "23:30", id: "23.5" },
      ],
      dialogFormVisible_add: false,
      tableDataList: [],
      cc_options: [],
      value1: [],
      distributionOptions: [],
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        excelName: [
          { required: true, message: "请输入文件名", trigger: "blur" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个接收方式",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择是或者否", trigger: "change" },
        ],
        sendUser: [
          { required: true, message: "请选择接收人", trigger: "change" },
        ],
        sourceName: [
          { required: true, message: "请选择账户", trigger: "change" },
        ],
        KeyName: [{ required: true, message: "请选择账户", trigger: "change" }],
        timeSlot: [
          {
            required: true,
            message: "",
            trigger: "change",
            validator: timeSlotReg,
          },
        ],
      },
      // cibaoList:['代理公司','渠道','报表类型','日期','时段'],
      cibaoList: ["日期", "时段", "编号"],
      wordsA: [],
      // 数据源
      searchLoading: false,
      tableAllData: [],
      activeNames: [0],
      innerVisible: false,
      dialogData: "",
      btnTitle: "",
      addTextIds: "",
      indexNum: "",
      listTip: "",
      sourceNames: [],
      warnNine: [],
      warnEigth: [],
      warningPersonOption: [],
    };
  },

  watch: {
    show(v) {
      this.$emit("update:visible", v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
    this.userName = this.$sessionStorage.getItem(config.USERNAME);
    this.cc_options = this.$sessionStorage.getItem("optionSelectCopyUser");
    this.getFpyjList328();
    // this.getFpyjList329();
    this.getList();
    // this.getFpList()
  },
  methods: {
    //数据源
    dealData() {
      const data = this.tableDataList;
      data.forEach((obj) => {
        this.tableAllData.push({
          key: obj.ownerId,
          label: obj.ownerName,
        });
      });
    },

    //添加抄送人
    csrSure() {
      let that = this;
      that.dialogFormVisible_add = false;
      let userName = that.addForm.name;
      let email = that.addForm.email;
      $http.axios
        .get(
          "/api/system/user/bdAccountCopyUser?userName=" +
            userName +
            "&email=" +
            email
        )
        .then(function (response) {
          if (response.data.code == 200) {
            getUserList().then((res) => {
              that.cc_options = res;
            });
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
    onConfirm(formName) {
      let _this = this,
        sourceIs = false,
        keyIs = false,
        sourceNum = 0,
        keyNum = 0;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if (_this.dataSourceIs) {
            _this.ruleForm.sourceName.forEach((obj, i, arr) => {
              if (obj.length == 0) {
                sourceIs = false;
                sourceNum--;
              } else {
                sourceNum++;
                if (sourceNum == arr.length) {
                  sourceIs = true;
                }
              }
            });
          } else {
            sourceIs = true;
          }
          if (_this.keywordsIs) {
            _this.ruleForm.KeyName.forEach((obj, i, arr) => {
              if (obj.length == 0) {
                keyIs = false;
                keyNum--;
              } else {
                keyNum++;
                if (keyNum == arr.length) {
                  keyIs = true;
                }
              }
            });
          } else {
            keyIs = true;
          }
          if (!sourceIs || !keyIs) {
            _this.$message({
              showClose: true,
              message: "请选择账户",
              type: "error",
            });
          } else {
            _this.addModelFn(_this.dayLimit);
          }
        }
      });
    },

    onCancel() {
      this.show = false;
      this.$refs.ruleForm.resetFields();
      this.pubVue.listModelFn();
    },
    handleClear() {
      this.ruleForm.excelName = "";
      this.wordsA = [];
    },

    focusFn(e) {
      if (e) {
        this.getFpList();
      }
    },

    changeFn(event, index, type) {
      let _this = this,
        ownersList = [],
        dataSourceList = {},
        sheetName = "";
      if (type == "data" && event.length != 0) {
        //数据源
        _this.tableAllData.forEach((obj) => {
          event.forEach((val) => {
            if (val == obj.key) {
              ownersList.push({ ownerId: obj.key, ownerName: obj.label });
            }
          });
        });
        this.dataSource[index].owners = ownersList;
      } else if (type == "keyword" && event.length != 0) {
        event.forEach((obj) => {
          ownersList.push({ ownerId: obj.key, ownerName: obj.label });
        });
        this.keywords[index].owners = ownersList;
        this.keywords[index].adType = 6;
      }
    },

    //获取分配人员列表
    getFpyjList328() {
      let that = this;
      $http.axios
        .get("/api/system/user/getAccountAppendList?deptId=328")
        .then(function (response) {
          if (response.data.code == 200) {
            that.warnEigth = response.data.data; //预警人
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
    getFpyjList329() {
      let that = this;
      $http.axios
        .get("/api/system/user/getAccountAppendList?deptId=329")
        .then(function (response) {
          if (response.data.code == 200) {
            that.warnNine = response.data.data; //预警人
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
    //获取分配人员列表
    getFpList() {
      this.getFpyjList328();
      // this.getFpyjList329();
      this.distributionOptions = this.warnEigth.concat(this.warnNine);
    },

    //获取菜单列表
    getList() {
      let _this = this,
        params = {};
      params = {
        pageIndex: _this.page || 1,
        pageSize: 1000,
        userId: _this.userId,
        userName: _this.userName,
        deptId: _this.deptId,
      };
      $http.axios.post("/api/apple/account/list", params).then((res) => {
        if (res.data.code == 200) {
          _this.tableDataList = res.data.data.list;
          _this.dealData();
        } else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },

    addModelFn() {
      let _this = this,
        params = {},
        excelSwitch = 0,
        imgSwtich = 0,
        sendUser = { string: [], id: [] },
        copyUser = { string: [], id: [] };
      let times = [];

      times = _this.ruleForm.timeSlot;
      if (_this.ruleForm.type.length > 0) {
        if (_this.ruleForm.type[0] == "图片") {
          imgSwtich = 1;
        } else if (_this.ruleForm.type[0] == "Excel") {
          excelSwitch = 1;
        }
        if (_this.ruleForm.type.length == 2) {
          excelSwitch = 1;
          imgSwtich = 1;
        }
      }
      _this.ruleForm.sendUser.forEach((obj) => {
        sendUser.string.push(obj.string);
        sendUser.id.push(obj.userId);
      });
      _this.ruleForm.copyUser.forEach((obj) => {
        copyUser.string.push(obj.name);
        copyUser.id.push(obj.id);
      });
      params = {
        userId: _this.userId,
        deptId: _this.deptId,
        userName: _this.userName,
        channelId: 8,
        type: _this.type, //报表类型
        name: _this.ruleForm.name, //模板名称
        title: _this.ruleForm.excelName, //报表名称，词包
        path: _this.path, //文件路径
        excelConfig: {
          dataSource: _this.dataSource,
          kwDataSource: _this.keywords,
        },
        sender: sendUser.id.toString(),
        senderName: sendUser.string.toString(),
        time: String(times),
        excelFlag: excelSwitch,
        imgFlag: imgSwtich,
        copySender: copyUser.id.toString(),
        copySenderName: copyUser.string.toString(),
        zeroFlag: _this.ruleForm.resource,
      };
      $http.axios
        .post("/api/dataReport/reportModel/addModel", params)
        .then((res) => {
          if (res.data.code == 200) {
            _this.onCancel();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    addWordsFn(val) {
      let _this = this,
        wordsA = [];
      if (_this.wordsA.length != 0) {
        _this.wordsA.forEach((obj, i, arr) => {
          if (val == obj) {
            arr.splice(i, 1);
          }
        });
        _this.wordsA.push(val);
        wordsA = _this.wordsA;
        _this.ruleForm.excelName += "{" + val + "}";
      } else {
        _this.ruleForm.excelName += "{" + val + "}";
        _this.wordsA.push(val);
      }
    },
    // 添加选中的id
    addBtnIndex(name, index) {
      this.btnTitle = name;
      this.indexNum = index;
      this.addTextIds = this.ruleForm.sourceName[index];
      this.innerVisible = true;
    },

    //确定添加选中的id到选框中
    determine(index) {
      let sourceNames = [],
        ownersList = [],
        addTextIds = [],
        list = [];
      this.addTextIds = this.addTextIds.toString();
      sourceNames = this.addTextIds.split(",");
      this.ruleForm.sourceName[index] = sourceNames;
      this.tableAllData.forEach((obj) => {
        sourceNames.forEach((val, index) => {
          if (val == obj.key) {
            ownersList.push({ ownerId: obj.key, ownerName: obj.label });
            addTextIds.push(obj.key);
            list = sourceNames.filter((item) => {
              return addTextIds.indexOf(item) === -1;
            });
          }
        });
      });
      sourceNames = sourceNames.filter((item) => !list.includes(item));
      this.addTextIds = sourceNames.toString();
      this.dataSource[index].owners = ownersList;
      this.addTextIds = JSON.stringify(sourceNames);
      this.sourceNames = sourceNames;
      this.innerVisible = false;
      this.addTextIds = "";
      this.listTip = list.toString();
      if (list.length != 0) {
        this.$alert(
          `<div><img class="icon" src="${require("@/assets/img/warningIcon.png")}" /><div class="text_tip">查询到<span class="tips">${listTip}</span>是错误的账户ID，请重新检查账户ID之后再次输入</div></div>`,
          "错误ID",
          {
            center: true,
            showCancelButton: true,
            dangerouslyUseHTMLString: true,
            customClass: "icon_custom_popup",
            beforeClose: (action, instance, done) => {
              done();
            },
          }
        )
          .then(() => {})
          .catch(() => {});
      }
    },

    //取消添加选中弹框
    cancel() {
      this.innerVisible = false;
      this.addTextIds = "";
    },

    addTitle(e) {
      const target = e.target;
      if (target.title) return;
      target.title = target.innerText;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

