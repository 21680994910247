<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/auth-management/consumptionSum' }">运营管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/ConsumptionSum' }">消耗汇总</el-breadcrumb-item>
          <el-breadcrumb-item>关联计划</el-breadcrumb-item>
          
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
          <el-button type="success" size="small" class="customColumns" @click="customColumns" style="margin-bottom:20px;">自定义列</el-button>
          <!-- 自定义列   -->
          <el-dialog
            title="自定义列"
            :visible.sync="dialogFormVisible"
            width="40%">
            <div class="customBox">
              <div class="customContent">
                <el-checkbox-group v-model="checkedCities">
                  <el-checkbox
                    v-for="(item,i) in customData"
                    :label="item.string"
                    :key="i"
                    style="display:block;">
                    {{item.name}}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <div class="customExist" >
                <span v-for="cus in checkList">{{cus.name}}</span>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="setCustom">确 定</el-button>
            </div>
          </el-dialog>
          <!-- <el-table :data="gridData">
            <el-table-column 
              v-for="(item,i) in gridDataList"
              :key="item.string"
              :label="item.name"
              :prop="item.string">
            </el-table-column>
          </el-table> -->
          <el-table :data="gridData">
            <el-table-column
              prop="launch_name"
              fixed="left"
              label="关联广告计划"
              show-overflow-tooltip>
              <template slot-scope="scope">{{scope.row.ad_id}}</template>
            </el-table-column> 
            <el-table-column 
              v-for="(item,i) in gridDataList"
              :key="item.string"
              :label="item.name"
              :prop="item.string">
            </el-table-column>
          </el-table>
          <div class="footerPaging">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.totalRow">
          </el-pagination>
          </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'demandOrder',
    data() {
      return {
        showTheForm:true,//表头是否显示全部表单
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dialogFormVisible:false,
        formLabelWidth:'120px',
        customData:[],//自定义列data
        checkList:[],
        checkListTd:[],
        checkListExist:'',
        setParams:{
          customLineString:'',
          userId:''
        },
        checkListString:[],
        dialogTableVisible:false,
        gridData:[],//计划data
        planParams:{
          materialId:'',
          userId:'',
          // pageSize:'',
          // pageNum:''
        },
        originParams:{
          materialId:'',
          userId:'',
          customLineString:'',
          // orderBy:'2',
          // pageSize:'',
          // pageNum:''
        },
        dialogTableVisible_origin:false,
        gridDataOrigin:[],//创意data
        getUserId:'',
        getzdyData:[],
        getMaterialListArray:[],
        checkdddd:'',
        checkListStringList:[],
        selectedPerson:[],
        ischecked:false,
        gridCheckList:[],
        cyData:'',
        cyTag:[],
        cyValue:[],
        detailId:'',
        gridDataList:[],
        checkedCities: [],






      }
    },
    computed: {
       
    },
    mounted() {
      this.getId()
      // this.gplan()
      this.getMorenList()
    },
    methods: {
      getId(){
        this.detailId = this.$route.params && this.$route.params.material_id
        console.log(this.detailId)
        this.originParams.materialId = this.detailId 
      },

      //获取全部自定义列
      customColumns(){
        let that = this
        that.dialogFormVisible = true
        that.checkedCities = that.getMaterialListArray
        // that.checkedCities = []
        $http.axios.get("/api/channel/dataWeb/queryCustomLineList").then(function(response) {
          that.customData = response.data
          console.log(that.customData)
        })
      },

      //获取用户自定义列
      getMorenList(){
        let that = this
        that.getUserId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        that.getMaterialListArray = []
        $http.axios.post("/api/channel/dataWeb/getCustomLineList?userId="+that.getUserId).then(function(response) {
          if(response.data.code == 200){
            that.getzdyData = response.data.data
            if(that.getzdyData[0] != null){
              that.getzdyData.forEach(function(v,i,arr){
                let bool = arr.indexOf(v,i+1)
                if(bool === -1){
                  that.getMaterialListArray.push(v.string)
                }
            })
              that.originParams.customLineString = String(that.getMaterialListArray)
            }else{
              that.originParams.customLineString = ''
            }
            that.gplan(that.originParams.customLineString)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //设置用户自定义列
      setCustom(){
        let that = this
        console.log(that.checkedCities)
        that.checkListString=[]
        that.checkListStringList = []
        // that.checkedCities.forEach(function(e,i){
        //   that.checkListString.push(e.string)
        // })
        that.setParams.userId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        that.setParams.customLineString = String(that.checkedCities)
        console.log(that.setParams.customLineString)
        $http.axios.post("/api/channel/dataWeb/bdCustomLineList",that.setParams).then(function(response) {
          if(response.data.code == 200){
            that.gridData.push(that.checkedCities)
            that.dialogFormVisible = false
            that.getMorenList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //关联计划
      gplan(customLineString){
        let that = this
        // that.originParams.materialId = '6746498909244358669'
        that.originParams.userId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        that.originParams.customLineString = String(customLineString)
        // that.originParams.pageSize = that.pageSize
        // that.originParams.pageNum = that.pageNum
        $http.axios.post("/api/channel/dataWeb/queryPlanList?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.originParams).then(function(response) {
          if(response.data.code == 200){
            that.gridData = response.data.rows
            that.totalRow = response.data.total
            if(that.getzdyData[0]==null){
              that.gridDataList=[]
            }else{
              that.gridDataList=that.getzdyData
            }
            console.log(that.gridDataList)
            // that.gridData.push(that.gridDataList)  
            // that.bigImg = "111111111"          
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
      

      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        //获取数据接口（调用封装的接口）
        this.gplan(this.getMaterialListArray)
      },
      //表格中选中和未选中
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materialHead{
      font-size:0;
    }
    .materialHchannel,.materialHBtns{
      display:inline-block;
      vertical-align:middle;
      font-size:14px;
    }
    .materialHchannel{
      width:30%;
    }
    .materialHBtns{
      width:70%;
    }
    .materiaContent{
      margin-top:15px;
    }
    .showTheForm,.hideenTheForm{
      display:inline-block;
      vertical-align:top;
    }
    .showTheForm{
      position:relative;
    }
    .showTheForm i{
      position:absolute;
      bottom:-10px;
      left:50%;
      margin-left:-11px;
      font-size:22px;
      color:rgb(36, 132, 168);
      cursor:pointer;
    }
    .hideenTheForm i{
      font-size:22px;
      vertical-align:middle;
      color:rgb(36, 132, 168);
      cursor:pointer;
      margin:0 20px;
    }
    .tableTop{
      margin-bottom:20px;
    }
    .customColumns{
      float:right;
    }
    .customBox{
      width:100%;
      padding:0 20px;
      box-sizing: border-box;
      font-size:0;
    }
    .customContent,.customExist{
      font-size:14px;
      display:inline-block;
      vertical-align:top;
      box-sizing: border-box;
    }
    .customContent{
      width:60%;
      padding-right:15px;
    }
    .customExist{
      width:40%;
    }
    .customExist span{
      display:block;
    }

  </style>
