<template>
  <el-dialog
    :visible.sync="show"
    width="50%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="批量日限额"
  >
    <el-form
    style="margin:0 auto;margin-left:10px;"
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData"
    >
   
      <el-form-item label="" prop="" >
        <el-select  clearable placeholder="选择日限额类型" v-model="dayLimitV"  @change="changeDayLimit">
          <el-option
            v-for="(item,i) in dayLimitList"
            :key="i"
            :label="item.text"
            :value="item.id"
            :disabled = "limitIs&&item.id=='1'">
          </el-option>
          <!-- <el-option  label="具体值" value='具体值'></el-option>
          <el-option :disabled = limitIsIs label="百分比" value='百分比'></el-option> -->
        </el-select>
      </el-form-item>   
      <el-form-item label="" prop="" v-show="dayLimitV == '1'" >
        <el-select  clearable placeholder="选择日限额方式" v-model="dayLimitF" @change = "numIn">
          <el-option
            v-for="(item,i) in dayLimitFnList"
            :key="i"
            :label="item.text"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>   
      <el-form-item :rules="dayLimitV == '1'?numberlimit:numberlimitM" prop="scan_limit">
        <el-input clearable v-model="formData.scan_limit" placeholder="请输入数值" @blur="numIn"></el-input>
      </el-form-item>
      <el-form-item label="日限额限制" :rules="numberlimitM" pro="upDown" >
        <el-input class="limitInput"  clearable v-model="limitTop" placeholder="上限值"></el-input>
        <el-input class="limitInput" clearable v-model="limitD" placeholder="下限值" ></el-input>
      </el-form-item>
      <el-button type="primary" size="small" @click="yulanFn" >预览</el-button>
      <div>
         <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                v-show="tableYL">
                
                <el-table-column
                  align='center'
                  v-for="(item,index) in dayLimitTitleList" :key="index"
                  :prop="item.prop"
                  :label="item.name"
                  show-overflow-tooltip>
                </el-table-column>
              </el-table>
      </div>
      <div>
        <el-checkbox v-model="checked">立即执行</el-checkbox>
        <el-form-item label="" v-if="checked == false" class="dateConent">
          <div class="block">
            <el-date-picker
            v-model="value1"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>

          </div>
        </el-form-item>
      </div>
      
    </el-form>

    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('formData')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';
export default {
  
  name: "dayLimitDialog",

  props: {
    pubVue: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    },
    yulanList:Array,
    limitIs:Boolean,
    // type: Number,
    // ownerId:Number,
    params:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    title:String,
    max: Number
  },
  data() {
    return {
      formData: {
        scan_limit:'',
        upDown:''
        },
      rules: {
      maxNum: [
        { required: true, message: '请输入最高值', trigger: 'blur' },
      ],
      minNum: [
        { required: true, message: '请输入最低值', trigger: 'blur' }
      ]},
      dayLimitList:[{'text':'具体值','id':0},{'text':'百分比','id':1}],
      dayLimitFnList:[{'text':'上调','id':0},{'text':'下调','id':1}],
      show: this.visible,
      loading: false,
      dayLimitV:'',
      dayLimitF:'',
      numberlimit: { validator: this.checkMoney, trigger: 'change'},

      numberlimitM: { validator: this.checkMoneyM, trigger: 'change' },
      limitTop:'',//上限值
      limitD:'',//下限值
      checked:false,//立即执行
      value1:'',
      dayLimitTitleList:[{name:'计划名称',prop:'planName'},
      {name:'日限额类型',prop:'lx'},
      {name:'日限额方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始日限额',prop:'dayBudget'},
      {name:'目标日限额',prop:'sendPrice'}],
      // tableData:[{"planName":null,
      //   "lx":null,
      //   "fs":null,
      //   "num":"412.00",
      //   "dayBudget":null,
      //   "sendPrice":"412412"}],
      tableData:[],
      changeDayLimitArr:[],
      numInArr:[],
      limitIsIs:false,//是否为未限制，未限制不可使用百分比类型
      tableYL:false,


    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
     this.pubVue.$on('SHOW_DIALOG', data => {
      console.log('方法监听', data)
      this.show = data.showDialog
    })
    
  },
  methods: {
    yulanFn(){
      let _this = this
      console.log(_this.yulanList)
      _this.tableYL = true
      _this.showYu()
    },
    showYu(){
      let _this = this
      
      _this.tableData = []
      _this.yulanList.forEach((obj,i)=>{
       _this.tableData.push(Object.assign({},obj,_this.changeDayLimitArr[i],_this.numInArr[i]))
      })
    },
    checkMoney (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (value > 100) {
            callback(new Error('不能超过100'))
          } else {
            callback()
          }
        }
      }, 1000)
    },
    checkMoneyM (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    },
    
    changeDayLimit(event){
      let _this = this
      _this.changeDayLimitArr = []
      console.log(999,event)
      _this.yulanList.forEach((obj,i)=>{
       _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
})
      console.log(_this.yulanList)
      _this.limitIsIs = _this.limitIs
      _this.formData.scan_limit = ''
      _this.showYu()
    },
    numIn(){
       let _this = this,val = _this.formData.scan_limit
        _this.numInArr = []
      console.log(666,_this.formData.scan_limit)
      _this.yulanList.forEach((obj,i)=>{
            if(_this.dayLimitV == '1'){
              if(_this.dayLimitF == '0'){
                val = parseInt(obj.dayBudget*(1+val/100))
                  _this.numInArr.push(Object.assign({},{num:val+'.00',sendPrice:val,fs:'上调'}))

              }else if(_this.dayLimitF == '1'){
                  val = parseInt(obj.dayBudget*(1-val/100))

                  _this.numInArr.push(Object.assign({},{num:val+'.00',sendPrice:val,fs:'下调'}))

              }
            }else{
              _this.numInArr.push(Object.assign({},{num:val+'.00',sendPrice:val}))
            }
        
        
      })
      console.log(_this.tableData)
      _this.showYu()
    },
    onConfirm(formName) {
      let _this = this
       _this.$refs[formName].validate((valid) => {
          if (valid) {
            if(_this.limitIs){
              if(Number(_this.formData.scan_limit)>=Number(_this.limitD)&&Number(_this.limitTop)>=Number(_this.formData.scan_limit)){
                console.log('成功1')
                if(this.checked){
                  console.log('选中')
                  _this.updateOppoDayBudgetThreeFn()
                }else{
                  console.log('未选中')
                  _this.addPlanDailyMoneySetFn()
                }
                
              }else{
              _this.$message({
              showClose: true,
              message: "超出日限额限制",
              type: 'error'
            })
              }
            }else{
              _this.tableData.forEach(obj=>{
                // if(_this.title == '批量日限额'){
                  if(Number(obj.num)>=Number(_this.limitD)&&Number(_this.limitTop)>=Number(obj.num)){
                    console.log('成功2')
                    if(this.checked){
                      console.log('选中')
                      _this.updateOppoDayBudgetThreeFn()
                    }else{
                      console.log('未选中')
                      if(_this.value1 != []){
                        _this.addPlanDailyMoneySetFn()
                      }else{
                        _this.$message({
                          showClose: true,
                          message: "请选择日期",
                          type: 'error'
                        })
                      }
                      
                    }
                  }else{
                    _this.$message({
                      showClose: true,
                      message: "超出日限额限制",
                      type: 'error'
                    })
                  }
                // }else{
                //   if(this.checked){
                //     console.log('选中')
                //     _this.updateOppoDayBudgetThreeFn()
                //   }else{
                //     console.log('未选中')
                //     if(_this.value1 != []){
                //       _this.addPlanDailyMoneySetFn()
                //     }else{
                //       _this.$message({
                //         showClose: true,
                //         message: "请选择日期",
                //         type: 'error'
                //       })
                //     }
                    
                //   }
                // }
                
              })
            }
            
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    },
    onCancel() {
      this.show= false;
      this.tableYL= false;
      this.formData.scan_limit = ''
      this.dayLimitF = ''
      this.dayLimitV = ''
      this.tableData = []
      this.changeDayLimitArr =[]
      this.numInArr = []
      this.value1 = []
      this.pubVue.$refs.multipleTable.clearSelection();
      this.pubVue.getAccountPlanListFn()
      this.pubVue.adPlanIdList = []


      console.log('关闭')
    },
    addOrSetFn(type){
      
    },
    
    //保存-立即执行接口	"dayBudget": 0,日预算 (dayLimit=1 时dayBudget必填 单位（分）)
                  // "dayLimit": "",0 不限制 1 限制
                  // "deptId": 0,
                  // "ownerId": 0,
                  // "planIds": [],
                  // "userId": 0,
                  // "userName": ""
    updateOppoDayBudgetThreeFn(){
    // /adPlan/v1/updateOppoDayBudgetThree
    let _this = this,params = []
    console.log(_this.tableData)
    _this.tableData.forEach((obj,i)=>{
      params.push({'dayLimit':1,'dayBudget':Number(obj.num)*100,'planName':obj.planName,'planId':obj.planId,'deptId':_this.params.deptId,'ownerId':_this.params.ownerId,'userId':_this.params.userId,'userName':_this.params.userName})
    })

    $http.axios.post("/api/advertisement/adPlan/v1/updateOppoDayBudgetThree",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
          _this.pubVue.$refs.multipleTable.clearSelection();
          _this.pubVue.getAccountPlanListFn()
          _this.pubVue.adPlanIdList = []
          _this.onCancel();
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
      })
    },
    //保存-未立即执行接口"deptId": 0,
                    // "endTime": "",
                    // "executionTime": "",
                    // "newPrice": 0,-num
                    // "oldPrice": 0,-dayBudget
                    // "ownerId": 0,
                    // "planId": 0,
                    // "planName": "",
                    // "priceDown": 0,-this.limitD
                    // "priceUp": 0,-this.limitTop
                    // "startTime": "",
                    // "upPrice": "",-num
                    // "upPriceMode": 0,-调价方式（1上调、2下调 ）
                    // "upPriceStatus": 0,-调价状态（0开启、1关闭）
                    // "upPriceType": 0,-调价类型（1具体值、2百分比）
                    // "userId": 0,
                    // "userName": ""
   addPlanDailyMoneySetFn(){
    //  /adPlan/v1/addPlanDailyMoneySet
     let _this = this,params1 = {},params = []
    console.log(_this.tableData)
    _this.tableData.forEach((obj,i)=>{
      params.push({'dayLimit':1,'planName':obj.planName,
      'planId':obj.planId,'endTime':_this.value1[1],'startTime':_this.value1[0],
      'newPrice':Number(obj.num)*100,'oldPrice':obj.dayBudget != null?Number(obj.dayBudget)*100:0,'priceDown':Number(_this.limitD)*100,
      'priceUp':Number(_this.limitTop)*100,'upPrice':Number(obj.num),'upPriceMode':Number(_this.dayLimitF)+1,'upPriceType':_this.dayLimitV+1,
      'deptId':_this.params.deptId,'ownerId':_this.params.ownerId,'userId':_this.params.userId,'userName':_this.params.userName})

    })

    $http.axios.post("/api/advertisement/adPlan/v1/addPlanDailyMoneySet",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.onCancel();
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
      })
   },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
</style>

