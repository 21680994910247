<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item  :to="{ path: '/auth-management/HomePage' }">首页管理</el-breadcrumb-item>
                <el-breadcrumb-item>用户列表</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>  
          <div class="userList materialHead">
            <h3 class="hLIne">
              <span class="line"></span>
              <span class="role_headerChecked role_headerChecked_user">用户列表</span>
            </h3>
            <el-form :inline="true" :model="ruleForm" ref="ruleForm" class="demo-form-inline" v-if="channelDistinguish == 3">
              <el-form-item label="" prop="qdvalue">
                <el-select v-model="ruleForm.qdvalue" placeholder="请选择渠道名称" @change="changeChannel($event)">
                  <el-option
                    v-for="item in qdoptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="yhvalue">
                <el-select v-model="ruleForm.yhvalue" filterable clearable placeholder="选择用户职位" @change="changePost($event)">
                  <el-option
                    v-for="(item,i) in yhoptions"
                    :key="i"
                    :label="item.postName"
                    :value="item.postId">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="sjvalue">
                <el-select v-model="ruleForm.sjvalue" filterable clearable placeholder="选择直属上级" @change="changeLeader($event)">
                  <el-option
                    v-for="(item,i) in sjoptions"
                    :key="i"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="yhinput" class="positionBox">
                <el-input v-model="ruleForm.yhinput" placeholder="输入用户姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="newQuery" size="small" @click="getUserList(1)">查询</el-button>
                <el-button type="info" @click="resetForm('ruleForm')" size="small" >重置</el-button>
              </el-form-item>
            </el-form>

            <el-form :inline="true" :model="ruleForm" ref="ruleForm" class="demo-form-inline" v-if="channelDistinguish == 4||channelDistinguish == 5||channelDistinguish == 6">
              <el-form-item label="" prop="qdvalue">
                <el-select v-model="ruleForm.qdvalue" placeholder="请选择渠道名称" @change="changeChannel($event)">
                  <el-option
                    v-for="item in qdoptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="yhvalue">
                <el-select v-model="ruleForm.yhvalue" filterable clearable placeholder="选择用户职位" @change="changePost($event)">
                  <el-option
                    v-for="(item,i) in yhoptions"
                    :key="i"
                    :label="item.postName"
                    :value="item.postName">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="sjvalue">
                <el-select v-model="ruleForm.sjvalue" filterable clearable placeholder="选择直属上级" @change="changeLeader($event)">
                  <el-option
                    v-for="(item,i) in sjoptions"
                    :key="i"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="yhinput" class="positionBox">
                <el-input v-model="ruleForm.yhinput" placeholder="输入用户姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="newQuery" size="small" v-if="channelDistinguish == 4" @click="getVivoUserList(1)">查询</el-button>
                <el-button type="primary" class="newQuery" size="small" v-if="channelDistinguish == 5" @click="getXmUserList(1)">查询</el-button>
                <el-button type="primary" class="newQuery" size="small" v-if="channelDistinguish == 6" @click="getHwUserList(1)">查询</el-button>
                <el-button type="info" @click="resetForm('ruleForm')" size="small" >重置</el-button>
              </el-form-item>
            </el-form>

            <div class="userTable" v-if="channelDistinguish == 3">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  fixed="left"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="channelName"
                  label="渠道名称"
                  width="180">
                  <template slot-scope="scope">{{scope.row.channel}}</template>
                </el-table-column>
                <el-table-column
                  prop="username"
                  label="用户姓名"
                  width="180">
                  <template slot-scope="scope">{{scope.row.userName}}</template>
                </el-table-column>
                <el-table-column
                  prop="userPosition"
                  label="用户职位"
                  width="180">
                  <template slot-scope="scope">{{scope.row.post}}</template>
                </el-table-column>
                <el-table-column
                  prop="userManage"
                  label="管理账户"
                  width="180">
                  <template slot-scope="scope">{{scope.row.accountNum}}</template>
                </el-table-column>
                <el-table-column
                  prop="userHeigher"
                  label="直属上级"
                  width="180">
                  <template slot-scope="scope">{{scope.row.leader}}</template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  prop="userOperation"
                  label="操作"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button plain size="mini" class="solid-tag-success" @click="goToDetail(scope.row)">详情</el-button>
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deleteUser(scope.row.userId)"
                    >
                    <template #reference>
                      <el-button plain size="mini" slot="reference" class="newBtn_delRed">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
                </el-pagination>
              </div>
            </div>

            <!-- vivo,xm -->
            <div class="userTable" v-if="channelDistinguish == 4||channelDistinguish == 5||channelDistinguish == 6">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  fixed="left"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="channelName"
                  label="渠道名称"
                  width="180">
                  <template slot-scope="scope">{{scope.row.channel}}</template>
                </el-table-column>
                <el-table-column
                  prop="username"
                  label="用户姓名"
                  width="180">
                  <template slot-scope="scope">{{scope.row.name}}</template>
                </el-table-column>
                <el-table-column
                  prop="userPosition"
                  label="用户职位"
                  width="180">
                  <template slot-scope="scope">{{scope.row.post}}</template>
                </el-table-column>
                <el-table-column
                  prop="userManage"
                  label="管理账户"
                  width="180">
                  <template slot-scope="scope">{{scope.row.num}}</template>
                </el-table-column>
                <el-table-column
                  prop="userHeigher"
                  label="直属上级"
                  width="180">
                  <template slot-scope="scope">{{scope.row.leader}}</template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  prop="userOperation"
                  label="操作"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button plain size="mini" class="solid-tag-success" @click="goToDetailvivo(scope.row)">详情</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
                </el-pagination>
              </div>
            </div>
          </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <accessDialog :visible.sync="showDialog" ></accessDialog>
    </div>
  </template>
  
  <script>
  import accessDialog from '@/views/dialog/accountDialog';
  import echarts from  "echarts";
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'authority',
    components: {
      accessDialog
    },
    data() {
      return {
        showDialog: false,
        tagsShow:false,
        qdoptions:[],
        yhoptions:[],
        sjoptions:[],
        ruleForm:{
          qdvalue:'',
          yhvalue:'',
          sjvalue:'',
          yhinput:'',
        },
        tableData: [],
        multipleSelection:[],
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        queryUserParams:{
          channel:'',
          leader:'',
          post:'',
          userId:'',
          userName:''
        },
        userListParams:{//oppo
          channel:'',
          leader:'',
          post:'',
          userId:'',
          userName:''
        },
        pageUserId:'',
        delaccountId:'',
        userChannel:'',
        //vivo开始
        oppoUserList:true,//oppo权限的用户列表显示
        vivoUserList:false,//vivo权限的用户列表显示
        channelDistinguish:'',//判断查询的是哪个渠道
        userVivoListParams:{
          leader:'',//上级领导
          post:'',//职位
          name:'',//用户名称
        },
        xmUserList:false,


      }
    },
    computed: {},
    mounted() {
      this.getuserChannel()
      this.getPostList()
      this.getleaderList()
    },
    methods: {
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
        this.$router.go(0)
      },
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          // if(this.channelDistinguish == 3){
          //   return (restaurant.userName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
          // }else if(this.channelDistinguish == 4||this.channelDistinguish == 5){
          //   return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
          // }
        };
      },

      handleSelect(item) {
        console.log(item);
      },

      //跳转到用户详情列表
      goToDetail(row){
        console.log(row)
        if(row.channel == 'oppo'){
          this.$router.push({path:'/auth-management/userDetailOppo/'+row.userId})
        }else{
          this.$router.push({path:'/auth-management/userDetail/'+row.userId})
        }
        
      },
      goToDetailvivo(row){
        if(row.channel == 'vivo'){
          this.$router.push({path:'/auth-management/userDetailVivo/'+row.userId})
        }else if(row.channel == 'mi'){
          this.$router.push({path:'/miAccount-management/UserDetailMi/'+row.userId})
        }else if(row.channel == '华为'){
          this.$router.push({path:'/hwAccount-management/UserDetailHw/'+row.userId})
        }
      },
      //获取用户列表
      getUserList(page){
        let that = this
        // if(page == 1){
        //   that.pageNum = 1
        // }
        that.userVivoListParams.channel = that.channelDistinguish
        that.userListParams.userName = that.ruleForm.yhinput
        that.userListParams.userId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.post("/api/channel/accountUser/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.userListParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
      //vivo用户列表
      getVivoUserList(page){
        let that = this
        // if(page == 1){
        //   that.pageNum = 1
        // }
        that.userVivoListParams.name = that.ruleForm.yhinput
        that.userVivoListParams.channel = that.channelDistinguish
        $http.axios.post("/api/viat/accountUser/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.userVivoListParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })  
      },

      //小米
      getXmUserList(page){
        let that = this
        // if(page == 1){
        //   that.pageNum = 1
        // }
        that.userVivoListParams.name = that.ruleForm.yhinput
        that.userVivoListParams.channel = that.channelDistinguish
        $http.axios.post("/api/xmat/accountUser/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.userVivoListParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //华为
      getHwUserList(page){
        let that = this
        // if(page == 1){
        //   that.pageNum = 1
        // }
        that.userVivoListParams.name = that.ruleForm.yhinput
        that.userVivoListParams.channel = that.channelDistinguish
        $http.axios.post("/api/hwat/accountUser/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.userVivoListParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取用户职位列表
      getPostList(){
        let that = this
        $http.axios.get("/api/system/post/optionselect").then(function(response) {
          if(response.data.code == 200){
            that.yhoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取直属上级
      getleaderList(){
        let that = this
        $http.axios.get("/api/system/user/getLeaderList").then(function(response) {
          if(response.data.code == 200){
            that.sjoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取channelId
      changeChannel(event){
        this.userListParams.channel = String(event)
        this.userVivoListParams.channel = String(event)
        this.channelDistinguish = event
        if(event == 3){
          this.getUserList(1)
        }else if(event == 4){
          this.getVivoUserList(1)
        }else if(event == 5){
          this.getXmUserList(1)
        }else if(event == 6){
          this.getHwUserList(1)
        }
      },
      //获取职位id
      changePost(event){
        this.userListParams.post = String(event)
        this.userVivoListParams.post = String(event)
      },

      //获取leader的id
      changeLeader(event){
        this.userListParams.leader = String(event)
        this.userVivoListParams.leader = String(event)
      },

       //修改后的关联到user的channel
       getuserChannel(){
        let that = this
        that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
          if(response.data.data.length!=0){
            that.qdoptions = response.data.data
            that.ruleForm.qdvalue = response.data.data[0].id
            that.userListParams.channel = response.data.data[0].id
            that.channelDistinguish = response.data.data[0].id
            if(that.channelDistinguish == 3){
              that.getUserList(1)
            }else if(that.channelDistinguish == 4){
              that.getVivoUserList(1)
            }else if(that.channelDistinguish == 5){
              that.getXmUserList(1)
            }else if(that.channelDistinguish == 6){
              that.getHwUserList(1)
            }
          }
        })
      },

      //删除用户
      deleteUser(e){
        let that = this
        $http.axios.get("/api/channel/user/"+e).then(function(response) {
          console.log(response)
          that.getUserList()
        })
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      
      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        if(this.channelDistinguish == 3){
          this.getUserList(1)
        }else if(this.channelDistinguish == 4){
          this.getVivoUserList(1)
        }else if(this.channelDistinguish == 5){
          this.getXmUserList(1)
        }else if(this.channelDistinguish == 6){
          this.getHwUserList(1)
        }
        
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    /* .userform .el-input{
      display:inline-block;
      margin:0!important;
      margin-right:1%;
    } */
    /* ul,li{
      margin:0;
      padding:0;
    } */
    .userform .el-select{
      margin-right:10px;
    }
    .userTable{
      margin-top:0!important;
    }
    .userTable .el-table .cell{
      text-align:center;
    }
    .el-table th{
      /* background:rgba(200, 200, 169, 0.5)!important; */
      color:#1b1f23;
      background:$poc-color-primary-deputy;
    }
    .footer_paging{
      margin:20px 0 4px;
    }
    .footer_totalNums{
      font-size:12px;
      color:#666;
      padding-left:5px;
      padding-right:20px;
    }
    .footerPaging {
      float:right;
    }
    /* .userBtn{
      padding:0 5px;
      cursor:pointer;
    } */
    /* .userBtn_delete{
      color:red;
    } */
    /* .userform .el-form-item__content{
      margin-left:0!important;
      margin-left:15px!important;
    } */
    .positionBox{
      position:relative;
    }
    .tagsList{
      position:absolute;
      top:30px;
      left:0;
      width:100%;
      border:1px solid #ddd;
      border-top:none;
    }
    .tagsList li{
      font-size:12px;
      padding-left:15px;
      cursor:pointer;
    }
    /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
      width:160px;
      height:32px;
      line-height:32px;
      background:#F5F7F6;
      border:none;
    }
    .hLIne{
      border-bottom:1px solid #F1F7F5;
      padding-bottom:15px;
    }
    .role_headerChecked,.line{
      display: inline-block;
      vertical-align: middle;
    }
    .line{
      width:3px;
      height:20px;
      background:#48916F;
    }
    .role_headerChecked{
      font-size:14px;
      font-weight:500;
      color:#333;
      margin-left:13px;
    }
  </style>
