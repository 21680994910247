<template>
  <el-dialog
    :visible.sync="show"
    width="440px"
    center
    :show-close = "false"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="设置出价预警">
    <div class="dialogIcon"><img :src='warningIcon'></div>
    <el-form
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData"
      :rules="rules">
      <el-form-item label="预警开关" label-width="100px">
        <el-switch
          v-model="formData.warningSwitch"
          active-value="0"
          inactive-value="1"
          active-color="#48916F"
          inactive-color="#EAEFED">
        </el-switch>    
      </el-form-item>
      <div v-for="(item,i) in formData.warningSetDetailInfoList" :key = 'i'>
        <p v-if="formData.warningSetDetailInfoList.length>1">预警{{i+1}}</p>
        <el-form-item label="最高值" :prop="'warningSetDetailInfoList.'+i+'.top'"
        :rules="[{ required: true, message: '请输入最高值', trigger: 'blur' }]" label-width="100px">
          <el-input
            v-model.trim="item.top" 
            placeholder="请输入限制金额"   
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            @blur="salaryChange(item.top,$event)"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item label="最低值" :prop="'warningSetDetailInfoList.'+i+'.low'"
        :rules="[{ required: true, message: '请输入最低值', trigger: 'blur' }]" label-width="100px">
          <el-input
            v-model.trim="item.low" 
            placeholder="请输入限制金额"   
            oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
            @blur="salaryChange(item.low,$event,'low',i)"
            clearable>
          </el-input>
        </el-form-item>
      </div>  
    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import warningIcon from '@/assets/img/warningIcon.png';
  import { $http } from '@/api/http';
export default {
  
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    pubVue:Object,
    userContent:Object,//登录用户信息
    page:Number,
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      warningIcon,
      formData: {
          maxNum: '',
          minNum: '',
          id:[],
          warningSetDetailInfoList:[],
          
          warningSwitch:null
        },
        detailInfoListArr:[],
      rules: {
        top: [
          { required: true, message: '请输入最高值', trigger: 'blur' },
        ],
        low: [
          { required: true, message: '请输入最低值', trigger: 'blur' }
        ],
        
      },
      loading: false,
      show: this.visible,
      
      infoId:[],//详情接口的id
      dayDisabled:false,//日预算是否可操作
      adPlanId:'',
      adPlanName:'',
      adGroupId:'',
      adGroupName:'',

      warningFieldType:''//预警字段 子任务出价-4
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
  },
  methods: {
    
    onConfirm(formName) {
      let _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let tag = _this.formData.warningSetDetailInfoList.every(ele=>{
            if(Number(ele.top)<Number(ele.low)){
              return false
            }else{
              return true
            }
          })
          if(!tag){
            let messageContent = '请调整最高值，最高值不能<或=最低值'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            })
          }else{
            _this.addAndUpdtateAdPlanWarningSetFn()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onCancel() {
      this.show= false;
      this.pubVue.groupsIds = []
       // _this.groupsIds = []
       this.pubVue.$refs.multipleTable.clearSelection();
      this.formData= {
          warningSwitch:null,
          id:[],
       
        warningSetDetailInfoList : []}
    },
    //最高最低值改变
    salaryChange(num,e,type,i){
        this[num] = e.target.value
        console.log(num,e,type,i)
    },
   
    //获取计划预警详情
    setDialogContentFn(adPlanId,adPlanName,adGroupId,adGroupName,warningFieldType,type){
      let _this = this,setData,detailInfoListArr = []
        _this.adPlanId = adPlanId
        _this.adPlanName = adPlanName
          _this.adGroupId = adGroupId
          _this.adGroupName = adGroupName
          _this.warningFieldType = warningFieldType
          _this.formData.warningSetDetailInfoList =[]
         
      $http.axios.post("/api/hw/adGroupWarning/getAdGroupWarningDetailList",{'adGroupIdList':adGroupId,'warningFieldType':warningFieldType}).then(res=> {
        if(type == 'list'){
        _this.adGroupId.forEach(obj=>{
          detailInfoListArr.push({id: null, top: '', low: '', time: null})
        })          
      }else{
        detailInfoListArr.push({id: null, top: '', low: '', time: null})
      }    
        if(res.data.code==200){
          if(res.data.data.length>0){
            setData = res.data.data
            setData.forEach((obj,i)=>{
              if(type == 'list'){
                _this.formData.warningSetDetailInfoList =[{ top: '', low: '', time: null}]
                detailInfoListArr[i] = obj.warningSetDetailInfoList[0]

              }else{
                _this.formData.warningSetDetailInfoList = obj.warningSetDetailInfoList
                detailInfoListArr[0] = obj.warningSetDetailInfoList[0]
              }
              _this.formData.id.push(obj.warningSetDetailInfoList[0].id)
              _this.formData.warningSwitch = obj.warningSwitch==null?'1':obj.warningSwitch.toString()
            })
          }else{
            this.formData= {
              warningSwitch:null,
              warningSetDetailInfoList :[{id: null, top: '', low: '', time: null}]
            }
                

         }
        }
        _this.detailInfoListArr = detailInfoListArr
        console.log(_this.formData.warningSetDetailInfoList)
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
  //     {
  // 	"addAndUpdateAdGroupWarningSetDTOList": [
  // 		{
  // 			"adGroupId": "",
  // 			"adGroupName": "",
  // 			"adPlanId": 0,
  // 			"adPlanName": "",
  // 			"ownerId": "",
  // 			"warningFieldType": 0,
  // 			"warningSetDetailInfoList": [
  // 				{
  // 					"id": 0,
  // 					"low": 0,
  // 					"time": "",
  // 					"top": 0
  // 				}
  // 			],
  // 			"warningSwitch": 0,
  // 			"userId": 0,
  // 			"userName": "",
  // 			"deptId": 0
  // 		}
  // 	]
  // }
    //新增&修改广告计划日限额预警
    addAndUpdtateAdPlanWarningSetFn(){
      let _this = this,params,addAndUpdateAdGroupWarningSetDTOList = [],warningSetDetailInfoList = []
      warningSetDetailInfoList =_this.detailInfoListArr
      _this.adGroupId.forEach((obj,i)=>{
        warningSetDetailInfoList[i].top = _this.formData.warningSetDetailInfoList[0].top
        warningSetDetailInfoList[i].low = _this.formData.warningSetDetailInfoList[0].low
        addAndUpdateAdGroupWarningSetDTOList.push({
          "adGroupId": obj,
          "adGroupName": _this.adGroupName instanceof Array?_this.adGroupName[i]:_this.adGroupName,
          "adPlanId": _this.adPlanId instanceof Array?_this.adPlanId[i]:_this.adPlanId,
          "adPlanName": _this.adPlanName instanceof Array?_this.adPlanName[i]:_this.adPlanName,
          "ownerId": _this.userContent.ownerId,
          "warningFieldType": _this.warningFieldType,
          "warningSetDetailInfoList": [warningSetDetailInfoList[i]],
          "warningSwitch": _this.formData.warningSwitch||1,
          "userId": _this.userContent.userId,
          "userName": _this.userContent.userName,
          "deptId": _this.userContent.deptId
        
        })
      })

      params = {
        'addAndUpdateAdGroupWarningSetDTOList':addAndUpdateAdGroupWarningSetDTOList}
        $http.axios.post("/api/hw/adGroupWarning/batchAddAndUpdtateAdGroupWarningSet",params).then(res=> {
          if(res.data.code==200){
            _this.pubVue.$refs.multipleTable.clearSelection();
            _this.pubVue.grouplistFn(_this.page)
            _this.pubVue.adPlanIdList = []
            _this.maxNum = ''
            _this.minNum = ''            
            if(params.type == 3){
                _this.show= false;
                
            }else{
              _this.show= false;
            }
            let messageContent =res.data.msg|| '添加成功'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'success'
            })
          
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
  }
};
</script>
<style lang="scss" scoped>

.account-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
  
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}
.el-checkbox{
  margin: 15px;
}
::v-deep .el-dialog__body{
  font-size: 14px;
  text-align: left!important;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
.formDatastyle .el-form-item--mini.el-form-item{
  margin:0;
}
.footerBottom{
  margin-top:20px;
  margin-bottom:20px;
  text-align: center;
}
</style>

