<template>
  <div class="authority common-page">
     <PocCardTable>
      <template slot="headerTitle">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item :to="{ path: '/auth-management/dataSjkbAsa' }">数据看板</el-breadcrumb-item>
              <el-breadcrumb-item >修改密码</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <div class="editPassWord">
          <h3>人员详情</h3>
          <ul class="staffDetil">
            <li class="staffDetilAll">
              <label>人员姓名</label>
              <span>{{staffDetailData.nickName}}</span>
            </li>
            <li>
              <label>手机号码</label>
              <span>{{staffDetailData.phonenumber}}</span>
            </li>
            <li>
              <label>E-mail</label>
              <span>{{staffDetailData.email}}</span>
            </li>
            <li>
              <label>所属部门</label>
              <span>{{staffDetailData.dept.deptName}}</span>
            </li>
            <li>
              <label>直属上级</label>
              <span>
                <span v-if="staffDetailData.dept.leader == null">-</span>
                <span v-else>{{staffDetailData.dept.leader}}</span>
              </span>
            </li>
            <li class="staffDetilAll">
              <label>角色权限</label>
              <span>
                <span v-for="item in staffDetailData.roles">
                  {{item.roleName}}
                </span>
              </span>
            </li>
            <li>
              <label>工作地点</label>
              <span>
                <span v-if="staffDetailData.place == null">-</span>
                <span>{{staffDetailData.place}}</span>
              </span>
            </li>
            <li>
              <label>入职时间</label>
              <span>{{staffDetailData.createTime}}</span>
            </li>
          </ul>
          <div class="setWord">设置平台使用账户</div>
          <ul class="setPw">
            <li>
              <label>个人帐户</label>
                <span>{{staffDetailData.userName}}</span>
            </li>
            <li v-if="userIdSelf == staffDetailData.userId" class="editPw">
              <label>修改密码</label>
              <span class="pwEdit">
                <el-input class="pwEditLeft" v-model="changePassword" placeholder="请修改密码" @blur="blurPw" @focus="focusPw"></el-input>
                <span class="pwEditRight">
                  <i class="editNewpw el-icon-edit" v-if="edit"></i>
                  <i class="editNewpw el-icon-circle-check" v-if="checked" @click="changeSuccess"></i>
                </span>
              </span>
            </li>
          </ul>
          <div class="editBtn">
            <el-button class="newDialogCancel" type="primary" @click="trouter">取消保存</el-button>
            <el-button class="newDialog" type="primary" @click="trouter">确定保存</el-button>
          </div>
        </div>
      </template>   
    </PocCardTable>
  </div>
</template>

<script>
import { $http } from '@/api/http';
import Api from "@/api";
import config from '@/api/config';

export default {
  data() {
    return {
      centerDialogVisible: false,
      staffDetailData:{},
      changePassword:'',//修改密码
      changePasswordParams:{
        userId:'',
        password:'',
        loginName:''
      },
      userIdSelf:'',
      edit:true,
      checked:false,

    }
  },
  computed: {},
  mounted() {
    this.userIdSelf = this.$sessionStorage.getItem(config.USERID) || 1
    this.staffDetail()
  },
  methods: {
    //光标
    focusPw(){
      this.edit = false
      this.checked = true
    },
    blurPw(){
      if(this.changePassword == ''){
        this.edit = true
        this.checked = false
      }else{
        this.edit = false
        this.checked = true
      }
      
    },

    // 部门人员详情
    staffDetail(){
      this.changePassword = ''
      let that = this
      that.centerDialogVisible = true
      $http.axios.get("/api/system/user/"+that.userIdSelf).then(function(response) {
        if(response.data.code == 200){
          that.staffDetailData = response.data.data
        }else{
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改密码
    changeSuccess(){
      let that = this
      that.changePasswordParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
      that.changePasswordParams.password = that.changePassword
      that.changePasswordParams.loginName = that.$sessionStorage.getItem(config.USERNAME)
      if(that.changePassword == ''){
        that.$message({
          showClose: true,
          message: '请输入要修改的密码',
          type: 'error'
        })
      }else{
        $http.axios.put("/api/system/user/resetPwd",that.changePasswordParams).then(function(response) {
          if(response.data.code == 200){
            that.$message({
              showClose: true,
              message: '密码修改成功',
              type: 'success'
            })
            that.$router.push('/')
          }else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      }
    },

    //确定和取消跳转
    trouter(){
      this.$router.push('/auth-management/dataSjkbAsa')
    },

  }
}
</script>
<style lang="scss" scoped>
  .editPassWord{
    width:600px;
    margin:20px auto 0;
    border:1px solid #48916F;
    border-radius:10px;
    box-sizing: border-box;
    padding:20px;
  }
  .editPassWord h3{
    font-size:18px;
    font-weight:700;
    text-align:center;
  }
  .staffDetil,.setPw{
    font-size:0;
    padding:0;
    margin:0;
  }
  .staffDetil li,.setPw li{
    display: inline-block;
    vertical-align:middle;
    border:1px solid #E7F1EC;
    border-radius:4px;
    font-size:12px;
    width:46%;
    margin:10px;
    padding:6px 12px;
    box-sizing: border-box;
  }
  .staffDetil li>label,.setPw li>label{
    color:#999;
    padding-right:20px;
  }
  .staffDetil li>span,.setPw li>span{
    color:#333;
    font-weight:700;
  }
  .staffDetil li.staffDetilAll{
    width:100%;
  }
  .setWord{
    font-size:12px;
    color:#999;
  }
  .setPw li.editPw{
    border:none;
    background: #F5F7F6;
  }
  .setPw li.editPw label{
    color:#330000;
  }
  .editNewpw{
    font-size:18px;
    font-weight:700;
    cursor: pointer;
    color:#ee7d3f;
  }
  .editNewpw.el-icon-circle-check{
    color:#65A487;
  }
  .setPw li.editPw label,.setPw li.editPw>span{
    display: inline-block;
    vertical-align: middle;
  }
  .setPw li.editPw label{
    width:50px;
  }
  .setPw li.editPw > span{
    width:155px;
  }
  .pwEditLeft,.pwEditRight{
    display: inline-block;
    vertical-align: middle;
  }
  .pwEditLeft{
    width:88%;
  }
  .pwEditRight{
    width:11%;
  }
  .pwEditRight i{
    padding-left:10px;
  }
  .editBtn{
    text-align:center;
    margin:30px 0 20px;
  }
  

</style>

