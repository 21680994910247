import { $http } from './http';
// const { VUE_APP_BASEURL} = process.env;
export default {
  /**
   *  获取项目类表
   */
  applist() {
    return $http.get(`/security-back/api/v1/app/list`);
  },
  /**
   * 获取用户权限
   */
  applyOption() {
    return $http.get(`/security-back/api/v1/app/applyOption`);
  },
  /**
   * 申请权限
   */
  apply(data) {
    return $http.get(`/security-back/api/v1/app/apply`,{params: data});
  }
}