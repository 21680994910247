<template>
  <el-dialog
    :visible.sync="show"
    width="1000px"
    @close = "onCancel"
    center
    :show-close = "false"
    :close-on-click-modal="false"
    class="account-detail-dialog"
    title="批量启停设置">
    <div class="dialogIcon"><img :src='suCaiIcon'></div>
    <div class="setPrice">
      <el-form :model="ruleForm" :rules="rules" ref='ruleForm'  class="demo-ruleForm">
        <el-form-item label="目标状态" label-width="120px">
          <el-switch
            v-model="planStatus"
            active-value="1"
            inactive-value="2"
            active-color="#48916F"
            inactive-color="#EAEFED">
          </el-switch>
        </el-form-item>
        <el-form-item class="marginPx" label="设置执行日期" prop="dateChecked" label-width="120px">
            <el-date-picker
              v-model="ruleForm.dateChecked"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="" label-width="120px">
          <span class="zxtime"><i class="redFont">* </i><span>执行时间</span></span>
          <TimeUnit ref="timeStrArray"></TimeUnit>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('ruleForm')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import suCaiIcon from '@/assets/img/suCaiIcon.png';
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  // import TimeUnit from '@/views/auth/TimeUnit'
  import TimeUnit from '@/views/auth/TimeUnitMi'
  import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')
  import dateValidator from '@/utils/dateValid'//日期范围校验
export default {
  components:{
    // TimeUnit
    TimeUnit
  },
  name: "accountDialog",

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    yulanList : Array,
    page:Number,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    pubVue:Object,
    objIdType:Number,
    max: Number
  },
  data() {
    return {
      suCaiIcon,
        show: this.visible,
        loading: false,
        planStatus:'1',
        userId:'',
        userName:'',
        deptId:'',
        ruleForm: {
          planName:'',
          dateChecked:[],//设置执行日期
          radio:'',//计划执行设置，单选
        },
        rules: {
          dateChecked:[
            // { message: '请设置执行日期', trigger: 'change' }
            { validator: dateValidator, 
                  dateType: 'days',
                  num: 31,
                  dateString: '天',
                  message:'请设置执行日期'
                }
          ],
        },
       
        setDateVisible:false,//计划启停的设置执行日期
        setDatevalue:[],//计划启停的设置执行日期----日期选择
        setDatevalueString:'',
        setTimeVisible:false,//计划执行设置执行日期
        setTimevalue:'',//计划执行设置执行日期----日期选择
        setTimevalueShow:false,//计划执行设置执行日期----日期选择展示
        setTimevalueString:'',
        DN:'yyb'//域名:Domain Name的缩写
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
 
  methods: {
    onConfirm(formName) {
      let _this = this
      let params = [],infos = []
      _this.setPriceCommon={'userId' : _this.userContent.userId,'userName' : _this.userContent.userName,
      deptId: _this.userContent.deptId,
      ownerName : _this.userContent.ownerName,
      ownerId : _this.userContent.ownerId,
      }
      let timeString = _this.$refs.timeStrArray.timeStr
      let addOpenClosePlanDetailDtoList = []
      timeString.forEach((obj,index)=>{
        if(obj!=''){
            addOpenClosePlanDetailDtoList.push(
              {
                'timeRange':obj,
                'week':index+1
              }
            )
          }
      })
        let flag = timeString.every(function(e,index){
          if(e!=''){
            
            return false
          }else{
            return true
          }
        })
        
        _this.yulanList.forEach((obj,i)=>{
          if(_this.objIdType==1){
            infos.push({'adPlanId':obj.adPlanId,'adPlanName':obj.adPlanName})
          }else if(_this.objIdType==3){
            infos.push({'adPlanId':obj.adPlanId,'adPlanName':obj.adPlanName,'adId':obj.adId,'adName':obj.adName})
          }
          params = {'userId' : _this.userContent.userId,
          'userName' : _this.userContent.userName,
          'deptId': _this.userContent.deptId,
          // 'ownerName' : _this.userContent.ownerName,
          'ownerId' : _this.userContent.ownerId,
          'startDate' : _this.ruleForm.dateChecked[0]||formatDate(new Date()),
          'endDate' : _this.ruleForm.dateChecked[1]||'2032-01-01',
          'adType':_this.objIdType,
          'infos':infos,
          'switchFlag':Number(_this.planStatus),//obj.planStatus,//计划开关状态 0:开启 1:关闭->静静说先写死成开启的状态
          'details':addOpenClosePlanDetailDtoList,
          }
        })
      // _this.setPriceCommon[0].type = _this.userContent.type
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(!flag){
            _this.carryTimeSure(params)
          }else{
             _this.$message({
              showClose: true,
              message: '启停时间段不能为空',
              type: 'error'
            })
          }
        } else {
          return false;
        }
      });
      
      
    },
    onCancel() {
      this.show= false;
      this.$refs.ruleForm.resetFields();
      this.$refs.timeStrArray.clear()
    },

      //计划启停设置---设置执行日期--取消
      setDateCancel(){
        this.setDateVisible = false
        this.ruleForm.dateChecked = false
      },

      //计划执行设置--选择
      changeRadio(val){
        if(val == 1){
          this.setTimeVisible = true
          this.setTimevalue = ''
        }else{
          this.setTimevalueShow = false
        }
      },
      //计划执行设置---设置执行日期--取消
      setTimeCancel(){
        this.setTimeVisible = false
        this.ruleForm.radio = ''
      },
      //计划执行设置---设置执行日期--确定
      setTimeCommit(){
        if(this.setTimevalue == ''){
          this.$message('请选择日期')
        }else{
          this.setTimeVisible = false
          this.setTimevalueShow = true
          this.setTimevalueString = String(this.setTimevalue[0]+'至'+this.setTimevalue[1])
        }
      },

      
      //设置执行时间显示弹框--确定
      carryTimeSure(params){
        let that = this,type,DN = this.DN
        that.$refs.ruleForm.validate((valid) => {
          if (valid) {
            $http.axios.post("/api/"+DN+"/openStop/add",params).then(res=> {
              if(res.data.code == 200){
                this.show = false
                that.pubVue.$refs.multipleTable.clearSelection();
                that.pubVue.getListFn(that.page)
                that.pubVue.adPlanIdList = []
                that.$refs.ruleForm.resetFields();
                that.ruleForm.ruleForm = ''
                type = 'success'
              }  else {
                type = 'error'
                let  msg = ''
                res.data.msg = res.data.msg.split(',')
                res.data.msg.forEach(obj=>{
                  msg+= '<p>'+obj+'</p>'
                })
                res.data.msg = msg
              }
              that.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: type,
                  dangerouslyUseHTMLString: true,
                })
            })
          } else {
            return false;
          }
        });

        
      },
    
  }
};
</script>
<style lang="scss" scoped>
  // .account-detail-dialog .redFont .el-input__inner,.redFont .el-input__count-inner,.redFont{
  //   color: red;
  // }
  .redFont{
    color: red;
    display: inline-block;
    margin: 2px 7px 0 7px;
    vertical-align: middle;
    font-style: normal;
  }
.account-detail-dialog .el-form-item__label {
    font-size: 18px;
  }
.account-detail-dialog .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(200, 200, 169, 0.5);
  }
.el-form-item--mini .el-form-item__label{
  font-size: 13px;
}
.account-detail-dialog .el-dialog__footer .el-button--primary:hover{
  background-color: rgba(200, 200, 169, 0.5);
  border-color: rgba(200, 200, 169, 0.5);
}
.account-detail-dialog .el-dialog .el-dialog__header{
  padding: 15px;
}

.marginPx{
  margin: 15px 8px;
}
.baseTitle{
        font-weight:700;
        display:block;
        font-size:14px;
        margin:10px 0 20px;
      }
      .setPrice .el-input{
        width:260px;
      }
      // .errorTip{
      //   margin-left:150px;
      // }
     /*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
.zxtime{
  display: inline-block;
  vertical-align: middle;
  width:80px;
  font-size:12px;
  color:#330000;
}
</style>

