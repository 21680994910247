<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/vivoAccount-management/AccountSelectionVivo' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/vivoAccount-management/AccountSelectionVivo' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>广告计划</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-dropdown trigger="click" @command = "changeStatusList">
                <el-button type="primary" size="small" @click="updateOppoSet('planStatus')">
                  修改状态
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" size="small" @click="warmIs('warningPrice',url[1])">
                  出价预警
              </el-button>
              <el-dropdown trigger="click" @command = "upAdPlanWarningSwitchFn">
                <el-button type="primary" size="small" @click="warmIs('warningOpen',url[1])">
                  预警开关
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> 
              <el-button type="primary" size="small" @click="warmIs('dayLimitPrice',url[2],4)">批量调价</el-button>
              <el-button type="primary" size="small" @click="warmIs('dayLimit',url[2],3)">批量日限额</el-button>
              <el-button type="primary" size="small" @click="updateOppoSet('updatedate')">修改日期</el-button>
              <el-button type="primary" size="small" @click="updateOppoSet('updateTime')">修改时段</el-button>
              <el-button type="primary" size="small" @click = "zdyFn">自定义列</el-button>
              <el-button type="primary" size="small" @click = "exportTeam">下载报表</el-button>
            </div>
          </div> 
          <div class="chanelTab">
            <span class="channel_vivo" @click="clickChangeChannel('adPlanVivo')" >广告计划</span>
            <span class="channel_vivo channelActive">广告组</span>
            <span class="channel_vivo" @click="clickChangeChannel('advertVivo')">广告</span>
          </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <el-input v-model = "groupName" clearable placeholder="请输入广告组名称查找"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "nameId" 
                  clearable           
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="请输入广告组ID查找"></el-input>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "searchPlanId" 
                  clearable           
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                  placeholder="请输入广告计划ID"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择广告组状态" v-model="channelp">
                    <el-option
                      v-for="(item,i) in qdoptions.adGroupStatus"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <div class="block">
                    <el-date-picker
                    v-model="value1"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <el-button type="primary" class="newQuery" size="small" @click = "grouplistFn(page,'','',groupName,nameId,channelp,value1)">查询</el-button>
                  <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                </el-form-item>
              </el-form> 
            </div>
          </div>
          <div class="clearfix">
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @cell-click="clickcell"
                :cell-style="setRowStyle"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="广告组名称"
                  width="200">
                  <template slot-scope="scope">
                    <span class="adTeamName">{{scope.row.name}}</span>
                    <i 
                      class="el-icon-edit adTeamEditBtn"
                      @click="editAdTeamName(scope)">
                    </i>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="id"
                  label="广告组ID"
                  width="120">
                </el-table-column>
                <el-table-column
                  prop="campaignName"
                  label="所属广告计划名称"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="campaignId"
                  label="所属计划ID"
                  width="120">
                </el-table-column>
                <el-table-column
                  prop="warningStatus"
                  label="预警状态"
                  width="120">
                  <template slot-scope="scope">
                    <span v-if="scope.row.warningStatus == 0">开</span>
                    <span v-else-if="scope.row.warningStatus == 1">关</span>
                    <span v-else>未设置</span>
                  </template>
                </el-table-column>
                <template v-for="(item, index) in titleList">
                    <el-table-column
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else :disabled = "scope.row.disabledIs">
                          <span v-if='scope.column.property == "pauseState" || scope.column.property == "priceSwitch" || scope.column.property =="openClosePlan" || scope.column.property == "warningSwitch"'>
                            {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                            <i v-if = 'scope.column.property =="openClosePlan" ||scope.column.property == "priceSwitch"'
                            :class = "{'isSwitch':scope.column.property =='openClosePlan'||scope.column.property =='priceSwitch','active':(scope.column.property =='openClosePlan'||scope.column.property =='priceSwitch')&&scope.row[scope.column.property]==0}"></i>
                          </span>
                          <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </div> 
                        <template v-if='item.prop=="pauseState"'>
                          <el-switch
                            v-model="scope.row.pauseState"
                            class="switchStyle"
                            :active-value="0"  
                            :inactive-value="1"
                            active-color="#48916F"
                            inactive-color="#EAEFED"
                            @change = "changeStatus(scope.row.pauseState,scope)"
                            >
                          </el-switch>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column
                    prop="statusDesc"
                    label="状态"
                    width="150">
                    <template slot-scope="scope">
                      <span v-if="scope.row.statusDesc == '计划暂停(已暂停)'" class="statusOtherBtn">{{scope.row.statusDesc}}</span>
                      <span v-else-if="scope.row.statusDesc == '已暂停'" class="statusBtnFinish">{{scope.row.statusDesc}}</span>
                      <span v-else class="statusBtn">{{scope.row.statusDesc}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="zqtime"
                      label="出价"
                      width="180">
                      <template slot-scope="scope">
                        <span v-if="scope.row.chargeType == 1" class="isStyle">
                          <i>CPM</i>
                          <i>{{scope.row.price}}</i>
                        </span>
                        <span v-if="scope.row.chargeType == 2" class="isStyle">
                          <i>CPC</i>
                          <i>{{scope.row.price}}</i>
                        </span>
                        <span v-if="scope.row.chargeType == 3" class="isStyle">
                          <i style="display: inline-block;width:30%;vertical-align: middle;">OCPC</i>
                          <span style="display: inline-block;width:60%;vertical-align: middle;">
                            <i style="display: block;">一阶段cpc {{scope.row.price}}</i>
                            <i style="display: block;">优化阶段 {{scope.row.ocpxPrice}}</i>
                          </span>
                        </span>
                        <span v-if="scope.row.chargeType == 4" class="isStyle">
                          <i>CPD</i>
                          <i>{{scope.row.price}}</i>
                        </span>
                        <span v-if="scope.row.chargeType == 5" class="isStyle">
                          <i>OCPD</i>
                          <i>{{scope.row.price}}</i>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="zqtime"
                      label="限额(元/天)"
                      width="120">
                      <template slot-scope="scope">
                        <span v-if="scope.row.dailyBudget<=0">不限</span>
                        <span v-else>{{scope.row.dailyBudget}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="cvType"
                      label="转化目标"
                      width="120">
                      <template slot-scope="scope">
                        <span v-if="scope.row.cvType == null">-</span>
                        <span v-else-if="scope.row.cvType == 1">下载</span>
                        <span v-else-if="scope.row.cvType == 3">新增激活</span>
                        <span v-else-if="scope.row.cvType == 5">(自定义)激活</span>
                      </template>
                    </el-table-column>
                  <el-table-column
                      prop="zqtime"
                      label="投放时间"
                      width="200">
                      <template slot-scope="scope">
                        <span v-if="scope.row.endDate == '2038-01-19T00:00:00.000+08:00'">{{scope.row.startDate | dateFormat}} 至 长期</span>
                        <span v-else>{{scope.row.startDate | dateFormat}} 至 {{scope.row.endDate | dateFormat}}</span>
                        <i class="el-icon-edit adTeamEditBtn" @click="editAdTeamName(scope)"></i>
                      </template>
                    </el-table-column>
                  <el-table-column
                      prop="zqtime"
                      label="创建时间"
                      width="120">
                      <template slot-scope="scope">{{scope.row.createDate | dateFormat}}</template>
                    </el-table-column>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='220'
                  :show-overflow-tooltip="true"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button class="solid-tag-success" data-type = 'tjBtn' plain size="mini">调价</el-button>
                    <el-button class="solid-tag-warning" data-type = 'warningPriceSingle' plain size="mini">预警</el-button>
                    <el-popconfirm
                      title="您是否确认删除，删除后不可恢复？"
                      @confirm="deletePlanFn(scope.row.id)">
                      <template #reference>
                        <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
            <!-- 修改广告组名称 -->
            <el-dialog title="修改名称" :visible.sync="editNameVisible" width="40%">
              <el-form :model="editNameForm">
                <el-form-item label="广告组名称" label-width="80px">
                  <el-input v-model="editName"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editNameVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditName">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 修改日期 -->
            <el-dialog title="修改投放日期"
              :visible.sync="ediDateVisible"
              width="400px"
              center
              :show-close = "false">
              <div class="dialogIcon"><img :src='kw'></div>
              <el-tabs v-model="activeName" class="dateTab">
                <el-tab-pane label="长期投放" name="first">
                  <el-form :model="editDateForm">
                    <el-form-item label="开始日期" label-width="90px">
                      <el-date-picker
                        v-model="firstDate"
                        type="date"
                        placeholder="选择日期"
                        style="width:220px;"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="指定开始和结束日期" name="second">
                  <el-form :model="editDateForm">
                    <el-form-item label="开始结束日期" label-width="90px">
                      <el-date-picker
                        v-model="secondDate"
                        type="daterange"
                        range-separator="至"
                        style="width:220px;"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              </el-tabs>
              <div slot="footer" class="dialog-footer footerBottom">
                <el-button type="primary" class="newDialogCancel" @click="ediDateVisible = false">取消保存</el-button>
                <el-button type="primary" class="newDialog" @click="sureEditDate">确认保存</el-button>
              </div>
            </el-dialog>

            <!-- 修改时间段-->
            <el-dialog 
              title="批量修改时段"
              center
              :show-close = "false"
              :visible.sync="ediTimeVisible"
              width="1000px" >
              <div class="dialogIcon"><img :src='kw'></div>
              <el-tabs v-model="activeTime" @tab-click="handleTimeClick" class="timeTab">
                <el-tab-pane label="不限" name="first"></el-tab-pane>
                <el-tab-pane label="自定义" name="second">
                  <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
                </el-tab-pane>
              </el-tabs>
              <div slot="footer" class="dialog-footer">
                <el-button @click="ediTimeVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditTime">确 定</el-button>
              </div>
            </el-dialog>

            <!-- 下载报表 -->
            <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="280px">
              <span class="el-icon-loading uploading uploadIcon" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <earlyWarningDialog ref="earlyWarningDialogIs" :visible.sync="showDialogIs" :pubVue = 'pubVue'></earlyWarningDialog>
    <timeDialog :visible.sync="timeDialog" :objIdType = 20 :yulanList = "yulanList" :userContent = 'userContent' :pubVue="pubVue"></timeDialog>
    <zdyVivo :visible.sync="showzdyDialog" ref = 'zdyVivo' :pubVue="pubVue" :typeName = "typeName"></zdyVivo>
    <startLimitSingleVivoDialog ref = 'dayLimitDom' :visible.sync="showSingleDialog" :nowIs = "nowIs" :yulanList = "yulanList" :limitIs = "limitIs" :page = 'page' :userContent = 'userContent' :dayType = "dayType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleVivoDialog>
    <startStopDialog ref = 'startStopDialogIs' :visible.sync="startStopDialog" :pubVue="pubVue"></startStopDialog>
    <tiaojiaDialog ref = 'tiaojiaDialog' :visible.sync="tiaojiaDialogIs" :pubVue="pubVue"></tiaojiaDialog>
    <setCjWarningVivoDialog ref = 'setCj' :visible.sync="setCjWarningDialogShow" :yulanList = "yulanList" :groupsIds = 'groupsIds' :userContent = 'userContent' :pubVue="pubVue"></setCjWarningVivoDialog>
    <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :groupName = "groupName" :pubVue="pubVue" :command = "command+''" :typeName = "'team'"></alertStartStopDialog>
  </div>
</template>

<script>
import kw from '@/assets/img/kw.png';
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import earlyWarningDialog from '@/views/dialog/earlyWarningDialog';
import timeDialog from '@/views/dialog/timeDialog'
import startLimitSingleVivoDialog from '@/views/dialog/startLimitSingleVivoDialog'
import setCjWarningVivoDialog from '@/views/dialog/setCjWarningVivoDialog'
import zdyVivo from './zdyVivo'
import startStopDialog from '@/views/dialog/startStopPageDialog'
import tiaojiaDialog from '@/views/dialog/tiaojiaDialog'
import alertStartStopDialog from '@/views/dialog/alertStartStopDialog'
import TimeUnit from '../TimeUnit.vue';
import advertVivo from '@/views/auth/planManage/advertVivo'
import Qs from 'qs'
export default {
  components: {
    earlyWarningDialog,
    timeDialog,
    zdyVivo,
    startLimitSingleVivoDialog,
    setCjWarningVivoDialog,
    startStopDialog,
    tiaojiaDialog,
    alertStartStopDialog,
    timeUnit:TimeUnit
  },
  props:{
    newVue:Object,
  },
  data() {
    return {
      kw,
      pubVue: this,//空vue，用于子组件之间传值
      showDialogIs: false,
      timeDialog:false,
      tiaojiaDialogIs:false,
      showzdyDialog:false,
      showSingleDialog:false,
      nowIs : false,
      alertStartStopDialogIs:false,
      planStatus:false,//计划状态下拉列表是否展示
      setCjWarningDialogShow:false,//出价预警弹框
      startStopDialog:false,
      exprtVisible:false,//
      uploadingShow:false,//确认导出弹框
      warningOpenList:[],//['开启','关闭']
      qdoptions:{goal:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],//extensionTypes-推广目标列表
                userStatuList:[{text:'全部推广状态',id:'0'},{text:'启动中',id:'1'},{text:'暂停中',id:'2'},{text:'余额不足',id:'3'},{text:'达到账户日预算',id:'4'},{text:'达到计划日预算',id:'5'}],//选择状态
                sceneList:[{text:'全部流量场景',id:'1'},{text:'自有流量',id:'2'},{text:'联盟流量',id:'3'}],//extensionFlows-推广流量列表
                modelList:[{text:'普通投放',id:'0'},{text:'跟随投放',id:'1'}],
                adGroupStatus:[{text:'所有数据',id:'0'},{text:'已删除',id:'1'},{text:'已暂停',id:'2'},{text:'计划暂停 ',id:'3'},{text:'APP状态异常',id:'4'},{text:'到达广告组日限额',id:'5'},{text:'未到投放日期',id:'6'},{text:'未到投放时段',id:'7'},{text:'投放已结束',id:'8'},{text:'投放中',id:'9'},{text:'APP状态异常',id:'10'}],
},//adServingType-投放类型列表
      // titleList:[{name:'广告组名称',prop:'name'},
      //   {name:'广告组ID',prop:'id'},
      //   {name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
      //   {name:'设置广告组',prop:'openClosePlan'},//1是0否
      //   {name:'限额(元/天)',prop:'dailyBudget'},//日限额，<=0代表不限
      //   {name:'投放时间',prop:'startDate'},
      //   {name:'状态',prop:'statusDesc'},
      //   {name:'预警状态',prop:'warningStatus'},],//0-正常 1-禁用 2-删除
      titleList:[
        // {name:'广告组名称',prop:'name'},
        {name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
        // {name:'状态',prop:'statusDesc'},
        // {name:'曝光量',prop:'expose'},

        // {name:'创建时间',prop:'createDate'},
        // {name:'广告组ID',prop:'id'},
        // {name:'设置广告组',prop:'openClosePlan'},//1是0否
        // {name:'限额(元/天)',prop:'dailyBudget'},//日限额，<=0代表不限        
        // {name:'预警状态',prop:'warningStatus'},//0-正常 1-禁用 2-删除
      ],
        
      showStatusList:{
                      pauseState:[''],//0开启1关闭
                      openClosePlan:['是','否'],},                
      planName:'',//请输入计划名称查找
      nameId:'',//请输入计划ID查找
      searchPlanId:'',//请输入广告计划id
      channel:'',//请选择状态
      channelm:'',//推广目的
      channeln:'',//推广流量
      channelo:'',//推广场景
      channelp:'',//投放模式
      value1:[],
      tableData:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:2,
      planType:30,
      // dayType:3,
      dayType:null,
      dialogTitle:'',
      dayLimitIs:false,//校验日限额状态是否一致
      limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
      userContent:{},//登录用户信息&弹框默认
      url:['/adGroup/v1/checkGroupWarning',// 出价预警-adGroupIdList 广告组ID
          '/account/v1/checkAccountWarning',//预警是否开启 -ownerIdList     账户ID     
          '/modifyPrice/valid'],//是否设置过定时调价计划-groupIds 广告组ID
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      // closeGroupList:[],
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      btnShow:false,//鼠标划到表格上出现修改按钮
      editNameVisible:false,//修改广告组名称
      editNameForm:{},
      editName:'',//广告组名称修改字段
      editType:'',
      editId:'',
      ediDateVisible:false,//投放日期弹框显示
      editDateForm:{},
      activeName:'first',
      firstDate:'',//长期投放的日期
      secondDate:[],//修改开始和结束日期
      getGroupIds:'',//获取批量修改日期和时间段的ids集合
      ediTimeVisible:false,//修改时间段的弹框
      activeTime:'first',//进弹框显示第一个
      timeUnitShow:true,//设置执行时间

    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.params.userId  = this.userId
    this.params.userName  = this.userName
    this.params.deptId = this.deptId
    this.params.ownerId = this.$route.query.id
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.userContent.ownerId = this.$route.query.id
    this.userContent.ownerName = this.$route.query.name
    this.type =this.$route.query.type
    this.getId()
    console.log('team')
    this.fileList={
        "ownerId":this.params.ownerId,
        "operaType":20,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
    if(this.type==undefined){
        this.grouplistFn(1)
      }else{
        this.grouplistFn(1,this.type)
      }
    this.$refs.zdyVivo.allWords(2)
  },
  methods: {
    //tab切换
    clickChangeChannel(url){
      this.$router.push({
        path: '/vivoAccount-management/'+url,
        query: {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName,
        }
      })
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
    zdyFn(){
      this.$refs.zdyVivo.generateTimes(2)
    },
    exportTeam(){
      this.exprtVisible = true
    },
    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,dataSource =[],keywords = []
      // let video = []
      if(response.code==200){
        that.grouplistFn(this.page)
       
      }else{
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'error'
        })
      }
       this.$refs.upload.clearFiles()
    },
    //下载报表
    downLoadTable(){
      let _this = this,params ={},date = new Date().getTime(),myDate = new Date(),adGroupIds = []
      let Time2 = myDate.toISOString().slice(0,10)//推荐 yyyy-MM-dd
      //延伸  取yyyyMMdd
      // let Time3 = Time2.replace(/[^0-9]/ig,'').substring(0,8)
      params = {'ownerId':_this.params.ownerId,
                'deptId':_this.deptId,
                'userId':_this.userId,
                'userName':_this.userName,
                'startDate':_this.value1[0]||Time2,
                'endDate':_this.value1[1]||Time2,
                'adPlanId':_this.searchPlanId,
                // 'adGroupIds':_this.nameId!=''&&_this.nameId!=undefined?adGroupIds.push(_this.nameId):[],
                // 'adGroupIds':adGroupIds.push(_this.nameId),
                'adGroupIds':_this.nameId,
                'adGroupName':_this.groupName,
                'adGroupStatus':_this.channelp
              },
      _this.uploadingShow = true
      $http.axios.post("/api/vivo/advertisingGroup/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
      // $http.axios.post("/guanjia/advertisingGroup/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        _this.exprtVisible = false 
        //添加{responseType: 'blob'}  导出文件以下代码都是
        let blob = response.data
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = e => {
          let a = document.createElement('a')
          a.download = "广告组报表"+myDate+".xls"
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          _this.uploadingShow = false
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
    creatPlan(url,type){
      // this.$router.push("/plan-management/"+routerTo)
      this.$router.push({
        path: url,
        query: {
          ownerId: this.userContent.ownerId,
          ownerName:this.userContent.ownerName,
          type:type
        }
      })
    },    
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      row.ownerId
      let yjType = event.target.parentNode.dataset.type
        this.userContent.adGroupId = row.id
        this.userContent.adGroupName = row.adGroupName
        this.userContent.adPlanName = row.planName
        this.userContent.adPlanId = row.planId
        this.userContent.showStatus = row.showStatus
      let className = event.target.className

      if(yjType == 'warningPriceSingle'){
        this.groupsIds = [row.id]
        // this.userContent.dayBudget = row.dayBudget
        this.yjCheck('warningPriceSingle',this.url[1],row)
        
      }else if(yjType == 'editBtn'){
        this.goPlan('/plan-management/AdgroupTeamEdit',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.id,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'qtBtn'){
        this.goPlan('/plan-management/SetAdgroupPrice',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.id,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'tjBtn'){
        this.goPlan('/vivoAccount-management/PriceModifyGroupVivo',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.id,
          adGroupName:row.adGroupName
        })
      }
      if(className=="adTeamName"){
        this.goPlan('/vivoAccount-management/advertVivo',{
          type:row.id,
        })
       
      }
      if(column.property=="startSwitch"){
          let params = {"adGroupId": row.id}
          this.ssDetailParams = {}
          if(row.startSwitch == 0){
            this.ssDetailParams={currentPage:1,
                                      deptId:this.deptId,
                                      etime:"",
                                      pageSize:50,
                                      status:"",
                                      stime:"",
                                      openClosePlanId:"",
                                      userId:this.userId,
                                      userName:this.userName}
            this.getOpenCloseIdFn(params)
          }
        }
        if(column.property =="priceSwitch"){
          this.tiaojiaDialogIs = true

          this.$refs.tiaojiaDialog.getTimeSlotDetail(row.planId,this.planType,this.userContent)

        }
    },
    getOpenCloseIdFn(params){
        let _this = this, type = ''
        $http.axios.post("/api/advertisement/adPlan/v1/getOpenCloseId",params).then(res=> {
          if(res.data.code==200&res.data.content!=null){
            _this.startStopDialog = true
            _this.ssDetailParams.openClosePlanId=res.data.content
            _this.$refs.startStopDialogIs.timeSlotDetail(_this.ssDetailParams)
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
    // 启停页面跳转
    goPlan(url,query){//
      let _this = this,query1 =  {
            id: this.userContent.ownerId,
            name:this.userContent.ownerName}
        query = Object.assign({},_this.clone(query), query1)
        this.$router.push({
            path: url,
            query: query
          })
    }, 
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },

    // 分页
     handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      if(this.type==undefined){
        _this.findAll(_this.page)
      }else{
        _this.findAll(_this.page,this.type)
      }
    },
    handleCurrentChange(val) {
      this.page = val
      if(this.type==undefined){
        _this.findAll(_this.page)
      }else{
        _this.findAll(_this.page,this.type)
      }
    }, 
    //分页方法
    findAll (page,searchPlanId) {
      //获取数据接口（调用封装的接口）
      searchPlanId = searchPlanId ||this.searchPlanId
      this.page = page
      this.grouplistFn(page,searchPlanId,'',this.groupName,this.nameId,this.channelp,this.value1)
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let groupsIds = [],yulanList = [],objList = [],startSwitch = [],numStr = 0,num = 0
      _this.limitIs = false
      val.forEach((obj,i,arr)=>{
        let cur = obj.pauseState
        groupsIds.push(obj.id)
        startSwitch.push(obj.startSwitch)
        // yulanList.push({ 'adGroupName':obj.name,'adGroupId':obj.id,'planName':obj.campaignName,'planId':obj.campaignId,'price':obj.price,'upPriceStatus':0})
        yulanList.push({ 'adGroupName':obj.name,'adGroupId':obj.id,'planName':obj.campaignName,'planId':obj.campaignId,'price':obj.price,'dayBudget':obj.dailyBudget})

        // if(obj.dayBudget == '不限'){
        //   _this.limitIs = true
        // }
        if(obj.dailyBudget =='不限'){
            _this.limitIs = true
            numStr++
          }else{
            num++
          }
        objList[cur] = cur
        // if(objList.length==0){
        //   objList[i] = cur
        // }else{
        //   objList[cur] = cur
        // }
      })
        _this.dayLimitIs = true
        if(val.length == num || val.length ==numStr){
            _this.dayLimitIs = true
          }else{
            _this.dayLimitIs = false
            // _this.$message({
            //   showClose: true,
            //   // message: "选择的计划日限额不一致，选择同一种计划日限额后才可操作",
            //   message: "请选择统一为不限或有具体日限额的数据",
            //   type: 'error'
            // })
          }
      //批量修改日期和时间段不需要校验是否状态相同，先注释，别的批量操作有要求的话再放开
      // objList.forEach((obj,i)=>{
      //   if(objList.length == 2&&objList[0]!=undefined){
      //   _this.planStatus = false
      //   _this.warningOpenList = []
      //   _this.$message({
      //       showClose: true,
      //       message: "选择的计划状态不一致，选择同一种计划状态后才可操作",
      //       type: 'error'
      //     })        
      //   }else{
      //     _this.planStatus = true
      //   }        
      // })
      _this.groupsIds = groupsIds
      _this.startSwitch = startSwitch
      _this.yulanList = yulanList
      if(val.length == 0){
        _this.warningOpenList = []
        _this.planStatus = false

      }else{
        _this.planStatus = true
      }
    },
      //重置
      resetForm(){
        let _this = this
        _this.clearFn()
        _this.grouplistFn()
        
      },
      clearFn(){
        let _this = this
        _this.nameId = ''
        _this.groupName = ''
        _this.channelp = ''
        _this.value1 = ''
      },
    //按钮开关
    getBrandList(){},
    
   
    //广告组列表
    grouplistFn(page,searchPlanId,pageCount,groupName,nameId,channelp,value1){
      let _this = this,params,pagetTxt,price,ocpc,deepOcpc,adGroupIds = []
      pagetTxt = {'pageSize' : pageCount|| 10,
              'pageIndex' : page || 1,
              'adGroupName':groupName,
              // 'adGroupIds':nameId!=''&&nameId!=undefined?adGroupIds.push(nameId):[],
              'adGroupIds':nameId,
              'adPlanId':searchPlanId||_this.searchPlanId,
              'adGroupStatus':channelp}//   广告组状态
      if(value1 != undefined&&value1 != ''){
        pagetTxt.startDate = value1[0]
        pagetTxt.endDate = value1[1]
      }
      params = Object.assign({},_this.clone(_this.params), pagetTxt)      
      $http.axios.post("/api/vivo/advertisingGroup/list",params).then(res=> {
      // $http.axios.post("/guanjia/advertisingGroup/list",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          if(res.data.data.list != null){
              _this.tableData = res.data.data.list
      
              _this.tableData.forEach((obj,i)=>{
                if(obj.dailyBudget<=0){
                  obj.dailyBudget = '不限'
                }
                if(obj.warningStatus == null){
                  obj.warningStatus = '未设置'
                }
              })
            _this.totalRow = res.data.data.pageInfo.rowCount
          }else{
            _this.tableData = []
            _this.totalRow = 0

          }
          // _this.clearFn()
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
    //预警出价
    upAdPlanWarningSwitchFn(command){
      let _this = this,type,params

      params={'adGroupIds':_this.groupsIds,
        'warningType' : command,//预警开关(0开 1关 )
        "deptId": _this.params.deptId,
        "userId": _this.params.userId,
        "userName": _this.params.userName}
      $http.axios.post("/api/vivo/adGroup/v1/upGroupWarSwitch",params).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();          
        if(res.data.code==200){
          _this.grouplistFn(_this.page)
          type = "success"
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    //V3.0批量暂停/启动广告组OPPO
    
    updateOppoAdGroupStatusFn(command,groupsIds,scope){//updateOppoAdGroupStatus
      // "deptId": 0,
      // "groupsIds": [],
      // "ownerId": 0,
      // "status2": "",
      // "userId": 0,
      // "userName": ""
      // let _this = this,groupsIdsString,type,params,params1
      let _this = this,groupsIdsString,type,params 
      groupsIds = groupsIds||_this.groupsIds
      groupsIdsString = String(groupsIds)
      // params.append('ids', groupsIdsString)
      // params.append('type', 20)
      // params={'ids':groupsIdsString,
      //   'type' : 20}
      
        params = {
          'advertiserId':this.$route.query.id,
          'ids':groupsIdsString,
          'startAndStop':command
        }      
     
      
      // params = Object.assign({},_this.clone(_this.params), params1)
      // $http.axios.post("/api/advertisement/adGroup/v1/updateOppoAdGroupStatus",params).then(res=> {
      // $http.axios.post("/api/vivo/openClose/valid",params).then(res=> {
      $http.axios.post("/api/vivo/advertisingGroup/startAndStop",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      // $http.axios.post("/guanjia/openClose/valid",params).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();
        if(res.data.code==200){
        _this.grouplistFn(_this.page)
          type = "success"
        }else{
          type = "error"
          this.tableData[scope.$index].pauseState = command?0:1
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // 批量启停设置
    updateOppoSet(type){
      console.log('startStop')
      let _this = this
      let groupsIds = []//声明一个空数组
      //  _this.ownerIdList = [1100018542,1100018078]
        // that.getzdyData 去重
        _this.groupsIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          groupsIds.push(v)
        }
      })
      
      if(_this.groupsIds.length!=0){
        _this.params.groupsIds = _this.groupsIds
        _this.btnIsFn(_this.params,type)
        return true
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
    // 按钮判断
    btnIsFn(params,type){
      // console.log(params)
      let _this = this
      _this.getGroupIds = String(params.groupsIds)
      if(type == 'startStop'){
        _this.timeDialog = true
      }else if(type == 'planStatus'&&_this.planStatus){
            _this.checkOpenCloseFn(params.groupsIds)//需求更改暂时注释
          _this.warningOpenList = ['开启','关闭']
      }else if(type == 'dayLimt'){

      }else if(type == 'updatedate'){
        _this.ediDateVisible = true
        // console.log(_this.getGroupIds)
      }else if(type == 'updateTime'){
        _this.ediTimeVisible = true
      }

    },  
    //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs(type,url,dayType){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      // /adPlan/v1/checkPlanDailyMoneySet
      // if(type == 'warningPrice'){
      //   this.checkoutSetIs(type)
      //   // this.setCjWarningDialogShow = true
      // }else{
        let _this = this
        let isTrue = _this.updateOppoSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        _this.dayType = dayType
        console.log(this.yulanList)
        if(isTrue){
            if(type == 'dayLimit'|| type == "dayLimitPrice"){
              _this.yulanList.forEach(obj=>{
                if(type == 'dayLimit'){
                  obj.price = obj.dayBudget
                }                
              })
              
              _this.dayLimitCheckFn(dayType)
            }else{
            _this.yjCheck(type,url)
            }
        }
      // }
     
    },
    dayLimitCheckFn(dayType){
      let _this = this,idList = [],params = null
        _this.groupsIds.forEach(obj=>{
          idList.push(obj)
        }) 
        params = {}
        params = {"ids":idList.join(','),"type":dayType}   
        $http.axios.post("/api/vivo"+_this.url[2],Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.data
          if(content == null){
              this.showSingleDialog = true
              this.nowIs = false
            }else{
              let text = content.toString()+'广告组已经设置过调价执行计划是否确认添加新计划'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                this.showSingleDialog = true
                this.nowIs = true
              }).catch(()=>{
                
              })
            }
            if(dayType == 3){
              this.dialogTitle = '批量日限额'
            }else{
              this.dialogTitle = '批量调价'
            }
            
          }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    yjCheck(type,url,row,adPlanId){
      let _this = this,messageContent,params = null,yulanList = []
      if(type == 'warningOpen'){
        params = []
        params = [_this.userContent.ownerId]
      }
      // else if(type == 'warningPriceSingle' || type == 'warningPrice'){
      //   params = []
      //   params.push(_this.params.ownerId)
      // }
      else if(type == 'warningPriceSingle'){
        // yulanList.push({ 'adGroupName':row.name,'adGroupId':row.id,'planName':row.campaignName,'planId':row.campaignId,'price':row.price,'upPriceStatus':0})
        yulanList.push({ 'adGroupName':row.name,'adGroupId':row.id,'planName':row.campaignName,'planId':row.campaignId,'price':row.price,'dayBudget':row.dailyBudget})
        params = []
        params.push(_this.params.ownerId)
        _this.yulanList = yulanList
      }else if(type == 'warningPrice'){
        params = []
        params.push(_this.params.ownerId)
      }
      else{
      _this.groupsIds.forEach(obj=>{
        params = []
        params.push(obj)
      })
    }

      $http.axios.post("/api/vivo"+url,params).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.data
          if(content == null){
            if(type != 'dayLimit'){
              messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
            
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }
              
            }else{
             if(type == 'warningPrice'){
               console.log('warningPrice')
                _this.checkoutSetIs()
                _this.warmType = type
                _this.warmTypeBtn = ''
              }else if(type == 'warningPriceSingle'){
                _this.warmTypeBtn = type
                _this.warmType = ''
                // _this.warningOpenList = ['开启','关闭']
                _this.setCjWarningDialogShow = true
                // if(adPlanId){
                  _this.$refs.setCj.getAdGroupWarningDetailFn({'adGroupId':row.id,'adPlanId':row.campaignId,'ownerId':_this.userContent.ownerId})
                  
                // }
              }else if(type == 'warningOpen'){
                _this.warningOpenList = ['开启','关闭']
              }
            }
          }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      // 批量出价预警--》没有回显值
      checkoutSetIs(){
        let _this = this,params = [],url = _this.url[0]
         _this.groupsIds.forEach(obj=>{
          params.push(obj)
        }) 
        $http.axios.post("/api/vivo"+url,params).then(res=> {
          if(res.data.code == 200){
            if(res.data.data == null){
                _this.setCjWarningDialogShow = true
            }else{
              let text = res.data.data.toString()+'广告组已经设置过出价预警是否修改原有出价'
              _this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                _this.setCjWarningDialogShow = true
              }).catch(()=>{
                
              })
            }
          }
        
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      checkOpenCloseFn(groupsIds){
      let _this = this,params
      //vivo校验当日是否存在启停计划
      groupsIds=groupsIds.join(',')
        params = {'ids': groupsIds,'type':20}
      $http.axios.post("/api/vivo/openClose/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          if(res.data.content!= ''){
            // res.data.content.forEach(obj=>{
            //   _this.closeGroupList.push(obj)
            // })
            _this.closeGroupList = res.data.content
          }else{
          // _this.closeGroupList = []
          _this.closeGroupList = ''
          }

        }
      }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
    changeStatus(value,scope) {
      //先把值赋为原来的值，保证swich状态不变
      this.groupsIds=[scope.row.id]
      let _this = this,params = {},groupsIdsValue,groupName = []
      this.tableData[scope.$index].pauseState = value == 0?1:0//需求更改暂时注释
      // params = {
      //   'advertiserId':this.$route.query.id,
      //   'ids':this.groupsIds,
      //   'startAndStop':value
      // }
      groupsIdsValue = String(this.groupsIds)
      params = {
        // 'advertiserId':this.$route.query.id,
        'ids':groupsIdsValue,
        'type':20
      }
        // $http.axios.post("/api/vivo/openClose/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        // if(res.data.code==200){
        //   if(res.data.data!= null){
        //     res.data.data.forEach(obj=>{
        //         groupName.push(obj.name)
        //     })
        //     _this.groupName = groupName
        //     _this.command = value
        //     _this.alertStartStopDialogIs = true   
        //   } else{
          this.tableData[scope.$index].pauseState = value
          this.updateOppoAdGroupStatusFn(scope.row.pauseState,[scope.row.id],scope)//需求更改暂时打开
          // }

      //   }
      // }).catch(error=>{
      //   let messageContent =error.message || '请查看网络，稍后再试'
      //   _this.$message({
      //     showClose: true,
      //     message: messageContent,
      //     type: 'error'
      //   })        
      // }) 
    },
    changeStatusList(command){
        // let _this = this,groupName = [],text = ''
        //   _this.closeGroupList.forEach((obj,i)=>{
        //       groupName.push(obj)
        //   })
        let _this = this,groupName = '',text = ''
        groupName = _this.closeGroupList
          if(groupName!=''){
            // _this.groupName = groupName.join('、')
            _this.groupName = groupName
            _this.command = command
            _this.alertStartStopDialogIs = true     
          }else{
            this.updateOppoAdGroupStatusFn(command)
          }

      
    }, 
         
    // 删除
    deletePlanFn(planId){
      let _this = this,params = {},type = ''
      // params = {"ownerId": _this.userContent.ownerId,
      //   "adGroupIdList": [planId],
      //   "userId": _this.userContent.userId,
      //   "userName": _this.userContent.userName}
      params = {"advertiserId": String(_this.userContent.ownerId),
        "ids": String(planId)}
      // $http.axios.post("/api/vivo/advertisingGroup/dels",params).then(res=> {
      $http.axios.post("/api/vivo/advertisingGroup/dels",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.grouplistFn(this.page)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 1){
        // return 'color: #67C23A' 
        return 'color: #2F674D' 
      }else{
          return 'color: #606266' 
      }
    },

    //修改广告组名称
    editAdTeamName(item){
      // console.log(item)
      this.editId = String(item.row.id)
      if(item.column.label == '广告组名称'){
        this.editNameVisible = true
        this.editName = item.row.name
      }else if(item.column.label == '投放时间'){
        this.ediDateVisible = true
        if(item.row.endDate == '2038-01-19T00:00:00.000+08:00'){
          this.activeName = 'first'
          this.firstDate = item.row.startDate
        }else{
          this.activeName = 'second'
          this.secondDate[0] = item.row.startDate
          this.secondDate[1] = item.row.endDate
        }
      }
    },

    //修改广告组名称和时间段
    sureEditName(){
      let that = this, params = {}
      params = {
        'advertiserId':String(this.$route.query.id),
        'ids':that.editId,
        'type':6,
        'name':that.editName,
      }
      $http.axios.post("/api/vivo/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          that.editNameVisible = false
          that.grouplistFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改单个修改日期
    sureEditDate(groupId){
      let that = this, params = {}
      params = {
        'advertiserId':String(this.$route.query.id),
        'ids':that.editId||that.getGroupIds,
        'type':2,
      }
      if(that.activeName == 'first'){
        params.startDate = that.firstDate
        params.endDate = '2038-01-19'
      }else{
        params.startDate = that.secondDate[0]
        params.endDate = that.secondDate[1]
      }
      $http.axios.post("/api/vivo/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          that.ediDateVisible = false
          that.grouplistFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //批量修改时间段
    sureEditTime(){
      let that = this, params = {},
      // scheduleTime = {
      //   key:'',
      //   value:''
      // },
      scheduleTime = [],
      timeStr = [],timeStrSingle = ''
      params = {
        'advertiserId':String(this.$route.query.id),
        'ids':that.getGroupIds,
        'type':3,
      }
      // console.log(that.$refs.timeStrArray)
      timeStr = that.$refs.timeStrArray.timeStr
      timeStr.forEach(function(e,i){
        if(e != ''){
          scheduleTime.push({
            'week':i+1,
            'time':e
          })
        }
      })      
      console.log(typeof JSON.stringify(scheduleTime))
      if(that.activeTime == 'second'){
        // params.scheduleTime = scheduleTime
        params.scheduleTime = JSON.stringify(scheduleTime)
      }
      $http.axios.post("/api/vivo/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      // $http.axios.post("/guanjia/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          console.log(res)
          that.ediTimeVisible = false
          that.grouplistFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改投放日期中tab切换投放方式
    handleTimeClick(tab, event) {
      console.log(tab, event);
    }


  },
//   
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */

  /* 操作按钮样式开始 */
  /* .planeBtn,.dayLimitBtn,.offBtn,.delBtn{
    display: inline-block;
    margin: 0 10px;
  } */
  /* 操作按钮样式结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .adTeamEditBtn{
    padding-left:10px;
    color:#333;
    cursor: pointer;
    display:none;
  }
  .el-table .el-table__body tr:hover td .adTeamEditBtn{
    display:inline-block;
  }
  .timeTab .el-tabs__item{
    padding:0!important;
    padding:10px!important;
    border:1px solid #ccc!important;
  }
  .statusBtn{
    padding:3px 8px;
    background:rgba(103,194,58,.1);
    color:#67c23a;
  }
  .statusOtherBtn{
    padding:3px 8px;
    background:rgba(245,108,108,.1);
    color:#f56c6c;
  }
  .statusBtnFinish{
    padding:3px 8px;
    background:rgba(144,147,153,.1);
    color:#909399;
  }
  .uploadIcon{
    font-size:40px;
    display:block;
    text-align:center;
  }
  .isStyle i{
    font-style:normal;
    padding-right:10px;
  }
  /* tab切换当前样式 */
  .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }
    /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  .footerBottom{
    padding-bottom:30px;
  }
  ::v-deep .dateTab .el-tabs__nav{
    margin-left:14px;
  }
  ::v-deep .dateTab .el-tabs__item{
    background:#ECF3F0!important;
    padding:0!important;
    padding:0 20px!important;
    margin:0 10px;
    border-radius:4px;
    color:#2F674D;
    font-weight:500;
  }
  ::v-deep .dateTab .el-tabs__item.is-active{
    background:#48916F!important;
    color:#fff;
  }
  
</style>
