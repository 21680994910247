<template>
  <div class="hlBread">
    <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
      <el-breadcrumb-item 
        v-for="(item,index) in titleData" :key="index" 
        :to="url[index]">
        {{item}}
        </el-breadcrumb-item>
    </el-breadcrumb>
  </div>

</template>
<script lang="ts">
import Vue from 'vue'

export default {
  props: {
    titleData: {
      type: Array,
      default: null
    },
  },
  data() {
    return {
      id:null,
      name:null,
      url:[]
    }
  },
  mounted(){
    this.id = Number(this.$route.query.ownerId)
    this.name = this.$route.query.ownerName
    this.url=['/plan-management/AccountSelection','/plan-management/AccountSelection','/plan-management/advertisingPlan?id='+this.id+'&name='+this.name]
    console.log(444)

  },
  methods:{

    // 计划、调价、启停页面跳转
    goPlan(type){//
      let _this = this
      _this.$router.push({
          path: _this.url[type],
          query: {
            id: _this.id,
            name:_this.name,
          }
        })
      // this.$router.push("/plan-management/"+urlList[num]+"/"+id+"/"+name)
    },
  },
}
  </script>
  <style scoped>
    /* .breadNav{
      margin-bottom:20px;    
    } */
    .hlBread{
      width: 800px;
      padding:10px 20px;
      margin-bottom:20px;
      background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
      border-radius: 10px 0px 0px 0px;
      color:#999;
    }

  </style>

