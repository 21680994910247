<template>
  <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/hwAccount-management/AccountSelectionHw' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>广告计划</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-dropdown trigger="click" @command = "changeStatusList">
                <el-button type="primary" size="small" @click="updateSetFn('planStatus')">
                  修改状态
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='item.openId'>{{item.name}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-button type="primary" size="small" @click="warmIs('warningPrice',url[1])">
                  出价预警
              </el-button>
              
              <el-dropdown trigger="click" @command = "upAdPlanWarningSwitchFn">
                <el-button type="primary" size="small" @click="warmIs('warningOpen',url[1])">
                  预警开关
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> 
              <el-button type="primary" size="small" @click="warmIs('dayLimit',url[2],4)">批量子任务调价</el-button>
              <el-button type="primary" size="small" @click="warmIs('keyWordsDayLimit',url[2],5)">批量关键词调价</el-button>
              <el-button type="primary" size="small" @click="updateSetFn('startStop')">批量启停设置</el-button>
              <!-- <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/startStopGroupHw')">启停计划列表页面</el-button>
              <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/PriceModifyAdvertHw')">调价计划列表页面</el-button> -->
              <el-button type="primary" size="small" @click = "zdyFn">自定义列</el-button>
              <el-button type="primary" size="small" @click = "exportTeam">下载报表</el-button>
            </div>
          </div>  

          <div class="chanelTab">
            <span class="channel_vivo" @click="clickChangeChannel('adPlanHw')" >任务</span>
            <span class="channel_vivo channelActive">子任务</span>
          </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="">
                  <div class="block">
                    <el-date-picker
                    v-model="value1"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                  </div>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "kw" clearable placeholder="请输入子任务名称"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择子任务状态" multiple v-model="subTaskType">
                    <el-option
                      v-for="(item,i) in subTaskTypeOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择转化目标" v-model="conversionTarget">
                    <el-option
                      v-for="(item,i) in conversionTargetOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="">
                  <el-input v-model = "selectWords" clearable placeholder="请输入关键词"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="请选择匹配方式" v-model="matchingType">
                    <el-option
                      v-for="(item,i) in matchingTypeOption"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select v-model="chargingType" clearable multiple placeholder="请选择计费类型" >
                    <el-option
                      v-for="(item,i) in chargingTypeOptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-button type="primary" class="newQuery" size="small" @click = "grouplistFn(page)">查询</el-button>
                  <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  <span class="moreBtns">
                    <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/startStopGroupHw')">启停计划列表页面</el-button>
                    <el-button type="primary" size="small" @click="goPlan('/hwAccount-management/PriceModifyAdvertHw')">调价计划列表页面</el-button>
                  </span>
                </el-form-item>
              </el-form> 
            </div>
          </div>
          <div class="clearfix">
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                style=" cursor:pointer;"
                tooltip-effect="dark"
                @cell-click="clickcell"
                @sort-change = "changeSort"
                :cell-style="setRowStyle"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <template >
                    <el-table-column
                    v-for="(item, index) in titleList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :sortable = "item.prop == 'bid'||item.prop == 'viewSumFormat'||item.prop == 'clickSumFormat'||item.prop == 'downloadSumFormat'||item.prop == 'accurateCostFormat'||item.prop == 'ctrFormat'||item.prop == 'cpcFormat'||item.prop == 'downloadRatioFormat'||item.prop == 'costPerDownloadFormat'||item.prop == 'ecpmFormat'?true:false"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else :disabled = "scope.row.disabledIs">
                          <span v-if='scope.column.property == "openStopPlanIds"||scope.column.property =="modifyKeyPricePlanIds" ||scope.column.property =="modifyPricePlanIds"'>
                            {{ showStatusList[scope.column.property][Number(Boolean(scope.row[scope.column.property]))] }}
                            <i v-if = 'scope.column.property =="openStopPlanIds" ||scope.column.property =="modifyKeyPricePlanIds" ||scope.column.property =="modifyPricePlanIds" '
                            :class = "{'isSwitch':scope.column.property =='openStopPlanIds'||scope.column.property =='modifyKeyPricePlanIds' ||scope.column.property =='modifyPricePlanIds','active':(scope.column.property =='openStopPlanIds'||scope.column.property =='modifyKeyPricePlanIds' ||scope.column.property =='modifyPricePlanIds')&&scope.row[scope.column.property]!=0}"></i>
                          </span>
                          <span v-else-if='scope.column.property == "subTaskStatus"'>
                            <span class="statusOtherBtnwei" v-if="!scope.row.inDelivery">
                              {{scope.row.subTaskStatus}}
                              <!-- <el-popover
                                placement="top-start"
                                title=""
                                width="200"
                                trigger="hover">
                                  {{scope.row.unDeliveryReasonList.toString()}}
                                  <i slot="reference" class="el-icon-warning warningIcon"></i>
                              </el-popover> -->
                            </span>
                            <span class="statusBtn" v-else>{{scope.row.subTaskStatus}}</span>
                          </span>
                         
                          
                          <span v-else-if='item.prop=="statusTypeIs"'>
                             <template >
                              <el-switch
                                v-model="scope.row.statusTypeIs"
                                class="switchStyle"
                                :active-value="1"  
                                :inactive-value="0"
                                :disabled = "scope.row.subTaskStatus=='暂停'||scope.row.subTaskStatus=='停用'||scope.row.subTaskStatus=='启用'?false:true"
                                active-color="#48916F"
                                inactive-color="#EAEFED"
                                @change = "changeStatusFn(scope.row.statusTypeIs,scope)"
                                >
                              </el-switch>
                            </template>
                            
                          </span>
                          <span v-else>
                          {{ scope.row[scope.column.property] }}
                          </span>
                          </div>
                      </template>
                    </el-table-column>
                  </template>
                <el-table-column 
                  fixed = 'right'
                  align="center"
                  :show-overflow-tooltip="true"
                  width="100"
                  label="操作">
                  <template>
                    <el-button class="solid-tag-success" data-type = 'warningPriceSingle' plain size="mini">出价预警</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="clearfix">
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
            <!-- 修改子任务名称 -->
            <el-dialog title="修改名称" :visible.sync="editNameVisible" width="40%">
              <el-form :model="editNameForm">
                <el-form-item label="子任务名称" label-width="80px">
                  <el-input v-model="editName"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editNameVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditName">确 定</el-button>
              </div>
            </el-dialog>
            <!-- 修改日期 -->
            <el-dialog title="修改投放日期" :visible.sync="ediDateVisible" width="50%">
              <el-tabs v-model="activeName">
                <el-tab-pane label="长期投放" name="first">
                  <el-form :model="editDateForm">
                    <el-form-item label="开始日期" label-width="100px">
                      <el-date-picker
                        v-model="firstDate"
                        type="date"
                        placeholder="选择日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="指定开始和结束日期" name="second">
                  <el-form :model="editDateForm">
                    <el-form-item label="开始结束日期" label-width="100px">
                      <el-date-picker
                        v-model="secondDate"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
              </el-tabs>
              <div slot="footer" class="dialog-footer">
                <el-button @click="ediDateVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditDate">确 定</el-button>
              </div>
            </el-dialog>

            <!-- 修改时间段-->
            <el-dialog title="批量修改时段" :visible.sync="ediTimeVisible" width="70%" >
              <el-tabs v-model="activeTime" @tab-click="handleTimeClick" class="timeTab">
                <el-tab-pane label="不限" name="first"></el-tab-pane>
                <el-tab-pane label="自定义" name="second">
                  <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
                </el-tab-pane>
              </el-tabs>
              <div slot="footer" class="dialog-footer">
                <el-button @click="ediTimeVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditTime">确 定</el-button>
              </div>
            </el-dialog>

            <!-- 下载报表 -->
            <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="280px">
              <span class="el-icon-loading uploading uploadIcon" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
    <startStopSetDialog :visible.sync="startStopSetDialog" :objIdType = 2 :userContent = 'userContent' :yulanList = "yulanList" :page = "page" :pubVue="pubVue"></startStopSetDialog>
    <zdyHw  ref = 'zdyHw' :pubVue="pubVue" :typeName = "typeName"></zdyHw>
    <startStopDialog ref = 'startStopDialog' :visible.sync="startStopDialogIs" :page = "page" :pubVue="pubVue"></startStopDialog>
    <startLimitSingleDialog ref = 'dayLimitDom' :visible.sync="showSingleDialog" :nowIs = "nowIs" :yulanList = "yulanList" :limitIs = "limitIs" :page = 'page' :userContent = 'userContent' :dayType = "dayType" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleDialog>
    <tiaojiaDialog ref = 'tiaojiaDialog' :visible.sync="tiaojiaDialogIs" :planType = "planType" :pubVue="pubVue"></tiaojiaDialog>
      <!-- 出价预警 -->
    <setCjWarningDialog ref = 'setCj' :visible.sync="setCjWarningDialogShow" :yulanList = "yulanList" :groupsIds = 'groupsIds' :page = "page" :userContent = 'userContent' :pubVue="pubVue"></setCjWarningDialog>
    <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :groupName = "groupName" :pubVue="pubVue" :command = "command+''" :typeName = "'team'"></alertStartStopDialog>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import startStopSetDialog from '@/views/dialog/startStopSetHwDialog'
import startLimitSingleDialog from '@/views/dialog/startLimitSingleHwDialog'
import tiaojiaDialog from '@/views/dialog/tiaojiaHwDialog'
import zdyHw from './zdyHw'
import startStopDialog from '@/views/dialog/startStopHwDialog'//列表->启停计划->是：点击查看启停详情列表弹框
import alertStartStopDialog from '@/views/dialog/alertStartStopHwDialog'
import TimeUnit from '../TimeUnit.vue';
import setCjWarningDialog from '@/views/dialog/setCjWarningHwDialog'
import Qs from 'qs'
import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')

export default {
  components: {
    startStopSetDialog,//批量启停设置
    zdyHw,
    startLimitSingleDialog,
    startStopDialog,
    alertStartStopDialog,
    timeUnit:TimeUnit,
    setCjWarningDialog,//出价预警弹框
    tiaojiaDialog,//调价列表
  },
  props:{
    newVue:Object,
  },
  data() {
    return {
      pubVue: this,//空vue，用于子组件之间传值
      startStopSetDialog:false,
      alertStartStopDialogIs:false,
      tiaojiaDialogIs:false,//调价列表
      planStatus:false,//计划状态下拉列表是否展示
      startStopDialogIs:false,
      setCjWarningDialogShow:false,//出价预警弹框是否展示
      exprtVisible:false,//
      showSingleDialog:false,//批量调价
      uploadingShow:false,//确认导出弹框
      kw:'',//搜索->子任务名称/ID
      nowIs : false,

      warningOpenList:[],//['开启','关闭']
      orderByList:{'viewSumFormat':'viewSum','clickSumFormat':'clickSum','downloadSumFormat':'downloadSum','accurateCostFormat':'cost','ctrFormat':'ctr','cpcFormat':'cpc','downloadRatioFormat':'downloadRatio','costPerDownloadFormat':'costPerDownload','ecpmFormat':'ecpm'},//排序字段
     
      titleList:[
      {name:'子任务名称',prop:'subTaskName'},
      {name:'子任务ID',prop:'subTaskId'},
      {name:'子任务出价',prop:'subTaskPrice'},
      {name:'关键词出价',prop:'subTaskKeyPrice'},
      {name:'投放开关',prop:'statusTypeIs'},// 
      // {name:'组类型',prop:'displayTypeFormat'},// 
      // {name:'投放状态',prop:'subTaskStatus'},// 
      {name:'启停计划',prop:'openStopPlanIds'},// 
      {name:'出价计划',prop:'modifyPricePlanIds'},// 
      {name:'关键词计划',prop:'modifyKeyPricePlanIds'},// 
      ],
        
      showStatusList:{
        status:[''],//0开启1关闭
        openStopPlanIds:['否','是'],
        modifyKeyPricePlanIds:['否','是'],
        modifyPricePlanIds:['否','是'],
      },                
      planName:'',//请输入计划名称查找
      adGroupType:'',//组类型
      programManagStatus:'',//程序化状态
      adGroupStatus:[],//广告组状态
      sortMode:'',//0-降序 1-升序 默认降序
      orderBy:'',//排序字段 
      value1:[formatDate(new Date()),formatDate(new Date())],//时间
      tableData:[],
      params:{},
      show:false,
      options: [{
        value: '1',
        label: '全部导出'
      }, {
        value: '2',
        label: '全部删除'
      }, ], //批量操作
      value:'',//批量操作绑定值
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      dataList:'',
      groupsIds:[],
      yulanList:[],
      typeName:2,
      planType:30,
      dayType:null,
      dialogTitle:'',
      dayLimitIs:false,//校验日限额状态是否一致
      limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
      userContent:{},//登录用户信息&弹框默认
      url:['adGroup/v1/checkGroupWarning',// 出价预警-adGroupIdList 广告组ID
          'accountWarning/checkAccountWarning',//预警是否开启 -ownerIdList     账户ID     
          'modifyPrice/valid'],//是否设置过定时调价计划-groupIds 广告组ID
      myHeaders: {Authorization:''},
      fileList:{},
      command:'',
      groupName:'',
      closeGroupList:'',
      planIds:null,
      warmType:'',
      warmTypeBtn:'',
      btnShow:false,//鼠标划到表格上出现修改按钮
      editNameVisible:false,//修改广告组名称
      editNameForm:{},
      editName:'',//广告组名称修改字段
      editType:'',
      editId:'',
      ediDateVisible:false,//投放日期弹框显示
      editDateForm:{},
      activeName:'first',
      firstDate:'',//长期投放的日期
      secondDate:[],//修改开始和结束日期
      getGroupIds:'',//获取批量修改日期和时间段的ids集合
      ediTimeVisible:false,//修改时间段的弹框
      activeTime:'first',//进弹框显示第一个
      timeUnitShow:true,//设置执行时间
      sums:{},//合计
      subTaskType:[],//子任务状态
      subTaskTypeOption:[{
        value:'ON',
        label:'启用'
      },{
        value:'SUSPEND',
        label:'暂停'
      },{
        value:'OFF',
        label:'停用'
      },{
        value:'DELETE',
        label:'删除'
      }],
      conversionTarget:'',//转化目标
      conversionTargetOption:[{
        value:1,
        label:'激活应用'
      },{
        value:2,
        label:'启动应用'
      },{
        value:3,
        label:'次日留存'
      },{
        value:4,
        label:'付费'
      },{
        value:5,
        label:'提交表单'
      },{
        value:6,
        label:'授权'
      },{
        value:7,
        label:'注册'
      },{
        value:9,
        label:'关键页面访问'
      },{
        value:14,
        label:'申请'
      },{
        value:18,
        label:'下单'
      },{
        value:21,
        label:'预约'
      },{
        value:101,
        label:'关键行为1'
      },{
        value:102,
        label:'关键行为2'
      }],
      selectWords:'',//关键词
      matchingType:'',//匹配方式
      matchingTypeOption:[{
        value:'ACCURATE',
        label:'精确匹配'
      },{
        value:'FUZZY',
        label:'广泛匹配'
      }],
      chargingType:[],//计费类型
      chargingTypeOptions:[{
        value:'CPD',
        label:'CPD-实际下载量计费'
      },{
        value:'CPT',
        label:'CPT-时长计费'
      },{
        value:'OCPD',
        label:'OCPD-系统智能调价,按下载量计费'
      },{
        value:'CPM',
        label:'CPM-曝光量计费'
      },{
        value:'CPC',
        label:'CPC-按点击计费'
      }],

    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.userContent.ownerId = this.$route.query.id
    this.userContent.ownerName = this.$route.query.name
    this.value1 = [formatDate(new Date()),formatDate(new Date())]
    this.getId()
    this.fileList={
        "ownerId":this.userContent.ownerId,
        "operaType":20,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
      if(this.$route.query.kw!=undefined){
        this.kw = this.$route.query.kw
      }
        this.grouplistFn(1)
      
    this.$refs.zdyHw.allWords(2)
  },
  methods: {
    //列表排序
    changeSort(val){
      console.log(val)
      if(val.order == 'descending'){
        this.sortMode = 0
      }else{
        this.sortMode = 1
      }
      this.orderBy = this.orderByList[val.prop]//获取排序需要的字段
      this.grouplistFn(this.page)

    },
    //tab切换
    clickChangeChannel(url){
      this.$router.push({
        path: '/HwAccount-management/'+url,
        query: {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName,
        }
      })
    },
    getId(){
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
    zdyFn(){
      this.$refs.zdyHw.generateTimes(2)
    },
    exportTeam(){
      this.exprtVisible = true
    },
    //下载报表
    downLoadTable(){
      let _this = this,params ={},date = new Date().getTime(),myDate = formatDate(new Date()),pagetTxt

        pagetTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : _this.page || 1,
          'subTaskName': _this.kw,//子任务名称
          'subTaskStatus': _this.subTaskType,//子任务状态
          'conversionBehaviorId': _this.conversionTarget,//转化目标
          'subTaskKey': _this.selectWords,//关键词
          'subTaskKeyMatchType': _this.matchingType,//匹配类型
          'pricingType': _this.chargingType,//计费类型
          // 'kw':_this.kw,
          // 'sortMode':_this.sortMode,
          // 'orderBy':_this.orderBy,
          // 'status':_this.adGroupStatus[0],
          // 'type':_this.adGroupType,
          // 'unDeliveryType':_this.adGroupStatus.length>1?_this.adGroupStatus[1]:'',
          // 'startDate':  _this.value1[0],
          // 'endDate':  _this.value1[1],
          // 'adPlanId':_this.$route.query.type
        }
        if(_this.value1 != undefined&&_this.value1 != ''){
        pagetTxt.startDate = _this.value1[0]
        pagetTxt.endDate = _this.value1[1]
      }
      params = Object.assign({},_this.clone(_this.userContent), pagetTxt)  
      _this.uploadingShow = true
      $http.axios.post("/api/hw/adSubTask/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        _this.exprtVisible = false 
        //添加{responseType: 'blob'}  导出文件以下代码都是
        let blob = response.data
        let reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = e => {
          let a = document.createElement('a')
          a.download = _this.userContent.ownerName+"_推广子任务_"+myDate+".xls"
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          _this.uploadingShow = false
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })    
    },
       
    // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
    clickcell(row, column, cell, event){
      row.ownerId
      let yjType = event.target.parentNode.dataset.type
      this.userContent.subTaskId = row.subTaskId
      this.userContent.subTaskName = row.subTaskName
      this.userContent.taskName = row.taskName
      this.userContent.taskId = row.taskId
      this.userContent.showStatus = row.subTaskStatus//???
      let className = event.target.className
      this.groupsIds = [{'subTaskId':row.subTaskId,'taskId':row.taskId}]

      
     if(yjType == 'editBtn'){
        this.goPlan('/plan-management/AdgroupTeamEdit',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.subTaskId,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'warningPriceSingle'){
        this.yjCheck('warningPriceSingle',this.url[1],row)
        
      }else if(yjType == 'qtBtn'){
        this.goPlan('/plan-management/SetAdgroupPrice',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.subTaskId,
          adGroupName:row.adGroupName
        })
      }else if(yjType == 'tjBtn'){
        this.goPlan('/hwAccount-management/PriceModifyGroupHw',{
          planId: row.planId,
          planName:row.planName,
          adGroupId:row.subTaskId,
          adGroupName:row.adGroupName
        })
      }
      
      if(column.property=="campaignName"){//点击所属计划名称跳转到相应的计划列表页
        this.goPlan('/hwAccount-management/adPlanHw',{
          kw:row.campaignId,
        })
      }
          // "userId": 0,
          // "userName": "",
          // "deptId": 0,
          // "pageIndex": 0,
          // "pageSize": 0,
          // "planIds": "",
          // "type": 0,
          // "startDate": "",
          // "endDate": ""
        if(column.property=="openStopPlanIds"){//列表->启停计划->是：点击查看启停详情列表弹框
          this.ssDetailParams = {}
          if(row.openStopPlanIds != 0){
            this.ssDetailParams={
                                  userId:this.userId,
                                userName:this.userName,
                                deptId:this.deptId,
                                pageIndex:1,
                                pageSize:50,
                                planIds:row.openStopPlanIds.toString()
                              }
                              console.log(row.openStopPlanIds)
          this.startStopDialogIs = true
          this.$refs.startStopDialog.timeSlotDetail(this.ssDetailParams)
        }
      }
      if(column.property =="modifyPricePlanIds"){
        if(row.modifyPricePlanIds!=0){
          this.tiaojiaDialogIs = true
          this.$refs.tiaojiaDialog.getTimeSlotDetail(row.modifyPricePlanIds,this.userContent)
        }
      }else if(column.property =="modifyKeyPricePlanIds"){
        if(row.modifyKeyPricePlanIds!=0){
          this.tiaojiaDialogIs = true
          this.$refs.tiaojiaDialog.getTimeSlotDetail(row.modifyKeyPricePlanIds,this.userContent)
        }
      }
     
    },
    // 启停页面跳转
    goPlan(url,query){//
      let _this = this,query1 =  {
      id: this.userContent.ownerId,
      name:this.userContent.ownerName}
      query = Object.assign({},_this.clone(query), query1)
      this.$router.push({
        path: url,
        query: query
      })
    }, 
    //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },

    // 分页
     handleSizeChange(val) {
      let _this = this
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(this.page)
      
    }, 
    //分页方法
    findAll (page) {
      //获取数据接口（调用封装的接口）
      this.page = page
      this.grouplistFn(page)
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let groupsIds = [],yulanList = [],objList = [],startSwitch = [],numStr = 0,num = 0
      _this.limitIs = false
      val.forEach((obj,i,arr)=>{
        let cur = obj.statusTypeIs
        groupsIds.push({'subTaskId':obj.subTaskId,'subTaskStatus':obj.subTaskStatus,'subTaskKey':obj.subTaskKey})
        startSwitch.push(obj.startSwitch)
        // yulanList.push({'ownerId':_this.userContent.ownerId,'taskId':obj.taskId,'taskName':obj.taskName, 'adName':obj.subTaskName,'adId':obj.subTaskId,'subTaskPrice':obj.subTaskPrice,'price':obj.subTaskKeyPrice})//subTaskPrice：子任务出价；price：关键词出价
        yulanList.push({'ownerId':_this.userContent.ownerId,'taskId':obj.taskId,'taskName':obj.taskName, 'subTaskName':obj.subTaskName,'subTaskId':obj.subTaskId,'subTaskPrice':obj.subTaskPrice,'price':obj.subTaskKeyPrice})//subTaskPrice：子任务出价；price：关键词出价
        if(obj.dailyBudget =='不限'){
            _this.limitIs = true
            numStr++
          }else{
            num++
          }
        objList[cur] = cur
      })
        _this.dayLimitIs = true
        if(val.length == num || val.length ==numStr){
            _this.dayLimitIs = true
          }else{
            _this.dayLimitIs = false
          }
      _this.groupsIds = groupsIds
      _this.startSwitch = startSwitch
      _this.yulanList = yulanList
      if(val.length == 0){
        _this.warningOpenList = []
        _this.planStatus = false

      }else{
        _this.planStatus = true
      }
    },
    //重置
    resetForm(){
      let _this = this
      _this.clearFn()
      _this.grouplistFn(1)
      
    },
    clearFn(){
      let _this = this
      _this.kw = ''
      _this.adGroupType = ''//组类型
      _this.adGroupStatus = ''//广告组状态
      _this.programManagStatus = ''//程序化状态
      
      _this.value1 = [formatDate(new Date()),formatDate(new Date())]//时间

    },

    //子任务列表
    grouplistFn(page){
      let _this = this,params,pagetTxt
      pagetTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : page || 1,
          'subTaskName': _this.kw,//子任务名称
          'subTaskStatus': _this.subTaskType,//子任务状态
          'conversionBehaviorId': _this.conversionTarget,//转化目标
          'subTaskKey': _this.selectWords,//关键词
          'subTaskKeyMatchType': _this.matchingType,//匹配类型
          'pricingType': _this.chargingType,//计费类型
        }
        if(_this.value1 != undefined&&_this.value1 != ''){
        pagetTxt.startDate = _this.value1[0]
        pagetTxt.endDate = _this.value1[1]
      }
      params = Object.assign({},_this.clone(_this.userContent), pagetTxt)      
      $http.axios.post("/api/hw/adSubTask/list",params).then(res=> {
        if(res.data.code==200){
          if(res.data.data.list != null){
              _this.tableData = res.data.data.list
              _this.tableData.forEach((obj,i)=>{
                //子任务状态
                if(obj.subTaskStatus=="ON"){
                  _this.$set(obj,'statusTypeIs',1)//statusTypeIs:1-开；0-关
                }else{
                  _this.$set(obj,'statusTypeIs',0)
                }
                if(obj.subTaskStatus == 'ON'){
                  obj.subTaskStatus = '启用'
                  // _this.$set(obj,'statusTypeIs',0)

                }else if(obj.subTaskStatus == 'SUSPEND'){
                  obj.subTaskStatus = '暂停'
                  // _this.$set(obj,'statusTypeIs',1)

                }else if(obj.subTaskStatus == 'OFF'){//接口列表未展示->后来加的
                  obj.subTaskStatus = '停用'
                }else if(obj.subTaskStatus == 'DELETE'){
                  obj.subTaskStatus = '删除'
                }

                if(obj.subTaskKeyMatchType == 'ACCURATE'){
                  obj.subTaskKeyMatchType = '精确匹配'
                }else if(obj.subTaskKeyMatchType == 'FUZZY'){
                  obj.subTaskKeyMatchType = '广泛匹配'
                }

                // if(obj.dailyBudget<=0){
                //   obj.dailyBudget = '不限'
                // }
                // if(obj.warningStatus == null){
                //   obj.warningStatus = '未设置'
                // }
                if(obj.openStopPlanIds==''||obj.openStopPlanIds==null){
                  _this.$set(obj,'openStopPlanIds',0)
                }
                if(obj.modifyKeyPricePlanIds==''||obj.modifyKeyPricePlanIds==null){
                  _this.$set(obj,'modifyKeyPricePlanIds',0)
                }
                if(obj.modifyPricePlanIds==''||obj.modifyPricePlanIds==null){
                  _this.$set(obj,'modifyPricePlanIds',0)
                }
                // if(obj.status!=2){
                //   _this.$set(obj,'statusTypeIs',1)
                // }else{
                //   _this.$set(obj,'statusTypeIs',2)
                // }

              })
            _this.totalRow = res.data.data.pageInfo.rowCount
          }else{
            _this.tableData = []
            _this.totalRow = 0

          }
          _this.sums = res.data.data.total
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
//预警出价
    upAdPlanWarningSwitchFn(command){
      let _this = this,type,params,groupsIds=[]
      _this.groupsIds.forEach(obj=>{
        groupsIds.push(obj.subTaskId)
      })

      params={'adGroupIdList':groupsIds,
        'warningSwitch' : command,//预警开关(0开 1关 )
        "deptId": _this.userContent.deptId,
        "userId": _this.userContent.userId,
        "userName": _this.userContent.userName}
      
///adGroupWarning/batchUpdateAdGroupWarningSwitch
      $http.axios.post("/api/hw/adGroupWarning/batchUpdateAdGroupWarningSwitch",params).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();          
        if(res.data.code==200){
          _this.grouplistFn(_this.page)
          type = "success"
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    }, 
    open(html){
      this.$alert(html, '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        type:'warning',
      });
    },
      

    //V3.0批量暂停/启动广告组
    updateStatusFn(command,status){//updateOppoAdGroupStatus
      console.log(command)
      let commandType = command ,groupsIds = [],taskId = []
      Number(commandType)?commandType="ON":commandType="SUSPEND"
      let _this = this,type,params,adGroupIdsString
      _this.groupsIds.forEach(obj=>{
        groupsIds.push(obj.subTaskId)
        taskId.push(obj.taskId)
      })
      adGroupIdsString = groupsIds.join(',')
      if(status == '继续'){
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':this.$route.query.id,
          'subTaskIds':adGroupIdsString,
          // 'taskIds':scope.row.taskId,
          'status':commandType,
          'validDate':formatDate(new Date(),'time')//当前时间
        }
      }else{
        params = {
          'userId':_this.userId,
          'userName':_this.userName,
          'deptId':_this.deptId,
          'ownerId':this.$route.query.id,
          'subTaskIds':adGroupIdsString,
          'taskIds':taskId.toString(),
          'status':commandType,
          'extendFlag':1,//不继续的时候传此参数
          'validDate':formatDate(new Date(),'time')//当前时间
        }      
      }    
        $http.axios.post("/api/hw/adSubTask/batch/openStop",params).then(res=> {
        _this.warningOpenList = []
        _this.planStatus = false
        _this.groupsIds = []
        _this.$refs.multipleTable.clearSelection();
        let totalNum ,successNum,errorNum
        if(res.data.code==200){
          type = "success"
        }else{
          if(res.data.data!=null){
            // type = "error"
            errorNum = eval('(' + res.data.data.failedTasks + ')').length
            totalNum = res.data.data.subTaskIds.split(',').length
            successNum = totalNum-errorNum
            if(errorNum>0&&errorNum==1){
              _this.open('<p>操作失败</p><p>您所选择的子任务，当前子任务状态下不支持启用。</p>')
            }else{
              _this.open('<p>批量处理任务结果</p><p>成功：'+successNum+'，失败：'+errorNum+'</p><p>操作失败的原因是由于您所选择的子任务，当前子任务状态下不支持启用。</p>')
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type:  'error'
            })
          }
        }
        _this.grouplistFn(_this.page)
        
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    // 批量启停设置
    updateSetFn(type){
      console.log('startStop')
      let _this = this
      let groupsIds = [],statusList = [],subTaskKeyList = []//声明一个空数组
        _this.groupsIds.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          groupsIds.push(v.subTaskId)
        }
        if(v.subTaskStatus != '启用'&&v.subTaskStatus != '暂停'&&v.subTaskStatus != '停用'){
          statusList.push(v.subTaskStatus)
        }
        if(v.subTaskKey!=null){
          subTaskKeyList.push(v.subTaskKey)
        }
      })
      
      
      if(groupsIds.length!=0){
        if(statusList.length!=0&&type=='planStatus'){
          _this.planStatus = false

        _this.$message({
          showClose: true,
          message: "被选中的任务/子任务状态不符",
          type: 'error'
        })
      }else if(subTaskKeyList.length==0&&type=='keyWordsDayLimit'){
        _this.$message({
          showClose: true,
          message: "被选中的子任务无关键词请重新选择",
          type: 'error'
        })
      }else{
        _this.userContent.groupsIds = groupsIds
        _this.planStatus = true

        _this.btnIsFn(_this.userContent,type)
        return true
      }
      }else{
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }
    },
    // 按钮判断
    btnIsFn(params,type){
      let _this = this
      _this.getGroupIds = String(params.groupsIds)
      if(type == 'startStop'){
        _this.startStopSetDialog = true
      }else if(type == 'planStatus'&&_this.planStatus){
            _this.checkOpenCloseFn(params.groupsIds)//需求更改暂时注释
          // _this.warningOpenList = ['开启','关闭']
      }else if(type == 'dayLimt'){

      }else if(type == 'updatedate'){
        _this.ediDateVisible = true
      }else if(type == 'updateTime'){
        _this.ediTimeVisible = true
      }

    },  
  warmIs(type,url,dayType){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
    // /adPlan/v1/checkPlanDailyMoneySet
    console.log(type)
    
      let _this = this
      let isTrue = _this.updateSetFn(type)
      _this.warningOpenList = []
      _this.planStatus = false
      if(isTrue){
          // _this.yjCheck(type,url)  
          if(isTrue){
          if(type == 'dayLimit'|| type =='keyWordsDayLimit'|| type == "dayLimitPrice"){
            _this.yulanList.forEach(obj=>{
              if(type == 'dayLimit' || type == 'keyWordsDayLimit'){
                obj.price = obj.dayBudget//这里有点问题
              }                
            })
            
            _this.dayLimitCheckFn(dayType,url)
          }else{
          _this.yjCheck(type,url)
          }
      }     
      }
    // }
    
  },
  dayLimitCheckFn(dayType,url){
      let _this = this,idList = [],params = null
      _this.dayType = dayType
        _this.groupsIds.forEach(obj=>{
          idList.push(obj.subTaskId)
        }) 
        params = {}
        params = {"ids":idList.join(','),"type":dayType}   
        $http.axios.get("/api/hw/"+url+"?ids="+idList.join(',')+"&type="+dayType).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.data
          if(content == null){
            _this.showSingleDialog = true
            _this.nowIs = false
            switch (dayType) {
            case 4:
              _this.dialogTitle = '子任务批量调价'
            break;
            case 5:
              _this.dialogTitle = '关键词批量调价'
            break;
            default:
              _this.dialogTitle = ''

            break;
            }
            }else{
              let text = content.toString()+'子任务/关键词已经设置过调价执行计划是否确认添加新计划'
              _this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                _this.showSingleDialog = true
                _this.nowIs = true
                _this.dialogTitle = dayType==3?'子任务批量调价':'关键词批量调价'
              }).catch(()=>{
                
              })
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
  yjCheck(type,url,row){
    let _this = this,messageContent,idList = [],params = null,yulanList = []
    if(type == 'warningOpen'){
      params = []
      params = [_this.userContent.ownerId]
    }else if(type == 'dayLimit' || type == 'keyWordsDayLimit'){
      _this.groupsIds.forEach(obj=>{
        idList.push(obj.subTaskId)
      }) 
      params = {}
      params = {"idList":idList,"planType":_this.planType}      
    }
    // else if(type == 'warningPriceSingle' || type == 'warningPrice'){
    //   params = []
    //   params.push(_this.params.ownerId)
    // }
    else if(type == 'warningPriceSingle'){
      console.log(row)//这里有问题
      yulanList.push({ 'subTaskName':row.subTaskName,'subTaskId':row.subTaskId,'taskId':row.taskId,'taskName':row.taskName,'price':row.subTaskPrice,'upPriceStatus':0})
      params = []
      params.push(_this.userContent.ownerId)
      _this.yulanList = yulanList
    }else if(type == 'warningPrice'){
      params = []
      params.push(_this.userContent.ownerId)
    }
    else{
    _this.groupsIds.forEach(obj=>{
      params = []
      params.push(obj.subTaskId)
    })
  }

  $http.axios.post("/api/hw/"+url,params).then(res=> {
    let content = []
    if(res.data.code==200){
     
      content = res.data.data
      if(content == null){
        if(type == 'dayLimit'){
          this.showSingleDialog = true
        }else{
          messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
        
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })
        }
          
        }else{
          if(type == 'dayLimit' || type == 'keyWordsDayLimit'){
          //   let msgContent =  content.toString()+'广告已经设置过调价执行计划是否确认添加新计划'
          //   _this.$message({
          //   showClose: true,
          //   message: msgContent,
          //   type: 'error'
          // })

          let text = content.toString()+'子任务/关键词已经设置过调价执行计划是否确认添加新计划'
          this.$confirm(text,"提示",{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(()=>{
            this.showSingleDialog = true
            this.nowIs = true
          }).catch(()=>{
            
          })
          }else if(type == 'warningPrice'){
            console.log(type)
            // _this.checkoutSetIs()
            let text = '如您已设置过预警范围则新的预警范围会覆盖原有预警范围'
            _this.$confirm(text,"提示",{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(()=>{
              _this.warmTypeBtn = type
              _this.warmType = ''
              _this.setCjWarningDialogShow = true
              let taskId=[],taskName=[],subTaskId=[],subTaskName=[]
              _this.yulanList.forEach(obj=>{
                taskId.push(obj.taskId)
                taskName.push(obj.taskName)
                subTaskId.push(obj.subTaskId)
                subTaskName.push(obj.subTaskName)
              })
              _this.$refs.setCj.setDialogContentFn(taskId,taskName,subTaskId,subTaskName,4,'list')
              //上边这行代码是金子注释的，因为在批量出价预警的时候没有回显
            }).catch(()=>{
              
            })
          }else if(type == 'warningPriceSingle'){
            console.log(type)
            _this.warmTypeBtn = type
            _this.warmType = ''
            // _this.warningOpenList = ['开启','关闭']
            _this.setCjWarningDialogShow = true
                // if(adPlanId){
            _this.$refs.setCj.setDialogContentFn(row.taskId,row.taskName,[row.subTaskId],row.subTaskName,4)
                

            // if(adPlanId){
              // _this.$refs.setCj.getAdGroupWarningDetailFn()
              
            // }
          }else if(type == 'warningOpen'){
            _this.warningOpenList = ['开启','关闭']
          }
        }
      }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
      }
    }).catch(error=>{
      let messageContent = error.message || '请查看网络，稍后再试'
      _this.$message({
        showClose: true,
        message: messageContent,
        type: 'error'
      })        
    }) 
  },
  checkoutSetIs(){
    let _this = this,params = [],url = _this.url[1]
      _this.groupsIds.forEach(obj=>{
      params.push(obj.subTaskId)
    }) 
    $http.axios.post("/api/hw"+url,params).then(res=> {
      if(res.data.code == 200){
        if(res.data.content == null){
            _this.setCjWarningDialogShow = true
        }else{
          let text = res.data.content.toString()+'子任务经设置过出价预警是否修改原有出价'
          _this.$confirm(text,"提示",{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(()=>{
            _this.setCjWarningDialogShow = true
            // _this.$refs.setCj.getAdGroupWarningDetailFn()
            //上边这行代码是金子注释的，因为在批量出价预警的时候没有回显
          }).catch(()=>{
            
          })
        }
      }
    
    }).catch(error=>{
      let messageContent = error.message || '请查看网络，稍后再试'
      _this.$message({
        showClose: true,
        message: messageContent,
        type: 'error'
      })        
    }) 
  },
  checkOpenCloseFn(groupsIds){
    let _this = this,params
    //校验当日是否存在启停计划
    groupsIds=groupsIds.join(',')
      params = {'ids': groupsIds,'type':2}
      $http.axios.get("/api/hw/openStop/valid?ids="+groupsIds+"&type=1").then(res=> {
      if(res.data.code==200){
        if(res.data.data!= null){
          _this.closeGroupList = res.data.data
        }else{
          _this.closeGroupList = ''
        }
        _this.warningOpenList = [{name:'开启',openId:1},{name:'关闭',openId:0}]

      }else{
        _this.warningOpenList = []

      }
    }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })      
    },

    //单个启停开关按钮启停计划校验
    changeStatusFn(value,scope) {
        console.log(value,typeof(scope))
        console.log(this.tableData[scope.$index].statusTypeIs)
        //先把值赋为原来的值，保证swich状态不变
        let _this = this,groupsId,params,time
        groupsId=[scope.row.subTaskId].join(',')
        _this.groupsId = [scope.row.subTaskId]
        params = {'ids': groupsId,'type':2}

        // this.tableData[scope.$index].pauseState = value == 0?1:0//需求更改暂时注释
        // this.tableData[scope.$index].statusTypeIs = value == 1?'2':'1'//需求更改暂时注释
        console.log(this.tableData[scope.$index].statusTypeIs)
        console.log(value)
        $http.axios.get("/api/hw/openStop/valid?ids="+groupsId+"&type=2").then(res=> {
          if(res.data.code==200){
           if(res.data.data!= null){
              // _this.planName = res.data.data
              _this.planName = res.data.data
              _this.command = value
              _this.alertStartStopDialogIs = true
              _this.validDate = formatDate(new Date())
           }else{
            this.tableData[scope.$index].statusTypeIs = value
            this.updateStatusFn(scope.row.statusTypeIs,'继续')//需求更改暂时打开
           }
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
    changeStatusList(command){
      console.log(command)
      let _this = this,groupName = '',text = ''
      groupName = _this.closeGroupList
      if(groupName!=''){
        _this.groupName = groupName
        _this.command = command
        _this.alertStartStopDialogIs = true     
      }else{
        this.updateStatusFn(command,'继续')
      }
    }, 
         
    // 删除
    deletePlanFn(planId){
      let _this = this,params = {},type = ''
      params = {"ownerId": _this.userContent.ownerId,
      "userId": _this.userId,
      "userName": _this.userName,
      "deptId": _this.deptId,
      "ids": String(planId)}
      $http.axios.post("/api/hw/adGroup/batch/del",params).then(res=> {
        _this.warningOpenList = []
        if(res.data.code==200){
          type = "success"
          _this.grouplistFn(this.page)
        }else{
          type = "error"
        }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 1){
        return 'color: #2F674D' 
      }else{
        return 'color: #606266' 
      }
    },

    //修改子任务名称
    editAdTeamName(item){
      this.editId = String(item.row.subTaskId)
      if(item.column.label == '子任务名称'){
        this.editNameVisible = true
        this.editName = item.row.name
      }else if(item.column.label == '投放时间'){
        this.ediDateVisible = true
        if(item.row.endDate == '2038-01-19T00:00:00.000+08:00'){
          this.activeName = 'first'
          this.firstDate = item.row.startDate
        }else{
          this.activeName = 'second'
          this.secondDate[0] = item.row.startDate
          this.secondDate[1] = item.row.endDate
        }
      }
    },

    //修改子任务名称和时间段
    sureEditName(){
      let that = this, params = {}
      params = {
        // 'advertiserId':String(this.$route.query.id),
        // 'ids':that.editId,
        // 'type':6,
        // 'name':that.editName,
        info:{
          groupId:that.editId,
          name:that.editName
        },
        ownerId:that.$route.query.id,
        userId:that.userId,
        userName:that.userName,
        deptId:that.deptId
      }

      // $http.axios.post("/api/hw/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      $http.axios.post("/api/hw/adGroup/edit",params).then(res=> {
        if(res.data.code==200){
          that.editNameVisible = false
          that.grouplistFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改单个修改日期
    sureEditDate(groupId){
      let that = this, params = {}
      params = {
        'advertiserId':String(this.$route.query.id),
        'ids':that.editId||that.getGroupIds,
        'type':2,
      }
      if(that.activeName == 'first'){
        params.startDate = that.firstDate
        params.endDate = '2038-01-19'
      }else{
        params.startDate = that.secondDate[0]
        params.endDate = that.secondDate[1]
      }
      $http.axios.post("/api/hw/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          that.ediDateVisible = false
          that.grouplistFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //批量修改时间段
    sureEditTime(){
      let that = this, params = {},
      scheduleTime = [],
      timeStr = [],timeStrSingle = ''
      params = {
        'advertiserId':String(this.$route.query.id),
        'ids':that.getGroupIds,
        'type':3,
      }
      timeStr = that.$refs.timeStrArray.timeStr
      timeStr.forEach(function(e,i){
        if(e != ''){
          scheduleTime.push({
            'week':i+1,
            'time':e
          })
        }
      })      
      console.log(typeof JSON.stringify(scheduleTime))
      if(that.activeTime == 'second'){
        params.scheduleTime = JSON.stringify(scheduleTime)
      }
      $http.axios.post("/api/hw/advertisingGroup/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          console.log(res)
          that.ediTimeVisible = false
          that.grouplistFn(that.page)
        }else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //修改投放日期中tab切换投放方式
    handleTimeClick(tab, event) {
      console.log(tab, event);
    }

  },
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */
  .MBOTTOM{
    margin-bottom:10px;
  }
  .el-button.el-button--primary{
    margin-right: 10px;
  }
  .isSwitch{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ccc;
    vertical-align: middle;
    margin-left: 5px;
}
.active{
    background-color: #13ce66;
  }
  #uploadBtn{
    float: right;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .adTeamEditBtn{
    padding-left:10px;
    color:#333;
    cursor: pointer;
    display:none;
  }
  .el-table .el-table__body tr:hover td .adTeamEditBtn{
    display:inline-block;
  }
  .timeTab .el-tabs__item{
    padding:0!important;
    padding:10px!important;
    border:1px solid #ccc!important;
  }
  .statusBtn{
    padding:3px 8px;
    background:rgba(103,194,58,.1);
    color:#67c23a;
  }
  .statusOtherBtn{
    padding:3px 8px;
    background:rgba(245,108,108,.1);
    color:#f56c6c;
  }
  .statusBtnFinish{
    padding:3px 8px;
    background:rgba(144,147,153,.1);
    color:#909399;
  }
  .uploadIcon{
    font-size:40px;
    display:block;
    text-align:center;
  }
  .isStyle i{
    font-style:normal;
    padding-right:10px;
  }
  /* tab切换当前样式 */
  .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }
  /* 投放状态按钮样式 */
  .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .statusOtherBtnwei{
      padding:3px 8px;
      background:rgba(230,162,60,.1);
      color:#E6A23C;
    }
    /*新版*/
  ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  .moreBtns{
    padding-left:20px;
  }
</style>
