<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    :title="title+'模板详情'"
  >
    <div class="authority common-page">
    <ul>
      <li>
        <span>模板名称:</span> <span>{{fileList.name}}</span>
      </li>
      <li>
        <span>广告主名称:</span> 
        <span>
          <span v-for="val in fileList.excelConfig.dataSource">
            <span v-for="obj in val.owners">
              {{obj.ownerName}}
            </span>
          </span>
          <span v-for="val in fileList.excelConfig.kwDataSource">
            <span v-for="obj in val.owners">
              {{obj.ownerName}}
            </span>
          </span>
        </span>
      </li>
      <li>
        <span>报表名称:</span> <span>{{fileList.title}}</span>
      </li>
      <li>
        <span>报表维度:</span> 
        <span>
          <!-- {{fileList.adType}} -->
          
          <span v-if="fileList.adType ==1">广告系列</span>
          <span v-else-if="fileList.adType ==2">广告组</span>
          <span v-else-if="fileList.adType ==3">广告</span>
          <span v-else-if="fileList.adType ==6">关键词</span>

        </span>
      </li>
      <li>
        <div>数据源:</div>
        <ul v-if="fileList.excelConfig!=undefined||fileList.excelConfig.dataSource!=[]">
          <li v-for="(item,i) in fileList.excelConfig.dataSource" :key="i">
            <span>{{item.sheetName}}:</span><span v-for="(ele,j) in item.owners" :key="j">{{ele.ownerId}}
            </span>
          </li>
        </ul>
      </li>
      <li>
        <div>关键词:</div>
        <ul >
          <li v-for="(item,i) in fileList.excelConfig.kwDataSource" :key="i">
            <span>{{item.sheetName}}:</span><span v-for="(ele,j) in item.owners" :key="j">{{ele.ownerId}}</span>
          </li>
        </ul>
      </li>
      <li>
        <span>接收人:</span> <span>{{fileList.senderName}}</span>
      </li>
      <li>
        <span>抄送人:</span> 
        <span>
          <span v-if="fileList.copySenderName == null">-</span>
          <span v-else>{{fileList.copySenderName}}</span>
        </span>
      </li>
      <li>
        <span>接收方式:</span> <span>{{fileList.senderF}}</span>
      </li>
      <li v-if="type == 2">
        <span>接收时间:</span> <span>{{fileList.time}}</span>
      </li>
      <li  v-if="type == 2"> 
        <span>是否拉取零时数据:</span> <span>{{fileList.zeroFlag}}</span>
      </li>
    </ul>
  </div>   
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
export default {
  props: {
    title:String,
    type:Number,
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      adTypeList:{10:'计划',20:'广告组',30:'广告',40:'账户',50:'创意'},
      needList:['否','是'],
      fileList:{
        excelConfig:{"dataSource":{"数据源":[1100018015],"数据源 (2)":[1100018015]}},
      },
      tableData:[],
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted(){
    
  },
  methods: {
    //user的channel渠道接口
    getuserChannel(id){
      let _this = this,time = []
      $http.axios.get("/api/dataReport/reportModel/queryById?id="+id).then(function(res) {
        if(res.data.code == 200){
          _this.fileList = res.data.data
          //接收方式
          if(res.data.data.imgFlag == 1){
            _this.fileList.senderF = "图片"
          }
          if(res.data.data.excelFlag == 1){
            _this.fileList.senderF = "Excel"
          }
          if(res.data.data.excelFlag == 1&& res.data.data.imgFlag == 1){
            _this.fileList.senderF = "Excel、图片"
          }
          if(res.data.data.zeroFlag == 1){
            _this.fileList.zeroFlag = "是"
          }else{
            _this.fileList.zeroFlag = "否"
          }
          _this.fileList.excelConfig.dataSource = res.data.data.excelConfig.dataSource
          _this.fileList.excelConfig.kwDataSource = res.data.data.excelConfig.kwDataSource
          _this.fileList.time = res.data.data.time
          if(res.data.data.excelConfig.dataSource.length!=0&&res.data.data.excelConfig.kwDataSource.length==0){
            _this.fileList.adType = res.data.data.excelConfig.dataSource[0].adType
          }
          if(res.data.data.excelConfig.dataSource.length==0&&res.data.data.excelConfig.kwDataSource.length!=0){
            _this.fileList.adType = res.data.data.excelConfig.kwDataSource[0].adType
          }
        } 
      })
    },

    onCancel() {
      this.show= false;
    },
  
  }
};
</script>
<style lang="scss" scoped>
  .limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
  }
  .dateConent{
    margin: 15px;
  }
  .addWord{
    cursor: pointer;
    display: inline-block;
    margin: 5px 8px;
    font-size: 12px;
    color: #ccc;
  }
</style>

