<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item>计划管理</el-breadcrumb-item>
                <el-breadcrumb-item @click.native="jumpPage" class="jumpPageStyle">启停展示</el-breadcrumb-item>
                <el-breadcrumb-item>创建启停</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>  
            <div class="setPrice">
                <el-form :model="ruleForm" :rules="rules" ref='ruleForm' label-width="150px" class="demo-ruleForm">
                    <span class="baseTitle">基础信息</span>
                    <el-form-item label="账户ID" prop="customerName">
                        <el-input v-model="ruleForm.customerName" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="计划名称" prop="planName">
                        <el-input v-model="ruleForm.planName" placeholder=""></el-input>
                    </el-form-item>
                    <span class="baseTitle">设置启停</span>
                    <el-form-item label="计划启停设置">
                        <el-form-item label="" prop="dateChecked">
                            <el-checkbox v-model="ruleForm.dateChecked" @change="dateSet">设置执行日期</el-checkbox>
                            <div class="setSureDate" v-show="setDatevalueShow">{{setDatevalueString}}</div>   
                        </el-form-item>
                        <el-form-item label="" prop="timeChecked">
                            <el-checkbox v-model="ruleForm.timeChecked" @change="setCarryTime">设置执行时间</el-checkbox>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item>
                      <timeUnit ref="timeStrArray" v-if="timeUnitShow"></timeUnit>
                    </el-form-item>
                    <el-form-item>
                      <div class="setPriceBtns">
                        <el-button type="primary" class="newDialogCancel" size="small" plain @click="cancleCarryTime">取消保存</el-button>
                        <el-button type="primary" class="newDialog" size="small" @click="carryTimeSure">确认保存</el-button>  
                      </div>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 计划启停的设置执行日期 -->
            <el-dialog 
              title="执行日期"
              center
              :show-close = "false"
              class="setPriceDialog"
              width="400px"
              :visible.sync="setDateVisible">
              <div style="width:100%;text-align: center;">
                <el-date-picker
                  v-model="setDatevalue"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" class="newDialogCancel" @click="setDateCancel">取消保存</el-button>
                <el-button type="primary" class="newDialog" @click="setDateCommit">确认保存</el-button>
              </div>
            </el-dialog>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import TimeUnit from '../../TimeUnit.vue';

  export default {
    name: 'SetPrice',
    components:{
      timeUnit:TimeUnit
    },
    data() {
      return {
        userId:'',
        userName:'',
        deptId:'',
        ruleForm: {
          customerName:'',
          planName:'',
          dateChecked:'',//设置执行日期
          timeChecked:'',//设置执行时间
          radio:'',//计划执行设置，单选
        },
        rules: {
          dateChecked:[
            { required: true, message: '请设置执行日期', trigger: 'change' }
          ],
          // timeChecked:[
          //   { required: true, message: '请设置执行时间', trigger: 'change' }
          // ],
          // radio:[
          //   { required: true, message: '请选择计划执行设置', trigger: 'change' }
          // ],
        },
        radioOptions:[{//单选
          label:'设置执行日期',
          value:'1'
        },{
          label:'立即执行',
          value:'2'
        }],
        setDateVisible:false,//计划启停的设置执行日期
        setDatevalue:[],//计划启停的设置执行日期----日期选择
        setDatevalueString:'',
        setDatevalueShow:false,//计划启停的设置执行日期----日期选择展示
        setTimeVisible:false,//计划执行设置执行日期
        setTimevalue:'',//计划执行设置执行日期----日期选择
        setTimevalueShow:false,//计划执行设置执行日期----日期选择展示
        setTimevalueString:'',
        planId:'',
        planName:'',
        ownerId:'',
        ownerName:'',
        carryTimeVisible:false,//计划启停设置--设置执行时间
        addParams:[{//创建计划的入参
          userId:'',
          userName:'',
          deptId:'',
          planId:'',
          planName:'',
          objIdType:'',
          ownerId:'',
          ownerName:'',
          startTime:'',
          endTime:'',
          addOpenClosePlanDetailDtoList:[
            {
              executeHourt:'',
              week:''
            }
          ]
        }],
        timeStr:[],
        timeUnitShow:false,

      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.getId()//上一级带过来的参数方法
      this.addParams[0].ownerId = this.$route.query.id
      this.addParams[0].ownerName = this.$route.query.name
      this.ownerName = this.$route.query.name
      this.ownerId = this.$route.query.id
      console.log(this.$route.query.name)
      // this.ruleForm.customerName = this.ownerName
      this.ruleForm.customerName = this.ownerId
    },
    methods: {
      //上一页带过来的参数
      getId(){
        this.planId = this.$route.query.planId
        this.planName = this.$route.query.planName
        this.ownerId = this.$route.query.id
        this.ownerName = this.$route.query.name
        this.ruleForm.customerName = this.ownerId
        this.ruleForm.planName = this.planName
      },

      //跳转到启停展示页面
      jumpPage(){
        this.$router.push('/plan-management/StartStop?id='+this.ownerId+'&name='+this.ownerName)
      },

      //计划启停设置---设置执行日期
      dateSet(val){
        if(val==true){
          this.setDateVisible = true
          this.setDatevalue = []
        }else{
          this.setDateVisible = false
          this.setDatevalueString = ''
        }
      },

      //计划启停设置---设置执行日期--取消
      setDateCancel(){
        this.setDateVisible = false
        this.ruleForm.dateChecked = false
      },
      //计划启停设置---设置执行日期--确定
      setDateCommit(){
        if(this.setDatevalue == null||''){
          this.$message('请选择日期')
        }else{
          this.setDateVisible = false
          this.setDatevalueShow = true
          this.setDatevalueString = String(this.setDatevalue[0]+'至'+this.setDatevalue[1])
        } 
      },

      //计划执行设置--选择
      changeRadio(val){
        console.log(val)
        if(val == 1){
          this.setTimeVisible = true
          this.setTimevalue = ''
        }else{
          this.setTimevalueShow = false
        }
      },
      //计划执行设置---设置执行日期--取消
      setTimeCancel(){
        this.setTimeVisible = false
        this.ruleForm.radio = ''
      },
      //计划执行设置---设置执行日期--确定
      setTimeCommit(){
        if(this.setTimevalue == ''){
          this.$message('请选择日期')
        }else{
          this.setTimeVisible = false
          this.setTimevalueShow = true
          this.setTimevalueString = String(this.setTimevalue[0]+'至'+this.setTimevalue[1])
        }
      },

      //设置执行时间显示弹框
      setCarryTime(){
        let that = this
        if(that.ruleForm.timeChecked == true){
          that.timeUnitShow = true
        }else{
          that.timeUnitShow = false
        }
        
      },

      //设置执行时间显示弹框--确定
      carryTimeSure(){
        let that = this
        that.$refs.ruleForm.validate((valid) => {
          if (valid) {
            that.addParams[0].userId = that.userId
            that.addParams[0].userName = that.userName
            that.addParams[0].deptId = that.deptId
            that.addParams[0].planId = that.planId
            that.addParams[0].planName = that.planName
            that.addParams[0].objIdType = 10//广告计划是10
            // that.addParams[0].planStatus = ''
            that.addParams[0].startTime = that.setDatevalue[0]
            that.addParams[0].endTime = that.setDatevalue[1]
            let timeString = that.$refs.timeStrArray.timeStr
            console.log(that.$refs)
            that.addParams[0].addOpenClosePlanDetailDtoList = []
            timeString.forEach(function(e,index){
              console.log(index)
              if(e!=''){
                that.addParams[0].addOpenClosePlanDetailDtoList.push(
                  {executeHourt:e,week:index+1}
                )
              }
            })
            $http.axios.post("/api/advertisement/adPlan/v1/addOpenClosePlan",that.addParams).then(res=> {
              if(res.data.code == 200){
                this.$router.go(-1);
              }  else {
                that.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });

        
      },

      //取消
      cancleCarryTime(){
        this.$router.go(-1);
      },
      
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .baseTitle{
      font-weight:700;
      display:block;
      font-size:14px;
      margin:10px 0 20px;
    }
    .setPrice .el-input{
      width:260px;
    }
    .errorTip{
      margin-left:150px;
    }
    .setPrice{
      margin-top:20px;
    }
    ::v-deep .setPriceDialog .el-dialog__body{
      margin:0!important;
    }
  </style>
