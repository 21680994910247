<template>
    <div class="authority common-page">
      <PocCardTable>
        <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/auth-management/dataSjkbAsa' }">数据看板</el-breadcrumb-item>
                <el-breadcrumb-item >归因文档</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <div>
            归因
          </div>
          <!-- 正在载入 -->
          <!-- <el-dialog
            title=""
            :visible.sync="loadingDialogVisible"
            width="20%"
            :destroy-on-close="true"
            :show-close="false"
            :close-on-click-modal="false"
            class="loadingBg">
            <span class="loading">
              <span>正在载入...</span>
              <i class="el-icon-loading"></i>
            </span>
          </el-dialog> -->
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  export default {
    data() {
      return {
        // loadingDialogVisible:true,//正在载入的弹框

      }
    },
    computed: {},
    mounted() {
      // this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      // this.userName = this.$sessionStorage.getItem(config.USERNAME)
      // this.deptId = this.$sessionStorage.getItem(config.DEPTID)
     
    },
    methods: {

    }
  }
  </script>
  <style lang="scss" scoped>
    .loading{
      text-align:center;
    }
    .loading span,.loading i{
      display: block;
    }
    .loading span{
      font-size:20px;
      font-weight:700;
      color:#fff;
    }
    .loading i{
      margin-top:10px;
      font-size:60px;
      /* color: #409eff; */
      color:#fff;
    }
    ::v-deep .loadingBg .el-dialog{
      margin-top:200px;
      background:rgba(255,255,255,0)!important;
      box-shadow: none;
    }




  </style>
