<template>
  <el-dialog
    :visible.sync="show"
    :close-on-click-modal="false"
    @close="onCancel"
    :title="'生成' + title + '模板'"
    width="640px"
    class="icon_custom_popup"
  >
    <img class="icon" src="@/assets/img/suCaiIcon.png" />
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
      :label-width="type == 2 ? '130px' : '106px'">
      <el-form-item label="模板名称" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入模板名称"></el-input>
      </el-form-item>
      <el-form-item label="报表名称" prop="excelName">
        <el-input v-model="ruleForm.excelName" clearable placeholder="请输入报表名称" oninput="" @clear='handleClear'></el-input>
        <div class="button_label">
          <div
            v-for="(item, i) in cibaoList"
            :key="i"
            @click="addWordsFn(item)"
          >
            {{ item }}
          </div>
        </div>
      </el-form-item>
      <el-form-item label="报表维度" prop="adType">
        <el-select v-model="ruleForm.adType" placeholder="请选择报表维度">
          <el-option label="计划" value="10"></el-option>
          <el-option label="广告组" value="20"></el-option>
          <el-option label="广告" value="30"></el-option>
        </el-select>
      </el-form-item>
      <div v-if="dataSourceIs" class="chuansuo">
        <div class="titles">数据源sheet</div>
        <div v-for="(item,i) in dataSource" :key = i>
          <el-collapse v-model="activeNames" @change="handleChange">
            <el-form-item  prop="sourceName">
              <el-collapse-item :title="item.name" :name="i">
                <div class="addBtn">
                  <div class="addChecked">
                    <el-button type="primary" size="small" @click="addBtnIndex(item.name,i)">批量生成</el-button>
                  </div>
                  <el-dialog
                    width="40%"
                    ref="addButton"
                    :title="btnTitle"
                    :visible.sync="innerVisible"
                    data-type="btns"
                    append-to-body>
                    <span class="wordsTip">每个ID之间用<i>英文逗号</i>分割，不要有空格，填写在下方输入框中即可</span>
                    <div class="textArea">
                      <el-input type="textarea" v-model="addTextIds" class="textAreaInput"></el-input>
                    </div>
                    <div class="twoBtns">
                      <el-button type="primary" size="mini" @click="determine(indexNum)">确 定</el-button>
                      <el-button type="primary" size="mini" @click="cancel">取 消</el-button>
                    </div>
                  </el-dialog>
                  <el-dialog
                    width="40%"
                    :visible.sync="innerTip"
                    title="错误ID"
                    append-to-body>
                    <div class="innerFalse">查询到<i>[{{listTip}}]</i>是错误的账户ID，请重新检查账户ID之后再次输入</div>
                    <div class="twoBtns">
                      <el-button type="primary" size="mini" @click="innerTip = false">确 定</el-button>
                    </div>
                  </el-dialog>
                </div>
              <div class="transferBox">
                <el-transfer
                  style="text-align: left; display: inline-block"
                  v-model="ruleForm.sourceName[i]"
                  filterable
                  :render-content="renderFunc"
                  :titles="['未选中', '已选中']"
                  :button-texts="['到左边', '到右边']"
                  @mouseover.native="addTitle"
                  :data="tableAllData"
                  :format="{
                    noChecked: '${total}',
                    hasChecked: '${checked}/${total}'
                  }"
                  @change="changeFn($event,i,'data')">
                </el-transfer>
              </div>
            </el-collapse-item>
            </el-form-item>
          </el-collapse>
        </div>
     </div>
      <div v-if="keywordsIs">
        <div class="titles">关键字sheet</div>
          <el-form-item v-for="(item,i) in keywords" :key = i prop="KeyName"
          :label="item.name">
            <el-select v-model="ruleForm.KeyName[i]" value-key = "name" multiple placeholder="请选择账户" filterable @change="changeFn($event,i,'keyword')">
              <el-option :label="item1.name" :value="item1" v-for="(item1,j) in tableDataList" :key=j></el-option>
            </el-select>
          </el-form-item>
      </div>
      <el-form-item label="接收人" prop="sendUser">
        <el-select v-model="ruleForm.sendUser" value-key ="string" multiple filterable placeholder="请选择接收人" @change="changeFn($event)">
            <el-option
            v-for="(item,i) in distributionOptions"
            :key="i"
            :label="item.string"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抄送人" prop="copyUser">
        <div class="inline">
        <el-select v-model="ruleForm.copyUser" multiple value-key ="name" filterable placeholder="请选择抄送人" @visible-change="focusFn"  @change="changeFn($event)">
          <el-option
            v-for="(item,i) in cc_options"
            :key="i"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
          <i
            class="el-icon-circle-plus-outline"
            @click="dialogFormVisible_add = true"
          ></i>
        </div>
        <el-dialog
          class="icon_custom_popup"
          width="400px"
          title="添加抄送人"
          :visible.sync="dialogFormVisible_add"
          append-to-body
          :close-on-click-modal="false"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_specify.png" />
          </div>
          <el-form :model="addForm" label-width="64px">
              <el-form-item label="人员名称" :label-width="formLabelWidth">
                <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="邮箱地址" :label-width="formLabelWidth">
                <el-input v-model="addForm.email" autocomplete="off" placeholder="请输入"></el-input>
              </el-form-item>
            </el-form>
          <div slot="footer">
            <el-button @click="dialogFormVisible_add = false">取 消</el-button>
            <el-button type="primary" @click="csrSure">确 定</el-button>
          </div>
          </el-dialog>
      </el-form-item>
      <el-form-item v-if="type == 2" label="接收方式" prop="type">
        <el-checkbox-group class="middle" v-model="ruleForm.type">
          <el-checkbox label="Excel" name="type"></el-checkbox>
          <el-checkbox label="图片" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="type==2" label="是否拉取零点数据" prop="resource">
        <el-switch
          class="middle"
          v-model="ruleForm.resource"
          :active-value="2"
          :inactive-value="1"
        >
        </el-switch>
      </el-form-item>
      <el-form-item v-if="type == 2" label="时报时间点" prop="timeSlot">
        <el-select v-model="ruleForm.timeSlot" multiple filterable placeholder="请选择时报时间点">
          <el-option
            v-for="(item,i) in timeLimitList"
            :key="i"
            :label="item.time"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" @click="onCancel">{{
        $t("dc:取消")
      }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm('ruleForm')"
        >{{ $t("dc:保存") }}</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
// import dayTimeUnit from '@/views/auth/dayTimeUnit';
import getUserList from '@/utils/getUserList'//获取用户列表
export default {
  
  // components:{
  //   dayTimeUnit
  // },
  props: {
    pubVue:Object,
    title:String,
    visible: {
      type: Boolean,
      default: false
    },
    type:Number,
    dataSourceIs:Boolean,
    keywordsIs:Boolean,
    dataSource:Array,
    keywords:Array,
    path:String
  },
  data() {
    return {
      // 数据源
      renderFunc(h, option) {
        return <span>{ option.key } - { option.label }</span>;
      },
      show: this.visible,
      loading: false,
      ruleForm: {
        name: '',
        excelName:'',
        adType: '',
        type: [],
        resource: '',
        sourceName:[],
        KeyName:[],
        sendUser:[],
        copyUser:[],
        timeSlot:[]
      },
      addForm: {
        name: '',
        email:''
      },
      timeLimitList:[{'time':'10:00','id':'10'},{'time':'10:30','id':'10.5'},{'time':'11:00','id':'11'},{'time':'11:30','id':'11.5'},
      {'time':'12:00','id':'12'},{'time':'12:30','id':'12.5'},{'time':'13:00','id':'13'},{'time':'13:30','id':'13.5'},{'time':'14:00','id':'14'},{'time':'14:30','id':'14.5'},{'time':'15:00','id':'15'},{'time':'15:30','id':'15.5'},
      {'time':'16:00','id':'16'},{'time':'16:30','id':'16.5'},{'time':'17:00','id':'17'},{'time':'17:30','id':'17.5'},{'time':'18:00','id':'18'},{'time':'18:30','id':'18.5'},{'time':'19:00','id':'19'},{'time':'19:30','id':'19.5'},
      {'time':'20:00','id':'20'},{'time':'20:30','id':'20.5'},{'time':'21:00','id':'21'},{'time':'21:30','id':'21.5'},{'time':'22:00','id':'22'},{'time':'22:30','id':'22.5'},{'time':'23:00','id':'23'},{'time':'23:30','id':'23.5'}],
      formLabelWidth: '120px',
      dialogFormVisible_add: false,
      tableDataList:[],
      cc_options:[],
      value1:[],
      distributionOptions:[],
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        excelName:[{required: true, message: '请输入报表名称', trigger: 'blur' }],
        adType: [{ required: true, message: '请选择报表维度', trigger: 'change' }],
        type: [{ type: 'array', required: true, message: '请至少选择一个接收方式', trigger: 'change' }],
        resource: [{ required: true, message: '请选择是或者否', trigger: 'change' }],
        sendUser:[{ required: true, message: '请选择接收人', trigger: 'change'}],
        sourceName: [{ required: true, message: '请选择账户', trigger: 'change' }],
        KeyName:[{ required: true, message: '请选择账户', trigger: 'change'}]
      },
      cibaoList:['代理公司','渠道','报表类型','日期','时段'],
      wordsA : [],
      // 数据源
      searchLoading:false,
      tableAllData:[],
      activeNames: [0],
      innerVisible:false,
      dialogData:'',
      btnTitle:'',
      addTextIds:'',
      indexNum:'',
      innerTip:false,
      listTip:'',
      sourceNames:[]
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    this.cc_options = this.$sessionStorage.getItem('optionSelectCopyUser')
    this.getList()
    console.log(this.pubVue.sizeType)
    
  },
  methods: {
    //数据源
    dealData() {
      const data = this.tableDataList
      data.forEach((obj,i)=>{
        this.tableAllData.push({
          key:obj.id,
          label:obj.name,
        })
      })
    },

    //添加抄送人
    csrSure(){
      let that = this
      that.dialogFormVisible_add = false
      // that.csrParams.accountId = '71754670438'
      // that.csrParams.accountId = String(that.userDetailId)
      // that.csrParams.email = that.addForm.email
      // that.csrParams.userName = that.addForm.name
      let userName = that.addForm.name
      let email = that.addForm.email
      $http.axios.get("/api/system/user/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
        if(response.data.code == 200){
          getUserList().then(res => {
            that.cc_options = res
          })
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onConfirm(formName) {

      let _this = this,sourceIs = false,keyIs = false,sourceNum = 0,keyNum = 0
        _this.$refs[formName].validate((valid) => {
          if(valid){
            console.log('成功2')
            console.log('选中')
            console.log(_this.ruleForm)
            if(_this.dataSourceIs){
              _this.ruleForm.sourceName.forEach((obj,i,arr)=>{
           
                if(obj.length==0){
                  sourceIs = false
                  sourceNum--
                }else{
                  sourceNum++
                  if(sourceNum ==arr.length){
                    sourceIs = true
                  }
                }
              })
            }else{
              sourceIs = true
            }
            
          if(_this.keywordsIs){
            _this.ruleForm.KeyName.forEach((obj,i,arr)=>{
            
              if(obj.length==0){
                keyIs = false
                keyNum--
              }else{
                keyNum++
                if(keyNum ==arr.length){
                  keyIs = true
                }
                
              }
            })
          }else{
            keyIs = true
          }
          
           if(!sourceIs||!keyIs){
              _this.$message({
                showClose: true,
                message: '请选择账户',
                type: 'error'
              })
           }else{
             if(_this.type==2){
              if(_this.ruleForm.timeSlot.length == 0){
                _this.$message({
                  showClose: true,
                  message: '请选择时报接收时间',
                  type: 'error'
                })
              }else{
                _this.addModelFn(_this.dayLimit)
              }
            }else{
              _this.addModelFn(_this.dayLimit)
            }
            
          }
        }
        });
        console.log(_this.ruleForm)

    },
    onCancel() {
      this.show= false;
      this.$refs.ruleForm.resetFields()
      this.pubVue.listModelFn()
    },
    addOrSetFn(type){
      
    },
    handleClear(){
      this.ruleForm.excelName = ''
      this.wordsA = []

    },
    focusFn(e){
      if(e){
        this.getFpList()
      }
    },
    changeFn(event,i,type){
      let _this = this,ownersList = []
      console.log(event,i)
      console.log(this.dataSource)
      console.log(this.keywords)

      if(type == "data"&&event.length != 0){
        //数据源
        _this.tableAllData.forEach(obj=>{
          event.forEach(val=>{
            if(val == obj.key){
              ownersList.push({"ownerId":obj.key,"ownerName":obj.label})
            }
          })
        })
        this.dataSource[i].owners = ownersList
        // event.forEach(obj=>{
        //   ownersList.push({"ownerId":obj.id,"ownerName":obj.name})
        // })
        // this.dataSource[i].owners = ownersList
      }else if(type == "keyword"&&event.length != 0){
        event.forEach(obj=>{
          ownersList.push({"ownerId":obj.id,"ownerName":obj.name})
        })
        this.keywords[i].owners = ownersList
      }

      console.log(this.dataSource)
      console.log(this.keywords)
    },
    //获取分配人员列表
    getFpList(){
      let _this = this
      // $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
      $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId=5").then(function(response) {
        if(response.data.code == 200){
          _this.distributionOptions = response.data.data
          _this.distributionOptions.forEach((obj,i)=>{
            if(obj.userId == _this.userId){
              _this.ruleForm.sendUser.push(obj)
              
            }
          })
        } else {
          _this.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    //获取菜单列表
    getList() {
      console.log(1)
      let that = this
      $http.axios.get("/api/xmat/accountUser/selectList?userId="+that.userId).then(function(response) {
      console.log(response)
      if(response.status==200){
        that.tableDataList = response.data
        that.dealData()
        console.log(that.tableDataList)
        }
      })
    },
    // {
    //   "adType": 0,
    //   "channelId": 0,
    //   "copyUser": "",
    //   "copyUserName": "",
    //   "deptId": 0,
    //   "excelConfig": {
    //     "dataSource": [
    //       {
    //         "name": "",
    //         "owners": [
    //           {
    //             "ownerId": 0,
    //             "ownerName": ""
    //           }
    //         ]
    //       }
    //     ],
    //     "keyword": [
    //       {}
    //     ]
    //   },
    //   "excelSwitch": 0,
    //   "imgSwtich": 0,
    //   "isNeedYesterday": 0,
    //   "path": "",
    //   "reportName": "",
    //   "reportSwitch": 0,
    //   "sender": "",
    //   "senderName": "",
    //   "times": [],
    //   "title": "",
    //   "type": 0,
    //   "userId": 0
    // }
    addModelFn(){
      let _this = this,params = {},excelSwitch = 1,imgSwtich = 1,sendUser = {"string":[],"id":[]},copyUser = {"string":[],"id":[]}
      let times = []

      times = _this.ruleForm.timeSlot
      if(_this.ruleForm.type.length>0){
        if(_this.ruleForm.type[0]=="图片"){
          imgSwtich = 2
        } else if(_this.ruleForm.type[0]=="Excel"){
          excelSwitch = 2
        }
        if(_this.ruleForm.type.length == 2){
          excelSwitch = 2
          imgSwtich = 2
        }
      }
      _this.ruleForm.sendUser.forEach(obj=>{
        sendUser.string.push(obj.string)
        sendUser.id.push(obj.userId)
      })
      _this.ruleForm.copyUser.forEach(obj=>{
        copyUser.string.push(obj.name)
        copyUser.id.push(obj.id)
      })
      params = {
        "adType":_this.ruleForm.adType,
        "channelId":5,
        "copyUser": copyUser.id.toString(),
	      "copyUserName": copyUser.string.toString(),
        "deptId": _this.deptId,
        "excelConfig": {
          "dataSource": _this.dataSource,
          "keyword": _this.keywords
        },
        "excelSwitch": excelSwitch,
        "imgSwtich": imgSwtich,
        "isNeedYesterday": _this.ruleForm.resource=="是"?2:1,
        "path": _this.path,
        "reportName": _this.ruleForm.name,//模板名称
        "reportSwitch": 2,
        "sender": sendUser.id.toString(),
        "senderName": sendUser.string.toString(),
        "times": times,
        "title": _this.ruleForm.excelName,//报表名称，词包
        "type": _this.type,
        "userId": _this.userId,
        "sizeType":_this.pubVue.sizeType
      }
      $http.axios.post("/api/miReport/newReport/addModel",params).then(res=> {
        if(res.data.code == 200){
          _this.onCancel()
        }else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    addWordsFn(val){
      let _this = this,wordsA = []
      // _this.ruleForm.excelName = ''
      if(_this.wordsA.length!= 0){
        _this.wordsA.forEach((obj,i,arr)=>{
          console.log(obj)
          if(val == obj){
            arr.splice(i,1)
            
          }
          
        //  _this.ruleForm.excelName +='{'+arr[i]+'}'

        })
        _this.wordsA.push(val)
        wordsA = _this.wordsA
        // wordsA.forEach(obj=>{
        _this.ruleForm.excelName +='{'+val+'}'
      // })
      }else{
        console.log(val)
        _this.ruleForm.excelName +='{'+val+'}'
        _this.wordsA.push(val)
      }  
    },

    //数据源开始
    handleChange(val){
      console.log(val)
    },

    // 添加选中的id
    addBtnIndex(name,i){
      let ownersList = [],event = [],addTextIds = []
      this.btnTitle = name 
      this.indexNum = i
      this.addTextIds = this.ruleForm.sourceName[i]
      this.innerVisible = true
    },

    //确定添加选中的id到选框中
    determine(i){
      //oppo  1000092281,1100047535,1100047545,1100047548,1100047542,1100047551,1100047554,1100047557
      //xiaomi 79502,371172
      let sourceNames = [],ownersList = [],addTextIds = [],list= []
      this.addTextIds = this.addTextIds.toString()
      sourceNames = this.addTextIds.split(',')
      this.ruleForm.sourceName[i] = sourceNames   
      this.tableAllData.forEach(obj=>{   
        sourceNames.forEach((val,index)=>{
          if(val == obj.key){
            ownersList.push({"ownerId":obj.key,"ownerName":obj.label})
            addTextIds.push(obj.key)
            list = sourceNames.filter(item=>{
              return addTextIds.indexOf(item) === -1
            })
          }
        })
      })

      sourceNames = sourceNames.filter(item => !list.includes(item))
      this.addTextIds = sourceNames.toString()
      this.dataSource[i].owners = ownersList
      this.addTextIds = JSON.stringify(sourceNames)
      this.sourceNames = sourceNames
      this.innerVisible = false
      this.addTextIds = ''
      this.listTip = list.toString()
      if(list.length != 0){
        this.innerTip = true
      }
      
      // this.$message({
      //   message: '账户ID中'+list.toString()+'错误',
      //   type: 'warning'
      // })
    },

    //取消添加选中弹框
    cancel(){
      this.innerVisible = false
      this.addTextIds = ''
    },

    addTitle(e) {
      // console.log(e)
      const target = e.target
      if (target.title) return
      target.title = target.innerText
    },

    //数据源结束
    
    
  }
};
</script>
<style lang="scss" scoped>
.addWord{
  cursor: pointer;
  display: inline-block;
  margin: 5px 8px;
  font-size: 12px;
  color: #ccc;
}
/*数据源*/
::v-deep .transferBox .el-transfer-panel{
  width: 300px !important;;
}
.chuansuo .el-collapse{
  border:none;
}
::v-deep .chuansuo .el-collapse-item__header{
  color:#F56C6C;
  margin-left:-60px;
}
::v-deep .chuansuo .el-icon-arrow-right:before{
  color:#666;
}
.addChecked{
  position: absolute;
  text-align:right;
  top:9px;
  right:50px;
}
.twoBtns{
  text-align:center;
  margin-top:30px;
}
::v-deep .el-popup-parent--hidden .el-dialog__body{
  margin:0!important;
}
.el-tree-node__label{
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wordsTip{
  display: block;
  margin-bottom:10px;
  font-size:12px;
}
.wordsTip i{
  font-style: normal;
  color:red;
}
::v-deep .textAreaInput .el-textarea__inner{
  height:100px;
}
.innerFalse{
  text-align:center;
}
.innerFalse i{
  font-style: normal;
  color:red;
}
</style>

