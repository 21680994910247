<template>
  <div class="hl_breadCrumb plan-tabs hlWrap">
    <breakTitle :titleData="breakTitle"></breakTitle>
    <template>
      <div >
        <el-tabs v-model="activeName" class="newTabType" @tab-click="handleClick">
          <el-tab-pane 
            v-for="(item,index) in tabsList" 
            :key="index"
            :label="item.label" 
            :name="item.name"
            :ref="item.name">
            <component :is='item.content' :newVue = "newVue"></component>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </div>
</template>
<script>
import Api from "@/api";
import config from '@/api/config';
import breakTitle from "./breakHeader";//面包屑导航
import adPlan from "./adPlan";//广告计划
import adTeam from "./adTeam";//广告组
import advert from "./advert"//广告
export default {
  name: "planTabs",
  data() {
    return {
      newVue:this,
      activeName: 'adPlan',//记得修改成adPlan
      breakTitle:['计划管理','账户选择','广告计划'],
      tabIndex:null,
      tabsList:[{label:"广告计划",name:"adPlan",objIdType:10,content:adPlan},
        {label:"广告组",name:"adTeam",objIdType:20,content:adTeam},
        {label:"广告",name:"advert",objIdType:30,content:advert}],//tabs动态加载的组件内容


    }
  },
  components:{
    breakTitle,//面包屑导航
    adPlan,//广告计划
    adTeam,//广告组
    advert//广告
  },
  mounted(){
    this.tabIndex = Number(this.$route.query.tabIndex)
    if(this.tabIndex == 1){
      this.activeName = 'adTeam'
      this.$refs.adTeam[0].$children[0].grouplistFn()
      this.$refs.adTeam[0].$children[0].$refs.zdy.allWords(20)
    }else{
      this.$refs.adPlan[0].$children[0].getAccountPlanListFn()
      this.$refs.adPlan[0].$children[0].$refs.zdy.allWords(10)
    }


    console.log(this)
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.index)
      if(tab.index == '0'){
        this.$refs.adPlan[0].$children[0].getAccountPlanListFn()
        this.$refs.adPlan[0].$children[0].$refs.zdy.allWords(10)
        console.log(this)
      }else if(tab.index == '1'){
        if(typeof event == 'number'){
          this.$refs.adTeam[0].$children[0].planIds = event
        }
        this.activeName = 'adTeam'
        this.$refs.adTeam[0].$children[0].grouplistFn()
        this.$refs.adTeam[0].$children[0].$refs.zdy.allWords(20)
      }else{
        if(typeof event == 'number'){
        this.$refs.advert[0].$children[0].adGroupIds = event
        }
        this.activeName = 'advert'
        this.$refs.advert[0].$children[0].v3groupListFn()
        this.$refs.advert[0].$children[0].$refs.zdy.allWords(30)
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .el-tabs__item.is-active {color: #6e7c7c;
    font-size: 16px;
    font-weight: 700;
  }
  .el-tabs__header{
    margin:15px 0 0;
  }
  .hlWrap{
    background:#fff;
    margin:20px 24px 0;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding:20px 30px;
  }
  ::v-deep .newTabType .el-tabs__header{
    border-bottom:1px solid #F5F7F6!important;
  }
  ::v-deep .newTabType .el-tabs__item{
    width: 110px;
    height: 40px;
    background: #F5F7F6;
    border-radius: 8px 8px 0px 0px;
    margin-right:10px;
    padding:0;
    text-align:center;
    color:#2F674D;
  }
  ::v-deep .newTabType .el-tabs__item.is-active{
    background: #48916F;
    color:#fff;
    border-radius: 8px 8px 0px 0px;
  }
</style>