<template>
  <div class="publicis-data-center">
    <poc-container class="sso-container">
      <template slot="logo" >
        <img :src="logoImg" />
      </template>
      <ProtailHeaderRightGroup slot="rightGroup" />
      <div class="publicis-data-center-body">
        <div class="publicis-data-center-body-project">
           <ul class="project-one">
             <li @click="applyOption(item)" v-for="(item,index) in appList" :key="index" :class="item.link !== '' ? 'app-items' : 'app-items app-disabled'">
                <div class="card-item-box"  v-if="index<2">
                  <h3 class="new-font" v-html="item.desc"></h3>
                  <div class="desc" v-html="item.introduction"></div>
                  <div class="goLink" v-if="item.link !== ''">
                    <a href="javascript:;" style="font-size: 40px;">
                      <i class="el-icon-bottom-right"></i>
                    </a>
                  </div>
                </div>
             </li>
           </ul>
            <ul class="project-two">
              <li  @click="applyOption(item)" v-for="(item,index) in appList" :key="index" :class="item.link !== '' ? 'app-items' : 'app-items app-disabled'">
                <div class="card-item-box"  v-if="index>1 && index<5">
                  <h3 class="new-font" v-html="item.desc"></h3>
                  <div class="desc" v-html="item.introduction"></div>
                  <div class="goLink" v-if="item.link !== ''">
                    <a href="javascript:;" style="font-size: 24px;">
                      <i class="el-icon-bottom-right"></i>
                    </a>
                  </div>
                </div>
              </li>
            </ul>
        </div>
      </div>
      <!-- <div slot="footer" class="container-footer">version 0.1.0</div> -->
    </poc-container>

    <div class="login-video">
      <video muted autoplay loop
        src="../assets/video/bg.mp4"
        class="video-tvc"
        id="video-tvc"
        object-fit
      >抱歉，您的浏览器不支持内嵌视频</video>
    </div>

    <el-dialog :close-on-click-modal="false" id="Model" :visible.sync="modal1" v-model="modal1" width="40%" title="Account Application">
      <el-form :model="formValidate" label-position="top" :rules="ruleValidate">
        <el-row>
          <el-col>
            <el-form-item label="Full Name" prop="fName">
              <el-input v-model="formValidate.fullName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="Email" prop="mail">
              <el-input v-model="formValidate.email" :disabled="true"></el-input>
              <span v-if="isEmailName">
                Full Name or Email cannot be empty, please contact Muriel Liu &lt;
                <a
                  href="mailto:muriel.liu@publicismedia.com"
                  target="_blank"
                >muriel.liu@publicismedia.com</a>&gt; to fill in.
              </span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" :gutter="16">
          <el-col :span="12">
            <el-form-item label="Agency" prop="departInfo">
              <el-select v-model="formValidate.departInfo">
                <el-option
                  v-for="item in agencyList"
                  :value="item.value"
                  :key="item.value"
                >{{item.name }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Office Location" prop="officeLocation">
              <el-select v-model="formValidate.officeLocation">
                <el-option
                  v-for="item in locationList"
                  :value="item.value"
                  :key="item.value"
                >{{item.name }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" justify="space-between" :gutter="16">
          <el-col :span="12">
            <el-form-item label="Required Product" prop="appId">
              <el-input v-model="applyProduct.name" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Title" prop="title">
              <el-select v-model="formValidate.title">
                <el-option v-for="item in titleList" :value="item" :key="item">{{ item }}</el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="false">
          <el-col>
            <el-form-item label="Note">
              <el-input
                v-model="formValidate.note"
                type="textarea"
                :rows="4"
                placeholder="Enter something..."
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button type="text" @click="cancel">Cancel</el-button>
        <el-button type="primary" @click="apply">Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import bgp4 from '@/assets/video/login.mp4'
import logoImg from '@/assets/img/hl_logo.png';
import ProtailHeaderRightGroup from '@/components/ProtailHeaderRightGroup';
import Api from "@/api";
import config from '@/api/config';

export default {
  name: 'Home',
  components: {
    ProtailHeaderRightGroup,
  },
  data() {
    return {
      logoImg,
      isEmailName: false,
      appList: [],
      applyProduct: {},
      formValidate: {
        fullName: "",
        email: "",
        title: "",
        appId: 0,
        departInfo: "",
        officeLocation: "",
        note: ""
      },
      titleList: [],
      modal1: false,
      locationList: [
        {
          name: "Beijing",
          value: "Beijing"
        },
        {
          name: "Shanghai",
          value: "Shanghai"
        },
        {
          name: "Guangzhou",
          value: "Guangzhou"
        }
      ],
      agencyList: [
        {
          name: "Starcom",
          value: "Starcom"
        },
        {
          name: "Spark",
          value: "Spark"
        },
        {
          name: "Zenith",
          value: "Zenith"
        },
        {
          name: "Performics",
          value: "Performics"
        },
        {
          name: "I&T",
          value: "I&T"
        },
        {
          name: "Saatchi&Saatchi",
          value: "Saatchi&Saatchi"
        },
        {
          name: "Digitas",
          value: "Digitas"
        }
      ],
      ruleValidate: {
        fName: [
          {
            required: true,
            message: "The First Name cannot be empty",
            trigger: "blur"
          }
        ],
        departInfo: [
          {
            required: true,
            message: "Agency cannot be empty",
            trigger: "change"
          }
        ],
        name: [
          {
            required: true,
            message: "The name cannot be empty",
            trigger: "blur"
          }
        ],
        mail: [
          {
            required: true,
            message: "Mailbox cannot be empty",
            trigger: "blur"
          },
          { type: "email", message: "Incorrect email format", trigger: "blur" }
        ],
        officeLocation: [
          {
            required: true,
            message: "Office Location cannot be empty",
            trigger: "blur"
          }
        ],
        appId: [
          {
            type: "number",
            required: true,
            message: "The product cannot be empty",
            trigger: "change"
          }
        ],
        title: [
          {
            required: true,
            message: "The title cannot be empty",
            trigger: "change"
          }
        ]
      },
      applyParams: {
        appId: '',
        departInfo: '',
        officeLocation: '',
        note: ''
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let {status,data} = await Api.project.applist();
      if(data) this.appList = data
      // else this.$router.push({ name: "login" });
    },
    async applyOption(item) {
      let token = this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
      if(item.link && token) {
        return window.location.href = `${item.link}?token=${token}`
      } else {
        this.applyProduct = item;
        this.modal1 = true
        let {code,data} = await Api.project.applyOption();
        if(data) {
            this.formValidate.email = data.email;
            this.formValidate.fullName = data.fullName;
            if (
              this.formValidate.email === null ||
              this.formValidate.fullName === null
            ) {
              this.isEmailName = true;
            } else {
              this.isEmailName = false;
            }
            this.titleList = data.title;
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },
    cancel() {
      this.modal1 = false;
    },
    apply() {
       if (
        this.formValidate.fullName === '' ||
        this.formValidate.email === ''
      ) {
        this.$message({
          showClose: true,
          message: 'Full Name or Email cannot be empty, please contact Muriel Liu <muriel.liu@publicismedia.com> to fill in.',
          type: 'error'
        });
        return false;
      }
      if (
        this.formValidate.departInfo === "" ||
        this.formValidate.officeLocation === "" ||
        this.formValidate.title === ""
      ) {
        this.$message({
          showClose: true,
          message:"There are missing fields, please fill required info in the form.",
          type: 'error'
        });
        return false;
      } else {
        this.applyParams.appId = this.applyProduct.id
        this.applyParams.departInfo = encodeURIComponent(this.formValidate.departInfo)
        this.applyParams.officeLocation = this.formValidate.officeLocation
        this.applyParams.title = this.formValidate.title
        this.applyParams.note = this.formValidate.note
        Api.project.apply(this.applyParams).then(res => {
          let {code,data} = res
          this.formValidate.appId = 0;
          this.formValidate.departInfo = "";
          this.formValidate.officeLocation = "";
          this.formValidate.title = "";
          if(code === "000000") {
             this.$message({
              showClose: true,
              message:"We will send you feedback via email within 1-3 working days",
              type: 'success'
            });
          } else if(code === "E01") {
             this.$message({
              showClose: true,
              message:"The application for the current account has been completed",
              type: 'warning'
            });
          }else {
             this.$message({
              showClose: true,
              message:"Application failed",
              type: 'error'
            });
          }
          this.modal1 = false;
        })
      }
    },
    // goLink(item) {
    //   let token = this.$cookies.get("token");
    //   if(item.link) window.location.href = `${item.link}?token=${token}`
    //   else return
    // },
  },
  destroyed() {
    // this.$cookies.remove("login_token");
    // this.$cookies.remove("userToken");
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/var.scss';

.new-font {
  font-family: 'tradeGothicLHExtended' !important;
}

.sso-container .poc-body .el-main{
  background: transparent; 
}
.poc-container .poc-header {
  background:#6e7c7c;
}
.sso-container .poc-header{
  width: 100%;
  background: transparent;
  position: fixed;
  display: flex;
  z-index: 10;
  // justify-content: space-around;
  height: 120px !important;
  padding: 20px 0 20px 30px; 
}
.publicis-data-center {
  height: 100%;
  background:red !important;

  .sso-container {
    background: transparent;
    position: relative;
    z-index: 2;
  }

  .login-video {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: fill; /*这里是关键*/

    .video-tvc {
      height: 100%;
      object-fit: cover; /*这里是关键*/
    }
  }

  .publicis-data-center-body {
    padding: 0 $MP16;
    padding-bottom: 100px;
    &-project{
      padding-top: 53%;
      width: 62%;
      margin-left: 31%;
      transform: translate(0,-47%);
      position: fixed;
      ul{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
      }
      li{
        flex: 4;
        font-family: "Roboto" !important;
        font-weight: 0;
        margin: 10px;
        border-radius: 12px;
        min-height: 160px;
        padding: 20px 50px 20px 30px;;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        color: #fff;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      
      li div{
        position: relative;
        font-size: 14px;
      
      }
      li.app-items{
        /* background: rgba(40, 40, 40, 0.7); */
      }
      li.app-items.app-disabled{
        /* background: rgba(40, 40, 40, 0.7); */
        // background: transparent;
      }
      li.app-items:hover{
        cursor: pointer;
        // background: #ba9765;
        // background: rgb(186,151,101, 0.8);
        background: rgba(#ba9765, 0.8);
        transition: all 0.8s;
      }
      
      ul.project-one li{
        font-size: 18px !important;
        display: flex;
        padding: 20px 35px 30px;
        h3{
          word-wrap:break-word;
          line-height: 1;
          font-size: 48px !important;
          font-weight: 600;
          padding: 0;
          margin: 18px 0 16px;
        }
        i{
          font-size: 36px !important;
          font-weight: 600;
        }
        .desc{
          height: 145px;
        }
      }
      ul.project-two li{
        padding: 20px 35px 30px;
        h3{
          font-size: 24px;
          margin: 14px 0 12px;
        }
      }
      .desc{
        font-size: 16px;
        height: 120px;
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-line-clamp:6;//想要的行数
        -webkit-box-orient:vertical;
        line-height: 1.5;
        font-weight: 200;
      }
    }
  }
}

.card-item-box {
  position: relative;
  .goLink {
    position: absolute!important;
    bottom: -8px;
    right: -12px;
    display: inline-block;
    a {
      color: #fff;
    }
  }
}

.common-page {
  padding: 20px 30px;
}
</style>
