<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    @close = "onCancel"
    :close-on-click-modal="false"
    class="access-detail-dialog"
    title="提示"
  >
  <div v-if="typeName=='plan'">
    您的计划{{planName}}与启停计划冲突，今天此时段是否继续执行？"
  </div>
  <div v-else-if="typeName=='team'">
    您的计划{{groupName}}与启停计划冲突，今天此时段是否继续执行？"
  </div>
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('不继续')">不继续</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('继续')">继续</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import Api from "@/api";

export default {
  name: "accessDialog",

  props: {
    pubVue:Object,
    command:String,
    visible: {
      type: Boolean,
      default: false
    },
    planName:String,
    groupName:String,
    typeName: String,
    typeOptions: {
      type: Array,
      default: () => []
    },
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
data() {
    return {
      loading: false,
      showDialog:false,
      show: this.visible,
    }},
  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },

  methods: {
    onConfirm(status) {
      this.onCancel()
      if(this.typeName == 'plan'){
        this.pubVue.updateOppoAdPlanStatusFn(this.command,'',status)
      }else if(this.typeName == 'team'){
        this.pubVue.updateOppoAdGroupStatusFn(this.command,'',status)
      }else if(this.typeName == 'advert'){
        this.pubVue.updateOppoAdAdvetStatusFn(this.command,'',status)
      }
    },
    onCancel() {
      this.show= false;
    },
    /**
     * 批量更新数据
     */
    
  }
};
</script>
<style lang="scss" scoped>
.access-detail-dialog {
  .el-dialog .el-dialog__header {
    padding: 40px 40px 16px;
  }
  .el-form-item__label {
    font-size: 18px;
  }
  .redFont .el-input__inner,.redFont .el-input__count-inner{
    color: red;
  }
}
</style>

