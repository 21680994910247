<template>
    <div class="authority common-page">
      <!-- <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
          <el-breadcrumb-item >创建广告</el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
       <PocCardTable>
         <!-- 新建广告 -->
        <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/plan-management/accountSelectionApple' }">账户列表</el-breadcrumb-item>
                <el-breadcrumb-item>创建广告</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <h2 class="createAdtitle">
            <span>创建广告</span>
          </h2>
          <div class="createGroupId">
            <el-select 
              placeholder="请选择广告组"
              v-model="adGroupChoose">
              <el-option
                v-for="(item,i) in adGroupChooseOption"
                :key="i"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="createName">
            <el-input v-model = "createCdvertName" placeholder="请输入广告名称" class="createNameInput"></el-input>
          </div>
          <div class="advertWrap">
            <span class="advertNum">
              可以使用的自定义产品页面：{{advertNum}}
            </span>
            <div class="advertMaterialList">
              <el-radio-group class="adMaterialGroup" v-model="advertChecked" size="mini">
                  <el-radio
                    class="adMaterialList"
                    v-for="(item,i) in advertData"
                    :key="i"
                    :value="item.id"
                    :label="item"
                    @change="changeAdvert"     
                    border>
                    <div class="admaterialDetail">
                      <span class="admaterialImg">
                        <span class="admaList ">
                          <span 
                            class="admaImg" 
                            v-for="(val,index) in item.detail.appPreviewDeviceWithAssets.iphone_6_5.screenshots">
                            <img :src="val.assetUrl">
                            <!-- {{val}} -->
                          </span>
                        </span>
                      </span>
                      
                      <span class="admaList admaterialName">{{item.name}}</span>
                      <span class="admaList admaterialIntro">{{item.detail.shortDescription}}</span>
                    </div>
                  </el-radio>
              </el-radio-group>
            </div>
            <div class="advertBtns">
              <el-button class="newDialogCancel" type="primary" size="middle" @click="createAdvertCancel">取消保存</el-button>
              <el-button class="newDialog" type="primary" size="middle" @click="createAdvertSure">确定保存</el-button>
            </div>
          </div>
        </template>
        <!-- 正在加载 -->
        <el-dialog
        title=""
        :visible.sync="loadingDialogVisible"
        width="20%"
        :destroy-on-close="true"
        :show-close="false"
        :close-on-click-modal="false"
        class="loadingBg">
        <span class="loading">
          <span>正在加载</span>
          <i class="el-icon-loading"></i>
        </span>
      </el-dialog>
      </PocCardTable>
    </div>
  </template>

  <script>
  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  export default {
    data() {
      return {
        //上级页面带过来的数据
        userId:'',
        userName:'',
        deptId:'',
        ownerId:'',
        ownerName:'',
        campaignId:'',
        campaignName:'',
        typeStyle:'',
        supplySources:'',
        appId:'',
        tab:'',
        budget:'',
        adGroupId:'',
        adGroupName:'',
        //新建广告
        loadingDialogVisible:false,//正在载入
        createCdvertName:'',//输入广告名称
        advertNum:'',//可以使用的自定义产品页面数量
        advertData:[],//获取已有的素材
        advertChecked:'',//广告素材被选中的
        adMaId:'',//选中的素材id
        getadamId:'',//获取appid
        adGroupChoose:'',
        adGroupChooseOption:[],


      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.ownerId = this.$route.query.id
      this.ownerName = this.$route.query.name
      this.campaignId = this.$route.query.campaignId
      this.campaignName = this.$route.query.campaignName
      this.adGroupId = this.$route.query.adGroupId
      this.adGroupName = this.$route.query.adGroupName
      this.typeStyle = this.$route.query.type
      this.supplySources = this.$route.query.supplySources
      this.appId = this.$route.query.appId
      this.tab = this.$route.query.tab
      this.budget = this.$route.query.budget

      this.getAdvertList()//广告的素材列表
      this.getAllGroup()


    },
    
    methods: {
      //获取广告的素材列表
      getAdvertList(){
        let _this = this
        _this.loadingDialogVisible = true
        $http.axios.get("/api/apple/adGroup/getproductPage/"+_this.ownerId+'/'+_this.appId).then(res=> {
          if(res.data.code==200){
            _this.loadingDialogVisible = false
            _this.advertData = res.data.data
            _this.advertNum = res.data.data.length
          }
          else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            }) 
          }   
        })
      },

      //获取广告组下拉
      getAllGroup(){
        let _this = this
        $http.axios.get("/api/apple/adGroup/getAllGroup/"+_this.ownerId+"/"+_this.campaignId).then(res=> {
          if(res.data.code==200){
            _this.adGroupChooseOption = res.data.data
          }
          else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: 'error'
            }) 
          }   
        })
      },

      //change广告素材-选中
      changeAdvert(item){
        this.adMaId = item.id
        this.getadamId = item.adamId
      },

      //保存
      createAdvertSure(){
        let _this = this,params={}
        console.log(this.adMaId)
        if(_this.adGroupChoose == ''){
          _this.$message({
            showClose: true,
            message: '请选择相应的广告组',
            type: 'error'
          }) 
        }else{
          params = {
            'deptId':_this.deptId,
            'userId':_this.userId,
            'userName':_this.userName,
            'adamId':_this.getadamId,
            'campaignId':Number(_this.campaignId),
            'name':_this.createCdvertName,//广告名称
            'orgId':_this.ownerId,
            'productPageId':_this.adMaId,//素材id
            'adgroupId':_this.adGroupChoose,//广告组id
            'type':'CUSTOM_PRODUCT_PAGE'
          }
          $http.axios.post("/api/apple/adGroup/saveCreative",params).then(res=> {
            if(res.data.code==200){
              _this.$router.push({path:'/plan-management/advertApple', query: { id: _this.ownerId, name:_this.ownerName ,campaignId:_this.campaignId,
              campaignName:_this.campaignName,adGroupId:_this.adGroupId,adGroupName:_this.adGroupName,supplySources:_this.supplySources,appId:_this.appId,
              budget:_this.budget}})
            }
            else{
              let messageContent =res.data.msg || '请查看网络，稍后再试'
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              }) 
            }   
          })
        }
      },

      //取消
      createAdvertCancel(){
        this.$router.push({path:'/plan-management/advertApple', query: { id: this.ownerId, name:this.ownerName ,campaignId:this.campaignId,
          campaignName:this.campaignName,adGroupId:this.adGroupId,adGroupName:this.adGroupName,supplySources:this.supplySources,appId:this.appId,
          budget:this.budget}})
      },




    }
  }
  </script>
  <style lang="scss" scoped>
    .createAdtitle{
      margin:0;
      padding:15px 0;
      border-bottom:1px solid #F1F7F5;
      margin-bottom:20px;
    }
    .createAdtitle span{
      padding-left:13px;
      border-left:3px solid #48916F;
      font-size:14px;
      font-weight:700;
    }
    .advertWrap{
      box-sizing: border-box;
      padding:15px;
      border:1px solid #ddd;
      border-radius:5px;
    }
    ::v-deep .createName .el-input--mini .el-input__inner{
      /* height:40px!important;
      line-height:40px!important; */
      width:300px!important;
      border:none;
      background:#F5F7F6;
    }
    .createName{
      display: inline-block;
      vertical-align: middle;
      margin-bottom:15px;
    }
    .createGroupId{
      width:300px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom:15px;
      margin-right:10px;
    }
    ::v-deep .createGroupId .el-select{
      width:300px!important;
    }
    ::v-deep .createGroupId .el-input--mini .el-input__inner{
      border:none;
      background:#F5F7F6;
    }
    .loading{
      text-align:center;
    }
    .loading span,.loading i{
      display: block;
    }
    .loading span{
      font-size:20px;
      font-weight:700;
      color:#fff;
    }
    .loading i{
      margin-top:10px;
      font-size:60px;
      /* color: #409eff; */
      color:#fff;
    }
    /* .loadingBg{
      margin-top:200px;
    } */
    ::v-deep .loadingBg .el-dialog{
      margin-top:200px;
      background:rgba(255,255,255,0)!important;
      box-shadow: none;
    }
    .advertNum{
      font-size:12px;
      color:#999;
    }
    .advertMaterialList{
      width:100%;
      box-sizing: border-box;
      font-size:0;
      margin-top:15px;
    }
    /* ::v-deep .advertMaterialList .el-radio.is-bordered + .el-radio.is-bordered{
      margin-left:0!important;
    } */
    .adMaterialGroup{
      width:100%;
      font-size:0;
    }
    .adMaterialList{
      display:inline-block;
      vertical-align:middle;
      width:31.7%;
      box-sizing: border-box;
      background:#F5F7F6;
      font-size:12px;
    }
    ::v-deep .adMaterialGroup .el-radio--mini.is-bordered{
      margin:0;
      border:none;
      border-radius: 10px;
      margin:10px;
      padding:10px 30px 15px;
    }
    .admaterialDetail{
      font-size:12px;
    }
    .admaterialDetail .admaterialName{
      font-size:14px;
      font-weight:700;
      padding-top:10px;
    }
    .admaList{
      display:block;
      overflow: hidden;
    }
    .admaterialIntro{
      width:100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color:#999;
    }
    .admaterialImg{
      width:100%;
      font-size:0;
      overflow: hidden;
      padding:20px;
      box-sizing:border-box;
      border-radius:10px;
      background:#fff;
      display: block;
    }
    .admaImg{
      display:inline-block;
      vertical-align:top;
      width:100px
    }
    .admaImg img{
      width:100%;
    }
    .advertBtns{
      margin:20px auto;
      margin-top:30px;
      text-align:center;
    }
     /*预警-新*/
    .newAddfpStyle{
      position: relative;
    }
    .newReduceBtnStyle{
      position:absolute;
      top:50%;
      right:0;
      transform: translate(0,-50%);
    }
    .warnInfosList span i{
      font-style: normal;
    }
    .customWrap h3{
      font-size:18px;
      font-weight:700;
      margin:0;
      text-align:center;
    }
    .customWrap p{
      line-height:30px;
      padding:10px 25px;
      text-align:left;
    }
    .customWrap{
      margin-bottom:20px;
    }
    .adverOk{
      text-align:center;
    }
    .queryLeft,.queryRight{
      display: inline-block;
      vertical-align: middle;
    }
    .queryLeft{
      width:65%;
    }
    .queryRight{
      width:35%;
      text-align:right;
    }
    ::v-deep .dateStyle.el-range-editor.el-input__inner{
      width:240px;
      background:#F5F7F6;
      border:none;
    }
    ::v-deep .dateStyle.el-range-editor .el-range-input{
      background:#F5F7F6!important;
    }
    ::v-deep .queryLeft .el-input--mini .el-input__inner{
      width:160px;
      border:none;
      background:#F5F7F6;
    }
    ::v-deep .queryLeft .el-button + .el-button{
      margin-left:0!important;
    }
    ::v-deep .queryLeft .el-form-item{
      margin-bottom:0!important;
    }
    .materialHead{
      margin-bottom:20px;
    }
    .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
  </style>
