<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="hl_headers sticky">
          <div class="hl_breadCrumb hl_headerLeft">
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="hl_headerLeftDrowp"
            >
              <el-breadcrumb-item
                :to="{ path: '/auth-management/materialList' }"
                >运营管理</el-breadcrumb-item
              >
              <el-breadcrumb-item
                :to="{ path: '/auth-management/materialList' }"
                >素材库</el-breadcrumb-item
              >
              <el-breadcrumb-item>修改</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
        </div>
        <div class="deputy_text_title">修改</div>
        <div class="submit_form_container">
          <el-form label-width="86px">
            <el-form-item label="渠道名称">
              <el-input
                class="max_width"
                :value="
                  detailData.channelId == 1
                    ? '今日头条'
                    : detailData.channelId == 2
                    ? '快手'
                    : detailData.channelId == 3
                    ? 'OPPO'
                    : detailData.channelId == 4
                    ? 'VIVO'
                    : detailData.channelId == 5
                    ? '小米'
                    : ''
                "
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="素材名称">
              <el-input
                class="max_width"
                :value="detailData.mtName"
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="工单名称">
              <el-input
                class="max_width"
                :value="detailData.demandName || '-'"
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="上传人员">
              <el-input
                :value="detailData.createUserName"
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="制作人">
              <el-input
                :value="detailData.makerUserName"
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="工单编号">
              <el-input
                :value="detailData.demandNum || '-'"
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="上传时间">
              <el-date-picker
                :value="detailData.creatTimeStr"
                type="datetime"
                placeholder=""
                disabled
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="审核状态">
              <el-input
                v-if="detailData.status != 3 && detailData.status != 4"
                :value="
                  detailData.status == 1
                    ? '禁用'
                    : detailData.status == 2
                    ? '正常'
                    : detailData.status == 5
                    ? '运营审核拒绝'
                    : detailData.status == 6
                    ? '客户审核拒绝'
                    : detailData.status == 7
                    ? '媒体审核拒绝'
                    : ''
                "
                placeholder=""
                disabled
              ></el-input>
              <div v-if="detailData.status == 3">
                <el-button type="success" @click="adopt"
                  >运营审核通过</el-button
                >
                <el-button type="danger" @click="dialogFormVisible = true"
                  >运营审核拒绝</el-button
                >
              </div>
              <div v-if="detailData.status == 4">
                <el-button type="success" @click="adopt"
                  >客户审核通过</el-button
                >
                <el-button type="danger" @click="dialogFormVisible = true"
                  >客户审核拒绝</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              label="拒绝原因"
              v-if="
                detailData.status == 5 ||
                detailData.status == 6 ||
                detailData.status == 7
              "
            >
              <el-input
                class="max_width"
                type="textarea"
                :rows="6"
                :value="detailData.subTitle || '-'"
                placeholder=""
                disabled
              ></el-input>
            </el-form-item>
            <div class="image_contant disabled">
              <div v-if="detailData.materialType == 1">
                <el-form-item label="素材内容">
                  <div
                    :class="[
                      !detailData.url.imgList ||
                      detailData.url.imgList.length < 2
                        ? 'just_image_1'
                        : 'just_image_2',
                    ]"
                    v-if="detailData.type == 1"
                  >
                    <div
                      class="image_news"
                      v-for="(item, i) in detailData.url.imgList"
                      :key="i"
                    >
                      <el-image
                        v-if="item"
                        :src="item"
                        fit="contain"
                        :preview-src-list="[item]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                    <div
                      class="image_news"
                      v-if="
                        !detailData.url.imgList ||
                        detailData.url.imgList.length == 0
                      "
                    >
                      <div class="empty">
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                  <div class="just_image_1" v-else>
                    <div class="image_news">
                      <el-image
                        v-if="detailData.url.imgUrl"
                        :src="detailData.url.imgUrl"
                        fit="contain"
                        :preview-src-list="[detailData.url.imgUrl]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="封面">
                  <div class="just_image_1">
                    <div class="image_news">
                      <el-image
                        v-if="detailData.url.imgUrl"
                        :src="detailData.url.imgUrl"
                        fit="contain"
                        :preview-src-list="[detailData.url.imgUrl]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="落地页图片">
                  <div class="just_image_1">
                    <div class="image_news">
                      <el-image
                        v-if="detailData.url.pageUrl"
                        :src="detailData.url.pageUrl"
                        fit="contain"
                        :preview-src-list="[detailData.url.pageUrl]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
              <div v-else>
                <el-form-item label="素材内容">
                  <!-- 视频 -->
                  <div class="just_image_1" v-if="detailData.type == 2">
                    <div class="image_news">
                      <el-image
                        :src="videoUrlLj + firstWord"
                        v-show="loadIn == false"
                        fit="contain"
                        @click="reloadVideo(loadIn)"
                      ></el-image>
                      <video
                        id="myvideo"
                        ref="videoPouset"
                        controls
                        autoplay
                        playsinline
                        x5-playsinline
                        webkit-playsinline
                        v-show="loadIn == true"
                        controlslist="nodownload"
                        style="width: 100%; height: 100%; object-fit: contain"
                      ></video>
                    </div>
                  </div>
                  <!-- 图片 -->
                  <div
                    :class="[
                      !imgj || imgj.length < 2
                        ? 'just_image_1'
                        : 'just_image_2',
                    ]"
                    v-else
                  >
                    <div
                      class="image_news"
                      v-for="(item, index) in imgj"
                      :key="index"
                    >
                      <el-image
                        v-if="item"
                        :src="item"
                        v-show="loadIn == false"
                        fit="contain"
                        :preview-src-list="[item]"
                      ></el-image>
                      <div class="empty" v-else>
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                    <div class="image_news" v-if="!imgj || imgj.length == 0">
                      <div class="empty">
                        <img src="@/assets/img/noImage.png" />
                        <div>暂无图片</div>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </div>
            </div>
            <!-- TODO(没有任何信息需要保存的) -->
            <el-form-item class="submit_bottom">
              <el-button @click="returnMaterialTable">返回</el-button>
              <el-button type="primary" @click="submit">保存</el-button>
            </el-form-item>
          </el-form>
          <!-- 审核拒绝弹框 -->
          <el-dialog
            class="icon_custom_popup"
            title="拒绝原因"
            :visible.sync="dialogFormVisible"
            width="400px"
          >
            <div class="icon">
              <img src="@/assets/img/juJueIcon.png" />
            </div>
            <el-form :model="jujueform" :rules="rules" ref="jujueform">
              <el-form-item prop="rejectionReason">
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="jujueform.rejectionReason"
                  placeholder="请输入拒绝理由"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="rejection(jujueform)"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </template>
      <template slot="headerGroup"> </template>
    </PocCardTable>
  </div>
</template>
  
  <script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";

export default {
  name: "videoEdit",
  data() {
    return {
      dialogFormVisible: false,
      jujueform: {
        rejectionReason: "",
      },
      editlId: "",
      detailData: {},
      editTitle: "",
      radio: "运营审核通过",
      editParams: {
        materialId: "",
        operationkey: "",
        tips: "",
        userId: "",
        username: "",
      },
      subParams: {
        materialId: "",
        title: "",
        userId: "",
        username: "",
      },
      videoUrlLj: "",
      imgUrlList: [],
      firstWord: "?x-oss-process=video/snapshot,t_1,m_fast", //获取视频第一帧作为图片
      loadIn: false, //是否加载视频
      imgj: [],
      rules: {
        rejectionReason: [
          { required: true, message: "请输入拒绝理由", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.getId();
    this.getDetail();
  },
  methods: {
    //点击返回按钮返回到列表页
    returnMaterialTable() {
      this.$router.push("/auth-management/MaterialList");
    },

    getId() {
      this.editlId = this.$route.params && this.$route.params.id;
    },

    //视频详情回显
    getDetail() {
      let that = this;
      $http.axios
        .post("/api/operationManagement/material/" + that.editlId)
        .then((res) => {
          if (res.data.code == 200) {
            that.detailData = res.data.data;
            that.videoUrlLj = that.detailData.url.videoUrl;
            that.imgUrlList = that.detailData.url.imgList;
            that.imgj = that.detailData.url.imgList;
            console.log(that.imgUrlList);
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //修改审核状态--通过
    adopt() {
      let that = this;
      that.editParams.materialId = that.editlId;
      that.editParams.userId = that.$sessionStorage.getItem(config.USERID) || 1;
      that.editParams.username = that.$sessionStorage.getItem(config.USERNAME);
      that.editParams.operationkey = "pass";
      $http.axios
        .post("/api/operationManagement/material/changestatus", that.editParams)
        .then((res) => {
          if (res.data.code == 200) {
            that.getDetail();
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    //拒绝
    rejection(jujueform) {
      let that = this;
      that.$refs.jujueform.validate((valid) => {
        if (valid) {
          that.editParams.materialId = that.editlId;
          that.editParams.userId =
            that.$sessionStorage.getItem(config.USERID) || 1;
          that.editParams.username = that.$sessionStorage.getItem(
            config.USERNAME
          );
          that.editParams.operationkey = "refuse";
          that.editParams.tips = that.jujueform.rejectionReason;
          $http.axios
            .post(
              "/api/operationManagement/material/changestatus",
              that.editParams
            )
            .then((res) => {
              if (res.data.code == 200) {
                that.dialogFormVisible = false;
                that.getDetail();
              } else {
                that.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
        } else {
          return false;
        }
      });
    },

    copy() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = this.detailData.localUrl; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    },

    //保存
    submit() {
      let that = this;
      that.subParams.materialId = that.editlId;
      that.subParams.title = that.editTitle;
      that.subParams.userId = that.$sessionStorage.getItem(config.USERID) || 1;
      that.subParams.username = that.$sessionStorage.getItem(config.USERNAME);
      $http.axios
        .post("/api/operationManagement/material/update", that.subParams)
        .then((res) => {
          if (res.data.code == 200) {
            this.$router.push("/auth-management/MaterialList");
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },

    reloadVideo(loadIn) {
      let _this = this;
      if (loadIn == false) {
        this.$refs.videoPouset.src = this.videoUrlLj;
        this.$refs.videoPouset.play();
        let timer = setTimeout(function () {
          _this.loadIn = true;
          window.clearTimeout(timer);
        }, 1000);
      } else {
        this.loadIn = false;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.submit_form_container {
  width: 100%;
  margin-top: 20px;
}
</style>
