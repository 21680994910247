export default {
  "dc:数据中心": "数据中心",
  "dc:请输入": "请输入",
  "dc:请输入名称": "请输入名称",
  "dc:请选择": "请选择",
  "dc:新增": "新增",
  "dc:退出": "退出",
  "dc:刷新": "刷新",
  "dc:删除": "删除",
  "dc:计算": "计算",
  "dc:详情": "详情",
  "dc:下载": "下载",
  "dc:上传": "上传",
  "dc:确认": "确认",
  "dc:取消": "取消",
  "dc:保存成功": "保存成功",
  "dc:上传成功": "上传成功",
  "dc:登录": "登录",
  "dc:保存": "保存",
  "dc:加载中": "加载中",
  "dc:运行中": "运行中",
  "dc:返回": "返回",
  "dc:删除成功": "删除成功",
  "dc:查看": "查看",
  "dc:操作": "操作",
  "dc:编辑": "编辑",

  // 权限
  "dc:产品名": "产品名",
  "dc:权限": "权限名字",
  "dc:模块": "模块",
  "dc:权限code": "权限 Code",
  "dc:添加权限": "添加权限",
  // 账号
  "dc:账号名称": "账号名称",
  "dc:账号管理": "账号管理",
  "dc:账号维护": "账号维护",
  "dc:账号关键词": "账号关键词",
  "dc:账号状态": "账号状态",
  "dc:品牌": "品牌",
  "dc:邮箱": "邮箱",
  "dc:添加品牌": "添加品牌",
  "dc:权限管理": "权限管理",
  'dc:品牌定义': "品牌定义",
  "dc:权限定义": "权限定义",
  "dc:角色定义": "角色定义",
  "dc:编码": "编码",
  "dc:网络地址": "网络地址",
};