<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>运营管理</el-breadcrumb-item>
        <el-breadcrumb-item>素材库</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
          <div class="materialHead">
            <div class="materialHBtns">
              <el-form :inline="true" :model="materiaForm" ref="materiaForm" class="demo-form-inline">
                  <el-form-item label="" prop="searchmaterialId">
                    <el-input v-model="materiaForm.searchmaterialId" clearable placeholder="请输入素材ID"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="mTitle">
                    <el-input v-model="materiaForm.mTitle" clearable placeholder="请输入主题文案"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="materialName">
                    <el-input v-model="materiaForm.materialName" clearable placeholder="请输入素材名称"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="demandNameQuery">
                    <el-input v-model="materiaForm.demandNameQuery" clearable placeholder="请输入工单名称"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="mTab">
                    <el-select v-model="materiaForm.mTab" filterable clearable placeholder="请选择素材标签" @focus="focusTag" @change="getTagList">
                      <el-option
                        v-for="(item,i) in materiaTabOptions"
                        :key="i"
                        :label="item.tagName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="mDate">
                    <el-date-picker
                      v-model="materiaForm.mDate"
                      type="daterange"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="" prop="mUser">
                    <el-select v-model="materiaForm.mUser" filterable clearable placeholder="请选择制作人" @change="getUserId($event,'createUserName')">
                      <el-option
                        v-for="(item,i) in materiaUserOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="mUserGroup">
                    <el-select v-model="materiaForm.mUserGroup" filterable clearable placeholder="请选择制作组">
                      <el-option
                        v-for="(item,i) in materiaGroupOptions"
                        :key="i"
                        :label="item.groupName"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="mCode">
                    <el-input v-model="materiaForm.mCode" placeholder="请输入工单编号"></el-input>
                  </el-form-item>
                  <el-form-item label="" prop="mCostStatus">
                    <el-select v-model="materiaForm.mCostStatus" clearable filterable placeholder="请选择消耗状态" @change="getUserId($event,'costStatus')">
                      <el-option
                        v-for="(item,i) in materiaUserCostStatus"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <i class="el-icon-arrow-up" @click="hiddenList"></i> -->
                <!-- </div> -->
                <el-form-item>
                  <div class="fL" style="margin-right:10px;">
                    <el-button type="primary" size="small" @click="getMaterialList(1)">查询</el-button>
                    <el-button type="info" plain size="small" @click="resetForm('materiaForm')">重置</el-button>
                  </div>
                </el-form-item>
              </el-form>  
              <div>
              </div>
            </div>
          </div>
          <div class="materialTab">
            <span class="active">视频</span>
            <span @click="materialShow">图文</span>
          </div>
          <div class="addUploadBtn">
            <el-button type="primary" size="small" @click="sureChangeMaterial">确定更换素材</el-button>
            <el-button type="primary" plain size="small" @click="cancleChangeMaterial">取消更换素材</el-button>
          </div>
          <div class="materiaContent">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55"
                fixed="left">
              </el-table-column>
              <el-table-column
                prop="name"
                label="原上传渠道"
                width="100"
                fixed="left">
                <template slot-scope="scope">
                  <span v-if="scope.row.channelId == 1">今日头条</span>
                  <span v-else-if="scope.row.channelId == 2">快手</span>
                  <span v-else-if="scope.row.channelId == 3">OPPO</span>
                  <span v-else>VIVO</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="素材id"
                width="180"
                fixed="left">
                <template slot-scope="scope">{{scope.row.id}}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="素材名称"
                width="180">
                <template slot-scope="scope">{{scope.row.mtName}}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="工单名称"
                width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.demandName == ''">--</span>
                  <span v-else>{{scope.row.demandName}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="资源位"
                width="180">
                <template slot-scope="scope">
                  <div v-for="item in resourceBitsOption">
                    <span v-if="item.globalSpecId == scope.row.materialType">
                      {{item.resourceBits}}
                    </span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column 
                prop="name"
                label="素材标签"
                width="180"> 
                <template slot-scope="scope">{{scope.row.showTagNames}}</template>
              </el-table-column>
              <el-table-column 
                prop="name"
                label="制作组"
                width="180"> 
                <template slot-scope="scope">{{scope.row.makerGroupName}}</template>
              </el-table-column>
              <el-table-column
                label="素材内容"
                width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.type==2" class="picTip">
                    <el-popover
                      placement="right"
                      title=""
                      trigger="hover">
                     
                      <img v-if="scope.row.costStatus != 10" :src="require('../../../assets/img/tip'+scope.row.costStatus+'.png')" style="position:absolute;width:40%;top:0;left:0;"/>                     
                      <img :src="scope.row.url.videoUrl+firstWord" style="width:200px;cursor: pointer"/>
                      
                      <img slot="reference" :src="scope.row.url.videoUrl+firstWord" style="width:50%;cursor: pointer"  @click="showVideo(scope.row)">
                      <img slot="reference" v-if="scope.row.costStatus != 10" class="tip" :src="require('../../../assets/img/tip'+scope.row.costStatus+'.png')" />

                    </el-popover>
                  </span>
                </template>
              </el-table-column>
              <el-table-column 
                prop="costStatus"
                label="投放状态"
                width="180"> 
                <template slot-scope="scope">{{scope.row.costStatusName}}</template>
              </el-table-column>
              <el-table-column 
                prop="name"
                label="主题文案"
                width="120"> 
                <template slot-scope="scope">{{scope.row.mainTitile}}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="编导"
                width="80">
                <template slot-scope="scope">{{scope.row.directorUserName}}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="摄影"
                width="80">
                <template slot-scope="scope">{{scope.row.photographyUserName}}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="剪辑"
                width="80">
                <template slot-scope="scope">{{scope.row.clipUserName}}</template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="素材状态"
                width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.status==1">禁用</span>
                  <span v-if="scope.row.status==2">正常</span>
                  <span v-if="scope.row.status==3">待审核</span>
                  <span v-if="scope.row.status==4">运营审核通过</span>
                  <span v-if="scope.row.status==5">运营审核拒绝</span>
                  <span v-if="scope.row.status==6">客户审核拒绝</span>  
                  <span v-if="scope.row.status==7">媒体审核拒绝</span>  
                </template>
              </el-table-column>
              <el-table-column
                label="上传时间"
                width="200">
                <template slot-scope="scope">{{scope.row.creatTimeStr}}</template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footerPaging">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageCount"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.totalRow">
          </el-pagination>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
        <refuseReasonDialog :visible.sync="refuseIs" :typeName="typeName" :id="id" :pubVue = "pubVue"></refuseReasonDialog>
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
// import { accessUserId } from '@/api/http';
// import { accessDeptId } from '@/api/http';
import refuseReasonDialog from '@/views/dialog/refuseReasonDialog'

export default {
  name: 'materiaList',
    components:{
      refuseReasonDialog
    },
  data() {
    return {
      pubVue:this,
      refuseIs:false,
      showTheForm:true,//表头是否显示全部表单
      materiaChannelValue:'', //头部渠道搜索value
      qdoptions: [],
      materiaForm:{//头部查询表单的prop
        materiaChannelValue:'',
        mStatus:'',
        mType:'',
        mPosition:'',
        mTitle:'',
        mTab:'',
        mDate:'',
        mUser:'',
        mAccount:'',
        mCode:'',
        demandNameQuery:'',
        mUserGroup:'',
        myOldChannel:'',
        materialName:'',
        mCostStatus:'',//消耗状态
        searchmaterialId:''
      },
      myOldChannelOptions:[{
        label:'今日头条',
        value:'1'
      },{
        label:'快手',
        value:'2'
      }],
      materiaStatusOptions: [{//查询表单的选择素材状态
        value: '1',
        label: '禁用'
      }, {
        value: '2',
        label: '正常'
      }, {
        value: '3',
        label: '待审核'
      }, {
        value: '4',
        label: '运营审核通过'
      }, {
        value: '5',
        label: '运营审核拒绝'
      }, {
        value: '6',
        label: '客户审核拒绝'
      },{
        value: '7',
        label: '媒体审核拒绝'
      }],
      // materiaTypeOptions:[{//查询表单的选择素材资源类型
      //   value: '选项1',
      //   label: '联盟'
      // }, {
      //   value: '选项2',
      //   label: '富媒体'
      // }, {
      //   value: '选项3',
      //   label: '信息流'
      // }],
      // materiaPositionOptions:[{//查询表单的选择资源位
      //   value: '选项1',
      //   label: '1'
      // }, {
      //   value: '选项2',
      //   label: '2'
      // }, {
      //   value: '选项3',
      //   label: '3'
      // }],
      materiaTabOptions:[],//查询表单的选择素材标签
      materiaUserOptions:[],
      materiaGroupOptions:[],
      materiaUserCostStatus:[
        // {name:'未通过',id:10},
        {name:'未投放',id:20},
        {name:'无消耗',id:30},
        {name:'有消耗',id:40}
        ], //消耗状态下拉列表内容
      materiaAccountOptions:[],
      tableData: [],
      multipleSelection: [],
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      materialParams:{//列表参数
        channlId: '',
        pages: '',
        pageCount: '',
        userId: '',
        status:'',
        copyWriting:'',
        endTime:'',
        startTime:'',
        createUserName:'',
        materialTag:'',
        materialType:'',
        demandNum:'',
        demandName:'',
        makerGroupId:'',//制作组
        costStatus:'',//消耗状态参数
        mtChannel:'',
        mtName:'',
      },
      deleteParams:{
        materialId:'',
        userId:'',
        username:''
      },
      tagListParams:{//标签列表参数
        tagType:'',
        userId:'',
        channel:''
      },
      queryParams:{//查询素材库列表
        channlId: '',
        page: '',
        pageCount: '',
        userId: '',
        status:'',
        copyWriting:'',
        endTime:'',
        startTime:'',
        createUserName:'',
        materialTag:'',
        materialType:'',
        demandNum:''
      },
      formLabelWidth:'120px',
      activeName:'picture',
      pictureTag:'',
      videoTag:'',
      newTagParams:{
        createUser:'',
        tagName:'',
        tagType:2
      },
      sendoutVisible:false,
      accountName:'',
      addWaterMark:'',
      accountNameOptions:[],
      radio:'',
      waterMarkParams:{ //获取列表和查询列表
        channel:'',
        accountId:'',//选择账户名称id
        userId:'',//选择当前用户运营人员id
        searchUserId:''//选择运营人员id
      },
      innerVisible:false,
      bigpic:false,
      rejectionReason:'',
      editParams:{
        materialId:'',
        operationkey:'',
        tips:'',
        userId:'',
        username:''
      },
      dialogFormVisible:false,
      imgArray:[],
      waterData:[],
      linkParams:{
        materialId:[],
        userId:'',
        userName:'',
        waterMarkId:''
      },
      bacthlinkParams:{
        materialId:[],
        userId:'',
        userName:'',
        waterMarkId:''
      },
      linkData:[],
      bacthLinkData:[],
      isShowLink:false,
      isShowBacthLink:false,
      materialIdsPara:[],//选中素材的materialIds
      channgetagParams:{
        channel:'',
        materialIds:[],
        oldTagId:'',
        userId:'',
        userName:'',
        newTagIds:''
      },
      modifyTag:false,
      // modifyTagName:'',
      deleteTag:false,
      // deleteTagName:'',
      deletePeopleOptions:[],
      deletePeople:'',
      deltagParams:{
        channel:'',
        materialIds:[],
        oldTagId:'',
        userId:'',
        userName:''
      },
      addTag:false,
      materialTagList:[],
      peopleTagList:[],
      peopleTagOptions:[],
      addParams:{
        tagIds:[],
        userIds:[],
        userId:'',
        userName:''
      },
      // sendttParams:{
      //   accountId:'',
      //   channel:'',
      //   materialIds:[],
      //   userId:'',
      //   userName:''
      // },
      sendttParams:{
        accountDtoList:[{
          // accountId:'',
          // channel:'',
        }],
        materialIds:[],
        userId:'',
        userName:''
      },
      sendksParams:{
        accountDtoList:[{
          // accountId:'',
          // channel:'',
        }],
        materialIds:[],
        userId:'',
        userName:''
      },
      newTagVisible:false,
      newtagName:'',
      addtagParams:{
        channel:'',
        createUser:'',
        tagName:'',
        tagType:''
      },
      addHadTagVisible:false,
      addhadtagParams:{
        channel:'',
        materialIds:[],
        newTagIds:'',
        userId:'',
        userName:''
      },
      sendToutiaoVisible:false,
      syntoutiao:[],
      syntoutiaoOptions:[],
      synkuaishouOptions:[],
      synkuaishou:[],
      projecttoutiao:'',//同步头条--项目select
      projecttoutiaoOptions:[],//同步头条--项目options
      projectkuaishou:'',//同步快手--项目select
      projectkuaishouOptions:[],//同步快手--项目options
      sendKuaishouVisible:false,
      materialImgList:[],
      materialImgListImg:[],
      materialImgListVideo:'',
      userIdAccount:'',//存储在本地的用户id||获取账户名称下拉列表入参
      userNameAccount:'',//存储在本地的用户name
      choiceTag:false,
      centerDialogVisible:false,
      videoUrlShow:'',
      typeFlags:'',
      firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',
      checkedWaterUrl:'',
      sendoutBatchVisible:false,
      tagNameTable:'',//修改/删除素材标签name
      tagIdTable:'',//修改/删除素材标签id
      batchIsPastParams:{
        "materialIds": [],
        "userId": 0,
        "username": ""
      },
      ptojectTTParams:{
        materialIds:[]
      },
      //运营/客户审核通过入参
      batchIsPastUrl:['batchPassMaterialByUser',//运营接口
                         'batchPassMaterialByCostmer'],//客户接口
      linksjsParams:{},
      sjslinkData:[],
      sjssendoutVisible:false,
      plsjssendoutVisible:false,
      typeName:'素材',
      id:null,
      getchannelId:'',
      getchannelIdnum:'',
      uploadingShow:true,
      exprtVisible:false,
      //创意列表中带过来的参数
      advertiseId:'',//广告主id
      chuangyiId:'',//创意id
      materialNormId:'',//创意无聊规格id
      placeType:'',//展现形式
      virtualPositionId:'',//虚拟广告位id
      advertId:'',//广告id
      advertName:'',//广告名称
      ownerName:'',//广告主名称
      appName:'',//应用名
      productName:'',//产品名
      subTitle:'',//副标题
      title:'',//标题
      tzGroupId:'',//广告组id
      resourceBitsOption:[]
    }
  },
  computed: {
     
  },
  mounted() {
    this.userIdAccount = this.$sessionStorage.getItem(config.USERID) || 1
    this.userNameAccount = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.getuserChannel()
    this.getStaffDetail()
    this.getAccountList()
    this.advertiseId = this.$route.query.advertiseId//广告主id
    this.chuangyiId = this.$route.query.id//创意id
    this.materialNormId = this.$route.query.materialNormId//创意无聊规格id
    this.placeType = this.$route.query.placeType//展现形式
    this.virtualPositionId = this.$route.query.virtualPositionId//虚拟广告位id
    this.advertId = this.$route.query.advertId//广告id
    this.advertName = this.$route.query.name//广告名称
    this.ownerName = this.$route.query.ownerName//广告主名称
    this.appName = this.$route.query.appName,//应用名
    this.productName = this.$route.query.productName,//产品名
    this.subTitle = this.$route.query.subTitle,//副标题
    this.title = this.$route.query.title//标题
    this.tzGroupId = this.$route.query.groupId//广告组id


    this.materiaForm.myOldChannel = 4
    this.getchannelId = '4'
    this.getchannelIdnum = 4
  },
  // created(){
  //   var params = {
  //     page: this.page,
  //     pageCount: this.pageCount
  //   }
  //   this.findAll(params)
  // },
  methods: {
    resourceBitsList(){
      let that = this,params={}
      params={
        'channelId':this.getchannelId,
        'materialType':2
      }
      $http.axios.post("/api/operationManagement/materialTypeSize/listByChannel" ,params).then(res=> {
        if(res.data.code == 200){
          that.resourceBitsOption = res.data.data 
          console.log(that.resourceBitsOption)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     refuseFn(id){
        this.refuseIs = true
        this.id = id
      },
    //头部表单重置
    resetForm(materiaForm) {
      this.$refs[materiaForm].resetFields();
      // this.getMaterialList()
      this.$router.go(0)
    },

    //点击按钮不变色
    handleClick(event) {
      let target = event.target;
      if(target.nodeName == "I"){
        target = event.target.parentNode;
      }
      target.blur();
    },

    //修改后的关联到user的channel
    getuserChannel(){
      let that = this
      let userChannel = String(that.userIdAccount)
      $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
        console.log(response.data.data)
        if(response.data.data.length == 0){
          that.getMaterialList()
        }else{
          that.qdoptions = response.data.data
          that.materiaForm.materiaChannelValue =  response.data.data[0].name
          // that.materiaForm.myOldChannel = response.data.data[0].id
          that.materiaForm.myOldChannel = 4
          that.materialParams.channlId = response.data.data[0].id
          that.tagListParams.channel = response.data.data[0].id
          that.materialParams.channlId = response.data.data[0].id
          that.waterMarkParams.channel = response.data.data[0].id
          that.channgetagParams.channel = response.data.data[0].id
          that.deltagParams.channel = response.data.data[0].id
          that.addtagParams.channel = response.data.data[0].id
          that.addhadtagParams.channel = response.data.data[0].id
          // that.getchannelId = String(response.data.data[0].id)
          // that.getchannelIdnum = response.data.data[0].id
          that.getMaterialList()
          that.produceGroupList()
          // that.resourceBitsList()
        }
        
      })
    },

    //根据id弹出视频
    showVideo(row){
      this.centerDialogVisible = true
      this.videoUrlShow = row.url.videoUrl
      this.$nextTick(() => {
        this.$refs.videoPouse.play()
      })
    },
    handleDialogClose(){
      this.centerDialogVisible = false
      this.$refs.videoPouse.pause()
    },

    //模糊搜索
    querySearch(queryString, cb) {
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString,name)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    //模糊搜索带参数的版本
    multipleFn(queryString, cb, name){
      var restaurants = this.tableData;
      var results = queryString ? restaurants.filter(this.createFilter(queryString,name)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString,name) {
      return (restaurant) => {
        if(name == 'wa'){// mainTitile主题文案
          return (restaurant.mainTitile.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        }else if(name=='sc'){// mtName素材名称
          return (restaurant.mtName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        }else if(name == 'gd'){//demandName工单名称
          return (restaurant.demandName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        }
      };
    },

    handleSelect(item) {
      console.log(item);
    },

    materialShow(){
      // this.$router.push('/vivoAccount-management/ChangeMaterialListPic')
      this.$router.push({name:'VivoAccountManagement.ChangeMaterialListPic',
      query:{
        advertiseId:this.advertiseId,//广告主id
        id:this.chuangyiId,//创意id
        materialNormId:this.materialNormId,//创意无聊规格id
        placeType:this.placeType,//展现形式
        virtualPositionId:this.virtualPositionId,//虚拟广告位id
        advertId:this.advertId,//广告id
        name:this.advertName,//广告名称
        ownerName:this.ownerName,//广告主name
        appName:this.appName,//应用名
        productName:this.productName,//产品名
        subTitle:this.subTitle,//副标题
        title:this.title,//标题
        groupId:this.tzGroupId,//广告组id

      }})
    },

    // 点击按钮进入水印库
    waterMark(){
      this.$router.push('/auth-management/WaterMark')
    },
    // 跳转视频详情页
    videoDetail(e){
      this.$router.push({path:'/auth-management/VideoDetail/'+e})
    },
    // 跳转视频修改页
    videoEdit(e){
      this.$router.push({path:'/auth-management/VideoEdit/'+e})
    },
    //表头点击向右三角显示
    showList(){
      this.showTheForm = false;
    },
    //表头点击向上三角隐藏
    hiddenList(){
      this.showTheForm = true;
    },

    //获取渠道id
    getChannelId(event){
      this.materialParams.channlId = event
    },
    //获取素材状态
    // getStatus(event){
    //   this.materialParams.status = event
    // },
    //获取制作人id
    getUserId(event,codeName){
      this.materialParams[codeName] = event
    },

    //获取账户名称下拉列表
    getAccountList(){
      let that = this
      $http.axios.get("/api/channel/accountUser/selectList?userId="+that.userIdAccount).then(function(response) {
        that.materiaAccountOptions = response.data
        that.syntoutiaoOptions = response.data
        that.synkuaishouOptions = response.data
        that.accountNameOptions = response.data
      })
    },

    //制作组无参下拉框
    produceGroupList(){
      let that = this
      $http.axios.get("/api/operationManagement/materialDemand/channelgrouplist?channelId="+that.getchannelId).then(res=> {
        if(res.data.code == 200){
          that.materiaGroupOptions = res.data.data
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    // yuanlaichannelId(val){
    //   this.getchannelId = String(val)
    //   this.getchannelIdnum = val
    //   this.produceGroupList()
    // },


    //获取素材库列表
    getMaterialList(page){
      let that = this
      if(page == 1){
        that.page = 1
      }
      that.materialParams.copyWriting = that.materiaForm.mTitle
      that.materialParams.demandName = that.materiaForm.demandNameQuery
      that.materialParams.endTime = that.materiaForm.mDate[1]
      that.materialParams.startTime = that.materiaForm.mDate[0]
      that.materialParams.demandNum = that.materiaForm.mCode
      that.materialParams.makerGroupId = that.materiaForm.mUserGroup
      // that.materialParams.mtChannel = that.materiaForm.myOldChannel
      // that.materialParams.channlId = that.materiaForm.myOldChannel
      that.materialParams.mtChannel = 4
      that.materialParams.channlId = 4
      that.materialParams.mtName = that.materiaForm.materialName
      that.materialParams.materialType = 2

      that.materialParams.pages = that.page
      that.materialParams.pageCount = that.pageCount
      that.materialParams.userId = that.userIdAccount
      // that.materialParams.userId = 304
      that.materialParams.materialId = that.materiaForm.searchmaterialId
      that.materialParams.status = 2
      that.materiaForm.myOldChannel = 4
      
      $http.axios.post("/api/operationManagement/material/list",that.materialParams).then(res=> {
        if(res.data.code == 200){
          that.tableData = res.data.data.items
          that.totalRow = res.data.data.totalCount  
          that.tagNameTable = res.data.data.tagName
          that.tagIdTable = res.data.data.tagId
          if(that.tagIdTable!=null){
            that.choiceTag = true
          }
          that.typeFlags = res.data.data.typeFlag
          that.tableData.forEach(obj=>{
            if(obj.costStatus == 20){
              obj['costStatusName'] = '未投放'
            }else if(obj.costStatus == 30){
              obj['costStatusName'] = '无消耗'
            }else if(obj.costStatus == 40){
              obj['costStatusName'] = '有消耗'
            }
          }) 
          that.resourceBitsList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     //获取人员信息
     getStaffDetail(){
      let that = this
      $http.axios.get("/api/system/user/optionselect").then(function(response) {
        that.materiaUserOptions = response.data
        that.peopleTagOptions = response.data
        that.deletePeopleOptions = response.data
      })
    },

    // //获取渠道列表
    // getChannelList(){
    //   let that = this
    //   $http.axios.get("/api/system/user/getChannelList").then(function(response) {
    //     if(response.data.code == 200){
    //       that.qdoptions = response.data.data
    //     } else {
    //       that.$message({
    //         showClose: true,
    //         message: response.data.msg,
    //         type: 'error'
    //       })
    //     }
    //   })
    // },

    //删除素材列表
    deletematerial(e){
      let that = this
      that.deleteParams.materialId=e
      that.deleteParams.userId = that.userIdAccount
      that.deleteParams.username=that.userNameAccount
      $http.axios.post("/api/operationManagement/material/delmaterial",that.deleteParams).then(res=> {
        if(res.data.code == 200){
          that.getMaterialList()      
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    focusTag(){
      this.getTagList()
    },

    //查询的素材标签下拉框数据
    getTagList(event){
      let that = this
      that.materialParams.materialTag = event
      that.tagListParams.tagType = 2
      that.tagListParams.userId = that.userIdAccount
      // that.tagListParams.channel = that.materiaForm.myOldChannel
      that.tagListParams.channel = 4
      $http.axios.post("/api/operationManagement/material/taglist",that.tagListParams).then(res=> {
        if(res.data.code == 200){
          that.materiaTabOptions = res.data.data
          
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //查询素材库列表
    queryMaterialList(){
      let that = this
      that.queryParams.pages = that.page
      that.queryParams.pageCount = that.pageCount
      that.queryParams.userId = that.userIdAccount
      that.queryParams.copyWriting = that.materiaForm.mTitle
      that.queryParams.endTime = that.materiaForm.mDate[1]
      that.queryParams.startTime = that.materiaForm.mDate[0]
      that.queryParams.materialType = 2
      that.queryParams.demandNum = that.materiaForm.mCode

      $http.axios.post("/api/operationManagement/material/list",that.queryParams).then(res=> {
        if(res.data.code == 200){
          that.tableData = []
          that.tableData = res.data.data.items 
          
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    modifyTagList(event){
      this.channgetagParams.newTagIds = event
    },
    //修改权限
    queryjurisdiction(){
      let that = this
      that.chooseTableFn('modifyTag')//批量选中
    },

    //确定修改权限
    queryjurisdictionSure(){
      let that = this
      that.channgetagParams.materialIds = that.materialIdsPara
      that.channgetagParams.oldTagId = that.tagIdTable
      that.channgetagParams.userId = that.userIdAccount
      that.channgetagParams.userName = that.userNameAccount
      that.channgetagParams.channel = that.getchannelIdnum
      if(that.channgetagParams.newTagIds!=''){//如果没有选择素材标签消息提示
        that.modifyTag = false
        $http.axios.post("/api/operationManagement/material/channgetag",that.channgetagParams).then(res=> {
          if(res.data.code == 200){
            that.getMaterialList()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      }else{
        that.$message({
          showClose: true,
          message: '请选择素材标签',
          type: 'error'
        })
      }
      
    },

    //打开删除权限弹框
    deletejurisdiction(){
      let that = this
      that.chooseTableFn('deleteTag')
    },

    //点击删除权限确定按钮
    deletejurisdictionSure(){
      let that = this
      that.deleteTag = false
      that.deltagParams.materialIds = that.materialIdsPara
      that.deltagParams.oldTagId = that.tagIdTable
      that.deltagParams.userId = that.userIdAccount
      that.deltagParams.userName = that.userNameAccount
      that.deltagParams.channel = that.getchannelIdnum
        
      $http.axios.post("/api/operationManagement/material/deltag",that.deltagParams).then(res=> {
        if(res.data.code == 200){
          that.getMaterialList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //点击新增弹出弹框
    addjurisdiction(){
      let that = this
      that.addTag = true
      that.getTagList()
    },
    
    //运营/客户审核通过
    batchIsPast(i){
      // this.chooseTableFn('')
      if(this.chooseTableFn('')){
        this.batchIsPastParams.materialIds = this.materialIdsPara
        this.batchIsPastParams.userId = this.userIdAccount
        this.batchIsPastParams.username = this.userNameAccount
        $http.axios.post("/api/operationManagement/material/"+this.batchIsPastUrl[i],this.batchIsPastParams).then(res=> {
          if(res.data.code == 200){
            this.getMaterialList()
          }  else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      }
    },
    
    //获取标签列表id集合
    mTagChange(event){
      this.addParams.tagIds = event
    },

    //获取调用人员id集合
    pTagChange(event){
      this.addParams.userIds = event
    },

    //新增权限
    addjurisdictionSure(){
      let that = this
      that.addTag = false
      that.addParams.materialIds = that.materialIdsPara
      that.addParams.userId = that.userIdAccount
      that.addParams.userName = that.userNameAccount
      $http.axios.post("/api/operationManagement/material/destag",that.addParams).then(res=> {
        if(res.data.code == 200){
          that.getMaterialList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //发送
    sendOut(e){
      let that = this
      that.sendoutVisible = true
      that.radio = ''
      that.accountName = ''
      that.addWaterMark=''
      that.isShowLink = false
      that.linkData = []
      that.checkedWaterUrl = ''
      that.linkParams.materialId = []
      that.linkParams.materialId.push(e)
    },
    //选择有水印之后弹出框
    getYes(){
      let that = this
      // if(event == 2){
      //   if(that.waterData.length == 0){
      //     that.innerVisible=false
      //     that.$message('请先到水印库添加水印');
      //   }else{
      //     that.innerVisible=true
      //     that.getWaterMarkList()
      //   } 
      // }
      // that.getWaterMarkList()
      // that.innerVisible=true
      // that.getWaterMarkList()
      if(that.waterData.length == 0){
        that.innerVisible=false
        that.$message('请先到水印库添加水印');
      }else{
        that.innerVisible=true
        
      }
    },
    getAccount(event){
      this.waterMarkParams.accountId = event.id
      this.accountName = event.name
      this.getWaterMarkList()
    },
    //获取水印库列表
    getWaterMarkList(){
      let that = this
      that.waterMarkParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
      that.waterMarkParams.searchUserId = that.$sessionStorage.getItem(config.USERID) || 1
      that.waterMarkParams.channel = that.materiaForm.myOldChannel

      $http.axios.post("/api/operationManagement/watermark/v1/getWaterMarkList",that.waterMarkParams).then(function(response) {
        if(response.data.code == 200){
          that.waterData = response.data.data.data
          // that.totalRow = response.data.data.total
          // that.totalPage = response.data.data.pageTotal
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //单选选中水印的id
    radioChecked(val){
      this.linkParams.waterMarkId = val.id
      this.bacthlinkParams.waterMarkId = val.id
      this.checkedWaterUrl = val.watermarkUrl
    },

    waterSure(){
      this.innerVisible = false
    },

    //生成链接
    newLink(){
      let that = this
      // that.linkParams.materialId = that.materialIdsPara
      that.linkParams.userId = that.userIdAccount
      that.linkParams.userName = that.userNameAccount
      $http.axios.post("/api/operationManagement/material/getwatervideo",that.linkParams).then(function(response) {
        if(response.data.code == 200){
          that.linkData = response.data.data
          that.isShowLink = true
          // that.sendoutVisible = false
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //设计师生成链接
    sjsSendOut(val){
      let that = this
      console.log(val)
      that.linkParams.materialId.push(val)
      that.linkParams.userId = that.userIdAccount
      that.linkParams.userName = that.userNameAccount
      that.linkParams.waterMarkId = 47
      
      $http.axios.post("/api/operationManagement/material/getwatervideo",that.linkParams).then(function(response) {
        if(response.data.code == 200){
          that.sjslinkData = response.data.data
          that.sjssendoutVisible = true
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },


    //批量上传水印
    batchSenout(){
      let that = this
      that.radio = ''
      that.accountName = ''
      that.addWaterMark=''
      that.isShowBacthLink = false
      that.bacthLinkData = []
      // that.bacthLinkData.materialId = []
      that.checkedWaterUrl = ''
      that.chooseTableFn('sendoutBatchVisible')
    },

    //批量上传水印--确定   同newLink方法相同
    newBatchLink(){
      let that = this
      that.bacthlinkParams.materialId = that.materialIdsPara
      that.bacthlinkParams.userId = that.userIdAccount
      that.bacthlinkParams.userName = that.userNameAccount
      $http.axios.post("/api/operationManagement/material/getwatervideo",that.bacthlinkParams).then(function(response) {
        if(response.data.code == 200){
          that.bacthLinkData = response.data.data
          that.isShowBacthLink = true
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

    //设计师批量发送
    sjsbatchSenout(){
      this.chooseTableFn()
      let that = this
      if(that.materialIdsPara.length != 0){
        that.bacthlinkParams.materialId = that.materialIdsPara
        that.bacthlinkParams.userId = that.userIdAccount
        that.bacthlinkParams.userName = that.userNameAccount
        that.bacthlinkParams.waterMarkId = 47
        $http.axios.post("/api/operationManagement/material/getwatervideo",that.bacthlinkParams).then(function(response) {
          if(response.data.code == 200){
            that.bacthLinkData = response.data.data
            that.plsjssendoutVisible = true
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      }
      
    },

    //修改审核状态--通过
    adopt(e){
      let that = this
      that.editParams.materialId = e
      that.editParams.userId = that.userIdAccount
      that.editParams.username = that.userNameAccount
      that.editParams.operationkey = 'pass'
      $http.axios.post("/api/operationManagement/material/changestatus",that.editParams).then(res=> {
        if(res.data.code == 200){
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    //拒绝
    rejection(e){
      let that = this
      that.dialogFormVisible = false
      that.editParams.materialId = e
      that.editParams.userId = that.userIdAccount
      that.editParams.username = that.userNameAccount
      that.editParams.operationkey = 'refuse'
      that.editParams.tips = that.rejectionReason
      $http.axios.post("/api/operationManagement/material/changestatus",that.editParams).then(res=> {
        if(res.data.code == 200){
          this.getMaterialList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //同步今日头条
    sendToutiao(){
      let that = this
      that.chooseTableFn('sendToutiaoVisible')
      that.getTToptions()//点击弹框获取项目名称的数据
    },

    //批量同步媒体--点击弹框获取项目名称接口
    getTToptions(){
      let that = this
      that.ptojectTTParams.materialIds = that.materialIdsPara
      $http.axios.post("/api/operationManagement/material/queryProject",that.ptojectTTParams).then(res=> {
        if(res.data.code == 200){
          that.projecttoutiaoOptions = res.data.data
          that.projectkuaishouOptions = res.data.data
         }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //同步媒体时如果选择了同步项目名称，则改变同步账户名称的下拉列表接口数据，如下
    projeactChangeSyn(event){
      this.syntoutiao = []
      this.changeSyn(event)
    },
    projeactChangeksSyn(event){
      this.synkuaishou = []
      this.changeSyn(event)
    },
    //新数据下拉列表
    changeSyn(val){
      let that = this
      let projectId = val
      $http.axios.get("/api/channel/account/queryAccountByProject?projectId="+projectId).then(res=> {
        if(res.data.code == 200){
          that.syntoutiaoOptions = res.data.data
          that.synkuaishouOptions = res.data.data
         }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //选中素材的materialIds
    chooseTableFn(visible){
      let that = this
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        that.materialIdsPara.push(e.id)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选素材',
          type: 'warning'
        });
        that[visible]=false
      }else{
        that[visible]=true
        return true
      }
    },
    
    //头条
    synChanneltt(val){
      let that = this
      that.sendttParams.accountDtoList = []
      val.forEach(function(e,i){
        that.sendttParams.accountDtoList.push({accountId:e.id,channel:e.channelId})
      })
    },

    // 快手
    synChannelks(val){
      let that = this
      that.sendksParams.accountDtoList = []
      val.forEach(function(e,i){
        that.sendksParams.accountDtoList.push({accountId:e.id,channel:e.channelId})
      })
    },
    //同步媒体
    sendToutiaoSuccess(){
      let that = this
      that.sendToutiaoVisible = false
      that.sendttParams.materialIds = that.materialIdsPara
      that.sendttParams.userId = that.userIdAccount
      that.sendttParams.userName = that.userNameAccount
      $http.axios.post("/api/operationManagement/material/devsendacc",that.sendttParams).then(res=> {
        if(res.data.code == 200){
          that.$message('系统已开始同步素材至'+that.sendttParams.userName+'帐号,同步结果稍后将以钉钉消息通知您')
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg||"工单异常",
            type: 'error'
          })
        }
      })
    },

    //同步快手
    sendKuaishou(){
      let that = this
      that.chooseTableFn('sendKuaishouVisible')
      that.getTToptions()
    },
    sendKuaishouSuccess(){
      let that = this
      that.sendKuaishouVisible = false
      that.sendksParams.materialIds  = that.materialIdsPara
      that.sendksParams.userId = that.userIdAccount
      that.sendksParams.userName = that.userNameAccount
      $http.axios.post("/api/operationManagement/material/devsendacc",that.sendksParams).then(res=> {
        if(res.data.code == 200){
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg||"工单异常",
            type: 'error'
          })
        }
      })
    },

    //标签按钮-新建标签
    newTag(){
      let that = this
      console.log(1111111)
      that.newTagVisible = true
    },

    //创建标签点击确定
    newtagSuccess(){
      let that = this
      that.addtagParams.createUser = that.userIdAccount
      that.addtagParams.tagName = that.newtagName
      that.addtagParams.tagType = 2
      that.addtagParams.channel = that.getchannelIdnum
      $http.axios.post("/api/operationManagement/material/addtag",that.addtagParams).then(res=> {
        if(res.data.code == 200){
          that.newTagVisible = false
          that.getMaterialList()
          that.newtagName = ''
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

     //取消新建标签
     canclenewTag(){
      this.newTagVisible = false
      this.newtagName = ''
    },
    
    //点击添加已有标签
    addHadTag(){
      let that = this
      that.getTagList()
      that.chooseTableFn('addHadTagVisible')
    },

    //获取已有标签要更换标签的id
    changeAddhadTag(event){
      this.addhadtagParams.newTagIds = event
    },

    //添加已有标签-确定
    addHadTagSuccess(){
      let that = this
      that.addHadTagVisible = false
      that.addhadtagParams.materialIds = that.materialIdsPara
      that.addhadtagParams.userId = that.userIdAccount
      that.addhadtagParams.userName = that.userNameAccount
      that.addhadtagParams.channel = that.getchannelIdnum
      $http.axios.post("/api/operationManagement/material/addmaterialtag",that.addhadtagParams).then(res=> {
        if(res.data.code == 200){
          this.getMaterialList()
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //批量下载
    batchDownload(){
      let that = this,materialIdsPara = [],materialIdsParaString = ''
      let sendttSelection = that.$refs.multipleTable.selection
      that.exprtVisible = true
      $http.axios.defaults.timeout = 500000
      sendttSelection.forEach(function(e,i){
        materialIdsPara.push(e.id)
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选素材',
          type: 'warning'
        })
      }else{
        materialIdsParaString = String(materialIdsPara)
        $http.axios.get("/api/operationManagement/material/downLoadMaterial?mids="+materialIdsPara,{responseType: 'blob',dataType:"json",async: false}).then(res=> {
          console.log(res.data)
          let blob = new Blob([res.data], {type: 'application/zip'})
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a') // 创建a标签
          link.href = url
          link.download = '批量下载素材' // 重命名文件
          link.click()
          URL.revokeObjectURL(url) // 释放内存
          that.exprtVisible = false
        })
      }
    },





    // 分页
    handleSizeChange(val) {
      this.pageCount = val
      var params = {
        page: this.page,
        pageCount: this.pageCount
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.page = val
      var params = {
        page: this.page,
        pageCount: this.pageCount
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getMaterialList()
    },
    //表格中选中和未选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    //确定更换素材
    sureChangeMaterial(){
      let that = this,params = {},materialIdsPara = ''
      let sendttSelection = that.$refs.multipleTable.selection
      that.materialIdsPara = []
      sendttSelection.forEach(function(e,i){
        materialIdsPara = e.id
      })
      if(sendttSelection.length == 0){
        that.$message({
          message: '请勾选素材',
          type: 'warning'
        });
      }else if(sendttSelection.length == 1){
        params = {
          "adId": that.advertId,
          "advertiseId": that.advertiseId,
          "creativeId": that.chuangyiId,
          "hlMaterialId": materialIdsPara,
          "userId": that.userIdAccount,
          "userName": that.userNameAccount,
          "deptId": that.deptId
        }
        $http.axios.post("/api/vivo/advertising/customize/edit",params).then(res=> {
          if(res.data.code==200){
      that.$router.push({path:'/vivoAccount-management/advertVivo',query:{type:that.tzGroupId,id:that.advertiseId,name:that.ownerName}})
          }else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      }else if(sendttSelection.length != 1){
        that.$message({
          message: '只能选一条素材进行更换',
          type: 'warning'
        });
      }else{
        return true
      }
    },

    //取消更换素材
    cancleChangeMaterial(){
      let that = this
      that.$router.push({path:'/vivoAccount-management/advertVivo',query:{type:that.tzGroupId,id:that.advertiseId,name:that.ownerName}})
    },




  }
}
</script>
<style scoped>
 .el-table .cell{
    transition: all 0.3s ease;
  }
  .el-table .cell .tip{
    position: absolute;
    top:6px;
    left:10px;
  }
  img{
    width: 20%;
  }
</style>

<style lang="scss" scoped>
  .materialHead{
    font-size:0;
  }
  .materialHchannel,.materialHBtns{
    display:inline-block;
    vertical-align:middle;
    font-size:14px;
  }
  .materialHchannel{
    width:30%;
  }
  .materialHBtns{
    width:70%;
  }
  .materiaContent{
    margin-top:25px;
  }
  .showPorV{
    text-align:center;
  }
  .generateLink li{
    font-size:0;
    margin:10px 0;
  }
  .generateLink li span{
    display:inline-block;
    vertical-align:middle;
    width:50%;
    box-sizing: border-box;
    padding:0 5px;
    font-size:14px;
  }
  .showTheForm,.hideenTheForm{
    display:inline-block;
    vertical-align:top;
  }
  .showTheForm{
    position:relative;
  }
  .showTheForm i{
    position:absolute;
    bottom:-10px;
    left:50%;
    margin-left:-11px;
    font-size:22px;
    color:rgb(36, 132, 168);
    cursor:pointer;
  }
  .hideenTheForm i{
    font-size:22px;
    vertical-align:middle;
    color:rgb(36, 132, 168);
    cursor:pointer;
    margin:0 20px;
  }
  .sendSet .el-select{
    display:block;
    margin:10px 0;
  }
  ::v-deep .mineBg{
     .el-dialog {
      background:none!important;
      box-shadow:none!important;
      .el-dialog__headerbtn .el-dialog__close{
        color:#fff;
        font-size:24px;
      }
    }
  }
  .waterStyle{
    width:50%;
    display:block;
    margin:10px auto
  }
  .materialTab{
    margin-bottom:20px;
  }
  .materialTab span{
    display:inline-block;
    vertical-align:middle;
    font-size:14px;
    width:80px;
    text-align:center;
    cursor: pointer;
  }
  .materialTab span.active{
    font-weight:700;
  }
  .uploading{
    display:block;
    text-align:center;
    font-size:50px;
  }
  .addUploadBtn{
    text-align:right;
    margin-bottom:15px;
    margin-top:-60px;
  }
  </style>
