<template>
  <div class="header-right-group">
    <div class="headerRight">
      <div class="headerMsg">
        <el-badge is-dot class="nav_message item el-icon-bell" style="font-weight:700;cursor:pointer;"></el-badge>
      </div>
      <div class="headerUser">
        <el-dropdown trigger="click" class="hrg-item headerUser_head" @command="onCommand">
          <div class="headSculpture">
            <img :src="titleImg">
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown-userinfo-popper">
            <!-- <el-dropdown-item command="info">{{nickName}}</el-dropdown-item> -->
            <el-dropdown-item command="logout" icon="poc-size12 iconfont icon-oneui-logout">{{$t('dc:退出')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span class="headerUser_name">{{nickName}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import titleImg from '@/assets/img/title.jpg';
import Api from '@/api';
import { mapGetters, mapActions } from 'vuex';
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'HeaderRightGroup',

  data() {
    return {
      titleImg,
      // nickName:''
      // logoutParams:{
      //   username:'admin', 
      //   password:'lokilhy'
      // }
    };
  },

  created(){
    this.$i18n.locale = 'zh-cn';
    this.$localStorage.setItem('lang', this.$i18n.locale);
  },
  moumted(){
    // this.nickName = this.$sessionStorage.getItem(config.NICKNAME) 
    console.log(this.nickName)
  },

  computed: {
    // ...mapGetters(['userInfo']),
    userName() {
      return this.$sessionStorage.getItem(config.USERNAME)
    },
    nickName() {
      return this.$sessionStorage.getItem(config.NICKNAME) 
    },
  },

  methods: {
    ...mapActions(['clearUserInfo']),
    /**
     * 到SSO的home页
     */
    goSSOHome() {
      Api.user.goSSOHome();
    },
    /**
     * 语言切换：zh-cn | en-us
     */
    // switchLang() {
    //   this.$i18n.locale = this.$i18n.locale === 'zh-cn' ? 'en-us' : 'zh-cn';
    //   this.$localStorage.setItem('lang', this.$i18n.locale);
    // },
    /**
     * dropdown项点击
     */
    onCommand(v) {
      if (v === 'logout') this.logout();
    },
    /**
     * 登出
     */
    async logout() {
      let that = this
      $http.axios.delete("/api/auth/logout").then(function(response) {
          if(response.data.code == 200){
            setTimeout(() => {
              location.reload() // 强制刷新
              that.$sessionStorage.clear()
              that.$cookies.remove("COOKIE_ROLES")
            }, 1000)
            // console.log(that.$sessionStorage.getItem(config.USERNAME))
            
            // that.$sessionStorage.removeItem(config.USERNAME)
            // that.$sessionStorage.removeItem(config.USERID) || 1
            // 'Bearer '+that.$sessionStorage.removeItem(config.USER_TOKEN_KEY);
            // console.log(that.$sessionStorage.getItem(config.USERNAME))

            that.$router.push('/')
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      // const { status } = await Api.user.logout();
      // if (status === this.$RESPONSE_STATUS.SUCCESS) {
      //   this.clearUserInfo();
      //   Api.user.goSSO();
      // }
      // $http.axios.get("/api/auth/logout").then(function(response) {
      //   console.log(response)
      //   this.$router.push('/')
      // })

    }
  },
}
</script>

<style lang="scss" scoped>
  .header-right-group {
    .hrg-item {
      margin-left: $MP8;
    }

    .switch-lang {
      width: 12px;
      align: center;
      display: inline-block;
    }

    .el-button {
      /* background: #435560; */
      color: #FFFFFF;
      border-color: transparent;
    }
  }
  ::v-deep .el-badge__content.is-dot{
    width:4px!important;
    height:4px!important;
    position: absolute;
    top: -3px;
    right:-3px;
    background: #FF686F;
  }
  .headerRight{
    font-size:0;
  }
  .headerMsg,.headerUser{
    display:inline-block;
    vertical-align:middle;
    font-size:12px;
  }
  .headSculpture{
    width:34px;
    height:34px;
    border-radius: 50%;
    overflow: hidden;
    line-height:34px;
    text-align:center
  }
  /* .headSculpture img{
    width:100%;
  } */
  .headerMsg{
    font-size:16px;
    margin-right:25px;
  }
  .headerUser{
    font-size:0
  }
  .headerUser_head,.headerUser_name{
    display: inline-block;
    vertical-align: middle;
  }
  .headerUser_name{
    font-size:12px;
    margin-left:10px;
  }

</style>
