/**
 * 为了避免Object/Array引用导致的state中的数据异常变化，在最终赋值时，切断对象引用
 * 原则：state中的数据必须采用commit的方式来进行修改
 */
const mutations = {
  // 获取最新用户信息
  updateUserInfo(state, data) {
    state.userInfo = data;
  },
};

export default mutations;
