<template>
  <el-dialog
    :visible.sync="show"
    width="640px"
    @close="onCancel"
    class="icon_custom_popup"
    :title="title + '模板'"
    :close-on-click-modal="false"
  >
    <img class="icon" src="@/assets/img/suCaiIcon.png" />
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      :label-width="type == 2 ? '130px' : '106px'"
    >
      <el-form-item label="模板名称" prop="name">
        <el-input
          v-model="ruleForm.name"
          :placeholder="
            fileList.reportName ? fileList.reportName : '请输入模板名称'
          "
        ></el-input>
      </el-form-item>
      <el-form-item label="报表名称" prop="excelName">
        <el-input
          v-model="ruleForm.excelName"
          clearable
          :placeholder="fileList.title ? fileList.title : '请输入报表名称'"
          oninput="value=value.replace(^[^\\\/\.]+$,'')"
          @clear="handleClear"
        ></el-input>
        <div class="button_label">
          <div
            v-for="(item, i) in cibaoList"
            :key="i"
            @click="addWordsFn(item)"
          >
            {{ item }}
          </div>
        </div>
      </el-form-item>
      <el-form-item label="报表维度" prop="adType">
        <el-select
          disabled
          v-model="ruleForm.adType"
          placeholder="请选择报表维度"
        >
          <el-option label="账户" value="40"></el-option>
          <el-option label="计划" value="10"></el-option>
          <el-option label="广告组" value="20"></el-option>
          <el-option label="广告" value="30"></el-option>
          <el-option label="创意" value="50"></el-option>
        </el-select>
      </el-form-item>
      <div v-if="dataSourceIs">
        <div class="titles">数据源sheet</div>
        <el-form-item
          v-for="(item, i) in dataSource"
          :key="i"
          :label="item.name"
        >
          <el-select
            disabled
            v-model="ruleForm.sourceName"
            value-key="name"
            placeholder=""
            class="select-disabled"
            multiple
            @change="changeFn($event, i, 'data')"
          >
            <el-option
              :label="item1.name"
              :value="item1"
              v-for="(item1, j) in tableDataList"
              :key="j"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div v-if="keywordsIs">
        <div class="titles">关键字sheet</div>
        <el-form-item v-for="(item, i) in keywords" :key="i" :label="item.name">
          <el-select
            disabled
            v-model="ruleForm.KeyName"
            value-key="name"
            multiple
            placeholder=""
            class="select-disabled"
            @change="changeFn($event, i, 'keyword')"
          >
            <el-option
              :label="item1.name"
              :value="item1"
              v-for="(item1, j) in tableDataList"
              :key="j"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="接收人" prop="sendUser">
        <el-select
          v-model="ruleForm.sendUser"
          value-key="userId"
          multiple
          filterable
          :placeholder="fileList.sender ? fileList.senderName : '请选择接收人'"
          @change="changeFn($event)"
        >
          <el-option
            v-for="item in distributionOptions"
            :key="item.userId"
            :label="item.string"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抄送人" prop="copyUser">
        <div class="inline">
          <el-select
            v-model="ruleForm.copyUser"
            value-key="id"
            multiple
            filterable
            :placeholder="
              fileList.copyUserName ? fileList.copyUserName : '请选择抄送人'
            "
            @change="changeFn($event)"
          >
            <el-option
              v-for="item in cc_options"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
          <i
            class="el-icon-circle-plus-outline"
            @click="dialogFormVisible_add = true"
          ></i>
        </div>
        <el-dialog
          class="icon_custom_popup"
          width="400px"
          title="添加抄送人"
          :visible.sync="dialogFormVisible_add"
          append-to-body
          :close-on-click-modal="false"
        >
          <div class="icon">
            <img src="@/assets/img/dialog_specify.png" />
          </div>
          <el-form :model="addForm" label-width="64px">
            <el-form-item label="人员名称">
              <el-input
                v-model="addForm.name"
                autocomplete="off"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="邮箱地址">
              <el-input
                v-model="addForm.email"
                autocomplete="off"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="dialogFormVisible_add = false">取 消</el-button>
            <el-button type="primary" @click="csrSure">确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item label="接收方式" prop="type">
        <el-checkbox-group class="middle" v-model="ruleForm.type">
          <el-checkbox label="Excel" name="type"></el-checkbox>
          <el-checkbox label="图片" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="type == 2" label="是否拉取零点数据" prop="resource">
        <el-switch
          class="middle"
          disabled
          v-model="ruleForm.resource"
          :active-value="2"
          :inactive-value="1"
        >
        </el-switch>
      </el-form-item>
      <el-form-item v-if="type == 2">
        <div slot="label"><span class="starStyle">*</span>接收时间</div>
        <el-input v-model="fileList.time" placeholder="" disabled></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button :loading="loading" @click="onCancel">{{
        $t("dc:取消")
      }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm('ruleForm')"
        >{{ $t("dc:保存") }}</el-button
      >
    </div>
    <addCopy :visible.sync="addCopyIs"></addCopy>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from "@/api/config";
import { $http } from "@/api/http";
import addCopy from "./addCopyDialog";
import getUserList from "@/utils/getUserList"; //获取用户列表

export default {
  components: {
    addCopy,
  },
  props: {
    title: String,
    visible: {
      type: Boolean,
      default: false,
    },
    type: Number,
    path: String,
    pubVue: Object,
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      dataSourceIs: false,
      keywordsIs: false,
      addCopyIs: false,
      dataSource: [],
      keywords: [],
      needList: ["否", "是"],
      ruleForm: {
        name: "",
        excelName: "",
        adType: "",
        type: [],
        resource: "",
        sourceName: [],
        KeyName: [],
        sendUser: [],
        copyUser: [],
      },
      addForm: {
        name: "",
        email: "",
      },
      dialogFormVisible_add: false,
      fileList: {},
      tableDataList: [],
      cc_options: [],
      value1: [],
      reportId: "",
      distributionOptions: [],
      rules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        excelName: [
          { required: true, message: "请输入报表名称", trigger: "blur" },
        ],
        adType: [
          { required: true, message: "请选择报表维度", trigger: "change" },
        ],
        type: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个接收方式",
            trigger: "change",
          },
        ],
        resource: [
          { required: true, message: "请选择是或者否", trigger: "change" },
        ],
        sendUser: [
          { required: true, message: "请选择接收人", trigger: "change" },
        ],
        sendTime: [
          { required: true, message: "请选择接收时间", trigger: "change" },
        ],
      },
      cibaoList: ["代理公司", "渠道", "报表类型", "日期", "时段"],
      wordsA: [],
    };
  },

  watch: {
    show(v) {
      this.$emit("update:visible", v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
    this.deptId = this.$sessionStorage.getItem(config.DEPTID);
    this.cc_options = this.$sessionStorage.getItem("optionSelectCopyUser");
    getUserList().then((res) => {
      this.cc_options = res;
    });
    console.log("VIVO");
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onConfirm(formName) {
      let _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("成功2");
          console.log("选中");
          console.log(_this.ruleForm);
          _this.modifyModelFn(_this.dayLimit);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //添加抄送人
    csrSure() {
      let that = this;
      that.dialogFormVisible_add = false;

      let userName = that.addForm.name;
      let email = that.addForm.email;
      $http.axios
        .get(
          "/api/system/user/bdAccountCopyUser?userName=" +
            userName +
            "&email=" +
            email
        )
        .then(function (response) {
          if (response.data.code == 200) {
            getUserList().then((res) => {
              that.cc_options = res;
            });
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },
    onCancel() {
      this.show = false;
      this.$refs.ruleForm.resetFields();
      this.pubVue.listModelFn();

      console.log("关闭");
    },
    addOrSetFn(type) {},
    handleClear() {
      this.ruleForm.excelName = "";
      this.wordsA = [];
    },

    //user的channel渠道接口
    getuserChannel(id) {
      let _this = this,
        senderName = [],
        time = [],
        sendUser = [],
        copyUserName = [],
        copyUser = [],
        type1 = "",
        type2 = "";
      _this.reportId = id;
      $http.axios
        .get("/api/vivoReport/reportModel/toModify?id=" + id)
        .then(function (res) {
          if (res.data.code == 200) _this.fileList = res.data.data;
          _this.getFpList();
          if (_this.type == 2 && _this.fileList.time != null) {
            _this.fileList.time.forEach((obj) => {
              if (obj % 1 === 0) {
                // time.push(obj)
                time.push(obj + ":00");
              } else {
                time.push(Math.floor(obj) + ":30");
              }
            });
            _this.fileList.time = time.toString();
          }

          _this.ruleForm.adType = _this.fileList.adType.toString();
          _this.dataSource = _this.fileList.excelConfig.dataSource;
          _this.keywords = _this.fileList.excelConfig.keyword;
          type1 = _this.fileList.excelSwitch == 2 ? "Excel" : "";
          type2 = _this.fileList.imgSwtich == 2 ? "图片" : "";
          if (type1 != "") {
            _this.ruleForm.type.push(type1);
          }
          if (type2 != "") {
            _this.ruleForm.type.push(type2);
          }
          (_this.ruleForm.name = _this.fileList.reportName),
            (_this.ruleForm.excelName = _this.fileList.title),
            (_this.ruleForm.adType = _this.fileList.adType.toString()),
            (_this.ruleForm.resource =
              _this.needList[_this.fileList.isNeedYesterday - 1]),
            (sendUser = _this.fileList.sender
              ? _this.fileList.sender.split(",")
              : []);
          senderName = _this.fileList.senderName
            ? _this.fileList.senderName.split(",")
            : [];
          copyUser = _this.fileList.copyUser
            ? _this.fileList.copyUser.split(",")
            : [];
          copyUserName = _this.fileList.copyUserName
            ? _this.fileList.copyUserName.split(",")
            : [];
          sendUser.forEach((obj, i) => {
            _this.ruleForm.sendUser.push({
              string: senderName[i],
              userId: obj,
            });
            // _this.$set(_this.ruleForm.sendUser,i, {'userId': obj})
            // _this.$set(_this.ruleForm.sendUser,i, {'string': senderName[i]})        })
          });
          copyUser.forEach((obj, i) => {
            _this.ruleForm.copyUser.push({ id: obj, name: copyUserName[i] });
          });

          console.log(_this.ruleForm.copyUser);
          if (_this.dataSource.length > 0) {
            _this.dataSourceIs = true;
          }
          if (_this.keywords.length > 0) {
            _this.keywordsIs = true;
          }
        });
      console.log(_this.ruleForm);
    },

    changeFn(event, i, type) {
      let _this = this,
        ownersList = [];
      // "owners": [
      //           {
      //             "ownerId": 0,
      //             "ownerName": ""
      //           }
      //         ]
      console.log(event, i);
      console.log(this.dataSource);
      console.log(this.keywords);

      if (type == "data" && event.length != 0) {
        event.forEach((obj) => {
          ownersList.push({ ownerId: obj.id, ownerName: obj.name });
        });
        this.dataSource[i].owners = ownersList;
      } else if (type == "keyword" && event.length != 0) {
        event.forEach((obj) => {
          ownersList.push({ ownerId: obj.id, ownerName: obj.name });
        });
        this.keywords[i].owners = ownersList;
      }

      console.log(this.dataSource);
      console.log(this.keywords);
    },
    //获取分配人员列表
    getFpList() {
      let that = this;
      // $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
      $http.axios
        .get("/api/system/user/getAppendUserListByChannel?channelId=4")
        .then(function (response) {
          if (response.data.code == 200) {
            that.distributionOptions = response.data.data;
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: "error",
            });
          }
        });
    },

    // //获取菜单列表
    getList() {
      console.log(1);
      let that = this;
      $http.axios
        .get("/api/viat/accountUser/selectList?userId=" + that.userId)
        .then(function (response) {
          console.log(response);
          if (response.status == 200) {
            that.tableDataList = response.data;
          }
        });
    },

    modifyModelFn() {
      let _this = this,
        params = {},
        excelSwitch = 1,
        imgSwtich = 1,
        sendUser = { string: [], id: [] },
        copyUser = { string: [], id: [] };
      if (_this.ruleForm.type.length > 0) {
        if (_this.ruleForm.type[0] == "图片") {
          imgSwtich = 2;
        } else if (_this.ruleForm.type[0] == "Excel") {
          excelSwitch = 2;
        }
        if (_this.ruleForm.type.length == 2) {
          excelSwitch = 2;
          imgSwtich = 2;
        }
      }
      _this.ruleForm.sendUser.forEach((obj) => {
        sendUser.string.push(obj.string);
        sendUser.id.push(obj.userId);
      });
      _this.ruleForm.copyUser.forEach((obj) => {
        copyUser.string.push(obj.name);
        copyUser.id.push(obj.id);
      });
      params = {
        copyUser: copyUser.id.toString(),
        copyUserName: copyUser.string.toString(),
        excelSwitch: excelSwitch,
        imgSwtich: imgSwtich,
        reportName: _this.ruleForm.name, //模板名称
        reportId: _this.reportId,
        sender: sendUser.id.toString(),
        senderName: sendUser.string.toString(),
        title: _this.ruleForm.excelName, //报表名称，词包
      };

      $http.axios
        .post("/api/vivoReport/reportModel/modifyModel", params)
        .then((res) => {
          if (res.data.code == 200) {
            // _this.tableData = res.data.data.items
            // _this.totalRow = res.data.data.totalCount
            _this.onCancel();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    addWordsFn(val) {
      let _this = this,
        wordsA = [];
      if (_this.wordsA.length != 0) {
        _this.wordsA.forEach((obj, i, arr) => {
          console.log(obj);
          if (val == obj) {
            arr.splice(i, 1);
          }
        });
        _this.wordsA.push(val);
        wordsA = _this.wordsA;
        _this.ruleForm.excelName += "{" + val + "}";
      } else {
        console.log(val);
        _this.ruleForm.excelName += "{" + val + "}";
        _this.wordsA.push(val);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.limitInput input {
  width: 80px;
  padding: 8px 10px !important;
  text-align: center;
  margin-left: 10px;
}
.dateConent {
  margin: 15px;
}
.addWord {
  cursor: pointer;
  display: inline-block;
  margin: 5px 8px;
  font-size: 12px;
  color: #ccc;
}
ul,
li {
  list-style: none;
}
.sendTIme {
  font-size: 12px;
}
.sendTimeTitle {
  display: inline-block;
  width: 120px;
  text-align: right;
}
.redContent {
  color: #f56c6c;
  margin-right: 4px;
}
</style>

