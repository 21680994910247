<template>
    <div class="authority common-page">
       <PocCardTable>
         <div>
           <span class="clasaify">素材分类排行榜</span>
         </div>
          <div>
            <iframe 
              id="iframe" 
              ref="iframeref" 
              name="mainFrame" 
              src="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=ee4d285c-c3bd-4f8f-8bb5-bd51114c2c5c&accessToken=0f0799051efeb933b74520399ab614b5&dd_orientation=auto" 
              class="biConsumptionSum">
            </iframe>
          </div>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'MaterialClasaify',
    data() {
      return {
        screenHeight:window.innerHeight-300,

      }
    },
    mounted() {

    },
    methods: {

    }
  }
  </script>
  
  <style lang="scss" scoped>
    .biConsumptionSum{
      width:100%;
      border:none;
      height:1000px;
      /* height:100%; */
      /* position: absolute;
      top:132px;
      left:50%;
      transform: translateX(-50%);
      z-index:10;
      background: none; */
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      margin:0!important;
    }
    ::v-deep .el-tabs__item {
      line-height:24px;
      height:24px;
    }
    ::v-deep .el-tabs--card > .el-tabs__header{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item:first-child{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
      border:none;
    }
    ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      background: #66856d;
      color:#fff;
    }
    .clasaify{
      font-size:16px;
      font-weight:700;
    }

  </style>
