<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title="创意复制"
  >
    <el-form
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData">
      <el-form-item class="copyPage" label="复制到广告组" prop="dayLimitV" >
        <!-- <el-select  clearable placeholder="选择广告组" v-model="formData.dayLimitV">
          <el-option
            v-for="(item,i) in tableDataList"
            :key="i"
            :label="item.adGroupName"
            :value="item.adGroupId">
          </el-option>
        </el-select> -->
        <el-select
          v-model="formData.dayLimitV"
          filterable
          remote
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="loading">
          <el-option
            v-for="(item,i) in options"
            :key="i"
            :label="item.adGroupName"
            :value="item.adGroupId">
          </el-option>
        </el-select>
      </el-form-item>   
    </el-form>
    <ul>
      <!-- <li><span>推广目的</span><span>{{copyCreatList[0].extensionTypeName}}</span></li> -->
      <!-- <li><span>推广流量</span><span>{{copyCreatList[0].extensionFlowName}}</span></li> -->
      <!-- <li><span>流量场景</span><span>{{copyCreatList[0].flowSceneName}}</span></li> -->
    </ul>

    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('formData')">确定</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';
export default {
  
  name: "copyDialog",

  props: {
    pubVue: {
      type: Object
    },
    tableDataList:Array,
    copyCreatList:Array,
    visible: {
      type: Boolean,
      default: false
    },
    limitIs:Boolean,
    // type: Number,
    // ownerId:Number,
    params:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    title:String,
    max: Number
  },
  data() {
    return {
      formData: {
        dayLimitV:'',
      },
      show: this.visible,
      // show:true,
      loading: false,
      states:this.tableDataList,
      options:[],
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    
  },
  methods: {
    onConfirm(formData) {
      let _this = this
      
      _this.$refs[formData].validate((valid) => {
        // if (valid&&Number(_this.formData.scan_limit)>200) {
        //     console.log('成功2')
        //     console.log('选中')
        //     _this.updateOppoDayBudgetThreeFn(_this.dayLimit)
  
        // } else {
        //    _this.$message({
        //     showClose: true,
        //     message: '请查看网络，稍后再试',
        //     type: 'error'
        //   })
        //   return false;
        // }
        _this.copyAdFn()
        this.show= false;
      });
    },
    onCancel() {
      this.show= false;
      this.formData.dayLimitV = ''
      this.pubVue.v3groupListFn()
      this.pubVue.$refs.multipleTable.clearSelection();
      console.log('关闭')
    },
    remoteMethod(query) {
      let _this = this
      if (query !== '') {
        _this.loading = true;
        setTimeout(() => {
          _this.loading = false;
          console.log(222)
          // _this.pubVue.newVue.$refs.adTeam[0].$children[0].grouplistFn(1,100)
          _this.options = _this.tableDataList.filter(item => {
            return item.adGroupName.toLowerCase()
              .indexOf(query.toLowerCase()) > -1;
            });
          }, 200);
        } else {
          this.options = [];
        }
    },
    copyAdFn(){
      let _this = this,sourceAds = [],params = []
      _this.copyCreatList.forEach((obj,i)=>{
        sourceAds.push({'adId':obj.adId,'adName':obj.adName})
      })
      // params = {'ownerId':_this.params.ownerId,'sourceAds':sourceAds,'targetGroupId':_this.formData.dayLimitV}
      params = {'ownerId':1000092281,'sourceAds':sourceAds,'targetGroupId':_this.formData.dayLimitV}

      // {
      //   "ownerId": 0,
      //   "sourceAds": [
      //     {
      //       "adId": 0,
      //       "adName": ""
      //     }
      //   ],
      //   "targetGroupId": 0
      // }
      $http.axios.post("/api/advertisement/ad/v1/copyAd",params).then(res=> {
            // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
          _this.onCancel()
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
      })
    },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
.el-form .el-form-item--mini .el-form-item__label{
  font-size: 14px;
}
.el-dialog__body ul li span:nth-of-type(2){
  margin-left: 30px;
}
.el-dialog__body ul{
  padding-left: 0;
}
</style>

