<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>权限菜单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
        <div class="departmentHeader">
          <span class="role_headerTitles">权限菜单</span>
          <div class="jurisdiction_btn">
            <!-- <el-button type="primary" @click="dialogFormVisible = true">添加菜单</el-button> -->
            
          </div>
        </div>
        <div class="jurisdiction_list">
          <template>
            <div class="white-body-view">
              <el-button type="primary" @click="addNewRecord()">添加菜单</el-button>
              <custom-tree ref="customTree" :tree-data="treeData" :tree-expand-all="treeExpandAll" :tree-node-key="treeNodeKey" @addItem="addTreeItem" @deleteItem="deleteTreeItem" @editItem="editTreeItem" />
              <!-- 地点弹窗 -->
              <place-dialog ref="placeDialog" @addData="addData" @editData="editData" @getMenuList="getMenuList"/>
            </div>
          </template>


        </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <!-- <accessDialog :visible.sync="showDialog" ></accessDialog> -->
  </div>
</template>

<script>
  import CustomTree from '@/components/Tree'
  import PlaceDialog from '@/components/PlaceDialog'
  import { $http } from '@/api/http';
  import Api from "@/api";
  import config from '@/api/config'

  export default {
    components: {
      CustomTree,
      PlaceDialog
    },
   
    data() {
      return {
        treeData: [],
        treeExpandAll: true,
        treeNodeKey: 'id',
        deleteParams:{
          menuId:''
        },
        // treeParams:{
        //   type:1
        // }
      }
    },
   
    created() {
    //     this.initTreeData()
    this.getMenuList()
    },
   
    methods: {
//     initTreeData() {
//       this.treeData = []
//       this.getMenuList()
//     },
      //获取菜单列表
      getMenuList() {
        console.log(1)
        let that = this
        $http.axios.get("/api/system/menu/treeselect").then(function(response) {
        console.log(response)
        if(response.data.code==200){
          let treeMenuDatas = response.data.data;
          that.treeData = JSON.parse(JSON.stringify(treeMenuDatas))
          console.log(that.treeData)
          }
        })
      },
   
      // 添加新记录
      addNewRecord() {
        this.$refs.placeDialog.openDialog(false)   
      },
   
      // 新增表单数据
      addData(data) {
        // 新增树节点
        this.$refs.customTree.treeAddItem(data)
      },
   
      // 修改表单数据
      editData(data) {
        // 修改树节点
        this.$refs.customTree.treeEditItem(data)
      },
   
      // 增加树节点
      addTreeItem(data) {
        // 打开地点弹窗，设置默认父级节点
        this.$refs.placeDialog.openDialog(false, data.id)
      },
   
      // 删除树节点
      deleteTreeItem(data) {
        this.$confirm('确定删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 删除树节点
          this.$refs.customTree.treeDeleteItem(data)
          let that = this
          that.deleteParams.menuId = data.id
          $http.axios.delete("/api/system/menu/"+that.deleteParams.menuId).then(function(response) {
            console.log(1)
          })
          // 提示
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
   
      // 修改树节点
      editTreeItem(data) {
        // 打开地点编辑弹窗
        this.$refs.placeDialog.openDialog(true, data.parentId, data)
      }
   
    }
  }
  </script>

