<template>
<div>
  <strong class="partTitle fW">广告创意</strong>
    <el-form :model="creativeForm" :rules="rules" ref="creativeForm"  label-width="110px" size="mini">
      <div class="adCreativePart">
        <span class="title fW">创意设置</span>
        <el-form-item label="创意设置">
          <el-button type="warning" size="small" v-model="creativeForm.creativeSet">自定义创意</el-button>
        </el-form-item>
        <el-form-item label="创意规格">
          <div class="tabZujian">
          <el-button class="tabBtn" :class="[active == i?'active':'']" v-model="activeName" v-for="(item,i) in mSpecNameList" :key="i" @click="handleClick(item.mSpecName,i,item.globalSpecId,globalSpecId)">
            <span class="title">{{item.mSpecName}}</span>
            <img v-if="active == i" :src="require('../../../../assets/img/'+sizeList[item.mSpecName][1])" alt="">
            <img v-else :src="require('../../../../assets/img/'+sizeList[item.mSpecName][0])" alt="">
          </el-button>
        </div>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="success" size="small" v-model="creativeForm.norms4" @click="addCreartFn">添加创意</el-button>
        </el-form-item> -->
        
        <!-- <div class = 'scBg' v-if = "num>0"> -->
          <div class = 'scBg'>
          <div>
              <el-button class="creatBtn fL " size="mini" :class="i == numIndex? 'creatActive': ''" v-for="(item,i) in addCreat" :key="i" @click.prevent="changeFn(i,globalSpecId)">
                {{item.name}}
                <i class="el-icon-remove-outline" @click.stop="deletClumnChecked(i,item.adId)"></i>
              </el-button>
            <el-button class="creatBtn" type="success" size="mini"  @click="addCreartFn">+</el-button>
          </div>
                   
          <el-button v-if="addCreatBtnIs[globalSpecId]" @click="listFn">素材库选择</el-button>
          <imgHBig v-if="videoHIs&& globalSpecId == 1" :specId = "globalSpecId" :childrenVue = "childrenVue" :key='sc.id'></imgHBig>
          <imgHSmall v-if="videoHIs && globalSpecId == 20" :specId = "globalSpecId" :childrenVue = "childrenVue"></imgHSmall>
          <imgHTeam v-if="videoHIs && globalSpecId == 30 " :specId = "globalSpecId" :childrenVue = "childrenVue"></imgHTeam>
          <imgHTwo v-if="videoHIs && globalSpecId == 35 " :specId = "globalSpecId" :childrenVue = "childrenVue"></imgHTwo>
          <imgIco v-if="videoHIs && globalSpecId == 71 " :specId = "globalSpecId" :childrenVue = "childrenVue"></imgIco>
          <imgKpH v-if="videoHIs && globalSpecId == 5" :specId = "globalSpecId" :childrenVue = "childrenVue"></imgKpH>
          <imgKpS v-if="videoHIs && globalSpecId == 34" :specId = "globalSpecId" :childrenVue = "childrenVue"></imgKpS>
          <imgSBig v-if="videoHIs && globalSpecId == 50" :specId = "globalSpecId" :childrenVue = "childrenVue"></imgSBig>
          <notice v-if="videoHIs && globalSpecId == 4" :specId = "globalSpecId" :childrenVue = "childrenVue"></notice>
          <videoH v-if="videoHIs && globalSpecId == 60" :specId = "globalSpecId" :childrenVue = "childrenVue"></videoH>
          <videoS v-if="videoHIs && globalSpecId == 63" :specId = "globalSpecId" :childrenVue = "childrenVue"></videoS>


          <el-form-item label-width="100px" v-if = "videoHIs">
            <el-switch
              v-if = "globalSpecList == 30"
              style="display: block"
              v-model="value2"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="开启"
              inactive-text="不开启">
            </el-switch>
            <el-form-item label="文案" prop="">
              <el-autocomplete
                popper-class="my-autocomplete"
                v-model="creativeForm.region"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
                @change="handleIconClick('copywriter',creativeForm.region)">
                <i
                  class="el-icon-edit el-input__icon"
                  slot="suffix"
                  >
                </i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.value }}</div>
                  <span class="addr">{{ item.address }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item label="品牌名称" prop="">
              <el-input v-model="creativeForm.brandName" placeholder="请输入 1 - 8 字的品牌名称" @change="handleIconClick('brandName',creativeForm.brandName)"></el-input>
            </el-form-item>
          </el-form-item>        
        </div>
        
      </div>
      <div class="otherLinkPart">
        <span class="title fW">第三方监控链接</span>
        <el-form-item label="曝光监测链接">
          <el-input v-model="creativeForm.LinkPart" clearable @blur="IsURLFn(creativeForm.LinkPart,'urlLinkPartIs')" @change="handleIconClick('exposeUrl',creativeForm.LinkPart)"></el-input>          
          <span class="wrong" v-if="urlLinkPartIs">*请输入正确的网址</span>
          <span v-else></span>

          <span class="batchSetBtn btnC" @click="showSetDialogFn('0')" >批量设置</span>
        </el-form-item>
        <el-form-item label="点击监测链接">
          <el-input v-model="creativeForm.clickLinkPart" clearable @blur="IsURLFn(creativeForm.clickLinkPart,'urlclickLinkPartIs')" @change="handleIconClick('clickUrl',creativeForm.clickLinkPart)"></el-input>
          <span class="wrong" v-if="urlclickLinkPartIs">*请输入正确的网址</span>
          <span v-else></span>
          <span class="batchSetBtn btnC" @click="showSetDialogFn('1')">批量设置</span>
           
        </el-form-item>
        <el-form-item label="播放开始监测链接" v-if="globalSpecId == 60 || globalSpecId == 63">
          <el-input v-model="creativeForm.playBeginUrl" clearable @blur="IsURLFn(creativeForm.playBeginUrl,'urlplayBeginUrlIs')" @change="handleIconClick('playBeginUrl',creativeForm.playBeginUrl)"></el-input>          
          <span class="wrong" v-if="urlplayBeginUrlIs">*请输入正确的网址</span>
          <span v-else></span>

          <span class="batchSetBtn btnC" @click="showSetDialogFn('3')" >批量设置</span>
        </el-form-item>
        <el-form-item label="播放结束监测链接" v-if="globalSpecId == 60 || globalSpecId == 63">
          <el-input v-model="creativeForm.playEndUrl" clearable @blur="IsURLFn(creativeForm.playEndUrl,'urlplayEndUrlIs')" @change="handleIconClick('playEndUrl',creativeForm.playEndUrl)"></el-input>
          <span class="wrong" v-if="urlplayEndUrlIs">*请输入正确的网址</span>
          <span v-else></span>
          <span class="batchSetBtn btnC" @click="showSetDialogFn('4')">批量设置</span>
           
        </el-form-item>
        
      </div>
      <div class="datePart">
        <span class="title fW">创意名称</span>
        <el-form-item label="创意名称" prop="creativeName">
           <el-input v-model="creativeForm.creativeName" @change="handleIconClick('adName',creativeForm.creativeName)"></el-input>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit(creativeForm)">完成</el-button>
        <el-button type="info" size="small" plain @click="goPlanPage">取消</el-button>
      </el-form-item>
    </el-form>
    <setDialog :visible.sync="showDialog" :LinkPart="LinkPart" :childrenVue = "childrenVue"></setDialog>
    <imgDialog ref="imgDialog" :visible.sync = "imgDialogShow" :childrenVue = "childrenVue"></imgDialog>

    <!-- <el-button
      type="primary"
      @click="openFullScreen2">
      服务方式
    </el-button> -->
  </div>

  
</template>
<script>
// import Directional from './directional.vue';
// import keyWord from "./keyWord";//关键词

import setDialog from "@/views/dialog/setDialog";//批量设置弹框
import { defineComponent, provide } from "vue";
import config from '@/api/config';
import { $http } from '@/api/http';
import imgHBig from "./adcreativityPart/imgHBig.vue";
import imgHSmall from "./adcreativityPart/imgHSmall.vue";
import imgHTeam from "./adcreativityPart/imgHTeam.vue";
import imgHTwo from "./adcreativityPart/imgHTwo.vue";
import imgIco from "./adcreativityPart/imgIco.vue";
import imgKpH from "./adcreativityPart/imgKpH.vue";
import imgKpS from "./adcreativityPart/imgKpS.vue";
import imgSBig from "./adcreativityPart/imgSBig.vue";
import notice from "./adcreativityPart/notice.vue";
import videoH from "./adcreativityPart/videoH.vue";
import videoS from "./adcreativityPart/videoS.vue";
import imgDialog from "@/views/dialog/imgDialog";//广告创意

export default {
   
  components:{
    setDialog,
    imgDialog,
    imgHBig,
    imgHSmall,
    imgHTeam,
    imgHTwo,
    imgIco,
    imgKpH,
    imgKpS,
    imgSBig,
    notice,
    videoH,
    videoS
  },
  props: {
    newPlanVue:Object,
  },
  data() {
    return {
      childrenVue:this,
      showDialog: false,
      // creatInfo:{'extensionFlow':null,//2,
      // 'extensionType':null,//2,//1网页推广；2应用推广
      // 'flowScene':null,//6,
      // 'adGroupId':null,//301272488,//;301156964
      // 'adPlanId':null,//200558199},
      activeName:'',
      showSetDialog: [{name:"曝光监测链接",id:"LinkPart"},
                      {name:"点击监测链接",id:"clickLinkPart"},
                      {name:"播放开始监测链接",id:"playBeginUrl"},
                      {name:"播放结束监测链接",id:"playEndUrl"}],
      LinkPart:{},
      creativeForm:{
        creativeSet:'',//自定义创意
        brandName: '',//品牌名称
        region: '',//文案
        LinkPart:'',//曝光监测链接 exposeUrl //下载监测链接->downloadUrl
        clickLinkPart:'',//点击监测链接
        playBeginUrl:'',//播放开始监测链接  60 playBeginUrl
        playEndUrl:'',//播放结束监测链接  60
        creativeName:'',//创意名称
      },
      LinkPartList:[],
      clickLinkPartList:[],
      playEndUrlList:[],
      playBeginUrlList:[],
      playEndUrl:[],
      rules: {
        creativeName: [
          { required: true, message: '请输入创意名称', trigger: 'blur' }
        ],
        brandName: [
            { required: true, message: '品牌名称不能为空', trigger: 'blur' },
            { min: 1, max: 18, message: '长度在 1 到 8 个字符', trigger: 'blur' }
          ],
          region: [
            { required: true, message: '文案不能为空', trigger: 'change' }
          ]        
          },//校验规则提示语
      
      flowName:['默认值','全流量场景','精选推广','安装有礼','开机必备','搜索','信息流','Banner','开屏','插屏','原生','激励视频','联盟通投'],
      extensionName:['默认值','网页推广','应用推广'],
      addCreat:[],
      addCreatList:[],
      num:0,
      numIndex:0,
      mSpecNameList:[],
      globalSpecList:[],
      totalNum:100,//所有规格支持的可创建创意数量上限
      lonNum:10,//每个规格支持的可创建创意数量上限
      sizeList:{'横版大图':['landscape-big-pic-0.png','landscape-big-pic-2.png',1],
      '横版小图':['landscape-small-pic-0.png','landscape-small-pic-2.png',20],
      '横版组图':['landscape-group-pic-0.png','landscape-group-pic-2.png',30],
      '横版两图':['landscape-two-pic-0.png','landscape-two-pic-2.png',35],
      '横版视频':['landscape-video-0.png','landscape-video-2.png',60],
      '竖版大图':['portrait-big-pic-0.png','portrait-big-pic-2.png',50],
      '竖版视频':['portrait-video-0.png','portrait-video-2.png',63],
      '图标大图':['icon-0.png','icon-2.png',71],
      '通知栏':['banner-0.png','banner-2.png',4],
      '开屏横图':['landscape-big-pic-0.png','landscape-big-pic-2.png',5],
      '开屏竖图':['open-screen-0.png','open-screen-2.png',34],
      '横版互动创意':['','',80],
      '横版互动创意':['','',81],
      },
      urlLinkPartIs:false,
      urlclickLinkPartIs:false,
      urlplayBeginUrlIs:false,
      urlplayEndUrlIs:false,
      creatNum:0,
      creatChildrenNum:0,//创意个数
      active:0,
      addList:[],//添加素材入参
      addScInfo:{},//添加素材容器
      delList:[],
      editListStart:[],
      editList:[],
      globalSpecId:null,//规格id
      videoHIs:false,
      imgDialogShow:false,
      userId:'',
      deptId:'',
      sc:{id:''},//选中素材的内容
      scList:[],
      creatUserInfo:{},//保存创意的信息
      // add
      sucaiLoaded:false,//素材是否加载完，
      restaurants: [],
      creatInfo:this.newPlanVue.creatInfo,
      firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',
      addCreatBtnIs:{},//添加素材按钮是否显示
      // rules: {
      //     name: [
      //       { required: true, message: '品牌名称不能为空', trigger: 'blur' },
      //       { min: 1, max: 18, message: '长度在 1 到 8 个字符', trigger: 'blur' }
      //     ],
      //     region: [
      //       { required: true, message: '文案不能为空', trigger: 'change' }
      //     ]}
    }
  },
  mounted(){
    console.log(666)
    console.log(this.newPlanVue.creatInfo)
    console.log(this.creativeForm)
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME) || 1
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.ownerId = Number(this.$route.query.ownerId)
    this.ownerName = this.$route.query.ownerName
    // this.speclistFn()
    this.adlistFn()
    console.log(this.creatInfo)
  },
  methods: {
     //弱提示
    alertMsgFn(msg,type){
      let _this = this
      _this.$message({
        showClose: true,
        message: msg,
        type: type
      })  
    },
    goPlanPage(){
      this.$router.push({
        path: '/plan-management/advertisingPlan',
        query: {
          id: this.ownerId,
          name:this.ownerName,
        }
      })
    },
    openFullScreen2() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return loading
    },
    showSetDialogFn(index){
      this.showDialog = true
      index = Number(index)
      this.LinkPart = this.showSetDialog[index]
    },
    IsURLFn (url,type) {
      let _this = this
      var strRegex = /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i;
      // var js=document.getElementById('url');
      
      if (!strRegex.test(url)&&url !='') {
          _this[type] = true
          return false;
      } else {
          _this[type] = false
          if(type == 'urlclickLinkPartIs'){
            // _this.clickLinkPartList.push(url)
            _this.addScInfo[_this.globalSpecId][_this.numIndex].clickUrl=url
          }
          if(type == 'urlLinkPartIs'){
            // _this.LinkPartList.push(url)
            _this.addScInfo[_this.globalSpecId][_this.numIndex].exposeUrl=url
          }
          if(type == 'urlplayBeginUrlIs'){
            // _this.playBeginUrlList.push(url)
            _this.addScInfo[_this.globalSpecId][_this.numIndex].playBeginUrl=url
          }
          if(type == 'urlplayEndUrlIs'){
            // _this.playEndUrlList.push(url)
            _this.addScInfo[_this.globalSpecId][_this.numIndex].playEndUrl=url
          }        
          return true;
      }
      
      
    },
    listFn(){
      let _this= this
      if(_this.addCreat.length>0){
        _this.imgDialogShow = true
        _this.$refs.imgDialog.listFn(_this.globalSpecId,_this.userId,_this.deptId,_this.num-1)
      }else{
        _this.alertMsgFn('请添加创意',"error")
      }
    },
    onSubmit(){
      let _this = this,creatNameIs = false,wenanNameIs = false
      console.log(this.addList)
      if(this.addCreat == undefined){
        _this.alertMsgFn('请添加创意',"error")
      }else{
        this.$refs.creativeForm.validate((valid) => {
          if (valid) {
            // creatNameIs = true
            if(_this.globalSpecId != 71){
              if(_this.videoHIs){//71是图标大图不需要素材
                this.$refs.creativeForm.validate((valid) => {
                  if (valid) {
                    wenanNameIs = true
                  } else {
                    return false;
                  }
                });
                if(creatNameIs || _this.videoHIs&&wenanNameIs){
                  this.batchAdFn()
                }
              }else{
                _this.alertMsgFn('请添加素材',"error")
              }
            }else{
              wenanNameIs = true
              this.batchAdFn()
            }
          } else {
            _this.alertMsgFn('请输入创意名称',"error")
            return false;
          }
        });
      }
    },
    addCreartFn(){
      let _this = this
      if(_this.num>9){
        return false
      }else{
        let i 

        if(_this.addScInfo[_this.globalSpecId]!=undefined){
          _this.num = _this.addScInfo[_this.globalSpecId].length
        }
        else{
          // if(_this.addScInfo[_this.globalSpecId]==undefined){
            // _this.addScInfo[_this.globalSpecId]={'addCreat':[],'numIndex':i}
            _this.addScInfo[_this.globalSpecId]=[]
          // }
        }
        _this.num++
        i = _this.num-1

        _this.numIndex = i
        _this.videoHIs = true
        _this.sc = {id:''}
        // _this.addScInfo[_this.globalSpecId].addCreat.push({'name':'创意'+_this.num})
        _this.addCreat=_this.addScInfo[_this.globalSpecId];
        _this.creativeForm.creativeName =_this.extensionName[_this.creatInfo.extensionType]+'_'+ _this.flowName[_this.creatInfo.flowScene]+'_'+this.mSpecNameList[_this.creatNum].mSpecName+'_创意'+_this.num+'_'+_this.dateFormat()
        _this.addScInfo[_this.globalSpecId].push({'name':'创意'+_this.num,'adGroupId':_this.creatInfo.adGroupId,'adName':_this.creativeForm.creativeName,'adPlanId':_this.creatInfo.planId,'globalSpecId':_this.globalSpecId})
      }
      _this.addCreatList.push({'name':'创意'+_this.num,'adGroupId':_this.creatInfo.adGroupId,'adName':_this.creativeForm.creativeName,'adPlanId':_this.creatInfo.planId,'globalSpecId':_this.globalSpecId})
      _this.creatUserInfo = {'adGroupId':_this.creatInfo.adGroupId,'adName':_this.creativeForm.creativeName,'adPlanId':_this.creatInfo.planId,'globalSpecId':_this.globalSpecId}
      // _this.addMaterialList(add)
    },
    //自定义弹框右侧选中的项-----删除
    deletClumnChecked(i,adId){
      let _this = this
      _this.addCreat.splice(i,1);
      _this.num--
      if(_this.addCreat[i]!=undefined){
        _this.addCreat.forEach((obj,i)=>{
          i = i+1
          _this.addCreat[i-1].name = '创意'+i
        })
      }
      
      if(i>1){
        if(_this.numIndex == _this.num){
          _this.numIndex = _this.num-1
        }
      _this.creativeForm.creativeName =_this.extensionName[_this.creatInfo.extensionType]+'_'+ _this.flowName[_this.creatInfo.flowScene]+'_'+this.mSpecNameList[_this.creatNum].mSpecName+'_'+_this.addCreat[i-1].name+'_'+_this.dateFormat()
      }else{
        _this.numIndex = 0
        if(_this.addCreat.length!=0){
          _this.creativeForm.creativeName =_this.extensionName[_this.creatInfo.extensionType]+'_'+ _this.flowName[_this.creatInfo.flowScene]+'_'+this.mSpecNameList[_this.creatNum].mSpecName+'_'+_this.addCreat[0].name+'_'+_this.dateFormat()
        }else{
          _this.creativeForm.creativeName = ''
          _this.videoHIs = false
          _this.addScInfo[_this.globalSpecId] = undefined
        }
      }
      if(adId!=undefined){
        _this.delList.push(adId)
      }
      if(_this.addCreat.length>0){
      // _this.sc = _this.scList[_this.num-1]
      _this.sc = _this.scList
      }else{
        _this.sc = {id:''}
      }
    },    
    changeFn(i,globalSpecId){
      let _this = this
      if(_this.addCreat.length>0){
        _this.creativeForm.creativeName =_this.addCreatList[i].adName
        _this.numIndex = i
      }else{
        _this.numIndex = 0
        _this.creativeForm.creativeName =_this.extensionName[_this.creatInfo.extensionType]+'_'+ _this.flowName[_this.creatInfo.flowScene]+'_'+this.mSpecNameList[_this.creatNum].mSpecName+'_'+_this.addCreat[i].name+'_'+_this.dateFormat()
      }
      _this.creativeForm.LinkPart = _this.addScInfo[_this.globalSpecId][i].exposeUrl || ''
      _this.creativeForm.clickLinkPart = _this.addScInfo[_this.globalSpecId][_this.numIndex].clickUrl || ''
      _this.creativeForm.playBeginUrl = _this.addScInfo[_this.globalSpecId][_this.numIndex].playBeginUrl || ''
      _this.creativeForm.playEndUrl = _this.addScInfo[_this.globalSpecId][_this.numIndex].playEndUrl || ''
      // if(_this.scList.length!=0){
      //   _this.sc = _this.scList[i]?_this.scList[i]:{id:''}
      // }
      // _this.xuanranFn(_this.globalSpecList[globalSpecId][i])
      _this.xuanranFn(_this.addScInfo[globalSpecId][i])
      _this.addFormFn(globalSpecId,i)
    },
    handleClick(mSpecName,i,newGlobalSpecId,oldGlobalSpecId){
      let _this = this
      _this.creatNum = i
      _this.creativeForm.creativeName = ''
      console.log(_this.addScInfo)
      if(_this.addScInfo[oldGlobalSpecId]==undefined){
        _this.num = 0
        _this.active = i
        _this.globalSpecId = newGlobalSpecId

      }else{
        if(_this.num>0&&_this.sc.id==''){
          _this.alertMsgFn('请添加素材',"error")
          return false
        }else{
          _this.active = i
          _this.videoHIs = false
          _this.globalSpecId = newGlobalSpecId
        }
      }
      if(_this.addScInfo[newGlobalSpecId]!=undefined){
        console.log('渲染创意素材')
        _this.videoHIs = true
        _this.xuanranFn(_this.addScInfo[newGlobalSpecId][0])
        _this.addFormFn(newGlobalSpecId,0)
      //  _this.addCreat=[]
        _this.addScInfo[newGlobalSpecId].forEach(obj=>{
          _this.addCreat.push(obj)
        })
      }else{
        _this.addCreat = []
        _this.sc = {id:''}
        _this.num = 0
      }
      
      // _this.numIndex = _this.num
      // _this.creativeForm.creativeName = []
      // _this.addCreat = _this.addScInfo[newGlobalSpecId]
      // _this.active = i
      console.log(this.addCreat)
      // if(_this.addCreat==undefined){
      //   _this.renderFn(mSpecName,newGlobalSpecId)
      // }
    },
    renderFn(mSpecName,globalSpecId){
      let _this = this
      if(_this.addScInfo[globalSpecId] != undefined){
       
        _this.addScInfo[globalSpecId].forEach((e,i)=>{
          if(globalSpecId == e.globalSpecId){
            // _this.addCreat.push(e)
            _this.addScInfo[globalSpecId].addCreat = e 
          }
        })
        _this.addCreat = _this.addScInfo[globalSpecId].addCreat
      }
      
      // if(_this.addCreat.length>0){
      //   _this.creativeForm.creativeName =_this.addCreat[0].adName
      // }else{
      //   _this.creativeForm.creativeName = ''
      // }
    },
    dateFormat(){
      const dt = new Date(new Date)
      const y = dt.getFullYear()
      const m = (dt.getMonth() + 1 + '').padStart(2, '0')
      const d = (dt.getDate() + '').padStart(2, '0')
      
      const hh = (dt.getHours() + '').padStart(2, '0')
      const mm = (dt.getMinutes() + '').padStart(2, '0')
      const ss = (dt.getSeconds() + '').padStart(2, '0')
      
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    },   
    
    creatSize(event){
      console.log(event.path[0].innerText)
    },
    //新增创意
    addMaterialList(){
      let _this = this ,params = {},addList = []
      _this.addScInfo[_this.globalSpecId].forEach(obj=>{
        // params=Object.assign({},_this.creatUserInfo,obj)
       addList.push(obj)

      })
      _this.addList = addList
      // _this.num++
      // _this.addList.push(params)
        // a = {
        //   "adGroupId": 0,
        //   "adName": "",
        //   "adPlanId": 0,
        //   "brandLogoImgId": 0,
        //   "brandName": "",
        //   "buttonTxt": "",
        //   "clickUrl": "",
        //   "copywriter": "",
        //   "copywriterId": 0,
        //   "downloadUrl": "",
        //   "dynamicCr": 0,
        //   "exposeEndUrl": "",
        //   "exposeUrl": "",
        //   "globalSpecId": 0,
        //   "hlMid": 0,
        //   "imgMatIds": 0,
        //   "playBeginUrl": "",
        //   "playEndUrl": "",
        //   "status": 0,
        //   "videoBgImgId": 0,
        //   "videoImgId": 0,
        //   "videoMatIds": 0
        // }
      
    },
    //删除创意
    removeRow(index) {
      this.num--
      if (index >= 0) {
        this.addList.splice(index, 1);
      }
      this.saveIsShow()
    },
    xuanranFn(item){
      let _this = this
      _this.scList={'imgUrl':item.imgMats!=null?item.imgMats[0].materialUrl:'',
        'videoUrl':item.videoUrl!=null?item.videoUrl+_this.firstWord:(item.imgMats!=null?item.imgMats[0].materialUrl:''),
        'logoUrl':item.logoUrl!=null?item.logoUrl:null,
        'id':item.adId,
        'imgList':item.imgList}
        _this.sc = _this.scList
    },
    //获取流量场景下的规格配置/perAddAd/speclist  extensionFlow:2-自有流量，3-联盟流量
    // speclistFn(){
    //   let _this = this//329;224
    //   let required
    //   $http.axios.get("/api/advertisement/perAddAd/speclist?extensionFlow="+_this.creatInfo.extensionFlow+"&extensionType="+_this.creatInfo.extensionType+"&flowScene="+_this.creatInfo.flowScene+"&ownerId="+_this.ownerId).then(res=> {
    //     // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
    //     if(res.data.code==0){
    //       if(res.data.data != null){
            
    //         _this.mSpecNameList = res.data.data
    //         _this.globalSpecId = _this.mSpecNameList[0].globalSpecId
    //         _this.mSpecNameList.forEach((obj,i)=>{
    //           required =eval("("+obj.required+")") 
    //           if (Object.keys(required).length == 0){   
    //             _this.addCreatBtnIs[obj.globalSpecId] = false
    //           }else {
    //             _this.addCreatBtnIs[obj.globalSpecId] = true
    //           }

    //           _this.mSpecNameList[i].globalSpecList = _this.globalSpecList[obj.globalSpecId]
    //           if(_this.mSpecNameList[i].globalSpecList){
    //             // _this.editList.push(_this.globalSpecList[_this.mSpecNameList[i].globalSpecId])
    //               _this.addScInfo = {}
    //               _this.mSpecNameList[i].globalSpecList.forEach((item,j,arr)=>{
                
    //               item.name = '创意'+Number(j+1)
    //               _this.addCreatList.push(item)
    //               // _this.addScInfo[item.globalSpecId].push(item)
    //              _this.addScInfo[item.globalSpecId] = _this.addCreatList//回显不能往addScInfo里添加
    //               if(_this.mSpecNameList[0].globalSpecList !== undefined) {
    //                 _this.videoHIs = true
    //                 _this.addCreat.push(item)
    //                 _this.xuanranFn(item)
    //                 _this.addFormFn(_this.mSpecNameList[0].globalSpecId,0)
    //               //   _this.scList.push({'imgUrl':item.imgMats!=null?item.imgMats[0].materialUrl:null,
    //               //   'videoUrl':item.videoMats!=null?item.videoMats[0].materialUrl+_this.firstWord:item.imgMats[0].materialUrl,
    //               //   'logoUrl':item.brandLogoImg!=null?item.brandLogoImg.materialUrl:null,
    //               //   'id':item.adId,
    //               //   'imgList':item.imgMats})
    //               //   _this.sc = _this.scList[0]
    //                 }
    //               })      
                 
    //           }
    //         })
    //         // _this.renderFn(_this.mSpecNameList[0].mSpecName,_this.mSpecNameList[0].globalSpecId)
    //         _this.globalSpecId = _this.mSpecNameList[0].globalSpecId
    //         console.log(_this.mSpecNameList)
    //         // let adlistArr = _this.mSpecNameList[0].globalSpecList
    //       //   if(adlistArr!=undefined){
    //       //     _this.creativeForm={
    //       //       'creativeSet':'',//自定义创意
    //       //       'brandName':adlistArr[0].brandName,
    //       //       'region':adlistArr[0].copywriter,
    //       //       'LinkPart':adlistArr[0].exposeUrl,//曝光监测链接
    //       //       'clickLinkPart':adlistArr[0].clickUrl,//点击监测链接
    //       //       'playBeginUrl':adlistArr[0].playBeginUrl,//播放开始监测链接  60
    //       //       'playEndUrl':adlistArr[0].playEndUrl,//播放结束监测链接  60
    //       //       'creativeName':adlistArr[0].adName,//创意名称
    //       //     }
    //       //   }
    //       }
          
    //     }else{
    //       _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
    //     }

    //   }).catch(error=>{
    //     let messageContent =error.message || '请查看网络，稍后再试'
    //     _this.alertMsgFn(messageContent,"error")
    //   })
    //   console.log(_this.mSpecNameList)
    // },

    speclistFn(){
      let _this = this//329;224
      let required
      $http.axios.get("/api/advertisement/perAddAd/speclist?extensionFlow="+_this.creatInfo.extensionFlow+"&extensionType="+_this.creatInfo.extensionType+"&flowScene="+_this.creatInfo.flowScene+"&ownerId="+_this.ownerId).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==0){
          if(res.data.data != null){
            
            _this.mSpecNameList = res.data.data
            _this.globalSpecId = _this.mSpecNameList[0].globalSpecId
            _this.mSpecNameList.forEach((obj,i)=>{
              required =eval("("+obj.required+")") 
              if (Object.keys(required).length == 0){   
                _this.addCreatBtnIs[obj.globalSpecId] = false
              }else {
                _this.addCreatBtnIs[obj.globalSpecId] = true
              }

              _this.mSpecNameList[i].globalSpecList = _this.globalSpecList[obj.globalSpecId]
              if(_this.mSpecNameList[i].globalSpecList){
                // _this.editList.push(_this.globalSpecList[_this.mSpecNameList[i].globalSpecId])
                  _this.addScInfo = {}
                  _this.mSpecNameList[i].globalSpecList.forEach((item,j,arr)=>{
                
                  item.name = '创意'+Number(j+1)
                  _this.addCreatList.push(item)
                  // _this.addScInfo[item.globalSpecId].push(item)
                 _this.addScInfo[item.globalSpecId] = _this.addCreatList//回显不能往addScInfo里添加
                  if(_this.mSpecNameList[0].globalSpecList !== undefined) {
                    _this.videoHIs = true
                    _this.addCreat.push(item)
                    _this.addFormFn(_this.mSpecNameList[0].globalSpecId,0)
                  //   _this.scList.push({'imgUrl':item.imgMats!=null?item.imgMats[0].materialUrl:null,
                  //   'videoUrl':item.videoMats!=null?item.videoMats[0].materialUrl+_this.firstWord:item.imgMats[0].materialUrl,
                  //   'logoUrl':item.brandLogoImg!=null?item.brandLogoImg.materialUrl:null,
                  //   'id':item.adId,
                  //   'imgList':item.imgMats})
                  //   _this.sc = _this.scList[0]
                    }
                  })      
                 _this.mSpecNameList[i].globalSpecList.reverse().forEach(ele=>{
                    _this.xuanranFn(ele)
                 })
              }
            })
            // _this.renderFn(_this.mSpecNameList[0].mSpecName,_this.mSpecNameList[0].globalSpecId)
            _this.globalSpecId = _this.mSpecNameList[0].globalSpecId
            console.log(_this.mSpecNameList)
            // let adlistArr = _this.mSpecNameList[0].globalSpecList
          //   if(adlistArr!=undefined){
          //     _this.creativeForm={
          //       'creativeSet':'',//自定义创意
          //       'brandName':adlistArr[0].brandName,
          //       'region':adlistArr[0].copywriter,
          //       'LinkPart':adlistArr[0].exposeUrl,//曝光监测链接
          //       'clickLinkPart':adlistArr[0].clickUrl,//点击监测链接
          //       'playBeginUrl':adlistArr[0].playBeginUrl,//播放开始监测链接  60
          //       'playEndUrl':adlistArr[0].playEndUrl,//播放结束监测链接  60
          //       'creativeName':adlistArr[0].adName,//创意名称
          //     }
          //   }
          }
          
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
      console.log(_this.mSpecNameList)
    },


    addFormFn(globalSpecId,index){
      let _this = this,adlistArr = []
      
        _this.mSpecNameList.forEach(obj=>{
          if(obj.globalSpecId == globalSpecId){
            if(obj.globalSpecList!=undefined){
              adlistArr =obj.globalSpecList
            }else{
              adlistArr = _this.addScInfo[globalSpecId]
            }
          }
        })
      if(adlistArr!=undefined){
        _this.creativeForm={
          'creativeSet':'',//自定义创意
          'brandName':adlistArr[index].brandName,
          'region':adlistArr[index].copywriter,
          'LinkPart':adlistArr[index].exposeUrl,//曝光监测链接
          'clickLinkPart':adlistArr[index].clickUrl,//点击监测链接
          'playBeginUrl':adlistArr[index].playBeginUrl,//播放开始监测链接  60
          'playEndUrl':adlistArr[index].playEndUrl,//播放结束监测链接  60
          'creativeName':adlistArr[index].adName,//创意名称
        }      }
      
    },
    //广告组下全部广告列表
    adlistFn(){
      let _this = this,params = {}
      params = {
        "adGroupId":_this.creatInfo.adGroupId,// 301156964,//300166557;301156964(224);301118935
        "ownerId": _this.ownerId//1000092281
      }
      $http.axios.post("/api/advertisement/perAddAd/adlist",params).then(res=> {
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          if(res.data.content != null){
            // _this.globalSpecList = res.data.content
            // _this.editListStart.push(res.data.content)
            // _this.addScInfo[_this.globalSpecId].push({'name':'创意'+_this.num,'adGroupId':_this.creatInfo.adGroupId,'adName':_this.creativeForm.creativeName,'adPlanId':_this.creatInfo.planId,'globalSpecId':_this.globalSpecId})

            
            _this.speclistFn()
          }
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })
    },
    // 获取广告组下每个规格支持的可创建创意数量上限
    adEachSpecNumLimitFn(){
      let _this = this
      $http.axios.get("/api/advertisement/perAddAd/adEachSpecNumLimit?ownerId="+_this.ownerId).then(res=> {//ownerId需要写活
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==0){
          if(res.data.data != null){
            
          }
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })    
    },
    // 批量操作广告
    batchAdFn(){
      let _this = this ,params = {},addList = []
      if(_this.addScInfo[_this.globalSpecId] !=undefined ){
          _this.addScInfo[_this.globalSpecId].forEach(obj=>{
          // params=Object.assign({},_this.creatUserInfo,obj)
          addList.push(obj)
        })      
      }else if(_this.editList.length !=0){

      }else{
        _this.alertMsgFn('请添加创意',"error")
        return false
      }
      
      _this.addList = addList
      params = {
        "addList": _this.addList,
        // [
        //   {
        //     "adGroupId": 0,
        //     "adName": "",
        //     "adPlanId": 0,
        //     "brandLogoImgId": 0,
        //     "brandName": "",
        //     "buttonTxt": "",
        //     "clickUrl": "",
        //     "copywriter": "",
        //     "copywriterId": 0,
        //     "downloadUrl": "",
        //     "dynamicCr": 0,
        //     "exposeEndUrl": "",
        //     "exposeUrl": "",
        //     "globalSpecId": 0,
        //     "hlMid": 0,
        //     "imgMatIds": 0,
        //     "playBeginUrl": "",
        //     "playEndUrl": "",
        //     "status": 0,
        //     "videoBgImgId": 0,
        //     "videoImgId": 0,
        //     "videoMatIds": 0
        //   }
        // ],
        "delList": _this.delList,
        "deptId": 3,
        "editList":_this.editList,
        // "editList": [
        //   {
        //     "adGroupId": 0,
        //     "adId": 0,
        //     "adName": "",
        //     "adPlanId": 0,
        //     "brandLogoImgId": 0,
        //     "brandName": "",
        //     "buttonTxt": "",
        //     "clickUrl": "",
        //     "copywriter": "",
        //     "copywriterId": 0,
        //     "downloadUrl": "",
        //     "dynamicCr": 0,
        //     "exposeEndUrl": "",
        //     "exposeUrl": "",
        //     "globalSpecId": 0,
        //     "hlMid": 0,
        //     "imgMatIds": 0,
        //     "playBeginUrl": "",
        //     "playEndUrl": "",
        //     "status": 0,
        //     "videoBgImgId": 0,
        //     "videoImgId": 0,
        //     "videoMatIds": 0
        //   }
        // ],
        "ownerId": _this.ownerId,//1000092281,
        "userId": _this.userId,
        "userName": _this.userName
      }
      $http.axios.post("/api/advertisement/ad/v1/batchAd",params).then(res=> {//ownerId需要写活
        // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code=='200'){
          // if(res.data.data != null){
            _this.alertMsgFn(res.data.msg,"success")
            _this.goPlanPage()
          // }
        }else{
          _this.alertMsgFn(res.data.msg || '请查看网络，稍后再试',"error")
        }

      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.alertMsgFn(messageContent,"error")
      })   
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    loadAll() {
      return [
        { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
        { "value": "Hot honey 首尔炸鸡（仙霞路）", "address": "上海市长宁区淞虹路661号" },
        { "value": "新旺角茶餐厅", "address": "上海市普陀区真北路988号创邑金沙谷6号楼113" },
        { "value": "泷千家(天山西路店)", "address": "天山西路438号" },
      ];
    },
    handleSelect(item) {
      console.log(item);
    },
    handleIconClick(key,ev) {
      let _this = this,editList = []
      console.log(key,ev);
      _this.addScInfo[_this.globalSpecId][_this.numIndex][key] = ev
      _this.editListStart.forEach((obj,i,arr)=>{
        if(Object.keys(obj)[0] == _this.globalSpecId){
          obj[_this.globalSpecId][_this.numIndex][key] = ev
        }
        

      })
      _this.editListStart.forEach(ele=>{
        ele[_this.globalSpecId].forEach(item=>{
          editList.push(item)
        })
        })
        _this.editList = editList
      console.log(_this.editList)
    }   
  }
}
</script>
<style lang="scss" scoped>
  .my-autocomplete {
    li {
      line-height: normal;
      padding: 7px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .addr {
        font-size: 12px;
        color: #b4b4b4;
      }

      .highlighted .addr {
        color: #ddd;
      }
    }
  }  
  .batchSetBtn{
    margin-left: 10px;
  }
 
    .column_left{
      width:70%;
      padding-right:20px;
    }
    
   
    .column_right ul li i{
      font-weight:700;
      cursor:pointer;
    }
    
    .column_right .el-icon-remove-outline{
      color:brown;
      padding-left:5px;
    }
   
    .tabBtn{
      float: left;
      border:1px solid #ccc;
      padding: 5px 30px;
    }
    .tabBtn.active{
      color: #2fc29b;
      background-color: #eaf9f5;
      border: 1px solid #2fc29b;
    }  
    .sizeIco{
      display: inline-block;
      text-align: center;
      border: 1px solid #e5e5e5;
      cursor: pointer;
      box-sizing: border-box;
      padding: 8px 16px;
      font-size: 14px;
      width: 110px;
      display: flex;
      flex-direction: column;
    }
    .sizeIco img{
      width: 45px;
      margin: 0 auto;
    }
    .wrong{
      color: red;
      font-size: 12px;
    }
    .title{
      display: block;
    }
    .creatBtn{
      cursor: pointer;
      margin-right: 10px;
      border: 1px solid transparent;
    }
    .creatBtn:hover,.creatActive{
      background-color: #d5f3eb;
      border: 1px solid #d5f3eb;
      color: #2fc29b;
    }
    .scBg{
      min-width: 950px;
      min-height:450px;
      margin-left: 150px;
      background-color: #f7f8fb;
      padding: 15px 25px;
      border-radius: 12px;
    }
</style>