<template>
  <div>
    <iframe 
      v-if="dateIs==false" 
      id="iframe" 
      ref="iframeref" 
      name="mainFrame" 
      src="https://bi.aliyuncs.com/token3rd/dashboard/view/pc.htm?pageId=5153d1f3-8b0f-4eef-95f4-eab883e258ba&accessToken=4482ebbbb3dee3286c3b7d7af6ab6902&dd_orientation=auto" 
      width="100%" 
      height="100%" 
      style="position: absolute;;top:60px;left:0;z-index:10;">
    </iframe>
    <i 
      v-if="dateIs==true" 
      class="el-icon-warning-outline">
      目前所有人员的看板功能都已经上线，如果您能看到这段话，说明您是新用户，您的看板没有配置，请联系技术中心解决！
    </i>
  </div>
</template>
<script>
  import config from '@/api/config';
  import { $http } from '@/api/http';
  export default {
    name: 'Home',
    data() {
      return {
        dateIs:false,
        url:'',
      }
    },
    mounted() {
      this.biUrlFn()
    },
    methods: {
      enter(event){
        console.log(this.$refs.iframeref.style.top)
        let y=event.pageY
        let y1=event.clientY
        console.log(y)
        console.log(y1)
        if(y > 50){
          this.$refs.iframeref.style.top = 0+'px'
        }else{
          this.$refs.iframeref.style.top = 60+'px'
        }
      },
      biUrlFn(){
        let _this = this
        $http.axios.get("/api/system/user/biUrl").then(function(response) {
          if(response.data.code == 200){
            if(response.data.data==undefined){
              _this.dateIs=true
            }else{
              _this.url = response.data.data
              _this.dateIs=false
            }
          } else {
            _this.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
  }
}
</script>
<style scoped>
.el-icon-warning-outline{
      display: block;
    margin: 200px auto;
    text-align: center;
    font-size: 24px;
}
.notice{
  padding:0 20px;
  font-size:14px;
}
.notice h3{
  text-align:center;
  font-size:22px;
  font-weight:700;
}
.notice p {
  text-indent:2em;
  font-weight:700;
}
.noticeH{
  font-weight:700;
}
.notice ul,.notice li{
  padding:0;
  margin:0;
  font-weight:400;
}
.notice li{
  margin:10px 0;
}
.noticeLast{
  text-align:right;
}
</style>
