<template>
  <el-dialog
  title="更新爱奇艺任务关系"
    :visible.sync="show"
    width="560px"
    :close-on-click-modal="false"
    class="account-detail-dialog">
      <el-table
        :data="updateList"
        style="width: 100%">
      <el-table-column label="广告主编号">
          <template slot-scope="scope">
            <el-input :class="{'error': !scope.row.ownerId && scope.$index == index}" v-model="scope.row.ownerId" :placeholder="scope.$index == index ? '广告主编号不可为空' : '必填'"></el-input>
          </template>
        </el-table-column>
      <el-table-column label="任务ID" width='280'>
          <template slot-scope="scope">
            <el-input :class="{'error': !scope.row.taskIds && scope.$index == index}" v-model="scope.row.taskIds" :placeholder="scope.$index == index ? '任务ID不可为空' : '必填'"></el-input>
          </template>
        </el-table-column>
      <el-table-column label="操作"
                  width='70'>
          <template slot-scope="scope">
            <el-button class="newBtn_Yellow" data-type = 'sendBtn' plain size="mini" @click="save(scope.row, scope.$index)">保存</el-button>
          </template>
        </el-table-column>
      </el-table>
  <div slot="footer" class="dialog-footer">
    <!-- <el-button @click="show = false">取 消</el-button>
    <el-button type="primary" @click="save">保 存</el-button> -->
  </div>
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      index: null,
      show: false,
      updateList: []
    };
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v
      if (this.show) {
        this.update()
      }
    },
  },
  mounted(){
    // this.update()
  },
  methods: {
    update () {
      let _this = this
      _this.index = null
      $http.axios.get("/api/dataReport/reportModel/taskRelation/list").then(res=> {
        if(res.data.code == 200){
          _this.updateList = res.data.data || []
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    save (item, index) {
      let _this = this
      if (!item.ownerId || !item.taskIds) {
        _this.index = index
        return
      }
      $http.axios.get("/api/dataReport/reportModel/taskRelation/insert?ownerId=" + item.ownerId + '&taskIds=' + item.taskIds).then(res=> {
        if(res.data.code == 200){
          _this.show = false
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep.error {
  .el-input__inner {
    border-color: red;

    &::placeholder {
  color: red;
}
  }
}
</style>

