<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    title=""
  >
<el-table :data="gridData" ref="multipleTable">
  
  <el-table-column
    property="priceDate"
    label="调价日期">
    <template slot-scope="scope">
      {{ scope.row.dateRange }}
    </template>
  </el-table-column>
  <el-table-column
    property="priceDateSlot"
    label="调价时间段">
    <template slot-scope="scope">
      <div v-for="(item,i) in scope.row.details" :key="i">
        <span>{{item.time}}</span>
      </div>
    </template>
  </el-table-column>
  <el-table-column
    property="priceStatus"
    label="当天是否继续执行">
    <template slot-scope="scope">
      {{scope.row.executeTodayFlag}}
    </template>
  </el-table-column>
  <el-table-column
    property="priceStatus"
    label="目标金额">
    <template slot-scope="scope">
      <div v-for="(item,i) in scope.row.details" :key="i">
        <span style="display: block;">{{item.price}}</span>
      </div>
    </template>
  </el-table-column>
  
  <el-table-column
    property="implementStatus"
    label="执行状态">
    <template slot-scope="scope">
      <span v-if="scope.row.status == 1">待执行</span>
      <span v-if="scope.row.status == 2">执行中</span>
      <span v-if="scope.row.status == 3">执行完成</span>
      <span v-if="scope.row.status == 4">异常</span>
      <span v-if="scope.row.status == 5">弃用</span>
    </template>
  </el-table-column>
  
 
</el-table>
  </el-dialog>
</template>

<script>
  import { $http } from '@/api/http';
export default {
  
  name: "startStopDialog",

  props: {
    pubVue:Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      timeForm:{
        tan1:'',
        tan2:'',
        tan3:'',
        tan4:'',
        tan5:'',
        tan6:'',
        tan7:'',
        tan8:'',
        tan9:''
      },//时间段弹框查询form
      tan5Options:[//子列表弹框中的调价状态
        {
          name:'开启中',
          id:'0'
        },{
          name:'关闭中',
          id:'1'
        }
      ],

      implementOptions:[],
      gridData: [],//时间段表格数据
      timeSlotParams:{//时间段详情列表入参
          adGroupId:'',//列表中的广告组id
          id:'',//每一条的id
          currentPage:'',
          pageSize:'',
          userId:'',
          userName:'',
          executionStatus:'',
          stime:'',
          etime:'',
          mathType:'',
          mathStart:'',
          mathEnd:'',
          upPriceStatus:'',
          priceType:'',
          upPriceType:'',
          upPriceMode:'',
          planType:''
        },
        DN:'yyb'
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    
  },
  methods: {
   
    onConfirm(formName) {
      let _this = this
     },
    onCancel() {
      this.show= false;
    },
    addOrSetFn(type){
      
    },


    // {
    //   "adGroupId": 0,
    //   "currentPage": 0,
    //   "deptId": 0,
    //   "etime": "",
    //   "executionStatus": 0,
    //   "id": 0,
    //   "mathEnd": 0,
    //   "mathStart": 0,
    //   "mathType": 0,
    //   "ownerId": 0,
    //   "pageIndex": 0,
    //   "pageSize": 0,
    //   "planId": 0,
    //   "planType": 0,
    //   "priceType": 0,
    //   "stime": "",
    //   "upPriceMode": 0,
    //   "upPriceStatus": 0,
    //   "upPriceType": 0,
    //   "userId": 0,
    //   "userName": ""
    // }

 //获取时间段的详情列表
 getTimeSlotDetail(planType,userContent){
      let that = this,DN = this.DN

      that.timeSlotParams.userId = userContent.userId
      that.timeSlotParams.userName = userContent.userName
      that.timeSlotParams.deptId = userContent.deptId
      that.timeSlotParams.pageIndex = 1
      that.timeSlotParams.pageSize = 100
      that.timeSlotParams.planIds = planType
      that.timeSlotParams.startDate = that.timeForm.tan2[0]
      that.timeSlotParams.endDate = that.timeForm.tan2[1]

      $http.axios.post("/api/"+DN+"/modifyPrice/infoList",that.timeSlotParams).then(res=> {
        if(res.data.code == 200){
          that.gridData = res.data.data.infos
          that.totalRowdetail = res.data.data.pageInfo.rowCount
          console.log(456)
        }  else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
   
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
</style>

