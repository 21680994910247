<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle" v-if="accountSelectionListShow">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item>首页管理</el-breadcrumb-item>
                <el-breadcrumb-item>账户列表</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-button type="primary" size="small" @click="openDistribution">分配</el-button>
              <el-button type="primary" size="small" v-if="personHide" @click="batchDistriAssist">批量增加辅优化师</el-button>
              <el-button type="primary" size="small" v-if="erdaizj" @click="batchErdai">二代扩展账户</el-button>
            </div>
          </div>  

          <div class="chanelTab">
          <span class="channel_oppo" :class="item.id==3?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=3&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
          </div>
          <div class="userList">
            <div class="userform">
              <el-form :model="ruleForm" :inline="true" ref="ruleForm" class="demo-form-inline">
                <el-form-item label="" prop="comName">
                  <el-autocomplete
                    class="inline-input"
                    v-model="ruleForm.comName"
                    value-key="company"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入公司名称"
                    @select="handleSelect"
                  ></el-autocomplete>
                </el-form-item>
                <el-form-item label="">
                  <el-autocomplete
                    class="inline-input"
                    v-model="ruleForm.accountName"
                    value-key="company"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入账户名称"
                    @select="handleSelect"
                  ></el-autocomplete>
                </el-form-item>
                <el-form-item label="" prop="comAccountID">
                  <el-input v-model="ruleForm.comAccountID" placeholder="请输入账户ID"></el-input>
                </el-form-item>
                <el-form-item label="" prop="fpstatuvalue" class="positionBox">
                  <el-select v-model="ruleForm.fpstatuvalue" placeholder="分配状态" clearable @change="changeFpStatus($event)">
                    <el-option
                      v-for="item in fpstatuoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      @change="changeUserStatue($event)">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" prop="firstName" class="positionBox">
                  <el-select v-model="ruleForm.firstName" placeholder="请选择一级行业" clearable @change="firstGongname">
                    <el-option
                      v-for="(item,i) in firstNameoptions"
                      :key="i"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="secondName" class="positionBox">
                  <el-select v-model="ruleForm.secondName" placeholder="请选择二级行业" clearable @change="secondGongname">
                    <el-option
                      v-for="item in secondNameoptions"
                      :key="item.value"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="customerType" class="positionBox">
                  <el-select v-model="ruleForm.customerType" placeholder="请选择客户类型" clearable >
                    <el-option
                      v-for="(item,i) in customerTypeoptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="dateChoice">
                  <el-date-picker
                    v-model="ruleForm.dateChoice"
                    type="daterange"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" class="newQuery" size="small" @click="getAccountList(1)">查询</el-button>
                  <el-button type="info" size="small" @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
              </el-form>
              <!-- 批量分配 -->
              <el-dialog
                title="分配账户"
                :visible.sync="dialogFormVisible"
                center
                :show-close = "false"
                width="600px">
                <div class="dialogIcon"><img :src='distrbIcon'></div>
                <el-form 
                  :model="distribution_form" 
                  :rules="distributionRule" 
                  ref="piliangfenpei" 
                  class="distributionAccount">
                  <el-form-item label="分配渠道" class="distribNewStyle" :label-width="formLabelWidth1">
                    <el-input v-model="qdvalueHx" autocomplete="off" :disabled="true"></el-input>
                  </el-form-item>
                  <div>
                    <div v-for="(item,i) in bigFpParams.userList" class="newAddfpStyle newNetStyle" :key="i">
                      <div class="redstar newNetStyleIn_inline">
                        <!-- <span class="starStyle">*</span> -->
                        <el-form-item label="">
                          <span class="newRedStar">
                            <i>*</i>
                            <span>分配部门</span>
                          </span>
                          <el-cascader
                            v-model="item.dept"
                            :options="deptTree"
                            :props="props"
                            ref="cascaderAddr"
                            :show-all-levels="false"
                            placeholder="请选择分配部门"
                            @change="deptTreeDetail">
                          </el-cascader>
                        </el-form-item>
                      </div>
                      <div class="redstar newNetStyleIn_inline">
                        <span class="starStyle">*</span>
                        <el-form-item :label="i == 0?'主分配优化师':'辅分配优化师'" :label-width="formLabelWidth1">
                          <el-select v-model="plfpyhsSingle[item.userId]" value-key="userId" filterable placeholder="请选择分配优化师" @visible-change="$forceUpdate()" @change="bigFpChange($event,i)">
                              <el-option
                              v-for="(item1,j) in distributionOptions[item.dept]"
                              :key="j"
                              :label="item1.string"
                              :value="item1">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <!-- <el-form-item label="" :label-width="formLabelWidth1" class="newReduceBtnStyle" >
                        <el-button type="primary" v-if="i>0" size="small" @click="removeRowpl(i)">删除</el-button>
                      </el-form-item> -->
                      <div class="deleteBtn">
                        <div v-if="i>0" @click="removeRowpl(i)">
                          <i class="deleteBtnicon el-icon-error"></i>
                        </div>
                      </div>
                    </div>
                    <!-- <el-button type="primary" size="small" class="newAddBtnStyle" @click="newAddListplBtn">新增</el-button> -->
                    <div @click="newAddListplBtn" class="addConditions">
                      <i class="el-icon-plus"></i>新增分配
                    </div>
                  </div>
                  <div class="redstar">
                    <span class="starStyle tabstarStyle">*</span>
                    <el-form-item label="素材标签" label-width="70px" class="distribNewStyle tabdistribNewStyle">
                      <el-select placeholder="请选择素材标签" v-model="addmarterialplValue" value-key="id" multiple filterable class="resourcesTab" @change="materialTagIds">
                        <el-option
                          v-for="(item,k) in resourcesTabPoptions"
                          :key="k"
                          :label="item.tagName"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <!-- 新建标签 -->
                      <div class="tabNew">
                        <el-button type="primary" class="newQuery" size="small" @click="addTagDialog = true">增加标签</el-button>
                        <el-dialog 
                          title="新增标签" 
                          :visible.sync="addTagDialog" 
                          append-to-body 
                          center
                          :show-close = "false"
                          width="300px">
                          <el-input v-model="addTagsValue" autocomplete="off" placeholder="请输入新增标签名称"></el-input>
                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" class="newDialogCancel" @click="addTagDialog = false">取 消</el-button>
                            <el-button type="primary" class="newDialog" @click="addTags">新 增</el-button>
                          </div>
                        </el-dialog>
                      </div>
                    </el-form-item>
                  </div>
                  <el-form-item label="项目类别" prop="projectType" class="distribNewStyle" :label-width="formLabelWidth1">
                    <el-select v-model="distribution_form.projectType" filterable placeholder="请选择项目类别">
                      <el-option
                        v-for="(item,i) in projectTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="产品名称" prop="fpproductName" class="distribNewStyle tabdistribNewStyle tabProduct">
                    <el-select placeholder="请选择产品名称" v-model="distribution_form.fpproductName" filterable class="resourcesTab">
                      <el-option
                        v-for="(item,i) in fpProductNameOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>

                    <div class="tabNew">
                      <el-button type="primary" class="newQuery" size="small" @click="productNameDialog = true">增加产品名称</el-button>
                      <el-dialog 
                        title="新增产品名称" 
                        :visible.sync="productNameDialog" 
                        append-to-body 
                        width="300px">
                        <el-input v-model="addproductNameValue" autocomplete="off" placeholder="请输入新增产品名称"></el-input>
                        <div slot="footer" class="dialog-footer">
                          <el-button type="primary" @click="addproductName">新 增</el-button>
                          <el-button @click="cancleProductName">取 消</el-button>
                        </div>
                      </el-dialog>
                    </div>
                    
                  </el-form-item>
                  <div>
                    <div v-if="ruleForm.qdvalue == 'OPPO'">
                    </div>
                    <div v-else>
                      <el-form-item label="客户名称" :label-width="formLabelWidth1">
                        <el-select v-model="distribution_form.userProductName" clearable filterable placeholder="请选择客户名称" @change="changeProduct($event)">
                          <el-option
                            v-for="(item,i) in productNameOptions"
                            :key="i"
                            :label="item.productName"
                            :value="item">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="项目名称" :label-width="formLabelWidth1">
                        <el-select v-model="distribution_form.projectName" clearable filterable placeholder="请选择项目名称" @change="changeProject($event)" @focus="focusProject">
                          <el-option
                            v-for="(item,i) in projectNameOptions"
                            :key="i"
                            :label="item.projectName"
                            :value="item">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" class="newDialogCancel" @click="plcancle">取消保存</el-button>
                  <el-button type="primary" class="newDialog" @click="bigSureFp('piliangfenpei')">确认保存</el-button>
                </div>
              </el-dialog>     
            </div>
            <!-- 批量增加辅优化师 -->
            <el-dialog
                title="批量增加辅优化师"
                :visible.sync="dialogassistVisible"
                center
                :show-close = "false"
                width="600px">
                <div class="dialogIcon"><img :src='distrbIcon'></div>
                <el-form :model="distribution_form" class="distributionAccount">
                  <div v-for="(item,i) in editBigFpParams.userList" class="newAddfpStyle newNetStyle" :key="i">
                    <div class="redstar newNetStyleIn_inline">
                      <span class="starStyle">*</span>
                      <el-form-item label="分配部门" label-width="70px">
                        <el-cascader
                          v-model="item.dept"
                          :options="deptTree"
                          :props="props"
                          ref="cascaderAddr"
                          :show-all-levels="false"
                          placeholder="请选择分配部门"
                          @change="batchDept">
                        </el-cascader>
                      </el-form-item>
                    </div>

                    <div class="redstar newNetStyleIn_inline">
                      <span class="starStyle">*</span>
                      <el-form-item label="辅分配优化师" label-width="90px">
                        <el-select v-model="item.userId" @visible-change="$forceUpdate()" filterable placeholder="请选择分配优化师">
                            <el-option
                            v-for="(item2,j) in batchDistributionOptions[item.dept]"
                            :key="j"
                            :label="item2.string"
                            :value="item2.userId">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="deleteBtn">
                      <div v-if="i>0" @click="removeRowplzj(i)">
                        <i class="deleteBtnicon el-icon-error"></i>
                      </div>
                    </div>

                    <!-- <el-form-item label="" :label-width="formLabelWidth" class="newReduceBtnStyle" >
                      <el-button type="primary" v-if="i>0" size="small" @click="removeRowplzj(i)">删除</el-button>
                    </el-form-item> -->
                  </div>
                  <!-- <el-button type="primary" size="small" class="newAddBtnStyle" @click="batchAddBtn">新增</el-button> -->
                  <div @click="batchAddBtn" class="addConditions">
                    <i class="el-icon-plus"></i>新增分配
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" class="newDialogCancel" @click="dialogassistVisible = false">取消保存</el-button>
                  <el-button type="primary" class="newDialog" @click="batchBistribution">确认保存</el-button>
                </div>
              </el-dialog>     
              <div class="userTable">
                <!-- 单个分配 -->
                <el-dialog
                  title="分配账户"
                  :visible.sync="dialogFormVisible_account"
                  :close-on-click-modal="false"
                  :show-close = "false"
                  center
                  class="topDistance"
                  width="600px">
                  <div class="dialogIcon"><img :src='distrbIcon'></div>
                  <el-form :model="account__form" ref="dangefenpei" :rules="rules" class="fpAccounts">
                    <el-form-item label="公司名称" prop="name" class="distribNewStyle" :label-width="formLabelWidth1">
                      <el-input v-model="account__form.name" :disabled="true" autocomplete="off" style="width:260px;"></el-input>
                    </el-form-item>
                    <el-form-item label="账户ID" prop="regionID" class="distribNewStyle" :label-width="formLabelWidth1">
                      <el-input v-model="account__form.regionID" :disabled="true" autocomplete="off" style="width:260px;"></el-input>
                    </el-form-item>

                    <div>
                      <div v-for="(item,i) in disAccountParams.userList" class="newAddfpStyle newNetStyle" :key="i">
                        <div class="redstar newNetStyleIn_inline">
                          <!-- <span class="starStyle">*</span> -->
                          <el-form-item label="" >
                            <span class="newRedStar">
                              <i>*</i>
                              <span>分配部门</span>
                            </span>
                            <el-cascader
                              v-model="item.dept"
                              :options="deptTreefp"
                              :props="props"
                              ref="cascaderAddr"
                              :show-all-levels="false"
                              :placeholder="item.deptName?item.deptName:'请选择分配部门'"
                              @change="deptTreefpDetail">
                            </el-cascader>
                          </el-form-item>
                        </div>
                        <div class="redstar newNetStyleIn_inline" v-if="zongjian">
                          <span class="starStyle">*</span>
                          <el-form-item :label="i==0?'主分配优化师':'辅分配优化师'" :label-width="formLabelWidth1">
                            <el-select v-model="fpyhsSingle[item.userId]" value-key="userId" filterable placeholder="请选择分配优化师" @visible-change="$forceUpdate()" @change="changeOptimizer($event,i)">
                                <el-option
                                v-for="(item1,j) in distributionOptionsfp[item.dept]"
                                :key="j"
                                :label="item1.string"
                                :value="item1"
                                :disabled="item1.chkDisabled">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                          <!-- <el-form-item label="" :label-width="formLabelWidth1" class="newReduceBtnStyle" >
                            <el-button type="primary" v-if="i>0" size="small" @click="removeRow(i)">删除</el-button>
                          </el-form-item> -->
                          <div class="deleteBtn">
                            <div v-if="i>0" @click="removeRow(i)">
                              <i class="deleteBtnicon el-icon-error"></i>
                            </div>
                          </div>
                      </div>
                      <!-- <el-button type="primary" size="small" class="newAddBtnStyle" @click="newAddListBtn" v-if="zongjian">新增</el-button> -->
                      <div @click="newAddListBtn" class="addConditions" v-if="zongjian">
                        <i class="el-icon-plus"></i>新增分配
                      </div>
                    </div>
                    <div v-if="zongjian">
                      <el-form-item label="素材标签" prop="addmarterialValue" label-width="80px" class="distribNewStyle tabdistribNewStyle">
                        <el-select placeholder="请选择素材标签" v-model="account__form.addmarterialValue" value-key="id" multiple filterable class="resourcesTab" @change="materialTagIdsSingle">
                          <el-option
                            v-for="(item,k) in resourcesTabPoptions"
                            :key="k"
                            :label="item.tagName"
                            :value="item.id">
                          </el-option>
                        </el-select>

                        <div class="tabNew">
                          <el-button type="primary" class="newQuery" size="small" @click="addTagDialog = true">增加标签</el-button>
                          <el-dialog 
                            title="新增标签" 
                            :show-close = "false"
                            center
                            :visible.sync="addTagDialog" 
                            append-to-body 
                            width="300px">
                            <el-input v-model="addTagsValue" autocomplete="off" placeholder="请输入新增标签名称"></el-input>
                            <div slot="footer" class="dialog-footer">
                              <el-button class="newDialogCancel" type="primary" @click="addTagDialog = false">取 消</el-button>
                              <el-button class="newDialog" type="primary" @click="addTags">新 增</el-button>
                            </div>
                          </el-dialog>
                        </div>
                    </el-form-item>
                    <el-form-item label="一级行业" prop="firstHangye" label-width="80px" class="distribNewStyle tabdistribNewStyle">
                      <el-select v-model="account__form.firstHangye" value-key="id" filterable placeholder="请选择一级行业" @change="firstGong">
                        <el-option
                          v-for="(item,i) in firstHangyeOptions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="二级行业" prop="secondHangye" label-width="80px" class="distribNewStyle tabdistribNewStyle">
                      <el-select v-model="account__form.secondHangye" value-key="id" filterable placeholder="请选择二级行业" @change="secondGong">
                        <el-option
                          v-for="(item,i) in secondHangyeOptions"
                          :key="item.id"
                          :label="item.name"
                          :value="item.name">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="客户类型" prop="costumerType" label-width="80px" class="distribNewStyle tabdistribNewStyle">
                      <el-select v-model="account__form.costumerType" filterable placeholder="请选择客户类型" @change="changeCustomer">
                        <el-option
                          v-for="(item,i) in costumerTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="项目类别" prop="projectType" label-width="80px" class="distribNewStyle tabdistribNewStyle">
                      <el-select v-model="account__form.projectType" filterable placeholder="请选择项目类别">
                        <el-option
                          v-for="(item,i) in projectTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="产品名称" prop="fpproductName" label-width="80px" class="distribNewStyle tabProduct">
                      <el-select placeholder="请选择产品名称" v-model="account__form.fpproductName" filterable class="resourcesTab">
                        <el-option
                          v-for="(item,i) in fpProductNameOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <div class="tabNew">
                        <el-button type="primary" class="newQuery" size="small" @click="productNameDialog = true">增加产品名称</el-button>
                        <el-dialog 
                          title="新增产品名称" 
                          :visible.sync="productNameDialog" 
                          append-to-body 
                          :show-close = "false"
                          center
                          width="300px">
                          <el-input v-model="addproductNameValue" autocomplete="off" placeholder="请输入新增产品名称"></el-input>
                          <div slot="footer" class="dialog-footer">
                            <el-button type="primary" class="newDialogCancel" @click="cancleProductName">取 消</el-button>
                            <el-button type="primary" class="newDialog" @click="addproductName">新 增</el-button>
                          </div>
                        </el-dialog>
                      </div>
                    </el-form-item>
                  </div>
                  <div>
                    <div v-if="ruleForm.qdvalue == 'OPPO'">
                    </div>
                    <div v-else>
                      <el-form-item label="可查看人员" :label-width="formLabelWidth1">
                        <el-select v-model="qdvalueLook" :placeholder="qdvalueLookhx" filterable style="margin-right:10px;" @change="changeChannelBottom($event)">
                          <el-option
                            v-for="(item,i) in qdoptionsLook"
                            :key="i"
                            :label="item.name"
                            :value="item">
                          </el-option>
                        </el-select>
                        <el-select v-model="regionyhsBtomm" :placeholder="regionyhsBtommHx" filterable @change="getLookUser($event)">
                          <el-option
                            v-for="(item,i) in sender_options"
                            :key="i"
                            :label="item.string"
                            :value="item">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" class="newDialogCancel" @click="cancel('dangefenpei')">取消保存</el-button>
                  <el-button type="primary" class="newDialog" @click="sureTobeAllocatd('dangefenpei')">确认保存</el-button>
                </div>
              </el-dialog>
              <!-- 二代扩展账户 -->
              <el-dialog 
                title="二代扩展账户页面" 
                :visible.sync="erdaiDialogShow" 
                center
                :show-close = "false"
                width="500px">
                <div class="dialogIcon"><img :src='distrbIcon'></div>
                <el-form :model="owneredForm" :rules="owneredRules" ref="owneredForm" label-width="90px">
                  <el-form-item label="api_id" prop="apiID">
                    <el-input 
                      v-model="owneredForm.apiID" 
                      @blur="owneredForm.apiID=$event.target.value.trim()"
                      placeholder="请输入api_id">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="api_key" prop="apiKey">
                    <el-input 
                      v-model="owneredForm.apiKey" 
                      @blur="owneredForm.apiKey=$event.target.value.trim()"
                      placeholder="请输入api_key">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="广告主ID" prop="ownerId">
                    <el-input 
                      v-model="owneredForm.ownerId" 
                      @blur="owneredForm.ownerId=$event.target.value.trim()"
                      placeholder="请输入广告主ID">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="广告主名称" prop="ownerName">
                    <el-input 
                      v-model="owneredForm.ownerName" 
                      @blur="owneredForm.ownerName=$event.target.value.trim()"
                      placeholder="请输入广告主名称">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="账号类型" prop="apiType">
                    <el-select v-model="owneredForm.apiType" placeholder="请选择账号类型">
                      <el-option
                        v-for="(item,i) in apiTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <div>
                    <el-form-item label="分配部门" >
                      <el-cascader
                        v-model="owneredForm.dept"
                        :options="deptTreeed"
                        :props="props"
                        ref="cascaderAddr"
                        :show-all-levels="false"
                        placeholder="请选择分配部门"
                        @change="erdaiDept">
                      </el-cascader>
                    </el-form-item>
                    <el-form-item label="分配人" prop="apiUser">
                      <el-select v-model="owneredForm.apiUser" value-key="userId" placeholder="请选择分配人" @focus='userEd'  @change="oppoName">
                          <el-option
                          v-for="(item2,j) in oppoUser"
                          :key="j"
                          :label="item2.string"
                          :value="item2">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button type="primary" class="newDialogCancel" @click="addedfwCancle('owneredForm')">取消保存</el-button>
                  <el-button type="primary" class="newDialog" @click="addedfwSure('owneredForm')">确认保存</el-button>
                </div>
              </el-dialog>

              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="username"
                  label="公司名称"
                  fixed="left"
                  width="220">
                  <template slot-scope="scope">{{scope.row.company}}</template>
                </el-table-column>
                <el-table-column
                  prop="username"
                  label="账户名称"
                  fixed="left"
                  width="220">
                  <template slot-scope="scope">{{scope.row.name}}</template>
                </el-table-column>
                <el-table-column
                  prop="userPosition"
                  label="客户ID"
                  width="120">
                  <template slot-scope="scope">{{scope.row.customerId}}</template>
                </el-table-column>
                <el-table-column
                  prop="userManage"
                  label="广告主ID"
                  width="120">
                  <template slot-scope="scope">{{scope.row.id}}</template>
                </el-table-column>
                <el-table-column
                  prop="userHeigher"
                  label="分配部门"
                  width="280">
                  <template slot-scope="scope">
                    <span v-for="item in scope.row.userList" class="deptNameList">{{item.deptName}}</span>                  
                  </template>
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="一级行业"
                  width="100">
                  <template slot-scope="scope">{{scope.row.firstIndustryName}}</template>
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="二级行业"
                  width="100">
                  <template slot-scope="scope">{{scope.row.secondIndustryName}}</template>
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="客户类型"
                  width="80">
                  <template slot-scope="scope">
                    <span v-if="scope.row.onFlag == 0">新客</span>
                    <span v-else>老客</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="proType"
                  label="项目类别"
                  width="150">
                </el-table-column>
                <el-table-column
                  prop="proName"
                  label="产品名称"
                  width="150">
                </el-table-column>
                <el-table-column
                  prop="zqtime"
                  label="抓取时间"
                  width="150">
                  <template slot-scope="scope">{{scope.row.time | dateFormat}}</template>
                </el-table-column>
                <el-table-column
                  prop="delFlag"
                  label="数据更新开关"
                  width="150">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.delFlag"
                      active-color="#48916F"
                      inactive-color="#EAEFED"
                      active-value="0"
                      inactive-value="1"
                      @change="changeDelFlag(scope.row)"
                      >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="userOperation"
                  fixed="right"
                  label="操作"
                  width="100px"
                  show-overflow-tooltip>
                  <template slot-scope="scope">
                    <el-button class="solid-tag-success" plain size="mini" @click="tobeAllocatd(scope.row)" v-if="scope.row.userList.length == 0">待分配</el-button>
                    <el-button class="solid-tag-warning" plain size="mini" v-show="changeBtn" @click="editTobeAllocatd(scope.row)" v-else>变更</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
          </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
      <accessDialog :visible.sync="showDialog" ></accessDialog>
    </div>
  </template>
  
  <script>
  import distrbIcon from '@/assets/img/distrbIcon.png';
  import accessDialog from '@/views/dialog/accountDialog';
  import echarts from  "echarts";
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'accountList',
    components: {
      accessDialog
    },
    data() {
      return {
        distrbIcon,
        showDialog: false,
        fpstatuoptions:[{
          value: 0,
          label: '未分配'
        }, {
          value: 1,
          label: '已分配'
        }],
        ruleForm:{
          qdvalue:'',
          comName:'',
          comAccountID:'',
          fpstatuvalue:'',
          dateChoice:'',
          accountName:'',//账户名称
          firstName:'',
          secondName:'',
          customerType:'',
        },
        firstNameoptions:[],
        secondNameoptions:[],
        customerTypeoptions:[{
          value:0,
          label:'新客'
        },{
          value:1,
          label:'老客'
        }],
        tableData: [],//账户列表数据
        multipleSelection:[],
        dialogFormVisible: false,
        dialogFormVisible_account:false,
        distribution_form: {
          name: '',
          regionzu: '',
          regionshi: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          userProductName:'',
          projectName:'',
          projectType:'',
          fpproductName:''
        },
        distributionRule:{
          projectType:[
            { required: true, message: '请选择项目类别', trigger: 'change' }
          ],
          fpproductName:[
            { required: true, message: '请选择产品名称', trigger: 'change' }
          ],
        },
        productNameOptions:[],
        projectNameOptions:[],
        account__form: {
          name: '',
          regionID: '',
          regionyhs:'',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
          qdvaluefp:'',
          firstHangye:'',
          secondHangye:'',
          costumerType:'',
          addmarterialValue:[],
          projectType:'',//项目类别
          fpproductName:'',//产品名称
          // fpyhsSingle:[]
        },
        projectTypeOptions:[{
          label:'项目',
          value:'项目'
        },{
          label:'非项目',
          value:'非项目'
        }],
        addmarterialplValue:[],
        rules: {
          firstHangye:[
            { required: true, message: '请选择一级行业', trigger: 'change' }
          ],
          secondHangye:[
            { required: true, message: '请选择二级行业', trigger: 'change' }
          ],
          costumerType:[
            { required: true, message: '请选择客户类型', trigger: 'change' }
          ],
          addmarterialValue:[
            { required: true, message: '请选择素材标签', trigger: 'change' }
          ],
          projectType:[
            { required: true, message: '请选择项目类别', trigger: 'change' }
          ],
          fpproductName:[
            { required: true, message: '请选择产品名称', trigger: 'change' }
          ],

        },
        costumerTypeOptions:[{
          value:0,
          label:'新客'
        },{
          value:1,
          label:'老客'
        }],
        firstHangyeOptions:[],
        secondHangyeOptions:[],
        qdoptionsfp:[],
        formLabelWidth: '120px',
        formLabelWidth1: '90px',
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        distributionOptions:{},//分配优化师
        disAccountParams:{
          id:'',
          lookChannel:'',
          lookUserDeptId:'',
          lookUser:'',
          tagIds:'',
          userList:[
            {
              dept:'',
              deptName:'',
              userId:'',
            }
          ],
          onFlag:'',
          firstIndustryName:'',
          secondIndustryName:''
        },
        accountParams:{
          channelId:'',
          company:'',
          id:'',
          ifUser:'',
          params:{
            beginTime:'',
            endTime:''
          },
          loginUserId:'',
          accountName:'',//账户名称
          firstName:'',//一级行业
          secondName:'',//二级行业
          onFlag:'',//客户类型
        },
        deptTree:[],
        fpDeptValue:'',
        props:{
          value:'id',
          label:'label',
          children:'children',
          emitPath:false,
          checkStrictly: true
        },
        accountListParams:{
          loginUserId:'',
          channelId:'',
          company:'',
          id:'',
          ifUser:'',
          params:{
            beginTime:'',
            endTime:''
          },
        },
        channelIdChecked:'',
        regionyhsBtomm:'',
        sender_options:[],
        qdoptionsLook:[],
        qdvalueLook:'',
        qdvalueHx:'',
        bigFpParams:{
          ids:'',
          productName:'',
          productId:'',
          projectName:'',
          projectId:'',
          tagIds:'',
          userList:[
          //   {
          //   dept:'',
          //   deptName:'',
          //   userId:'',
          // }
         ]
        },
        editBigFpParams:{
          advertiserId:'',
          userList:[{
            dept:'',
            userId:''
          }]
        },
        bigFpids:[],
        hxfpzh:true,
        qdvalueLookhx:'',
        regionyhshx:[],
        regionyhsBtommHx:'',
        channelIdNew:'',
        deptIdNew:'',
        deptIdNewfp:'',
        userChannel:'',
        deptTreefp:[],
        channelIdfpNew:'',
        getProductId:'',
        newAddDeptList:[],
        fpyhsSingle:[],
        plfpyhsSingle:[],
        choiceDept:[],
        choiceDeptId:[],
        cityList:[],
        resourcesTabPoptions:[],//素材标签
        materialListParams:{
          tagType:2,
          userId:'',
          channel:''
        },
        setUserId:'',
        addTagDialog:false,//新增标签弹框
        addTagsValue:'',
        addtagParams:{
          channel:'',
          createUser:'',
          tagName:'',
          tagType:''
        },
        needUserId:'',
        zongjian:true,
        roleKey:[],
        haveTagListParams:{
          channelId:'',
          ownerId:''
        },
        haveTaglist:[],
        dataChange:'',
        changeBtn:true,//普通优化师不显示变更按钮
        zongjianDept:'',
        deptValue:'',
        distributionOptionsfp:{},
        batchDistributionOptions:{},
        hxyouhuashi:'',
        hxyouhuashiId:'',
        activeName:'OPPO',//tab选项卡的当前卡
        dialogassistVisible:false,//批量增加辅优化师
        personHide:true,
        dutrDeptId:'',//分配中从登陆接口获取的部门id
        dutrDeptName:'',//分配中从登陆接口获取的部门名称
        jingli:false,
        accountSelectionListShow:true,//用户是否有权限
        channelTitleList:[],//权限名称列表
        fpProductNameOptions:[],//产品名称
        productNameDialog:false,
        addproductNameValue:'',
        //二代
        owneredForm:{
          apiID:'',//api_id
          apiKey:'',//api_key
          ownerId:'',//广告主id
          ownerName:'',//广告主名称
          apiType:'',//账号类型
          apiUser:'',//分配人
          dept:''
        },
        owneredRules:{
          apiID: [
            { required: true, message: '请输入api_id', trigger: 'blur' },
          ],
          apiKey:[
            { required: true, message: '请输入api_key', trigger: 'blur' },
          ],
          ownerId:[
            { required: true, message: '请输入广告主ID', trigger: 'blur' }
          ],
          ownerName:[
            { required: true, message: '请输入广告主名称', trigger: 'blur' }
          ],
          apiType:[
            { required: true, message: '请选择账号类型', trigger: 'change' }
          ],
          apiUser:[
            { required: true, message: '请选择分配人', trigger: 'change' }
          ],
        },
        erdaiDialogShow:false,
        apiTypeOptions:[
          {
            value:1,
            label:'代理商'
          },
          {
            value:2,
            label:'广告主'
          }
        ],
        oppoUser:[],//
        opposId:'',
        opposName:'',
        erdaiparams:{
          infos:[{
            apiId:'',
            apiKey:'',
            creater:'',
            ownerId:'',
            ownerName:'',
            type:'',
            userId:'',
            userName:''
          }]
        },
        erdaizj:false,
        deptTreeed:[]
        
      }
    },

    mounted() {
      console.log('小米')
      this.setUserId = this.$sessionStorage.getItem(config.USERID) || 1
      this.dutrDeptId = this.$sessionStorage.getItem(config.DEPTID)
      this.dutrDeptName = this.$sessionStorage.getItem(config.DEPTNAME)
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.getRole()
      this.getChannelList()
      this.getuserChannel()
      this.getIndustrySelect()
      this.fpchaxunAccountProduct()
    },
    methods: {
      //获取角色权限
      getRole(){
        let that = this
        $http.axios.get("/api/system/user/getInfo").then(function(response) {
          if(response.data.code == 200){
            that.roleKey = response.data.roles
            that.roleKey.forEach(function(e,i){
              //总监
              if(e.indexOf('director') !=-1){
                that.zongjian = false
                that.erdaizj = true
              }else{
                that.zongjian = true
                that.erdaizj = false
              }
              //普通优化师
              if(e.indexOf('person') !=-1){
                that.changeBtn = false
                that.personHide = false
              }else{
                that.changeBtn = true
                that.personHide = true
              }
              //经理
              if(e.indexOf('leader') !=-1){
                that.jingli = true
                that.erdaizj = true
              }else{
                that.jingli = false
                that.erdaizj = false
              }
              if(e.indexOf('director') !=-1||e.indexOf('leader') !=-1){
                that.erdaizj = true
              }else{
                that.erdaizj = false
              }
            })
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //头部表单重置
      resetForm(ruleForm) {
        this.$refs[ruleForm].resetFields();
        this.$router.go(0)
      },

      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.company.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      handleSelect(item) {
        console.log(item);
      },

      //获取账户列表
      getAccountList(page){
        let that = this
        if(page == 1){
          that.pageNum = 1
        }
        that.accountParams.company = String(that.ruleForm.comName)
        that.accountParams.id = String(that.ruleForm.comAccountID)
        that.accountParams.params.beginTime = String(that.ruleForm.dateChoice[0])
        that.accountParams.params.endTime = String(that.ruleForm.dateChoice[1])
        that.accountParams.loginUserId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        that.accountParams.accountName = String(that.ruleForm.accountName)
        that.accountParams.onFlag = that.ruleForm.customerType
        $http.axios.post("/api/channel/account/list?pageNum="+that.pageNum+"&pageSize="+that.pageSize,that.accountParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取渠道列表
      getChannelList(){
        let that = this
        $http.axios.get("/api/system/user/getChannelList").then(function(response) {
          if(response.data.code == 200){
            that.qdoptionsLook = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取渠道id
      changeChannel(event){
        this.qdvalue = event.name
        this.ruleForm.qdvalue = event.name
        this.accountParams.channelId = String(event.id)
        this.accountListParams.channelId = String(event.id)
        this.channelIdNew = event.id
      },

      //获取分配状态
      changeFpStatus(event){
        this.accountParams.ifUser = String(event)
        this.accountListParams.ifUser = String(event)
      },

      //获取分配状态
      changeUserStatue(event){
        this.accountParams.ifUser = String(event)
        this.accountListParams.ifUser = String(event)
      },

      //查询账户列表
      queryList(){
        this.accountParams.company = String(this.ruleForm.comName)
        this.accountParams.id = String(this.ruleForm.comAccountID)
        this.accountParams.params.beginTime = String(this.ruleForm.dateChoice[0])
        this.accountParams.params.endTime = String(this.ruleForm.dateChoice[1])
        this.accountParams.loginUserId = String(this.$sessionStorage.getItem(config.USERID) || 1)
        let that = this
        $http.axios.post("/api/channel/account/list?pageNum="+that.pageNum+"&pageSize="+that.pageSize,that.accountParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = []
            that.tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
          if(response.data.data!=[]){
            that.channelTitleList = response.data.data
          //    that.channelTitleList = [{'id':3,name:'OPPO'},
          //  {'id':4,name:'VIVO'},
          //  {'id':5,name:'Mi'}]     
           that.accountSelectionListShow=true//用户是否有权限
       
            that.ruleForm.qdvalue = response.data.data[0].name
            that.channelIdNew = response.data.data[0].id
            that.accountListParams.channelId = response.data.data[0].id
            that.accountParams.channelId = response.data.data[0].id
            that.materialListParams.channel = response.data.data[0].id
            that.addtagParams.channel = response.data.data[0].id
            // if(that.channelTitleList.id!=3){
            //   let channelNameList = {4:'VivoAccountManagement.AccountListVivo',5:'MiAccountManagement.AccountListMi'}
            //   that.$router.push({name:channelNameList[that.channelTitleList[0].id]})
            // }else{
            //   that.getAccountList()
            // }
          let flag = that.channelTitleList.every((obj=>{
           if(obj.id == 3){
             return false
           }else{
             return true
           }
          }))
          if(flag){
            let channelNameList = {4:'VivoAccountManagement.AccountListVivo',5:'MiAccountManagement.AccountListMi',6:'HwAccountManagement.AccountListHw',7:'YybAccountManagement.AccountListYyb'}
            that.$router.push({name:channelNameList[that.channelTitleList[0].id]})
          }else{
            that.getAccountList()
          }
          }else{
            that.accountSelectionListShow = false
            that.$message({
              showClose: true,
              message: "您的操作权限不足",
              type: 'error'
            })
          }
        })
      },
      //channel的tab切换
      clickChangeChannel(channelId){
        let channelNameList = {4:'VivoAccountManagement.AccountListVivo',5:'MiAccountManagement.AccountListMi',6:'HwAccountManagement.AccountListHw',7:'YybAccountManagement.AccountListYyb'}
        this.$router.push({name:channelNameList[channelId]})
      },
      //获取channel的id
      changeChannelBottom(event){
        this.qdvalueLook = event.name
        this.channelIdChecked = event.id
        this.disAccountParams.lookChannel = event.id
        this.regionyhsBtomm = ''
        this.getLookPerson()
      },

      //产品名称下拉
      fpchaxunAccountProduct(){
        let that = this
        $http.axios.get("/api/channel/account/productSelect").then(function(response) {
          that.fpProductNameOptions = response.data
        })
      },

       //新增产品名称
       addproductName(){
        let that = this
        $http.axios.get("/api/channel/account/insertProduct?pName="+that.addproductNameValue).then(function(response) {
          if(response.data.code == 200){
            console.log(response)
            that.productNameDialog = false
            that.fpchaxunAccountProduct()
            that.addproductNameValue = ''
          } else {
            that.$message({
              showClose: true,
              // message: response.data.msg,
              message:'产品名称重复',
              type: 'error'
            })
          }
        })
      },

      //取消新增产品名称
      cancleProductName(){
        this.addproductNameValue = ''
        this.productNameDialog = false
      },

      //可查看的人员
      getLookPerson(){
        let that = this
        $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId="+that.channelIdChecked).then(function(response) {
          if(response.data.code == 200){
            that.sender_options = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取批量分配账户的分配部门id
      deptTreeDetail(event){
        // this.deptIdNew = event
        this.getFpList(event)
      },

      deptTreefpDetail(event){
        console.log(event)
        this.deptIdNewfp = event
        let that = this
        if(that.zongjian == false){
          that.disAccountParams.userList.forEach(function(e,i){
            if(e.dept == 298){
              e.deptName = '华北'
              e.userId = 282
            }else if (e.dept == 297){
              e.deptName = '华南'
              e.userId = 300
            }
          })
        }
        that.roleKey.forEach(function(e,i){
          if(e.indexOf('leader') !=-1){
            that.disAccountParams.userList.forEach(function(e,i){
            if(e.dept == 298){
              e.deptName = '华北'
            }else if (e.dept == 297){
              e.deptName = '华南'
            }
          })
          }else{
          }
        })

        this.getFpsingleList(event)
      },
      
      //打开批量分配弹框
      openDistribution(){
        let that = this
        that.materialTab()
        that.bigFpids = []
        that.plfpyhsSingle = []
        // that.account__form.fpyhsSingle = []
        let modifySelection = that.$refs.multipleTable.selection
        modifySelection.forEach(function(e,i){
          that.bigFpids.push(e.id)  
        })
        if(that.ruleForm.qdvalue == ''){
          that.$message('请选择渠道名称')
          that.dialogFormVisible = false
        }else{
          that.dialogFormVisible = true
        }
        if(that.bigFpids.length == 0){
          that.$message({
            message: '请勾选账户',
            type: 'warning'
          });
          that.dialogFormVisible = false
        }else{
          that.dialogFormVisible = true
        }
        // that.qdvalueHx = that.ruleForm.qdvalue
        that.qdvalueHx = 'OPPO'
        that.fpDeptValue = ''
        that.distribution_form.regionshi = ''
        that.getDeptTree()
        that.getProduct()
        // //经理
        that.roleKey.forEach(function(e,i){
          if(e.indexOf('leader') !=-1){
            that.bigFpParams.userList[0] = {
              dept:that.dutrDeptId,
              deptName:that.dutrDeptName,
              userId:''
            }
            that.getFpList(that.dutrDeptId)
          }else{
            that.bigFpParams.userList[0] = {
              dept:'',
              deptName:'',
              userId:''
            }
          }
        })
      },

      //获取批量分配中的分配部门下拉框
      getDeptTree(){
        let that = this
        $http.axios.get("/api/system/dept/treeselect?channelId="+that.channelIdNew).then(function(response) {
          if(response.data.code == 200){
            that.deptTree = response.data.data
            that.deptTreefp = response.data.data
            that.deptTreeed = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取批量分配中的分配部门下拉框
      getDeptfpTree(){
        let that = this
        $http.axios.get("/api/system/dept/treeselect?channelId="+that.channelIdfpNew).then(function(response) {
          if(response.data.code == 200){
            that.deptTreefp = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //批量分配新增
      newAddListplBtn(){
        // this.bigFpParams.userList.push({})
        this.distributionOptions = {}
        let that = this
        //经理
        that.roleKey.forEach(function(e,i){
          if(e.indexOf('leader') !=-1){
            that.bigFpParams.userList.push({
              dept:that.dutrDeptId,
              deptName:that.dutrDeptName,
              userId:''
            })
            that.getFpList(that.dutrDeptId)
            console.log(that.bigFpParams.userList)
          }else{
            that.bigFpParams.userList.push({
              dept:'',
              deptName:'',
              userId:''
            })
          }
        })
      },

      //批量增加辅优化师
      batchAddBtn(){
        this.batchDistributionOptions = {}
        this.editBigFpParams.userList.push({})
      },

      //批量分配删除
      removeRowpl(index) {
        if (index >= 0) {
          this.bigFpParams.userList.splice(index, 1);
          this.plfpyhsSingle.splice(index, 1)
          // this.account__form.fpyhsSingle.splice(index, 1)
        }
      },
      removeRowplzj(index){
        if (index >= 0) {
          this.editBigFpParams.userList.splice(index, 1);
        }
      },

      //获取分配人员详情
      bigFpChange(event,i){
        let that = this
        that.bigFpParams.userList[i].deptName = String(event.depyName)
        that.bigFpParams.userList[i].userId = String(event.userId)
        that.plfpyhsSingle[event.userId] = String(event.string)
        // that.account__form.fpyhsSingle[i] = String(event.string)
        
        if(i == 0){
          that.bigFpParams.userList[0].main = 1
        }else{
          that.bigFpParams.userList[i].main = 0
        }
      },

      //客户名称下拉框
      getProduct(){
        let that = this
        $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
          if(response.data.code == 200){
            that.productNameOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //项目名称下拉框
      getProject(){
        let that = this
        $http.axios.get("/api/operationManagement/materialoperation/getallProject?productId="+that.getProductId).then(function(response) {
          if(response.data.code == 200){
            that.projectNameOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取客户名称详情
      changeProduct(event){
        this.distribution_form.userProductName = event.productName
        this.getProductId = event.id
        this.distribution_form.projectName = ''
        this.bigFpParams.productId = event.id
        this.bigFpParams.productName = event.productName
        if(this.distribution_form.userProductName){
          this.getProject()
        }else{
          this.getProductId = ''
          this.getProject()
        }
      },

      //获取项目名称详情
      changeProject(event){
        this.distribution_form.projectName = event.projectName
        this.bigFpParams.projectId = event.id
        this.bigFpParams.projectName = event.projectName
      },
      focusProject(){
        if(this.getProductId == null){
          this.$message('请选择客户名称');
        }else{
          this.getProject()
        }
      },

      //批量分配---确定
      bigSureFp(piliangfenpei){
        let that = this
        that.bigFpParams.proType = that.distribution_form.projectType
        that.bigFpParams.proId = that.distribution_form.fpproductName
        that.bigFpParams.ids = String(that.bigFpids)
        that.$refs.piliangfenpei.validate((valid) => {
          if (valid) {
            that.roleKey.forEach(function(e,i){
              if(e.indexOf('leader') != -1){
                let flag = that.bigFpParams.userList.every(obj=>{
                  console.log(obj)
                  if(obj.userId == ''){
                    that.$message({
                      showClose: true,
                      message: '请选择分配优化师',
                      type: 'error'
                    })
                    return false
                  }else{
                    return true
                  }
                })
                if(flag){
                  $http.axios.post("/api/channel/account/editBatch",that.bigFpParams).then(function(response) {
                    if(response.data.code == 200){
                      that.dialogFormVisible = false
                      that.getAccountList()
                    } else {
                      that.$message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'error'
                      })
                    }
                  })
                }
                // that.$router.go(0)
              }else{
                $http.axios.post("/api/channel/account/editBatch",that.bigFpParams).then(function(response) {
                  if(response.data.code == 200){
                    that.dialogFormVisible = false
                    that.getAccountList()
                  } else {
                    that.$message({
                      showClose: true,
                      message: response.data.msg,
                      type: 'error'
                    })
                  }
                })
              }
            })
          } else {
            return false;
          }
        })
      },

      //批量分配的弹框取消
      plcancle(){
        this.dialogFormVisible = false
        this.$router.go(0)
      },

      //二代部门选择
      erdaiDept(val){
        this.getFpList(val)
      },

      //获取分配人员列表
      getFpList(deptId){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList?deptId="+deptId).then(function(response) {
          if(response.data.code == 200){
            // that.distributionOptions = response.data.data
            that.distributionOptions[deptId] = response.data.data 
            that.oppoUser = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取分配人员列表
      getFpsingleList(deptId){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList?deptId="+deptId).then(function(response) {
          if(response.data.code == 200){
            that.distributionOptionsfp[deptId] = response.data.data 
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //点击待分配按钮-回显
      tobeAllocatd(row){
        let that = this
        that.dialogFormVisible_account = true
        that.disAccountParams.userList=[{
          dept:'',
          deptName:'',
          userId:''
        }]
        that.distributionOptionsfp = {}
        that.materialTab()
        that.getIndustry()//一二级行业
        that.hxfpzh = true
        if(that.hxfpzh == true){
          that.account__form.regionyhs = ''
          that.qdvalueLook = ''
          that.regionyhsBtomm = ''         
          that.account__form.name = row.company
          that.account__form.regionID = row.id
          that.disAccountParams.id = String(row.id)
          that.qdvalueLookhx = '请选择渠道名称'
          that.regionyhshx = '请选择分配优化师'
          that.regionyhsBtommHx = '请选择分配优化师'
          that.channelIdfpNew = row.channelId
          that.fpyhsSingle = []
          // that.account__form.fpyhsSingle = []
          
          that.choiceDept = []
          that.getDeptfpTree()
        }
      },

      //点击变更按钮--回显
      editTobeAllocatd(row){
        let that = this
        that.materialTab()
        that.getIndustry()//一二级行业
        that.getHaveTaglist(row.id)
        that.hxfpzh = false //判断是变更还是待分配
        that.dialogFormVisible_account = true //分配账户弹框显示
        if(that.hxfpzh == false){
          that.account__form.name = row.company
          that.account__form.regionID = row.id
          that.qdvalueLookhx = row.channel
          that.regionyhsBtommHx = row.lookUser
          that.disAccountParams.id = String(row.id)
          that.disAccountParams.lookUserDeptId = row.lookUserDeptId
          that.disAccountParams.lookChannel = row.channel
          that.channelIdfpNew = row.channelId
          that.getDeptfpTree()
          let userList = row.userList
          that.disAccountParams.userList.length = userList.length
          that.disAccountParams.userList = []
          userList.forEach(function(e,i){
            if(i == 0){
              that.disAccountParams.userList.push({
                dept:e.dept,
                deptName:e.deptName,
                userId:e.userId,
                main:1
              })
            }else{
              that.disAccountParams.userList.push({
                dept:e.dept,
                deptName:e.deptName,
                userId:e.userId,
                main:0
              })
            }
            that.fpyhsSingle[e.userId] = row.channel+'-'+e.username
            // that.account__form.fpyhsSingle[e.userId] = row.channel+'-'+e.username
            
            // that.fpyhsSingle[i] = row.channel+'-'+e.username
            that.choiceDept[i] = e.deptName
            that.getFpsingleList(e.dept)
          })
          if(row.onFlag == '0'){
            that.account__form.costumerType = 0
          }else if(row.onFlag == '1'){
            that.account__form.costumerType = 1
          }
          that.account__form.projectType = row.proType //项目类别
          that.account__form.fpproductName = row.proId
          // that.disAccountParams.proType = row.proType
          // that.disAccountParams.proId = row.proId
          that.disAccountParams.onFlag = row.onFlag
          that.account__form.firstHangye = row.firstIndustryName
          that.account__form.secondHangye = row.secondIndustryName
          that.disAccountParams.firstIndustryName = row.firstIndustryName
          that.disAccountParams.secondIndustryName = row.secondIndustryName
        }
      },

      //获取素材标签回显
      getHaveTaglist(ownerId){
        let that = this
        that.haveTagListParams.channelId = 3
        that.haveTagListParams.ownerId = ownerId
        $http.axios.post("/api/operationManagement/accountTag/getView",that.haveTagListParams).then(res=> {
          if(res.data.code == 200){
            that.haveTaglist = res.data.data
            that.account__form.addmarterialValue = []
            let newtagIds = []
            that.haveTaglist.forEach(function(e,i){
              that.account__form.addmarterialValue.push(e.id)
              newtagIds.push(e.id)
            })
            that.disAccountParams.tagIds = String(newtagIds)
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //change一级行业
      firstGong(val){
        let that = this
        that.account__form.secondHangye = ''
        that.disAccountParams.firstIndustryName = val
        that.firstHangyeOptions.forEach(function(e,i){
          if(e.name == val){
            that.secondHangyeOptions = e.secondList
          }
        })
      },
      firstGongname(val){
        let that = this
        that.ruleForm.secondName = ''
        that.accountParams.firstName = val
        that.firstNameoptions.forEach(function(e,i){
          if(e.name == val){
            that.secondNameoptions = e.secondList
          }
        })
      },
      //change二级行业
      secondGong(val){
        this.disAccountParams.secondIndustryName = val
      },
      secondGongname(val){
        this.accountParams.secondName = val
      },

      //一级二级行业接口
      getIndustry(){
        let that = this
        $http.axios.get("/api/channel/account/industrySelect").then(res=> {
          that.firstHangyeOptions = res.data
        })
      },
      getIndustrySelect(){
        let that = this
        $http.axios.get("/api/channel/account/industrySelect").then(res=> {
          that.firstNameoptions = res.data
        })
      },

      //单个分配取消
      cancel(dangefenpei){
        this.dialogFormVisible_account = false
        this.$refs.dangefenpei.resetFields()
      },

      //弹框中的分配优化师获取数据
      changeOptimizer(event,i){
        let that = this
        that.disAccountParams.userList[i].deptName = String(event.depyName)
        that.disAccountParams.userList[i].userId = String(event.userId)
        that.fpyhsSingle[event.userId] = String(event.string)
        // that.account__form.fpyhsSingle[event.userId] = String(event.string)
        
        that.needUserId = event.userId
        if(i == 0){
          that.disAccountParams.userList[0].main = 1
        }else{
          that.disAccountParams.userList[i].main = 0
        }
      },

      //分配优化师失去焦点之后提示
      bluryhs(val){
        console.log(123456)
        let that = this
        console.log(val)
      },

      //change客户类型
      changeCustomer(val){
        this.disAccountParams.onFlag = String(val)
      },

      //分配账户弹框点击确定按钮
      sureTobeAllocatd(dangefenpei){
        let that = this
        that.disAccountParams.proType = that.account__form.projectType //项目类别
        that.disAccountParams.proId = that.account__form.fpproductName //产品名称
        that.roleKey.forEach(function(e,i){
          if(e.indexOf('director') !=-1){
            $http.axios.put("/api/channel/account",that.disAccountParams).then(function(response) {
              if(response.data.code == 200){
                that.dialogFormVisible_account = false
                that.$refs.dangefenpei.resetFields()
                that.getAccountList()
              } else {
                that.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: 'error'
                })
              }
            })
          }else if(e.indexOf('leader') != -1){
            // let flag
            that.$refs.dangefenpei.validate((valid) => {
              if (valid) {
                let flag = that.disAccountParams.userList.every(obj=>{
                  console.log(obj)
                  if(obj.userId == ''){
                    that.$message({
                      showClose: true,
                      message: '请选择分配优化师',
                      type: 'error'
                    })
                    return false
                  }else{
                    return true
                  }
                })
                if(flag){
                  $http.axios.put("/api/channel/account",that.disAccountParams).then(function(response) {
                    if(response.data.code == 200){
                      that.dialogFormVisible_account = false
                      that.$refs.dangefenpei.resetFields()
                      that.getAccountList()
                    }else {
                      that.$message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'error'
                      })
                    }
                  })
                }
              } else {
                return false;
              }
            })
          }else{
            that.$refs.dangefenpei.validate((valid) => {
              if (valid) {
                $http.axios.put("/api/channel/account",that.disAccountParams).then(function(response) {
                  if(response.data.code == 200){
                    that.dialogFormVisible_account = false
                    that.$refs.dangefenpei.resetFields()
                    that.getAccountList()
                  } else {
                    that.$message({
                      showClose: true,
                      message: response.data.msg,
                      type: 'error'
                    })
                  }
                })
              } else {
                return false;
              }
            })
          }
        })
        
      },

      //新增分配部门和分配优化师
      newAddListBtn(){
        this.distributionOptionsfp = {}
        // this.disAccountParams.userList.push({
        //   dept:'',
        //   deptName:'',
        //   userId:''
        // })
        let that = this
        //经理
        that.roleKey.forEach(function(e,i){
          if(e.indexOf('leader') !=-1){
            that.disAccountParams.userList.push({
              dept:that.dutrDeptId,
              deptName:that.dutrDeptName,
              userId:''
            })
            that.getFpsingleList(that.dutrDeptId)
          }else{
            that.disAccountParams.userList.push({
              dept:'',
              deptName:'',
              userId:''
            })
          }
        })
      },

      //删除分配部门和分配优化师
      removeRow(index) {
        console.log(index)
        if (index >= 0) {
          this.disAccountParams.userList.splice(index, 1);
          // this.fpyhsSingle.splice(index, 1)
        }
      },

      //获取查看人员信息
      getLookUser(event){
        this.disAccountParams.lookUserDeptId = event.deptId
        this.disAccountParams.lookUser = event.userId
        this.regionyhsBtomm = event.string
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      goToDetail(){
        this.$router.replace('/auth-management/userDetail')
      },

       //素材样式标签列表接口
      materialTab(){
        let that = this
        that.materialListParams.userId = that.setUserId
        $http.axios.post("/api/operationManagement/material/taglist",that.materialListParams).then(res=> {
          if(res.data.code == 200){
            that.resourcesTabPoptions = res.data.data
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //新增标签
      addTags(){
        let that = this
        that.addtagParams.createUser = that.setUserId
        that.addtagParams.tagName = that.addTagsValue      
        that.addtagParams.tagType = 2
        $http.axios.post("/api/operationManagement/material/addtag",that.addtagParams).then(res=> {
          if(res.data.code == 200){
            that.addTagDialog = false
            that.materialTab()
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //change标签
      materialTagIds(val){
        this.bigFpParams.tagIds = String(val)
      },
      materialTagIdsSingle(val){
        this.disAccountParams.tagIds = String(val)
      },

      //change数据更新开关
      changeDelFlag(val){
        let that = this
        $http.axios.get("/api/channel/account/delSwitch?id="+val.id+"&type="+val.delFlag).then(res=> {
          if(res.data.code == 200){
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //批量增加辅优化师
      batchDistriAssist(){
        let bigFpids = [],userList = [],that = this, modifySelection = that.$refs.multipleTable.selection
        console.log(modifySelection)
        modifySelection.forEach(function(e,i){
          bigFpids.push(e.id)  
          that.editBigFpParams.advertiserId = String(bigFpids)
          userList = e.userList
        })
        if(bigFpids.length == 0){
          that.$message({
            message: '请勾选账户',
            type: 'warning'
          });
          that.dialogassistVisible = false
        }else{
          if(userList.length == 0){
            that.$message({
              message: '勾选的分配状态错误',
              type: 'warning'
            })
          }else{
            that.dialogassistVisible = true
            that.getDeptTree()
          }
        }
      },

      //批量增加辅优化师
      batchDept(event){
        this.getbatchDeptList(event) 
      },

      //获取分配人员列表
      getbatchDeptList(deptId){
        let that = this
        $http.axios.get("/api/system/user/getAccountAppendList?deptId="+deptId).then(function(response) {
          if(response.data.code == 200){
            that.batchDistributionOptions[deptId] = response.data.data 
            console.log(that.batchDistributionOptions[deptId])
            console.log(that.batchDistributionOptions)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //批量增加辅助优化师确定按钮
      batchBistribution(){
        let that = this
        $http.axios.post("/api/channel/account/editSubBatch",that.editBigFpParams).then(function(response) {
          if(response.data.code == 200){
            that.dialogassistVisible = false
            that.getAccountList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },


      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        var params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.findAll(params)
      },
      //分页方法
      findAll (params) {
        //获取数据接口（调用封装的接口）
        this.getAccountList()
      },

      //分配人获取焦点
      userEd(){
        if(this.owneredForm.dept == ''){
          this.$message.error('请先选择分配部门');
          // owneredForm.apiUser
        }
      },

      //二代
      batchErdai(){
        this.erdaiDialogShow = true
        this.getDeptTree()
      },

      //二代服务类页面取消
      addedfwCancle(owneredForm){
        this.erdaiDialogShow = false
        this.$refs[owneredForm].resetFields()
        this.owneredForm.dept = ''
        this.oppoUser = ''
      },

      //获取分配人信息
      oppoName(val){
        console.log(val)
        this.opposId = val.userId
        this.opposName = val.string
      },

      //二代服务类页面
      addedfwSure(owneredForm){
        let that = this,params = [],infos = []
        that.$refs[owneredForm].validate((valid) => {
          if (valid) {
            that.erdaiparams.infos = [{
              apiId : that.owneredForm.apiID,
              apiKey : that.owneredForm.apiKey,
              creater : that.userName,
              ownerId : that.owneredForm.ownerId,
              ownerName : that.owneredForm.ownerName,
              type : that.owneredForm.apiType,
              userId : that.opposId,
              userName : that.opposName
            }]
            $http.axios.post("/api/advertisement/extendAccount/insertBatch",that.erdaiparams).then(function(response) {
              if(response.data.code == 200){
                that.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: 'success'
                })
                that.erdaiDialogShow= false
                that.$refs[owneredForm].resetFields()
                that.owneredForm.dept = ''
                that.oppoUser = ''
                that.getAccountList()
              } else {
                that.erdaiDialogShow= true
                that.$message({
                  showClose: true,
                  message: response.data.msg,
                  type: 'error'
                })
              }
            })
          } 
        })
      },

    }
  }
  </script>
  <style lang="scss" scoped>
    .userTable{
      margin-top:25px;
    }
    .userTable .el-table .cell{
      text-align:center;
    }
    .el-table th{
      color:#1b1f23;
      background:$poc-color-primary-deputy;
    }
    .footer_paging{
      margin:20px 0 4px;
    }
    .footer_totalNums{
      font-size:12px;
      color:#666;
      padding-left:5px;
      padding-right:20px;
    }
    .footerPaging {
      float:right;
    }
    .userBtn{
      padding:0 5px;
      cursor:pointer;
    }
    .userBtn_delete{
      color:red;
    }
    .distributionAccount .el-form-item,.fpAccounts .el-form-item{
      display:block;
    }
    .el-form-item--mini.el-form-item{
      margin: 0 5px 18px;
    }
    .deptNameList{
      padding:0 5px;
    }
    .newAddfpStyle{
      position: relative;
    }
    .newReduceBtnStyle{
      position:absolute;
      top:50%;
      left:250px;
      transform: translate(0,-50%);
    }
    .newAddBtnStyle{
      margin-left:60px;
      margin-bottom:20px;
    }
    .redstar{
      position: relative;
    }
    .starStyle{
      position:absolute;
      color:red;
      font-size:10px;
      left:5px;
      top:10px;
    }
    /* oppo和vivo的tab切换 */
    .chanelTab{
      margin-bottom:20px;
      width:100%;
      border-bottom:1px solid #ddd;
    }
    .channel_oppo,.channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width:80px;
      height:50px;
      line-height:50px;
      text-align:center;
      margin-bottom:-1px; 
      cursor: pointer;
    }
    .channel_vivo{
      margin-left:-1px;
    }
    .channelActive{
      font-weight:700;
      color:#48916F;
      border-bottom:2px solid #48916F;
    }
    /* 新版 */
    .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    ::v-deep .userList .el-dialog__header{
      padding-top:45px!important;
      font-size:14px!important;
      font-weight:500!important;
    }
    .distribNewStyle{
      background:#F5F7F6;
      padding:10px;
      box-sizing: border-box;
    }
    ::v-deep .distribNewStyle .el-input__inner{
      border:none!important;
      width: 420px;
    }
    .newNetStyle{
      position: relative;
      box-sizing: border-box;
      border:1px dashed #DCEEE8;
      padding:10px;
      border-radius:4px;
      margin:10px 0;
    }
    .newNetStyleIn_inline{
      display:inline-block;
      vertical-align: middle;
      width:50%;
      background:#F5F7F6;
      padding:10px;
      box-sizing: border-box;
    }
    ::v-deep .newNetStyle .el-form-item{
      margin:0!important;
    }
    .newRedStar{
      display: inline-block;
      vertical-align: middle;
      width:65px;
    }
    .newRedStar i,.newRedStar span{
      display: inline-block;
      vertical-align: middle;
      font-size:12px;
      color:#330000;
    }
    .newRedStar i{
      color:red;
      padding-right:5px;
    }
    .addConditions{
      width:100%;
      height: 27px;
      line-height:27px;
      text-align:center;
      border-radius: 4px;
      border: 1px dashed #DCEEE8;
      font-size:12px;
      color:#48916F;
      margin-bottom:15px;
      cursor: pointer;
    }
    .deleteBtn{
      position: absolute;
      top:-10px;
      right:-10px;
      cursor: pointer;
    }
    .deleteBtnicon{
      color:#f12323;
      font-size:20px;
    }
    .tabdistribNewStyle{
      padding-left:27px;
    }
    .tabstarStyle{
      left:35px;
    }
    .tabNew{
      display: inline-block;
    }
    ::v-deep .tabdistribNewStyle .el-input__inner{
      width:335px!important;
    }
    ::v-deep .tabProduct .el-input__inner{
      width:310px!important;
    }
    .topDistance{
      padding-top:400px;
    }
  
  </style>
