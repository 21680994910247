<template>
  <el-dialog
    title="自定义列"
    center
    :visible.sync="columnVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="700px">
    <div class="columnWrap">
      <div class="zdyicon"><img :src='ZDYLicon'></div>
      <div style="text-align: center">
        <el-transfer
          style="text-align: left; display: inline-block"
          v-model="customValue"
          filterable
          class="transfer-high transferStyle"
          :titles="['全部列', '选中列']"
          :render-content="renderFunc"
          :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}'
          }"
          @change="handleChange"
          :data="adPlanData">

        </el-transfer>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" class="newDialogCancel" @click="columnVisibleFn">取消保存</el-button>
      <el-button type="primary" class="newDialog" @click="saveCustomColumns">确定保存</el-button>
    </span>
  </el-dialog>  
  </template>
  <script>
    import ZDYLicon from '@/assets/img/ZDYLicon.png';
    import Api from "@/api";
    import config from '@/api/config';
    import { $http } from '@/api/http';
    import alertMsgFn from '@/assets/js/alert';
    export default {
      name: 'custom',
      props:{
        pubVue: {
        type: Object
      },
      typeName:Number
      },
      data() {
        return {
          ZDYLicon,
          userId:'',
          userName:'',
          deptId:'',
          deptName:'',
          ownerId:'',
          columnVisible:this.visible,//生成模版弹框隐藏显示
          adPlanData: [],
          renderFunc(h, option) {
            return <span>{ option.label }</span>;
          },
          customValue:[],//选中的自定义列
          chooseCustom:'',//选中的自定义列字符串集合
          type:'',//自定义类型-1-计划系列 2-广告组 3-广告 4-关键词 5-否定关键词 6-搜索词
          chooseCustomArr:[],
          chooseCustomList:[],
  
        };
      },
      mounted() {
        this.userId = this.$sessionStorage.getItem(config.USERID) || 1
        this.userName = this.$sessionStorage.getItem(config.USERNAME)
        this.deptName = this.$sessionStorage.getItem(config.DEPTNAME) 
        this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
      },
      methods: {
        //弱提示
         alertMsgFn(msg,type){
          this.$message({
            showClose: true,
            message: msg,
            type: type
          })  
        },

        //取消
        columnVisibleFn(){
          this.columnVisible = false
          this.customValue = []
        },

        //广告系列等弹框显示
        generateTimes(num,ownerId){
          console.log(num)
          this.columnVisible = true//弹框显示
          this.allWords(num)
          this.type = num
          this.ownerId = ownerId
          this.userSetCustom()
        },

        //查询全部自定义列字段
        allWords(type){
          let _this = this,adPlanData = []
          $http.axios.post("/api/apple/CustomColumn/getALLColumns?customType="+type).then(res=> {
            if(res.data.code == 200){
              adPlanData = res.data.data
              _this.adPlanData = []
              adPlanData.forEach((obj,i) => {
                _this.adPlanData .push({
                  key:obj.id,
                  label:obj.name,
                  disabled:false
                })
              });
            }  else {
              _this.alertMsgFn(res.data.msg,'error')
            }
          })
        },

        //change选中
        handleChange(value) {
          let chooseCustom = []
          chooseCustom = value
          this.chooseCustom = String(chooseCustom)
          // this.chooseCustomList = value
        },

        //保存自定义列的设置
        saveCustomColumns(){
          let  _this = this
          $http.axios.post("/api/apple/CustomColumn/addOrgCustom?customType="+_this.type+"&orgId="+_this.ownerId+"&columnIds="+_this.chooseCustom).then(res=> {
            if(res.data.code == 200){
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'success'
              })
              _this.columnVisible = false
              _this.customValue = []
              if(_this.type == 1){
                _this.$parent.getAccountPlanListFn()
              }else if(_this.type == 2){
                _this.$parent.grouplistFn()
              }else if(_this.type == 3){
                _this.$parent.advertListFn()
              }else if(_this.type == 4){
                _this.$parent.getKwList()
              }else if(_this.type == 6){
                _this.$parent.getSearchList()
              }
            }  else {
              _this.alertMsgFn(res.data.msg,'error')
            }
          })
        },

        //用户已经设置的自定义列
        userSetCustom(){
          let _this = this,ownerId='',type=''
          type = Number(_this.type)
          ownerId = Number(_this.ownerId)
          $http.axios.post("/api/apple/CustomColumn/getColumnsByOrgId?customType="+type+"&orgId="+ownerId).then(res=> {
            if(res.data.code == 200){
              _this.chooseCustomArr = res.data.data
              _this.customValue = []
              if(res.data.data != null){
                _this.chooseCustomArr.forEach((val)=>{
                  _this.customValue.push(val.id)
                })
              }
            }  else {
              _this.alertMsgFn(res.data.msg,'error')
            }
          })
        },

      }
    }
    </script>
    
    <style lang="scss" scoped>
      ::v-deep .el-transfer.transfer-high{
        .el-transfer-panel{
          &__header{

          }
          &__body{
            height: 500px !important;
            .el-transfer-panel__list{
              height: 500px - 60 !important;
            }
          }
        }
      }
      .zdyicon{
        position: absolute;
        top:0;
        left:50%;
        margin-left:-35px;
        margin-top:-35px;
      }
      ::v-deep .el-transfer-panel{
        background:#F9FBFB!important;
        width:225px!important;
      }
      ::v-deep .el-transfer-panel .el-transfer-panel__header{
        background:#F1F7F5!important;
      }
      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner{
        background-color:rgba(72,145,111,0.5)!important;
        border: none!important;
      }
      ::v-deep .is-checked{
        background:#F1F7F5!important;
        margin-right:0!important;
      }
      ::v-deep .transferStyle .el-button--primary{
        padding:0 10px!important;
      }
     



    </style>
    