<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/auth-management/materialList' }">运营管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/materialList' }">素材库</el-breadcrumb-item>
          <el-breadcrumb-item>详情页</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <ul class="videoWrap">
              <li>
                <label>渠道名称</label>
                <div class="videoDetailLi" v-if="detailData.channelId==1">今日头条</div>
                <div class="videoDetailLi" v-else-if="detailData.channelId==2">快手</div>
                <div class="videoDetailLi" v-else-if="detailData.channelId==3">OPPO</div>
                <div class="videoDetailLi" v-else-if="detailData.channelId==4">VIVO</div>
              </li>
              <li>
                <label>素材名称</label>
                <div class="videoDetailLi">{{detailData.mtName}}</div>
              </li>
              <li>
                <label>工单名称</label>
                <div class="videoDetailLi">
                  <!-- {{detailData.demandName}} -->
                  <span v-if="detailData.demandName == ''">--</span>
                  <span v-else>{{detailData.demandName}}</span>
                </div>
              </li>
              <!-- <li>
                <label>素材标签</label>
                <div class="videoDetailLi">{{detailData.showTagNames}}</div>
              </li> -->
             <!-- <li>
                <label>素材描述</label>
                <div class="videoDetailLi">{{detailData.copywriting}}</div>
              </li> -->
              <li>
                <label>上传人员</label>
                <div class="videoDetailLi">{{detailData.createUserName}}</div>
              </li>
              <!-- <li>
                <label>所属部门</label>
                <div class="videoDetailLi">{{detailData.departmentName}}</div>
              </li> -->
              <li>
                <label>制作人</label>
                <div class="videoDetailLi">{{detailData.makerUserName}}</div>
              </li>
              <li>
                <label>工单编号</label>
                <div class="videoDetailLi">{{detailData.demandNum}}</div>
              </li>
              <li>
                <label>上传时间</label>
                <div class="videoDetailLi">{{detailData.creatTimeStr}}</div>
              </li>
              <li>
                <label>审核状态</label>
                <div class="videoDetailLi">
                  <span v-if="detailData.status==1">禁用</span>
                  <span v-if="detailData.status==2">正常</span>
                  <span v-if="detailData.status==3">待审核</span>
                  <span v-if="detailData.status==4">运营审核通过</span>
                  <span v-if="detailData.status==5">运营审核失败</span>
                  <span v-if="detailData.status==6">客户审核未通过</span>                 
                </div>
              </li>
              <li>
                <label>拒绝原因</label>
                <div class="videoDetailLi">{{detailData.subTitle}}</div>
              </li>
              <!-- <li>
                <label>视频链接</label>
                <div class="videoDetailLi">{{videoUrlLj}}<el-button type="primary" @click="copy" style="margin-left:30px;">复制</el-button></div>
              </li> -->
              <!-- <li>
                <label>素材使用</label>
                <div class="videoDetailLi">已下载{{detailData.downloadCount}}次</div>
              </li> -->
              <li>
                <div v-if="detailData.materialType == 1"><!-- 视频的（目前只有图片测试） -->
                  <div>
                    <label>素材内容</label>
                    <div class="videoDetailLi materialListTxt" v-if="detailData.type==1">
                      <span v-for="(item,i) in detailData.url.imgList" :key="i">
                        <img  :src="item" style="width:50%;margin:0 10px;">
                      </span>
                    </div>
                    <div class="videoDetailLi materialListTxt" v-else>
                      <img :src="detailData.url.imgUrl" style="width:50%;margin:0 10px;">
                    </div>
                  </div>
                  <div>
                    <label>封面</label>
                    <div class="videoDetailLi">
                      <img :src="detailData.url.imgUrl" style="width:50%;margin:0 10px;">
                    </div>
                  </div>
                  <div>
                    <label>落地页图片</label>
                    <div class="videoDetailLi">
                      <img :src="detailData.url.pageUrl" style="width:50%;margin:0 10px;">
                    </div>
                  </div>
                </div>
                <div v-else><!-- 图片的 -->
                  <div>
                    <label>素材内容</label>
                    <div class="videoDetailLi" v-if="detailData.type == 2">
                        <!-- <img :src="item" style="width:50%;margin:0 10px;"> -->
                        <img :src="videoUrlLj+firstWord" style="width:10%;margin:0 10px;cursor: pointer;" @click='reloadVideo(loadIn)' v-show="loadIn==false">
                        <video id="myvideo"
                          ref="videoPouse"
                          controls
                          autoplay
                          webkit-playsinline
                          playsinline
                          x5-playsinline
                          v-show="loadIn==true"
                          controlslist="nodownload"  
                          style="width:30%;" ></video>
                    </div>
                    <div v-else>
                      <div v-for="item in imgj">
                      <img :src="item" style="width:10%;margin:0 10px;margin-left:200px;cursor: pointer;" v-show="loadIn==false"></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="returnBtn"><el-button type="primary" @click="returnMaterialTable" class="returnList">返回</el-button></div>  
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'vedioDetail',
    data() {
      return {
        detailId:'',
        detailData:{},
        videoUrlLj:'',
        imgUrlList:[],
        firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',//获取视频第一帧作为图片
        loadIn:false,//是否加载视频
        imgj:[]

      }
    },
    computed: {
       
    },
    mounted() {
      this.getId()
      this.getDetail()
    },
    methods: {
      //点击返回按钮返回到列表页
      returnMaterialTable(){
        this.$router.push('/auth-management/MaterialList')
      },

      getId(){
        this.detailId = this.$route.params && this.$route.params.id
        console.log(this.detailId)
      },

      getDetail(){
        let that = this
        $http.axios.post("/api/operationManagement/material/"+that.detailId).then(res=> {
          if(res.data.code == 200){
            that.detailData = res.data.data   
            that.videoUrlLj = that.detailData.url.videoUrl
            that.imgj = that.detailData.url.imgList
            that.imgUrlList = that.detailData.url.imgList
            console.log(that.imgUrlList)
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },
      copy(){
        var input = document.createElement("input");   // js创建一个input输入框
        input.value = this.detailData.localUrl;  // 将需要复制的文本赋值到创建的input输入框中
        document.body.appendChild(input);    // 将输入框暂时创建到实例里面
        input.select();   // 选中输入框中的内容
        document.execCommand("Copy");   // 执行复制操作
        document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      },
      reloadVideo(loadIn){
        let _this = this
        if(loadIn==false){
          this.$refs.videoPouse.src = this.videoUrlLj
          this.$refs.videoPouse.play()

          let timer = setTimeout(function () {
            _this.loadIn=true
            window.clearTimeout(timer)
          }, 1000)
        }else{
          this.loadIn=false
        }
        console.log(document.readyState)
      }

    }
  }
  </script>
  
  <style lang="scss" scoped>
    ul,li{
      margin:0;
      padding:0;
    }
    .videoWrap{
      padding:25px 30px;
      box-sizing: border-box;
    }
    .videoWrap li{
      margin:10px;
      font-size:0;
    }
    .videoWrap li label,.videoWrap li .videoDetailLi{
      display:inline-block;
      vertical-align: top;
      font-size:14px;
    }
    .videoWrap li label{
      width:10%;
      text-align:right;
    }
    .videoWrap li .videoDetailLi{
      width:90%;
      padding-left:25px;
      box-sizing: border-box;
    }
    .returnBtn{
      text-align:center;
    }
    .returnList{
      padding:10px 30px;
    }

  </style>
