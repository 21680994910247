<template>
  <el-dialog
    :visible.sync="show"
    width="600px"
    :close-on-click-modal="false"
    @close = "onCancel"
    center
    :show-close = "false"
    class="account-detail-dialog"
    title="批量修改自动优化广告出价">
    <div class="dialogIcon"><img :src='accountCost'></div>
    <el-form
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData">
      <div class="dailyLimit">
        <el-form-item prop="accAdvertBudgetIDs" class="editInput">
          <el-input 
            type="textarea"
            style="width:520px;"
            v-model = "formData.accAdvertBudgetIDs"
            placeholder="请输入ID，并以英文逗号隔开"  
            @blur="blurBudgetIds">
          </el-input>
        </el-form-item>
		<!-- 投放范围 -->
		<el-select  clearable multiple placeholder="请选择投放范围" v-model="formData.campaignType"  style="width:100%;margin-bottom: 10px;">
		  <el-option value='1' label='商店'>商店</el-option>
		  <el-option value='2' label="非商店">非商店</el-option>
		  <el-option value='3' label='米盟'>米盟</el-option>
		</el-select>
        <!-- 二阶出价 -->
        <el-form-item prop="" class="editInput">
          <span>ocpx二阶段出价</span>
          <el-input 
            class="delArr"
            v-model = "formData.secondPrice"
            type="number"
            style="width: 400px !important;margin-left:5px;"
            placeholder="请输入ocpx二阶段出价"
          >
          <!-- @blur="blurOcpx" -->
          </el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancelNew('formData')">取消保存</el-button>
      <!-- <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button> -->
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirmNew('formData')">确认保存</el-button>
      <!-- <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button> -->
    </span> 
    <el-dialog
      title=""
      :visible.sync="loadingDialogVisible"
      width="400px"
      append-to-body
      :destroy-on-close="true"
      :show-close="false"
      :close-on-click-modal="false"
      class="loadingBg">
      <span class="loading">
        <i class="el-icon-loading"></i>
        <span>正在修改中，可能时间过长，可先关闭窗口，提示成功之后可进入创意列表查看</span>
      </span>
      <div style="text-align: center;">     
        <!-- <el-button type="primary" class="newDialogCancel" @click="loadingDialogVisible = false">取消</el-button> -->
        <el-button type="primary" class="newDialog" @click="loadingDialog">确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
  import accountCost from '@/assets/img/accountCost.png';
  import { $http } from '@/api/http';
  import TimeUnit from '@/views/auth/TimeUnit'
  import Qs from 'qs';
  import formatDate from '@/utils/formatDate'//将new Date() 转换成->2022-05-11入参formatDate(new Date())/2022-05-11 12:21:34入参formatDate(new Date(),'time')

export default {
components:{
    // TimeUnit
  },  
  name: "dayLimitDialog",

  props: {
    pubVue: {
      type: Object
    },
    page:Number,
    nowIs:Boolean,
    dialogTitle:String,
    dayType:Number,//前端自己定义的:账户选择->1 计划管理->2 广告创意->4;
    groupsIds:Array,
    visible: {
      type: Boolean,
      default: false
    },
    limitIs:Boolean,
    yulanList:Array,
    userContent:Object,//登录用户信息:{userId:userId,userName:userName,ownerId:ownerId,deptId:deptId}
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      accountCost,
      
      formData: {
        scan_limit:'',
        upDown:'',
        limitTop:'',
        limitD:'',
        time_limit:'',
        accDayBudget:'',//设置日预算
        accAdvertBudgetIDs:'',
        accAdvertBudgetClass:[],
        accDaySecondBudget:'',
        secondBudgetInput:'',
        basePrice:'',//基础出价
        secondPrice:'',//一阶出价
        thirdPrice:'',//二阶出价
		campaignType:'',//投放范围
      },
      basePrice:true,
      secondPrice:true,
      thirdPrice:true,
      rules: {
        minNum: [
          { required: true, message: '请输入最低值', trigger: 'blur' }
      ]},
      budgetClassOptions:[{
        value:1,
        label:'二阶段'
      }],
      secondBudgetInputList:[{
        value:'OCPC',
        label:'OCPC'
      },{
        value:'OCPD',
        label:'OCPD'
      }],
      accAdvertBudgetIDs:[],
      dayLimitList:[{'text':'具体值','id':0},{'text':'百分比','id':1}],
      // dayLimitList:[{'text':'具体值','id':0}],
      dayLimitFnList:[{'text':'上调','id':0},{'text':'下调','id':1}],
      show: this.visible,
      timeLimitIs:false,//是否可选
      loading: false,
      dayLimitV:'',
      dayLimitF:'',
      timeLimitV:[],
      numberlimit: { validator: this.checkMoney, trigger: 'blur'},
      numberlimitJ: { validator: this.checkMoneyJ, trigger: 'blur'},

      numberlimitM: [{validator: this.checkMoneyM},
                    {required:true,  trigger: 'blur' }],
      checked:true,//立即执行
      value1: [formatDate(new Date()),formatDate(new Date())],//时间
      dayLimitTitleList:{1:[{name:'账户名称',prop:'ownerName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      2:[{name:'广告计划名称',prop:'planName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      3:[{name:'广告创意名称',prop:'adName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],},
      timeList:[
        {
          'executeHour':'',
          'price':'',//一阶
          'secondPrice':'',//二阶
          'threePrice':'',//三阶
        }
      ],
      timeLimitList:[{'time':'00:00','timeLimitIs':false,'id':'0'},{'time':'00:30','timeLimitIs':false,'id':'1'},{'time':'01:00','timeLimitIs':false,'id':'2'},{'time':'01:30','timeLimitIs':false,'id':'3'},{'time':'02:00','timeLimitIs':false,'id':'4'},{'time':'02:30','timeLimitIs':false,'id':'5'},{'time':'03:00','timeLimitIs':false,'id':'6'},{'time':'03:30','timeLimitIs':false,'id':'7'},
      {'time':'04:00','timeLimitIs':false,'id':'8'},{'time':'04:30','timeLimitIs':false,'id':'9'},{'time':'05:00','timeLimitIs':false,'id':'10'},{'time':'05:30','timeLimitIs':false,'id':'11'},{'time':'06:00','timeLimitIs':false,'id':'12'},{'time':'06:30','timeLimitIs':false,'id':'13'},{'time':'07:00','timeLimitIs':false,'id':'14'},{'time':'07:30','timeLimitIs':false,'id':'15'},
      {'time':'08:00','timeLimitIs':false,'id':'16'},{'time':'08:30','timeLimitIs':false,'id':'17'},{'time':'09:00','timeLimitIs':false,'id':'18'},{'time':'09:30','timeLimitIs':false,'id':'19'},{'time':'10:00','timeLimitIs':false,'id':'20'},{'time':'10:30','timeLimitIs':false,'id':'21'},{'time':'11:00','timeLimitIs':false,'id':'22'},{'time':'11:30','timeLimitIs':false,'id':'23'},
      {'time':'12:00','timeLimitIs':false,'id':'24'},{'time':'12:30','timeLimitIs':false,'id':'25'},{'time':'13:00','timeLimitIs':false,'id':'26'},{'time':'13:30','timeLimitIs':false,'id':'27'},{'time':'14:00','timeLimitIs':false,'id':'28'},{'time':'14:30','timeLimitIs':false,'id':'29'},{'time':'15:00','timeLimitIs':false,'id':'30'},{'time':'15:30','timeLimitIs':false,'id':'31'},
      {'time':'16:00','timeLimitIs':false,'id':'32'},{'time':'16:30','timeLimitIs':false,'id':'33'},{'time':'17:00','timeLimitIs':false,'id':'34'},{'time':'17:30','timeLimitIs':false,'id':'35'},{'time':'18:00','timeLimitIs':false,'id':'36'},{'time':'18:30','timeLimitIs':false,'id':'37'},{'time':'19:00','timeLimitIs':false,'id':'38'},{'time':'19:30','timeLimitIs':false,'id':'39'},
      {'time':'20:00','timeLimitIs':false,'id':'40'},{'time':'20:30','timeLimitIs':false,'id':'41'},{'time':'21:00','timeLimitIs':false,'id':'42'},{'time':'21:30','timeLimitIs':false,'id':'43'},{'time':'22:00','timeLimitIs':false,'id':'44'},{'time':'22:30','timeLimitIs':false,'id':'45'},{'time':'23:00','timeLimitIs':false,'id':'46'},{'time':'23:30','timeLimitIs':false,'id':'47'}],

      // timeLimitList:[{'time':'00:00','timeLimitIs':false,'id':'0'},{'time':'00:30','timeLimitIs':false,'id':'1'},{'time':'01:00','timeLimitIs':false,'id':'2'},{'time':'01:30','timeLimitIs':false,'id':'3'},{'time':'02:00','timeLimitIs':false,'id':'4'},{'time':'02:30','timeLimitIs':false,'id':'5'},{'time':'03:00','timeLimitIs':false,'id':'6'},{'time':'03:30','timeLimitIs':false,'id':'7'},
      // {'time':'04:00','timeLimitIs':false,'id':'8'}],


      tableData:[],
      changeDayLimitArr:[],
      numInArr:[],
      tableYL:false,
      cjLimit:{1:'预算限制',2:'预算限制',3:'出价限制'},
      nowIsValue:'0',
      accountNowIsValue:'0',
      choosedTime:[],
      timeEvent:'',
      validDate:new Date().getTime(),
      secondBudget:false,
      checkList:[],
      checkEveryList:[],
      loadingDialogVisible:false,

    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;
    },
  },
  mounted(){
     this.pubVue.$on('SHOW_DIALOG', data => {
      console.log('方法监听', data)
      this.show = data.showDialog
    })
    console.log(this.dayType)
    console.log(this.checked)
  },
  methods: {
    yulanFn(){
      let _this = this

      if(_this.tableYL){
          _this.tableYL= false
      } else{
        _this.tableYL= true
        _this.showYu()
      }
    },
    showYu(){
      let _this = this
      console.log(this.dayType)
      _this.tableData = []
      _this.yulanList.forEach((obj,i)=>{
       _this.tableData.push(Object.assign({},obj,_this.changeDayLimitArr[i],_this.numInArr[i]))
      })
      console.log(_this.tableData)
    },
    
    deleTimeFn(i,time){
      if (i >= 0) {
          this.timeList.splice(i, 1);
          this.choosedTime.splice(i, 1);
          this.timeLimitList.forEach(obj=>{
            if(obj.time == time){
              obj.timeLimitIs = false
            }
          })
        }
    },
    addTimeFn(){
      let _this = this
      _this.timeList.push({})
      console.log(this.timeLimitV)
      // _this.choosedTime.push(_this.timeEvent)
      console.log(_this.choosedTime)
      _this.choosedTime.forEach(obj=>{
      // _this.timeLimitList.forEach((ele,i,arr)=>{
        _this.timeLimitList[obj].timeLimitIs = true
      // })
      })      
      
    },
    checkMoney (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        // if (!/(^[1-9]\d*$)/.test(value)) {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (value > 100) {
            callback(new Error('不能超过100'))
          } else {
            callback()
          }
        }
      }, 1000)
    },
    checkMoneyJ (rule, value, callback)  {
      let _this = this

        if (!value) {
            return callback(new Error('不能为空'))
        }else if(_this.dayType == 2||_this.dayType == 1){
          if( value<200){
            return callback(new Error('不能小于200'))
          }
        }
        const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
        setTimeout(() => {
          if (!mailReg.test(value)) {
            callback(new Error('请输入数字值'))
          } else {
            callback()
          }
        }, 1000)
    },
    checkMoneyM (rule, value, callback)  {
      let _this = this
      if (!value) {
          return callback(new Error('不能为空'))
      }else if(_this.dayType == 2||_this.dayType == 1){
        if( value<200){
          return callback(new Error('不能小于200'))
        }
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    },
    datelimitM(rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
    },

    // 设置账户日预算->立即执行调这个接口
    editBudgetFn(){
      let _this = this,accDayBudget = parseInt(this.formData.accDayBudget),params,msg = '',adverInfos = [],time = ''
      if(!Boolean(Number(_this.accountNowIsValue))){
        _this.yulanList.forEach(obj=>{
        adverInfos.push({'ownerId':obj.ownerId,'price':accDayBudget})
      })
      }else{
        _this.yulanList.forEach(obj=>{
          adverInfos.push({'ownerId':obj.ownerId,'price':accDayBudget,'extendFlag':1})
        })
      }
      params = {
        "userId": _this.userContent.userId,
        "userName": _this.userContent.userName,
        "deptId": _this.userContent.deptId,
        "ownerIds":_this.accAdvertBudgetIDs.toString(),
      }
      if(_this.formData.basePrice == ''&&_this.formData.secondPrice == ''&&_this.formData.thirdPrice == ''){
        _this.$message({
          showClose: true,
          message:'请填写至少一个出价',
          type: 'warning'
        })
      }else{
        params.price = _this.formData.basePrice
        params.secondPrice = _this.formData.secondPrice
        params.threePrice = _this.formData.thirdPrice
        $http.axios.post("/api/mi/adCreative/batch/editPriceOfOpenAdByAccount",params).then(res=> {
          if(res.data.code!=200){
            if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ownerId
                msg+=obj.msg
              })
              _this.$message({
                showClose: true,
                message:msg,
                type: 'error'
              })
            }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type:  'error'
              }) 
            }
          }else{
            _this.show = false
            _this.$message({
              showClose: true,
              message:res.data.msg+',操作详情请点击任务列表按钮查看',
              type: 'success'
            })
            _this.pubVue.miAccountList(_this.page)
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      }     
          
    },
    loadingDialog(){
      this.show = false
      this.loadingDialogVisible = false
    },
    changeDayLimit(event,type,index){
      let _this = this,num
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
        //获取对象在数组的索引
          num = _this.timeLimitList.indexOf(_this.timeLimitList.filter(d => d.time ===event)[0])
          _this.choosedTime[index] = num
          _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
             
            }
             _this.choosedTime.forEach(obj=>{
              _this.timeLimitList[obj].timeLimitIs = true
            })
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
     changeDayLimitFn(event,type,index){
      let _this = this
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
           _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
              _this.choosedTime.forEach(obj=>{
                _this.timeLimitList[obj].timeLimitIs = true
              })
            }
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
    numIn(){
       let _this = this,val = _this.formData.scan_limit
        _this.numInArr = []
      console.log(666,_this.formData.scan_limit)
      _this.yulanList.forEach((obj,i)=>{
        if(_this.dayLimitV == '1'){
          if(_this.dayLimitF == '0'){
            if(_this.dayType == 3){
              val = (Number(obj.price)*(1+Number(_this.formData.scan_limit)/100)).toFixed(2)
            }else{
              val = Math.round(Number(obj.price)*(1+Number(_this.formData.scan_limit)/100))
            }
              _this.numInArr.push(Object.assign({},{num:_this.formData.scan_limit,sendPrice:val,fs:'上调'}))

          }else if(_this.dayLimitF == '1'){
            if(_this.dayType == 3){
              val = (Number(obj.price)*(1-Number(_this.formData.scan_limit)/100)).toFixed(2)
            }else{
              val = Math.round(Number(obj.price)*(1-Number(_this.formData.scan_limit)/100))
            }
              _this.numInArr.push(Object.assign({},{num:_this.formData.scan_limit,sendPrice:val,fs:'下调'}))

          }
        }else{
          _this.numInArr.push(Object.assign({},{num:val,sendPrice:val}))
        }
      })
      console.log(_this.tableData)
      _this.showYu()
    },
    onConfirm(formName) {
      console.log(this.dayType)
      let _this = this
       _this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('成功2')
            if(_this.checked){
              console.log('立即执行')
              if(_this.dayType ==1){
                console.log(1)
                _this.editBudgetFn()
              }else if(_this.dayType == 2){
                console.log(2)
                _this.editPlanBudgetFn()
              }else if(_this.dayType == 3){
                console.log(3)
                _this.editPriceBatchFn()//创意列表页->批量调价(按钮功能)
              }
            }else{
              console.log('非立即执行')
              if(_this.value1 != []){
                if(_this.value1.length == 0){
                    _this.$message({
                      showClose: true,
                      message: '启动日期不能为空',
                      type: 'error'
                    })
                    return false
                }

                let flag = _this.timeList.length!=0
                flag = _this.timeList.every(obj=>{
                  if(obj.executeHour==''||obj.executeHour==undefined){
                      _this.$message({
                      showClose: true,
                      message: '请选择时间！',
                      type: 'error'
                    })
                    return false
                  }

                  // else if(obj.price==''||obj.price==undefined){
                  //   _this.$message({
                  //     showClose: true,
                  //     message: '请输入目标金额！',
                  //     type: 'error'
                  //   })
                  //   return false
                  // }else if(obj.secondPrice==''||obj.secondPrice==undefined){
                  //   _this.$message({
                  //     showClose: true,
                  //     message: '请输入二阶段出价！',
                  //     type: 'error'
                  //   })
                  //   return false
                  // }else if(obj.threePrice==''||obj.threePrice==undefined){
                  //   _this.$message({
                  //     showClose: true,
                  //     message: '请输入三阶段出价！',
                  //     type: 'error'
                  //   })
                  //   return false
                  // }
                  
                  // else if(_this.dayType == 2&&obj.price<200){
                  //   _this.$message({
                  //     showClose: true,
                  //     message: '输入目标金额不能小于200！',
                  //     type: 'error'
                  //   })
                  //   return false
                  // }else if(_this.dayType == 1&&obj.price<200){
                  //   _this.$message({
                  //     showClose: true,
                  //     message: '输入目标金额不能小于200！',
                  //     type: 'error'
                  //   })
                  //   return false
                  // }
                  else{
                    return true
                  }
                })
                if(flag){
                  // _this.addGroupModifyPriceSetFn(_this.dayType)
                  _this.addGroupModifyPriceSetFn()
                }
              }else{
                _this.$message({
                  showClose: true,
                  message: "请选择日期",
                  type: 'error'
                })
              }
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      
    },
    onCancel() {
      console.log(this.checked)
      this.show= false;
      this.tableYL= false;
      this.nowIsValue = '0'
      this.accountNowIsValue = '0'
      this.formData.scan_limit = ''
      this.dayLimitF = ''
      this.dayLimitV = ''
      this.tableData = []
      this.changeDayLimitArr =[]
      this.numInArr = []
      this.value1 = []
      this.formData.limitTop = ''
      this.formData.limitD = ''
      this.formData.accDayBudget = ''
      if(this.dayType == 1){
        this.checked = false
      }else{
        this.checked = true
      }
      // this.checked = true
      this.timeList=[
        {
          'executeHour':'',
          'price':'',//一阶
          'secondPrice':'',//二阶
          'threePrice':'',//三阶
        }
      ],
      this.pubVue.$refs.multipleTable.clearSelection();
      this.$refs.formData.clearValidate();
      // this.pubVue.grouplistFn()
      console.log('关闭')
      this.timeLimitList.forEach(obj=>{
        obj.timeLimitIs = false
      })
      console.log(this.checked)
    },
    addOrSetFn(type){
      
    },
    //保存-未立即执行接口	
      // {
      //   	"userId": 0,
          // "userName": "",
          // "deptId": 0,
          // "startDate": "",
          // "endDate": "",
          // "type": 0,
          // "infos": [
          //   {
          //     "ownerId": 0,
          //     "ownerName": "",
          //     "adPlanId": 0,
          //     "adPlanName": "",
          //     "adCreativeId": 0,
          //     "adCreativeName": ""
          //   }
          // ],
          // "details": [
          //   {
          //     "executeHour": "",
          //     "price": 0,
          //     "bidType": 0,
          //     "secondPrice": 0
          //   }
          // ]
      // }
    //添加调价计划  1-账户日预算 2-计划日预算 3-广告出价
    addGroupModifyPriceSetFn(){
      // /adPlan/v1/updateOppoDayBudgetThree
      let _this = this,params = {},infos = [],ownerIdsList =[],ownerNamesList =[],userContent={}
      console.log(_this.tableData)
      let timeString = _this.timeList
      let addOpenClosePlanDetailDtoList = []
        timeString.forEach(function(e,index){
          if(e!=''){
            addOpenClosePlanDetailDtoList.push(
              {
                executeHour:e.executeHour,
                price:Number(e.price),
                secondPrice:Number(e.secondPrice),
                threePrice:Number(e.threePrice),              
              }
            )
          }
        })
        _this.tableData.forEach(obj=>{
          ownerIdsList.push(obj.ownerId)
          ownerNamesList.push(obj.ownerName)
        })
        params = {
        'deptId':_this.userContent.deptId,
        'userId':_this.userContent.userId,
        'userName':_this.userContent.userName,
        "startDate": _this.value1[0]?_this.value1[0]:'2121-01-01 00:00:00',
        "endDate": _this.value1[1]?_this.value1[1]:'2121-01-01 00:00:00',
        "details": addOpenClosePlanDetailDtoList,
        "ownerIds":_this.accAdvertBudgetIDs.toString(),
        "type":3
      }     
      // if(type == 1){
      //   _this.yulanList.forEach((obj,i)=>{
      //     infos.push({'ownerId':obj.ownerId,'ownerName':obj.ownerName})
      //   })
      //   params = Object.assign({},userContent,{"type":1,"infos":infos})
        
      // }else if(type == 2){
      //   _this.yulanList.forEach((obj,i)=>{
      //     infos.push({'ownerId':_this.userContent.ownerId,'ownerName':_this.userContent.ownerName,'adPlanId':obj.planId,'adPlanName':obj.planName})
      //   })
      //   params = Object.assign({},userContent,{"type":2,"infos":infos})

      // }else if(type == 3){
      //   _this.tableData.forEach((obj,i)=>{
      //     infos.push({'ownerId':_this.userContent.ownerId,'ownerName':_this.userContent.ownerName,'adPlanId':obj.planId,'adPlanName':obj.planName,'adCreativeId':obj.id,'adCreativeName':obj.adName})
      //   })
      //   params = Object.assign({},userContent,{"type":3,"infos":infos})
      // }
      
      // $http.axios.post("/api/mi/modifyPrice/add",params).then(res=> {
      $http.axios.post("/api/mi/modifyPrice/addQuick",params).then(res=> {
        if(res.data.code==200){
          _this.pubVue.adPlanIdList = []
          if(_this.dayType == 1){
            setTimeout(function(){
            _this.pubVue.miAccountList(_this.page)
            },100)
          }else if(_this.dayType == 2){
            setTimeout(function(){
            _this.pubVue.getAccountPlanListFn(_this.page)
            },100)
          }else if(_this.dayType == 3){
            setTimeout(function(){
            _this.pubVue.advertListFn(_this.page)
            },100)
          }
           _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
          _this.onCancel();
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    // {
		// 	"ownerId": 0,
		// 	"extendFlag": 0,
		// 	"adId": 0,
		// 	"price": 0,//ocpc一阶段出价,单位:元,保留小数点后两位
		// 	"bidType": 0,//出价类型 1-CPM 2-CPC 3-CPD 101-OCPC 102-OCPD
		// 	"secondPrice": 0//ocpc二阶段出价,单位:元,保留小数点后两位
		// }
    //批量修改出价
    editPriceBatchFn(){//创意列表页的批量调价
     let _this = this,params = {},ids = [],adInfos = [],msg = ''
      console.log(_this.tableData)
      _this.tableData.forEach((obj,i)=>{
        // ids.push(obj.adGroupId)
        if(!Boolean(Number(_this.nowIsValue))&&_this.nowIs){
          adInfos.push({'ownerId':_this.userContent.ownerId,
            'price':Number(obj.sendPrice),
            'bidType':obj.bidType,
            'adId':obj.id,})//广告id
        }else{
          
           adInfos.push({'ownerId':_this.userContent.ownerId,
            'price':Number(obj.sendPrice),
            'bidType':obj.bidType,
            'adId':obj.id,//广告id
            'extendFlag':1,})//设置排除日期 1-是
        }
        console.log(_this.numInArr)
        // if(limitIs){

        // }
      })
      params = {	"userId": _this.userContent.userId,
                  "userName": _this.userContent.userName,
                  "deptId": _this.userContent.deptId,
                  "validDate": _this.validDate,
                  'adInfos':adInfos}
      $http.axios.post("/api/mi/adCreative/batch/editPrice",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
            setTimeout(function(){
              _this.pubVue.advertListFn(_this.page)
            },100)
            _this.$message({
                showClose: true,
                message:res.data.msg,
                type: 'success'
              })
          _this.pubVue.adIds = []
        }else{
          if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ids
                msg+=obj.msg
              })
              
            }else{
              msg = res.data.msg
            }
            _this.$message({
              showClose: true,
              message:msg,
              type: 'error'
            })
        }
        _this.onCancel();
        console.log('失败')
      }).catch(error=>{
        console.log(error)
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
     
      // "userId": 0,
      // "userName": "",
      // "deptId": 0,
      // "validDate": "",
      // "adverInfos": [
      //   {
      //     "ownerId": 0,
      //     "extendFlag": 0,
      //     "price": 0
      //   }
      // ],
      // "adPlanInfos": [
      //   {
      //     "ownerId": 0,
      //     "extendFlag": 0,
      //     "adPlanId": 0,
      //     "price": 0
      //   }
      // ],
    //修改计划日限额->立即执行时调用的接口
    editPlanBudgetFn(){
    let _this = this,params = {},msg = '',adPlanInfos = []
     
    _this.tableData.forEach((obj,i)=>{
      if(!Boolean(Number(_this.nowIsValue))&&_this.nowIs){
        adPlanInfos.push({'price':Number(obj.sendPrice),
          'ownerId':obj.ownerId,
          'adPlanId':obj.planId})
      }else{
        adPlanInfos.push({'price':Number(obj.sendPrice),
          'ownerId':obj.ownerId,
          'adPlanId':obj.planId,
          'extendFlag':1,})//设置排除日期 1-是
          }
        console.log(_this.numInArr)
    })
    params = {
      "userId": _this.userContent.userId,
      "userName": _this.userContent.userName,
      "deptId": _this.userContent.deptId,
      "validDate":_this.validDate,
      "adPlanInfos": adPlanInfos,
    }
    $http.axios.post("/api/mi/adPlan/batch/editBudget",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
           _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          })
            setTimeout(function(){
              _this.pubVue.getAccountPlanListFn(_this.page)
            },100)
          
          _this.pubVue.adPlanIdList = []
          
        }else{
          if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ids
                msg+=obj.msg
              })
               _this.$message({
                showClose: true,
                message:msg,
                type: 'error'
              })
            }
        }
        _this.onCancel();
      }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
    },
    //去重
    unique(arr) {
      if (!Array.isArray(arr)) {
        return 
      }
      var array = []; 
      for (var i = 0; i < arr.length; i++) {
        if (array .indexOf(arr[i]) === -1) {
          array .push(arr[i]) 
        } 
      }
      this.accAdvertBudgetIDs = array
      return array; 
    },

    
    blurBudgetIds(){
      let accAdvertBudgetIDs = []
      if(this.formData.accAdvertBudgetIDs != ''){
        if(!/，|^,|,$/.test(this.formData.accAdvertBudgetIDs) == true){
          accAdvertBudgetIDs = this.formData.accAdvertBudgetIDs.split(',')
          this.unique(accAdvertBudgetIDs)
          console.log(this.accAdvertBudgetIDs)
        }else{
          this.$message({
            showClose: true,
            message: '请用英文逗号隔开',
            type: 'warning'
          })
        }
      }
    },

    chooseClass(val){
      console.log(val)
      if(val.length != 0){
        val.forEach((item,i)=>{
          if(item == 1){
            this.secondBudget = true
          }
        })
      }else{
        this.secondBudget = false
        this.formData.accDaySecondBudget = ''
        this.formData.secondBudgetInput = ''
      }
    },

    changesecondBudget(){
      this.formData.accDaySecondBudget = ''
    },
    changeList(val){
      console.log(this.checkList)
      console.log(val.indexOf('1') == -1)
      if(val.indexOf('1') == -1){
        this.basePrice = true
        this.formData.basePrice = ''
      }else{
        this.basePrice = false
      }
      if(val.indexOf('2') == -1){
        this.secondPrice = true
        this.formData.secondPrice = ''
      }else{
        this.secondPrice = false
      }
      if(val.indexOf('3') == -1){
        this.thirdPrice = true
        this.formData.thirdPrice = ''
      }else{
        this.thirdPrice = false
      }
    },

    //ocpx的转化(⼆阶段)价格 最低0.15
    // blurOcpx(){
    //   console.log(this.formData.secondPrice)
    //   if(this.formData.secondPrice<0.15){
    //     this.$message({
    //       showClose: true,
    //       message:'ocpx的转化(⼆阶段)价格不能低于0.15',
    //       type: 'warning'
    //     })
    //     this.formData.secondPrice = ''
    //   }
    // },

    //新的取消
    onCancelNew(formName){
      console.log(this.$refs[formName])
      this.$refs[formName].resetFields()
      this.formData.secondPrice = ''
      this.show = false
    },

    //新的确定
    onConfirmNew(formName) {
      let _this = this,params = {}
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.formData.secondPrice<0.15){
            this.$message({
              showClose: true,
              message:'ocpx的转化(⼆阶段)价格不能低于0.15',
              type: 'warning'
            })
            this.formData.secondPrice = ''
          }else{
			if(_this.formData.campaignType.length<=0){
				_this.$message({
				  showClose: true,
				  message: '请选择投放范围',
				  type: 'warning'
				})
				return false
			}
            params = {
              "userId": _this.userContent.userId,
              "userName": _this.userContent.userName,
              "deptId": _this.userContent.deptId,
              "ownerIds":_this.accAdvertBudgetIDs.toString(),
              "ocpxBid":_this.formData.secondPrice,
			  "campaignType":_this.formData.campaignType.toString(),
            }
            $http.axios.post("/api/mi/adPlan/batch/editPriceByAccount",params).then(res=> {
              if(res.data.code == 200){
                _this.show = false
                _this.$message({
                  showClose: true,
                  message:res.data.msg,
                  type: 'success'
                })
                _this.pubVue.miAccountList(_this.page)
                _this.$refs[formName].resetFields()
                _this.formData.secondPrice = ''
              }else{
                _this.$message({
                  showClose: true,
                  message: res.data.msg,
                  type: 'error'
                }) 
              }
            })
          }
        } else {
          return false;
        }
      }); 
    },


  }
};
</script>
<style lang="scss" scoped>
  .limitInput input{
      width: 80px;
      padding: 8px 10px!important;
      text-align: center;
      margin-left: 10px;
  }

  .el-form--inline .el-form-item.priceLimt{
    display: block;
  }
  .priceLimt i{
    color:red;
  }
  .el-form--inline .el-form-item.dateConent{
    display: block;
    margin: 15px 0;
  }
  #app .el-dialog__body .el-form.el-form--inline{
    text-align: left!important;
  }
  /*新版*/
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .editInput .el-input{
    width:520px;
  }
  .costAdd{
    width:100%;
    height:26px;
    line-height:26px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    color:#48916F;
    font-size:12px;
    text-align:center;
    cursor: pointer;
  }
  .addList{
    position: relative;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin:10px 0;
    padding:10px;
    box-sizing: border-box;
  }
  .deleteBtn{
    position: absolute;
    font-size:18px;
    top:-13px;
    right:-11px;
    color:rgb(212, 24, 24);
    cursor: pointer;
  }
  ::v-deep .addList .el-form-item--mini.el-form-item{
    margin:0 5px;
    display: inline-block;
    vertical-align: middle;
  }
  ::v-deep .addList .el-input--mini .el-input__inner{
    width:120px!important;
  }
  ::v-deep .editInput .secondBudgetStyle .el-input,::v-deep .editInput .secondBudgetStyle .el-input__inner {
    width:200px!important;
    display: inline-block!important;
  }
  .peiceType span{
    font-size:12px;
  }
  ::v-deep .peiceType .el-input--mini .el-input__inner{
    width:97px!important;
  }
  .peiceTypeList{
    /* display: flex; */
    display: inline-block;
    vertical-align: middle;
  }
  .peiceTypeList .peiceType{
    display: inline-block;
    vertical-align: middle;
  }
  ::v-deep .editInput .el-checkbox{
    display: inline-block;
    vertical-align: middle;
    width:44%;
    margin-top:10px;
  }
  .loading{
    text-align:center;
  }
  .loading span,.loading i{
    display: block;
  }
  .loading span{
    font-size:14px;
    display: block;
    margin-bottom:30px;
    margin-top:20px;
  }
  .loading i{
    font-size:60px;
  }
  ::v-deep .loadingBg .el-dialog{
    margin-top:200px;
    background:rgba(255,255,255,0)!important;
    box-shadow: none;
  }
  ::v-deep .loading .el-popup-parent--hidden .el-dialog__body, #app .el-dialog__body{
    margin:0;
  }
  /* 隐藏输入框上下箭头 */
  ::v-deep .delArr input::-webkit-inner-spin-button,
  ::v-deep .delArr input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* 兼容Firefox */
  ::v-deep .delArr input[type=number] {
    -moz-appearance: textfield;
  }
</style>

