<template>
  <el-dialog
    :visible.sync="show"
    width="600px"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    center
    :show-close = "false"
    :title="dialogTitle">
    <div class="dialogIcon"><img :src='accountCost'></div>
    <el-form
      :inline="true"
      hide-required-asterisk
      ref="formData"
      :model="formData">
      <div v-if="checked == true&&planType!=10">
        <el-form-item label="调价" prop="" label-width="80px">
          <el-select  clearable placeholder="选择调价类型" v-model="dayLimitV"  @change="changeDayLimit">
            <el-option
              v-for="(item,i) in dayLimitList"
              :key="i"
              :label="item.text"
              :value="item.id"
              :disabled = "limitIs&&item.id=='1'">
            </el-option>
          </el-select>
        </el-form-item>   
        <el-form-item label="" prop="" v-show="dayLimitV == '1'" >
          <el-select  clearable placeholder="选择调价方式" v-model="dayLimitF" @change = "numIn">
            <el-option
              v-for="(item,i) in dayLimitFnList"
              :key="i"
              :label="item.text"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>   
        <el-form-item :rules="dayLimitV == '1'?numberlimit:numberlimitM" prop="scan_limit">
          <el-input clearable v-model="formData.scan_limit" placeholder="请输入数值" @blur="numIn"></el-input>
        </el-form-item>
        <el-form-item class="priceLimt" label="">
          <span class="priceLimtSpan"><i>*</i>{{cjLimit[planType]}}</span>
          <el-form-item class="priceLimt" label="" :rules="numberlimitM" prop="limitTop">
            <el-input class="limitInput" clearable v-model="formData.limitTop"  placeholder="上限值"></el-input>
          </el-form-item>
          <el-form-item class="priceLimt" label="" :rules="numberlimitM" prop="limitD">
            <el-input class="limitInput" clearable v-model="formData.limitD" placeholder="下限值" ></el-input>
          </el-form-item>
        </el-form-item>
        <el-button type="primary" class="newQuery" size="small" @click="yulanFn" >预览</el-button>
        <div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            class="ylTable"
            v-show="tableYL">
            <el-table-column
              align='center'
              v-for="(item,index) in dayLimitTitleList[planType]" :key="index"
              :prop="item.prop"
              :label="item.name"
              show-overflow-tooltip>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="nowIs">
        <span class="priceLimtSpanDay">当日是否继续执行</span>
        <el-switch
          v-model="nowIsValue"
          active-color="#48916F"
          inactive-color="#EAEFED">
        </el-switch>
      </div>

      <div class="dailyLimit">
        <el-checkbox v-if='planType!=10' v-model="checked" @change="nowLimitFn(checked)">立即执行</el-checkbox>
        <el-form-item label="开始（结束）时间" label-width="120px" v-if="checked == false" class="dateConent">
          <div>
            <el-date-picker
              v-model="value1"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              style="width:384px;"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>
        <div v-if="checked == false"  >
          <div v-for="(item,i) in timeList" :key="i" class="addList">
            <el-form-item label="时间点">
              <el-select  clearable  placeholder="选择时间段" v-model="item.executeHourt"  @change="changeDayLimit($event,'addTime',i)">
                <el-option
                  v-for="(item1,j) in timeLimitList"
                  :key="j"
                  :label="item1.time"
                  :value="item1.time"
                  :disabled = item1.timeLimitIs>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item  v-if="checked == false"  lable='目标价格'>
              <el-input clearable oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="item.newPrice" placeholder="请输入目标金额" ></el-input>
            </el-form-item>
            <!-- <el-button type="primary" v-if="i>0" @click="deleTimeFn(i,item.executeHourt)">删除</el-button> -->
            <div class="deleteBtn" v-if="i>0" @click="deleTimeFn(i,item.executeHourt)">
              <i class="el-icon-error"></i>
            </div>
          </div>
          <!-- <el-button type="primary"  @click="addTimeFn">新增</el-button> -->
          <div class="costAdd"  @click="addTimeFn"><i class="el-icon-plus"></i>新增时间段</div>
        </div>
      </div>

    </el-form>
    <span slot="footer" >
      <el-button type="primary" class="newDialogCancel" :loading="loading" @click="onCancel">取消保存</el-button>
    </span>
    <span slot="footer" >
      <el-button type="primary" class="newDialog" :loading="loading" @click="onConfirm('formData')">确认保存</el-button>
    </span> 
  </el-dialog>
</template>

<script>
  import accountCost from '@/assets/img/accountCost.png';
  import { $http } from '@/api/http';
  import TimeUnit from '@/views/auth/TimeUnit'
export default {
components:{
    // TimeUnit
  },  
  name: "dayLimitDialog",

  props: {
    pubVue: {
      type: Object
    },
    nowIs:Boolean,
    dialogTitle:String,
    planType:Number,
    groupsIds:Array,
    visible: {
      type: Boolean,
      default: false
    },
    limitIs:Boolean,
    yulanList:Array,
    // type: Number,
    // ownerId:Number,
    userContent:Object,//登录用户信息
    submitData: {
      type: Array,
      default: () => []
    },
    max: Number
  },
  data() {
    return {
      accountCost,
      formData: {
        scan_limit:'',
        upDown:'',
        limitTop:'',
        limitD:'',
        time_limit:'',
        },
      rules: {
      
      minNum: [
        { required: true, message: '请输入最低值', trigger: 'blur' }
      ]},
      dayLimitList:[{'text':'具体值','id':0},{'text':'百分比','id':1}],
      dayLimitFnList:[{'text':'上调','id':0},{'text':'下调','id':1}],
      show: this.visible,
      timeLimitIs:false,//是否可选
      loading: false,
      dayLimitV:'',
      dayLimitF:'',
      timeLimitV:[],
      numberlimit: { validator: this.checkMoney, trigger: 'blur'},

      numberlimitM: { validator: this.checkMoneyM, trigger: 'blur' },
      // limitTop:'',//上限值
      // limitD:'',//下限值
      checked:this.planType==10?false:true,//立即执行
      value1:[],
      dayLimitTitleList:{10:[{name:'账户名称',prop:'ownerName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      20:[{name:'广告名称',prop:'planName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],
      30:[{name:'广告组名称',prop:'adGroupName'},
      {name:'调价类型',prop:'lx'},
      {name:'调价方式',prop:'fs'},
      {name:'数值',prop:'num'},
      {name:'原始出价',prop:'price'},
      {name:'设置出价',prop:'sendPrice'},
      ],},
      timeList:[
        {
          'executeHourt':'',
          'newPrice':''

        }
      ],
      timeLimitList:[{'time':'00:00','timeLimitIs':false,'id':'0'},{'time':'00:30','timeLimitIs':false,'id':'1'},{'time':'01:00','timeLimitIs':false,'id':'2'},{'time':'01:30','timeLimitIs':false,'id':'3'},{'time':'02:00','timeLimitIs':false,'id':'4'},{'time':'02:30','timeLimitIs':false,'id':'5'},{'time':'03:00','timeLimitIs':false,'id':'6'},{'time':'03:30','timeLimitIs':false,'id':'7'},
      {'time':'04:00','timeLimitIs':false,'id':'8'},{'time':'04:30','timeLimitIs':false,'id':'9'},{'time':'05:00','timeLimitIs':false,'id':'10'},{'time':'05:30','timeLimitIs':false,'id':'11'},{'time':'06:00','timeLimitIs':false,'id':'12'},{'time':'06:30','timeLimitIs':false,'id':'13'},{'time':'07:00','timeLimitIs':false,'id':'14'},{'time':'07:30','timeLimitIs':false,'id':'15'},
      {'time':'08:00','timeLimitIs':false,'id':'16'},{'time':'08:30','timeLimitIs':false,'id':'17'},{'time':'09:00','timeLimitIs':false,'id':'18'},{'time':'09:30','timeLimitIs':false,'id':'19'},{'time':'10:00','timeLimitIs':false,'id':'20'},{'time':'10:30','timeLimitIs':false,'id':'21'},{'time':'11:00','timeLimitIs':false,'id':'22'},{'time':'11:30','timeLimitIs':false,'id':'23'},
      {'time':'12:00','timeLimitIs':false,'id':'24'},{'time':'12:30','timeLimitIs':false,'id':'25'},{'time':'13:00','timeLimitIs':false,'id':'26'},{'time':'13:30','timeLimitIs':false,'id':'27'},{'time':'14:00','timeLimitIs':false,'id':'28'},{'time':'14:30','timeLimitIs':false,'id':'29'},{'time':'15:00','timeLimitIs':false,'id':'30'},{'time':'15:30','timeLimitIs':false,'id':'31'},
      {'time':'16:00','timeLimitIs':false,'id':'32'},{'time':'16:30','timeLimitIs':false,'id':'33'},{'time':'17:00','timeLimitIs':false,'id':'34'},{'time':'17:30','timeLimitIs':false,'id':'35'},{'time':'18:00','timeLimitIs':false,'id':'36'},{'time':'18:30','timeLimitIs':false,'id':'37'},{'time':'19:00','timeLimitIs':false,'id':'38'},{'time':'19:30','timeLimitIs':false,'id':'39'},
      {'time':'20:00','timeLimitIs':false,'id':'40'},{'time':'20:30','timeLimitIs':false,'id':'41'},{'time':'21:00','timeLimitIs':false,'id':'42'},{'time':'21:30','timeLimitIs':false,'id':'43'},{'time':'22:00','timeLimitIs':false,'id':'44'},{'time':'22:30','timeLimitIs':false,'id':'45'},{'time':'23:00','timeLimitIs':false,'id':'46'},{'time':'23:30','timeLimitIs':false,'id':'47'}],
      // tableData:[{"planName":null,
      //   "lx":null,
      //   "fs":null,
      //   "num":"412.00",
      //   "dayBudget":null,
      //   "sendPrice":"412412"}],
      tableData:[],
      changeDayLimitArr:[],
      numInArr:[],
      tableYL:false,
      cjLimit:{10:'预算限制',20:'预算限制',30:'出价限制'},
      nowIsValue:true,
      choosedTime:[],
      timeEvent:''

    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
     this.pubVue.$on('SHOW_DIALOG', data => {
      console.log('方法监听', data)
      this.show = data.showDialog
    })
    console.log(this.planType)
    console.log(this.checked)
  },
  methods: {
    yulanFn(){
      let _this = this

      if(_this.tableYL){
          _this.tableYL= false
      } else{
          _this.tableYL= true
      }
      _this.showYu()
    },
    showYu(){
      let _this = this
      
      _this.tableData = []
      _this.yulanList.forEach((obj,i)=>{
       _this.tableData.push(Object.assign({},obj,_this.changeDayLimitArr[i],_this.numInArr[i]))
      })
    },
    nowLimitFn(checked){
        this.nowIs = checked
              console.log(checked)

    },
    deleTimeFn(i,time){
      if (i >= 0) {
          this.timeList.splice(i, 1);
          this.choosedTime.splice(i, 1);
          this.timeLimitList.forEach(obj=>{
            if(obj.time == time){
              obj.timeLimitIs = false
            }
          })
          // this.timeLimitList[]
          
          // this.formData[i] = []
        }
    },
    addTimeFn(){
      let _this = this
      _this.timeList.push({})
      console.log(this.timeLimitV)
      // _this.choosedTime.push(_this.timeEvent)
      _this.choosedTime.forEach(obj=>{
        // _this.timeLimitList.forEach((ele,i,arr)=>{
          _this.timeLimitList[obj].timeLimitIs = true
        // })
      })
    },
    checkMoney (rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        // if (!/(^[1-9]\d*$)/.test(value)) {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (value > 100) {
            callback(new Error('不能超过100'))
          } else {
            callback()
          }
        }
      }, 1000)
    },
    checkMoneyM (rule, value, callback)  {
      if (!value) {
        
        return callback(new Error('不能为空'))
      }else{
        if(this.planType == 20&& value<200){
          return callback(new Error('不能小于200'))
        }
      }
      const mailReg =  /^[0-9]+(.?[0-9]{1,2})?$/;
      setTimeout(() => {
        if (!mailReg.test(value)) {
          callback(new Error('请输入数字值'))
        } else {
          callback()
        }
      }, 1000)
    },
    datelimitM(rule, value, callback)  {
      if (!value) {
        return callback(new Error('不能为空'))
      }
    },
     changeDayLimit(event,type,index){
      let _this = this,num
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
        //获取对象在数组的索引
          num = _this.timeLimitList.indexOf(_this.timeLimitList.filter(d => d.time ===event)[0])
          _this.choosedTime[index] = num
          _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
             
            }
             _this.choosedTime.forEach(obj=>{
              _this.timeLimitList[obj].timeLimitIs = true
            })
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
    changeDayLimitFn(event,type,index){
      let _this = this
      _this.changeDayLimitArr = []
      console.log(999,event)
      if(type=='addTime'){
          // _this.choosedTime.forEach(obj=>{
          //   _this.timeLimitList[obj].timeLimitIs = true
          // })
          _this.timeLimitList.forEach((ele,i,arr)=>{
            if(event==ele.time){
              arr[i].timeLimitIs = true
              _this.timeEvent = i
              _this.choosedTime[index] = _this.timeEvent
            }else{
              arr[i].timeLimitIs = false
              _this.choosedTime.forEach(obj=>{
                _this.timeLimitList[obj].timeLimitIs = true
              })
            }
          })
        }
        _this.yulanList.forEach((obj,i)=>{
        _this.changeDayLimitArr.push(Object.assign({},{lx:_this.dayLimitV!==''?_this.dayLimitList[_this.dayLimitV].text:''}))
      })
      
      
      console.log(_this.yulanList)
      _this.formData.scan_limit = ''
      _this.showYu()
    },
    numIn(){
       let _this = this,val = _this.formData.scan_limit
        _this.numInArr = []
      console.log(666,_this.formData.scan_limit)
      _this.yulanList.forEach((obj,i)=>{
            if(_this.dayLimitV == '1'){
              if(_this.dayLimitF == '0'){
                val = (Number(obj.price)*(1+Number(_this.formData.scan_limit)/100)).toFixed(2)
                  _this.numInArr.push(Object.assign({},{num:_this.formData.scan_limit,sendPrice:val,fs:'上调'}))

              }else if(_this.dayLimitF == '1'){
                  val = (Number(obj.price)*(1-Number(_this.formData.scan_limit)/100)).toFixed(2)

                  _this.numInArr.push(Object.assign({},{num:_this.formData.scan_limit,sendPrice:val,fs:'下调'}))

              }
            }else{
              _this.numInArr.push(Object.assign({},{num:val,sendPrice:val}))
            }
        
        
      })
      console.log(_this.tableData)
      _this.showYu()
    },
    onConfirm(formName) {
      let _this = this
       _this.$refs[formName].validate((valid) => {
          if (valid) {
            // _this.tableData.forEach(obj=>{
                // if(Number(obj.num)>=Number(_this.limitD)&&Number(_this.limitTop)>=Number(obj.num)){
                  console.log('成功2')
                  if(this.checked){
                    console.log('选中')
                    if(this.planType == 20){
                      _this.updateOppoDayBudgetThreeFn()

                    }else if(this.planType == 30){
                      _this.updateOppoGroupPriceFn()
                    }
                  }else{
                    console.log('未选中')
                    if(_this.value1 != []){
                      _this.addGroupModifyPriceSetFn()
                    }else{
                      _this.$message({
                        showClose: true,
                        message: "请选择日期",
                        type: 'error'
                      })
                    }
                  }
                // }else{
                //   _this.$message({
                //     showClose: true,
                //     message: "超出日限额限制",
                //     type: 'error'
                //   })
                // }
            // })
          } else {

            console.log('error submit!!');
            return false;
          }
        });
      
    },
    onCancel() {
      console.log(this.checked)
      this.show= false;
      this.tableYL= false;
      this.formData.scan_limit = ''
      this.dayLimitF = ''
      this.dayLimitV = ''
      this.tableData = []
      this.changeDayLimitArr =[]
      this.numInArr = []
      this.value1 = []
      this.formData.limitTop = ''
      this.formData.limitD = ''
      if(this.planType == 10){
        this.checked = false
      }else{
        this.checked = true
      }
      // this.checked = true
      this.timeList=[
        {
          'executeHourt':'',
          'newPrice':''

        }
      ],
      this.pubVue.$refs.multipleTable.clearSelection();
      // this.pubVue.grouplistFn()
      console.log('关闭')
      this.timeLimitList.forEach(obj=>{
        obj.timeLimitIs = false
      })
      console.log(this.checked)
    },
    addOrSetFn(type){
      
    },
    
    //保存-未立即执行接口	



                  // [
                  //   {
                  //     "adGroupId": 0,
                  //     "adGroupName": "",
                  //     "addGroupModifyPriceSetDetailDtoList": [
                  //       {
                  //         "executeHourt": "",
                  //         "week": 0
                  //       }
                  //     ],
                  //     "deptId": 0,
                  //     "executionTime": "",-不用传
                  //     "newPrice": 0,新出价金额
                  //     "oldPrice": 0,原始出价金额
                  //     "ownerId": 0,
                  //     "ownerName": "",
                  //     "planId": 0,
                  //     "planName": "",
                  //     "priceDown": 0,出价下限
                  //     "priceType": 0,出价类型1cpd 2:cpc 5:ocpc-先不要
                  //     "priceUp": 0,出价上限
                  //     "upPrice": "",百分比/具体值
                  //     "upPriceMode": 0,调价方式（1上调、2下调 ）
                  //     "upPriceStatus": 0,调价状态（0开启、1关闭）
                  //     "upPriceType": 0,调价类型（1具体值、2百分百）
                  //     "userId": 0,
                  //     "userName": ""
                  //   }
                  // ]
    addGroupModifyPriceSetFn(){
    // /adPlan/v1/updateOppoDayBudgetThree
    let _this = this,params1 = {},params = []
    console.log(_this.tableData)
    let timeString = _this.timeList
    let addOpenClosePlanDetailDtoList = []
      timeString.forEach(function(e,index){
        if(e!=''){
          addOpenClosePlanDetailDtoList.push(
            {executeHourt:e.executeHourt,newPrice:Number(e.newPrice)*100}
          )
        }
      })
      if(_this.planType == 10){
       _this.tableData.forEach((obj,i)=>{
         params1 = {
          'endTime':_this.value1[1]?_this.value1[1]:'2121-01-01',
          'startTime':_this.value1[0],
          'ownerId':obj.ownerId,
          'ownerName':obj.ownerName,
          'addGroupModifyPriceSetDetailDtoList':addOpenClosePlanDetailDtoList,
          'oldPrice':Number(obj.price)*100 || 0,
          'priceDown':parseInt(Number(_this.formData.limitD)*100),
          'priceUp':Number(_this.formData.limitTop)*100,
          'upPrice':Number(obj.num)*100,
          'upPriceMode':_this.dayLimitF+1,
          'upPriceStatus':obj.upPriceStatus,
          'upPriceType':_this.dayLimitV+1,
          'planType':_this.planType,
         }
          params[i] = Object.assign({},_this.userContent,params1)
       })
        
      }else{
        _this.tableData.forEach((obj,i)=>{  
          params1 = {
          'endTime':_this.value1[1]?_this.value1[1]:'2121-01-01',
          'startTime':_this.value1[0],
          'adGroupName':obj.adGroupName,
          'adGroupId':obj.adGroupId,
          'planId':obj.planId,
          'planName':obj.planName,
          'addGroupModifyPriceSetDetailDtoList':addOpenClosePlanDetailDtoList,
          'oldPrice':Number(obj.price)*100 || 0,
          'priceDown':parseInt(Number(_this.formData.limitD)*100),
          'priceUp':Number(_this.formData.limitTop)*100,
          'upPrice':Number(obj.num)*100,
          'upPriceMode':_this.dayLimitF+1,
          'upPriceStatus':obj.upPriceStatus,
          'upPriceType':_this.dayLimitV+1,
          'planType':_this.planType},
          
          // params = Object.assign({},_this.clone(_this.params), params1)
          params[i] = Object.assign({},_this.userContent,params1)

        })
      } 
      
    // addPlanDailyMoneySet
    $http.axios.post("/api/advertisement/adGroup/v1/addGroupModifyPriceSet",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.pubVue.adPlanIdList = []
          if(_this.planType == 10){
            setTimeout(function(){
            _this.pubVue.oppoAccountList()
            },100)
          }else if(_this.planType == 20){
            setTimeout(function(){
            _this.pubVue.getAccountPlanListFn()
            },100)
          }else{
            setTimeout(function(){
            _this.pubVue.grouplistFn()
            },100)
          }
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
        _this.onCancel();
      })
    },
     //保存-立即执行接口	"dayBudget": 0,日预算 (dayLimit=1 时dayBudget必填 单位（分）)
                  // "dayLimit": "",0 不限制 1 限制
                  // "deptId": 0,
                  // "ownerId": 0,
                  // "planIds": [],
                  // "userId": 0,
                  // "userName": ""
    updateOppoDayBudgetThreeFn(){
    // /adPlan/v1/updateOppoDayBudgetThree
    let _this = this,params = []
    console.log(_this.tableData)
    _this.tableData.forEach((obj,i)=>{
      params.push({
      'dayLimit':1,
      'dayBudget':Number(obj.sendPrice)*100,
      'planName':obj.planName,
      'planId':obj.planId,
      'deptId':_this.userContent.deptId,
      'ownerId':_this.userContent.ownerId,
      'userId':_this.userContent.userId,
      'userName':_this.userContent.userName,
      'status':Number(!_this.nowIsValue)})
    })

    $http.axios.post("/api/advertisement/adPlan/v1/updateOppoDayBudgetThree",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
            setTimeout(function(){
              _this.pubVue.getAccountPlanListFn()
            },100)
          
          _this.pubVue.adPlanIdList = []
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
        _this.onCancel();
    })
    },
    //保存-立即执行接口"adGroupId": 0,
                    // "adGroupName": "",
                    // "ownerId": 0,
                    // "price": 0,
                    // "userId": 0,
                    // "userName": ""
   updateOppoGroupPriceFn(){
    //  /adPlan/v1/addPlanDailyMoneySet
     let _this = this,params1 = {},params = []
    console.log(_this.tableData)
    _this.tableData.forEach((obj,i)=>{
      params1 = {'adGroupName':obj.adGroupName,
      'adGroupId':obj.adGroupId,
      'price':Number(obj.sendPrice)*100,
      'status':Number(!_this.nowIsValue)
      }
      params[i] = Object.assign({},_this.userContent,params1)

    })

    $http.axios.post("/api/advertisement/adGroup/v1/updateOppoGroupPrice",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
           setTimeout(function(){
              _this.pubVue.grouplistFn()
            },100)
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
        _this.onCancel()
      })
   },
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}

.el-form--inline .el-form-item.priceLimt{
  display: inline-block;
  /* vertical-align: middle; */
}
.priceLimt i{
  color:red;
}
.el-form--inline .el-form-item.dateConent{
  display: block;
  margin: 15px 0;
}
#app .el-dialog__body .el-form.el-form--inline{
  text-align: left!important;
}
/*新版*/
.dialogIcon{
  position: absolute;
  top:0;
  left:50%;
  margin-left:-35px;
  margin-top:-35px;
}
::v-deep .editInput .el-input{
  width:400px;
}
.costAdd{
  width:100%;
  height:26px;
  line-height:26px;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  color:#48916F;
  font-size:12px;
  text-align:center;
  cursor: pointer;
}
.addList{
  position: relative;
  border:1px dashed #DCEEE8;
  border-radius:4px;
  margin:10px 0;
  padding:10px;
  box-sizing: border-box;
}
.deleteBtn{
  position: absolute;
  font-size:18px;
  top:-13px;
  right:-11px;
  color:rgb(212, 24, 24);
  cursor: pointer;
}
::v-deep .addList .el-form-item--mini.el-form-item{
  margin:0 5px;
}
::v-deep .addList .el-input--mini .el-input__inner{
  width:215px!important;
}
.priceLimtSpan{
  font-size:12px;
  color:#330000;
  width:80px;
  display: inline-block;
  padding-left:10px;
  box-sizing: border-box;
}
.priceLimtSpan i{
  padding-right:5px;
}
.ylTable{
  width:100%;
  margin-bottom:20px;
  margin-top:-10px;
}
.priceLimtSpanDay{
  font-size:12px;
  color:#330000;
  display: inline-block;
  padding-left:10px;
  box-sizing: border-box;
  width:120px;
  margin-bottom:15px;
}
</style>

