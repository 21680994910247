<template>
    <div class="authority common-page">
       <PocCardTable>
         <template slot="headerTitle">
          <div class='hl_header'>
            <div class="hl_breadCrumb hl_headerLeft" >
              <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
                <el-breadcrumb-item :to="{ path: '/vivoAccount-management/AccountSelectionVivo' }">计划管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/vivoAccount-management/AccountSelectionVivo' }">账户选择</el-breadcrumb-item>
                <el-breadcrumb-item>广告计划</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <div class="hl_headerRight">
              <el-button type="primary" size="small" @click="warmIs('dayLimit',url[1])">批量日限额</el-button>
              <el-dropdown trigger="click" @command = "changeStatusList">
                <el-button type="primary" size="small" @click="updateOppoSet('planStatus')">修改状态</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown trigger="click" @command = "upAdPlanWarningSwitchFn">
                <el-button type="primary" size="small" @click="warmIs('warningOpen',url[0])">
                    预警开关
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for='(item,i) in warningOpenList' :key="i" :command='i'>{{item}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> 
              <el-button type="primary" size="small" @click="updateOppoSet('startStop')">批量启停设置</el-button>

              <el-button type="primary" size="small" @click = "zdyFn">自定义列</el-button>
              <el-button type="primary" size="small" @click = "exportPlan">下载报表</el-button>
            </div>
          </div>  
          <div class="chanelTab">
            <span class="channel_vivo channelActive" >广告计划</span>
            <span class="channel_vivo" @click="clickChangeChannel('adTeamVivo')">广告组</span>
            <span class="channel_vivo" @click="clickChangeChannel('advertVivo')">广告</span>
          </div>
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="ruleForm" class="demo-form-inline fL">
                  <el-form-item label="">
                    <el-date-picker
                      v-model="value1"
                      type="daterange"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select v-model="senceType" clearable placeholder="请选择媒体类型" >
                      <el-option
                        v-for="(item,i) in senceTypeOptions"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="">
                    <el-select v-model="statusType" clearable placeholder="请选择计划状态" >
                      <el-option
                        v-for="(item,i) in statusTypeOptions"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "planName" clearable placeholder="请输入计划名称"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-input v-model = "planIdSearch" clearable oninput="value=value.replace(/^\.+|[^\d.]/g,'')" placeholder="请输入计划ID"></el-input>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "getAccountPlanListFn(page,value1,senceType,statusType,planName,planIdSearch)">查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
            </div>
            <div class="clearfix">
              <div class="materiaContent">
                <el-table
                  ref="multipleTable"
                  style=" cursor:pointer;"
                  :data="tableData"
                  tooltip-effect="dark"
                  @cell-click="clickcell"
                  :cell-style="setRowStyle"
                  @selection-change="handleSelectionChange">
                  <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column 
                  width='200'
                  label="广告计划名称">
                    <template slot-scope="scope">
                      <span class="adTeamName">{{scope.row.name}}</span>
                      <i class="el-icon-edit adTeamEditBtn" @click="editAdTeamName(scope)"></i> 
                    </template>
                  </el-table-column>
                  <el-table-column 
                  width='100'
                  label="广告类型">
                    <template slot-scope="scope">
                      <span v-if="scope.row.campaignType ==0">展示广告</span>
                      <span v-else>搜索广告</span>
                    </template>
                  </el-table-column>
                  <el-table-column 
                  prop='statusDesc'
                  width='100'
                  label="状态">
                    <template slot-scope="scope">
                      <span class="statusBtn" v-if="scope.row.statusDesc=='投放中'">{{scope.row.statusDesc}}</span>
                      <span class="statusOtherBtn" v-else>{{scope.row.statusDesc}}</span>
                    </template>
                  </el-table-column>
                  <template >
                    <el-table-column
                    v-for="(item, index) in titleList"
                    :key="index"
                    :prop="item.prop"
                    :label="item.name"
                    :align="item.align || 'center'"
                    :show-overflow-tooltip="item.overHidden || true">
                      <template slot-scope="scope">
                        <!-- // 这里通过插槽实现自定义列 -->
                        <slot
                          v-if="item.slot"
                          :name="scope.column.property"
                          :row="scope.row"
                          :$index="scope.$index"/>
                        <div v-else :disabled = "scope.row.disabledIs">
                          <span v-if='scope.column.property == "pauseState" || scope.column.property =="openClosePlan" || scope.column.property == "mediaType"'>
                            {{ showStatusList[scope.column.property][scope.row[scope.column.property]] }}
                            <i v-if = 'scope.column.property =="openClosePlan"'
                              :class = "{'isSwitch':scope.column.property =='openClosePlan','active':scope.column.property =='openClosePlan'&&scope.row[scope.column.property]==1}"></i>
                          </span>
                          <span v-else>{{ scope.row[scope.column.property] }}</span>
                        </div> 
                        <template v-if='item.prop=="pauseState"'>
                          <el-switch
                            v-model="scope.row.pauseState"
                            class="switchStyle"
                            :active-value="0"  
                            :inactive-value="1"
                            active-color="#48916F"
                            inactive-color="#EAEFED"
                            @change = "changeStatus(scope.row.pauseState,scope)">
                          </el-switch>
                        </template>
                      </template>
                    </el-table-column>
                  </template>
                  <el-table-column 
                    width='120'
                    label="推广目标">
                    <template slot-scope="scope" >
                      <span v-if="scope.row.adType == 1">普通网址</span>
                      <span v-else-if="scope.row.adType == 2">应用下载</span>
                      <span v-else-if="scope.row.adType == 3">动态商品</span>
                      <span v-else-if="scope.row.adType == 8">快生态</span>
                    </template>
                  </el-table-column>
                  <el-table-column 
                    width='120'
                    label="限额（元/天）" data-type = 'dailyBudget'>
                    <template slot-scope="scope" >
                      <span class="adTeamName">{{scope.row.dailyBudget}}</span>
                      <i class="el-icon-edit adTeamEditBtn"></i> 
                    </template>
                  </el-table-column>
                  <el-table-column 
                    prop='createDate'
                    width='120'
                    label="创建时间">
                    <template slot-scope="scope" >
                      <span class="adTeamName">{{scope.row.createDate | dateFormat}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column 
                  fixed = 'right'
                  align="center"
                  width='300'
                  :show-overflow-tooltip="true"
                  label="操作">
                    <template slot-scope="scope">
                      <el-button class="solid-tag-success" data-type = 'qtBtn' plain size="mini">启停</el-button>
                      <el-button class="solid-tag-warning" data-type = 'tjBtn' plain size="mini">调价</el-button>
                      <el-button class="solid-tag-instructions" data-type = 'yjBtn' plain size="mini">日限额预警</el-button> 
                      <el-popconfirm
                        title="您是否确认删除，删除后不可恢复？"
                        @confirm="deletePlanFn(scope.row.id)">
                        <template #reference>
                          <el-button class="newBtn_delRed" data-type = 'delBtn' plain size="mini">删除</el-button>
                        </template>
                      </el-popconfirm>
                  </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageCount"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
              <el-dialog
              title="确认下载吗"
              :visible.sync="exprtVisible"
              width="280px">
              <span class="el-icon-loading uploading uploadIcon" v-show="uploadingShow"></span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="exprtVisible = false">取 消</el-button>
                <el-button type="primary" @click="downLoadTable">确 定</el-button>
              </span>
            </el-dialog>

            <!-- 修改广告组名称 -->
            <el-dialog title="修改计划" :visible.sync="editNameVisible" width="40%">
              <el-form :model="editNameForm">
                <el-form-item label="计划名称" label-width="80px">
                  <el-input v-model="editName"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="editNameVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditName">确 定</el-button>
              </div>
            </el-dialog>

            </div>
         </template>
        <template slot="headerGroup">
        </template>
      </PocCardTable>
      <dayLimitSingleDialog :visible.sync="showSingleDialog" :userContent = 'userContent' :yulanList = 'yulanList' :limitIs = "limitIs" :pubVue="pubVue"></dayLimitSingleDialog>
      <!-- <startLimitSingleDialog :visible.sync="showDialog" :nowIs="nowIs" :yulanList = "yulanList" :dialogTitle="dialogTitle" :limitIs = "limitIs" :userContent = 'userContent' :planType = "planType" :pubVue="pubVue"></startLimitSingleDialog> -->
      <startLimitSingleVivoDialog :visible.sync="showDialog" :nowIs="nowIs" :yulanList = "yulanList" :dialogTitle="dialogTitle" :page = 'page' :limitIs = "limitIs" :userContent = 'userContent' :dayType = "dayType" :pubVue="pubVue"></startLimitSingleVivoDialog>
      <earlyWarningSetVivoDialog ref = 'earlyWarningDialog' :visible.sync="showEarlyWarningSetDialog" :userContent = 'userContent' :pubVue = 'pubVue'></earlyWarningSetVivoDialog>
      <zdyVivo :visible.sync="showzdyDialog" ref = 'zdyVivo' :pubVue="pubVue" :typeName = "typeName"></zdyVivo>
      <!-- <timeDialog :visible.sync="timeDialog" :objIdType = 10 :userContent = 'userContent' :yulanList = "yulanList" :pubVue="pubVue"></timeDialog> -->
      <timeVivoDialog :visible.sync="timeVivoDialog" :objIdType = 10 :userContent = 'userContent' :yulanList = "yulanList" :pubVue="pubVue"></timeVivoDialog>
      <startStopVivoDialog ref = 'startStopDialogIs' :visible.sync="startStopDialog" :pubVue="pubVue"></startStopVivoDialog>
      <tiaojiaDialog ref = 'tiaojiaDialogIs' :visible.sync="tiaojiaDialog" :planType = "planType" :pubVue="pubVue"></tiaojiaDialog>
      <alertStartStopDialog ref = 'alertStartStopDialog' :visible.sync="alertStartStopDialogIs" :planName = "planName" :pubVue="pubVue" :command = "command+''" :typeName = "'plan'"></alertStartStopDialog>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  
  import { $http } from '@/api/http';
  import config from '@/api/config';
  import dayLimitDialog from '@/views/dialog/dayLimitDialog'
  import earlyWarningSetVivoDialog from '@/views/dialog/earlyWarningSetVivoDialog'
  import dayLimitSingleDialog from '@/views/dialog/dayLimitSingleDialog'
  import startLimitSingleVivoDialog from '@/views/dialog/startLimitSingleVivoDialog'
  import zdyVivo from './zdyVivo'
  import timeVivoDialog from '@/views/dialog/timeVivoDialog'
  import startStopVivoDialog from '@/views/dialog/startStopVivoDialog'
  import tiaojiaDialog from '@/views/dialog/tiaojiaDialog'
  import alertStartStopDialog from '@/views/dialog/alertStartStopDialog'
  import Qs from 'qs';
  export default {
    components: {
      earlyWarningSetVivoDialog,
      zdyVivo,
      dayLimitSingleDialog,
      startLimitSingleVivoDialog,
      timeVivoDialog,
      startStopVivoDialog,
      tiaojiaDialog,
      alertStartStopDialog,
    },
    props:{
      newVue:Object,
    },
    data() {
      return {
        pubVue: this,//空vue，用于子组件之间传值
        showDialog:false,
        nowIs:false,
        showEarlyWarningSetDialog:false,
        showSingleDialog:false,
        showzdyDialog:true,
        timeVivoDialog:false,
        startStopDialog:false,
        alertStartStopDialogIs:false,
        tiaojiaDialog:false,
        planStatus:false,//计划状态下拉列表是否展示
        exprtVisible:false,//
        uploadingShow:false,//确认导出弹框
        planType:20,
        dayType:2,//批量日限额：2->计划
        dialogTitle:'批量日限额',
        // userContent:{},//登录用户信息&弹框默认
        qdoptions:{goal:[{text:'网页推广',id:'1'},{text:'应用推广',id:'2'}],
                  userStatuList:[{text:'启动中',id:'0'},{text:'暂停中',id:'1'},{text:'余额不足',id:'2'},{text:'达到账户日预算',id:'3'},{text:'达到计划日预算',id:'4'}]},//选择状态
        warningOpenList:[],//['开启','关闭']
        value1: [],//时间
        tableOption:[],
        title:'',
        titleList:[
        // {name:'广告计划名称',prop:'name'},
        {name:'广告计划ID',prop:'id'},
        {name:'广告计划类型',prop:'mediaType'},//0-应用商店 1-非应用商店 2-广告联盟
        // {name:'广告类型',prop:'campaignType'},//0-展示广告 1-搜索广告
        {name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
        {name:'设置计划',prop:'openClosePlan'},//是否设置启停:1-是 0-否
        // {name:'限额(元/天)',prop:'dailyBudget'},//1-不限额显示 有限额以元为单位
        // {name:'状态',prop:'statusDesc'},
        {name:'预警状态',prop:'warningStatus'},//0-正常 1-禁用 2-删除
        ],
        showStatusList:{
                        pauseState:[''],//0开启1关闭
                        openClosePlan:['否','是'],
                        mediaType:['应用商店','非应用商店','广告联盟']},
        planName:'',//请输入计划名称查找
        planId:'',//请输入计划ID查找
        channel:'',//请选择推广目的
        channeln:'',//请选择状态
        channelm:'',//请选择预警状态
        tableData:[],
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dataList:'',
        userId:'',//用户ID
        userName:'',//用户名
        deptId:'',//
        params:{},//入参
        userContent:{},//登录用户信息&弹框默认
        yulanList:[],//批量日限额预览
        disabledIs: false,//switch是否可操作
        warmType:false,//判断是否设置过预警消息推送
        url:['/account/v1/checkAccountWarning',
            '/modifyPrice/valid'],//预警是否开启、是否设置过定时调价计划
        msgContent:'',//确认添加新计划
        msgContent1:'',//确认投放开关关闭/计划状态关闭
        limitIs:false,//选择的”计划日限额“类型是否有——”未设置“类型
        itemValue:'',
        startSwitch:'',
        ssDetailParams:[],
        typeName:1,
        adPlanIdList:[],
        dayLimitIs:false,
        closePlanList:[],//校验是否有存在的启停计划
        myHeaders: {Authorization:''},
        fileList:{},
        command:'',
        //vivo
        senceType:'',//商店非商店等类型model
        senceTypeOptions:[{//所有类型
          value:0,
          label:'应用商店'
        },{
          value:1,
          label:'非应用商店'
        },{
          value:2,
          label:'广告联盟'
        }],
        statusType:'',//状态类型
        statusTypeOptions:[{//所有状态类型
          value:0,
          label:'所有数据'
        },{
          value:1,
          label:'已删除 '
        },{
          value:2,
          label:'已暂停'
        },{
          value:3,
          label:'余额不足'
        },{
          value:4,
          label:'到达账户日限额'
        },{
          value:5,
          label:'到达计划日限额'
        },{
          value:6,
          label:'投放中'
        }],
        reportType:'1',
        editNameVisible:false,//修改广告计划名称
        editNameForm:{},
        editName:'',
        editId:'',
        planIdSearch:'',
        validDate:null,//当前时间



      }
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      this.params.userId  = this.userId
      this.userContent.userId = this.userId
      this.params.userName  = this.userName
      this.userContent.userName = this.userName
      this.params.deptId = this.deptId
      this.userContent.deptId = this.deptId
      this.params.ownerId = this.$route.query.id
      this.userContent.ownerId = this.$route.query.id
      this.userContent.ownerName = this.$route.query.name
      // this.senceType = 1//默认选中第一个
      // this.statusType = 1//默认选中第一个
      // this.getAdverList()//广告计划列表
      // this.getAccountPlanListFn()
      console.log('planVivo')
      this.getAccountPlanListFn()
      this.$refs.zdyVivo.allWords(1)


    },
    methods: {
      //vivo
      //tab切换
      clickChangeChannel(url){
        this.$router.push({
          path: '/vivoAccount-management/'+url,
          query: {
            id: this.userContent.ownerId,
            name:this.userContent.ownerName,
          }
        })
      },

      //广告计划列表
      getAdverList(){
        let that = this
        // $http.axios.post("/api/advertisement/adPlan/v1/addOpenClosePlan",that.addParams).then(res=> {
        //   if(res.data.code == 200){
        //     this.$router.go(-1);
        //   }  else {
        //     that.$message({
        //       showClose: true,
        //       message: res.data.msg,
        //       type: 'error'
        //     })
        //   }
        // })
      },

      //oppo
      getId(){
        this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
        this.uploadId = this.$route.params && this.$route.params.id
      },
      zdyFn(){
        this.$refs.zdyVivo.generateTimes(1)
      },
      exportPlan(){
        this.exprtVisible = true
      },
      //文件上传成功时的钩子
      onSuccess(response, file, fileList, i) {
        let that = this,dataSource =[],keywords = []
        if(response.code==200){
          that.getAccountPlanListFn()
        }else{
          that.$message({
            showClose: true,
            message: response.msg,
            type: 'error'
          })
        }
        this.$refs.upload.clearFiles()
      },
      //下载报表
      downLoadTable(){
        let _this = this,params ={},date = new Date().getTime(),myDate = new Date()
        let Time2 = myDate.toISOString().slice(0,10)//推荐 yyyy-MM-dd
        //延伸  取yyyyMMdd
        let Time3 = Time2.replace(/[^0-9]/ig,'').substring(0,8)
        params = {
          'ownerId':_this.params.ownerId,
          'deptId':_this.deptId,
          'userId':_this.userId,
          'userName':_this.userName,
          'startDate':_this.value1[0]||Time2,
          'endDate':_this.value1[1]||Time2
        },
            
        _this.uploadingShow = true
        $http.axios.post("/api/vivo/advertisingPlan/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
        // $http.axios.post("/guanjia/advertisingPlan/export",params,{responseType: 'blob',dataType:"json",async: false}).then(function(response) {
            _this.exprtVisible = false 
            //添加{responseType: 'blob'}  导出文件以下代码都是
            let blob = response.data
            let reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onload = e => {
              let a = document.createElement('a')
              a.download = "计划报表"+myDate+".xls"
              a.href = e.target.result
              document.body.appendChild(a)
              a.click()
              document.body.removeChild(a)
              _this.uploadingShow = false
            }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })    
      },
      //弱提示
      alertMsgFn(msg,type){
        Vue.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
       // 点击一个单元格 账户余额(rebateBal)、今日消耗(cashCost)、计划日预算(accDayBudget)
       clickcell(row, column, cell, event){
        let yulanList = []
        let yjType = event.target.parentNode.dataset.type
        let className = event.target.className
        if(yjType == 'yjBtn'){
          this.adPlanIdList = [row.planId]
          yulanList.push({'planName':row.name,'planId':row.id,'price':row.dailyBudget})
          this.yjCheck('yjBtn',this.url[0],row.id,row.name,row.dailyBudget)  
        }else if(yjType == 'qtBtn'){
          this.goPlan('/vivoAccount-management/StartStopVivo')
          // ,{
          //   id:row.ownerId,
          //   name:row.name,
          // })
        }else if(yjType=='tjBtn'){
          this.goPlan('/vivoAccount-management/PriceModifyPlanVivo')
          // this.goPlan('/vivoAccount-management/PriceModifyPlanVivo',{
          //   planId:row.id,
          //   planName:row.name,
          // })
        }
        if(className=="adTeamName"){
          this.goPlan('/vivoAccount-management/adTeamVivo',{
            type:row.id,
          })
        }
        if(column.label == "限额（元/天）"){
          this.adPlanIdList = [row.planId]
          this.dayLimitCheckFn('dayLimit',this.url[1],row.id,row.name,'dailyBudget')
          // this.yjCheck('dayLimit',this.url[1],row.id,row.name,'dailyBudget')  
          yulanList.push({'planName':row.name,'planId':row.id,'dayBudget':row.dailyBudget})
        }
        if(column.type=="selection"){
          this.$refs.multipleTable.toggleRowSelection(row)
        }
        // if(column.property=="startSwitch"){//openClosePlan
        if(column.property=="openClosePlan"){//openClosePlan
          let params = {"planId": row.id}
          this.ssDetailParams = {}
          if(row.openClosePlan == 1){
            // "userId": 0,
            // "userName": "",
            // "deptId": 0,
            // "pageIndex": 0,
            // "pageSize": 0,
            // "adType": 0,
            // "ownerId": "",
            // "name": "",
            // "adPlanId": 0
            this.ssDetailParams={"pageIndex":1,
                                  "pageSize":100,
                                  "planIds": row.openClosePlanIds,
                                  "deptId":this.deptId,
                                  "userId":this.userId,
                                  "userName":this.userName}
            // this.getOpenCloseIdFn(params)
            this.startStopDialog = true
            // _this.ssDetailParams.openClosePlanId=res.data.content
            this.$refs.startStopDialogIs.timeSlotDetail(this.ssDetailParams)
          }
        }
        this.yulanList = yulanList
      }, 
       // 分页
      handleSizeChange(val) {
        let _this = this
        _this.pageCount = val
        _this.findAll(_this.page)
      },
      handleCurrentChange(val) {
        this.page = val
        this.findAll(val)
      },
      //分页方法
      findAll (page) {
        //获取数据接口（调用封装的接口）
        this.page = page
        // this.getAccountPlanListFn(page,this.planName,this.planId,this.channel,this.channeln,this.value1)
        this.getAccountPlanListFn(page,this.value1,this.senceType,this.statusType,this.planName,this.planIdSearch)
      },
      //选择行
      handleSelectionChange(val){
        let _this = this
        let  yulanList = [],objList = [],startSwitch = [],numStr =0 ,num = 0
        _this.limitIs = false
        _this.adPlanIdList = []
        val.forEach((obj,i,arr)=>{
          let cur = obj.pauseState
          _this.adPlanIdList.push(obj.id)
          // startSwitch.push(obj.startSwitch)
          startSwitch.push(obj.openClosePlan)
          
          if(obj.dailyBudget =='不限'){
            _this.limitIs = true
            numStr++
          }else{
            num++
          }
          yulanList.push({ 'adGroupName':obj.adGroupName,'adGroupId':obj.adGroupId,'planName':obj.name,'planId':obj.id,'upPriceStatus':0,'price':obj.dailyBudget})//upPriceStatus:调价状态;status2:计划状态
          objList[cur] = cur
        })
        _this.dayLimitIs = true
        if(val.length == num || val.length ==numStr){
            _this.dayLimitIs = true
          }else{
            _this.dayLimitIs = false
            // _this.$message({
            //   showClose: true,
            //   message: "请选择统一为不限或有具体日限额的数据",
            //   type: 'error'
            // })
          }
        // objList.forEach((obj,i)=>{
        //   if(objList.length == 2&&objList[0]!=undefined){
        //   _this.planStatus = false
        //   _this.warningOpenList = []
        //   _this.$message({
        //       showClose: true,
        //       message: "选择的计划状态不一致，选择同一种计划状态后才可操作",
        //       type: 'error'
        //     })        
        //   }else{
        //     _this.planStatus = true
        //   }        
        // })
        _this.startSwitch = startSwitch
        _this.yulanList = yulanList
        if(val.length == 0){
          _this.warningOpenList = []
          _this.planStatus = false
        }else{
          _this.planStatus = true
        }
      },
      
      //批量删除计划
      delsFn(){
        let _this = this,params = new URLSearchParams()
        params.append('advertiserId',_this.userContent.ownerId)
        params.append('ids',_this.adPlanIdList.join(','))

        $http.axios.post("/api/vivo/advertisingPlan/dels",params).then(res=> {
          // _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
        if(res.data.code==200){
          _this.tableYL= false;
            setTimeout(function(){
              _this.getAccountPlanListFn()
            },100)
          _this.adPlanIdList = []
        }else{
          _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
        }
        _this.onCancel();
    })
        
        console.log(_this.adPlanIdList,_this.userContent.ownerId)
      },
      // 批量日限额取消limitIs为false
      // 批量启停设置
      updateOppoSet(type){
        let _this = this
        let planIdList = []//声明一个空数组
        _this.adPlanIdList.forEach(function(v,i,arr){
          let bool = arr.indexOf(v,i+1)
          if(bool === -1){
            planIdList.push(v)
          }
        })
        if(_this.adPlanIdList.length!=0){
          _this.params.adPlanIdList = _this.adPlanIdList
          if(type == 'dels'){

            let text = '删除广告计划将同步删除该广告计划下的所有的广告组，且无法恢复，是否仍要删除？'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type:'warning'
              }).then(()=>{
                _this.delsFn()
              }).catch(()=>{
                
              })
          }else{
            _this.btnIsFn(_this.params,type)
          }
          return true
        }else{
          _this.$message({
              showClose: true,
              message: "请勾选数据",
              type: 'error'
            })
          }
      },
      // 按钮判断
      btnIsFn(params,type){
        let _this = this
        if(type == 'startStop'){
          _this.timeVivoDialog = true

        }else if(type == 'planStatus'&&_this.planStatus){
            _this.checkOpenCloseFn(params.adPlanIdList)
            _this.warningOpenList = ['开启','关闭']
        }else if(type == 'dayLimt'){
        }
      },
     // 时间戳转换器
      formatDate(now) { 
        var year=now.getFullYear(); 
        var month=now.getMonth()+1>9?now.getMonth()+1:'0'+(now.getMonth()+1); 
        var date=now.getDate()>9?now.getDate():'0'+now.getDate(); 
        var hour=now.getHours()>9?now.getHours():'0'+now.getHours(); 
        var minute=now.getMinutes()>9?now.getMinutes():'0'+now.getMinutes(); 
        var second=now.getSeconds()>9?now.getSeconds():'0'+now.getSeconds(); 
        return year+"-"+month+"-"+date+" "+hour+":"+minute+":"+second; 
      } ,
      //开关按钮启停计划校验
      changeStatus(value,scope) {
        //先把值赋为原来的值，保证swich状态不变
        let _this = this,adPlanIdList,params,time
        adPlanIdList=[scope.row.id].join(',')
        _this.adPlanIdList = [scope.row.id]
        params = {'ids': adPlanIdList,'type':10}
        this.tableData[scope.$index].pauseState = value == 0?1:0//需求更改暂时注释
        // $http.axios.post("/api/vivo"+_this.url[1],Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        $http.axios.post("/api/vivo/openClose/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code==200){
           if(res.data.data!= null){
              _this.planName = res.data.data
              _this.command = value
              _this.alertStartStopDialogIs = true
              _this.validDate = _this.formatDate(new Date())


                 
           }else{
            this.tableData[scope.$index].pauseState = value
            this.updateOppoAdPlanStatusFn(scope.row.pauseState,scope,'继续')//需求更改暂时打开
           }
          }
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //vivo校验当日是否存在启停计划
    checkOpenCloseFn(adPlanIdList){
      adPlanIdList=adPlanIdList.join(',')
        let _this = this,params = {'ids': adPlanIdList,'type':10},planName
      $http.axios.post("/api/vivo/openClose/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        if(res.data.code==200){
          if(res.data.data!= null){
            _this.planName = res.data.data
            _this.validDate = _this.formatDate(new Date())
            planName = res.data.data.split(',')
            planName.forEach(obj=>{
              _this.closePlanList.push(obj)
            })
          }else{
          _this.closePlanList = []
          }
        }
      }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },

      changeStatusList(command){
        let _this = this,planName = [],text = ''
          _this.closePlanList.forEach((obj,i)=>{
              planName.push(obj)
          })
          if(planName.length>0){
            _this.planName = planName.join(',')
            _this.command = command
            _this.alertStartStopDialogIs = true     
          }else{
            this.updateOppoAdPlanStatusFn(command,'','继续')
          }
      },      
      creatPlan(url,type){
        this.$router.push({
          path: url,
          query: {
            ownerId: this.userContent.ownerId,
            ownerName:this.userContent.ownerName,
            type:type
          }
        })
      },
      
      //重置
      resetForm(){
        let _this = this
        _this.value1 = ''
        _this.senceType = ''
        _this.statusType = ''
        _this.planName = ''
        _this.planIdSearch = ''
        _this.getAccountPlanListFn()
      },

      //计划列表
      getAccountPlanListFn(page,value1,senceType,statusType,planName,planIdSearch){
        // page,value1,senceType,statusType,planName,planIdSearch
         //获取查询有权限的所有帐号接口
         console.log(planIdSearch)
         console.log(typeof planIdSearch)
        let _this = this,params,pagetTxt
        pagetTxt = {
          'pageSize' : _this.pageCount,
          'pageIndex' : page || 1,
          'mediaType':senceType,
          'campaignStatus':statusType,
          'name':planName,
          'ids':planIdSearch
        }
        if(value1 != undefined&&value1 != ''){
          pagetTxt.startDate = value1[0]
          pagetTxt.endDate = value1[1]
        }
        params = Object.assign({},_this.clone(_this.userContent), pagetTxt)//之前是params，因为里面有groupId所以换成了userContent
        // $http.axios.post("/guanjia/advertisingPlan/list",params).then(res=> {
        $http.axios.post("/api/vivo/advertisingPlan/list",params).then(res=> {
          _this.titleList = _this.titleList.concat(_this.tableOption)//将自定义列表跟固定列表合二为一
          if(res.data.code==200){
            if(res.data.data.list != null){
              // 数据修改，余额、消耗、日预算初始值如果为空的状态
              res.data.data.list.forEach((obj,i) => {
                if(obj.warningStatus == null){
                  obj.warningStatus = '未设置'
                }else if(obj.warningStatus == 0){
                  obj.warningStatus = '开'
                }else if(obj.warningStatus == 1){
                  obj.warningStatus = '关'
                }
                if(obj.dailyBudget == '-1'){
                  obj.dailyBudget = '不限'
                }
                if(obj.openClosePlanIds!=""){
                  obj.openClosePlan = 1
                }else{
                  obj.openClosePlan = 0
                }
            });
            _this.tableData = res.data.data.list
            _this.totalRow = res.data.data.pageInfo.rowCount
            }else{
              _this.tableData = []
            _this.totalRow = 0
            }
            // _this.planId = ''
            // _this.planName = ''
            // _this.channel = ''
            // _this.channeln = ''
            // _this.value1 = ''
          }else{
            let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })    
          }

        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })

      },
      
      //预警开关
      upAdPlanWarningSwitchFn(command){
        let _this = this,type,params,params1
        params1={'adPlanIdList':_this.adPlanIdList,
          'warningSwitch' : command,}
        params = Object.assign({},_this.clone(_this.userContent), params1)//之前是params，因为里面有groupId所以换成了userContent
        $http.axios.post("/api/vivo/adPlan/v1/updateWarningSwitch",params).then(res=> {
          _this.warningOpenList = []
          _this.planStatus = false
          if(res.data.code==200){
            _this.adPlanIdList = []
            _this.$refs.multipleTable.clearSelection();          
            _this.getAccountPlanListFn(this.page)
            type = "success"
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //计划状态设置
      updateOppoAdPlanStatusFn(command,scope,status){
        console.log(command)
        let _this = this,type,params,planIdsString,msg
        planIdsString = _this.adPlanIdList.join(',')
        if(status == '继续'){
          params = {
            'advertiserId':this.$route.query.id,
            'ids':planIdsString,
            'startAndStop':command,
          }
        }else{
          params = {
            'advertiserId':this.$route.query.id,
            'ids':planIdsString,
            'startAndStop':command,
            'validDate':_this.validDate,//后续不继续执行校验的时间
            'extendFlag':1//不继续的时候传此参数

          }      
        }
        $http.axios.post("/api/vivo/advertisingPlan/startAndStop",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          _this.warningOpenList = []
          _this.planStatus = false
          _this.$refs.multipleTable.clearSelection();          
          _this.adPlanIdList = []
          if(res.data.code==200){
            _this.getAccountPlanListFn(_this.page)
            type = "success"
          }else{
            if(res.data.data!=null){
              res.data.data.forEach(obj=>{
                msg+=obj.ownerId
                msg+=obj.msg
              })
                _this.$message({
                showClose: true,
                message:msg,
                type: 'error'
              })
              this.tableData[scope.$index].pauseState = command?0:1

            }else{
              _this.$message({
                showClose: true,
                message: res.data.msg,
                type:  'error'
              })
            }
          }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      
      deletePlanFn(id){
        let _this = this,params = {},type = ''
        params = {
          'ids':String(id),
          'advertiserId':String(_this.$route.query.id)
        }
        $http.axios.post("/api/vivo/advertisingPlan/dels",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code==200){
            type = "success"
            _this.getAccountPlanListFn(this.page)
          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })
      },
      //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
      warmIs(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
        let _this = this
        let isTrue = _this.updateOppoSet(type)
        _this.warningOpenList = []
        _this.planStatus = false
        if(isTrue){
            // _this.yjCheck(type,url)  
            if(type == 'dayLimit'){
              _this.dayLimitCheckFn(type)
            }else{
            _this.yjCheck(type,url)
            }   
        }
      },
      //vivo校验当日是否存在日限额
      dayLimitCheckFn(type,url,adPlanId,adPlanName,dayBudget){
        let _this = this,idList = [],params = {}
        // if( !_this.dayLimitIs){
        //     return false
        //   }//不记得为什么这里要做这个判断先注释
            _this.adPlanIdList.forEach(obj=>{
            idList.push(obj)
          }) 
          params = {"ids":idList.join(','),"type":_this.dayType} 
          $http.axios.post("/api/vivo"+_this.url[1],Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          let data = []
          if(res.data.code==200){
            data = res.data.data
            if(data == null){
              if(dayBudget == 'dayBudget'){
                  this.showSingleDialog = true
                }else{
                  _this.showDialog = true
                }
                this.nowIs = false

            }else{
              let text = data.toString()+'计划已经设置过日限额执行计划是否确认添加新计划'
              this.$confirm(text,"提示",{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(()=>{
                _this.showDialog = true
                _this.nowIs = true
              }).catch(()=>{
                
              })
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      yjCheck(type,url,adPlanId,adPlanName,dayBudget){
        let _this = this,messageContent,params = null,idList = []
        if(type == 'warningOpen' || type =='yjBtn'){
          params = []
          params.push(_this.userContent.ownerId)
          console.log(_this.userContent.ownerId)
        }
      $http.axios.post("/api/vivo"+url,params).then(res=> {
        let data = []
        if(res.data.code==200){
          data = res.data.data
          if(data == null){
            if(type == 'dayLimit'){
              if(dayBudget == 'dailyBudget'){
                this.showSingleDialog = true
              }else{
                _this.showDialog = true
              }
              this.nowIs = false

            }else{
              messageContent = '您未设置预警发送人，请先到预警设置添加抄送人'
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }
            }else{
              if(type == 'dayLimit'){

                let text = data.toString()+'计划已经设置过日限额执行计划是否确认添加新计划'
                this.$confirm(text,"提示",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(()=>{
                  _this.showDialog = true
                  _this.nowIs = true
                }).catch(()=>{
                  
                })
              }else if(type == 'warningOpen'){
                _this.warningOpenList = ['开启','关闭']
              }else if(type == 'yjBtn'){
                _this.showEarlyWarningSetDialog = true
                _this.$refs.earlyWarningDialog.setDialogContentFn(_this.userContent.ownerId,adPlanId,adPlanName)
              }
            }
          }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },
      // 启停页面跳转
      goPlan(url,query){//
        let _this = this,query1 =  {
          id: this.userContent.ownerId,
          name:this.userContent.ownerName}
        query = Object.assign({},_this.clone(query), query1)
        this.$router.push({
          path: url,
          query: query
        })
      },
      getOpenCloseIdFn(params){
        let _this = this, type = ''
        $http.axios.post("/api/vivo/openClose/valid",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
        // $http.axios.post("/api/advertisement/adPlan/v1/getOpenCloseId",params).then(res=> {
          if(res.data.code==200){
            if(res.data.data!=null){
              _this.startStopDialog = true
            // _this.ssDetailParams.openClosePlanId=res.data.data
            _this.$refs.startStopDialogIs.timeSlotDetail(_this.ssDetailParams)
            _this.validDate = _this.formatDate(new Date())
            }

          }else{
            type = "error"
          }
            let messageContent =res.data.msg || '请查看网络，稍后再试'
            _this.$message({
              showClose: true,
              message: messageContent,
              type: type
            })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
      // /adPlan/v1/v3export下载报表
      v3exportFn(){
        let _this = this, type = ''
        $http.axios.post("/api/advertisement/adPlan/v1/v3export",params).then(res=> {
          if(res.data.code==200&res.data.content!=null){
            type = "success"
          }else{
            type = "error"
          }
          let messageContent =res.data.msg || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: type
          })  
        }).catch(error=>{
          let messageContent =error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        })      
      },
      setRowStyle(row, column, rowIndex, columnIndex) {
        if(row.columnIndex == 1){
          return 'color: #2F674D' 
        }else{
          return 'color: #606266' 
        }
      },

      //修改广告组名称
      editAdTeamName(item){
        console.log(item)
        this.editId = String(item.row.id)
        if(item.column.label == '广告计划名称'){
          this.editNameVisible = true
          this.editName = item.row.name
        }
      },

      //修改广告计划名称
      sureEditName(){
        let that = this, params = {}
        params = {
          'advertiserId':String(that.$route.query.id),
          'id':that.editId,
          'name':that.editName,
        }
        $http.axios.post("/api/vivo/advertisingPlan/editById",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code==200){
            that.editNameVisible = false
            that.getAccountPlanListFn(that.page)
          }else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },




    }
  }
  </script>
  <style lang="scss" scoped>
    /* 开关开始 */
    .el-switch__label{
      color:#fff !important;
      position: absolute;
    }
    .switchStyle .el-switch__label {
      position: absolute;
      display: none;
      color: #fff;
    }
    .switchStyle .el-switch__label--left {
      z-index: 9;
      left: 6px;
    }
    .switchStyle .el-switch__label--right {
      z-index: 9;
      left: -14px;
    }
    .switchStyle .el-switch__label.is-active {
      display: block;
    }
    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
      width: 50px !important;
    }
     /* 开关结束 */
     /* 批量操作开始 */
    .allOperation{
      margin-top: 10px;
    }
    .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
      font-style: initial;
    }
    .footerPaging{
      margin-top: 10px;
    }
    /* 批量操作结束 */
    .MBOTTOM{
      margin-bottom:10px;
    }
    .el-button--small{
      margin-left: 10px;
    }
    .line.el-col.el-col-2{
      text-align: center;
    }
    .isSwitch{
      width: 15px;
      height: 15px;
      border-radius: 50%;
      display: inline-block;
      background-color: #ccc;
      vertical-align:middle;
      margin-left: 5px;
    }
    .active{
      background-color: #13ce66;
    }
    #uploadBtn{
      float: right;
    }
    .el-button.el-button--primary{
      margin: 0 10px 0 0!important;
    }
    .el-table .el-table__row {
      cursor: pointer!important;
    }
    /* tab切换 */
    .daily_library{
      margin-bottom:20px;
    }
    .daily_library span{
      padding-right:20px;
      font-size:14px;
      cursor: pointer;
    }
    .daily_library .timesTab{
      font-size:16px;
      font-weight:700;
      color:#6e7c7c;
    }
    .adTeamEditBtn{
      padding-left:10px;
      color:#333;
      cursor: pointer;
      display:none;
    }
    .el-table .el-table__body tr:hover td .adTeamEditBtn{
      display:inline-block;
    }
    .editDayLimit{
      width:80%;
      margin:0 auto;
    }
    .editDayLimit .editDayLimitText,.editDayLimit span{
      display: inline-block;
      vertical-align:middle;
    }
    .editDayLimit .editDayLimitText{
      width:80%;
    }
    .editDayLimit span{
      width:18%;
      margin-left:1%;
    }
    .statusBtn{
      padding:3px 8px;
      background:rgba(103,194,58,.1);
      color:#67c23a;
    }
    .statusOtherBtn{
      padding:3px 8px;
      background:rgba(245,108,108,.1);
      color:#f56c6c;
    }
    .uploadIcon{
      font-size:40px;
      display:block;
      text-align:center;
    }
    /* tab切换当前样式 */
    .chanelTab {
      margin-bottom: 20px;
      margin-top:20px;
      width: 100%;
      border-bottom: 1px solid #F5F7F6;
    }
    .channel_vivo{
      display:inline-block;
      vertical-align:middle;
      width: 110px;
      height: 40px;
      font-size:14px;
      line-height:40px;
      text-align:center;
      cursor: pointer;
      background: #F5F7F6;
      color:#2F674D;
      border-radius: 8px 8px 0px 0px;
      margin-right:10px;
    }
    .channelActive{
      font-weight:700;
      color:#fff;
      background: #48916F;
      border-radius: 8px 8px 0px 0px;
    }
   /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  </style>
