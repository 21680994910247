<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>职位管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
        <div class="roleHeader">
          <span class="role_headerTitle">
            <span @click="goToTitle">角色职位</span>
            <span class="role_headerChecked" @click="goToRole">职位名称</span>
          </span>
          <el-select v-model="rolesvalue" filterable placeholder="请选择职位名称" class="roleSelect" @change="changePostSelect">
            <el-option
              v-for="(item,i) in options"
              :key="i"
              :label="item.postName"
              :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="sureQueryPost" style="margin-left:20px;">查询</el-button>
          <el-button type="primary" size="small" @click="addPost" class="add_roleBtn">添加职位</el-button>
          <el-dialog title="添加职位" :visible.sync="dialogFormVisible01">
            <el-form :model="titleform">
              <el-form-item label="职位名称" :label-width="formLabelWidth">
                <el-input v-model="postNameValue" autocomplete="off" placeholder="请输入职位名称"></el-input>
              </el-form-item>
              <el-form-item label="直属上级" :label-width="formLabelWidth">
                <el-select v-model="leaderNameValue" filterable :placeholder="hxName" @change="changeLeaderId">
                  <el-option
                  v-for="(item,i) in sjoptions"
                  :key="i"
                  :label="item.postName"
                  :value="item.postId">
                </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="职能描述" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="descValue" placeholder="请输入职能描述"></el-input>
              </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="dialogFormVisible01 = false">取 消</el-button>
                <el-button type="primary" @click="addPostList">确 定</el-button>
              </span>
            </template>
          </el-dialog>
        </div>
        <div class="roleTable">
          <el-table
            ref="multipleTable"
            :data="postTableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }">
            <el-table-column
              type="selection"
              width="100">
            </el-table-column>
            <el-table-column
              prop="rolename"
              label="职位名称"
              width="200">
              <template slot-scope="scope">{{scope.row.postName}}</template>
            </el-table-column>
            <el-table-column
              prop="rolename"
              label="直属上级"
              width="200">
              <template slot-scope="scope">{{scope.row.leaderName}}</template>
            </el-table-column>
            <el-table-column
              prop="jurisdiction"
              label="职能描述"
              width="500">
              <template slot-scope="scope">{{scope.row.desc}}</template>
            </el-table-column>
            <el-table-column
              prop="titleSratus"
              label="使用状态"
              width="200">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.status"
                  active-value="0"
                  inactive-value="1"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changePostStatus(scope.row)">
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop=""
              label="操作"
              show-overflow-tooltip>
              <template slot-scope="scope">
              <div class="roleOperation">
                <div class="tabBtns roleOperationBtn">
                  <el-button @click="xgPostDtail(scope.row.postId)" class="newBtn_lightYellow" plain size="mini">编辑</el-button>
                  <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deletePostList(scope.row.postId)"
                  >
                  <template #reference>
                    <el-button class="newBtn_orang" plain size="mini">删除</el-button>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </template>
            </el-table-column>
          </el-table>
          <div class="footer_paging">
            <div class="footer_totalNum">
              <!-- <span class="footer_totalNums">共{{footerTotalNum}}条</span> -->
              <!-- <el-popconfirm
                title="确定全部删除吗？"
              >
              <template #reference>
                <el-button>全部删除</el-button>
                </template>
              </el-popconfirm> -->
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
          </div>
        </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <accessDialog :visible.sync="showDialog" ></accessDialog>
  </div>
</template>

<script>
import accessDialog from '@/views/dialog/accountDialog'
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

const indexManageOptions = ['只读', '只写', '删除'];
// const indexManage_pageOptions = ['首页信息', '用户列表', '用户详情','2222', '1321', '1'];
export default {
  name: 'authority',
  components: {
    accessDialog
  },
  data() {
    return {
      productName: '',
      pageConfig: {
        pageNum: 1,
        total: 20
      },
      dialogFormVisible: false,
      dialogFormVisible01: false,
      dialogVisible: false,
      form: {
        name: '11',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      titleform: {
      },
      formLabelWidth: '120px',
      loading: false,
      showDialog: false,
      activeName_hrader: 'role',
      postTableData:[], //获取部门列表data
      options: [],
        rolesvalue: '',
        titleValue: '',
        tableData: [{
          rolename: '初级优化师',
          jurisdiction: '首页管理，首页信息，数据统计，日报模板库查，写，删，设置权限',
          titleSratus: ''
        }],
        multipleSelection: [],
        checkAll: false,
        checkedmanage01: [],
        // checkedmanage_pages: [],
        indexManages: indexManageOptions,
        // indexManage_pages: indexManage_pageOptions,
        isIndeterminate: false,
        Ovalue: 0, //开关控制器
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        // 新增职位参数
        postParams:{
          postName:'',
          desc:'',
          leaderId:'',
        },
        postNameValue:'',
        leaderNameValue:'',
        descValue:'',
        queryPost:false, //判断是修改还是新增
        xgpostNameParams:{
          postName:'',
          desc:'',
          leaderId:'',
          postId:''
        },
        sjoptions:[],
        hxName:'',
        queryParamsPost:{
          // postId:'',
          postName:''
        },
        queryTopPostName:'',
        queryTopPostId:'',
        changeStatusParams:{
          id:'',
          status:''
        },


    };
  },
  computed: {
  },
  mounted() {
    this.getPostList();
  },
  // created(){
  //   var params = {
  //     pageNum: this.pageNum,
  //     pageSize: this.pageSize
  //   }
  //   this.findAll(params)
  // },
  methods: {
    changePostStatus(row){
      console.log(row)
      let that = this
      if(row.status == 1){
        that.changeStatusParams.status = '1'        
      }else{
        that.changeStatusParams.status = '0'
      }
      that.changeStatusParams.id = String(row.postId)
      console.log( that.changeStatusParams.id)
      $http.axios.put("/api/system/post",that.changeStatusParams).then(function(response) {
        if(response.data.code == 200){
          
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })

    },
    // 权限设置弹窗
    handleCheckAllChange(val) {
      this.checkedmanage01 = val ? indexManageOptions : [];
      this.isIndeterminate = false;
    },
    handlecheckedmanage01Change(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.indexManages.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.indexManages.length;
    },
    // tab跳转
    goToTitle(){
      this.$router.replace('/auth-management/roleManage')
    },
    goToRole(){
      this.$router.replace('/auth-management/titleManage')
    },

    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getPostList()
    },

    //获取职位列表
    getPostList() {
      let that = this
      that.queryParamsPost.postName = that.queryTopPostName
      $http.axios.post("/api/system/post/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.queryParamsPost).then(function(response) {
        if(response.data.code == 200){
          that.postTableData = response.data.rows
          that.totalRow = response.data.total
          that.options = that.postTableData
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },

     //获取相关职位信息
     getPostLeader(){
        let that = this
        $http.axios.get("/api/system/post/optionselect").then(function(response) {
          if(response.data.code == 200){
            that.sjoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
    
    //点击添加部门按钮
    addPost(){
      let that = this
      that.queryPost = true
      that.postParams.postName = ''
      that.dialogFormVisible01 = true
      that.hxName = "请选择直属上级"
      that.postNameValue = ''
      that.leaderNameValue = ''
      that.descValue = ''
      // that.getleaderList()
      that.getPostLeader()
    },

    //修改部门按钮
    xgPostDtail(q){
      let that = this
      that.queryPost = false
      that.dialogFormVisible01 = true
      // that.getleaderList()
      that.getPostLeader()
      $http.axios.get("/api/system/post/"+q).then(function(response) {
        if(response.data.code == 200){
          that.postNameValue = response.data.data.postName
          that.hxName = response.data.data.leaderName
          that.descValue = response.data.data.desc
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
      that.xgpostNameParams.postId = q
    },

     //获取直属上级
    //  getleaderList(){
    //     let that = this
    //     $http.axios.get("/api/system/user/getLeaderList").then(function(response) {
    //       if(response.data.code == 200){
    //         console.log(response)
    //         that.sjoptions = response.data.data
    //       } else {
    //         that.$message({
    //           showClose: true,
    //           message: response.data.msg,
    //           type: 'error'
    //         })
    //       }
    //     })
    //   },
      

    //获取leader的岗位id
    changeLeaderId(val){
      this.postParams.leaderId = String(val)
      this.xgpostNameParams.leaderId = String(val)
    },

    // 新增职位
    addPostList() {
      let that = this
      if(that.queryPost == true){
        that.postParams.postName = that.postNameValue
        that.postParams.desc = that.descValue
        $http.axios.post("/api/system/post",that.postParams).then(function(response) {
          if(response.data.code == 200){
            that.dialogFormVisible01 = false
            that.getPostList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      } else {
        that.xgpostNameParams.postName = that.postNameValue
        that.xgpostNameParams.desc = that.descValue
        $http.axios.put("/api/system/post",that.xgpostNameParams).then(function(response) {
          if(response.data.code == 200){
            that.dialogFormVisible01 = false
            that.getPostList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      }
    },

    //获取下拉列表
    changePostSelect(val){
      console.log(val)
      this.queryTopPostId = val.postId
      this.queryTopPostName = val.postName
      this.rolesvalue = val.postName
    },

    //查询
    sureQueryPost(){
      let that = this
      // that.queryParamsPost.postId = that.queryTopPostId
      that.queryParamsPost.postName = that.queryTopPostName
      // $http.axios.get("/api/system/post/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum+"&postId="+that.queryTopPostId+"&postName="+that.queryTopPostName).then(res=> {
      $http.axios.post("/api/system/post/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum,that.queryParamsPost).then(res=> {
        if(res.data.code == 200){
          that.postTableData = []
          that.postTableData = res.data.rows
          that.totalRow = res.data.total
        } else {
          that.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },

    //删除部门
    deletePostList(e){
      let that = this
      $http.axios.delete("/api/system/post/"+e).then(function(response) {
        if(response.data.code == 200){
          that.getPostList()
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
  }
}
</script>

<style lang="scss">

.authority .poc-card-table .pcth-query-box .pcth-queryName{
  min-width:0;
}
.authority .marginR24 {
  margin-right: 24px;
}
.el-tabs__active-bar,.el-tabs__nav-wrap::after {
  display:none;
}
.poc-card-table .pcth-title-box {
  display:block;
}
.el-tabs__content{
  overflow:inherit !important;
}

.header_role_select{
  height:1px;
}
.add_titleBtn .el-select{
  width:100%;
}
.add_roleBtn .el-dialog__body{
  margin-bottom:0!important;
}
.el-table th{
  color:#1b1f23;
  background:$poc-color-primary-deputy;
}
.roleTable .el-table--mini th{
  padding:12px 0;
  /* font-weight:700 !important; */
  font-size:14px;
}
.roleTable .el-table--mini td{
  padding:10px 0;
}
.el-popup-parent--hidden .el-dialog__body{
  text-align:left!important;
}
.roleTable .el-form-item__content{
  margin-left:20px!important;
}
.dialogCheckall,.dialogCheck{
  display:inline-block;
  vertical-align:top;
}
.dialogCheckall{
  width:20%;
}
.dialogCheck{
  width:78%;
}
.dialog_tableCheckbox {
  margin-left:100px;
}
.dialog_foot {
  text-align:center;
}
.roleSelect{
  margin-left:40px;
}
.add_roleBtn {
  float:right;
} 
.role_headerTitle{
  font-size:14px;
}
.role_headerTitle span{
  padding-right:15px;
  cursor:pointer;
}
.role_headerChecked{
  color:#6e7c7c;
  font-size:16px;
  font-weight:700;
}
.roleHeader{
  margin-top:5px;
  margin-bottom:20px;
}
</style>
