<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>运营管理</el-breadcrumb-item>
          <el-breadcrumb-item>消耗汇总</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialHBtns">
                <el-form :inline="true" :model="materiaForm" :rules="rules"  ref="materiaForm" class="demo-form-inline">
                  <!-- <el-form-item prop="showQdList">
                    <el-select v-model="materiaForm.showQdList" multiple placeholder="请选择渠道" @change="changechannel($event)">
                      <el-option
                        v-for="(item,i) in qdoptions"
                        :key="i"
                        :label="item.name"
                        :value="item">
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="" prop="showQdList">
                    <el-select v-model="materiaForm.showQdList" multiple placeholder="请选择渠道" @change="changechannel($event)">
                      <el-option
                        v-for="item in qdoptions"
                        :key="item.name"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="glStaff">
                      <el-select v-model="aboutUserIdNNN" multiple :placeholder="consumeParams.aboutUserId ?consumeParams.aboutUserId: '请选择关联人员，可多选'" @change="changeGl($event)">
                        <el-option
                        v-for="(item,i) in tfAccounttOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="materialDate">
                    <el-date-picker
                      v-model="materiaForm.materialDate"
                      type="daterange"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      range-separator="至"
                      start-placeholder="消耗开始时间"
                      end-placeholder="消耗结束时间"
                      :default-time="['00:00:00', '23:59:59']">
                    </el-date-picker>
                  </el-form-item>
                  <div class="hideenTheForm" v-if="showTheForm"><i class="
                    el-icon-arrow-right" @click="showList"></i></div>
                  <div class='showTheForm' v-else>
                    <!-- <el-form-item label="" prop="tfAccount">
                      <el-input v-model="materiaForm.tfAccount" placeholder="请输入资投放账号"></el-input>
                    </el-form-item> -->
                    <el-form-item label="" prop="userids">
                      <!-- <el-select v-model="materiaForm.userids" ref="searchSelect" filterable clearable placeholder="请选择用户" @change="getUserMaterial" @keydown.enter.native="getDataByKeywords"> -->
                      <el-select v-model="consumeParams.hlMaterial"  filterable clearable placeholder="请选择用户名称" @change="getUserMaterial" @keydown.enter.native="getDataByKeywords">
                        <el-option
                          v-for="(item,i) in useridsOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="tfName">
                      <!-- <el-select v-model="materiaForm.tfName" value-key = "id" filterable clearable :placeholder="holderProduct" @change="changeProduct"> -->
                        <el-select v-model="consumeParams.productionName" value-key = "id" filterable clearable placeholder="请选择客户名称" @change="changeProduct">
                          <el-option
                          v-for="(item,i) in tfNameOptions"
                          :key="i"
                          :label="item.productName"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="tfProject">
                      <el-select v-model="materiaForm.tfProject" filterable clearable placeholder="请选择项目名称" @change="changeProject">
                        <el-option
                          v-for="(item,i) in tfProjectOptions"
                          :key="i"
                          :label="item.projectName"
                          :value="item.projectName">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="tfMaterialType">
                      <el-select v-model="materiaForm.tfMaterialType" clearable filterable placeholder="请选择素材类型" @change="bqType">
                        <el-option
                          v-for="(item,i) in tfMaterialTypeOptions"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="tfMaterialTab">
                      <el-select v-model="materiaForm.tfMaterialTab" filterable clearable placeholder="请选择素材标签" @change="bqTag">
                        <el-option
                          v-for="(item,i) in tfMaterialTabOptions"
                          :key="i"
                          :label="item.tagName"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" prop="mPname">
                      <el-select v-model="materiaForm.mPname" filterable clearable placeholder="请选择编导人员" @change="directChange">
                        <el-option
                          v-for="(item,i) in mPnameOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <!-- <el-input v-model="materiaForm.mPname" placeholder="请输入制作人员"></el-input> -->
                    </el-form-item>
                    <el-form-item label="" prop="mXname">
                      <el-select v-model="materiaForm.mXname" filterable clearable placeholder="请选择剪辑人员" @change="jjChange">
                        <el-option
                          v-for="(item,i) in mXnameOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <!-- <el-input v-model="materiaForm.mXname" placeholder="请输入需求人员"></el-input> -->
                    </el-form-item>
                    <el-form-item label="" prop="mPaccount">
                      <el-select v-model="materiaForm.mPaccount" filterable clearable placeholder="请选择摄影人员" @change="photoChange">
                        <el-option
                          v-for="(item,i) in mPaccountOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                      <!-- <el-input v-model="materiaForm.mPaccount" placeholder="请输入制作材料的账户"></el-input> -->
                    </el-form-item>
                    <el-form-item label="" prop="tfPerson">
                      <el-select v-model="materiaForm.tfPerson" filterable clearable placeholder="请选择投放人员" @change="changeTf">
                        <el-option
                          v-for="(item,i) in tfPersonOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="" prop="tfDate">
                      <el-date-picker
                        v-model="materiaForm.tfDate"
                        type="date"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="请选择创建时间">
                      </el-date-picker>
                    </el-form-item> -->
                    <el-form-item label="" prop="tfmaterialName">
                      <el-input v-model="materiaForm.tfmaterialName" placeholder="请输入素材名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="tfData">
                      <el-select v-model="materiaForm.tfData" filterable clearable placeholder="请选择数据" @change="changeSj">
                        <el-option
                          v-for="(item,i) in xzDataOptions"
                          :key="i"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>

                      <el-form-item label="" prop="tfSymbol">

                      <el-select v-model="materiaForm.tfSymbol" filterable clearable placeholder="请选择符号" @change="changeFh">
                        <el-option
                          v-for="(item,i) in tfSymbolOptions"
                          :key="i"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>

                      <el-form-item label="" prop="Numsr">

                      
                      <el-input v-model.number="materiaForm.Numsr" placeholder="请输入数值" style="width:100px;"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="" prop="tfSymbol">
                      <el-select v-model="materiaForm.tfSymbol" placeholder="符号">
                        <el-option
                          v-for="(item,i) in tfSymbolOptions"
                          :key="i"
                          :label="item.label"
                          :value="item.label">
                        </el-option>
                      </el-select>
                    </el-form-item> -->
                    <i class="el-icon-arrow-up" @click="hiddenList"></i>
                  </div>
                  <el-form-item>
                    <!-- <el-button type="primary" @click="onSubmit">查询</el-button> -->
                    <el-button type="primary" size="small" @click="getMaterialList1(1)">查询</el-button>
                    <el-button type="info" plain size="small" @click="resetForm('materiaForm')">重置</el-button>
                    <el-button type="primary" size="small" @click="materialManage">素材管理</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>

            <div class="materiaContent">
              
              <div class="tableTop">
                <!-- <el-select v-model="reportValue" placeholder="选择报表维度">
                  <el-option
                    v-for="(item,i) in reportOptions"
                    :key="i"
                    :label="item.label"
                    :value="item.label">
                  </el-option>
                </el-select> -->
                <span class="customColumns">
                  <el-button type="danger" plain size="small" class="customColumnsTag" >总花费：{{totalCost}}</el-button>
                  <el-button type="success" size="small" @click="customColumns" style="margin-bottom:10px;">自定义列</el-button>
                  <el-button type="warning" size="small" style="margin-bottom:10px;" @click="exprot">导出</el-button>
                </span>
                <el-dialog
                  title="确认导出吗"
                  :visible.sync="exprtVisible"
                  width="30%">
                  <span class="el-icon-loading uploading" v-show="uploadingShow"></span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="exprtVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureExport">确 定</el-button>
                  </span>
                </el-dialog>
                
                <!-- 自定义列   -->
                <el-dialog
                  title="自定义列"
                  :visible.sync="dialogFormVisible"
                  width="40%">
                  <div class="customBox">
                    <div class="customContent">
                      <el-checkbox-group v-model="checkedCities" @change="checkChange($event)">
                        <el-checkbox
                          v-for="item in customData"
                          :label="item.string"
                          :key="item.string"
                          style="display:block;"
                          @change="changeChecked">
                          {{item.name}}
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                    <!-- <div class="customExist" >
                      <span v-for="cus in checkList">{{cus.name}}</span>
                    </div> -->
                  </div>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="setCustom">确 定</el-button>
                  </div>
                </el-dialog>
              </div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @sort-change="sortChange"
                >
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="name"
                  fixed="left"
                  label="素材"
                  width="100">
                  <template slot-scope="scope">
                     <img :src="scope.row.video_url+firstWord" style="width:50%;cursor: pointer;" @click="showVideo(scope.row)"/>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  fixed="left"
                  label="封面"
                  width="100">
                  <template slot-scope="scope">
                    <span>
                      <el-popover
                        placement="right"
                        title=""
                        trigger="hover">                      
                        <img :src="scope.row.video_url+firstWord" style="width:200px;cursor: pointer"/>
                        <img slot="reference" :src="scope.row.video_url+firstWord" style="width:50%;cursor: pointer">
                      </el-popover>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  fixed="left"
                  label="标题"
                  width="180">
                  <template slot-scope="scope">{{scope.row.material_name}}</template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  fixed="left"
                  label="关联计划"
                  width="80">
                  <template slot-scope="scope">
                    <span @click="glPlan(scope.row.material_id)" class="ss">{{scope.row.palnNum}}</span>
                  </template>
                </el-table-column>
                <el-table-column 
                  prop="name"
                  fixed="left"
                  label="关联创意"
                  width="80">
                  <template slot-scope="scope">
                    <span @click="originality(scope.row.material_id)" class="ss">{{scope.row.creativeNum}}</span>   
                  </template>
                </el-table-column>
                <el-table-column
                  prop="launch_name"
                  fixed="left"
                  label="投放人员">
                  <template slot-scope="scope">{{scope.row.works_name}}</template>
                </el-table-column> 
                <el-table-column
                  prop=""
                  label="编导">
                  <template slot-scope="scope">{{scope.row.direct_name}}</template>
                </el-table-column> 
                <el-table-column
                  prop=""
                  label="摄影">
                  <template slot-scope="scope">{{scope.row.photography_name}}</template>
                </el-table-column> 
                <el-table-column
                  prop=""
                  label="剪辑">
                  <template slot-scope="scope">{{scope.row.clip_name}}</template>
                </el-table-column> 
                <el-table-column
                  v-for="item in checkList"
                  :key="item.string"
                  :label="item.name"
                  :prop="item.string"
                  width="200"
                  sortable="custom"
                  >
                  
                </el-table-column> 
              </el-table>
              
            </div>
            <el-dialog
              title=""
              :visible.sync="centerDialogVisible"
              width="80%"
              background="rgba(0,0,0,0)"
              center
              :before-close="handleDialogClose"
              class="mineBg">
              <span style="text-align:center;display:block;">
                <video :src="videoUrlShow" controls autoplay controlslist="nodownload" ref="videoPouse" style="width:30%;margin:0 auto;"></video>
              </span>
            </el-dialog>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  // const customData1 = ['广告主id']
  // const cityOptions = ['上海', '北京', '广州', '深圳'];
  export default {
    name: 'demandOrder',
    data() {
      var checkAge = (rule, value, callback) => {
        
        setTimeout(() => {
          if (!Number.isInteger(value)) {
            callback(new Error('请输入数字值'));
          } 
        }, 1000);
      };
      
      return {
        rules: {
          
          Numsr: [
            { validator: checkAge, trigger: 'blur' }
          ]},
        aboutUserIdNNN:[],//关联人员回显
        checkedCities: [],
        cities: [],
        city:[],
        showTheForm:true,//表头是否显示全部表单
        materiaChannelValue:'', //头部渠道搜索value
        mStatusValue:'', //选择素材状态下拉框的value
        qdoptions: [],//渠道列表
        materiaForm:{//头部查询表单的prop
          showQdList:[],//选择渠道
          showScstatus:'',//选择素材状态
          materialDate:[],//选择素材开始结束日期
          // tfAccount:'',
          tfName:'',
          tfProject:'',
          tfMaterialType:'',
          tfMaterialTab:'',
          tfPerson:'',
          // tfDate:'',
          tfmaterialID:'',
          tfmaterialName:'',
          tfData:'',
          tfSymbol:'',
          postgl:'',
          glStaff:[],
          mPname:'',
          mXname:'',
          mPaccount:'',
          Numsr:'',
          userids:''
        },
        mPnameOptions:[],
        mXnameOptions:[],
        mPaccountOptions:[],
        materiaStatusOptions: [{//查询表单的选择素材状态
          value: '选项1',
          label: '待审核'
        }, {
          value: '选项2',
          label: '运营审核通过'
        }, {
          value: '选项3',
          label: '运营审核拒绝'
        }, {
          value: '选项4',
          label: '客户审核拒绝'
        }, {
          value: '选项4',
          label: '可使用'
        }],
        xzDataOptions:[{
          value:'cost',
          name:'消耗'
        },{
          value:'show',
          name:'展示数'
        },{
          value:'click',
          name:'点击数'
        },{
          value:'convert',
          name:'转化数'
        }],
        tfAccounOptions:[],
        tfAccounttOptions:[],
        tfNameOptions:[],
        tfProjectOptions:[],
        tfMaterialTypeOptions:[{//选择素材类型
          value: '1',
          label: '横版'
        }, {
          value: '2',
          label: '竖版'
        }],
        tfMaterialTabOptions:[],
        tfPersonOptions:[],
        tfDataOptions:[],
        // tfDataOptions:[],
        tableData: [],
        multipleSelection: [],
        reportValue:'',//报表维度
        // reportOptions:[{//报表维度
        //   value: '1',
        //   label: '素材维度'
        // }, {
        //   value: '2',
        //   label: '封面维度'
        // }, {
        //   value: '3',
        //   label: '标题维度'
        // }],
        tfSymbolOptions:[{
          value: 1,
          label: '='
        }, {
          value: 2,
          label: '>'
        }, {
          value: 3,
          label: '<'
        }, {
          value: 4,
          label: '>='
        }, {
          value: 5,
          label: '<='
        }],
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        dialogFormVisible:false,
        formLabelWidth:'120px',
        customData:[],//自定义列data
        checkList:[],
        checkListTd:[],
        checkListExist:'',
        setParams:{
          customLineString:'',
          userId:''
        },
        checkListString:[],
        dialogTableVisible:false,
        gridData:[],//计划data
        planParams:{
          materialId:'',
          userId:'',
          // pageSize:'',
          // pageNum:''
        },
        originParams:{
          materialId:'',
          userId:''
          // pageSize:'',
          // pageNum:''
        },
        dialogTableVisible_origin:false,
        gridDataOrigin:[],//创意data
        consumeParams:{
          channelId:'',
          userId:'',
          customLineString:'',
          pageSize:'',
          pageNum:'',
          orderBy:'',
          postId:'',
          aboutUserId:'',
          beginTime:'',
          endTIme:'',
          // launchName:'',
          productionName:'',
          projectName:'',
          materialType:'',
          materialTag:'',
          directId:'',
          clipId:'',
          photographyId:'',
          launchId:'',
          // statDatetime:'',
          materialName:'',
          key:'',
          keyMark:'',
          keyData:'',
          hlMaterial:'',
          // userId:''

        },
        getUserId:'',
        getzdyData:[],
        getMaterialListArray:[],
        checkdddd:'',
        checkListStringList:[],
        selectedPerson:[],
        // ischecked:false,
        gridCheckList:[],
        cyData:'',
        cyTag:[],
        cyValue:[],
        staffPageSize:'500',
        staffPageNum:'1',
        tagListParams:{//标签列表参数
          tagType:'2',
          userId:'',
          channel:''
        },
        bigImg:'',
        userIdParams:'',
        channelIdParams:'',
        typeParams:'',
        // 测试
        numm:[],
        userChannel:'',
        centerDialogVisible:false,
        videoUrlShow:'',
        firstWord:'?x-oss-process=video/snapshot,t_1,m_fast',
        useridsOptions:[],
        yonghu:'',
        getParams:{
          productId:''
        },
        getProductId:'',
        exprtVisible:false,
        myHeaders: {Authorization:''},
        totalCost:'',
        //搜索框回显
        channelPH:'',//渠道
        holderAboutUser:'',//关联人员
        holderBegin:'',//消耗开始时间
        holderEnding:'',//消耗结束时间
        holderUser:'',//选择用户
        holderProduct:'',//客户名称
        holderProject:'',//项目名称
        holderTagType:'',//素材类型
        holderDirect:'',//编导
        holderClip:'',//剪辑
        holderPhoto:'',//摄影
        holderLanuch:'',//投放人员
        holderMaterName:'',//素材名称
        holderKey:'',//选择的数据形式
        holderKeyMark:'',//选择的符号
        holderKeyData:'',//数值
        uploadingShow:false,



      }
    },
    computed: {
       
    },
    mounted() {
      this.getPostDetail()
      this.getStaffDetail()
      this.getProject()
      this.getProduct()
      this.getuserChannel()
      this.getUserMaterialId()
      //this.getUserData()
    },
    methods: {
      //根据id弹出视频
      showVideo(row){
        this.centerDialogVisible = true
        this.videoUrlShow = row.video_url
        this.$nextTick(() => {
          this.$refs.videoPouse.play()
        })
      },
      handleDialogClose(){
        this.centerDialogVisible = false
        this.$refs.videoPouse.pause()
      },

      //头部表单重置
      resetForm(materiaForm) {
        this.$refs[materiaForm].resetFields();
        console.log(this.materiaForm)
        this.aboutUserIdNNN = ''
        this.consumeParams.photographyId =  ''
        this.consumeParams.aboutUserId = ''
        this.consumeParams.beginTime = ''
        this.consumeParams.endTIme = ''
        this.consumeParams.productionName = ''
        this.consumeParams.projectName = ''
        this.consumeParams.materialType = ''
        this.consumeParams.materialTag = ''
        this.consumeParams.directId = ''
        this.consumeParams.clipId = ''
        this.consumeParams.launchId = ''
        this.consumeParams.materialName = ''
        this.consumeParams.key = ''
        this.consumeParams.keyMark = ''
        this.consumeParams.keyData = ''
        this.consumeParams.hlMaterial = ''

        this.materiaForm.showQdList = ''
        //this.consumeParams.productionName = ''//客户名称
        this.materiaForm.tfProject = '' //项目名称
        this.materiaForm.tfMaterialType = '' //素材类型
        this.materiaForm.tfMaterialTab = '' //素材标签
        this.materiaForm.mPname = ''
        this.materiaForm.mXname = ''
        this.materiaForm.mPaccount = ''
        this.materiaForm.tfPerson = ''
        this.materiaForm.tfData =''
        this.materiaForm.tfSymbol = ''
        this.materiaForm.Numsr = ''




        this.materiaForm.tfmaterialName = '' //素材名称
        this.getMaterialList1(1)
        // this.$router.go(0)
      },

      // arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      //   if (rowIndex % 2 === 0) {
      //     if (columnIndex === 0) {
      //       return [1, 2];
      //     } else if (columnIndex === 1) {
      //       return [0, 0];
      //     }
      //   }
      // },

      changechannel(event){
        let channelIds = []
        channelIds.push(event)
        
        // this.materiaForm.showQdList.push(event.name)
        this.consumeParams.channelId = String(channelIds)       
        this.channelIdParams = String(channelIds)
        if(this.channelIdParams){
          this.getchannelUser()
        }
        
        
      },
      changePost(event){
        // this.getPostUser()
        this.consumeParams.postId = String(event)
        this.userIdParams = String(event)
        this.materiaForm.glStaff =[]
        if(this.materiaForm.postgl){
          this.getPostUser()
        }
        
      },

      //获取相关职位信息
      getPostDetail(){
        let that = this
        $http.axios.get("/api/system/post/optionselect").then(function(response) {
          if(response.data.code == 200){
            that.tfAccounOptions = response.data.data
            // that.getPostUser()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取人员信息
      getStaffDetail(){
        let that = this
        $http.axios.get("/api/system/user/optionselect").then(function(response) {
          // that.tfAccounttOptions = response.data
          that.mPnameOptions = response.data
          that.mXnameOptions = response.data
          that.mPaccountOptions = response.data
          that.tfPersonOptions = response.data
        })
      },

      //项目名称下拉框
      getProject(){
        let that = this
        $http.axios.get("/api/operationManagement/materialoperation/getallProject?productId="+that.getProductId).then(function(response) {
          if(response.data.code == 200){
            that.tfProjectOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //客户名称下拉框
      getProduct(){
        let that = this
        $http.axios.post("/api/operationManagement/materialoperation/getallProduct").then(function(response) {
          if(response.data.code == 200){
            that.tfNameOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },


      //查询的素材标签下拉框数据
      getTagList(){
        let that = this
        that.tagListParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/material/taglist",that.tagListParams).then(res=> {
          if(res.data.code == 200){
            that.tfMaterialTabOptions = res.data.data
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      changeGl(event){

        this.consumeParams.aboutUserId = String(event)
        
      },
      changeProduct(event){
        // this.materiaForm.tfName = event.productName
        this.materiaForm.tfName = 23
        // this.consumeParams.productionName = String(event.productName) || ''
        this.consumeParams.productionName = event.productName || ''
        this.getProductId = event.id||''
        this.materiaForm.tfProject = ''
        if(this.materiaForm.tfName){
          this.getProject()
        }else{
          this.getProductId = ''
          this.getProject()
        }
      },
      changeProject(event){
        this.consumeParams.projectName = String(event)
      },
      bqType(event){
        this.consumeParams.materialType = String(event)
      },
      bqTag(event){
        this.consumeParams.materialTag = String(event)
      },
      directChange(event){
        this.consumeParams.directId = String(event)
      },
      jjChange(event){
        this.consumeParams.clipId = String(event)
      },
      photoChange(event){
        this.consumeParams.photographyId = String(event)
      },
      changeTf(event){
        this.consumeParams.launchId = String(event)
      },
      changeSj(event){
        this.consumeParams.key = String(event)
      },
      changeFh(event){
        this.consumeParams.keyMark = event
      },

      //查询用户
      getUserMaterialId(){
        let that = this
        $http.axios.get("/api/operationManagement/material/materialpull?name="+'').then(res=> {
          if(res.data.code == 200){
            that.useridsOptions = res.data.data
          }  else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      getUserMaterial(val){
        let that = this
        // that.materiaForm.userids = val.name
        // that.consumeParams.hlMaterial = val.id
      },

      getDataByKeywords(){
        let that = this
        let userName = that.$refs.searchSelect.previousQuery
        // let userName = that.materiaForm.userids
        // if(that.materiaForm.userids == ''){
        //   that.getUserMaterialId()
        // }else{
          $http.axios.get("/api/operationManagement/material/materialpull?name="+userName).then(res=> {
            if(res.data.code == 200){
              that.useridsOptions = res.data.data
              if(res.data.data == ''){
                that.$message('无匹配结果')
                that.getMaterialList(that.pageNum)
              }else{
                
              } 
            }  else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        // }
      },

      //导出
      exprot(){
        let that = this
        that.exprtVisible = true
      },
      //确认导出
      sureExport(){
        let that = this
        that.uploadingShow = true
        that.consumeParams.beginTime = that.materiaForm.materialDate[0]
        that.consumeParams.endTIme = that.materiaForm.materialDate[1]
        that.consumeParams.materialName = that.materiaForm.tfmaterialName
        that.consumeParams.keyData = that.materiaForm.Numsr
        that.consumeParams.userId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        let timestamp = Date.parse(new Date())
        $http.axios.defaults.timeout = 500000
        $http.axios.post("/api/channel/dataWeb/exportMaterialList",that.consumeParams,
          {responseType: 'blob',dataType:"json",async: false}).then(function(response) {
          that.exprtVisible = false 
          //添加{responseType: 'blob'}  导出文件以下代码都是
          let blob = response.data
          let reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onload = e => {
            let a = document.createElement('a')
            a.download = "素材消耗数据"+timestamp+".xls"
            a.href = e.target.result
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            that.uploadingShow = false
          }
        })
      },

     

      //打开新建标签弹框
      addNewTab() {
        //this.$refs.materiaDialog.openDialog(false)   
      },
      //素材管理页面跳转
      materialManage(){
        this.$router.push('/auth-management/MaterialProduct')
      },
      //表头点击向右三角显示
      showList(){
        this.showTheForm = false;
      },
      //表头点击向上三角隐藏
      hiddenList(){
        this.showTheForm = true;
      },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        that.userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userChannel).then(function(response) {
          if(response.data.data.length!=0){
            that.qdoptions = response.data.data
            // that.materiaForm.showQdList = response.data.data[0].name
            that.tagListParams.channel = response.data.data[0].id
            let channelIdPa = []
            that.qdoptions.forEach(function(e,i){
              channelIdPa.push(e.id)
              that.consumeParams.channelId = String(channelIdPa)
              that.channelIdParams = String(channelIdPa)
            })
            that.getUserData()
            // that.getMorenList()
            that.getTagList()
            that.getchannelUser()
          }
        })
      },


      getchannelUser(){
        let that = this
        // that.userIdParams = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/optionselectQuery?ids="+that.channelIdParams+"&type=1").then(function(response) {
          that.tfAccounttOptions = response.data
        })
      },

      getPostUser(){
        let that = this
        // that.userIdParams = String(that.$sessionStorage.getItem(config.USERID) || 1)
        // that.userIdParams = "21"
        $http.axios.get("/api/system/user/optionselectQuery?id="+that.userIdParams+"&type=2").then(function(response) {
          that.tfAccounttOptions = response.data
        })
      },

      //获取用户自定义列
      getMorenList(){
        let that = this
        that.getUserId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        that.getMaterialListArray = []
        $http.axios.post("/api/channel/dataWeb/getCustomLineList?userId="+that.getUserId).then(function(response) {
          if(response.data.code == 200){
            that.getzdyData = response.data.data
            if(that.getzdyData[0] != null){
                // that.getzdyData 去重
                that.getzdyData.forEach(function(v,i,arr){
                let bool = arr.indexOf(v,i+1)
                if(bool === -1){
                  that.getMaterialListArray.push(v.string)
                }
            })
              that.consumeParams.customLineString = String(that.getMaterialListArray)
            }else{
              that.consumeParams.customLineString = ''
            }
            that.getMaterialList(that.pageNum,that.consumeParams.customLineString)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      sortChange(column) {
        if(column.order == 'ascending'){
          this.consumeParams.orderBy = "1";
          column.order = 'descending'
        }else{
          this.consumeParams.orderBy = "2";
          column.order = 'ascending'
        }
        let allRe=this.consumeParams.customLineString;
        this.getMaterialList(this.pageNum,column.prop,allRe,this.consumeParams.orderBy)
      },

      //获取用户上次搜索结果
      getUserData(){
        let that = this
        that.getUserId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/channel/dataWeb/getUserQuery?userId="+that.getUserId).then(function(response) {
          if(response.data.code == 200){
            that.consumeParams.photographyId = that.materiaForm.mPaccount = response.data.data.photographyId
            that.consumeParams.channelId = response.data.data.channelId
            response.data.data.channelId?that.materiaForm.showQdList= that.consumeParams.channelId.split(','):[]
            that.consumeParams.aboutUserId = that.aboutUserIdNNN = response.data.data.aboutUserId
            
            that.consumeParams.beginTime =  response.data.data.beginTime || ''
            // that.materiaForm.materialDate.push(response.data.data.beginTime)
            that.consumeParams.endTIme = response.data.data.endTIme || ''
            that.materiaForm.materialDate.push(that.consumeParams.beginTime,that.consumeParams.endTIme)
            console.log(that.materiaForm.materialDate[0])
            console.log(that.materiaForm.materialDate[1])
            if(response.data.data.productionName=='undefined'){
            that.consumeParams.productionName = ''
          }else{
            that.consumeParams.productionName = response.data.data.productionName 
          }

            that.consumeParams.projectName = that.materiaForm.tfProject = response.data.data.projectName
            that.consumeParams.materialType = that.materiaForm.tfMaterialType = response.data.data.materialType
            that.consumeParams.materialTag =  response.data.data.materialTag
            response.data.data.materialTag?that.materiaForm.tfMaterialTab = Number(response.data.data.materialTag):''
            that.consumeParams.directId = that.materiaForm.mPname = response.data.data.directId
            that.consumeParams.clipId = that.materiaForm.mXname = response.data.data.clipId
            that.consumeParams.launchId = that.materiaForm.tfPerson = response.data.data.launchId
            that.consumeParams.materialName = that.materiaForm.tfmaterialName = response.data.data.materialName
            that.consumeParams.key = that.materiaForm.tfData = response.data.data.key
            that.consumeParams.keyMark = that.materiaForm.tfSymbol = response.data.data.keyMark
            that.consumeParams.keyData = that.materiaForm.Numsr = response.data.data.keyData
            that.consumeParams.hlMaterial = response.data.data.hlMaterial

            if(response.data.data.channelId == ''){
              that.channelPH = '请选择渠道名称'
            }else{
              if(response.data.data.channelId == '1'){
                that.channelPH = '今日头条'
              }else{
                that.channelPH = '快手'
              }
            }
            if(response.data.data.aboutUserName == null||''){
              that.aboutUserIdNNN = []
            }else{
              // that.holderAboutUser = response.data.data.aboutUserName // 需要改回来
              that.aboutUserIdNNN=that.consumeParams.aboutUserId.split(',') 
            }
            if(response.data.data.beginTime == null||''){
              that.holderBegin = '消耗开始时间'
            }else{
              that.holderBegin = response.data.data.beginTime
            }
            if(response.data.data.endTIme == null||''){
              that.holderEnding = '消耗结束时间'
            }else{
              that.holderEnding = response.data.data.endTIme
            }
            // if(response.data.data.hlMaterial == ''){
            //   that.holderUser = '请选择用户'
            // }else{
            //   that.holderUser = response.data.data.hlMaterial
            // }
            if(response.data.data.productionName == ''){
              that.holderProduct = '请选择客户名称'
            }else{
              that.holderProduct = response.data.data.productionName|| ''
            }
            if(response.data.data.projectName == ''){
              that.holderProject = '请选择项目名称'
            }else{
              that.holderProject = response.data.data.projectName
            }
            if(response.data.data.materialType == ''){
              that.holderTagType = '请选择素材类型'
            }else{
              if(response.data.data.materialType == '1'){
                that.holderTagType = '横版'
              }else{
                that.holderTagType = '竖版'
              }
            }
            if(response.data.data.directName == null||''){
              that.holderDirect = '请选择编导人员'
            }else{
              that.holderDirect = response.data.data.directName
            }
            if(response.data.data.clipName == null||''){
              that.holderClip = '请选择剪辑人员'
            }else{
              that.holderClip = response.data.data.clipName
            }
            if(response.data.data.photographyName == null||''){
              that.holderPhoto = '请选择摄影人员'
            }else{
              that.holderPhoto = response.data.data.photographyName
            }
            if(response.data.data.launchName == null||''){
              that.holderLanuch = '请选择投放人员'
            }else{
              that.holderLanuch = response.data.data.launchName
            }
            if(response.data.data.materialName == ''){
              that.holderMaterName = '请输入素材名称'
            }else{
              that.holderMaterName = response.data.data.materialName
            }
            if(response.data.data.key == null||''){
              that.holderKey = '请选择数据'
            }else{
              if(response.data.data.key == 'cost'){
                // that.holderKey = '总花费'
                that.holderKey = '消耗'
              }else if(response.data.data.key == 'show'){
                that.holderKey = '展示数'
              }else if(response.data.data.key == 'click'){
                that.holderKey = '点击数'
              }else{
                that.holderKey = '转化数'
              }
            }
            if(response.data.data.keyMark == null||''){
              that.holderKeyMark = '请选择符号'
            }else{
              if(response.data.data.keyMark == '1'){
                that.holderKeyMark = '='
              }else if(response.data.data.keyMark == '2'){
                that.holderKeyMark = '>'
              }else if(response.data.data.keyMark == '3'){
                that.holderKeyMark = '<'
              }else if(response.data.data.keyMark == '4'){
                that.holderKeyMark = '>='
              }else{
                that.holderKeyMark = '<='
              }
            }
            if(response.data.data.keyData == ''){
              that.holderKeyData = '请输入数值'
            }else{
              that.holderKeyData = response.data.data.keyData
            }


            that.getMorenList()
          
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //消耗列表
      getMaterialList(page,customLineString,allRe,orderBy){
        let that = this
        that.consumeParams.userId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        // that.consumeParams.customLineString = String(customLineString)
        that.consumeParams.customLineString = allRe || ''
        that.consumeParams.pageSize = that.pageSize
        that.consumeParams.pageNum = page
        that.consumeParams.orderBy = orderBy||'2'
        // that.consumeParams.beginTime = that.materiaForm.materialDate[0]
        that.consumeParams.beginTime=that.materiaForm.materialDate!=null? that.materiaForm.materialDate[0]:''
        // that.consumeParams.endTIme = that.materiaForm.materialDate[1]
        that.consumeParams.endTIme=that.materiaForm.materialDate!=null? that.materiaForm.materialDate[1]:''
        that.consumeParams.materialName = that.materiaForm.tfmaterialName
        that.consumeParams.keyData = that.materiaForm.Numsr
               
       $http.axios.post("/api/channel/dataWeb/queryMaterialList",that.consumeParams).then(function(response) {
        //$http.axios.post("/aki/dataWeb/queryMaterialList",that.consumeParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.rows
            that.totalRow = response.data.total
            that.totalCost = response.data.totalCost
            if(that.getzdyData[0]==null){
              that.checkList=[]
            }else{
              that.checkList=that.getzdyData
              // that.tableData.push(that.checkList) 
            }
            // that.tableData.push(that.checkList)  
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
        // that.handleCurrentChange(1)
      },
      // dateFormat(time) {
      //   var date=new Date(time);
      //   var year=date.getFullYear();
      //   /* 在日期格式中，月份是从0开始的，因此要加0
      //     * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
      //     * */
      //   var month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
      //   var day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
      //   var hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
      //   var minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
      //   var seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
      //   // 拼接
      //   return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
      // },

      changeChecked(){
        // console.log(this.checkedCities)
      },

      //获取全部自定义列
      customColumns(){
        let that = this
        that.dialogFormVisible = true
        that.checkedCities = that.getMaterialListArray
        $http.axios.get("/api/channel/dataWeb/queryCustomLineList").then(function(response) {
          that.customData = response.data
        })
      },

      //自定义弹框是否选中
      checkChange(event){
        let that = this
      },

      //设置用户自定义列
      setCustom(){
        let that = this
        that.checkListString=[]
        that.checkListStringList = []
        // that.checkedCities.forEach(function(e,i){
        //   that.checkListString.push(e.string)
        // })
        that.setParams.userId = String(that.$sessionStorage.getItem(config.USERID) || 1)
        that.setParams.customLineString = String(that.checkedCities)
        $http.axios.post("/api/channel/dataWeb/bdCustomLineList",that.setParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData.push(that.checkedCities)
            that.dialogFormVisible = false
            that.getMorenList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },


      //关联计划
      glPlan(e){
        // e = "6746498909244358669"
        this.$router.push({path:'/auth-management/ConsumptionSumPlan/'+e})
      },

      //关联创意
      originality(e){
        // e = "6746498909244358669"
        this.$router.push({path:'/auth-management/ConsumptionSumCy/'+e})
      },
      

      // 分页
      handleSizeChange(val) {
        this.pageSize = val
        this.findAll(val)
      },
      handleCurrentChange(val) {
        this.pageNum = val
        this.findAll(val)
      },
      //分页方法
      findAll (params) {
        //获取数据接口（调用封装的接口）
        this.getMaterialList(params)
      },

      getMaterialList1(pageNum){
        this.handleCurrentChange(1)
        this.getMaterialList(1)
      },
      //表格中选中和未选中
      // toggleSelection(rows) {
      //   if (rows) {
      //     rows.forEach(row => {
      //       this.$refs.multipleTable.toggleRowSelection(row);
      //     });
      //   } else {
      //     this.$refs.multipleTable.clearSelection();
      //   }
      // },
      // handleSelectionChange(val) {
      //   this.multipleSelection = val;
      // }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materialHead{
      font-size:0;
    }
    .materialHchannel,.materialHBtns{
      display:inline-block;
      vertical-align:middle;
      font-size:14px;
    }
    .materialHchannel{
      width:30%;
    }
    .materialHBtns{
      width:70%;
    }
    .materiaContent{
      margin-top:15px;
    }
    .showTheForm,.hideenTheForm{
      display:inline-block;
      vertical-align:top;
    }
    .showTheForm{
      position:relative;
    }
    .showTheForm i{
      position:absolute;
      bottom:-10px;
      left:50%;
      margin-left:-11px;
      font-size:22px;
      color:rgb(36, 132, 168);
      cursor:pointer;
    }
    .hideenTheForm i{
      font-size:22px;
      vertical-align:middle;
      color:rgb(36, 132, 168);
      cursor:pointer;
      margin:0 20px;
    }
    .tableTop{
      margin-bottom:20px;
    }
    .customColumns{
      float:right;
    }
    .customBox{
      width:100%;
      padding:0 20px;
      box-sizing: border-box;
      font-size:0;
    }
    .customContent,.customExist{
      font-size:14px;
      display:inline-block;
      vertical-align:top;
      box-sizing: border-box;
    }
    .customContent{
      width:60%;
      padding-right:15px;
    }
    .customExist{
      width:40%;
    }
    .customExist span{
      display:block;
    }
    .ss{
      cursor:pointer;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }
    .customColumnsTag{
      cursor:default;
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .customColumnsTag:hover{
      background:#fef0f0!important;
      border-color:#fbc4c4!important;
      color:#f56c6c!important;
    }
    .uploading{
      display:block;
      text-align:center;
      font-size:50px;
    }

  </style>
