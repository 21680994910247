<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item  :to="{ path: '/auth-management/materialList' }">运营管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/materialList' }">素材库</el-breadcrumb-item>
          <el-breadcrumb-item>水印库</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialHBtns">
                <el-form :inline="true" :model="materiaForm" ref="materiaForm" class="demo-form-inline">
                  <el-form-item label="" prop="mStatus">
                    <el-select v-model="materiaForm.mStatus" placeholder="请选择渠道" @change="changeChannel($event)">
                      <el-option
                        v-for="(item,i) in qdoptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="mType">
                    <el-select v-model="materiaForm.mType" filterable placeholder="请选择账户名称" @change="mTypeChange($event)">
                      <el-option
                        v-for="(item,i) in materiaTypeOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="" prop="mPosition">
                    <el-select v-model="materiaForm.mPosition" filterable placeholder="请选择运营人员" @change="mNameChange($event)">
                      <el-option
                        v-for="(item,i) in materiaPositionOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="waterMarkQuery">查询</el-button>
                    <el-button @click="resetForm('materiaForm')">重置</el-button>
                    <el-button type="primary" @click="uploadWmark">上传水印</el-button>
                  </el-form-item>
                </el-form>   
                <!-- 上传水印弹出框 -->
                <el-dialog title="上传设置" :visible.sync="dialogFormVisible" width="30%">
                  <el-form :model="uploadForm" ref="uploadForm" class="waterMarkDialog">
                    <el-form-item label="" :label-width="formLabelWidth">
                      <el-select v-model="uploadForm.qdname" placeholder="请选择渠道名称">
                        <el-option
                          v-for="(item,i) in qdoptions"
                          :key="i"
                          :label="item.name"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                      <el-select v-model="uploadForm.zhname" filterable placeholder="请选择账户名称" @change=typeChange($event)>
                        <el-option
                          v-for="(item,i) in materiaTypeOptionsXlk"
                          :key="i"
                          :label="item.name"
                          :value="item">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                      <el-input v-model="uploadForm.syname" autocomplete="off" placeholder="请输入水印名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-upload 
                        class="upload-demo" 
                        ref="upload" 
                        action="/api/operationManagement/watermark/v1/uploadImg" 
                        :on-preview="handlePreview" 
                        :on-remove="handleRemove" 
                        :file-list="fileList" 
                        :headers="myHeaders"
                        list-type="text" 
                        :limit="1" 
                        :on-success="onSuccess"
                        :on-error="onError"
                        style="margin-left:120px;">
                        <el-button size="small" type="primary" v-if="waterStatus==true">点击上传图片</el-button>
                        <el-button size="small" type="primary" v-else>替换</el-button>
                      </el-upload>
                    </el-form-item>
                    <el-form-item> 
                      <div v-if="waterStatus==true" class="showWaterMark">
                        <img :src="waterMarkUrlShowFirst" class="waterMark_img"/>
                      </div>
                      <div v-if="waterStatus==false" class="showWaterMark">
                        <img :src="waterMarkUrlShow" class="waterMark_img"/>
                      </div>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary"  @click="uploadWaterMark">上 传</el-button>
                  </div>
                </el-dialog>
              </div>
            </div>
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="渠道名称"
                  width="120">
                  <template slot-scope="scope">
                    <span v-if="scope.row.channel == 1">今日头条</span>
                    <span v-else>快手</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="账户名称"
                  width="200">
                  <template slot-scope="scope">{{scope.row.accountName}}</template>
                </el-table-column>
                <el-table-column 
                  prop="name"
                  label="水印名称"
                  width="200">
                  <template slot-scope="scope">{{scope.row.watermarkName}}</template>
                </el-table-column>
                <el-table-column
                  label="水印素材"
                  width="200">
                  <template slot-scope="scope">
                    <img :src="scope.row.watermarkUrl" class="waterMark_img"/>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="运营"
                  width="120">
                  <template slot-scope="scope">{{scope.row.userName}}</template>
                </el-table-column>
                </el-table-column>
                <el-table-column
                  label="上传时间"
                  width="200">
                  <template slot-scope="scope">{{scope.row.createTime | dateFormat}}</template>
                </el-table-column>
                <el-table-column
                  label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" @click="editWaterMark(scope.row.id)">修改</el-button>  
                    <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deleteWaterMark(scope.row.id)">
                  <template #reference>
                    <el-button type="text" style="margin-left:10px;">删除</el-button>
                    </template>
                  </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="footerPaging" style="clear:both!important;">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import { accessUserId } from '@/api/http';
  import { accessUserName } from '@/api/http';

  // var token =  'Bearer '+$sessionStorage.getItem(config.USER_TOKEN_KEY);
  export default {
    name: 'watermark',
    data() {
      return {
        myHeaders: {Authorization:''},
        qdoptions: [],
        materiaForm:{//头部查询表单的prop
          mStatus:'今日头条',
          mType:'',
          mPosition:''
        },
        materiaTypeOptions: [],
        materiaTypeOptionsXlk:[],
        materiaPositionOptions:[],
        tableData: [],//表格列表data
        multipleSelection: [],
        dialogFormVisible: false,
        uploadForm: { //上传水印弹框表单
          qdname:'',
          zhname:'',
          syname:''
        },
        formLabelWidth: '120px',
        // 分页
        currentPage: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        waterMarkParams:{ //获取列表和查询列表
          channel:'',
          pageSize:'',
          currentPage:'',
          accountId:'',//选择账户名称id
          userId:'',//选择当前用户运营人员id
          searchUserId:''//选择运营人员id
        },
        upParams:{
          file:{} //水印图片链接
        },
        uploadParams:{ //上传水印
          userId:'',//选择当前用户运营人员id
          userName:'',//选择当前用户运营人员名称
          channel:'',
          accountId:'',//选择账户名称id
          accountName:'',//选择账户名称
          watermarkName:'', //水印名称
          watermarkUrl:'',//水印url
          id:''
          // file:{} //水印图片链接
        },
        fileList:[],
        waterStatus:true,
        deleteParams:{
          status:1,
          id:'',
          userId:''
        },
        waterMarkUrlShow:'',//回显
        waterMarkUrlShowFirst:'',//上传之后生成
        accountUserId:'',
        
      }
    },
    computed: {
       
    },
    mounted() {
      // this.getWaterMarkList();
      // this.getChannelList()
      this.getuserChannel()
      this.getStaffDetail()
      this.getAccountList()
    },
    // created(){
    //   var params = {
    //     currentPage: this.currentPage,
    //     pageSize: this.pageSize
    //   }
    //   this.findAll(params)
    // },
    methods: {
      //头部表单重置
      resetForm(materiaForm) {
        this.$refs[materiaForm].resetFields();
        // this.getWaterMarkList()
        this.$router.go(0)
      },
      //点击查询按钮
      onSubmit() {
        console.log('submit!');
      },

      changeChannel(event){
        this.waterMarkParams.channel = event
      },

      //获取渠道列表
      // getChannelList(){
      //   let that = this
      //   $http.axios.get("/api/system/user/getChannelList").then(function(response) {
      //     if(response.data.code == 200){
      //       that.qdoptions = response.data.data
      //     } else {
      //       that.$message({
      //         showClose: true,
      //         message: response.data.msg,
      //         type: 'error'
      //       })
      //     }
      //   })
      // },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        let userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
          if(response.data.data.length!=0){
            that.qdoptions = response.data.data
            that.materiaForm.mStatus = response.data.data[0].id
            that.waterMarkParams.channel = response.data.data[0].id
            that.uploadParams.channel = response.data.data[0].id
            // that.materiaForm.showQdList = response.data.data[0].name
            // that.demandParams.channel = response.data.data[0].id
            // that.queryDemandParams.channel = response.data.data[0].id
            that.getWaterMarkList()
          }
        })
      },
     
      //获取水印列表
      getWaterMarkList(){
        let that = this
        that.myHeaders.Authorization = 'Bearer '+that.$sessionStorage.getItem(config.USER_TOKEN_KEY)
        that.waterMarkParams.pageSize = that.pageSize
        that.waterMarkParams.currentPage = that.currentPage
        that.waterMarkParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        // that.waterMarkParams.currentPage = that.currentPage
        
        $http.axios.post("/api/operationManagement/watermark/v1/getWaterMarkList",that.waterMarkParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.data.data
            that.totalRow = response.data.data.total
            that.totalPage = response.data.data.pageTotal
            console.log(1)
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取人员信息
      getStaffDetail(){
        let that = this
        $http.axios.get("/api/system/user/optionselect").then(function(response) {
          that.materiaPositionOptions = response.data
        })
      },

      mTypeChange(event){
        this.waterMarkParams.accountId = event //获取账户名称当前id
      },
      typeChange(event){
        this.uploadParams.accountId = event.id
        this.uploadParams.accountName = event.name
        this.uploadForm.zhname = event.name
        console.log(this.uploadForm.zhname)
        // this.uploadParams.accountId = event 
      },
      mNameChange(event){
        this.waterMarkParams.searchUserId = event //获取运营人员当前id
      },
      //水印列表查询
      waterMarkQuery(){
        let that = this
        that.waterMarkParams.pageSize = that.pageSize
        that.waterMarkParams.currentPage = that.currentPage
        that.waterMarkParams.channel = that.materiaForm.mStatus
        that.waterMarkParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/watermark/v1/getWaterMarkList",that.waterMarkParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = []
            that.tableData = response.data.data.data
            that.totalRow = response.data.data.total
            that.totalPage = response.data.data.pageTotal
            // that.materiaChannelOptions = that.tableData //渠道下拉
            // that.materiaTypeOptions = that.tableData //用户名称下拉
            // that.materiaPositionOptions = that.tableData //运营人员
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
      // 回显水印详情
      editWaterMark(e){
        let that = this
        that.waterStatus = false
        that.dialogFormVisible = true
        $http.axios.get("/api/operationManagement/watermark/v1/getWaterDetail?id="+e).then(function(response) {
          if(response.data.code == 200){
            that.uploadForm.qdname = response.data.data.channel
            that.uploadForm.zhname = response.data.data.accountName
            that.uploadForm.syname = response.data.data.watermarkName
            that.uploadParams.id = response.data.data.id
            that.uploadParams.watermarkUrl = response.data.data.watermarkUrl
            that.waterMarkUrlShow = response.data.data.watermarkUrl
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取账户名称下拉列表
      getAccountList(){
        let that = this
        that.accountUserId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.get("/api/channel/accountUser/selectList?userId="+that.accountUserId).then(function(response) {
          that.materiaTypeOptions = response.data
          that.materiaTypeOptionsXlk = response.data
            console.log(that.materiaTypeOption)
        })
      },

      //点击上传水印按钮，弹出添加框
      uploadWmark(){
        let that = this
        that.waterStatus = true
        that.uploadParams.channel = ''
        that.uploadParams.accountName = ''
        that.uploadParams.watermarkName = ''
        that.uploadParams.userId = ''
        that.uploadParams.userName = ''
        that.uploadParams.accountId = ''
        that.uploadParams.watermarkUrl = ''
        that.dialogFormVisible = true
        //清空
        that.uploadForm.qdname = ''
        that.uploadForm.zhname = ''
        that.uploadForm.syname = ''

      },
      //上传水印、修改水印
      uploadWaterMark(){
        let that = this
        // that.dialogFormVisible = false
        if(that.waterStatus == true){
          that.uploadParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
          that.uploadParams.userName = that.$sessionStorage.getItem(config.USERNAME)
          that.uploadParams.channel = that.uploadForm.qdname
          // that.uploadParams.accountName = that.uploadForm.zhname
          that.uploadParams.watermarkName = that.uploadForm.syname
          $http.axios.post("/api/operationManagement/watermark/v1/addWaterMark",that.uploadParams).then(function(response) {
            if(response.data.code == 200){
              that.dialogFormVisible = false
              that.getWaterMarkList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        }else{
          that.uploadParams.accountId = that.uploadForm.zhname
          that.uploadParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
          that.uploadParams.userName = that.$sessionStorage.getItem(config.USERNAME)
          that.uploadParams.channel = that.uploadForm.qdname
          that.uploadParams.accountName = that.uploadForm.zhname
          that.uploadParams.watermarkName = that.uploadForm.syname

          console.log(that.uploadParams.watermarkUrl)
          
          $http.axios.post("/api/operationManagement/watermark/v1/updateWaterMark",that.uploadParams).then(function(response) {
            if(response.data.code == 200){
              that.dialogFormVisible = false
              that.getWaterMarkList()
              console.log(that.uploadParams.watermarkUrl)
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        }
        
      },

      //上传水印
      //文件列表移除文件时的钩子
      handleRemove(file, fileList) {
        console.log(file, fileList)
      },
      //点击已上传的文件链接时的钩子,获取服务器返回的数据
      handlePreview(file) {
        console.log(file)
      },

      //文件上传成功时的钩子
      onSuccess(response, file, fileList) {
        console.log(response)    //服务器返回的图片信息，可以提交到表格
        this.uploadParams.watermarkUrl = response.data
        this.waterMarkUrlShowFirst = response.data
        this.waterMarkUrlShow = response.data
        // if (fileList.length > 1) {
        //   fileList.splice(0, 1)
        // } 
        this.$message({
          // message: response.msg
          showClose: true,
          message: response.msg,
          // type: 'error'
          type: 'success'
        })
        this.$refs.upload.clearFiles()//上传成功清除列表，否则每次上传都要删掉上次上传的列表
      },
      onError(response, file, fileList) {
        console.log(response.msg)
        this.$message.error(response.msg)
      },

      //删除
      deleteWaterMark(e){
        let that = this
        that.deleteParams.id = e
        that.deleteParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/watermark/v1/updateWaterMark",that.deleteParams).then(function(response) {
          if(response.data.code == 200){
            that.getWaterMarkList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },



      //表格中选中和未选中
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      var params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val
      var params = {
        currentPage: this.currentPage,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getWaterMarkList()
    },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .materiaContent{
      margin-top:25px;
    }
    .waterMark_img{
      width:50%;
    }
    .waterMarkDialog .el-form-item{
      display:block;
    }
    .showWaterMark{
      margin:0 auto;
      text-align:center;
    }

  </style>
