<template>
    <div class="authority common-page">
      <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/auth-management/consumptionSum' }">运营管理</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/consumptionSum' }">消耗汇总</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/auth-management/MaterialProduct' }">素材管理</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialTab">
                <span @click="mProduct">客户</span>
                <span @click="mProject">项目</span>
                <span class="materialTabCurrent">标注</span>
              </div>
              <div class="materialHBtns">
                <el-form :inline="true" :model="materiaForm" ref="materiaForm" class="demo-form-inline">
                  <!-- <el-form-item label="" prop="mType">
                    <el-select v-model="materiaForm.mType" placeholder="请选择渠道">
                      <el-option
                        v-for="(item,i) in materiaTypeOptions"
                        :key="i"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item label="" prop="mPosition" class="positionBox">
                    <!-- <el-input v-model="materiaForm.materialName" autocomplete="off" placeholder="请搜索素材名称" @change="tagsDetail"></el-input> -->
                    <!-- <ul v-show="tagsShow" class="tagsList">
                      <li v-for="(item,index) in fSearch" :key="index" @click="tagListChecked(item)">
                        <span>{{item}}</span>
                      </li>
                    </ul> -->
                    <el-autocomplete
                      class="inline-input"
                      v-model="materiaForm.materialName"
                      value-key="materialName"
                      :fetch-suggestions="querySearch"
                      placeholder="请搜索素材名称"
                      @select="handleSelect"
                    ></el-autocomplete>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
                    <!-- <el-button @click="resetForm('materiaForm')">重置</el-button> -->
                    <el-button type="primary" size="small" @click="addTagMaterial">添加素材</el-button>
                    <el-button type="primary" size="small" @click="deleteAllTags">删除</el-button>
                    <el-button type="primary" size="small" @click="goback">返回</el-button>
                  </el-form-item>
                </el-form>

                <el-dialog title="添加素材" :visible.sync="dialogTableVisible" width="70%">
                  <el-table 
                  :data="gridData"
                  ref="multipleTablexz">
                    <el-table-column
                      type="selection"
                      width="55">
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="渠道名称"
                      width="100">
                      <template slot-scope="scope">
                        {{scope.row.channelId}}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="素材名称"
                      width="180">
                      <template slot-scope="scope">{{scope.row.mtName}}</template>
                    </el-table-column>
                    <el-table-column 
                      prop="name"
                      label="素材标签"> 
                      <template slot-scope="scope">{{scope.row.showTagNames}}</template>
                    </el-table-column>
                    <el-table-column
                      label="素材内容"
                      width="100">
                      <template slot-scope="scope">
                        <div>{{scope.row.mainTitile}}</div>
                        <span v-for="val in scope.row.url.imgList" v-if="scope.row.type==1">
                          <img :src="val" style="width:50%;margin:0 10px;">
                        </span>
                        <!-- <span v-if="scope.row.type==2">
                          <img src="../../../../assets/img/videoImg.jpeg" style="width:50px;cursor: pointer;" @click="showVideo(scope.row)"/>
                        </span> -->
                        <span v-if="scope.row.type==2" class="picTip">
                          <img :src="scope.row.url.videoUrl+firstWord" style="width:200px;cursor: pointer"/>
                          <!-- <el-popover
                            placement="right"
                            title=""
                            trigger="hover">
                            <img :src="scope.row.url.videoUrl+firstWord" style="width:200px;cursor: pointer"/>
                            <img slot="reference" :src="scope.row.url.videoUrl+firstWord" style="width:50%;cursor: pointer"  @click="showVideo(scope.row)">
                          </el-popover> -->
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="编导"
                      width="80">
                      <template slot-scope="scope">{{scope.row.directorUserName}}</template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="摄影"
                      width="80">
                      <template slot-scope="scope">{{scope.row.photographyUserName}}</template>
                    </el-table-column>
                    <el-table-column
                      prop="name"
                      label="剪辑"
                      width="80">
                      <template slot-scope="scope">{{scope.row.clipUserName}}</template>
                    </el-table-column>
                  </el-table>
                  <div class="footerPaging">
                    <el-pagination
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page="page"
                      :page-sizes="[10, 20, 30, 40]"
                      :page-size="pageCount"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="this.totalRow">
                    </el-pagination>
                  </div>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogTableVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureNewMaterial">确 定</el-button>
                  </div>
                </el-dialog>

                <el-dialog
                  title="提示"
                  :visible.sync="deleteDialogVisible"
                  width="30%">
                  <span>确定要删除吗</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="deleteDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="deleteAllTags_sure">确 定</el-button>
                  </span>
                </el-dialog>
              </div>
              <el-dialog title="新建标签" :visible.sync="dialogFormVisible" width="20%">
                <el-form :model="form">
                  <el-form-item label="" >
                    <el-input v-model="form.newtagName" autocomplete="off" placeholder="请输入标签名称"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false">取 消</el-button>
                  <el-button type="primary" >确 定</el-button>
                </div>
              </el-dialog>
            </div>
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  width="55">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="序号"
                  type="index"
                  :index="indexMethod"
                  width="120">
                  <!-- <template slot-scope="scope">{{ scope.row.materialId }}</template> -->
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="素材名称"
                  width="350">
                  <template slot-scope="scope">{{ scope.row.materialName }}</template>
                </el-table-column>
                <el-table-column 
                  prop="name"
                  label="素材内容"
                  width="100">
                  <template slot-scope="scope">
                    <span v-for="val in scope.row.url.imgList" v-if="scope.row.type==1">
                      <img :src="val" style="width:50%;margin:0 10px;">
                    </span>
                    <!-- <span v-if="scope.row.type==2">
                      <video :src="scope.row.url.videoUrl" controls style="width:30%;margin:0 10px;"></video>
                    </span> -->
                    <span v-if="scope.row.type==2" class="picTip">
                      <el-popover
                        placement="right"
                        title=""
                        trigger="hover">
                        <!-- <img :src="require('../../../../assets/img/tip'+scope.row.costStatus+'.png')" style="position:absolute;width:40%;top:0;left:0;"/>                      -->
                        <img :src="scope.row.url.videoUrl+firstWord" style="width:200px;cursor: pointer"/>
                        <img slot="reference" :src="scope.row.url.videoUrl+firstWord" style="width:50%;cursor: pointer"  @click="showVideo(scope.row)">
                        <!-- <img slot="reference" class="tip" :src="require('../../../../assets/img/tip'+scope.row.costStatus+'.png')" /> -->
                      </el-popover>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button class="newBtn_Yellow" plain size="mini" @click="editTag(scope.row)">修改</el-button>
                    <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deleteTag(scope.row.materialId)"
                   >
                      <template #reference>
                        <el-button class="newBtn_orang" plain size="mini" style="margin-left:10px;">删除</el-button>
                      </template>
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-dialog title="修改标注名称" :visible.sync="tagDialogVisible" width="30%">
              <el-form :model="tagsForm">
                <el-form-item label="" >
                  <el-input v-model="tagsForm.name" autocomplete="off" :placeholder="rowtagName"></el-input>
                </el-form-item>
                <el-form-item label="" >
                  <el-select v-model="tagsForm.region" filterable placeholder="请选择标注名称" @change="changeTagName">
                    <el-option
                      v-for="(item,i) in tagsData"
                      :key="i"
                      :label="item.taggingName"
                      :value="item">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="tagDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureEditTag">确 定</el-button>
              </div>
            </el-dialog>
            <el-dialog
              title=""
              :visible.sync="centerDialogVisible"
              width="70%"
              ref="videoBtn"
              center
              :before-close="handleDialogClose"
              class="mineBg">
              <span style="text-align:center;display:block;">
                <video :src="videoUrlShow" autoplay controls controlslist="nodownload" ref="videoPouse" style="width:30%;margin:0 auto;"></video>
              </span>
              <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
              </span> -->
            </el-dialog>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
              </el-pagination>
            </div>
         </template>
        <template slot="headerGroup">
        </template>
        
      </PocCardTable>
    </div>
  </template>
  
  <script>
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';

  export default {
    name: 'materiaList',
    data() {
      return {
        tagsShow:false,
        materiaForm:{//头部查询表单的prop
          mType:'今日头条',
          materialName:'',
          materialAccount:'',
          materialDate:''
        },
        materiaTypeOptions: [],
        materiaPositionOptions:[{//查询表单的选择运营人员
          value: '选项1',
          label: '联盟'
        }, {
          value: '选项2',
          label: '富媒体'
        }, {
          value: '选项3',
          label: '信息流'
        }],
        tableData: [],
        multipleSelection: [],
        // 分页
        page: 1,//当前页
        pageCount: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        totalNum:'',
        tagParams:{
          channel:'',
          page:'',
          pageCount:'',
          userId:''
        },
        dialogFormVisible:false,
        form:{
          newtagName:''
        },
        newtagParams:{
          name:'',
          userId:''
        },
        detailId:'',
        taglistParams:{
          channel:'',
          page:'',
          pageCount:'',
          taggingId:'',
          userId:''
        },
        queryTaglistParams:{
          channel:'',
          page:'',
          pageCount:'',
          taggingId:'',
          userId:'',
          materialId:'',
          materialName:''
        },
        deleteParams:{
          materialIds:[],
          taggingId:'',
          userId:''
        },
        deleteDialogVisible:false,
        materialNames:[],
        // fSearch:[],
        // fSearchId:[],
        // queryData:[],
        tagDialogVisible:false,
        tagsForm:{
          name:'',
          region:''
        },
        tagsData:[],
        dialogTableVisible:false,
        materialParams:{//列表参数
          channlId: '1',
          page: '',
          pageCount: '',
          userId: ''
        },
        gridData:[],
        newMaterialParams:{
          materialIds:[],
          newTaggingId:'',
          userId:''
        },
        editParams:{
          materialIds:[],
          newTaggingId:'',
          taggingId:'',
          userId:''
        },
        rowtagName:'',
        videoUrlShow:'',
        centerDialogVisible:false,
        firstWord:'?x-oss-process=video/snapshot,t_1,m_fast'





      }
    },
    computed: {
      //模糊搜索--下拉
      // fSearch(){
      //   let that = this
      //   let fSearch = []
      //   that.fSearchId = []
      //   that.queryData.map(function(item) {
      //     if (item.materialName.search(that.materiaForm.materialName) != -1) {
      //       fSearch.push(item.materialName)
      //       that.fSearchId.push(item.materialId)
      //       // console.log(fSearch)
      //       // console.log(that.fSearchId)
      //     }
      //   })
      //   if(that.materiaForm.materialName.length>0){
      //     that.tagsShow=true;
      //   }else{
      //     that.tagsShow=false;
      //   }
      //   return fSearch
      // } 
    },
    mounted() {
      this.getId()
      // this.getChannelList()
      // this.getTaggingList()
      this.getuserChannel()
      this.getTagSelect()
    },
    // created(){
    //   var params = {
    //     page: this.page,
    //     pageCount: this.pageCount
    //   }
    //   this.findAll(params)
    // },
    methods: {
      //头部表单重置
      resetForm(materiaForm) {
        this.$refs[materiaForm].resetFields();
        this.$router.go(0)
      },

       //根据id弹出视频
       showVideo(row){
         console.log(111)
        this.centerDialogVisible = true
        this.videoUrlShow = row.url.videoUrl
        this.$nextTick(() => {
          this.$refs.videoPouse.play()
        })
        console.log(row.url.videoUrl)
        console.log(this.$refs.videoBtn)
      },
      handleDialogClose(){
        console.log(12)
        this.centerDialogVisible = false
        this.$refs.videoPouse.pause()
      },

      //模糊搜索
      querySearch(queryString, cb) {
        var restaurants = this.tableData;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.materialName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      handleSelect(item) {
        console.log(item);
      },

      //tab跳转
      mProduct(){
        this.$router.push('/auth-management/MaterialProduct')
      },
      mProject(){
        this.$router.push('/auth-management/MaterialProject')
      },
      indexMethod(index) {
        return index+1;
      },

      //获取相应id
      getId(){
        this.detailId = this.$route.params && this.$route.params.taggingid
        // console.log(this.$route.params.taggingName)
      },

      //修改后的关联到user的channel
      getuserChannel(){
        let that = this
        let userChannel = String(that.$sessionStorage.getItem(config.USERID) || 1)
        $http.axios.get("/api/system/user/getChannelByUser?userId="+userChannel).then(function(response) {
          // that.materiaChannelOptions = response.data.data
          // that.materiaForm.showQdList = response.data.data[0].name
          // that.demandParams.channel = response.data.data[0].id
          // that.queryDemandParams.channel = response.data.data[0].id
          // that.getDemandOrder()
          if(response.data.data.length!=0){
            that.taglistParams.channel = response.data.data[0].id
            that.queryTaglistParams.channel = response.data.data[0].id
            that.getTaggingList()
          }
        })
      },

      // //获取渠道列表
      // getChannelList(){
      //   let that = this
      //   $http.axios.get("/api/system/user/getChannelList").then(function(response) {
      //     if(response.data.code == 200){
      //       that.materiaTypeOptions = response.data.data
      //     } else {
      //       that.$message({
      //         showClose: true,
      //         message: response.data.msg,
      //         type: 'error'
      //       })
      //     }
      //   })
      // },

      //获取标注列表
      getTaggingList(){
        let that = this
        
        that.taglistParams.pages = that.page
        that.taglistParams.pageCount = that.pageCount
        that.taglistParams.taggingId = that.detailId
        that.taglistParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/materialoperation/gettagMaterialList",that.taglistParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = response.data.data.items
            that.totalRow = response.data.data.totalCount
            that.queryData = that.tableData
            that.materialNames = []
            that.tableData.forEach(function(val,item){
              // console.log(val)
              that.materialNames.push(val.materialName)
              // console.log(that.materialNames)
            })
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },
      
      //模糊搜索--把li的选项选到搜索框里
      // tagListChecked(item){
      //   this.materiaForm.materialName = item
      // },

      // //
      // tagsDetail(val){
      //   console.log(val)
      // },

       //点击查询按钮
       onSubmit() {
        let that = this
        
        that.queryTaglistParams.pages = that.page
        that.queryTaglistParams.pageCount = that.pageCount
        that.queryTaglistParams.taggingId = Number(that.detailId)
        that.queryTaglistParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        that.queryTaglistParams.materialId = Number(that.fSearchId)
        that.queryTaglistParams.materialName = that.materiaForm.materialName
        $http.axios.post("/api/operationManagement/materialoperation/gettagMaterialList",that.queryTaglistParams).then(function(response) {
          if(response.data.code == 200){
            that.tableData = []
            that.tableData = response.data.data.items
            that.totalRow = response.data.data.totalCount
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
        
      },

      //返回
      goback(){
        this.$router.back()
      },

      //删除
      deleteTag(i){
        let that = this
        that.deleteParams.materialIds.push(i)
        that.deleteParams.taggingId = Number(that.detailId)
        that.deleteParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/materialoperation/delmaterialTagging",that.deleteParams).then(function(response) {
          if(response.data.code == 200){
            that.getTaggingList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //批量删除弹框
      deleteAllTags(){
        let that = this
        // that.deleteDialogVisible = true
        let modifySelection = that.$refs.multipleTable.selection
        modifySelection.forEach(function(e,i){
          console.log(e)
          that.deleteParams.materialIds.push(e.materialId)
          console.log(that.deleteParams.materialIds)
        })
        if(modifySelection.length == 0){
          that.$message({
            message: '请勾选素材标注',
            type: 'warning'
          });
          that.deleteDialogVisible = false
        }else{
          that.deleteDialogVisible = true
        }
      },

      //确定批量删除
      deleteAllTags_sure(){
        let that = this
        that.deleteDialogVisible = false
        that.deleteParams.taggingId = Number(that.detailId)
        that.deleteParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/materialoperation/delmaterialTagging",that.deleteParams).then(function(response) {
          if(response.data.code == 200){
            that.getTaggingList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取标注名字详情
      changeTagName(val){
        console.log(val)
        this.tagsForm.region = val.taggingName
        this.editParams.newTaggingId = val.id
        console.log(this.editParams.newTaggingId)
      },

      //修改标注
      editTag(row){
        this.tagDialogVisible = true
        console.log(row)
        this.rowtagName = row.materialName
        this.editParams.materialIds.push(row.materialId)
      },

      //修改标注下名称
      sureEditTag(){
        let that = this
        // that.editParams.materialIds = 
        // that.editParams.newTaggingId = 
        that.editParams.taggingId = that.detailId
        that.editParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
        $http.axios.post("/api/operationManagement/materialoperation/changematerialTagging",that.editParams).then(function(response) {
          if(response.data.code == 200){
            console.log(response)
            that.tagDialogVisible = false
            that.getTaggingList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //标注下拉框
      getTagSelect(){
        let that = this
        $http.axios.post("/api/operationManagement/materialoperation/getallTagging").then(function(response) {
          if(response.data.code == 200){
            that.tagsData = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //获取素材库列表
      getMaterialList(){
          let that = this
          that.materialParams.page = that.page
          that.materialParams.pageCount = that.pageCount
          that.materialParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
          
          $http.axios.post("/api/operationManagement/material/list",that.materialParams).then(res=> {
            if(res.data.code == 200){
              that.gridData = res.data.data.items
              that.totalRow = res.data.data.totalCount  
            }  else {
              that.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        },

        //添加素材
        addTagMaterial(){
          console.log(111)
          let that = this
          // this.$router.push('/auth-management/MaterialAddTag')
          that.dialogTableVisible = true
          that.getMaterialList()
          
        },

        sureNewMaterial(){
          let that =this
          let modifySelection = that.$refs.multipleTablexz.selection
          console.log(modifySelection)
          modifySelection.forEach(function(e,i){
            that.newMaterialParams.materialIds.push(e.id)
            // that.modifyTagName = e.showTagNames
            console.log(that.newMaterialParams)
          })
          that.newMaterialParams.newTaggingId = that.detailId
          console.log(that.detailId)
          that.newMaterialParams.userId = that.$sessionStorage.getItem(config.USERID) || 1
          $http.axios.post("/api/operationManagement/materialoperation/addmaterialTagging",that.newMaterialParams).then(function(response) {
            if(response.data.code == 200){
              // that.getTagList()
              console.log(response)
              that.dialogTableVisible = false
              that.getTaggingList()
            } else {
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        },






      // 分页
      handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageCount = val
      var params = {
        page: this.page,
        pageCount: this.pageCount
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.page = val
      var params = {
        page: this.page,
        pageCount: this.pageCount
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getTaggingList()
    },

      //表格中选中和未选中
      toggleSelection(rows) {
        if (rows) {
          rows.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    ul,li{
      margin:0;
      padding:0;
    }
    .materiaContent{
      margin-top:25px;
    }
    .materialTab{
      font-size:14px;
      color:#6e7c7c;
      margin-bottom:20px;
    }
    .materialTabCurrent{
      color:#333;
      font-size:16px;
    }
    .materialTab span{
      display:inline-block;
      vertical-align:middle;
      margin-right:10px;
      cursor: pointer;
    }
    .positionBox{
      position:relative;
    }
    .tagsList{
      position:absolute;
      top:30px;
      left:0;
      width:100%;
      border:1px solid #ddd;
      border-top:none;
    }
    .tagsList li{
      font-size:12px;
      padding-left:15px;
      cursor:pointer;
    }
    ::v-deep .mineBg{
       .el-dialog {
        background:none!important;
        box-shadow:none!important;
        .el-dialog__headerbtn .el-dialog__close{
          color:#fff;
          font-size:24px;
        }
      }
    }

  </style>
