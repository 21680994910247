<template>
  <el-dialog
    title="自定义列"
    :visible.sync="columnVisible"
    center
    :show-close = "false"
    class="zdylMargin"
    width="820px">
    <div class="dialogIcon"><img :src='ZDYLicon1'></div>
    <div class="columnWrap">
      <div class="column_left">
        <template>
          <div>
            <!-- <span class="zdybox">接口信息</span> -->
            <!-- <span class="zdyboxTitle">基础属性</span>
            <el-checkbox :indeterminate="isIndeterminateSystem" v-model="checkAllSystem" @change="(val) => handleCheckAllChange(val,'基础属性')">全选</el-checkbox> -->
            <el-checkbox-group 
              v-model="checkedSystemId" @change = "checed => handleCheckedCitiesChange('基础属性',checkedSystemId)">
              <el-checkbox v-for="(item,i) in systemList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdybox zdylStyle">
            <span class="zdyboxTitle">展示指标</span>
            <el-checkbox :indeterminate="isIndeterminateInfo" class="zdylAll" v-model="checkAllInfo" @change="(val) => handleCheckAllChange(val,'展示指标')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedInfoId" @change = "checed => handleCheckedCitiesChange('展示指标',checkedInfoId)">
              <el-checkbox v-for="(item,i) in infoList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdybox zdylStyle">
            <span class="zdyboxTitle">应用转化指标(转化时间)</span>
            <el-checkbox :indeterminate="isIndeterminateData" class="zdylAll" v-model="checkAllData" @change="(val) => handleCheckAllChange(val,'应用转化指标(转化时间)')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedDataId" @change = "checed => handleCheckedCitiesChange('应用转化指标(转化时间)',checkedDataId)">
              <el-checkbox v-for="(item,i) in dataList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdybox zdylStyle">
            <span class="zdyboxTitle">应用转化指标(计费时间)</span>
            <el-checkbox :indeterminate="isIndeterminateConversion" class="zdylAll" v-model="checkAllConversion" @change="(val) => handleCheckAllChange(val,'应用转化指标(计费时间)')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedConversionId" @change = "checed => handleCheckedCitiesChange('应用转化指标(计费时间)',checkedConversionId)">
              <el-checkbox v-for="(item,i) in conversionList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
        <template>
          <div class="zdybox zdylStyle">
            <span class="zdyboxTitle">网页转化指标</span>
            <el-checkbox :indeterminate="isIndeterminateWeb" class="zdylAll" v-model="checkAllweb" @change="(val) => handleCheckAllChange(val,'网页转化指标')">全选</el-checkbox>
            <el-checkbox-group class="zdylChecked"
              v-model="checkedWebId"  @change = "checed => handleCheckedCitiesChange('网页转化指标',checkedWebId)">
              <el-checkbox v-for="(item,i) in webList" :label="item.id" :key="i" class="checkboxStyle">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </template>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" class="newDialogCancel" @click="columnVisibleFn">取消保存</el-button>
      <el-button type="primary" class="newDialog" @click="generateTemplate">确认保存</el-button>
    </span>
  </el-dialog>  
</template>
<script>
  import ZDYLicon1 from '@/assets/img/ZDYLicon1.png';
  import Api from "@/api";
  import config from '@/api/config';
  import { $http } from '@/api/http';
  import alertMsgFn from '@/assets/js/alert';
  import Qs from 'qs'
  export default {
    name: 'Daily',
    props:{
       pubVue: {
      type: Object
    },
    typeName:Number
    },
    data() {
      return {
        ZDYLicon1,
        userId:'',
        userName:'',
        deptId:'',
        deptName:'',
        ruleForm:{//查询条件
          channelName:'',
          templateName:'',
          dateChoice:''
        },
        allList:[],//自定义返回的所有
        tableData: [],//时报列表数据
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: 0,//总条数
        totalPage: 0,//总页数
        batchOperation:'',
        columnVisible:this.visible,//生成模版弹框隐藏显示
        checkedCities: [],//生成模版选择了多少选项
        checkedInfo: [],//
        checkedSystem: [],//
        checkedData: [],//
        checkedConversion: [],//
        checkedWeb:[],
        checkedInfoId: [],//
        checkedSystemId: [],//
        checkedDataId: [],//
        checkedConversionId: [],//
        checkedWebId:[],
        newWords:'',//生成新的时报模板字段
        system:[],
        info:[],
        data:[],
        conversion:[],//点击生成按钮返回的关键字段
        web:[],
        systemList:[],
        infoList:[],//选中的基础信息
        dataList:[],//选中的基础数据
        conversionList:[],//选中的转化数据
        webList:[],
        systemIdList:[],
        infoIdList:[],
        dataIdList:[],
        conversionIdList:[],
        webIdList:[],
        deleteWords:false,//删除手动添加字段的按钮
        addWordsParams:{//添加自定义字段入参
          name:'',
          userId:'',
          userName:''
        },
        deleteParams:{//删除手动添加关键字的入参
          ids:[],
          userId:'',
          userName:''
        },
        newTempName:'',//模版名称
        dialogVisible:false,
        isIndeterminateInfo:false,
        checkAllInfo:false,
        isIndeterminateSystem:false,
        checkAllSystem:false,
        isIndeterminateData:false,
        checkAllData:false,
        isIndeterminateConversion:false,
        checkAllConversion:false,
        isIndeterminateWeb:false,
        checkAllweb:false,
        titleListAll:{
          1:[
            {name:'广告计划ID',prop:'id'},
            {name:'广告计划类型',prop:'mediaType'},//0-应用商店 1-非应用商店 2-广告联盟
            {name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
            {name:'设置计划',prop:'openClosePlan'},//1是0否
            {name:'预警状态',prop:'warningStatus'}//0-正常 1-禁用 2-删除
          ],
          2:[{name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
          ],
          3:[
            {name:'开关',prop:'pauseState'},
            {name:'广告ID',prop:'id'},
            {name:'所属广告组名称',prop:'groupName'},
            {name:'所属广告组ID',prop:'groupId'},
            {name:'所属广告计划名称',prop:'campaignName'},
            {name:'所属广告计划ID',prop:'campaignId'},
            {name:'广告生成类型',prop:'genType'},//广告生成类型 0:自定义广告 1:程序化广告
          ] 
        }


      };
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptName = this.$sessionStorage.getItem(config.DEPTNAME) 
      this.deptId = this.$sessionStorage.getItem(config.DEPTID) 
    },
    methods: {
      //弱提示
       alertMsgFn(msg,type){
        this.$message({
          showClose: true,
          message: msg,
          type: type
        })  
      },
      columnVisibleFn(){
        let _this = this
        _this.columnVisible = false
      },
      handleCheckAllChange(val,type) {
        console.log(this.checkedCities,this.info,type,val)
        let _this = this
        if(type == '展示指标'){
          _this.checkedInfoId = val ? _this.infoIdList : [];
          _this.checkedInfo = val ? _this.infoList : [];
          _this.isIndeterminateInfo = false;
        }else if(type == '应用转化指标(转化时间)'){
          _this.checkedDataId = val ? _this.dataIdList : [];
          _this.checkedData = val ? _this.dataList : [];
          _this.isIndeterminateData = false;
        }else if(type == '应用转化指标(计费时间)'){
          _this.checkedConversionId = val ? _this.conversionIdList : [];
          _this.checkedConversion = val ? _this.conversionList : [];
          _this.isIndeterminateConversion = false;
        }else if(type == '基础属性'){
          _this.checkedSystemId = val ? _this.systemIdList : [];
          _this.checkedSystem = val ? _this.systemList : [];
          _this.isIndeterminateSystem = false;
        }
        else if(type == '网页转化指标'){
          _this.checkedWebId = val ? _this.webIdList : [];
          _this.checkedWeb = val ? _this.webList : [];
          _this.isIndeterminateWeb = false;
        }
        // if(this.checkedCities.length!=0&&val){
        //   this.checkedCities = this.checkedCities.concat(checkedInfo,checkedData,checkedConversion,checkedSystem,checkedWeb)
        // }else{
          this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkedSystem,_this.checkedWeb)
        // }
      },
      handleCheckedCitiesChange(type,value) {
        let _this = this,val = [],checkedCountInfo = this.checkedInfoId,checkedCountData = this.checkedDataId,checkedCountConversion = this.checkedConversionId,checkedCountSystem = this.checkedSystemId,checkedCountWeb = this.checkedWebId
        _this.allList.forEach((obj,i)=>{
          value.forEach((ele,j)=>{
            if(obj.id == ele){
              val.push(obj)
            }
          })
        })
        if(type == '展示指标'){
          _this.checkedInfoId = value 
          checkedCountInfo = value.length
          this.checkAllInfo = checkedCountInfo === this.infoIdList.length;
          this.isIndeterminateInfo = checkedCountInfo > 0 && checkedCountInfo < this.infoIdList.length;
          _this.checkedInfo = val
        }else{
          _this.checkedInfo = []
          _this.checkedInfoId.forEach((obj)=>{
            _this.infoList.forEach((ele)=>{
              if(ele.id == obj){
                _this.checkedInfo.push(ele)
              }
            })
          })
        }
        if(type == '应用转化指标(转化时间)'){
          _this.checkedDataId = value
          checkedCountData =value.length
          this.checkAllData = checkedCountData === this.dataIdList.length;
          this.isIndeterminateData = checkedCountData > 0 && checkedCountData < this.dataIdList.length;
          _this.checkedData = val
        }else{
          _this.checkedData = []
          _this.checkedDataId.forEach((obj,i)=>{
            _this.dataList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedData.push(ele)
              }
            })
          })
        }
        if(type == '应用转化指标(计费时间)'){
          _this.checkedConversionId = value 
          checkedCountConversion = value.length;
          this.checkAllConversion = checkedCountConversion === this.conversionIdList.length;
          this.isIndeterminateConversion = checkedCountConversion > 0 && checkedCountConversion < this.conversionIdList.length;
          _this.checkedConversion = val
        }else{
          _this.checkedConversion = []
          _this.checkedConversionId.forEach((obj,i)=>{
            _this.conversionList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedConversion.push(ele)
              }
            })
          })
        }
        if(type == '基础属性'){
          _this.checkedSystemId = value
          checkedCountSystem = value.length
          this.checkAllSystem = checkedCountSystem === this.systemIdList.length;
          this.isIndeterminateSystem = checkedCountSystem > 0 && checkedCountSystem < this.systemIdList.length;
          _this.checkedSystem = val
        }else{
           _this.checkedSystem = []
           _this.checkedSystemId.forEach((obj,i)=>{
            _this.systemList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedSystem.push(ele)
              }
            })
          })
        }
        if(type == '网页转化指标'){
          _this.checkedWebId = value
          checkedCountWeb = value.length
          this.checkAllWeb = checkedCountWeb === this.webIdList.length;
          this.isIndeterminateWeb = checkedCountWeb > 0 && checkedCountWeb < this.webIdList.length;
          _this.checkedWeb = val
        }else{
          _this.checkedWeb = []
          _this.checkedWebId.forEach((obj,i)=>{
            _this.webList.forEach(ele=>{
              if(ele.id == obj){
                _this.checkedWeb.push(ele)
              }
            })
          })
        }
        this.checkedCities = _this.checkedInfo.concat(_this.checkedData,_this.checkedConversion,_this.checkedSystem,_this.checkedWeb)
      },
      //生成时报模板弹框显示
      generateTimes(num){
        this.columnVisible = true
        this.allWords(num)
      },
      //查询全部关键字接口
      allWords(type){
        let that = this,params = {},checkedList = [],systemList = [],infoList = [],dataList = [],conversionList = [],webList = [],systemIdList = [],infoIdList = [],dataIdList = [],conversionIdList = [],webIdList = []
        params = {'channelId': 4,'reportType':type}
        $http.axios.post("/api/vivo/keyWord/list",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code == 200){
            // that.conversion = res.data.content.conversion
            // that.info = res.data.content.info
            // that.data = res.data.content.data
            // that.system = res.data.content.system
            // that.web = res.data.content.web
            // checkedList = that.info.concat(that.data,that.conversion,that.system,that.web)
            checkedList = res.data.data
            checkedList.forEach((e,i,arr)=>{
              if(e.type == "基础属性"){
                systemList.push(e)
                systemIdList.push(e.id)
              }else if(e.type == '展示指标'){
                infoList.push(e)
                infoIdList.push(e.id)
              } else if (e.type == '应用转化指标(转化时间)'){
                dataList.push(e)
                dataIdList.push(e.id)
              }else if(e.type == '应用转化指标(计费时间)'){
                conversionList.push(e)
                conversionIdList.push(e.id)
              }else if(e.type == '网页转化指标'){
                webList.push(e)
                webIdList.push(e.id)
              }
            })
            that.allList = checkedList
            that.checkedWordsFn(type)
            that.systemList = systemList
            that.infoList = infoList
            that.dataList = dataList
            that.conversionList = conversionList
            that.webList = webList
            that.systemIdList = systemIdList
            that.infoIdList = infoIdList
            that.dataIdList = dataIdList
            that.conversionIdList = conversionIdList
            that.webIdList = webIdList
          }  else {
            that.alertMsgFn(res.data.msg,'error')
          }
        })
      },
      //用户选中的
      checkedWordsFn(type){
        let that = this,params,checkedCities = [],titleList = [],checkedInfo = [],checkedData = [],checkedConversion = [],checkedSystem = [],checkedWeb = [],checkedInfoId = [],checkedDataId = [],checkedConversionId = [],checkedSystemId = [],checkedWebId = []
         params = {'userId': that.userId,'reportType':type}
        $http.axios.post("/api/vivo/keyWord/query",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
          if(res.data.code == 200){
            if(res.data.data.length!=0){
                res.data.data.forEach((e,i)=>{
                  titleList.push({'name':e.name,'prop':e.fieldName})
                  if(e.type == '基础属性'){
                    checkedSystem.push(e)
                    checkedSystemId.push(e.id)
                  } else if(e.type == '展示指标'){
                    checkedInfo.push(e)
                    checkedInfoId.push(e.id)
                  } else if (e.type == '应用转化指标(转化时间)'){
                    checkedData.push(e)
                    checkedDataId.push(e.id)
                  }else if(e.type == '应用转化指标(计费时间)'){
                    checkedConversion.push(e)
                    checkedConversionId.push(e.id)
                  }else if(e.type == '网页转化指标'){
                    checkedWeb.push(e)
                    checkedWebId.push(e.id)
                  }
                  checkedCities.push(e)
                })
              that.checkedCities = checkedCities
              that.pubVue.titleList = that.titleListAll[that.typeName].concat(titleList)
              that.checkedInfo = checkedInfo
              that.checkedData = checkedData
              that.checkedConversion = checkedConversion
              that.checkedSystem = checkedSystem
              that.checkedWeb = checkedWeb
              that.checkedInfoId = checkedInfoId
              that.checkedDataId = checkedDataId
              that.checkedConversionId = checkedConversionId
              that.checkedSystemId = checkedSystemId
              that.checkedWebId = checkedWebId
              if(that.checkedInfo.length>0){
                that.isIndeterminateInfo = true
                if(that.checkedInfo.length == that.info.length){
                  that.checkAllInfo = true
                  that.isIndeterminateInfo = true
                }
              }else{
                that.isIndeterminateInfo = false
                that.checkAllInfo = false
              }
              if(that.checkedData.length>0){
                that.isIndeterminateData = true
                if(that.checkedData.length == that.data.length){
                  that.checkAllData = true
                  that.isIndeterminateData = true
                }
              }else{
                that.checkAllData = false
                that.isIndeterminateData = false
              }
              if(that.checkedConversion.length>0){
                that.isIndeterminateConversion = true
                if(that.checkedConversion.length == that.conversion.length){
                  that.checkAllConversion = true
                  that.isIndeterminateConversion = true
                }
              }else{
                that.checkAllConversion = false
                that.isIndeterminateConversion = false
              }
              if(that.checkedSystem.length>0){
                that.isIndeterminateSystem = true
                if(that.checkedSystem.length == that.system.length){
                  that.checkAllSystem = true
                  that.isIndeterminateSystem = true
                }
              }else{
                that.checkAllSystem = false
                that.isIndeterminateSystem = false
              }
              if(that.checkedWeb.length>0){
                that.isIndeterminateWeb = true
                if(that.checkedWeb.length == that.web.length){
                  that.checkAllWeb = true
                  that.isIndeterminateWeb = true
                }
              }else{
                that.checkAllWeb = false
                that.isIndeterminateWeb = false
              }
            }
          }
        })
      },
      //清空自定义列弹框选中项
      clearWrap(){
        this.checkedCities = []
        this.checkedInfo = []
        this.checkedSystem = []
        this.checkedData = []
        this.checkedConversion = []
        this.checkedWeb = []
        this.isIndeterminateInfo=false
        this.checkAllInfo=false
        this.isIndeterminateSystem = false
        this.checkAllSystem = false
        this.isIndeterminateData=false
        this.checkAllData=false
        this.isIndeterminateConversion=false
        this.checkAllConversion=false
        this.isIndeterminateWeb=false
        this.checkAllWeb=false
      },
  
      //自定义弹框右侧选中的项-----删除
      deletClumnChecked(i,val){
        console.log(i)
        console.log(val)
        let _this = this
        if(val.type=='展示指标'){
          _this.checkedInfo.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedInfo.splice(j,1)
              _this.checkedInfoId.splice(j,1)
              if(_this.checkedInfoId.length == 0){
                _this.isIndeterminateInfo=false
                _this.checkAllInfo=false                
              }
            }
          })
        }else if(val.type=='基础属性'){
          _this.checkedSystem.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedSystem.splice(j,1)
              _this.checkedSystemId.splice(j,1)
              if(_this.checkedSystemId.length == 0){
                _this.isIndeterminateSystem=false
                _this.checkAllSystem=false                
              }
            }
          })
        }else if(val.type=='应用转化指标(转化时间)'){
          _this.checkedData.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedData.splice(j,1)
              _this.checkedDataId.splice(j,1)
              if(_this.checkedDataId.length == 0){
                _this.isIndeterminateData=false
                _this.checkAllData=false
              }
            }
          })        
          }else if(val.type=='应用转化指标(计费时间)'){
            _this.checkedConversion.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedConversion.splice(j,1)
              _this.checkedConversionId.splice(j,1)
              if(_this.checkedConversionId.length == 0){
                this.isIndeterminateConversion=false
                this.checkAllConversion=false
              }
            }
          })
        }else if(val.type=='网页转化指标'){
            _this.checkedWeb.forEach((obj,j)=>{
            if(obj.name == val.name){
              _this.checkedWeb.splice(j,1)
              _this.checkedWebId.splice(j,1)
              if(_this.checkedWebId.length == 0){
                this.isIndeterminateWeb=false
                this.checkAllWeb=false
              }
            }
          })
        }
        _this.checkedCities.splice(i,1);
      },
    
      //生成模版
      generateTemplate(){
        let that = this,params = {},titleList = []
        let allwordsArr = []
        if(that.checkedCities.length == 0){
          if(that.typeName == 1){
              that.pubVue.titleList=[
              // {'name':'广告计划名称','prop':'name'},
              // {'name':'广告计划ID','prop':'id'},
              // {'name':'广告计划类型','prop':'mediaType'},//0-应用商店 1-非应用商店 2-广告联盟
              // {'name':'广告类型','prop':'campaignType'},//0-展示广告 1-搜索广告
              // {'name':'开关','prop':'pauseState'},// 0-未暂停 1-已暂停
              // {'name':'设置计划','prop':'openClosePlan'},//1是0否
              // {'name':'状态','prop':'statusDesc'},
              // {'name':'预警状态','prop':'warningStatus'}//0-正常 1-禁用 2-删除
                {name:'广告计划ID',prop:'id'},
                {name:'广告计划类型',prop:'mediaType'},//0-应用商店 1-非应用商店 2-广告联盟
                {name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
                {name:'设置计划',prop:'openClosePlan'},//1是0否
                {name:'预警状态',prop:'warningStatus'}//0-正常 1-禁用 2-删除
              ]

            }else if(that.typeName == 2){
              that.pubVue.titleList=[
                {name:'开关',prop:'pauseState'},// 0-未暂停 1-已暂停
                {name:'预警状态',prop:'warningStatus'},//0-正常 1-禁用 2-删除
                  // {'name':'开关','prop':'pauseState'},// 0-未暂停 1-已暂停
                  // {'name':'状态','prop':'statusDesc'},
                ]           
            }else if(that.typeName == 3){
              that.pubVue.titleList=[
                // {'name':'广告名称','prop':'name'},
                // {'name':'开关','prop':'pauseState'},
                // {'name':'所属广告组名称','prop':'groupName'},
                // {'name':'ID','prop':'id'},
                // {'name':'计划类型','prop':'genType'},//广告生成类型 0:自定义广告 1:程序化广告
                // {'name':'状态','prop':'statusDesc'},
                {name:'开关',prop:'pauseState'},
                {name:'广告ID',prop:'id'},
                {name:'所属广告组名称',prop:'groupName'},
                {name:'所属广告组ID',prop:'groupId'},
                {name:'所属广告计划名称',prop:'campaignName'},
                {name:'所属广告计划ID',prop:'campaignId'},
                {name:'广告生成类型',prop:'genType'},//广告生成类型 0:自定义广告 1:程序化广告
              ]          
            }
            }else{
              that.checkedCities.forEach(function(e,i,arr){
                //去重
                // that.getzdyData.forEach(function(v,i,arr){
                let bool = arr.indexOf(e,i+1)
                if(bool === -1){
                titleList.push({'name':e.name,'prop':e.fieldName})
                allwordsArr.push(e.id)
                }
              }) 
              that.pubVue.titleList = that.titleListAll[that.typeName].concat(titleList)
            }
          params.keyWordIds = allwordsArr.join(',')
          params.userId = that.userId
          params.reportType = that.typeName
          // "keys": [],
          // "type": "",类型 10计划 20广告组 30广告
          // "userId": ""
          $http.axios.post("/api/vivo/keyWord/updateCols",Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {          
            if(res.data.code == 200){
            that.columnVisible = false
            if(that.typeName == 1){
              that.pubVue.getAccountPlanListFn()
            }else if(that.typeName == 2){
              that.pubVue.grouplistFn()
            }else if(that.typeName == 3){
              that.pubVue.v3groupListFn()
            }
          }  else {
            that.alertMsgFn(res.data.msg,'error')
          }
        })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .daily_library{
      margin-bottom:20px;
    }
    .daily_library span{
      padding-right:20px;
      font-size:14px;
      cursor: pointer;
    }
    .daily_library .timesTab{
      font-size:16px;
      font-weight:700;
      color:#6e7c7c;
    }
    .pageWrap{
      clear:both;
      margin-top:15px;
    }
    .pageBatch{
      float:left;
    }
    .paging{
      float:right;
    }
    .columnWrap{
      font-size:0;
    }
    .column_left,.column_right{
      display:inline-block;
      vertical-align:top;
      font-size:14px;
      box-sizing:border-box;
    }
    .column_left{
      width:100%;
      padding-right:20px;
    }
    .column_right{
      width:25%;
      padding-left:20px;
      border-left:1px solid #ddd;
    }
    .column_right ul,.column_right li{
      margin:0;
      padding:0;
      list-style:none;
    }
    .column_right ul li i{
      font-weight:700;
      cursor:pointer;
    }
    .column_right .el-icon-download{
      transform: rotate(180deg);
      padding-right:10px;
    }
    .column_right .el-icon-remove-outline{
      color:brown;
      padding-left:5px;
    }
    .column_clear_showNum i{
      font-style:normal;
      color:#6e7c7c;
      padding:0 3px;
    }
    .column_right_clear{
      border:1px solid #ddd;
      padding:0 5px;
      border-radius:5px;
      margin-bottom:5px;
    }
    .column_clear{
      color:rgba(36, 132, 168, 1);
      float:right;
      cursor: pointer;
    }
    .newWordsBorn{
      margin-bottom:20px;
    }
    .newWordsBorn .el-input{
      width:300px;
      margin-left:10px;
    }
    .handAdd{
      border-top:1px solid #ddd;
      padding-bottom:20px;
      padding-top:20px;
      margin-top:20px;
    }
    .handAddwords{
      margin-top:20px;
      border-top:1px solid #ddd;
      /* padding:20px 0; */
    }
    .handAddwords h3{
      font-size:12px;
    }
    .timeCondition{
      display:inline-block;
      margin:0 3px;
    }
    .newWordsDel{
      float:right;
    }
    .tempName{
      margin-bottom:20px;
      padding-bottom:20px;
      border-bottom:1px solid #ddd;
    }
    .tempName label{
      display:inline-block;
      vertical-align:middle;
      margin-right:10px;
    }
    .tempnameWrite{
      width:200px;
    }
    .byted-weektime .calendar .calendar-table tr,.byted-weektime .calendar .calendar-table td,.byted-weektime .calendar .calendar-table th{
      min-width:3px;
    }
    /* .zdyboxTitle{
      font-weight:700;
      display:inline-block;
      margin:15px 0;
      margin-right:20px;
      color:#66856d;
    } */
    .checkboxStyle{
      width:20%;
      box-sizing: border-box;
      margin-bottom:10px;
    }
    /*新版*/
    .dialogIcon{
      position: absolute;
      top:0;
      left:50%;
      margin-left:-35px;
      margin-top:-35px;
    }
    .zdylStyle{
      margin-bottom:10px;
      position: relative;
    }
    .zdylAll{
      position: absolute;
      top:0;
      right:20px;
    }
    .zdylStyle span{
      display: block;
      font-weight: 500;
      padding:10px 20px;
      box-sizing: border-box;
      background: linear-gradient(270deg, #FFFFFF 0%, #F1F7F5 100%);
      border-radius: 10px 0px 0px 0px;
    }
    .zdylChecked{
      margin-top:10px;
      box-sizing: border-box;
      padding-left:10px;
    }
    .zdylChecked .el-checkbox{
      width:25%;
      margin:3px 0;
    }
    .zdylMargin{
      padding-top:400px;
      box-sizing: border-box;
    }
  </style>
  