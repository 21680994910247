<template>
  <div class="authority common-page">
     <PocCardTable>
       <template slot="headerTitle" v-if="accountSelectionListShow">
        <div class='hl_header'>
          <div class="hl_breadCrumb hl_headerLeft" >
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>计划管理</el-breadcrumb-item>
              <el-breadcrumb-item>账户选择</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button type="success" class="setWarnning" size="small" @click="warmSet" >预警设置</el-button>
            <!-- <el-button type="primary" size="small" @click="warmListIs('dayLimitPrice','modifyPrice/valid')">批量修改创意出价</el-button> -->
            <el-button type="primary" size="small" @click="dayLimitPrice">批量修改创意出价</el-button>
            <el-button type="primary" size="small" @click="bacthEditOcpxPrice">批量修改自动优化广告出价</el-button>
            
            <el-dropdown @command="taskFrame">
              <el-button type="primary" size="small">任务列表</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="1">修改普通广告出价</el-dropdown-item>
                <el-dropdown-item command="2">修改自动优化广告出价</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

            <el-button type="primary" size="small" @click="warmListIs('dayLimit','modifyPrice/valid')">批量日限额</el-button>
            <el-dropdown>
              <el-button type="primary" size="small">
                下载模板<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="cyOne">创意模板</el-dropdown-item>
                <el-dropdown-item @click.native="cyTwo">广告组+创意</el-dropdown-item>
                <el-dropdown-item @click.native="cyThree">广告计划+广告组+广告</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>  
        <div class="chanelTab">
          <span class="channel_mi" :class="item.id==5?'channelActive':''" v-for="(item,i) in channelTitleList" :key="i" @click="item.id!=5&& clickChangeChannel(item.id)">{{item.name.toUpperCase()}}</span>
        </div>
          <div class="materialHead">
            <div class="materialHBtns clearfix">
              <el-form :inline="true" ref="" class="demo-form-inline fL">
                <el-form-item label="" prop="">
                  <el-input v-model="customerName" placeholder="请输入公司名称"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-input v-model="accountUserName" placeholder="请输入账户ID"></el-input>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select clearable placeholder="账户状态" v-model="channeln">
                    <el-option
                      v-for="(item,i) in qdoptions.userStatuList"
                      :key="i"
                      :label="item.text"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="" prop="">
                  <el-select v-model="tokenTypes" placeholder="请选择账户类型" clearable >
                    <el-option
                      v-for="(item,i) in tokenTypeOptions"
                      :key="i"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-form> 
              <el-button type="primary" class="newQuery" size="small" @click="userStatusFn">查询</el-button>
              <el-button type="info" size="small" plian @click="reset">重置</el-button>
            </div>
          </div>
          <div class="materiaContent">
             <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              style="width: 100%;cursor: pointer;"
              fit
              @cell-click="clickcell"
              :cell-class-name="tableCellClassName"
              :cell-style="setRowStyle"
              @selection-change="handleSelectionChange">
              <el-table-column
                type="selection"
                width="55"
                fixed>
              </el-table-column>
              <el-table-column
                prop="accountName"
                label="公司名称"
                width="240"
                fixed="left">
              </el-table-column>
              <el-table-column
                align='center'
                v-for="(item,index) in titleList" :key="index"
                :prop="item.prop"
                :label="item.name"
                width="200"
                show-overflow-tooltip>
                <template v-if='item.prop=="type"' v-slot="scope">
                  <el-switch
                    v-model="scope.row.type"
                    class="switchStyle"
                    :active-value="3"  
                    :inactive-value="2"
                    active-text=""
                    inactive-text=""
                    active-color="#48916F"
                    inactive-color="#EAEFED"
                    :disabled = "scope.row.disabledIs"
                    @change="getBrandList(scope.row)">
                  </el-switch>
                </template>
                <template v-slot="scope" v-else-if='item.prop=="cashBal"'>
                  <span >
                    {{scope.row.cashBal}}
                    <span>
                      <span v-if="scope.row.remType == 1||scope.row.remType == 0"></span>
                      <span v-else-if="scope.row.remType == 2" class="closeStatus"></span>
                      <span v-else class="openStatus"></span>
                    </span>
                  </span>
                </template>
                <template v-slot="scope" v-else-if='item.prop=="cashCost"'>
                  <span >
                    {{scope.row.cashCost}}
                    <span>
                      <span v-if="scope.row.costType == 1||scope.row.costType == 0"></span>
                      <span v-else-if="scope.row.costType == 2" class="closeStatus"></span>
                      <span v-else class="openStatus"></span>
                    </span>
                  </span>
                </template>
                <template v-slot="scope" v-else-if='item.prop=="cashDayBudget"'>
                  <span >
                    {{scope.row.cashDayBudget}}
                    <span>
                      <span v-if="scope.row.budgetType == 1||scope.row.budgetType == 0"></span>
                      <span v-else-if="scope.row.budgetType == 2" class="closeStatus"></span>
                      <span v-else class="openStatus"></span>
                    </span>
                  </span>
                </template>

              </el-table-column>
              <el-table-column
                align="center"
                fixed="right"
                :show-overflow-tooltip="true"
                label="操作"
                width="340px">
                <template v-slot="scope">
                  <div>
                    <!-- 2：服务类隐藏计划、调价、启停 -->
                    <div v-if="scope.row.tokenType==1" style="display: inline-block;">
                      <el-button class="solid-tag-success" plain size="mini" data-type = 'planPage' >计划</el-button>
                      <el-button class="solid-tag-warning" plain size="mini" data-type = 'tiao' >调价</el-button>
                      <el-button class="solid-tag-harmful" plain size="mini" data-type = 'startStop' >启停</el-button>
                    </div>
                    <el-button class="solid-tag-instructions" size="mini" data-type = 'uploadButton' @click="uploadDialogIs =true">上传</el-button>
                    <el-button class="solid-tag-instructions" data-type = 'uploadHistoty' plain size="mini" >上传记录</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="clearfix">
            <div class="allOperation fL">
            </div>
            <div class="footerPaging">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageCount"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.totalRow">
            </el-pagination>
            </div>
            <el-dialog 
              title="批量上传" 
              width="400px"
              center
              :visible.sync="uploadDialogIs">
              <div class="dialogIcon"><img :src='biaoQianIcon'></div>
              <div class="batchUpload ">
                <el-select v-model="uploadValue" placeholder="请选择上传类型" @change = "uploadFn">
                  <el-option
                    v-for="item in uploadOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <div id="uploadBtn" class="uploadBtnBottom">
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :headers="myHeaders"
                    action="/api/mi/adFile/uploadAdFile"
                    accept=".xlsx"
                    :limit='1'
                    :data = fileList
                    :on-success= "onSuccess"
                    :show-file-list='false'>
                    <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                  </el-upload>
                </div>
              </div>
              <div class="uploadDialogtf">
                <el-button type="primary" class="newDialogCancel" @click="uploadDialogIs = false">取 消</el-button>
              </div>   
            </el-dialog>
            <!-- 任务列表 -->
            <el-dialog 
              title="任务列表" 
              width="1000px"
              center
              :visible.sync="taskDialogIs">
              <div class="dialogIcon"><img :src='biaoQianIcon'></div>
              <div>
                <el-table
                  ref="multipleTable"
                  :data="taskTableData"
                  max-height="400px"
                  min-height="100px"
                  tooltip-effect="dark">
                    <el-table-column
                      prop="ownerIds"
                      label="广告主IDs"
                      width="200">
                      <template slot-scope="scope">
                        <span v-if="scope.row.ownerIds.split(',').length>3">
                          <el-button type="text" @click="clickownerIds(scope.row.ownerIds)">查看</el-button>
                        </span>
                        <span v-else>{{scope.row.ownerIds}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop=""
                      label="状态"
                      width="100">
                      <template slot-scope="scope">
                        <span v-if="scope.row.status==1">执行中</span>
                        <span v-else>执行完成</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="bid.price"
                      label="基础出价"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      prop="bid.secondPrice"
                      label="二阶出价"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      prop="bid.threePrice"
                      label="三阶出价"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      prop=""
                      label="结果"
                      width="80">
                      <template slot-scope="scope">
                        <span v-if="scope.row.result==null">-</span>
                        <span v-else>
                          <el-button type="text" @click="clickResult(scope.row.result)">查看</el-button>
                        </span>
                        <!-- <el-button type="text" @click="clickResult(scope.row.result)">查看</el-button> -->
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="userName"
                      label="操作人"
                      width="100">
                    </el-table-column>
                    <el-table-column
                      prop="createTime"
                      label="创建时间"
                      width="150">
                    </el-table-column>
                    <el-table-column 
                      prop="updateTime"
                      label="更新时间"
                      width="150">
                    </el-table-column>
                  </el-table>
              </div>
              <div class="uploadDialogtf" style="margin-top:20px;">
                <el-button type="primary" class="newDialog" @click="taskDialogIs = false">确 定</el-button>
              </div>   
            </el-dialog>
            <!-- 结果 -->
            <el-dialog 
              title="结果详情" 
              width="500px"
              center
              append-to-body
              :destroy-on-close="true"
              :show-close="false"
              :close-on-click-modal="false"
              :visible.sync="resultDialogIs">
              <div class="dialogIcon"><img :src='biaoQianIcon'></div>
              <div class="resultDialog">
                {{resultText}}
              </div>
              <div class="uploadDialogtf" style="margin-top:20px;">
                <el-button type="primary" class="newDialog" @click="resultDialogIs = false">确 定</el-button>
              </div>   
            </el-dialog>
            <!-- ownerIds -->
            <el-dialog 
              title="广告主IDs" 
              width="500px"
              center
              append-to-body
              :destroy-on-close="true"
              :show-close="false"
              :close-on-click-modal="false"
              :visible.sync="ownerIdsDialogIs">
              <div class="dialogIcon"><img :src='biaoQianIcon'></div>
              <div class="resultDialog">
                {{ownerIdsList}}
              </div>
              <div class="uploadDialogtf" style="margin-top:20px;">
                <el-button type="primary" class="newDialog" @click="ownerIdsDialogIs = false">确 定</el-button>
              </div>   
            </el-dialog>
          </div>
       </template>
      <template slot="headerGroup">
      </template>
    </PocCardTable>
      <!-- 预警设置弹框 -->
      <earlyWarningMiDialog :visible.sync="yjszShowDialog" ref = 'yjszDialog' :userContent = "userContent" :ownerIdList = 'ownerIdList' :pubVue = 'pubVue'></earlyWarningMiDialog>
      <warningMiDialog :visible.sync="showDialogIs" ref = 'earlyWarningDialogIs' :pubVue = "pubVue" :nowIs = "nowIs" :userContent = 'userContent' :ownerIdList = 'ownerIdList'></warningMiDialog>
      <costSetMiDialog :visible.sync="costSetDialogIs" ref = 'costSetDialogIs' :yulanList = "yulanList" :userContent = 'userContent' :planType = "planType" :pubVue="pubVue"></costSetMiDialog>
      <startLimitSingleMiDialogNew :visible.sync="showSingleDialog" :yulanList = "yulanList" :userContent = 'userContent' :nowIs = "nowIs" :dayType = "dayType" :page ="page" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitSingleMiDialogNew>
      <startLimitPriceMiDialog :visible.sync="showPriceSingleDialog" :yulanList = "yulanList" :userContent = 'userContent' :nowIs = "nowIs" :dayType = "dayType" :page ="page" :dialogTitle="dialogTitle" :pubVue="pubVue"></startLimitPriceMiDialog>
      <startLimitOcpxPriceMiDialog :visible.sync="showOcpxPriceSingleDialog" :yulanList = "yulanList" :userContent = 'userContent' :page ="page" :pubVue="pubVue"></startLimitOcpxPriceMiDialog>
      
   
  </div>
</template>

<script>
import biaoQianIcon from '@/assets/img/biaoQianIcon.png';
import Api from "@/api";
import config from '@/api/config';
import Qs from 'qs';
import { $http } from '@/api/http';
import earlyWarningMiDialog from '@/views/dialog/earlyWarningMiDialog'//预警设置弹框
import warningMiDialog from '@/views/dialog/warningMiDialog'//账户余额、日预算预警设置
import costSetMiDialog from '@/views/dialog/costSetMiDialog'//账户消耗预警设置
import startLimitSingleMiDialogNew from '@/views/dialog/startLimitSingleMiDialogNew'//批量设置日预算
import startLimitPriceMiDialog from '@/views/dialog/startLimitPriceMiDialog'//批量修改创意出价
import startLimitOcpxPriceMiDialog from '@/views/dialog/startLimitOcpxPriceMiDialog'//批量修改创意出价
import uploadDialog from '@/views/dialog/uploadDialog'
import accountSelectionVue from './accountSelection.vue';

export default {
  components: {
    earlyWarningMiDialog,
    warningMiDialog,
    costSetMiDialog,
    startLimitSingleMiDialogNew,
    startLimitPriceMiDialog,
    startLimitOcpxPriceMiDialog
  },
  data() {
    return {
      biaoQianIcon,
      tokenTypeIs:null,//业绩类：1；服务类：2服务类隐藏计划、调价、启停按钮
      tokenTypeList:[],//判断是否有服务类
      pubVue:this,
      showSingleDialog:false,
      showPriceSingleDialog:false,//批量修改创意出价-弹窗
      showOcpxPriceSingleDialog:false,//批量修改自动优化广告出价
      uploadDialogIs:false,//批量上传弹框
      yjszShowDialog: false,//预警设置弹框
      showDialogIs: false,//提示已经设置过的弹框
      costSetDialogIs:false,//消耗设置弹框
      nowIs:false,
      userContent:{},//登录用户信息&弹框默认
      params:{},//入参
      qdoptions:{
        channelList:[{text:'oppo',id:'3'}],
        userStatuList:[{text:'全部状态',id:'0'},{text:'未设置',id:'1'},{text:'预警已关闭',id:'2'},{text:'预警开启中',id:'3'}]//选择状态
      },
      titleList:[
        // {name:'渠道名称',prop:'channelId'},
        // {name:'公司名称',prop:'accountName'},
        {name:'广告主ID',prop:'advertiserId'},
        // {name:'用户名',prop:'cname'},
        {name:'账户类别',prop:'tokenTypeName'},
        {name:'账户余额',prop:'cashBal'},
        {name:'今日消耗',prop:'cashCost'},
        {name:'账户日预算',prop:'cashDayBudget'},
        {name:'账户预警',prop:'type'}
      ],//title名称
      channel:'',
      channeln:'',
      customerName:'',//搜索框--客户名称
      tableData:[],//查询有权限的所有帐号接口回显
      // tableData:[{
      //   "advertiserId":"24152",
      //   "accountName":"北京一亩田新农网络科技有限公司",
      //   "tokenType":"1",
      //   "cashBal":"0",
      //   "cashCost":"0",
      //   "cashDayBudget":"30000",
      //   "channelId":5,
      //   "type":0
      // },{
      //   "advertiserId":"79502",
      //   "accountName":"广州朱雀信息科技有限公司（一件）",
      //   "tokenType":"1",
      //   "cashBal":"0",
      //   "cashCost":"0",
      //   "cashDayBudget":"30000",
      //   "channelId":5,
      //   "type":0
      // }],//查询有权限的所有帐号接口回显
      show:false,
      uploadOptions: [{
        value: '10',
        label: ' 广告计划+广告组+广告创意'
      },
      {
        value: '20',
        label: '广告组+广告创意'
      },
      {
        value: '30',
        label: '广告创意'
      } ], //批量上传
      uploadValue:'30',//批量上传绑定值
      // 分页
      page: 1,//当前页
      pageCount: 10,//每页大小
      totalRow: 0,//总条数
      userId:'',//用户ID
      userName:'',
      deptId:'',
      ownerId:'',
      ownerIdList:[],//选中的用户
      disabledIs: false,//switch是否可操作
      operNum:0,
      yulanList:[],
      planType:10,
      dayType:1,//批量日限额:1->账户
      dialogTitle:'批量日限额',
      myHeaders: {Authorization:''},
      fileList:{},
      accountParams:{},
      tokenTypes:'',//查询条件：账户类型
      tokenTypeOptions:[{//账户类型列表
        value:0,
        label:'全部'
      },{
        value:1,
        label:'业绩类'
      },{
        value:2,
        label:'服务类'
      }],
      tokenTypeisOne:2,//业绩类
      storeTokenType:[],
      accountUserName:'',//列表搜索中的用户名
      accountSelectionListShow:true,//用户是否有权限
      channelTitleList:[],//权限名称列表
      channelIdNew:'',//用户权限ID:3=>OPPO;4=>VIVO;5=>小米
      taskDialogIs:false,
      taskTableData:[],
      resultDialogIs:false,
      resultText:'',
      ownerIdsDialogIs:false,
      ownerIdsList:'',
      screenHeight: window.innerHeight - 600,
    }
  },
  computed: {
     
  },
  mounted() {
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.userContent.userId = this.userId
    this.userContent.userName = this.userName
    this.userContent.deptId = this.deptId
    this.getId()//获取token
    this.getuserChannel()//获取用户的权限
  },
  methods: {
    //channel的tab切换=>3:OPPO;4:VIVO;5:小米
    clickChangeChannel(channelId){
      let channelNameList = {3:'planManagement.AccountSelection',4:'VivoAccountManagement.AccountSelectionVivo',6:'HwAccountManagement.AccountSelectionHw',7:'YybAccountManagement.AccountSelectionYyb',8:'planManagement.AccountSelectionApple'}
      this.$router.push({name:channelNameList[channelId]})
    },
    getId(){//获取token
      this.myHeaders.Authorization = 'Bearer '+this.$sessionStorage.getItem(config.USER_TOKEN_KEY)
      this.uploadId = this.$route.params && this.$route.params.id
    },
    //重置
    reset(){
      this.$router.go(0)
    },

     //获取用户的权限
    getuserChannel(){
      let that = this
      $http.axios.get("/api/system/user/getChannelByUser?userId="+that.userId).then(function(response) {
        if(response.data.data!=undefined&&response.data.data.length!=0){
          that.channelTitleList = response.data.data
          that.accountSelectionListShow = true
          that.channelIdNew = response.data.data[0].id

          that.miAccountList()
        }else{
          that.accountSelectionListShow = false
          that.$message({
          showClose: true,
          message: "您的操作权限不足",
          type: 'error'
        })
        }
      })
    },

    //文件上传成功时的钩子
    onSuccess(response, file, fileList, i) {
      let that = this,dataSource =[],keywords = []
      console.log(response)
      if(response.code==200){
        that.miAccountList()
        that.uploadDialogIs = false
        that.$message({
          showClose: true,
          message: "提示上传成功，请稍后移步【上传记录】查看上传结果",
          type: 'success'
        })
      }else{
        that.$message({
          showClose: true,
          message: response.msg,
          type: 'error'
        })
      }
      this.$refs.upload.clearFiles()
    },

    uploadFn(){
      console.log(this.ownerId)
      this.fileList={
        "ownerId":this.ownerId,
        "opertaType":this.uploadValue,
        "userId":this.userId,
        "userName":this.userName,
        "deptId":this.deptId
      }
    },

    //获取查询有权限的所有帐号接口
    miAccountList(pageNum,channelId,type,name){
      let _this = this,pageSize
      _this.accountParams={
        type : type|| 0,
        name : name || '',
        tokenType : String(_this.tokenTypes),
        accId : _this.accountUserName
      }
      channelId = channelId||5
      pageNum = pageNum|| 1
      pageSize= _this.pageCount,
      $http.axios.post("/api/xmat/account/accountCostList?pageSize="+pageSize+'&pageNum='+pageNum,_this.accountParams).then(res=> {
        if(res.data.code==200){
          // res.data.rows = [{
          //   "advertiserId":"24152",
          //   "accountName":"北京一亩田新农网络科技有限公司",
          //   "tokenType":"1",
          //   "cashBal":"0",
          //   "cashCost":"0",
          //   "cashDayBudget":"30000",
          //   "channelId":5,
          //   "type":0
          // },{
          //   "advertiserId":"79502",
          //   "accountName":"广州朱雀信息科技有限公司（一件）",
          //   "tokenType":"1",
          //   "cashBal":"0",
          //   "cashCost":"0",
          //   "cashDayBudget":"30000",
          //   "channelId":5,
          //   "type":0
          // }]

          // 数据修改，余额、消耗、日预算初始值如果为空的状态
          res.data.rows.forEach(element => {
            element.channelId = '小米'
            if(element.cashBal  == null){
              element.cashBal  = 0.00
            }else{
              element.cashBal = Number(element.cashBal).toFixed(2)
            }
            if(element.cashCost== null){
              element.cashCost = 0.00
            }else{
              element.cashCost = Number(element.cashCost).toFixed(2)
            }
            if(element.cashDayBudget== null||element.cashDayBudget== 100000000.00001){
              element.cashDayBudget = 0.00
            }else{
              element.cashDayBudget = Number(element.cashDayBudget).toFixed(2)
            }
            if(element.type == 0||element.type == 1){
              element.disabledIs = true
            }else{
              element.disabledIs = false
            }
            if(element.tokenType == '0'){//业绩类：1；服务类：2服务类隐藏计划、调价、启停按钮
              element.tokenTypeName = "全部"
            }else if(element.tokenType == '1'){
              element.tokenTypeName = "业绩类"
            }else if(element.tokenType == '2'){
              element.tokenTypeName = "服务类"
            }
            if(element.channelId == null){
              _this.titleList.forEach(function(e,i){
              })
            }
          });
          _this.tableData = res.data.rows
          _this.totalRow = res.data.total
          _this.storeTokenType = []
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent =error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },

    // 选择账户状态
    userStatusFn(){
      let _this = this
      _this.miAccountList(1,_this.channel,_this.channeln,_this.customerName,)
    },
    
    // 预警设置（判断是否有设置&无设置直接显示设置弹框，并加载部门名称列表/有设置罗列已设置的相，并提醒用户是否要设置=>取消关闭弹框||确认关闭确认弹框，开启预警设置弹框）
    warmSet(){
      let _this = this
      let ownerIdList = []
       _this.ownerIdList.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          ownerIdList.push(v.ownerId)
        }
      })
      _this.storeTokenType = []
      _this.tokenTypeList.forEach(obj=>{
        if(obj == '2'){
          _this.storeTokenType.push(obj)
        }
      })
      if(_this.ownerIdList.length!=0&&_this.storeTokenType.length==0){
        _this.warmIs('warm',ownerIdList)
      }else if(_this.ownerIdList.length==0){
        _this.$message({
            showClose: true,
            message: "请勾选数据",
            type: 'error'
          })
        }else if(_this.storeTokenType.length!=0){
          _this.$message({
            showClose: true,
            message: "【服务类客户暂时不支持此功能】",
            type: 'error'
          })
        }
      },

     // 批量启停设置
    updateOppoSet(type){
      let _this = this
      let ownerIdList = []
      _this.ownerIdList.forEach(function(v,i,arr){
        let bool = arr.indexOf(v,i+1)
        if(bool === -1){
          ownerIdList.push(v.ownerId)
        }
      })
      _this.storeTokenType = []
       _this.tokenTypeList.forEach(obj=>{
        if(obj == '2'){
          _this.storeTokenType.push(obj)
        }
      })
      _this.showSingleDialog = true
      console.log(_this.storeTokenType)
      if(_this.ownerIdList.length!=0&& _this.storeTokenType.length==0){
        _this.params.ownerIdList = ownerIdList
        return true
      }
      else if(_this.ownerIdList.length==0){
        _this.showSingleDialog = true
        // _this.$message({
        //   showClose: true,
        //   message: "请勾选数据",
        //   type: 'error'
        // })
      }
      else if( _this.storeTokenType.length!=0){
        _this.showSingleDialog = true
        // _this.$message({
        //   showClose: true,
        //   message: "【服务类客户暂时不支持此功能】",
        //   type: 'error'
        // })
      }  
      
    },
   
    dayLimitPrice(){
      console.log(this.ownerIdList)
      this.showPriceSingleDialog = true
      // if(this.ownerIdList.length != 0){
      //   this.showPriceSingleDialog = true
      // }else{
      //   this.$message({
      //     showClose: true,
      //     message: "请勾选需要修改的账户",
      //     type: 'warning'
      //   })
      // }
      
    },
    //单个或批量验证是否设置过定时调价计划planIdList:[] 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmListIs(type,url){//type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      console.log(url)
      console.log(type)
      let _this = this
      let isTrue = _this.updateOppoSet(type)
      _this.planStatus = false
      if(isTrue){
          _this.yjCheck(type,url)     
      }
    },

    yjCheck(type,url,adPlanId){
      let _this = this,messageContent,idList = [],params 
      if(type == 'dayLimit'){
        console.log(111)
        _this.ownerIdList.forEach(obj=>{
          idList.push(obj.ownerId)
        })
        params = {
          'ids':idList.toString(),
          'type':1//类型（1-账户日预算 2-计划日预算 3-广告出价）
        } 
      }
      // else if(type=='dayLimitPrice'){
      //   console.log(111)
      // }
      else{
      _this.ownerIdList.forEach(obj=>{
        params.push(obj)
      })
    }

    $http.axios.post("/api/mi/"+url,Qs.stringify(params),{headers:{'Content-Type':'application/x-www-form-urlencoded'}}).then(res=> {
      let content = []
      if(res.data.code==200){
        content = res.data.data
        if(content == null){
          if(type == 'dayLimit'){
            this.showSingleDialog = true
            this.nowIs = false
          }
        }else{
          if(type == 'dayLimit'){
            let text = content.toString()+'账户已经设置过调价执行计划是否确认添加新计划'
            this.$confirm(text,"提示",{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(()=>{
              this.showSingleDialog = true
              this.nowIs = true
            }).catch(()=>{
              })
            }
          }
        }else{
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }).catch(error=>{
          let messageContent = error.message || '请查看网络，稍后再试'
          _this.$message({
            showClose: true,
            message: messageContent,
            type: 'error'
          })        
        }) 
      },

    // 预警是否开启  预警人部门id(departmentId)、预警人部门名称(departmentName)、部门人员id(userId)、抄送人ids(userIds)、预警开关(warningSwitch)
    warmIs(type,ownerIdList,setType){ //type:'warm':预警设置校验、'set':消耗、余额、日预算预警设置校验';setType消耗、余额、日预算的状态值
      let _this = this
      console.log(setType)
      $http.axios.post("/api/mi/accountWarning/checkAccountWarning",ownerIdList).then(res=> {
        let content = []
        if(res.data.code==200){
          content = res.data.data
          if(type=='warm'){
            if(content == null){
            // _this.showDialog = true
            _this.yjszShowDialog = true//预警设置弹框是否显示
            _this.$refs.yjszDialog.getDeptTree(_this.channelIdNew)//预警设置弹框获取部门名称
            _this.$refs.yjszDialog.getUserList()//预警设置弹框获取人员名称

            }else{
              let messageContent = '已经设置过预警的用户无法多选账户设置预警；以下为已设置过的数据'+content.toString()
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }
          }else if(type=='set'){
            if(content == null){
              let messageContent = '您的账户未设置抄送人，请先设置抄送人'
              _this.$message({
                showClose: true,
                message: messageContent,
                type: 'error'
              })
            }else{
              if(setType == 1){
                _this.costSetDialogIs = true
                _this.$refs.costSetDialogIs.setDialogContentFn(_this.userContent.ownerId,setType)              
              }else{
                _this.showDialogIs = true
                _this.$refs.earlyWarningDialogIs.setDialogContentFn(_this.userContent.ownerId,setType)              
              }
            }
          }
        }else{
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })
    },
    
    tableCellClassName({row, column, rowIndex, columnIndex}){
      //注意这里是解构
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index=rowIndex;
      column.index=columnIndex;
    },
    
    // 点击一个单元格 账户余额(cashBal)0、今日消耗(cashCost)1、计划日预算(cashDayBudget)2
    clickcell(row, column, cell, event){
      let _this = this,setType,num = event.target.parentNode.dataset.num//偶尔获取到undefined
      let type = event.target.parentNode.dataset.type
      console.log(column.property)
      console.log(setType)
      console.log(type)
      if(column.property=='cashBal'||column.property=='cashCost'||column.property=='cashDayBudget'){
        if(column.property=='cashBal'){
          setType = 0
        }else if(column.property=='cashCost'){
          setType = 1
        }else{
          setType = 2
        }
        _this.userContent.ownerId = row.advertiserId//账户ID/广告主ID
        _this.userContent.ownerName = row.accountName
        _this.userContent.type = setType
        console.log(_this.userContent.type)
        console.log(setType)
        _this.ownerIdList = [] 
        _this.ownerIdList.push({ 'ownerId':row.advertiserId,'ownerName':row.accountName})
        _this.warmIs('set',[row.advertiserId],setType)
      }else if(column.label == '操作'){
        if(type =='uploadHistoty'){
          _this.goPlan('/MiAccount-management/uploadPageMi',row.advertiserId,row.accountName)
        }else if(type =='uploadButton'){
          _this.ownerId = row.advertiserId
          // _this.uploadFn(_this.ownerId)//获取ownerId
          _this.uploadFn()
        }else if(type == 'planPage'){
          _this.goPlan('/MiAccount-management/adPlanMi',row.advertiserId,row.accountName)
        }else if(type =='tiao'){
          _this.goPlan('/MiAccount-management/PriceModifyMi',row.advertiserId,row.accountName)
        }else if(type == 'startStop'){
          _this.goPlan('/MiAccount-management/StartStopMi',row.advertiserId,row.accountName)
        }
      }
      console.log(column.property,num)
      if(event.target.parentNode.dataset.type=="selection"){//event.target.parentNode.dataset.type
          this.$refs.multipleTable.toggleRowSelection(row)
      }
      if(row.disabledIs && column.property == 'type'){
        let messageContent = '您的账户未设置抄送人，请先设置抄送人'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })
      }
    }, 
    // 分页
    handleSizeChange(val) {
      let _this = this
      console.log(666)
      _this.pageCount = val
      _this.findAll(_this.page)
    },
    handleCurrentChange(val) {
      this.page = val
      this.findAll(val)
    },
    //分页方法
    findAll (val) {
      this.page = val
      //获取数据接口（调用封装的接口）
      this.miAccountList(val,3,0)
    },
    //选择行
    handleSelectionChange(val){
      let _this = this
      let ownerIdList = [], yulanList = []
      _this.tokenTypeList = []
      val.forEach(obj=>{
        ownerIdList.push({ 'ownerId':obj.advertiserId,'ownerName':obj.accountName})
        _this.tokenTypeList.push(obj.tokenType)
        yulanList.push({ 'ownerId':obj.advertiserId,'ownerName':obj.accountName,'price':obj.cashDayBudget,'upPriceStatus':0})
      })
      _this.ownerIdList = ownerIdList
      _this.yulanList = yulanList
    },
    
    //按钮开关
    getBrandList(row){
      console.log(row)
      let params = {ownerId:row.advertiserId,totalSwitch:row.type,userId:this.userId,userName:this.userName,deptId:this.deptId} 
      this.updateAccountSwitchFn(params)       
    },
    
    // 账户列表页账户预警开关修改
    updateAccountSwitchFn(params){
      let _this = this
      $http.axios.post("/api/mi/accountWarning/updateAccountSwitch",params).then(res=> {
        if(res.data.code == 200){

        }
      }).catch(error=>{
        let messageContent = error.message || '请查看网络，稍后再试'
        _this.$message({
          showClose: true,
          message: messageContent,
          type: 'error'
        })        
      })

    },  
    
    // 计划、调价、启停页面跳转
    goPlan(url,id,name){//
      this.$router.push({
        path: url,
        query: {
          id: id,
          name:name,
        }
      })
    },
    setRowStyle(row, column, rowIndex, columnIndex) {
      if(row.columnIndex == 4){
        return 'color: #6495ed' 
      }else if(row.columnIndex == 5){
        return 'color: #6495ed' 
      }else if(row.columnIndex == 6){
        return 'color: #6495ed' 
      }else{
        return 'color: #606266' 
      }
    },

    cyOne(){
      // window.location.href="https://xiaomi-ad.oss-cn-beijing.aliyuncs.com/template3-1/ad_template.xlsx"//jiu
      window.location.href="https://xiaomi-ad.oss-cn-beijing.aliyuncs.com/template/template4-11/ad_template.xlsx"//新
    },
    cyTwo(){
      // window.location.href="https://xiaomi-ad.oss-cn-beijing.aliyuncs.com/template3-1/group_ad_template.xlsx"//jiu
      window.location.href="https://xiaomi-ad.oss-cn-beijing.aliyuncs.com/template/template4-11/group_ad_template.xlsx"//新
    },
    cyThree(){
      // window.location.href="https://xiaomi-ad.oss-cn-beijing.aliyuncs.com/template3-1/plan_group_ad_template.xlsx"//jiu
      window.location.href="https://xiaomi-ad.oss-cn-beijing.aliyuncs.com/template/template4-11/plan_group_ad_template.xlsx"//新
    },

    //任务列表
    taskFrame(val){
      this.taskDialogIs = true
      console.log(val)
      this.taskList(val)
    },
    //任务列表展示
    taskList(type){
      console.log(type)
      let _this = this
      $http.axios.get("/api/mi/modifyPrice/quickModifyPriceLog?type="+type).then(res=> {
        if(res.data.code == 200){
          if(res.data.data.infos.length != 0){
            console.log(res)
            _this.taskTableData = res.data.data.infos
          }
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    clickResult(val){
      this.resultDialogIs = true
      this.resultText = val
    },

    clickownerIds(val){
      this.ownerIdsDialogIs = true
      this.ownerIdsList = val
      // this.ownerIdsList = '79502,371172,394702,513592'
    },

    //批量修改自动优化广告出价
    bacthEditOcpxPrice(){
      this.showOcpxPriceSingleDialog = true
    },




  }
}
</script>

<style lang="scss" scoped>
  
  /* 开关开始 */
  .el-switch__label{
    color:#fff !important;
    position: absolute;
  }
  .switchStyle .el-switch__label {
    position: absolute;
    display: none;
    color: #fff;
  }
  .switchStyle .el-switch__label--left {
    z-index: 9;
    left: 6px;
  }
  .switchStyle .el-switch__label--right {
    z-index: 9;
    left: -14px;
  }
  .switchStyle .el-switch__label.is-active {
    display: block;
  }
  .switchStyle.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 50px !important;
  }
   /* 开关结束 */

   /* 批量操作开始 */
  .allOperation{
    margin-top: 10px;
  }
  .allOperation .totalNum{
      margin-right: 15px;
      font-size: 13px;
      color: #606266;
    }
    .allOperation .totalNum i{
        font-style: initial;
      }
  .footerPaging{
    margin-top: 10px;
  }
  /* 批量操作结束 */
  /* 操作按钮样式开始 */
  /* 操作按钮样式结束 */
   #uploadBtn{
    display: inline-block;
  }
  .el-button.el-button--primary{
    margin: 0 10px 0 0!important;
  }
  .el-button--small{
    margin-right: 10px;
  }
  /* oppo和vivo的tab切换 */
  .chanelTab{
    margin-bottom:20px;
    width:100%;
    border-bottom:1px solid #ddd;
  }
  .channel_mi{
    display:inline-block;
    vertical-align:middle;
    width:80px;
    height:50px;
    line-height:50px;
    text-align:center;
    margin-bottom:-1px; 
    cursor: pointer;
  }
  .channel_mi{
    margin-left:-1px;
  }
  .channelActive{
    font-weight:700;
    color:#48916F;
    border-bottom:2px solid #48916F;
  }
  .showOrNo{
    display: inline-block;
  }
  .statusOtherBtnwei{
    padding:3px 8px;
    background:rgba(230,162,60,.1);
    color:#E6A23C;
  }
  .closeStatus,.openStatus{
    display:inline-block;
    margin-left:8px;
    width:8px;
    height:8px;
    border-radius:8px;
    background-color:#ccc;
  }
  .openStatus{
    background-color:#67c23a;
  }
  .setWarnning{
    background:#FFF5EC;
    color:#F3920B;
    border:1px solid #F3920B;
    border-radius:6px;
  }
  .setWarnning:hover{
    background:#F3920B;
    color:#FFF5EC;
    border:1px solid #F3920B;
  }
   /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  .resultDialog{
    width:100%;
    min-height:120px;
    max-height:300px;
    overflow: scroll;
    padding:10px;
    box-sizing: border-box;
  }
</style>