import { render, staticRenderFns } from "./zdyVivo.vue?vue&type=template&id=a21a0a3c&scoped=true&"
import script from "./zdyVivo.vue?vue&type=script&lang=js&"
export * from "./zdyVivo.vue?vue&type=script&lang=js&"
import style0 from "./zdyVivo.vue?vue&type=style&index=0&id=a21a0a3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a21a0a3c",
  null
  
)

export default component.exports