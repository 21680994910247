<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    :title="title+'模板详情'"
  >
    <div class="authority common-page">
    <ul>
      <li>
        <span>模板名称:</span> <span>{{fileList.name}}</span>
      </li>
      <!-- <li>
        <span>广告主名称:</span> <span>{{fileList.account}}</span>
      </li> -->
      <li>
        <span>报表名称:</span> <span>{{fileList.title}}</span>
      </li>
      <li>
        <span>报表维度:</span> <span>{{adTypeList[fileList.adType]}}</span>
      </li>
      <li>
        <div>数据源:</div>
        <ul v-if="fileList.excelConfig!=undefined||fileList.excelConfig.dataSource!=undefined">
          <li v-for="(item,i) in fileList.excelConfig.dataSource" :key="i">
            <span>{{item.sheetName}}:</span><span v-for="(ele,j) in item.owners" :key="j">{{ele.ownerId}}({{ele.appId.toString()}})
            <div>广告主名称: {{ele.ownerName}}</div>
            </span>
          </li>
        </ul>
      </li>
      <li>
        <div>关键词:</div>
        <ul>
           <li v-for="(item,i) in fileList.excelConfig.keyword" :key="i">
            <span>{{item.sheetName}}:</span><span v-for="(ele,j) in item.owners" :key="j">{{ele.ownerId}}({{ele.appId.toString()}})</span>
            <span>广告主名称:</span> <span>{{ele.ownerName}}</span>
          </li>
        </ul>
      </li>
      <li>
        <span>接收人:</span> <span>{{fileList.senderName}}</span>
      </li>
      <li>
        <span>抄送人:</span> <span>{{fileList.copySenderName}}</span>
      </li>
      <li>
        <span>接收方式:</span> <span>{{fileList.senderF}}</span>
      </li>
      <li v-if="type == 2">
        <span>接收时间:</span> <span>{{fileList.time}}</span>
      </li>
      <li  v-if="type == 2"> 
        <span>是否拉取零时数据:</span> <span>{{needList[fileList.zeroFlag]}}</span>
      </li>
    </ul>
    
  </div>
    
  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  
 
  props: {
    title:String,
    type:Number,
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      adTypeList:{1:'广告计划',2:'广告组',3:'广告'},
      needList:['否','是'],
      fileList:{
        // accout:"LX_Test_10006(1100018015)",
        // adType:10,
        // channelId:3,
        // copyUser:"",
        // copySenderName:"",
        // deptId:270,
        excelConfig:{"dataSource":{"数据源":[1100018015],"数据源 (2)":[1100018015]}},
        // excelFlag:2,
        // id:40,
        // imgFlag:1,
        // zeroFlag:2,
        // path:"/home/hljy/op/report/sourceModel/76cb3b23-4de6-49cb-bd00-1e943f74d9cd.xlsx",
        // reportName:"人",
        // reportNum:"H1639470406778",
        // reportSwitch:2,
        // sender:"315",
        // senderName:"OPPO-开发组-测试优化师01-315",
        // status:2,
        // time:["10.5"],
        // title:"{渠道}{时段}",
        // type:2,
        // userId:315,
      },
      tableData:[],
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
console.log(555)
    
  },
  methods: {
    //详情
    getInfoFn(id){
      let _this = this,time = []
      console.log(333)
      $http.axios.get("/api/hwReport/reportModel/queryById?id="+id).then(function(res) {
        
        if(res.data.code == 200){
          _this.fileList = res.data.data
          _this.fileList.excelConfig.dataSource = res.data.data.excelConfig.dataSource
          _this.fileList.excelConfig.keyword = res.data.data.excelConfig.keyword
          _this.fileList.time = res.data.data.time
          if(res.data.data.imgFlag == 1){
            _this.fileList.senderF = "图片"
          }
          if(res.data.data.excelFlag == 1){
            _this.fileList.senderF = "Excel"
          }
          if(res.data.data.excelFlag == 1&& res.data.data.imgFlag == 1){
            _this.fileList.senderF = "Excel、图片"
          }
          if(_this.type == 2&&_this.fileList.time != null){
            _this.fileList.time.split(",").forEach(obj=>{
              if(obj%1 === 0){
                time.push(obj+':00')
              }else{
                time.push(Math.floor(obj)+':30')
              }
            })
            _this.fileList.time = time.toString()
          }
        }
        
      })
    },
    onCancel() {
      this.show= false;
    },
    
    
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
.addWord{
  cursor: pointer;
  display: inline-block;
  margin: 5px 8px;
  font-size: 12px;
  color: #ccc;
}
</style>

