<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
        <div class="roleHeader">
          <span class="role_headerTitle">
            <span class="role_headerChecked" @click="goToTitle">角色职位</span>
            <span @click="goToRole">职位名称</span>
          </span>
          <el-select v-model="roleSelectValue" filterable placeholder="请选择角色职位" class="roleSelect" @change="changeRoleSelect($event)">
            <el-option
              v-for="(item,i) in roleSelectOptions"
              :value="item"
              :key="i"
              :label="item.roleName">
              <!-- {{ item.roleName }} -->
            </el-option>
          </el-select>
          <el-button type="primary" size="small" @click="getRoleList" style="margin-left:20px;">查询</el-button>
          <el-button type="primary" size="small" @click="addRolesName" class="add_roleBtn">添加角色</el-button>
            <el-dialog title="添加角色" :visible.sync="dialogFormVisible">
              <el-form :model="addRoleForm">
                <el-form-item label="渠道名称" :label-width="formLabelWidth">
                  <el-select v-model="addqdvalue" placeholder="请选择渠道名称">
                    <el-option
                      v-for="(item,i) in addqdoptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色名称" :label-width="formLabelWidth">
                  <el-input v-model="params.roleName" autocomplete="off" placeholder="请输入角色名称"></el-input>
                </el-form-item>
              </el-form>
              <template #footer>
                <span class="dialog-footer">
                  <el-button @click="dialogFormVisible = false">取 消</el-button>
                  <el-button type="primary" @click="addRoleName">确 定</el-button>
                </span>
              </template>
            </el-dialog>
        </div>
        <div class="roleTable">
          <el-table
            ref="multipleTable"
            :data="role_tableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{textAlign: 'center'}"
            :cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              type="selection"
              width="100">
            </el-table-column>
            <el-table-column
              prop="rolename"
              label="角色名称"
              width="200">
              <template slot-scope="scope">{{scope.row.roleName}}</template>
            </el-table-column>
            <el-table-column
              prop="jurisdiction"
              label="角色权限"
              show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-for="val in scope.row.menuList" style="display:inline-block;vertical-align:middle;margin:0 5px;">
                  {{val.name}}
                </span>
              </template>
            </el-table-column>
            <el-table-column

              label="操作"
              width="200">
              <template slot-scope="scope">
              <div class="roleOperation">
                <div class="tabBtns roleOperationBtn">
                  <span>
                    <el-button class="newBtn_garyLightBlue" plain size="mini" @click="getMenuLists(scope.row.roleId)" v-if="scope.row.menuIds==null" >设置</el-button>
                    <el-button class="newBtn_garyLightBlue" plain size="mini" @click="getMenuListsHx(scope.row.roleId)" v-else >设置</el-button>
                  </span>
                  <el-button @click="queryRoleName(scope.row.roleId)" class="newBtn_lightYellow" plain size="mini">编辑</el-button>
                  <el-popconfirm
                    title="您是否确认删除，删除后不可恢复？"
                    @confirm="deleteRole(scope.row.roleId)"
                  >
                  <template #reference>
                    <el-button class="newBtn_orang" plain size="mini">删除</el-button>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </template>
            </el-table-column>
          </el-table>
          <el-dialog
            title="权限设置"
            :visible.sync="dialogVisible"
            width="70%">
            <div class="dialog_table">
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="">
                  <el-select v-model="form.region" placeholder="请选择渠道名称">
                    <el-option
                      v-for="(item,i) in qdoptions"
                      :key="i"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <div class="dialog_tableCheckbox">
                    <!-- :default-checked-keys="[5]" -->
                    <!-- :default-expanded-keys="[1]" -->
                    <el-tree
                      :data="indexManageOptions"
                      show-checkbox
                      node-key="id"
                      default-expand-all
                      :default-checked-keys="checkedKeys"
                      ref="tree"
                      :props="defaultProps"
                      @check="treeChecked">
                    </el-tree>
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div class="dialog_foot">
              <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="sureDialogVisible">确 定</el-button>
              </span>
            </div>
          </el-dialog>
          <div class="footer_paging">
            <div class="footer_totalNum">
              <!-- <span class="footer_totalNums">共{{footerTotalNum}}条</span> -->
              <!-- <el-popconfirm
                title="确定全部删除吗？"
              >
              <template #reference>
                <el-button>全部删除</el-button>
                </template>
              </el-popconfirm> -->
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
          </div>
        </div>
       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
  </div>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
// import Children from "./operateManage/checkedSon";

export default {
  name: 'RoleManage',
  // components: {
  //   Children:Children
  // },
  data() {
    return {
      productName: '',
      pageConfig: {
        pageNum: 1,
        total: 20
      },
      dialogFormVisible: false,
      dialogFormVisible01: false,
      dialogVisible: false,
      // xgdialogVisible:false, // 修改角色名称dialog
      // xgroleName:'', //修改名称value
      queryName:false,// 判断是修改还是新增
      addRoleForm:{},
      addqdvalue:'',
      form:{
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      titleform: {
        name: '111',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px',
      loading: false,
      showDialog: false,
      activeName_hrader: 'role',
      tableData:[{
        no: 1,
        accountName: '钱币11111',
        brand: '通用',
        Email: 'A',
        accountStatus:'Normal'
      }],
      roleSelectOptions: [],
      roleSelectId:'',
      roleSelectValue: '', //选择角色职位下拉框
      rolesId:'',
      titleValue: '',
      // 获取角色列表
      role_tableData: [],
      params:{//新增角色
        roleName:'',
        channel:''
      },
      deleteParams:{//删除角色
        roleIds:''
      },
      multipleSelection: [],
      checkAll: false,
      checkedmanage01: [],
      // checkedmanage_pages: [],
      // indexManages: indexManageOptions,
      // indexManage_pages: indexManage_pageOptions,
      isIndeterminate: false,
      footerTotalNum: 100, //底部条数
      // 分页
      pageNum: 1,//当前页
      pageSize: 10,//每页大小
      totalRow: 0,//总条数
      totalPage: 0,//总页数
      xgRolesNameParams:{
        roleName:'',
        roleId:'',
        channel:''
      },
      roleParams:{
        roleName:''
      },
      qdoptions:[],
      addqdoptions:[],
      // jurisdictionParams:{//角色设置权限

      // },
      // checkList: ['复选框 A'],
      checkList:[],
      indexManageOptions:[],
      // sureDialogParams:{
      //   roleId:''
      // },
      checkedMenuIds:'',
      checkedId:[],
      checkParentIdArr:[],
      idArr:[],
      defaultProps: {//树结构
        children: 'children',
        label: 'label'
      },
      roleIdShow:'',
      isCheckboxHx:false,
      checkedKeys:[],
      menuListShow:[],
      queryTopName:'',
      queryTopId:'',








    };
  },
  computed: {
     
  },
  mounted() {
    this.getRoleList(); // 获取角色列表
    this.getRoleselectList();//获取角色name下拉列表
    // this.getChinese(val)
    this.getChannelList()
  },
  // created(){
  //   var params = {
  //     pageNum: this.pageNum,
  //     pageSize: this.pageSize
  //   }
  //   this.findAll(params)
  // },
  methods: {

      // 获取角色列表
      getRoleList() {
        let that = this
        that.role_tableData = []
        
        $http.axios.get("/api/system/role/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum+"&roleId="+that.queryTopId+"&roleName="+that.queryTopName).then(response=> {
        // $http.axios.get("/api/system/role/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum).then(function(response) {
          if(response.data.code == 200){
            that.role_tableData = response.data.rows
            that.totalRow = response.data.total
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      //角色权限对应得中文
      // getChinese(val){
      //   let that = this
      //   $http.axios.get("/api/system/role/"+val).then(function(response) {//,that.jurisdictionParams
      //       if(response.data.code == 200){
      //         console.log(response)
      //       } else {
      //         that.$message({
      //           showClose: true,
      //           message: response.data.msg,
      //           type: 'error'
      //         })
      //       }
      //     })
      // },

      //获取角色下拉列表
      getRoleselectList(){
        let that = this
        $http.axios.get("/api/system/role/optionselect").then(function(response) {
          if(response.data.code == 200){
            that.roleSelectOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          } 
        })
      },
      //下拉搜索
      changeRoleSelect(event){
        this.queryTopId = event.roleId
        this.queryTopName = event.roleName
        this.roleSelectValue = event.roleName
      },

      //查询
      queryRole(){
        let that = this
        $http.axios.get("/api/channel/role/list?pageSize="+that.pageSize+"&pageNum="+that.pageNum+"&roleId="+that.queryTopId+"&roleName="+that.queryTopName).then(res=> {
          if(res.data.code == 200){
            that.role_tableData = []
            that.role_tableData = res.data.rows
            that.totalRow = res.data.total
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        })
      },

      //确认添加角色、修改角色名称
      addRoleName(){  
        let that = this
        // that.params.roleName = 
        that.params.channel = that.addqdvalue

        if(that.queryName == true){
          Api.user.roleAdd(that.params).then(res => {
            if(res.code == 200) {
              that.dialogFormVisible = false;
              that.getRoleList()
            }else{
                that.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              })
            }
          })
        } else {
          that.xgRolesNameParams.roleName = that.params.roleName
          that.xgRolesNameParams.channel = that.addqdvalue
          // that.xgRolesNameParams.roleId = that.params.roleId
          // that.xgRolesNameParams.menuIds = that.params.menuIds
          $http.axios.put("/api/channel/role",that.xgRolesNameParams).then(function(response) {
            if(response.data.code == 200) {
              that.dialogFormVisible = false;
              that.getRoleList()
            }else{
              that.$message({
                showClose: true,
                message: response.data.msg,
                type: 'error'
              })
            }
          })
        }
      },

      //新增角色点击事件
      addRolesName(){
        let that = this
        that.queryName = true
        that.addqdvalue = ''
        that.params.roleName = ''
        that.dialogFormVisible = true
      },

      //修改角色名称点击事件
      queryRoleName(a){
        let that = this
        that.queryName = false
        that.dialogFormVisible = true
        $http.axios.get("/api/channel/role/"+a).then(function(response) {
          if(response.data.code == 200) {
            that.xgRolesNameParams.roleId  = response.data.data.roleId
            that.xgRolesNameParams.channel  = response.data.data.channel
            that.params.roleName = response.data.data.roleName
            if(response.data.data.channel == 1){
              that.addqdvalue = '1'
            }else if(response.data.data.channel == 2){
              that.addqdvalue = '2'
            }else{
              that.addqdvalue = ''
            }
          }else{
              that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

       //删除角色
       deleteRole(e){
        var that = this
        $http.axios.delete("/api/channel/role/"+e).then(function(response) {
          if(response.data.code == 200) {
            that.getRoleList()
          }else{
              that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
       
      },

      //获取渠道列表
      getChannelList(){
        let that = this
        $http.axios.get("/api/system/user/getChannelList").then(function(response) {
          if(response.data.code == 200){
            that.qdoptions = response.data.data
            that.addqdoptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      // //设置按钮点击获取菜单列表
      getMenuLists(e){
        let that = this
        that.dialogVisible = true
        that.roleIdShow = e
        that.getChannelList()
        $http.axios.get("/api/system/menu/treeselect").then(function(response) {//,that.jurisdictionParams
          if(response.data.code == 200){
            that.indexManageOptions = response.data.data
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
        // getChinese(e)
      },

      //设置角色权限回显
      getMenuListsHx(e){
        let that = this
        that.dialogVisible = true
        that.roleIdShow = e
        $http.axios.get("/api/system/menu/roleMenuTreeselect/"+e).then(function(response) {
          if(response.data.code == 200){
            that.indexManageOptions = response.data.menus
            that.checkedKeys = response.data.checkedKeys
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },

      treeChecked(){
        this.checkedMenuIds = this.$refs.tree.getCheckedKeys()
      },

      //权限弹框确定按钮
      sureDialogVisible(){
        let that = this
        let menuIdshow = String(that.checkedMenuIds)
        $http.axios.get("/api/channel/role/editRoleMenu?roleId="+that.roleIdShow+"&menuIds="+menuIdshow,that.sureDialogParams).then(function(response) {//,that.jurisdictionParams
          if(response.data.code == 200){
            that.dialogVisible = false
            that.getRoleList()
          } else {
            that.$message({
              showClose: true,
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      },


      // 分页
    handleSizeChange(val) {
      this.pageSize = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    handleCurrentChange(val) {
      this.pageNum = val
      var params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.findAll(params)
    },
    //分页方法
    findAll (params) {
      //获取数据接口（调用封装的接口）
      this.getRoleList()
    },

      // tab跳转
      goToTitle(){
        this.$router.replace('/auth-management/roleManage')
      },
      goToRole(){
        this.$router.replace('/auth-management/titleManage')
      }
  }
}
</script>

<style lang="scss">

.authority .poc-card-table .pcth-query-box .pcth-queryName{
  min-width:0;
}
.authority .marginR24 {
  margin-right: 24px;
}
.el-tabs__active-bar,.el-tabs__nav-wrap::after {
  display:none;
}
.poc-card-table .pcth-title-box {
  display:block;
}
.el-tabs__content{
  overflow:inherit !important;
}
.roleSelect{
  margin-left:40px;
}
.add_roleBtn {
  float:right;
} 
.header_role_select{
  height:1px;
}
.add_titleBtn .el-select{
  width:100%;
}
.add_roleBtn .el-dialog__body{
  margin-bottom:0!important;
}
.roleTable .el-table th{
  color:#1b1f23;
  background:$poc-color-primary-deputy;
}
.roleTable .el-table--mini th{
  padding:12px 0;
  /* font-weight:700 !important; */
  font-size:14px;
}
.roleTable .el-table--mini td{
  padding:10px 0;
}
.el-popup-parent--hidden .el-dialog__body{
  text-align:left!important;
}
.roleTable .el-form-item__content{
  margin-left:20px!important;
}
.dialogCheckall,.dialogCheck{
  display:inline-block;
  vertical-align:top;
}
.dialogCheckall{
  width:20%;
}
.dialogCheck{
  width:78%;
}
.dialog_tableCheckbox {
  margin-left:100px;
}
.dialog_foot {
  text-align:center;
}
.roleHeader{
  margin-top:5px;
  margin-bottom:20px;
}
.role_headerTitle{
  font-size:14px;
}
.role_headerTitle span{
  padding-right:15px;
}
.role_headerChecked{
  color:#6e7c7c;
  font-size:16px;
  font-weight:700;
}
.footer_paging{
  margin:20px 0 4px;
}
.footer_totalNums{
  font-size:12px;
  color:#666;
  padding-left:5px;
  padding-right:20px;
}
.footerPaging {
  float:right;
}
.roleTable .el-table .cell.el-tooltip{
  white-space:initial!important;
}

</style>
