

import PublicisCommon from 'publicis-common';
import config from '@/api/config';
import Router from 'vue-router'
import Vue from 'vue'

import Home from '@/views/index';
import Login from '@/views/Login';
import HomePage from '@/views/auth/homePage/HomePage';
import AuthManagement from '@/views/auth/index';
import PlanManagement from '@/views/auth/index';
import VivoAccountManagement from '@/views/auth/index';
import MiAccountManagement from '@/views/auth/index';
import HwAccountManagement from '@/views/auth/index';
import YybAccountManagement from '@/views/auth/index';
import OperationAccess from '@/views/operation/index';
import AuthManagementRoutes from './auth-management';
import PlanManagementRoutes from './plan-management';
import VivoAccountManagementRoutes from './vivoAccount-management';
import MiAccountManagementRoutes from './miAccount-management';
import HwAccountManagementRoutes from './hwAccount-management';
import YybAccountManagementRoutes from './yybAccount-management';
import OperationAccessRoutes from './operation-access';
import AccountUser from '@/views/user/index';
import AccountUserRouter from './account-user';

const { VueRouter, $sessionStorage, CommonRouter } = PublicisCommon;

//解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const original = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return original.call(this, location).catch(err => err)
// }
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
}
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// Vue.use(Router)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes: [
    ...CommonRouter,
    {
      path: '/',
      name: 'login',
      component: Login,
      query: {
        callbackUrl: '',
        appName: ''
      }
    },
    {
      path: '/sso',
      name: 'sso',
      redirect: '/',
    },
    {
      path: '/auth-management',
      name: 'authManagement',
      component: AuthManagement,
      redirect: '/auth-management/account-list',
      children: [
        ...AuthManagementRoutes.routes
      ],
    },
    {
      path: '/plan-management',
      name: 'planManagement',
      component: PlanManagement,
      redirect: '/plan-management/accountSelection',
      children: [
        ...PlanManagementRoutes.routes
      ],
    },
    {
      path: '/vivoAccount-management',
      name: 'VivoAccountManagement',
      component: VivoAccountManagement,
      redirect: '/vivoAccount-management/account-list',
      children: [
        ...VivoAccountManagementRoutes.routes
      ],
    },
    {
      path: '/miAccount-management',
      name: 'MiAccountManagement',
      component: MiAccountManagement,
      redirect: '/miAccount-management/account-list',
      children: [
        ...MiAccountManagementRoutes.routes
      ],
    },
    {
      path: '/hwAccount-management',
      name: 'HwAccountManagement',
      component: HwAccountManagement,
      redirect: '/hwAccount-management/account-list',
      children: [
        ...HwAccountManagementRoutes.routes
      ],
    },
    {
      path: '/yybAccount-management',
      name: 'YybAccountManagement',
      component: YybAccountManagement,
      redirect: '/yybAccount-management/account-list',
      children: [
        ...YybAccountManagementRoutes.routes
      ],
    },
    {
      path: '/operation-access',
      name: 'oerationAccess',
      component: OperationAccess,
      redirect: '/operation-access/product-list',
      children: [
        ...OperationAccessRoutes.routes
      ],
    },
    {
      path: '/account-user',
      name: 'accountUser',
      component: AccountUser,
      redirect: '/account-user/apply',
      children: [
        ...AccountUserRouter.routes
      ],
    },
  ]
});


/**
 * 导航守卫 - 进入前
 * 清理url中的token参数，并存储token信息
 */
router.beforeEach((to, from, next) => {
  const query = JSON.parse(JSON.stringify(to.query));
  if (query.token) {
    $sessionStorage.setItem(config.USER_TOKEN_KEY, query.token);
    delete query.token;
    next({ path: to.path, params: to.params, query });
  } else {
    next();
  }
});

export default router;

