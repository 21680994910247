<template>
  <div class="header-right-group">
    <el-button
      size="small"
      class="user-login-btn"
      @click="logout"
    >LOGOUT<i class="el-icon-arrow-right el-icon-right"></i></el-button>
  </div>
</template>

<script>
import Api from '@/api';
import config from '@/api/config';

export default {
  name: 'HeaderRightGroup',

  data() {
    return {
      userInfo: {
        username: '',
      },
    };
  },

  methods: {
    /**
     * 登出
     */
    async logout() {
      const { status } = await Api.user.logout();
      if (status === 0 || status === 100000) {
        this.$cookies.remove(config.COOKIE_TOKEN);
        this.$cookies.remove("userToken");
        this.$sessionStorage.removeItem(config.USER_TOKEN_KEY);
        this.$router.push({ name: 'login' });
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/var.scss';
.header-right-group .user-login-btn.el-button{
  margin-right: 30px;
  background: transparent;
  color: #FFFFFF;
  border: none;
  a{
    display: flex;
    align-items: center;
  }
  i{
    font-size: 14px;
    margin-left: 5px;
  }
}
.header-right-group .user-login-btn.el-button:hover{
  color: #ba9765;
  border: none;
}
.header-right-group {
  .hrg-item {
    margin-left: $MP8;
  }

  .switch-lang {
    width: 12px;
    align: center;
    display: inline-block;
  }

  .el-button {
    background: #66856d;
    color: #FFFFFF;
    border-color: transparent;
  }
}
</style>
