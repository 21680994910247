<template>
  <el-dialog
    :visible.sync="show"
    width="70%"
    top="2%"
    :close-on-click-modal="false"
    @close = "onCancel"
    class="account-detail-dialog"
    :title="title+'模板'"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="模板名称" prop="name">
        <el-input v-model="ruleForm.name" style="width:40%" :placeholder="fileList.reportName?fileList.reportName:'请输入模板名称'"></el-input>
      </el-form-item>
      <el-form-item label="报表名称" prop="excelName">

        <!-- <el-form-item label="报表名称" prop="excelName"> -->
        <!-- <el-input v-model="ruleForm.excelName" style="width:40%" clearable placeholder="请输入发送报表名称" oninput="" @clear='handleClear'></el-input> -->
        <!-- <div>
          <span class="addWord" v-for="(item,i) in cibaoList" :key='i' @click="addWordsFn(item)">[{{item}}]</span>
        </div> -->
      <!-- </el-form-item>         -->
        <el-input v-model="ruleForm.excelName" style="width:40%" clearable :placeholder="fileList.title?fileList.title:'请输入发送报表名称'" oninput="value=value.replace(^[^\\\/\.]+$,'')" @clear='handleClear'></el-input>
        <div>
          <span class="addWord" v-for="(item,i) in cibaoList" :key='i' @click="addWordsFn(item)">[{{item}}]</span>
        </div>
      </el-form-item>
      <el-form-item label="报表维度" prop="adType">
        <el-select disabled v-model="ruleForm.adType" style="width:40%" placeholder="请选择报表维度">
          <!-- <el-option label="账户" value="40"></el-option> -->
          <el-option label="任务" value="1"></el-option>
          <el-option label="子任务" value="2"></el-option>
          <!-- <el-option label="创意" value="50"></el-option> -->
        </el-select>
      </el-form-item>
      <div v-if="dataSourceIs">
        
     
        <span>数据源sheet</span>
        <!-- <li>
        <div>数据源:</div>
        <ul>
          <li v-for="(item,i) in fileList.excelConfig.dataSource" :key="i">
            <span>{{item.name}}:</span><span v-for="(ele,j) in item.owners" :key="j">{{ele.ownerName}}</span>
          </li>
        </ul>
      </li> -->
        <div v-for="(item,i) in dataSource" :key = i>
          <el-form-item :label="item.sheetName" prop="sourceName">
            <!-- <span class="sourceClass"><i class="redTip">*</i>{{item.sheetName}}</span> -->
            <el-select disabled v-model="ruleForm.sourceName[i]" value-key = "name" style="width:40%" multiple :placeholder="item.owners[0].ownerName?item.owners[0].ownerName:'请选择账户'"  @change="changeFn($event,i,'data')">
              <el-option :label="item1.name" :value="item1" v-for="(item1,j) in tableDataList" :key=j></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="App" prop="appList">
            <el-select disabled v-model="ruleForm.appList[i]" value-key = "name" style="width:40%" multiple filterable :placeholder="item.owners[0].appId?item.owners[0].appId.toString():'请选择App'" @change="changeFn($event,i,'appId')">
              <el-option :label="item1.name" :value="item1" v-for="(item1,j) in appList[i]" :key=j>{{item1.name}} </el-option>
            </el-select>
          </el-form-item> 
        </div>

      <!-- <el-form-item  v-for="(item,i) in dataSource" :key = i :label="item.sheetName"  >
        <el-select disabled v-model="ruleForm.sourceName[i]" value-key = "name" style="width:40%" multiple :placeholder="fileList.account?fileList.account:'请选择账户'" @change="changeFn($event,i,'data')">
          <el-option :label="item1.sheetName" :value="item1" v-for="(item1,j) in tableDataList" :key=j></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="App" prop="appList">
          <el-select v-model="ruleForm.appList[i]" value-key = "name" style="width:40%" multiple filterable placeholder="请选择App" @change="changeFn($event,i,'appId')">
            <el-option :label="item1.name" :value="item1" v-for="(item1,j) in appList" :key=j>{{item1.name}} </el-option>
          </el-select>
        </el-form-item>  -->
     </div>
 
      <div v-if="keywordsIs">
        <span>关键字sheet</span>
         <!-- <li>
        <div>关键词:</div>
        <ul>
           <li v-for="(item,i) in fileList.excelConfig.keyword" :key="i">
            <span>{{item.name}}:</span><span v-for="(ele,j) in item.owners" :key="j">{{ele.ownerName}}</span>
          </li>
        </ul>
      </li> -->
        <div v-for="(item,i) in keywords" :key = i>
        <div v-for="(ele,index) in item.owners" :key = index >
        <el-form-item :label="item.sheetName" prop="KeyName">
            <el-select disabled v-model="ruleForm.KeyName[i]" value-key = "name" style="width:40%" multiple :placeholder="ele.ownerName?ele.ownerName:'请选择账户'" @change="changeFn($event,i,'keyword')">
              <el-option :label="item.sheetName" :value="item1" v-for="(item1,j) in tableDataList" :key=j></el-option>
            </el-select>
          </el-form-item>   
          <el-form-item label="App" prop="appList">
            <el-select v-model="ruleForm.appList[i]" value-key = "name" style="width:40%" multiple filterable :placeholder="item.owners[0].appId?item.owners[0].appId.toString():'请选择App'" @change="changeFn($event,i,'appId')">
              <el-option :label="item1.name" :value="item1" v-for="(item1,j) in appList" :key=j>{{item1.name}} </el-option>
            </el-select>
          </el-form-item>
        </div>
          
        </div>  
      </div>
      
      <el-form-item label="接收人" prop="sendUser">
        <!-- <label>接收人</label> -->
        <el-select v-model="ruleForm.sendUser" value-key ="userId" multiple style="width:40%" filterable placeholder="请选择接收人"  @change="changeFn($event)">
            <el-option
            v-for="item in distributionOptions"
            :key="item.userId"
            :label="item.string"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="抄送人" prop="copySender">
        <!-- <label>抄送人</label> -->
        <el-select v-model="ruleForm.copySender"  value-key ="id" multiple style="width:40%" filterable placeholder="请选择抄送人"  @change="changeFn($event)">
          <el-option
            v-for="item in cc_options"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
        <el-button type="text" @click="dialogFormVisible_add = true" style="padding-left:10px;">添加</el-button>
        <el-dialog title="添加抄送人" :visible.sync="dialogFormVisible_add" append-to-body :modal-append-to-body="false">
          <el-form :model="addForm">
            <el-form-item label="人员名称" :label-width="formLabelWidth">
              <el-input v-model="addForm.name" autocomplete="off" placeholder="请输入添加人员姓名" style="width:100%!important;"></el-input>
            </el-form-item>
            <el-form-item label="邮箱地址" :label-width="formLabelWidth">
              <el-input v-model="addForm.email" autocomplete="off" placeholder="请输入添加邮箱地址" style="width:100%!important;"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible_add = false">取 消</el-button>
            <el-button type="primary" @click="csrSure">确 定</el-button>
          </div>
        </el-dialog>
      </el-form-item>
      <el-form-item v-if="type==2" label="接收方式" prop="type">
        <el-checkbox-group v-model="ruleForm.type">
          <el-checkbox label="Excel" name="type"></el-checkbox>
          <el-checkbox label="图片" name="type"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item v-if="type==2" label="是否拉取零点数据" prop="resource">
        <el-radio-group disabled v-model="ruleForm.resource">
          <el-radio label="是" value="1"></el-radio>
          <el-radio label="否" value="0"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="type == 2" label="时报时间点" prop="timeSlot">
        <el-select v-model="ruleForm.timeSlot" multiple filterable placeholder="请选择时报时间点">
          <el-option
            v-for="(item,i) in timeLimitList"
            :key="i"
            :label="item.time"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- <dayTimeUnit v-if="type == 2"></dayTimeUnit> -->
     
      <!-- <div  v-if="type == 2"> 
        <span>是否拉取零时数据:</span> <span>{{needList[fileList.isNeedYesterday-1]}}</span>
      </div> -->
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onConfirm('ruleForm')">{{$t('dc:保存')}}</el-button>
    </span> 
    <span slot="footer" >
      <el-button type="primary" :loading="loading" @click="onCancel">{{$t('dc:取消')}}</el-button>
    </span>
      <addCopy :visible.sync="addCopyIs"></addCopy>

  </el-dialog>
</template>

<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';
import addCopy from './addCopyDialog';
import getUserList from '@/utils/getUserList'//获取用户列表
export default {
  
  components:{
    addCopy
  },
  props: {
    title:String,
    visible: {
      type: Boolean,
      default: false
    },
    type:Number,
    path:String,
    pubVue:Object,
  },
  data() {
    return {
      show: this.visible,
      loading: false,
      dataSourceIs:false,
      keywordsIs:false,
      addCopyIs:false,
      dataSource:[],
      keywords:[],
      needList:['否','是'],
      ruleForm: {
        name: '',
        excelName:'',
        adType: '',
        type: [],
        resource: '',
        sourceName:[],
        KeyName:[],
        sendUser:[],
        copySender:[],
        appList:{},
        timeSlot:[]
      },
      addForm: {
          name: '',
          email:''
        },
      timeLimitList:[{'time':'10:00','id':'10'},{'time':'10:30','id':'10.5'},{'time':'11:00','id':'11'},{'time':'11:30','id':'11.5'},
      {'time':'12:00','id':'12'},{'time':'12:30','id':'12.5'},{'time':'13:00','id':'13'},{'time':'13:30','id':'13.5'},{'time':'14:00','id':'14'},{'time':'14:30','id':'14.5'},{'time':'15:00','id':'15'},{'time':'15:30','id':'15.5'},
      {'time':'16:00','id':'16'},{'time':'16:30','id':'16.5'},{'time':'17:00','id':'17'},{'time':'17:30','id':'17.5'},{'time':'18:00','id':'18'},{'time':'18:30','id':'18.5'},{'time':'19:00','id':'19'},{'time':'19:30','id':'19.5'},
      {'time':'20:00','id':'20'},{'time':'20:30','id':'20.5'},{'time':'21:00','id':'21'},{'time':'21:30','id':'21.5'},{'time':'22:00','id':'22'},{'time':'22:30','id':'22.5'},{'time':'23:00','id':'23'},{'time':'23:30','id':'23.5'}],

      formLabelWidth: '120px',
      dialogFormVisible_add: false,
      fileList:{},
      tableDataList:[],
      appList:{},
      cc_options:[],
      value1:[],
      reportId:'',
      distributionOptions:[],
      rules: {
        name: [
          { required: true, message: '请输入模板名称', trigger: 'blur' },
        ],
        excelName:[
          { required: true, message: '请输入发送报表名称', trigger: 'blur' },
        ],
        adType: [
          { required: true, message: '请选择报表维度', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个接收方式', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择是或者否', trigger: 'change' }
        ],
        sourceName:[{ required: true, message: '请选择账户', trigger: 'change'}],
        KeyName:[{ required: true, message: '请选择账户', trigger: 'change'}],
        sendUser:[{ required: true, message: '请选择接收人', trigger: 'change'}],
        appList:[{ required: true, message: '请选择App', trigger: 'change'}],
        sendTime:[{ required: true, message: '请选择接收时间', trigger: 'change'}]
      },
      cibaoList:['代理公司','渠道','报表类型','日期','时段'],
      wordsA : [],
    };
     
  },

  watch: {
    show(v) {
      this.$emit('update:visible', v);
    },
    visible(v) {
      this.show = v;

    },
  },
  mounted(){
    this.userId = this.$sessionStorage.getItem(config.USERID) || 1
    this.userName = this.$sessionStorage.getItem(config.USERNAME)
    this.deptId = this.$sessionStorage.getItem(config.DEPTID)
    this.cc_options = this.$sessionStorage.getItem('optionSelectCopyUser')
    getUserList().then(res => {
      this.cc_options = res
    })

  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onConfirm(formName) {
      let _this = this
        _this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log('成功2')
            console.log('选中')
            console.log(_this.ruleForm)
            _this.modifyModelFn(_this.dayLimit)
    
          } else {
            console.log('error submit!!');
            return false;
          }
          
          
        });
    },
    //添加抄送人
    csrSure(){
      let that = this
      that.dialogFormVisible_add = false
      // that.csrParams.accountId = '71754670438'
      // that.csrParams.accountId = String(that.userDetailId)
      // that.csrParams.email = that.addForm.email
      // that.csrParams.userName = that.addForm.name
      let userName = that.addForm.name
      let email = that.addForm.email
      $http.axios.get("/api/system/user/bdAccountCopyUser?userName="+userName+"&email="+email).then(function(response) {
        if(response.data.code == 200){
          getUserList().then(res => {
            that.cc_options = res
          })
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    onCancel() {
      this.show= false;
      this.$refs.ruleForm.resetFields()
      this.pubVue.listModelFn()

      console.log('关闭')
    },
    addOrSetFn(type){
      
    },
    handleClear(){
      this.ruleForm.excelName = ''
      this.wordsA = []

    },
    
    //获取详情
    editFn(id){
      let _this = this,senderName =[],time = [],sendId =[],sendUser =[],copySenderName=[],copySender=[],type1='',type2=''
      _this.reportId = id
      $http.axios.get("/api/hwReport/reportModel/queryById?id="+id).then(function(res) {
        if(res.data.code == 200)
        _this.fileList = res.data.data
        _this.getFpList()
        
        if(_this.type == 2&&_this.fileList.time != null){
          _this.ruleForm.timeSlot =  _this.fileList.time.split(",")
        }
        _this.ruleForm.adType = _this.fileList.adType.toString()
        _this.dataSource = _this.fileList.excelConfig.dataSource
        _this.keywords = _this.fileList.excelConfig.keyword
        _this.ruleForm.resource = _this.fileList.zeroFlag.toString()
        type1 = _this.fileList.excelFlag==1?'Excel':''
        type2 = _this.fileList.imgFlag==1?'图片':''
        if(type1!=''){
          _this.ruleForm.type.push(type1)
        }
        if(type2!=''){
          _this.ruleForm.type.push(type2)
        }
        
        _this.ruleForm.name= _this.fileList.name,
        _this.ruleForm.excelName=_this.fileList.title,
        _this.ruleForm.adType= _this.fileList.adType.toString(),
        _this.ruleForm.resource= _this.needList[_this.fileList.zeroFlag],
        sendUser=_this.fileList.sender?_this.fileList.sender.split(','):[]
        senderName=_this.fileList.senderName?_this.fileList.senderName.split(','):[]
        copySender=_this.fileList.copySender?_this.fileList.copySender.split(','):[]
        copySenderName=_this.fileList.copySenderName?_this.fileList.copySenderName.split(','):[]
        sendUser.forEach((obj,i)=>{
          _this.ruleForm.sendUser.push({'string':senderName[i],'userId':obj})
              // _this.$set(_this.ruleForm.sendUser,i, {'userId': obj})
              // _this.$set(_this.ruleForm.sendUser,i, {'string': senderName[i]})        })
        })
        copySender.forEach((obj,i)=>{
          _this.ruleForm.copySender.push({'id':obj, 'name': copySenderName[i]})
        })
        // })
        // copySenderName.forEach((obj,i)=>{
        //   copySenderName.push({'name':obj})
        // })
        // copyId.forEach((obj,i)=>{
        //   copySender[i].id=obj
        // })        
        if(_this.dataSource!=null){
          _this.dataSourceIs = true
        }
        if(_this.keywords!=null){
          _this.keywordsIs = true
        }
      })
    },
    
    changeFn(event,i,type){
      let _this = this,ownersList = [],ownerIds = []
      // "owners": [
    //           {
    //             "ownerId": 0,
    //             "ownerName": ""
    //           }
    //         ]
      console.log(event,i)
      console.log(this.dataSource)
      console.log(this.keywords)

      if(type == "data"&&event.length != 0){
        event.forEach(obj=>{
          ownersList.push({"ownerId":obj.id,"ownerName":obj.name})
          ownerIds.push(obj.id)
        })
        this.dataSource[i].owners = ownersList
      }else if(type == "keyword"&&event.length != 0){
        event.forEach(obj=>{
          ownersList.push({"ownerId":obj.id,"ownerName":obj.name})
          ownerIds.push(obj.id)
        })
        this.keywords[i].owners = ownersList
      }
      if((type == "data"||type == "keyword")&&event.length != 0){

        // this.getAppList(ownerIds.toString())
      }
      console.log(this.dataSource)
      console.log(this.keywords)
    },
    //获取分配人员列表
    getFpList(){
      let that = this
      // $http.axios.get("/api/system/user/getAccountAppendList").then(function(response) {
      $http.axios.get("/api/system/user/getAppendUserListByChannel?channelId=6").then(function(response) {
        if(response.data.code == 200){
          that.distributionOptions = response.data.data
        } else {
          that.$message({
            showClose: true,
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    },
    
   
    
  //修改
    modifyModelFn(){
      let _this = this,params = {},excelFlag = 0,imgFlag = 0,sendUser = {"string":[],"id":[]},copySender = {"string":[],"id":[]}
      if(_this.ruleForm.type.length>0){
        if(_this.ruleForm.type[0]=="图片"){
          imgFlag = 1
        } else if(_this.ruleForm.type[0]=="Excel"){
          excelFlag = 1
        }
        if(_this.ruleForm.type.length == 2){
          excelFlag = 1
          imgFlag = 1
        }
      }
      _this.ruleForm.sendUser.forEach(obj=>{
        sendUser.string.push(obj.string)
        sendUser.id.push(obj.userId)
      })
      _this.ruleForm.copySender.forEach(obj=>{
        copySender.string.push(obj.name)
        copySender.id.push(obj.id)
      })
      params = {
        "copySender": copySender.id.toString(),
	      "copySenderName": copySender.string.toString(),
        "excelFlag": excelFlag,
        "imgFlag": imgFlag,
        "name": _this.ruleForm.name,//模板名称
        "id":_this.reportId,
        "sender": sendUser.id.toString(),
        "senderName": sendUser.string.toString(),
        "title": _this.ruleForm.excelName,//报表名称，词包
        "userId":  _this.userId,
        "userName":  _this.userName,
        "deptId":  _this.deptId,
        "type":_this.type,
        "time":_this.ruleForm.timeSlot.toString()
      }

      $http.axios.post("/api/hwReport/reportModel/edit",params).then(res=> {
        if(res.data.code == 200){
          // _this.tableData = res.data.data.items
          // _this.totalRow = res.data.data.totalCount
          _this.onCancel()
        }  else {
          _this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    addWordsFn(val){      
      let _this = this,wordsA = []
      if(_this.wordsA.length!= 0){
        _this.wordsA.forEach((obj,i,arr)=>{
          console.log(obj)
          if(val == obj){
            arr.splice(i,1)
            
          }
        })
        _this.wordsA.push(val)
        wordsA = _this.wordsA
        _this.ruleForm.excelName +='{'+val+'}'
      }else{
        console.log(val)
        _this.ruleForm.excelName +='{'+val+'}'
        _this.wordsA.push(val)
      }

    },
    
    
  }
};
</script>
<style lang="scss" scoped>
.limitInput input{
    width: 80px;
    padding: 8px 10px!important;
    text-align: center;
    margin-left: 10px;
}
.dateConent{
  margin: 15px;
}
.addWord{
  cursor: pointer;
  display: inline-block;
  margin: 5px 8px;
  font-size: 12px;
  color: #ccc;
}
ul,li{
  list-style: none;
}
.sendTIme{
  font-size: 12px;
}
.sendTimeTitle{
  display: inline-block;
  width: 120px;
  text-align: right;
}
.redContent{
    color: #F56C6C;
    margin-right: 4px;
}
</style>

