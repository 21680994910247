
<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle" v-if="authorityType">
        <div class="hl_headers sticky" style="margin-bottom: 0">
          <div class="hl_breadCrumb hl_headerLeft">
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>运营管理</el-breadcrumb-item>
              <el-breadcrumb-item>{{reporFormsTypeList[reporFormsTypeIndex].name}}模板库</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="hl_headerRight">
            <el-button
              v-if="channelId == 6 && userId == 614"
              type="primary"
              @click="aiQiYiUpdateVisible = true"
              >更新爱奇艺任务关系</el-button
            >
            <el-upload
              :limit="1"
              ref="upload"
              accept=".xlsx"
              :headers="headers"
              :show-file-list="false"
              :on-success="uploadSuccess"
              :action="`/api/${interfaceParams.interfaceFields}/reportModel/uploadExcel`"
            >
              <el-button type="primary">+ 新建模板</el-button>
            </el-upload>
            <el-button type="primary" @click="emplateDescriptionVisible = true"
              >模板表头字段说明</el-button
            >
            <el-button
              type="primary"
              v-if="channelId != 8"
              @click="systemInstructions"
              >系统使用说明</el-button
            >
          </div>
        </div>
        <!-- 模板表头字段说明弹框 -->
        <el-dialog
          title="模板表头字段说明"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="emplateDescriptionVisible"
          :width="
            channelId == 3
              ? '930px'
              : channelId == 4
              ? '980px'
              : channelId == 5 || channelId == 6
              ? '880px'
              : '740px'
          "
        >
          <img class="icon" src="@/assets/img/ZDYLicon.png" />
          <el-collapse :value="[1]">
            <el-collapse-item
              v-for="(item, index) in explainList[channelId]"
              :key="index"
              :title="item.name"
              :name="index + 1"
            >
              <div class="table">
                <div
                  class="news"
                  v-for="(items, indexs) in item.list"
                  :key="indexs"
                >
                  {{ items }}
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div slot="footer">
            <el-button type="primary" @click="emplateDescriptionVisible = false"
              >关 闭</el-button
            >
          </div>
        </el-dialog>
        <div class="line_function_contant">
          <div
            v-for="(item, index) in channelList"
            :key="index"
            :class="{ active: channelId == item.id }"
            @click="changeChannel(item.id)"
          >
            {{ item.name.toUpperCase() }}
          </div>
        </div>
        <div class="radius_function_contant">
          <div
            v-for="(item, index) in reporFormsTypeList"
            :key="index"
            :class="{ active: reporFormsTypeIndex == index }"
            @click="changeReporFormsType(index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="search_container">
          <el-form :inline="true" ref="ruleForm">
            <el-form-item>
              <el-input
                v-model="searchForm.name"
                clearable
                placeholder="请输入报表名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchForm.ownerName"
                clearable
                placeholder="请输入广告主名称"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchForm.senderName"
                clearable
                placeholder="请输入接收人"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="searchForm.copySenderName"
                clearable
                placeholder="请输入抄送人"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-select
                clearable
                placeholder="请选择报表开关状态"
                v-model="searchForm.status"
              >
                <el-option label="开启报表状态" value="0">开</el-option>
                <el-option label="关闭报表状态" value="1">关</el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="getTableDataList(pageForm.index)"
                >查询</el-button
              >
              <el-button type="info" size="small" @click="resetForm"
                >重置</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="materiaContent">
          <el-table
            ref="multipleTable"
            :data="tableDataList"
            tooltip-effect="dark"
            style="width: 100%"
            :cell-class-name="tableCellClassName"
            @cell-click="clickcell"
          >
            <el-table-column width="170px" show-overflow-tooltip label="编号">
              <template v-slot="scope">
                {{ scope.row.id }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="right-end"
                  v-if="scope.row.fileSizeWarnStatus"
                >
                  <div
                    slot="content"
                    v-html="
                      scope.row.fileSizeWarnStatus == 1
                        ? '文件大小目前已超过10MB，请尽快处理！'
                        : '文件大小目前已超过18MB，目前已进行强制停用！'
                    "
                  ></div>
                  <i
                    class="el-icon-warning"
                    style="font-size: 14px"
                    :style="{
                      color:
                        scope.row.fileSizeWarnStatus == 1
                          ? '#F3920B'
                          : '#F12323',
                    }"
                  ></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              min-width="120px"
              prop="name"
              show-overflow-tooltip
              label="名称"
            >
            </el-table-column>
            <el-table-column
              min-width="140px"
              prop="title"
              show-overflow-tooltip
              label="文件名"
            >
            </el-table-column>
            <el-table-column width="80px" label="状态">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.status"
                  :active-value="0"
                  :inactive-value="1"
                  @change="switchFunction(scope.row, 'status')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="90px" label="Excel">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.excelFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="switchFunction(scope.row, 'excelFlag')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="80px" label="图片">
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.imgFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="switchFunction(scope.row, 'imgFlag')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              width="110px"
              label="零点推送"
              v-if="reporFormsTypeList[reporFormsTypeIndex].id == 2"
            >
              <template v-slot="scope">
                <el-switch
                  v-model="scope.row.zeroFlag"
                  :active-value="1"
                  :inactive-value="0"
                  @change="switchFunction(scope.row, 'zeroFlag')"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              min-width="240px"
              v-for="(item, index) in titleList"
              :key="index"
              :prop="item.prop"
              :label="item.name"
              show-overflow-tooltip
            >
              <template v-if="item.prop == 'copySenderName'" v-slot="scope">
                <span>{{ scope.row.copySenderName || "-" }}</span>
              </template>
            </el-table-column>
            <el-table-column
              width="110px"
              prop="fileSize"
              show-overflow-tooltip
              label="文件大小(kb)"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              min-width="180px"
              label="广告主名称"
            >
              <template v-slot="scope">
                <span
                  v-for="(item, index) in scope.row.excelConfig"
                  :key="index"
                >
                  <span v-for="(items, indexs) in item" :key="indexs">
                    <span v-for="(itemd, indexd) in items.owners" :key="indexd">
                      {{ itemd.ownerName }}
                    </span>
                  </span>
                </span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="370" label="操作">
              <template slot-scope="scope">
                <el-button class="solid-tag-instructions" data-type="xiuGai"
                  >修改</el-button
                >
                <el-button class="solid-tag-error" data-type="xiazai"
                  >下载</el-button
                >
                <el-button class="solid-tag-warning" data-type="bufa"
                  >补发</el-button
                >
                <el-dropdown
                  @command="retransmissionFunction($event, scope.row.id)"
                >
                  <el-button class="solid-tag-warning">重发</el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="1">全部发送</el-dropdown-item>
                    <el-dropdown-item :command="2">接收人</el-dropdown-item>
                    <el-dropdown-item :command="3">抄送人</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button class="solid-tag-death" data-type="zhuanrang"
                  >转让</el-button
                >
                <el-button class="solid-tag-error" data-type="delBtn"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 转让弹框 -->
        <el-dialog
          title="转让"
          width="400px"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="assignmentVisible"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form label-width="50px">
            <el-form-item label="转让人">
              <el-select
                filterable
                placeholder="请选择"
                :value="assignmentInfor.userId"
              >
                <el-option
                  v-for="(item, index) in transferorList"
                  :key="index"
                  :label="item.string"
                  :value="item.userId"
                  @click.native="assignmentInfor = item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div slot="footer">
            <el-button @click="assignmentVisible = false">取 消</el-button>
            <el-button type="primary" @click="assignmentSubmit"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- 补发弹框 -->
        <el-dialog
          width="470px"
          title="补发"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="reissueVisible"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form>
            <el-form-item>
              <el-date-picker
                type="daterange"
                range-separator="-"
                v-model="reissueDate"
                value-format="yyyy-MM-dd"
                end-placeholder="结束日期"
                start-placeholder="开始日期"
                :disabled="
                  reporFormsTypeList[reporFormsTypeIndex].id == 2 ? true : false
                "
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="tips">
            温馨提示：
            <div style="margin-top: 6px">
              由于数据补发时间较长，请您耐心等待，请勿重复补发，造成文件损坏！
            </div>
          </div>
          <div slot="footer">
            <el-button @click="reissueVisible = false">取 消</el-button>
            <el-button type="primary" @click="reissueSubmit">确 定</el-button>
          </div>
        </el-dialog>
        <div class="footerPaging">
          <el-pagination
            :total="pageForm.total"
            :page-size="pageForm.size"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="pageForm.index"
            @size-change="getTableDataSizeList"
            @current-change="getTableDataList"
            layout="total, sizes, prev, pager, next, jumper"
          >
          <!-- @current-change="getTableDataList" -->

          </el-pagination>
        </div>
      </template>
    </PocCardTable>
    <aiQiYiUpdate :visible.sync="aiQiYiUpdateVisible"></aiQiYiUpdate>
    <editReportForms
      :pubVue="pubVue"
      :keyWord="keyWord"
      :filePath="filePath"
      ref="editReportForms"
      :channelId="channelId"
      :dataSource="dataSource"
      :fileNameList="fileNameList"
      :dimensionsList="dimensionsList"
      :interfaceParams="interfaceParams"
      :operationStatus="operationStatus"
      :visible.sync="editReportFormsVisible"
      :editReportFormsTime="editReportFormsTime"
      v-if="JSON.stringify(interfaceParams) != '{}'"
      :reporFormsType="reporFormsTypeList[reporFormsTypeIndex].id"
      :reporFormsTitle="reporFormsTypeList[reporFormsTypeIndex].name"
    ></editReportForms>
  </div>
</template>

<script>
import config from "@/api/config";
import { $http } from "@/api/http";
import formatDate from "@/utils/formatDate";
import aiQiYiUpdate from "@/views/dialog/aiQiYiUpdate";
import editReportForms from "@/views/dialog/editReportForms";

export default {
  name: "authManagement.ReportForms",

  data() {
    return {
      dataId: "",
      keyWord: [],
      filePath: "",
      pubVue: this,
      channelId: "",
      dataSource: [],
      channelList: [],
      reissueDate: [],
      fileNameList: [],
      tableDataList: [],
      transferorList: [],
      dimensionsList: [],
      assignmentInfor: {},
      interfaceParams: {},
      authorityType: true,
      operationStatus: "",
      reissueVisible: false,
      editReportFormsTime: 0,
      assignmentVisible: false,
      reporFormsTypeIndex: null,
      aiQiYiUpdateVisible: false,
      editReportFormsVisible: false,
      emplateDescriptionVisible: false,
      headers: {
        Authorization: "",
      },
      pageForm: {
        index: 1,
        size: 10,
        total: 0,
      },
      titleList: [
        { name: "接收人", prop: "senderName" },
        { name: "抄送人", prop: "copySenderName" },
      ],
      searchForm: {
        name: "",
        status: "",
        ownerName: "",
        senderName: "",
        copySenderName: "",
      },
      
      explainList: {
        3: [
          {
            name: "账户",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "推广目标",
              "推广流量",
              "流量场景",
              "统一规格id",
              "应用ID",
              "应用名称",
              "消耗",
              "ECPM",
              "曝光量",
              "点击量",
              "点击率",
              "点击均价",
              "下载量",
              "下载率",
              "下载均价",
              "LT7总留存",
              "LI7总留存率",
              "预约量",
              "预约下载量",
              "取消预约量",
              "表单量",
              "表单均价",
              "表单转化率",
              "表单关键行为量",
              "表单关键行为均价",
              "网页购买量",
              "网页购买均价",
              "有效咨询量",
              "有效咨询均价",
              "回传注册量",
              "回传注册均价",
              "自定义转化",
              "自定义转化均价",
              "应用内授信",
              "应用内授信均价",
              "应用内下单",
              "应用内下单均价",
              "应用内付费",
              "应用内付费均价",
              "注册应用付费率",
              "回传激活量",
              "回传激活均价",
              "回传次留量",
              "回传次留率",
              "回传次留均价",
              "第3日留存",
              "第4日留",
              "第5日留存",
              "第6日留存",
              "第7日留存",
              "第3日留存率",
              "第4日留存率",
              "第5日留存率",
              "第6日留存率",
              "第7日留存率",
              "拨打电话量",
              "拨打电话均价",
              "付费次数",
              "每次付费均价",
              "每次付费转化量",
              "每次付费转化成本",
              "游戏关键行为量",
              "游戏关键行为均",
              "deeplink回传留存量",
              "deeplink回传留存率",
              "deeplink回传留存均价",
              "dp自定义转化量",
              "dp自定义转化均价",
              "dp回传注册量,",
              "dp回传注册均价",
              "dp回传激活量",
              "dp回传激活均价",
              "dp回传拉活量",
              "dp回传拉活均价",
              "dp应用内下单量",
              "dp应用内下单均价",
              "dp应用内付费量",
              "dp应用内付费均价",
              "dp应用内授信量",
              "dp应用内授信均价",
              "快应用付费量",
              "快应用付费均价",
              "快应用关键行为量",
              "快应用关键行为均价",
              "快应用加桌量",
              "快应用加桌均价",
              "微信小游戏首次付费人数",
              "微信小游戏付费总额",
              "微信小游戏付费量",
              "微信小游戏付费均价",
              "微信小游戏注册量",
              "微信小游戏注册均价",
              "微信小游戏ROI1",
              "微信关注量",
              "微信关注均价",
              "应用付费金额",
              "应用ROI",
              "应用付费LTV1",
              "应用付费ROI1",
            ],
          },
          {
            name: "广告计划",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "计划id",
              "计划名称",
              "推广目标",
              "推广流量",
              "流量场景",
              "统一规格id",
              "应用ID",
              "应用名称",
              "消耗",
              "ECPM",
              "曝光量",
              "点击量",
              "点击率",
              "点击均价",
              "下载量",
              "下载率",
              "下载均价",
              "LT7总留存",
              "LI7总留存率",
              "预约量",
              "预约下载量",
              "取消预约量",
              "表单量",
              "表单均价",
              "表单转化率",
              "表单关键行为量",
              "表单关键行为均价",
              "网页购买量",
              "网页购买均价",
              "有效咨询量",
              "有效咨询均价",
              "回传注册量",
              "回传注册均价",
              "自定义转化",
              "自定义转化均价",
              "应用内授信",
              "应用内授信均价",
              "应用内下单",
              "应用内下单均价",
              "应用内付费",
              "应用内付费均价",
              "注册应用付费率",
              "回传激活量",
              "回传激活均价",
              "回传次留量",
              "回传次留率",
              "回传次留均价",
              "第3日留存",
              "第4日留",
              "第5日留存",
              "第6日留存",
              "第7日留存",
              "第3日留存率",
              "第4日留存率",
              "第5日留存率",
              "第6日留存率",
              "第7日留存率",
              "拨打电话量",
              "拨打电话均价",
              "付费次数",
              "每次付费均价",
              "每次付费转化量",
              "每次付费转化成本",
              "游戏关键行为量",
              "游戏关键行为均",
              "deeplink回传留存量",
              "deeplink回传留存率",
              "deeplink回传留存均价",
              "dp自定义转化量",
              "dp自定义转化均价",
              "dp回传注册量,",
              "dp回传注册均价",
              "dp回传激活量",
              "dp回传激活均价",
              "dp回传拉活量",
              "dp回传拉活均价",
              "dp应用内下单量",
              "dp应用内下单均价",
              "dp应用内付费量",
              "dp应用内付费均价",
              "dp应用内授信量",
              "dp应用内授信均价",
              "快应用付费量",
              "快应用付费均价",
              "快应用关键行为量",
              "快应用关键行为均价",
              "快应用加桌量",
              "快应用加桌均价",
              "微信小游戏首次付费人数",
              "微信小游戏付费总额",
              "微信小游戏付费量",
              "微信小游戏付费均价",
              "微信小游戏注册量",
              "微信小游戏注册均价",
              "微信小游戏ROI1",
              "微信关注量",
              "微信关注均价",
              "应用付费金额",
              "应用ROI",
              "应用付费LTV1",
              "应用付费ROI1",
            ],
          },
          {
            name: "广告组",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "计划id",
              "计划名称",
              "广告组ID",
              "广告组名称",
              "推广目标",
              "推广流量",
              "流量场景",
              "统一规格id",
              "应用ID",
              "应用名称",
              "消耗",
              "ECPM",
              "曝光量",
              "点击量",
              "点击率",
              "点击均价",
              "下载量",
              "下载率",
              "下载均价",
              "LT7总留存",
              "LI7总留存率",
              "预约量",
              "预约下载量",
              "取消预约量",
              "表单量",
              "表单均价",
              "表单转化率",
              "表单关键行为量",
              "表单关键行为均价",
              "网页购买量",
              "网页购买均价",
              "有效咨询量",
              "有效咨询均价",
              "回传注册量",
              "回传注册均价",
              "自定义转化",
              "自定义转化均价",
              "应用内授信",
              "应用内授信均价",
              "应用内下单",
              "应用内下单均价",
              "应用内付费",
              "应用内付费均价",
              "注册应用付费率",
              "回传激活量",
              "回传激活均价",
              "回传次留量",
              "回传次留率",
              "回传次留均价",
              "第3日留存",
              "第4日留",
              "第5日留存",
              "第6日留存",
              "第7日留存",
              "第3日留存率",
              "第4日留存率",
              "第5日留存率",
              "第6日留存率",
              "第7日留存率",
              "拨打电话量",
              "拨打电话均价",
              "付费次数",
              "每次付费均价",
              "每次付费转化量",
              "每次付费转化成本",
              "游戏关键行为量",
              "游戏关键行为均",
              "deeplink回传留存量",
              "deeplink回传留存率",
              "deeplink回传留存均价",
              "dp自定义转化量",
              "dp自定义转化均价",
              "dp回传注册量,",
              "dp回传注册均价",
              "dp回传激活量",
              "dp回传激活均价",
              "dp回传拉活量",
              "dp回传拉活均价",
              "dp应用内下单量",
              "dp应用内下单均价",
              "dp应用内付费量",
              "dp应用内付费均价",
              "dp应用内授信量",
              "dp应用内授信均价",
              "快应用付费量",
              "快应用付费均价",
              "快应用关键行为量",
              "快应用关键行为均价",
              "快应用加桌量",
              "快应用加桌均价",
              "微信小游戏首次付费人数",
              "微信小游戏付费总额",
              "微信小游戏付费量",
              "微信小游戏付费均价",
              "微信小游戏注册量",
              "微信小游戏注册均价",
              "微信小游戏ROI1",
              "微信关注量",
              "微信关注均价",
              "应用付费金额",
              "应用ROI",
              "应用付费LTV1",
              "应用付费ROI1",
            ],
          },
          {
            name: "广告",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "计划id",
              "计划名称",
              "广告组ID",
              "广告组名称",
              "广告ID",
              "广告名称",
              "推广目标",
              "推广流量",
              "流量场景",
              "统一规格id",
              "应用ID",
              "应用名称",
              "消耗",
              "ECPM",
              "曝光量",
              "点击量",
              "点击率",
              "点击均价",
              "下载量",
              "下载率",
              "下载均价",
              "LT7总留存",
              "LI7总留存率",
              "预约量",
              "预约下载量",
              "取消预约量",
              "表单量",
              "表单均价",
              "表单转化率",
              "表单关键行为量",
              "表单关键行为均价",
              "网页购买量",
              "网页购买均价",
              "有效咨询量",
              "有效咨询均价",
              "回传注册量",
              "回传注册均价",
              "自定义转化",
              "自定义转化均价",
              "应用内授信",
              "应用内授信均价",
              "应用内下单",
              "应用内下单均价",
              "应用内付费",
              "应用内付费均价",
              "注册应用付费率",
              "回传激活量",
              "回传激活均价",
              "回传次留量",
              "回传次留率",
              "回传次留均价",
              "第3日留存",
              "第4日留",
              "第5日留存",
              "第6日留存",
              "第7日留存",
              "第3日留存率",
              "第4日留存率",
              "第5日留存率",
              "第6日留存率",
              "第7日留存率",
              "拨打电话量",
              "拨打电话均价",
              "付费次数",
              "每次付费均价",
              "每次付费转化量",
              "每次付费转化成本",
              "游戏关键行为量",
              "游戏关键行为均",
              "deeplink回传留存量",
              "deeplink回传留存率",
              "deeplink回传留存均价",
              "dp自定义转化量",
              "dp自定义转化均价",
              "dp回传注册量,",
              "dp回传注册均价",
              "dp回传激活量",
              "dp回传激活均价",
              "dp回传拉活量",
              "dp回传拉活均价",
              "dp应用内下单量",
              "dp应用内下单均价",
              "dp应用内付费量",
              "dp应用内付费均价",
              "dp应用内授信量",
              "dp应用内授信均价",
              "快应用付费量",
              "快应用付费均价",
              "快应用关键行为量",
              "快应用关键行为均价",
              "快应用加桌量",
              "快应用加桌均价",
              "微信小游戏首次付费人数",
              "微信小游戏付费总额",
              "微信小游戏付费量",
              "微信小游戏付费均价",
              "微信小游戏注册量",
              "微信小游戏注册均价",
              "微信小游戏ROI1",
              "微信关注量",
              "微信关注均价",
              "应用付费金额",
              "应用ROI",
              "应用付费LTV1",
              "应用付费ROI1",
            ],
          },
          {
            name: "关键词",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "关键词",
              "应用ID",
              "应用名称",
              "广告计划ID",
              "广告计划名称",
              "广告组ID",
              "广告组名称",
              "广告ID",
              "广告名称",
              "推广目的",
              "推广流量",
              "流量场景",
              "消耗",
              "ECPM",
              "曝光量",
              "点击量",
              "点击率",
              "点击均价",
              "下载量",
              "下载率",
              "下载均价",
              "统一规格ID",
              "结果页曝光量",
              "结果页广告位序号总和",
              "竞价排名",
            ],
          },
          {
            name: "广告-基础信息",
            list: [
              "计划id",
              "计划名称",
              "广告组ID",
              "广告组名称",
              "广告ID",
              "广告名称",
              "状态",
              "投放阶段",
              "基础出价",
              "转化出价",
              "转化目标",
              "深度转化出价",
              "深度转化目标",
              "推广目标",
              "推广流量",
              "流量场景",
              "创意规格",
              "创意类型",
            ],
          },
        ],
        4: [
          {
            name: "账户",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "每次付费数",
              "每次付费数(J)",
              "每次付费金额",
              "每次付费金额(J)",
              "游戏付费数",
              "游戏付费数(J)",
              "游戏注册量",
              "游戏注册量(J)",
              "游戏预约数",
              "游戏预约数(J)",
              "游戏首日付费数",
              "首日回收金额-充值付费(J)",
              "累计回收金额-充值付费(J)",
              "首日回收金额-广告变现(J)",
              "累计回收金额-广告变现(J)",
              "安装完成数",
              "安装完成数(J)",
              "自定义注册量",
              "自定义注册量(J)",
              "自定义拉活",
              "自定义拉活(J)",
              "自定义次留数",
              "自定义次留数(J)",
              "拉活自定义次留数",
              "拉活自定义次留数(J)",
              "自定义付费数",
              "自定义付费数(J)",
              "自定义授信数",
              "自定义授信数(J)",
              "自定义激活数",
              "自定义激活数(J)",
              "新增激活数",
              "新增激活数(J)",
              "普通激活数",
              "普通激活数(J)",
              "目标用户量",
              "目标用户量(J)",
              "个性化事件数",
              "个性化事件数(J)",
              "快应用付费数",
              "快应用付费数(J)",
              "微信-添加微信数",
              "微信-识别二维码数",
              "微信-用户首次消息数",
              "微信小游戏付费数",
              "微信小游戏付费数(J)",
              "微信小游戏注册数",
              "微信小游戏注册数(J)",
              "曝光量",
              "点击量",
              "加桌数",
              "加桌数(J)",
              "表单提交量",
              "下载数",
              "下载数(J)",
              "有效咨询数",
              "日历预约数(J)",
              "按钮点击量",
              "消耗",
              "网页购买",
              "ECPM",
              "点击均价",
              "下载率",
              "下载均价",
              "自定义激活率",
              "自定义激活均价",
              "自定义次留率",
              "自定义次留均价",
              "自定义次留率(J)",
              "自定义次留均价(J)",
              "自定义激活率(J)",
              "自定义激活均价(J)",
              "自定义拉活均价",
              "自定义注册均价",
              "自定义付费均价",
              "点击率",
              "应用付费次数",
              "应用付费次数(J)",
              "应用付费金额",
              "应用付费金额(J)",
            ],
          },
          {
            name: "广告计划",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "广告计划ID",
              "广告计划名称",
              "计划类型",
              "每次付费数",
              "每次付费数(J)",
              "每次付费金额",
              "每次付费金额(J)",
              "游戏付费数",
              "游戏付费数(J)",
              "游戏注册量",
              "游戏注册量(J)",
              "游戏预约数",
              "游戏预约数(J)",
              "游戏首日付费数",
              "首日回收金额-充值付费(J)",
              "累计回收金额-充值付费(J)",
              "首日回收金额-广告变现(J)",
              "累计回收金额-广告变现(J)",
              "安装完成数",
              "安装完成数(J)",
              "自定义注册量",
              "自定义注册量(J)",
              "自定义拉活",
              "自定义拉活(J)",
              "自定义次留数",
              "自定义次留数(J)",
              "拉活自定义次留数",
              "拉活自定义次留数(J)",
              "自定义付费数",
              "自定义付费数(J)",
              "自定义授信数",
              "自定义授信数(J)",
              "自定义激活数",
              "自定义激活数(J)",
              "新增激活数",
              "新增激活数(J)",
              "普通激活数",
              "普通激活数(J)",
              "目标用户量",
              "目标用户量(J)",
              "个性化事件数",
              "个性化事件数(J)",
              "快应用付费数",
              "快应用付费数(J)",
              "微信-添加微信数",
              "微信-识别二维码数",
              "微信-用户首次消息数",
              "微信小游戏付费数",
              "微信小游戏付费数(J)",
              "微信小游戏注册数",
              "微信小游戏注册数(J)",
              "曝光量",
              "点击量",
              "加桌数",
              "加桌数(J)",
              "表单提交量",
              "下载数",
              "下载数(J)",
              "有效咨询数",
              "日历预约数(J)",
              "按钮点击量",
              "消耗",
              "网页购买",
              "ECPM",
              "点击均价",
              "下载率",
              "下载均价",
              "自定义激活率",
              "自定义激活均价",
              "自定义次留率",
              "自定义次留均价",
              "自定义次留率(J)",
              "自定义次留均价(J)",
              "自定义激活率(J)",
              "自定义激活均价(J)",
              "自定义拉活均价",
              "自定义注册均价",
              "自定义付费均价",
              "点击率",
              "应用付费次数",
              "应用付费次数(J)",
              "应用付费金额",
              "应用付费金额(J)",
            ],
          },
          {
            name: "广告组",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "广告计划ID",
              "广告计划名称",
              "广告组ID",
              "广告组名称",
              "包名",
              "转化类型",
              "计划类型",
              "每次付费数",
              "每次付费数(J)",
              "每次付费金额",
              "每次付费金额(J)",
              "游戏付费数",
              "游戏付费数(J)",
              "游戏注册量",
              "游戏注册量(J)",
              "游戏预约数",
              "游戏预约数(J)",
              "游戏首日付费数",
              "首日回收金额-充值付费(J)",
              "累计回收金额-充值付费(J)",
              "首日回收金额-广告变现(J)",
              "累计回收金额-广告变现(J)",
              "安装完成数",
              "安装完成数(J)",
              "自定义注册量",
              "自定义注册量(J)",
              "自定义拉活",
              "自定义拉活(J)",
              "自定义次留数",
              "自定义次留数(J)",
              "拉活自定义次留数",
              "拉活自定义次留数(J)",
              "自定义付费数",
              "自定义付费数(J)",
              "自定义授信数",
              "自定义授信数(J)",
              "自定义激活数",
              "自定义激活数(J)",
              "新增激活数",
              "新增激活数(J)",
              "普通激活数",
              "普通激活数(J)",
              "目标用户量",
              "目标用户量(J)",
              "个性化事件数",
              "个性化事件数(J)",
              "快应用付费数",
              "快应用付费数(J)",
              "微信-添加微信数",
              "微信-识别二维码数",
              "微信-用户首次消息数",
              "微信小游戏付费数",
              "微信小游戏付费数(J)",
              "微信小游戏注册数",
              "微信小游戏注册数(J)",
              "曝光量",
              "点击量",
              "加桌数",
              "加桌数(J)",
              "表单提交量",
              "下载数",
              "下载数(J)",
              "有效咨询数",
              "日历预约数(J)",
              "按钮点击量",
              "消耗",
              "网页购买",
              "ECPM",
              "点击均价",
              "下载率",
              "下载均价",
              "自定义激活率",
              "自定义激活均价",
              "自定义次留率",
              "自定义次留均价",
              "自定义次留率(J)",
              "自定义次留均价(J)",
              "自定义激活率(J)",
              "自定义激活均价(J)",
              "自定义拉活均价",
              "自定义注册均价",
              "自定义付费均价",
              "点击率",
              "应用付费次数",
              "应用付费次数(J)",
              "应用付费金额",
              "应用付费金额(J)",
            ],
          },
          {
            name: "广告",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "广告计划ID",
              "广告计划名称",
              "广告组ID",
              "广告组名称",
              "广告ID",
              "广告名称",
              "包名",
              "转化类型",
              "计划类型",
              "每次付费数",
              "每次付费数(J)",
              "每次付费金额",
              "每次付费金额(J)",
              "游戏付费数",
              "游戏付费数(J)",
              "游戏注册量",
              "游戏注册量(J)",
              "游戏预约数",
              "游戏预约数(J)",
              "游戏首日付费数",
              "首日回收金额-充值付费(J)",
              "累计回收金额-充值付费(J)",
              "首日回收金额-广告变现(J)",
              "累计回收金额-广告变现(J)",
              "安装完成数",
              "安装完成数(J)",
              "自定义注册量",
              "自定义注册量(J)",
              "自定义拉活",
              "自定义拉活(J)",
              "自定义次留数",
              "自定义次留数(J)",
              "拉活自定义次留数",
              "拉活自定义次留数(J)",
              "自定义付费数",
              "自定义付费数(J)",
              "自定义授信数",
              "自定义授信数(J)",
              "自定义激活数",
              "自定义激活数(J)",
              "新增激活数",
              "新增激活数(J)",
              "普通激活数",
              "普通激活数(J)",
              "目标用户量",
              "目标用户量(J)",
              "个性化事件数",
              "个性化事件数(J)",
              "快应用付费数",
              "快应用付费数(J)",
              "微信-添加微信数",
              "微信-识别二维码数",
              "微信-用户首次消息数",
              "微信小游戏付费数",
              "微信小游戏付费数(J)",
              "微信小游戏注册数",
              "微信小游戏注册数(J)",
              "曝光量",
              "点击量",
              "加桌数",
              "加桌数(J)",
              "表单提交量",
              "下载数",
              "下载数(J)",
              "有效咨询数",
              "日历预约数(J)",
              "按钮点击量",
              "消耗",
              "网页购买",
              "ECPM",
              "点击均价",
              "下载率",
              "下载均价",
              "自定义激活率",
              "自定义激活均价",
              "自定义次留率",
              "自定义次留均价",
              "自定义次留率(J)",
              "自定义次留均价(J)",
              "自定义激活率(J)",
              "自定义激活均价(J)",
              "自定义拉活均价",
              "自定义注册均价",
              "自定义付费均价",
              "点击率",
              "应用付费次数",
              "应用付费次数(J)",
              "应用付费金额",
              "应用付费金额(J)",
            ],
          },
          {
            name: "创意",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "广告计划ID",
              "广告计划名称",
              "广告组ID",
              "广告组名称",
              "广告ID",
              "广告名称",
              "创意ID",
              "包名",
              "广告位类型",
              "转化类型",
              "计划类型",
              "每次付费数",
              "每次付费数(J)",
              "每次付费金额",
              "每次付费金额(J)",
              "游戏付费数",
              "游戏付费数(J)",
              "游戏注册量",
              "游戏注册量(J)",
              "游戏预约数",
              "游戏预约数(J)",
              "游戏首日付费数",
              "首日回收金额-充值付费(J)",
              "累计回收金额-充值付费(J)",
              "首日回收金额-广告变现(J)",
              "累计回收金额-广告变现(J)",
              "安装完成数",
              "安装完成数(J)",
              "自定义注册量",
              "自定义注册量(J)",
              "自定义拉活",
              "自定义拉活(J)",
              "自定义次留数",
              "自定义次留数(J)",
              "拉活自定义次留数",
              "拉活自定义次留数(J)",
              "自定义付费数",
              "自定义付费数(J)",
              "自定义授信数",
              "自定义授信数(J)",
              "自定义激活数",
              "自定义激活数(J)",
              "新增激活数",
              "新增激活数(J)",
              "普通激活数",
              "普通激活数(J)",
              "目标用户量",
              "目标用户量(J)",
              "个性化事件数",
              "个性化事件数(J)",
              "快应用付费数",
              "快应用付费数(J)",
              "微信-添加微信数",
              "微信-识别二维码数",
              "微信-用户首次消息数",
              "微信小游戏付费数",
              "微信小游戏付费数(J)",
              "微信小游戏注册数",
              "微信小游戏注册数(J)",
              "曝光量",
              "点击量",
              "加桌数",
              "加桌数(J)",
              "表单提交量",
              "下载数",
              "下载数(J)",
              "有效咨询数",
              "日历预约数(J)",
              "按钮点击量",
              "消耗",
              "网页购买",
              "ECPM",
              "点击均价",
              "下载率",
              "下载均价",
              "自定义激活率",
              "自定义激活均价",
              "自定义次留率",
              "自定义次留均价",
              "自定义次留率(J)",
              "自定义次留均价(J)",
              "自定义激活率(J)",
              "自定义激活均价(J)",
              "自定义拉活均价",
              "自定义注册均价",
              "自定义付费均价",
              "点击率",
              "应用付费次数",
              "应用付费次数(J)",
              "应用付费金额",
              "应用付费金额(J)",
            ],
          },
          {
            name: "关键词",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "广告计划ID",
              "广告计划名称",
              "广告组ID",
              "广告组名称",
              "广告ID",
              "广告名称",
              "创意ID",
              "应用名称",
              "消耗",
              "CPD",
              "CPM",
              "曝光量",
              "下载量",
              "下载率",
              "提词",
              "提词类型",
              "匹配方式",
            ],
          },
        ],
        5: [
          {
            name: "广告计划",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "消耗",
              "自定义新增激活留存率",
              "自定义新增激活量",
              "新增激活量",
              "自定义激活留存率",
              "自定义激活量",
              "激活量",
              "加购量",
              "点击加桌率",
              "加桌均价",
              "加桌量",
              "收藏量",
              "APP购买量",
              "预约量",
              "web购买均价",
              "表单均价",
              "现金消耗",
              "点击自定义新增激活率",
              "点击新增激活率",
              "点击自定义激活率",
              "点击激活率",
              "点击转化率",
              "点击安装完成率",
              "点击付费率",
              "点击首次拉活率",
              "点击拉活率",
              "点击自定义注册率",
              "点击自定义留存率",
              "点击量",
              "下单量",
              "自定义激活均价",
              "自定义新增激活均价",
              "新增激活均价",
              "激活均价",
              "收藏均价",
              "APP购买均价",
              "预约均价",
              "下载均价",
              "安装完成均价",
              "页面停留均价",
              "付费均价",
              "首次拉活均价",
              "拉活均价",
              "自定义注册均价",
              "自定义留存均价",
              "APP召回均价",
              "商详页浏览均价",
              "APP调起均价",
              "点击均价",
              "点击率",
              "下载自定义新增激活率",
              "下载新增激活率",
              "下载自定义激活率",
              "下载激活率",
              "下载安装率",
              "下载付费率",
              "下载首次拉活率",
              "下载拉活率",
              "下载自定义注册率",
              "下载自定义留存率",
              "下载召回率",
              "下载率",
              "下载量",
              "ECPM",
              "表单提交量",
              "曝光自定义新增激活率",
              "曝光新增激活率",
              "曝光自定义激活率",
              "曝光激活率",
              "曝光安装完成率",
              "曝光付费率",
              "曝光首次拉活率",
              "曝光拉活率",
              "曝光自定义注册率",
              "曝光自定义留存率",
              "安装完成量",
              "页面停留量",
              "付费量",
              "web购买量",
              "首次拉活量",
              "拉活自定义留存率",
              "拉活量",
              "自定义注册量",
              "自定义留存量",
              "状态",
              "APP召回量",
              "商详页浏览量",
              "曝光转化率",
              "曝光量",
              "虚拟金消耗",
              "APP调起量",
              "web加购量",
              "web下单量",
              "关键行为数",
              "关键行为成本",
              "关键行为点击率",
              "关键行为下载率",
              "计划类型",
              "IPVUV数",
              "IPVUV率",
              "IPVUV成本",
              "首单购买均价",
              "24小时变现金额",
              "广告计划ID",
              "广告计划名称",
              "首单购买量",
              "完件量",
              "完件均价",
              "首次付费总数",
              "24小时变现金额(J)" ,
              "付费金额(z)"
            ],
          },
          {
            name: "广告组",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "消耗",
              "自定义新增激活留存率",
              "自定义新增激活量",
              "新增激活量",
              "自定义激活留存率",
              "自定义激活量",
              "激活量",
              "加购量",
              "点击加桌率",
              "加桌均价",
              "加桌量",
              "收藏量",
              "APP购买量",
              "预约量",
              "web购买均价",
              "表单均价",
              "现金消耗",
              "点击自定义新增激活率",
              "点击新增激活率",
              "点击自定义激活率",
              "点击激活率",
              "点击转化率",
              "点击安装完成率",
              "点击付费率",
              "点击首次拉活率",
              "点击拉活率",
              "点击自定义注册率",
              "点击自定义留存率",
              "点击量",
              "下单量",
              "自定义激活均价",
              "自定义新增激活均价",
              "新增激活均价",
              "激活均价",
              "收藏均价",
              "APP购买均价",
              "预约均价",
              "下载均价",
              "安装完成均价",
              "页面停留均价",
              "付费均价",
              "首次拉活均价",
              "拉活均价",
              "自定义注册均价",
              "自定义留存均价",
              "APP召回均价",
              "商详页浏览均价",
              "APP调起均价",
              "点击均价",
              "点击率",
              "下载自定义新增激活率",
              "下载新增激活率",
              "下载自定义激活率",
              "下载激活率",
              "下载安装率",
              "下载付费率",
              "下载首次拉活率",
              "下载拉活率",
              "下载自定义注册率",
              "下载自定义留存率",
              "下载召回率",
              "下载率",
              "下载量",
              "ECPM",
              "表单提交量",
              "曝光自定义新增激活率",
              "曝光新增激活率",
              "曝光自定义激活率",
              "曝光激活率",
              "曝光安装完成率",
              "曝光付费率",
              "曝光首次拉活率",
              "曝光拉活率",
              "曝光自定义注册率",
              "曝光自定义留存率",
              "安装完成量",
              "页面停留量",
              "付费量",
              "web购买量",
              "首次拉活量",
              "拉活自定义留存率",
              "拉活量",
              "自定义注册量",
              "自定义留存量",
              "状态",
              "APP召回量",
              "商详页浏览量",
              "曝光转化率",
              "曝光量",
              "虚拟金消耗",
              "APP调起量",
              "web加购量",
              "web下单量",
              "关键行为数",
              "关键行为成本",
              "关键行为点击率",
              "关键行为下载率",
              "展示形式",
              "IPVUV数",
              "IPVUV率",
              "IPVUV成本",
              "首单购买均价",
              "24小时变现金额",
              "广告组ID",
              "广告组名称",
              "广告计划ID",
              "广告计划名称",
              "首单购买量",
              "完件量",
              "完件均价",
              "应用商店ID",
              "计划类型",
              "首次付费总数",
              "24小时变现金额(J)",
              "付费金额(z)"
            ],
          },
          {
            name: "广告",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "消耗",
              "自定义新增激活留存率",
              "自定义新增激活量",
              "新增激活量",
              "自定义激活留存率",
              "自定义激活量",
              "激活量",
              "加购量",
              "点击加桌率",
              "加桌均价",
              "加桌量",
              "收藏量",
              "APP购买量",
              "预约量",
              "web购买均价",
              "表单均价",
              "现金消耗",
              "点击自定义新增激活率",
              "点击新增激活率",
              "点击自定义激活率",
              "点击激活率",
              "点击转化率",
              "点击安装完成率",
              "点击付费率",
              "点击首次拉活率",
              "点击拉活率",
              "点击自定义注册率",
              "点击自定义留存率",
              "点击量",
              "下单量",
              "自定义激活均价",
              "自定义新增激活均价",
              "新增激活均价",
              "激活均价",
              "收藏均价",
              "APP购买均价",
              "预约均价",
              "下载均价",
              "安装完成均价",
              "页面停留均价",
              "付费均价",
              "首次拉活均价",
              "拉活均价",
              "自定义注册均价",
              "自定义留存均价",
              "APP召回均价",
              "商详页浏览均价",
              "APP调起均价",
              "点击均价",
              "点击率",
              "下载自定义新增激活率",
              "下载新增激活率",
              "下载自定义激活率",
              "下载激活率",
              "下载安装率",
              "下载付费率",
              "下载首次拉活率",
              "下载拉活率",
              "下载自定义注册率",
              "下载自定义留存率",
              "下载召回率",
              "下载率",
              "下载量",
              "ECPM",
              "表单提交量",
              "曝光自定义新增激活率",
              "曝光新增激活率",
              "曝光自定义激活率",
              "曝光激活率",
              "曝光安装完成率",
              "曝光付费率",
              "曝光首次拉活率",
              "曝光拉活率",
              "曝光自定义注册率",
              "曝光自定义留存率",
              "安装完成量",
              "页面停留量",
              "付费量",
              "web购买量",
              "首次拉活量",
              "拉活自定义留存率",
              "拉活量",
              "自定义注册量",
              "自定义留存量",
              "状态",
              "APP召回量",
              "商详页浏览量",
              "曝光转化率",
              "曝光量",
              "虚拟金消耗",
              "APP调起量",
              "web加购量",
              "web下单量",
              "RTAToken",
              "渠道包",
              "深度转化目标",
              "关键行为数",
              "关键行为成本",
              "关键行为点击率",
              "关键行为下载率",
              "广告位",
              "出价类型",
              "出价",
              "IPVUV数",
              "IPVUV率",
              "IPVUV成本",
              "首单购买均价",
              "24小时变现金额",
              "广告计划ID",
              "广告计划名称",
              "广告组ID",
              "广告组名称",
              "广告创意ID",
              "广告创意名称",
              "首单购买量",
              "完件量",
              "完件均价",
              "应用商店ID",
              "计划类型",
              "展示形式",
              "首次付费总数",
              "24小时变现金额(J)",
              "付费金额(z)"
            ],
          },
          {
            name: "关键词",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "创意ID",
              "广告ID",
              "消耗",
              "下载单价",
              "游戏支付率",
              "游戏ROI",
              "创意类型",
              "搜索词",
              "搜索词类型",
              "下载量",
              "素材类型",
            ],
          },
        ],
        6: [
          {
            name: "任务",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "推广任务ID",
              "任务投放区域",
              "渠道号",
              "消耗",
              "展示量",
              "点击量",
              "点击率",
              "下载量",
              "下载率",
              "下载均价",
              "物理渠道包ID",
              "应用激活量",
              "应用激活率",
              "应用激活均价",
              "启动应用量",
              "启动应用率",
              "启动应用均价",
              "次日留存量",
              "次日留存率",
              "次日留存均价",
              "付费量",
              "付费率",
              "付费均价",
              "提交表单量",
              "提交表单率",
              "提交表单均价",
              "授权量",
              "授权率",
              "授权均价",
              "注册量",
              "注册率",
              "注册均价",
              "关键页面访问量",
              "关键页面访问率",
              "关键页面访问均价",
              "申请量",
              "申请率",
              "申请均价",
              "下单量",
              "下单率",
              "下单均价",
              "预约量",
              "预约率",
              "预约均价",
              "关键行为1量",
              "关键行为1率",
              "关键行为1均价",
              "关键行为2量",
              "关键行为2率",
              "关键行为2均价",
              "任务名称",
              "任务状态",
              "应用ID",
              "应用名称",
              "任务计费模式",
              "投放模式",
              "任务类型",
              "任务出价",
              "任务日预算",
            ],
          },
          {
            name: "子任务",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "推广任务ID",
              "推广子任务ID",
              "任务投放区域",
              "渠道号",
              "消耗",
              "展示量",
              "点击量",
              "点击率",
              "下载量",
              "下载率",
              "下载均价",
              "应用激活量",
              "应用激活率",
              "应用激活均价",
              "启动应用量",
              "启动应用率",
              "启动应用均价",
              "次日留存量",
              "次日留存率",
              "次日留存均价",
              "付费量",
              "付费率",
              "付费均价",
              "提交表单量",
              "提交表单率",
              "提交表单均价",
              "授权量",
              "授权率",
              "授权均价",
              "注册量",
              "注册率",
              "注册均价",
              "关键页面访问量",
              "关键页面访问率",
              "关键页面访问均价",
              "申请量",
              "申请率",
              "申请均价",
              "下单量",
              "下单率",
              "下单均价",
              "预约量",
              "预约率",
              "预约均价",
              "关键行为1量",
              "关键行为1率",
              "关键行为1均价",
              "关键行为2量",
              "关键行为2率",
              "关键行为2均价",
              "任务名称",
              "任务状态",
              "应用ID",
              "应用名称",
              "任务计费模式",
              "投放模式",
              "任务类型",
              "任务出价",
              "任务日预算",
              "子任务名称",
              "子任务出价",
              "子任务计费模式",
              "子任务搜索词",
              "子任务搜索词匹配方式",
            ],
          },
          {
            name: "关键词",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "任务ID",
              "子任务ID",
              "任务投放区域",
              "搜索词",
              "投放词",
              "匹配方式",
              "消耗",
              "展示量",
              "下载量",
              "下载率",
              "下载均价",
              "搜索排名",
              "任务名称",
              "任务状态",
              "应用ID",
              "应用名称",
              "任务计费模式",
              "投放模式",
              "任务类型",
              "任务出价",
              "任务日预算",
              "子任务名称",
              "子任务出价",
              "子任务计费模式",
            ],
          },
        ],
        7: [
          {
            name: "账户",
            list: [
              "日期",
              "时段",
              "广告主ID",
              "广告主名称",
              "余额",
              "消耗",
              "曝光量",
              "下载量",
              "安装量",
              "激活量",
            ],
          }
        ],
        8: [
          {
            name: "账户",
            list: [
              "日期",
              "时段",
              "广告系列组ID",
              "广告系列组名称",
              "国家地区",
              "余额",
              "支出",
              "曝光次数",
              "点击次数",
              "下载次数",
              "新下载",
              "重新下载",
              "开启LAT的安装数",
              "关闭LAT的安装数",
              "点击率",
              "下载率",
              "ECPM均价",
              "平均CPT",
              "平均CPA",
              "货币",
            ],
          },
          {
            name: "广告系列",
            list: [
              "日期",
              "时段",
              "广告系列组ID",
              "广告系列组名称",
              "国家地区",
              // "开始日期",
              // "结束日期",
              // "广告系列剩余天数",
              "生命周期预算",
              "广告系列ID",
              "广告系列名称",
              "广告展示位置",
              "每日预算",
              "支出",
              "曝光次数",
              "点击次数",
              "下载次数",
              "新下载",
              "点击率",
              "下载率",
              "ECPM均价",
              "平均CPT",
              "平均CPA",
              "货币",
            ],
          },
          {
            name: "广告组",
            list: [
              "日期",
              "时段",
              "广告系列组ID",
              "广告系列组名称",
              "国家地区",
              "开始日期",
              "结束日期",
              "广告系列ID",
              "广告组ID",
              "广告组名称",
              // "搜索匹配",
              "支出",
              "曝光次数",
              "点击次数",
              "下载次数",
              "新下载",
              "重新下载",
              "开启LAT的安装数",
              "关闭LAT的安装数",
              "点击率",
              "下载率",
              "ECPM均价",
              "平均CPT",
              "平均CPA",
              "货币",
            ],
          },
          {
            name: "关键词",
            list: [
              "日期",
              "时段",
              "广告系列组ID",
              "广告系列组名称",
              "国家地区",
              "广告系列ID",
              "广告组ID",
              "广告组名称",
              "关键词ID",
              "关键词名称",
              "匹配类型",
              "搜索词",
              "点击出价",
              "建议出价范围",
              "支出",
              "曝光次数",
              "点击次数",
              "下载次数",
              "新下载",
              "重新下载",
              "开启LAT的安装数",
              "关闭LAT的安装数",
              "点击率",
              "下载率",
              "平均CPT",
              "平均CPA",
              "货币",
            ],
          },
        ],
      },
      reporFormsTypeList: [
        { id: 2, name: "时报" },
        { id: 1, name: "日报" },
      ],
    };
  },

  components: {
    aiQiYiUpdate,
    editReportForms,
  },

  watch: {
    $route: {
      handler(to) {
        Object.assign(this.$data, this.$options.data());
        this.pubVue = this;
        this.channelId = to.query.channelId;
        this.reporFormsTypeIndex = to.query.type || 0;
        this.editReportFormsTime = new Date().getTime();
        this.deptId = this.$sessionStorage.getItem(config.DEPTID);
        this.userName = this.$sessionStorage.getItem(config.USERNAME);
        this.deptName = this.$sessionStorage.getItem(config.DEPTNAME);
        this.userId = this.$sessionStorage.getItem(config.USERID) || 1;
        this.channelList = this.$sessionStorage.getItem("channelTitleList");
        this.headers.Authorization = this.$sessionStorage.getItem(
          config.USER_TOKEN_KEY
        );
        this.getUserChannel();
      },
      immediate: true,
    },
  },

  methods: {
    // 获取数据列表
    getTableDataList(page) {
      let _this = this;
      _this.pageForm.index = page || 1;
      let params = {
        userId: _this.userId,
        deptId: _this.deptId,
        userName: _this.userName,
        pageSize: _this.pageForm.size,
        pageIndex: _this.pageForm.index,
        channelId: Number(_this.channelId),
        type: _this.reporFormsTypeList[_this.reporFormsTypeIndex].id,
      };
      params = { ..._this.searchForm, ...params };
      $http.axios
        .post(
          `/api/${_this.interfaceParams.interfaceFields}/reportModel/listModel`,
          params
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.tableDataList = res.data.data.infos;
            _this.pageForm.total = res.data.data.pageInfo.rowCount;
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        });
    },
    getTableDataSizeList(val){
      this.pageForm.size = val
      this.getTableDataList(this.pageForm.index)
    },
    /* channelId(渠道):
    3: OPPO
    4: VIVO
    5: 小米
    6: 华为
    8: APPLE(ASA)
    */
    // 获取用户渠道
    getUserChannel() {
      let _this = this;
      if (_this.channelList && _this.channelList.length > 0) {
        _this.authorityType = true;
        if (!_this.channelId) {
          _this.channelId = _this.channelList[0].id;
        }
        _this.fileNameList = ["日期", "时段", "编号"];
        if (_this.channelId == 8) {
          // _this.fileNameList = ["日期", "时段", "编号"];
          _this.dimensionsList = [
            { id: 1, name: "账户" },
            { id: 2, name: "广告系列" },
            { id: 3, name: "广告组" },
          ];
          _this.interfaceParams.getMenuList = "/api/apple/account/list";
          _this.interfaceParams.getAssignPersonnel =
            "/api/system/user/getAccountAppendList?deptId=328";
        } else {
          // _this.fileNameList = ["代理公司", "渠道", "报表类型", "日期", "时段"];
          _this.interfaceParams.getAssignPersonnel = `/api/system/user/getAppendUserListByChannel?channelId=${_this.channelId}`;
          if (_this.channelId == 3) {
            _this.dimensionsList = [
              { id: 1, name: "账户" },
              { id: 2, name: "广告计划" },
              { id: 3, name: "广告组" },
              { id: 4, name: "广告" },
              { id: 14, name: "广告-基础信息" },             
            ];
            _this.interfaceParams.getMenuList =
              "/api/channel/accountUser/selectList";
          } else if (_this.channelId == 4) {
            _this.dimensionsList = [
              { id: 1, name: "账户" },
              { id: 2, name: "广告计划" },
              { id: 3, name: "广告组" },
              { id: 4, name: "广告" },
              { id: 5, name: "创意" },
            ];
            _this.interfaceParams.getMenuList =
              "/api/viat/accountUser/selectList";
          } else if (_this.channelId == 5) {
            _this.dimensionsList = [
              { id: 2, name: "广告计划" },
              { id: 3, name: "广告组" },
              { id: 4, name: "广告" },
            ];
            _this.interfaceParams.getMenuList =
              "/api/xmat/accountUser/selectList";
          } else if (_this.channelId == 6) {
            _this.dimensionsList = [
              { id: 2, name: "任务" },
              { id: 3, name: "子任务" },
            ];
            _this.interfaceParams.getMenuList =
              "/api/hwat/accountUser/selectList";
          } else if (_this.channelId == 7) {
            _this.dimensionsList = [
              { id: 1, name: "账户" },
            ];
            _this.interfaceParams.getMenuList =
              "/api/ybat/account/list";
          } 
        }

        if (_this.channelId == 5) {
          _this.interfaceParams.interfaceFields = "dataReportMi";
        } else {
          _this.interfaceParams.interfaceFields = "dataReport";
        }
        _this.getTransferor();
        _this.getTableDataList();
      } else {
        $http.axios
          .get("/api/system/user/getChannelByUser?userId=" + _this.userId)
          .then((res) => {
            let data = res.data.data;
            if (data && data.length > 0) {
              _this.channelList = data;
              _this.$sessionStorage.setItem("channelTitleList", data);
              _this.getUserChannel();
            } else {
              _this.authorityType = false;
              _this.$message({
                type: "error",
                showClose: true,
                message: "您的操作权限不足",
              });
            }
          });
      }
    },
    // 获取转让人
    getTransferor() {
      let _this = this;
      $http.axios.get(_this.interfaceParams.getAssignPersonnel).then((res) => {
        if (res.data.code == 200) {
          _this.transferorList = res.data.data || [];
        } else {
          _this.$message({
            type: "error",
            showClose: true,
            message: res.data.msg,
          });
        }
      });
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      //利用单元格的 className 的回调方法，给行列索引赋值
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 更改渠道
    changeChannel(id) {
      if (this.channelId != id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            channelId: id,
            type: this.reporFormsTypeIndex,
          },
        });
      }
    },
    // 文件上传成功
    uploadSuccess(response) {
      let _this = this,
        keyWord = [],
        dataSource = [];
      if (response.code == 200) {
        _this.operationStatus = "create";
        _this.editReportFormsVisible = true;
        let params = {};
        if (_this.channelId == 6) {
          params.value = [];
          params.appInfor = {};
          params.appValidateList = [];
        }
        response.data.dataSource.forEach((item) => {
          params.sheetName = item;
          if (_this.channelId == 8) {
            params.timeZone = "Asia";
          }
          dataSource.push(JSON.parse(JSON.stringify(params)));
        });
        response.data.kwDataSource.forEach((item) => {
          params.sheetName = item;
          keyWord.push(JSON.parse(JSON.stringify(params)));
        });
        _this.keyWord = keyWord;
        _this.dataSource = dataSource;
        _this.filePath = response.data.path;
      } else {
        _this.$message({
          duration: 0,
          type: "error",
          showClose: true,
          message: response.msg,
        });
      }
      _this.$refs.upload.clearFiles();
    },
    // 系统使用说明
    systemInstructions() {
      let route = this.$router.resolve({
        path: "/auth-management/FaqVivo",
        query: { id: 28, channelId: this.channelId },
      });
      window.open(route.href, "_blank");
    },
    // 更改报表类型
    changeReporFormsType(index) {
      if (this.reporFormsTypeIndex != index) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            type: index,
            channelId: this.channelId,
          },
        });
      }
    },
    // 单元格
    clickcell(row, column, cell, event) {
      let yjType = event.target.parentNode.dataset.type;
      if (yjType == "xiuGai") {
        // 修改
        this.operationStatus = "modify";
        this.editReportFormsVisible = true;
        this.$refs.editReportForms.getReportFormsDetails(String(row.id));
      } else if (yjType == "xiazai") {
        // 下载
        this.downLoadFunction(row.id);
      } else if (yjType == "zhuanrang") {
        // 转让
        this.assignmentFunction(row.id);
      } else if (yjType == "bufa") {
        // 补发
        this.reissueFunction(row);
      } else if (yjType == "delBtn") {
        // 删除
        this.deleteFunction(row.id);
      }
    },
    // 开关
    switchFunction(item, name) {
      let _this = this;
      let params = {
        id: item.id,
        status: item.status,
        userId: _this.userId,
        deptId: _this.deptId,
        userName: _this.userName,
      };
      params[name] = item[name];
      $http.axios
        .post(
          `/api/${_this.interfaceParams.interfaceFields}/reportModel/edit`,
          params
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.getTableDataList();
          } else {
            _this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
            item[name] = item[name] == 1 ? 0 : 1;
          }
        })
        .catch(() => {
          item[name] = item[name] == 1 ? 0 : 1;
        });
    },
    // 重发
    retransmissionFunction(type, id) {
      let _this = this;
      let params = {
        ids: id,
        type: type,
        userId: _this.userId,
        deptId: _this.deptId,
        userName: _this.userName,
      };
      $http.axios
        .post(
          `/api/${_this.interfaceParams.interfaceFields}/reportSend/rePush`,
          params
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.$message({
              type: "success",
              showClose: true,
              message: "已重新发送，请注意查收",
            });
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: res.data.msg,
            });
          }
        });
    },
    //下载
    downLoadFunction(id) {
      let _this = this;
      $http.axios
        .get(
          `/api/${_this.interfaceParams.interfaceFields}/reportModel/queryImgUrlById?id=${id}`
        )
        .then((res) => {
          if (res.data.code == 200) {
            window.location.href = res.data.data;
            _this.$message({
              type: "success",
              showClose: true,
              message: res.data.msg,
            });
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: res.data.msg,
            });
          }
        });
    },
    // 转让
    assignmentFunction(id) {
      this.dataId = id;
      this.assignmentInfor = {};
      this.assignmentVisible = true;
    },
    // 转让弹框提交
    assignmentSubmit() {
      let _this = this;
      let params = {
        id: _this.dataId,
        userId: _this.userId,
        deptId: _this.deptId,
        userName: _this.userName,
        newUserId: _this.assignmentInfor.userId,
        newDeptId: _this.assignmentInfor.deptId,
      };
      $http.axios
        .post(
          `/api/${_this.interfaceParams.interfaceFields}/reportModel/edit`,
          params
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.getTableDataList();
            _this.assignmentVisible = false;
            _this.$message({
              type: "success",
              showClose: true,
              message: res.data.msg,
            });
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: res.data.msg,
            });
          }
        });
    },
    // 补发
    reissueFunction(item) {
      if (item.status !== 0) {
        this.$alert(
          `<div><img class="icon" src="${require("@/assets/img/warningIcon.png")}" /><div class="text_tip">请确认操作的模板状态为已开启！</div></div>`,
          "提醒",
          {
            center: true,
            dangerouslyUseHTMLString: true,
            customClass: "icon_custom_popup",
            beforeClose: (action, instance, done) => {
              done();
            },
          }
        )
          .then(() => {})
          .catch(() => {});
        return;
      }
      this.dataId = item.id;
      this.reissueVisible = true;
      let currentDate = new Date();
      if (this.reporFormsTypeList[this.reporFormsTypeIndex].id == 2) {
        this.reissueDate = [formatDate(currentDate), formatDate(currentDate)];
      } else {
        this.reissueDate = [
          formatDate(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate() - 1
            )
          ),
          formatDate(
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate() - 1
            )
          ),
        ];
      }
    },
    // 补发提交
    reissueSubmit() {
      let _this = this;
      $http.axios
        .get(
          `/api/${_this.interfaceParams.interfaceFields}/reportSend/manualReport?ids=${_this.dataId}&sDate=${_this.reissueDate[0]}&eDate=${_this.reissueDate[1]}`
        )
        .then((res) => {
          if (res.data.code == 200) {
            _this.reissueVisible = false;
            _this.$message({
              type: "success",
              showClose: true,
              message: res.data.msg,
            });
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: res.data.msg,
            });
          }
        });
    },
    // 删除
    deleteFunction(id) {
      let _this = this;
      this.$alert(
        `<div><img class="icon" src="${require("@/assets/img/deleteIcon.png")}" /><div class="text_tip">您是否确认删除报表模板，删除后数据源中数据将不再累加，且无法恢复？</div></div>`,
        "删除",
        {
          center: true,
          showCancelButton: true,
          dangerouslyUseHTMLString: true,
          customClass: "icon_custom_popup",
          beforeClose: (action, instance, done) => {
            if (action == "confirm") {
              instance.confirmButtonLoading = true;
              let params = {
                id: id,
                status: 2,
                userName: _this.userName,
                userId: _this.userId,
                deptId: _this.deptId,
              };

              $http.axios
                .post(
                  `/api/${_this.interfaceParams.interfaceFields}/reportModel/edit`,
                  params
                )
                .then((res) => {
                  if (res.data.code == 200) {
                    _this.getTableDataList();
                    done();
                    instance.confirmButtonLoading = false;
                  } else {
                    _this.$message({
                      showClose: true,
                      message: res.data.msg,
                      type: "error",
                    });
                    instance.confirmButtonLoading = false;
                  }
                })
                .catch(() => {
                  instance.confirmButtonLoading = false;
                });
            } else {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        }
      )
        .then(() => {})
        .catch(() => {});
    },
    // 重置
    resetForm() {
      Object.assign(this.$data.searchForm, this.$options.data().searchForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  overflow: visible;
}
</style>
