<template>
    <div class="authority common-page">
      <!-- <planHeader :breakTitle="breakTitle"></planHeader> -->
       <PocCardTable>
         <template slot="headerTitle">
            <div class="materialHead">
              <div class="materialHBtns clearfix">
                <el-form :inline="true" ref="" class="demo-form-inline fL">
                  <el-form-item label="状态">
                    <el-select v-model="status" placeholder="请选择状态">
                      <el-option label="未执行" value="0"></el-option>
                      <el-option label="执行中" value="1"></el-option>
                      <el-option label="执行完成" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="">
                    <div class="block">
                      <el-date-picker
                      v-model="value1"
                      type="daterange"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker>
                    </div>
                  </el-form-item>
                  <el-form-item label="">
                    <el-button type="primary" class="newQuery" size="small" @click = "getAccountPlanListFn(pageNum,status,value1)">查询</el-button>
                    <el-button type="info" size="small" @click = 'resetForm'>重置</el-button>
                  </el-form-item>
                </el-form> 
              </div>
              <!-- <div class="MBOTTOM">
                <el-button type="primary" size="small" @click = "getAccountPlanListFn(pageNum,status,value1)">查询</el-button>
                <el-button type="info" size="small" plain @click = 'resetForm'>重置</el-button>
              </div> -->
            </div>
           
            <div class="materiaContent">
              <el-table
                ref="multipleTable"
                :data="tableData"
                @cell-click="clickcell"
                style=" cursor:pointer;"
                :cell-style="setRowStyle"
                >
                <el-table-column
                :key="index"
                :prop="item.prop"
                :label="item.name"
                v-for="(item, index) in titleList"
                :align="item.align || 'center'"
                :show-overflow-tooltip="item.overHidden || true">
                <template slot-scope="scope">
                          <!-- // 这里通过插槽实现自定义列 -->                  
                  <div v-if='item.prop=="errorMessage"'>
                    <span v-if="scope.row.errorMessage==null&&scope.row.status ==2">上传成功</span>
                    <span v-else-if="scope.row.errorMessage==null&&scope.row.status ==1">上传中</span>
                    <span v-else-if="scope.row.errorMessage==null&&scope.row.status ==0">未上传</span>
                    <el-tooltip v-else class="item" effect="dark"  placement="right-end">
                      <div slot="content" v-html="scope.row.errorMessage"></div>   
                      <i class="el-icon-warning-outline"></i>
                      <!-- <span>{{scope.row.errorMessage}}</span> -->
                    </el-tooltip>

                  </div>
                  <span v-else>{{ scope.row[scope.column.property] }}</span>
                </template>
              </el-table-column>
              </el-table>
            </div>
            <div class="clearfix">
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleSizeChange"
                  :current-page="pageNum"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.totalRow">
              </el-pagination>
              </div>
            </div>
         </template>
      </PocCardTable>

    </div>
  </template>
  
  <script>

  import Api from "@/api";
  import { $http } from '@/api/http';
  import config from '@/api/config';
  export default {
    props:{
      tableData:Array,
      newVue:Object,
    },
    data() {
      return {
        errorMessage:'可以输入一个IP地址，多个IP地址，多个IP范围或网段,<br>多个项之间用“，”分隔，输入格式为：<br>',
        pubVue: this,//空vue，用于子组件之间传值
        planType:20,
        // userContent:{},//登录用户信息&弹框默认
        value1: [],//时间
        tableOption:[],
        status:'',
        titleList:[{name:'创建者',prop:'createrName'},
        {name:'创建时间',prop:'createTime'},
        {name:'状态',prop:'statusName'},//0-未执行 1-执行中  2-执行完成
        {name:'源文件',prop:'filePathName'},
        {name:'错误文件',prop:'errorFilePathName'},
        {name:'执行结果',prop:'errorMessage'},
        ],
        showStatusList:{
                        status2:[''],
                        startSwitch:['是','否'],
                        warningSwitch:['开启','关闭','未设置']},
      
        show:false,
        options: [{
          value: '1',
          label: '全部导出'
        }, {
          value: '2',
          label: '全部删除'
        }, ], //批量操作
        value:'',//批量操作绑定值
        // 分页
        pageNum: 1,//当前页
        pageSize: 10,//每页大小
        totalRow: this.newVue.total,//总条数
        totalPage: 0,//总页数
        dataList:'',
        userId:'',//用户ID
        userName:'',//用户名
        deptId:'',//

      }
    },
    computed: {
       
    },
    mounted() {
      this.userId = this.$sessionStorage.getItem(config.USERID) || 1
      this.userName = this.$sessionStorage.getItem(config.USERNAME)
      this.deptId = this.$sessionStorage.getItem(config.DEPTID)
      console.log(this.tableData)
     
    },
    
    methods: {
      getAccountPlanListFn(pageNum,status,value1){
        this.newVue.getUploadListFn(30,pageNum,status,value1)
      },
       //重置
       resetForm(){
        let _this = this
        _this.planId = ''
        _this.planName = ''
        _this.channel = ''
        _this.channeln = ''
        _this.value1 = ''
      },
      handleSizeChange(val) {
        let _this = this
        console.log(666)
        // _this.$set(_this.params,'page',val)
        _this.findAll(val)
      },
      // handleCurrentChange(val) {
      //   this.page = val
      //   var params = {
      //     page: this.page,
      //     pageCount: this.pageCount
      //   }
      //   this.findAll(params)
      // },
      //分页方法
      findAll (pageNum) {
        //获取数据接口（调用封装的接口）
        this.getAccountPlanListFn(pageNum,this.status,this.value1)
      },
      clickcell(row, column, cell, event){
        console.log(row);
        console.log(column);
        console.log(cell);
        console.log(event.target);
        console.log(event.target.parentNode.dataset.type)
        if(column.property == "filePathName"){
          if(row.filePathName != "--"){
            this.newVue.dowloadFileFn(1,row.opertaType,row.filePath)
          }
        }else if(column.property == "errorFilePathName"){
          if(row.errorFilePathName != "--"){
            this.newVue.dowloadFileFn(2,row.opertaType,row.errorFilePath)
          }
        }
      }, 
      setRowStyle(row, column, rowIndex, columnIndex) {
        if(row.columnIndex == 3){
          return 'color: #6495ed' 
        }else if(row.columnIndex == 4){
          return 'color: #6495ed' 
        }else{
          return 'color: #606266' 
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    
    .el-icon-warning-outline{
      color:red
    }
     /*新版*/
   ::v-deep .materialHead .el-input--mini .el-input__inner{
    width:160px;
    height:32px;
    line-height:32px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-dialog{
    border-radius:20px;
  }
  .dialogIcon{
    position: absolute;
    top:0;
    left:50%;
    margin-left:-35px;
    margin-top:-35px;
  }
  ::v-deep .el-dialog .el-dialog__header{
    padding-top:45px;
    font-weight:500;
  }
  ::v-deep .el-dialog__header.el-dialog__title{
    font-size:18px;
    font-weight:500;
  }
  ::v-deep .distribDialog .el-dialog--center .el-dialog__body{
    padding:0 40px;
  }
  ::v-deep .distribDialog .el-form-item--mini.el-form-item{
    width: 100%;
    padding:7px 0;
    background: #F5F7F6;
    border-radius: 4px;
    margin-bottom:10px;
  }
  ::v-deep .el-dialog--center .el-dialog__footer{
    padding:0 16px;
  }
  ::v-deep .distribDialog .el-input--mini .el-input__inner{
    width:240px;
    background:#F5F7F6;
    border:none;
  }
  ::v-deep .distribDialog .el-input__suffix{
    right:65px;
  }
  ::v-deep .disabledChannel.el-input.is-disabled .el-input__inner{
    color:#333;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    padding:0 40px!important;
    margin:0 0 30px;
  }
  .warnInfosList{
    color:#2F674D;
    text-align:left;
    padding-left:20px
  }
  .warnInfosList span{
    font-weight:500;
    padding-right:5px;
  }
  .warnningForm .el-form-item--mini.el-form-item{
    margin-bottom:10px;
    width:100%;
    background: #F5F7F6;
    border-radius: 4px;
    padding:7px 0;
  }
  ::v-deep .observationTarget .el-select,::v-deep .otherItemPeople .el-input{
    width:420px;
  }
  ::v-deep .observationTarget{
    margin-bottom:-10px!important;
    padding-bottom:10px!important;
  }
  .deleteBtn{
    position: absolute;
    top:-10px;
    right:-10px;
    cursor: pointer;
  }
  .deleteBtnicon{
    color:#f12323;
    font-size:20px;
  }
  .addIputs{
    padding:5px;
    border:1px dashed #DCEEE8;
    border-radius:4px;
    margin-bottom:10px;
  }
  .addIputs .el-form-item--mini.el-form-item{
    margin-bottom:0!important;
  }
  .addConditions{
    width:100%;
    height: 27px;
    line-height:27px;
    text-align:center;
    border-radius: 4px;
    border: 1px dashed #DCEEE8;
    font-size:12px;
    color:#48916F;
    margin-bottom:15px;
    cursor: pointer;
  }
  .other{
    display:block;
    color:#999;
    font-size:12px;
  }
  .otherItem{
    width:48%!important;
    margin-right:20px;
    display: inline-block;
    vertical-align: middle;
  }
  .otherItemRight{
    margin-right:0;
  }
  ::v-deep .otherItem .el-select,::v-deep .otherItem .el-input{
    width:160px!important;
  }
  ::v-deep .otherItemradio .el-radio__label{
    font-size:12px!important;
  }
  .uploadBtnBottom{
    margin-bottom:20px;
    margin-left:10px;
  }
  .batchUpload{
    text-align:center;
  }
  .uploadDialogtf{
    text-align:center;
    padding-bottom: 30px;
  }
  .setWarnning{
    background:#FFF5EC;
    color:#F3920B;
    border:1px solid #F3920B;
    border-radius:6px;
  }
  .setWarnning:hover{
    background:#F3920B;
    color:#FFF5EC;
    border:1px solid #F3920B;
  }
  </style>
