<template>
    <div class="authority common-page">
      <PocCardTable>
        <template slot="headerTitle">

          <div class="notice">
            <h3>通知</h3>
            <div class="noticeWrap">
              <div class="noticeH">各位同事：</div>
              <p>系统已经2岁啦！！！值此系统2周年之际我们给系统进行了2.0版本更新，我们给系统更换了皮肤，同时根据运营反馈、使用习惯进行了功能迭代。</p>
              <p>系统2.0版本更新内容如下：</p>
              <p>
                <ul>
                  <li>1、2024年啦！！！我们系统的皮肤也升级啦！！！</li>
                  <li>2、应大家需求，BI看板展现样式更新；</li>
                  <!-- <li>3、列功能在官方功能基础上，为简化运营操作、提升效率，增加随意切换广告系列、广告组查看列表的功能；增加单独保存计划系列功能；增加单项操作数据功能；</li> -->
                  <li class="newStyle">
                    3、
                    <span style="display: inline;">时报、日报功能大改版，增加以下功能：</span>
                    <span>①、【下载】：可下载当前时报/日报模板；</span>
                    <span>②、【补发】：时报支持补发当天；日报支持补发周期最多为14天的数据，：例 2024年1月3日至2024年1月16日 或 2023年12月3日至2023年12月16日；</span>
                    <span>③、【重发】：在原有基础上增加重发发送人；</span>
                    <span>④、【转让】：创建时报、日报的优化师或部门上级领导 ，可以将时报/日报转让给别人， 转让之后原优化师失去当前模板权限；</span>
                    <span>⑤、【状态】：暂停之后不再往excel填充数据  需要填充数据把excel/图片开关开启即可；</span>
                    <span>⑥、【预警】：文件大小超过15MB在每行数据前显示红色感叹号  ，10MB显示黄色感叹号 ；15MB预警之后不处理就关停， 可下载模板处理后重新上传；</span>
                    <span>⑦、【修改】：在原有基础上支持修改账户、时间点</span>
                    <span>注意：旧版时报、日报与新版时报、日报将并行1个月，一个月之后旧版时报、日报将下线</span>
                  </li>
                  <li>4、oppo渠道广告组增加【定时调整转化出价】系列功能，功能逻辑与定时调价相同；</li>
                  <li>5、oppo渠道二代发送钉钉通知。</li>
                </ul>
              </p>
              <div class="noticeH">烦请大家在使用的过程中遇到问题及时在群里沟通，以便技术中心更快的解决，也希望大家多提宝贵意见，使系统越来越完善，更好的帮助大家提高工作效率</div>
              <ul class="noticeLast">
                <li>技术中心</li>
                <li>2024年1月16日</li>
              </ul>
            </div>
          </div>
        </template>
      </PocCardTable>
    </div>
  </template>
  
 
<script>
import Api from "@/api";
import config from '@/api/config';
import { $http } from '@/api/http';

export default {
  name: 'faq',
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
    


  }
}
</script>

<style lang="scss" scoped>
.notice{
  width:42%;
  margin:0 auto;
  padding:0 20px;
  font-size:14px;
}
.notice h3{
  text-align:center;
  font-size:22px;
  font-weight:700;
}
.notice p {
  text-indent:2em;
  font-weight:700;
}
.noticeH{
  font-weight:700;
}
.notice ul,.notice li{
  padding:0;
  margin:0;
  font-weight:400;
}
.notice li{
  margin:10px 0;
}
.noticeLast{
  text-align:right;
}
.newStyle span{
  display:block;
  text-indent: 4em;
}
</style>
