<template>
  <div>
    <el-dialog
      :visible.sync="show"
      width="32.5%"
      top="15%"
      :title="titleMsg"
      :close-on-click-modal="false"
      class="acctount-detail-dialog"
    >
      <!-- <div slot="title" class="header-box">
        <span>Reminder</span>
      </div>-->
      <div>{{contentMsg}}</div>
      <div slot="footer">
        <el-button type @click="show=false" class="marginR20">No</el-button>
        <el-button type="primary" @click="submit">YES</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'applyDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: String,
    content: String
  },
  data() {
    return {
      show: this.visible,
      titleMsg: this.title,
      contentMsg: this.content
    }
  },
  methods: {
    submit() {
      this.$emit('confrom');
    }
  }
}
</script>

