// vivo
import AccountListVivo from '@/views/auth/homePage/AccountListVivo';//账户列表
import DailyVivo from '@/views/auth/operateManage/dailyOrtimes/DailyVivo';//日报
import TimesVivo from '@/views/auth/operateManage/dailyOrtimes/TimesVivo';//时报

import AccountSelectionVivo from '@/views/auth/planManage/accountSelectionVivo';//账户选择
import AdvertisingPlanVivo from '@/views/auth/planManage/advertisingPlanVivo';
import AdPlanVivo from '@/views/auth/planManage/adPlanVivo';//广告计划
import AdTeamVivo from '@/views/auth/planManage/adTeamVivo';//广告组
import AdvertVivo from '@/views/auth/planManage/advertVivo';//广告
import UploadPageVivo from '@/views/auth/planManage/uploadPageVivo';
import StartStopVivo from '@/views/auth/planManage/modifyPrice/StartStopVivo';
import StartStopGroupVivo from '@/views/auth/planManage/modifyPrice/StartStopGroupVivo';
import PriceModifyGroupVivo from '@/views/auth/planManage/modifyPrice/PriceModifyGroupVivo';
import PriceModifyPlanVivo from '@/views/auth/planManage/modifyPrice/PriceModifyPlanVivo';
import PriceModifyVivo from '@/views/auth/planManage/modifyPrice/PriceModifyVivo';

import ConsumptionSumVivo from '@/views/auth/operateManage/ConsumptionSumVivo';//素材消耗
import ChangeMaterialList from '@/views/auth/operateManage/ChangeMaterialList';//素材消耗
import ChangeMaterialListPic from '@/views/auth/operateManage/ChangeMaterialListPic';//素材消耗




export default {
  routes: [
    {
      path: 'accountListVivo',
      name: 'VivoAccountManagement.AccountListVivo',
      component: AccountListVivo
    },
    {
      path: 'dailyVivo',
      name: 'VivoAccountManagement.DailyVivo',
      component: DailyVivo
    },
    
    {
      path: 'timesVivo',
      name: 'VivoAccountManagement.TimesVivo',
      component: TimesVivo
    }, 
    {
      path: 'accountSelectionVivo',
      name: 'VivoAccountManagement.AccountSelectionVivo',
      component: AccountSelectionVivo
    },
    {
      path: 'advertisingPlanVivo',
      name: 'VivoAccountManagement.AdvertisingPlanVivo',
      component: AdvertisingPlanVivo
    },
    {
      path: 'adPlanVivo/',//广告计划
      component: AdPlanVivo
    },
    {
      path: 'adTeamVivo/',//广告组
      component: AdTeamVivo
    },
    {
      path: 'advertVivo/',//广告
      component: AdvertVivo
    },
    {
      path: 'uploadPageVivo',
      name: 'VivoAccountManagement.UploadPageVivo',
      component: UploadPageVivo
    },
    {
      path: 'startStopVivo',
      name: 'VivoAccountManagement.StartStopVivo',
      component: StartStopVivo
    },
    {
      path: 'startStopGroupVivo',
      name: 'VivoAccountManagement.StartStopGroupVivo',
      component: StartStopGroupVivo
    },
    {
      path: 'priceModifyGroupVivo',
      name: 'VivoAccountManagement.PriceModifyGroupVivo',
      component: PriceModifyGroupVivo
    },
    {
      path: 'priceModifyPlanVivo',
      name: 'VivoAccountManagement.PriceModifyPlanVivo',
      component: PriceModifyPlanVivo
    },
    {
      path: 'priceModifyVivo',//调价计划展示页--账户
      name: 'VivoAccountManagement.PriceModifyVivo',
      component: PriceModifyVivo
    },
    {
      path: 'consumptionSumVivo',
      name: 'VivoAccountManagement.ConsumptionSumVivo',
      component: ConsumptionSumVivo
    },
    {
      path: 'changeMaterialList',
      name: 'VivoAccountManagement.ChangeMaterialList',
      component: ChangeMaterialList
    },
    {
      path: 'changeMaterialListPic',
      name: 'VivoAccountManagement.ChangeMaterialListPic',
      component: ChangeMaterialListPic
    },
    
  ],
};
