import AccountSelectionMi from '@/views/auth/planManage/accountSelectionMi';//账户选择
import AccountListMi from '@/views/auth/homePage/AccountListMi';//账户列表
import UserDetailMi from '@/views/auth/homePage/UserDetailMi';//用户列表详情
import TimesMi from '@/views/auth/operateManage/dailyOrtimes/TimesMi';//时报
import DailyMi from '@/views/auth/operateManage/dailyOrtimes/DailyMi';//日报
import ConsumptionSumMi from '@/views/auth/operateManage/ConsumptionSumMi';//素材消耗
import PriceModifyMi from '@/views/auth/planManage/modifyPrice/PriceModifyMi';//调价-账户
import PriceModifyPlanMi from '@/views/auth/planManage/modifyPrice/PriceModifyPlanMi';//调价-计划
import PriceModifyAdvertMi from '@/views/auth/planManage/modifyPrice/PriceModifyAdvertMi';//调价-创意
import AdPlanMi from '@/views/auth/planManage/adPlanMi';//广告计划
import AdTeamMi from '@/views/auth/planManage/adTeamMi';//广告组
import AdvertMi from '@/views/auth/planManage/advertMi';//广告
import StartStopMi from '@/views/auth/planManage/modifyPrice/StartStopMi';//启停-计划
import StartStopGroupMi from '@/views/auth/planManage/modifyPrice/StartStopGroupMi';//启停-广告组
import UploadPageMi from '@/views/auth/planManage/uploadPageMi';//上传记录页



export default {
  routes: [
    {
      path: 'accountSelectionMi',
      name: 'MiAccountManagement.AccountSelectionMi',
      component: AccountSelectionMi
    },
    {
      path: 'accountListMi',
      name: 'MiAccountManagement.AccountListMi',
      component: AccountListMi
    },
    { 
      path: 'consumptionSumMi',
      name: 'MiAccountManagement.ConsumptionSumMi',
      component: ConsumptionSumMi
    },
    { 
      path: 'userDetailMi/:userId',
      name: 'MiAccountManagement.UserDetailMi',
      component: UserDetailMi
    },
    {
      path: 'timesMi',
      name: 'MiAccountManagement.TimesMi',
      component: TimesMi
    },
    
    {
      path: 'dailyMi',
      name: 'MiAccountManagement.DailyMi',
      component: DailyMi
    },
    
    {
      path: 'priceModifyMi',
      name: 'MiAccountManagement.PriceModifyMi',
      component: PriceModifyMi
    },
    {
      path: 'priceModifyPlanMi',
      name: 'MiAccountManagement.PriceModifyPlanMi',
      component: PriceModifyPlanMi
    },
    {
      path: 'priceModifyAdvertMi',
      name: 'MiAccountManagement.PriceModifyAdvertMi',
      component: PriceModifyAdvertMi
    },
    {
      path: 'adPlanMi',//广告计划
      name: 'MiAccountManagement.AdPlanMi',
      component: AdPlanMi
    },
    {
      path: 'adTeamMi',//广告组
      name: 'MiAccountManagement.AdTeamMi',
      component: AdTeamMi
    },
    {
      path: 'advertMi',//广告
      name: 'MiAccountManagement.AdvertMi',
      component: AdvertMi
    },
    {
      path: 'startStopMi',
      name: 'MiAccountManagement.StartStopMi',
      component: StartStopMi
    },
    {
      path: 'startStopGroupMi',
      name: 'MiAccountManagement.StartStopGroupMi',
      component: StartStopGroupMi
    },
    {
      path: 'uploadPageMi',
      name: 'MiAccountManagement.UploadPageMi',
      component: UploadPageMi
    },
  ]
  };