import { render, staticRenderFns } from "./dataSjkbAsa.vue?vue&type=template&id=06bedbaa&scoped=true&"
import script from "./dataSjkbAsa.vue?vue&type=script&lang=js&"
export * from "./dataSjkbAsa.vue?vue&type=script&lang=js&"
import style0 from "./dataSjkbAsa.vue?vue&type=style&index=0&id=06bedbaa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bedbaa",
  null
  
)

export default component.exports