<template>
  <div class="authority common-page">
    <PocCardTable>
      <template slot="headerTitle">
        <div class="hl_headers sticky" style="margin-bottom: 0">
          <div class="hl_breadCrumb hl_headerLeft">
            <el-breadcrumb separator="/" class="hl_headerLeftDrowp">
              <el-breadcrumb-item>运营管理</el-breadcrumb-item>
              <el-breadcrumb-item>日报KPI</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div
            class="hl_headerRight"
            v-if="authorityIds && authorityIds.indexOf(channelId) > -1"
          >
            <!-- <el-button
              v-if="channelId == 4"
              type="primary"
              :loading="generateLoading"
              :disabled="generateLoading"
              @click="generateFunction"
              >生成重点客户波动描述</el-button
            > -->
            <el-upload
              v-if="channelId == 7"
              action="#"
              :headers="filesHeader"
              :show-file-list="false"
              ref="customerInforUpload"
              :http-request="(e) => uploadFilesFunction(e, 'customerInfor')"
            >
              <el-button slot="trigger" type="primary"
                >上传客户关系表</el-button
              >
            </el-upload>
            <el-dropdown v-if="channelId != 7 && channelId != 8">
              <el-button type="primary">上传</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="channelId == 4">
                  <el-upload
                    action="#"
                    :headers="filesHeader"
                    :show-file-list="false"
                    ref="dailyUpload"
                    :http-request="(e) => uploadFilesFunction(e, 'daily')"
                    >日报数据</el-upload
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="channelId != 3 && channelId != 4">
                  <el-upload
                    action="#"
                    :headers="filesHeader"
                    :show-file-list="false"
                    ref="customerInforUpload"
                    :http-request="
                      (e) => uploadFilesFunction(e, 'customerInfor')
                    "
                    >客户关系表</el-upload
                  >
                </el-dropdown-item>
                <el-dropdown-item v-if="channelId == 5">
                  <div @click="dataReportFunction('month')">月数据报表</div>
                </el-dropdown-item>
                <el-dropdown-item
                  v-if="channelId == 4 || channelId == 5 || channelId == 6"
                >
                  <div @click="dataReportFunction('day')">日数据报表</div>
                </el-dropdown-item>
                <el-dropdown-item v-if="channelId == 6">
                  <div @click="dataReportFunction('day', 'ads')">
                    ADS日数据报表
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="channelId == 3">
                  <div @click="dataReportFunction('day', 'ov')">OV差异数据</div>
                </el-dropdown-item>
                <el-dropdown-item v-if="channelId == 3">
                  <el-upload
                    action="#"
                    :headers="filesHeader"
                    :show-file-list="false"
                    ref="newCustomersUpload"
                    :http-request="
                      (e) => uploadFilesFunction(e, 'newCustomers')
                    "
                    >新客新品数据</el-upload
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-dropdown>
              <el-button
                type="primary"
                :loading="previewLoading"
                :disabled="previewLoading"
                >预览</el-button
              >
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="previewFunction('current')">实时预览</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="previewFunction('final')">服务器预览</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              type="primary"
              :loading="updateLoading"
              :disabled="updateLoading"
              @click="updateFunction"
              >更新</el-button
            >
          </div>
        </div>
        <!-- 上传月数据报表弹框 -->
        <el-dialog
          width="400px"
          title="上传月数据报表"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="uploadMonthDataReportVisible"
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form label-width="60px">
            <el-form-item label="月份">
              <el-date-picker
                type="month"
                format="yyyy-MM"
                :clearable="false"
                value-format="yyyy-MM"
                placeholder="请选择月份"
                v-model="uploadMonthDate"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="text_tip">
            文件数据为媒体提供的月账户消耗数据文件<br />解决账户迁移历史数据无法追溯问题
          </div>
          <div slot="footer">
            <el-button @click="uploadMonthDataReportVisible = false"
              >取消</el-button
            >
            <el-upload
              action="#"
              ref="monthDataUpload"
              :headers="filesHeader"
              :show-file-list="false"
              :http-request="(e) => uploadFilesFunction(e, 'monthData')"
            >
              <el-button slot="trigger" type="primary">上 传</el-button>
            </el-upload>
          </div>
        </el-dialog>
        <!-- 上传日数据报表弹框 -->
        <el-dialog
          width="500px"
          class="icon_custom_popup"
          :close-on-click-modal="false"
          :visible.sync="uploadDayDataReportVisible"
          :title="
            dataReportStatus == 'ads'
              ? '上传ADS日数据报表'
              : dataReportStatus == 'ov'
              ? '上传OV差异数据'
              : '上传日数据报表'
          "
        >
          <img class="icon" src="@/assets/img/suCaiIcon.png" />
          <el-form label-width="80px">
            <el-form-item label="日期">
              <el-date-picker
                type="date"
                :clearable="false"
                v-model="uploadDayData"
                format="yyyy-MM-dd"
                placeholder="请选择日期"
                value-format="yyyy-MM-dd"
                @change="changeDayData"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="数据文件" v-if="dataReportStatus != 'ov'">
              <el-upload
                multiple
                action="#"
                ref="dayDataUpload"
                :headers="filesHeader"
                :show-file-list="false"
                :file-list="datafilesList"
                :on-change="handleFileChange"
                :before-upload="handleBeforeUpload"
                :http-request="(e) => uploadFilesFunction(e, 'dayData')"
              >
                <el-button
                  slot="trigger"
                  type="primary"
                  style="margin-left: 12px; margin-top: 4px"
                  >上 传</el-button
                >
              </el-upload>
            </el-form-item>
          </el-form>
          <div class="text_tip" v-if="!dataReportStatus">
            <div v-if="channelId == 4">
              请选择<br />【vivo代理商平台】-【数据中心】-【消耗数据】-【历史数据统计】<br />单日导出的数据文件
            </div>
            <div v-if="channelId == 5">
              请选择核代账户下【数据管理】单日导出的数据文件
            </div>
          </div>
          <div slot="footer">
            <el-button
              :type="dataReportStatus == 'ov' ? '' : 'primary'"
              @click="uploadDayDataReportVisible = false"
              >{{ dataReportStatus == "ov" ? "取 消" : "关 闭" }}</el-button
            >
            <el-upload
              v-if="dataReportStatus == 'ov'"
              action="#"
              ref="dayDataUpload"
              :headers="filesHeader"
              :show-file-list="false"
              :http-request="(e) => uploadFilesFunction(e, 'dayData')"
            >
              <el-button slot="trigger" type="primary">上 传</el-button>
            </el-upload>
          </div>
        </el-dialog>
        <div class="line_function_contant">
          <div
            v-for="item in Object.keys(channalInforList)"
            :key="item"
            :class="{ active: channelId == item }"
            @click="changeChannel(item)"
          >
            {{ channalInforList[item].name.toUpperCase() }}
          </div>
        </div>
        <el-alert
          class="alertContainer"
          title="数值内容请勿使用科学计数法！"
          type="error"
          :closable="false"
          description="例：2,000,000 需改成 2000000"
        >
        </el-alert>
        <div class="materiaContent">
          <el-table
            ref="multipleTable"
            :data="dataList"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="title" label="字段"></el-table-column>
            <el-table-column prop="answer" label="内容"> </el-table-column>
            <el-table-column prop="updateTime" label="修改时间" width="120">
              <template v-slot="scope">
                <span>{{ scope.row.updateTime | dateFormat }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="updater" label="修改人" width="120">
            </el-table-column>
            <el-table-column label="操作" width="90">
              <template v-slot="scope">
                <el-button
                  class="solid-tag-instructions"
                  @click="modifyDataFunction(scope.row)"
                  >修改</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 预览图片 -->
          <el-image-viewer
            v-if="previewVisible"
            :url-list="[postermage]"
            :on-close="closePreview"
          />
          <!-- 修改内容弹框 -->
          <el-dialog
            title="修改内容"
            class="icon_custom_popup"
            :close-on-click-modal="false"
            :visible.sync="modifyDataVisible"
          >
            <img class="icon" src="@/assets/img/xiuGaiIcon.png" />
            <el-form>
              <el-form-item>
                <el-input
                  type="textarea"
                  :rows="6"
                  v-model="modifyDataForm.describe"
                  placeholder="请输入具体内容"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer">
              <el-button @click="modifyDataVisible = false">取 消</el-button>
              <el-button type="primary" @click="modifyDataSubmit"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </div>
      </template>
    </PocCardTable>
    <div id="posterContainer" ref="posterImage">
      <div class="bannerContainer">
        <div class="title1">{{ channalInforList[channelId].date }}</div>
        <div class="title2">
          {{
            channalInforList[channelId].names ||
            channalInforList[channelId].name
          }}
        </div>
        <div class="title3">媒体日报</div>
      </div>
      <div
        :class="{
          combinationContainer: channalInforList[channelId].auxiliaryTitle,
        }"
      >
      <div class="classificationTitle" v-if="channalInforList[channelId].title">{{channalInforList[channelId].title}}</div>
        <div v-if="channalInforList[channelId].summary">
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].summary.serialNumber"
                >{{ channalInforList[channelId].summary.serialNumber
                }}<span class="drop">.</span></span
              >
              <span class="name">{{
                channalInforList[channelId].summary.title
              }}</span>
            </div>
            <img :src="channalInforList[channelId].summary.image" />
          </div>
          <div class="summaryContainer">
            <div
              class="summaryNews"
              :style="{
                width:
                  item.width == '50%' &&
                  channalInforList[channelId].auxiliaryTitle
                    ? '634px !important'
                    : item.width == '50%' &&
                      !channalInforList[channelId].auxiliaryTitle
                    ? '604px !important'
                    : item.width,
              }"
              v-for="(item, index) in channalInforList[channelId].summary.list"
              :key="index"
            >
              <div class="number">
                <span>
                  <span
                    class="value"
                    :style="{
                      'font-size':
                        item.value && item.value.length == 9 ? '62px' : '72px',
                    }"
                    >{{ intercepValue(item.value, 0) }}</span
                  >
                  {{ intercepValue(item.value, 1) }}
                </span>
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div v-if="channalInforList[channelId].summary_two">
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].summary_two.serialNumber"
                >{{ channalInforList[channelId].summary_two.serialNumber
                }}<span class="drop">.</span></span
              >
              <span class="name">{{
                channalInforList[channelId].summary_two.title
              }}</span>
            </div>
            <img :src="channalInforList[channelId].summary_two.image" />
          </div>
          <div class="summaryContainer">
            <div
              class="summaryNews"
              :style="{
                width:
                  item.width == '50%' &&
                  channalInforList[channelId].auxiliaryTitle
                    ? '634px !important'
                    : item.width == '50%' &&
                      !channalInforList[channelId].auxiliaryTitle
                    ? '604px !important'
                    : item.width,
              }"
              v-for="(item, index) in channalInforList[channelId].summary_two
                .list"
              :key="index"
            >
              <div class="number">
                <span>
                  <span
                    class="value"
                    :style="{
                      'font-size':
                        item.value && item.value.length == 9 ? '62px' : '72px',
                    }"
                    >{{ intercepValue(item.value, 0) }}</span
                  >
                  {{ intercepValue(item.value, 1) }}
                </span>
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div v-if="channalInforList[channelId].dataInfor">
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].dataInfor.serialNumber"
                >{{ channalInforList[channelId].dataInfor.serialNumber
                }}<span class="drop">.</span></span
              >
              <span
                class="name"
                v-html="channalInforList[channelId].dataInfor.title"
              ></span>
            </div>
            <img :src="channalInforList[channelId].dataInfor.image" />
          </div>
          <div class="detailedContainer" :style="{'padding-bottom': channelId == 6 || channelId == 7 ? '3px' : ''}">
            <div
              :class="{dataContainer:channelId != 6 && channelId != 7}"
              v-for="(item, index) in channalInforList[channelId].dataInfor
                .list"
              :key="index"
            >
            <div v-if="channelId == 6 || channelId == 7">
              <div class="dataTitle">{{ item.typeName }}</div>
              <div v-for="(items, indexs) in item.typeDetail"
              :key="indexs">
                <div class="dataContainer"
                  style="margin-bottom: 50px">
              <div class="auxiliaryTitle" v-if="items.name && item.typeDetail.length > 1">
                {{ items.name }}
                <div
                  class="small"
                  v-if="channalInforList[channelId].dataInforList[0].status"
                >
                  {{ channalInforList[channelId].dataInforList[0].name
                  }}<span>{{
                    items[channalInforList[channelId].dataInforList[0].field]
                  }}</span>
                </div>
              </div>
              <div class="dataText" v-if="!items.name" v-html="items.value"></div>
              <div class="dataNews" v-else>
                <div
                  class="dataValue"
                  :style="{
                    width: itemd.width,
                    display:
                      (itemd.hideList &&
                        itemd.hideList.indexOf(items.name) > -1) ||
                      (channalInforList[channelId].dataInforIndex &&
                        channalInforList[channelId].dataInforIndex != 0 &&
                        channalInforList[channelId].dataInforIndex == index) ||
                      itemd.status == 'auxiliary'
                        ? 'none'
                        : '',
                  }"
                  v-for="(itemd, indexd) in channalInforList[channelId]
                    .dataInforList"
                  :key="indexd"
                >
                  <span
                    class="name"
                    :style="{
                      width: items.width
                        ? ''
                        : channalInforList[channelId].dataInforWidth,
                    }"
                    >{{
                      itemd.names &&
                      itemd.nameList &&
                      itemd.nameList.indexOf(items.name) > -1
                        ? itemd.names
                        : itemd.name
                    }}</span
                  >
                  <span
                    class="number"
                    :style="{
                      'font-size':
                        items[itemd.field] && items[itemd.field].length > 7
                          ? '34px'
                          : '',
                    }"
                    >{{
                      items[itemd.field] || items[itemd.field] == 0
                        ? items[itemd.field]
                        : "- -"
                    }}</span
                  >
                </div>
              </div>
              <div class="tips" v-if="items.tip">{{ items.tip }}</div>
            </div>
              </div>
            </div>
              <div v-else>
              <div class="auxiliaryTitle" v-if="item.name">
                {{ item.name }}
                <div
                  class="small"
                  v-if="channalInforList[channelId].dataInforList[0].status"
                >
                  {{ channalInforList[channelId].dataInforList[0].name
                  }}<span>{{
                    item[channalInforList[channelId].dataInforList[0].field]
                  }}</span>
                </div>
              </div>
              <div class="dataText" v-if="!item.name" v-html="item.value"></div>
              <div class="dataNews" v-else>
                <div
                  class="dataValue"
                  :style="{
                    width: items.width,
                    display:
                      (items.hideList &&
                        items.hideList.indexOf(item.name) > -1) ||
                      (channalInforList[channelId].dataInforIndex &&
                        channalInforList[channelId].dataInforIndex != 0 &&
                        channalInforList[channelId].dataInforIndex == index) ||
                      items.status == 'auxiliary'
                        ? 'none'
                        : '',
                  }"
                  v-for="(items, indexs) in channalInforList[channelId]
                    .dataInforList"
                  :key="indexs"
                >
                  <span
                    class="name"
                    :style="{
                      width: item.width
                        ? ''
                        : channalInforList[channelId].dataInforWidth,
                    }"
                    >{{
                      items.names &&
                      items.nameList &&
                      items.nameList.indexOf(item.name) > -1
                        ? items.names
                        : items.name
                    }}</span
                  >
                  <span
                    class="number"
                    :style="{
                      'font-size':
                        item[items.field] && item[items.field].length > 7
                          ? '34px'
                          : '',
                    }"
                    >{{
                      item[items.field] || item[items.field] == 0
                        ? item[items.field]
                        : "- -"
                    }}</span
                  >
                </div>
              </div>
              <div class="tips" v-if="item.tip">{{ item.tip }}</div>
            </div>
          </div>
          </div>
          <div v-if="channalInforList[channelId].dataInfor_four">
            <div class="titleContainer">
              <div class="titleNews">
                <span
                  class="number"
                  v-if="channalInforList[channelId].dataInfor_four.serialNumber"
                  >{{ channalInforList[channelId].dataInfor_four.serialNumber
                  }}<span class="drop">.</span></span
                >
                <span
                  class="name"
                  v-html="channalInforList[channelId].dataInfor_four.title"
                ></span>
              </div>
              <img :src="channalInforList[channelId].dataInfor_four.image" />
            </div>
            <div class="detailedContainer">
              <div
                class="dataContainer"
                v-for="(item, index) in channalInforList[channelId]
                  .dataInfor_four.list"
                :key="index"
              >
                <div class="auxiliaryTitle" v-if="item.name">
                  {{ item.name }}
                  <div
                    class="small"
                    v-if="
                      channalInforList[channelId].dataInforList_four[0].status
                    "
                  >
                    {{ channalInforList[channelId].dataInforList_four[0].name
                    }}<span>{{
                      item[
                        channalInforList[channelId].dataInforList_four[0].field
                      ]
                    }}</span>
                  </div>
                </div>
                <div
                  class="dataText"
                  v-if="!item.name"
                  v-html="item.value"
                ></div>
                <div class="dataNews" v-else>
                  <div
                    class="dataValue"
                    :style="{
                      width: items.width,
                      display:
                        (items.hideList &&
                          items.hideList.indexOf(item.name) > -1) ||
                        (channalInforList[channelId].dataInforIndex &&
                          channalInforList[channelId].dataInforIndex != 0 &&
                          channalInforList[channelId].dataInforIndex ==
                            index) ||
                        items.status == 'auxiliary'
                          ? 'none'
                          : '',
                    }"
                    v-for="(items, indexs) in channalInforList[channelId]
                      .dataInforList_four"
                    :key="indexs"
                  >
                    <span
                      class="name"
                      :style="{
                        width: item.width
                          ? ''
                          : channalInforList[channelId].dataInforWidth,
                      }"
                      >{{
                        items.names &&
                        items.nameList &&
                        items.nameList.indexOf(item.name) > -1
                          ? items.names
                          : items.name
                      }}</span
                    >
                    <span class="number">{{
                      item[items.field] || item[items.field] == 0
                        ? item[items.field]
                        : "- -"
                    }}</span>
                  </div>
                </div>
                <div class="tips" v-if="item.tip">{{ item.tip }}</div>
              </div>
            </div>
          </div>
          <div v-if="channalInforList[channelId].dataInfor_five">
            <div class="titleContainer">
              <div class="titleNews">
                <span
                  class="number"
                  v-if="channalInforList[channelId].dataInfor_five.serialNumber"
                  >{{ channalInforList[channelId].dataInfor_five.serialNumber
                  }}<span class="drop">.</span></span
                >
                <span
                  class="name"
                  v-html="channalInforList[channelId].dataInfor_five.title"
                ></span>
              </div>
              <img :src="channalInforList[channelId].dataInfor_five.image" />
            </div>
            <div class="detailedContainer">
              <div
                class="dataContainer"
                v-for="(item, index) in channalInforList[channelId]
                  .dataInfor_five.list"
                :key="index"
              >
                <div class="auxiliaryTitle" v-if="item.name">
                  {{ item.name }}
                  <div
                    class="small"
                    v-if="
                      channalInforList[channelId].dataInforList_four[0].status
                    "
                  >
                    {{ channalInforList[channelId].dataInforList_four[0].name
                    }}<span>{{
                      item[
                        channalInforList[channelId].dataInforList_four[0].field
                      ]
                    }}</span>
                  </div>
                </div>
                <div
                  class="dataText"
                  v-if="!item.name"
                  v-html="item.value"
                ></div>
                <div class="dataNews" v-else>
                  <div
                    class="dataValue"
                    :style="{
                      width: items.width,
                      display:
                        (items.hideList &&
                          items.hideList.indexOf(item.name) > -1) ||
                        (channalInforList[channelId].dataInforIndex &&
                          channalInforList[channelId].dataInforIndex != 0 &&
                          channalInforList[channelId].dataInforIndex ==
                            index) ||
                        items.status == 'auxiliary'
                          ? 'none'
                          : '',
                    }"
                    v-for="(items, indexs) in channalInforList[channelId]
                      .dataInforList_four"
                    :key="indexs"
                  >
                    <span
                      class="name"
                      :style="{
                        width: item.width
                          ? ''
                          : channalInforList[channelId].dataInforWidth,
                      }"
                      >{{
                        items.names &&
                        items.nameList &&
                        items.nameList.indexOf(item.name) > -1
                          ? items.names
                          : items.name
                      }}</span
                    >
                    <span class="number">{{
                      item[items.field] || item[items.field] == 0
                        ? item[items.field]
                        : "- -"
                    }}</span>
                  </div>
                </div>
                <div class="tips" v-if="item.tip">{{ item.tip }}</div>
              </div>
            </div>
          </div>
          <div v-if="channalInforList[channelId].dataInfor_two">
            <div class="titleContainer">
              <div class="titleNews">
                <span
                  class="number"
                  v-if="channalInforList[channelId].dataInfor_two.serialNumber"
                  >{{ channalInforList[channelId].dataInfor_two.serialNumber
                  }}<span class="drop">.</span></span
                >
                <span
                  class="name"
                  v-html="channalInforList[channelId].dataInfor_two.title"
                ></span>
                <div
                  class="date"
                  v-if="channalInforList[channelId].dataInfor_two.date"
                >
                  日期：{{ channalInforList[channelId].dataInfor_two.date }}
                </div>
              </div>
              <img :src="channalInforList[channelId].dataInfor_two.image" />
            </div>
            <div class="detailedContainer">
              <div
                class="dataContainer"
                v-for="(item, index) in channalInforList[channelId]
                  .dataInfor_two.list"
                :key="index"
              >
                <div class="auxiliaryTitle" v-if="item.name">
                  {{ item.name }}
                  <div
                    class="small"
                    v-if="
                      channalInforList[channelId].dataInforList_two[0].status
                    "
                  >
                    {{ channalInforList[channelId].dataInforList_two[0].name
                    }}<span>{{
                      item[
                        channalInforList[channelId].dataInforList_two[0].field
                      ]
                    }}</span>
                  </div>
                </div>
                <div
                  class="dataText"
                  v-if="!item.name"
                  v-html="item.value"
                ></div>
                <div class="dataNews" v-else>
                  <div
                    class="dataValue"
                    :style="{
                      width: items.width,
                      display:
                        (items.hideList &&
                          items.hideList.indexOf(item.name) > -1) ||
                        (channalInforList[channelId].dataInforIndex &&
                          channalInforList[channelId].dataInforIndex != 0 &&
                          channalInforList[channelId].dataInforIndex ==
                            index) ||
                        items.status == 'auxiliary'
                          ? 'none'
                          : '',
                    }"
                    v-for="(items, indexs) in channalInforList[channelId]
                      .dataInforList_two"
                    :key="indexs"
                  >
                    <span
                      class="name"
                      :style="{
                        width: item.width
                          ? ''
                          : channalInforList[channelId].dataInforWidth,
                      }"
                      >{{
                        items.names &&
                        items.nameList &&
                        items.nameList.indexOf(item.name) > -1
                          ? items.names
                          : items.name
                      }}</span
                    >
                    <span class="number">{{
                      item[items.field] || item[items.field] == 0
                        ? item[items.field]
                        : "- -"
                    }}</span>
                  </div>
                </div>
                <div class="tips" v-if="item.tip">{{ item.tip }}</div>
              </div>
            </div>
          </div>
          <div v-if="channalInforList[channelId].dataInfor_three">
            <div class="titleContainer">
              <div class="titleNews">
                <span
                  class="number"
                  v-if="
                    channalInforList[channelId].dataInfor_three.serialNumber
                  "
                  >{{ channalInforList[channelId].dataInfor_three.serialNumber
                  }}<span class="drop">.</span></span
                >
                <span
                  class="name"
                  v-html="channalInforList[channelId].dataInfor_three.title"
                ></span>
              </div>
              <img :src="channalInforList[channelId].dataInfor_three.image" />
            </div>
            <div class="detailedContainer">
              <div
                class="dataContainer"
                :class="{ dataContainers: channelId == 3 }"
                v-for="(item, index) in channalInforList[channelId]
                  .dataInfor_three.list"
                :key="index"
              >
                <div class="auxiliaryTitle" v-if="item.name">
                  {{ item.name }}
                  <div
                    class="small"
                    v-if="
                      channalInforList[channelId].dataInforList_three[0].status
                    "
                  >
                    {{ channalInforList[channelId].dataInforList_three[0].name
                    }}<span>{{
                      item[
                        channalInforList[channelId].dataInforList_three[0].field
                      ]
                    }}</span>
                  </div>
                </div>
                <div
                  class="dataText"
                  v-if="!item.name"
                  v-html="item.value"
                ></div>
                <div class="dataNews" v-else>
                  <div
                    class="dataValue"
                    :style="{
                      width: items.width,
                      display:
                        (items.hideList &&
                          items.hideList.indexOf(item.name) > -1) ||
                        (channalInforList[channelId].dataInforIndex &&
                          channalInforList[channelId].dataInforIndex != 0 &&
                          channalInforList[channelId].dataInforIndex ==
                            index) ||
                        items.status == 'auxiliary'
                          ? 'none'
                          : '',
                    }"
                    v-for="(items, indexs) in channalInforList[channelId]
                      .dataInforList_three"
                    :key="indexs"
                  >
                    <span
                      class="name"
                      :style="{
                        width: item.width
                          ? ''
                          : items.nameWidth
                          ? items.nameWidth
                          : channalInforList[channelId].dataInforWidth_three
                          ? channalInforList[channelId].dataInforWidth_three
                          : channalInforList[channelId].dataInforWidth,
                      }"
                      >{{
                        items.names &&
                        items.nameList &&
                        items.nameList.indexOf(item.name) > -1
                          ? items.names
                          : items.name
                      }}</span
                    >
                    <span class="number">{{
                      item[items.field] || item[items.field] == 0
                        ? item[items.field]
                        : "- -"
                    }}</span>
                  </div>
                </div>
                <div class="tips" v-if="item.tip">{{ item.tip }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="channalInforList[channelId].dataText">
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].dataText.serialNumber"
                >{{ channalInforList[channelId].dataText.serialNumber
                }}<span class="drop">.</span></span
              >
              <span
                class="name"
                v-html="channalInforList[channelId].dataText.title"
              ></span>
            </div>
            <img :src="channalInforList[channelId].dataText.image" />
          </div>
          <div class="detailedContainer">
            <div class="dataContainer" style="background: #ffffff">
              <div
                class="dataTextNews"
                v-for="(item, index) in channalInforList[channelId].dataText
                  .list"
                :key="index"
              >
                <div
                  class="name"
                  v-if="
                    channalInforList[channelId].dataText.nameList &&
                    channalInforList[channelId].dataText.nameList[index]
                  "
                >
                  {{ channalInforList[channelId].dataText.nameList[index] }}
                </div>
                <div class="value">
                  <div v-html="item.shop"></div>
                  <div v-html="item.ads"></div>
                  <div
                    v-html="item.league"
                    :style="{
                      'margin-top': item.ads && item.league ? '32px' : '',
                    }"
                  ></div>
                </div>
              </div>
              <div
                class="dataText"
                v-if="!channalInforList[channelId].dataText.list"
                v-html="channalInforList[channelId].dataText.value"
              ></div>
            </div>
          </div>
        </div>
        <div
          v-if="
            channalInforList[channelId].echarts &&
            channalInforList[channelId].echarts.title
          "
        >
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].echarts.serialNumber"
                >{{ channalInforList[channelId].echarts.serialNumber
                }}<span class="drop">.</span></span
              >
              <span
                class="name"
                v-html="channalInforList[channelId].echarts.title"
              ></span>
            </div>
            <img src="http://iad.hljyer.com/iad_assets/icon4.png" />
          </div>
          <div class="echartsContainer">
            <div ref="echarts" style="width: 100%; height: 660px"></div>
          </div>
        </div>
      </div>
      <div
        class="combinationContainer"
        v-if="channalInforList[channelId].other"
      >
      <div class="classificationTitle" v-if="channalInforList[channelId].other.title">{{channalInforList[channelId].other.title}}</div>
        <div v-if="channalInforList[channelId].other.summary">
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].other.summary.serialNumber"
                >{{ channalInforList[channelId].other.summary.serialNumber
                }}<span class="drop">.</span></span
              >
              <span class="name">{{
                channalInforList[channelId].other.summary.title
              }}</span>
            </div>
            <img :src="channalInforList[channelId].other.summary.image" />
          </div>
          <div class="summaryContainer">
            <div
              class="summaryNews"
              :style="{
                width:
                  item.width == '50%' &&
                  channalInforList[channelId].other.auxiliaryTitle
                    ? '634px !important'
                    : item.width == '50%' &&
                      !channalInforList[channelId].other.auxiliaryTitle
                    ? '604px !important'
                    : item.width,
              }"
              v-for="(item, index) in channalInforList[channelId].other.summary.list"
              :key="index"
            >
              <div class="number">
                <span>
                  <span
                    class="value"
                    :style="{
                      'font-size':
                        item.value && item.value.length == 9 ? '62px' : '72px',
                    }"
                    >{{ intercepValue(item.value, 0) }}</span
                  >
                  {{ intercepValue(item.value, 1) }}
                </span>
              </div>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div v-if="channalInforList[channelId].other.dataInfor">
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].other.dataInfor.serialNumber"
                >{{ channalInforList[channelId].other.dataInfor.serialNumber
                }}<span class="drop">.</span></span
              >
              <span
                class="name"
                v-html="channalInforList[channelId].other.dataInfor.title"
              ></span>
            </div>
            <img :src="channalInforList[channelId].other.dataInfor.image" />
          </div>
          <div class="detailedContainer">
            <div
              :class="{dataContainer:channelId != 6}"
              v-for="(item, index) in channalInforList[channelId].other.dataInfor
                .list"
              :key="index"
            >
            <div v-if="channelId == 6">
              <div class="dataTitle">{{ item.typeName }}</div>
              <div v-for="(items, indexs) in item.typeDetail"
              :key="indexs">
                <div class="dataContainer"
                  style="margin-bottom: 50px">
              <div class="auxiliaryTitle" v-if="items.name">
                {{ items.name }}
                <div
                  class="small"
                  v-if="channalInforList[channelId].other.dataInforList[0].status"
                >
                  {{ channalInforList[channelId].other.dataInforList[0].name
                  }}<span>{{
                    items[channalInforList[channelId].other.dataInforList[0].field]
                  }}</span>
                </div>
              </div>
              <div class="dataText" v-if="!items.name" v-html="items.value"></div>
              <div class="dataNews" v-else>
                <div
                  class="dataValue"
                  :style="{
                    width: itemd.width,
                    display:
                      (itemd.hideList &&
                        itemd.hideList.indexOf(items.name) > -1) ||
                      (channalInforList[channelId].other.dataInforIndex &&
                        channalInforList[channelId].other.dataInforIndex != 0 &&
                        channalInforList[channelId].other.dataInforIndex == index) ||
                      itemd.status == 'auxiliary'
                        ? 'none'
                        : '',
                  }"
                  v-for="(itemd, indexd) in channalInforList[channelId].other
                    .dataInforList"
                  :key="indexd"
                >
                  <span
                    class="name"
                    :style="{
                      width: items.width
                        ? ''
                        : channalInforList[channelId].other.dataInforWidth,
                    }"
                    >{{
                      itemd.names &&
                      itemd.nameList &&
                      itemd.nameList.indexOf(items.name) > -1
                        ? itemd.names
                        : itemd.name
                    }}</span
                  >
                  <span
                    class="number"
                    :style="{
                      'font-size':
                        items[itemd.field] && items[itemd.field].length > 7
                          ? '34px'
                          : '',
                    }"
                    >{{
                      items[itemd.field] || items[itemd.field] == 0
                        ? items[itemd.field]
                        : "- -"
                    }}</span
                  >
                </div>
              </div>
              <div class="tips" v-if="items.tip">{{ items.tip }}</div>
            </div>
              </div>
            </div>
              <div v-else>
              <div class="auxiliaryTitle" v-if="item.name">
                {{ item.name }}
                <div
                  class="small"
                  v-if="channalInforList[channelId].other.dataInforList[0].status"
                >
                  {{ channalInforList[channelId].other.dataInforList[0].name
                  }}<span>{{
                    item[channalInforList[channelId].other.dataInforList[0].field]
                  }}</span>
                </div>
              </div>
              <div class="dataText" v-if="!item.name" v-html="item.value"></div>
              <div class="dataNews" v-else>
                <div
                  class="dataValue"
                  :style="{
                    width: items.width,
                    display:
                      (items.hideList &&
                        items.hideList.indexOf(item.name) > -1) ||
                      (channalInforList[channelId].other.dataInforIndex &&
                        channalInforList[channelId].other.dataInforIndex != 0 &&
                        channalInforList[channelId].other.dataInforIndex == index) ||
                      items.status == 'auxiliary'
                        ? 'none'
                        : '',
                  }"
                  v-for="(items, indexs) in channalInforList[channelId].other
                    .dataInforList"
                  :key="indexs"
                >
                  <span
                    class="name"
                    :style="{
                      width: item.width
                        ? ''
                        : channalInforList[channelId].other.dataInforWidth,
                    }"
                    >{{
                      items.names &&
                      items.nameList &&
                      items.nameList.indexOf(item.name) > -1
                        ? items.names
                        : items.name
                    }}</span
                  >
                  <span
                    class="number"
                    :style="{
                      'font-size':
                        item[items.field] && item[items.field].length > 7
                          ? '34px'
                          : '',
                    }"
                    >{{
                      item[items.field] || item[items.field] == 0
                        ? item[items.field]
                        : "- -"
                    }}</span
                  >
                </div>
              </div>
              <div class="tips" v-if="item.tip">{{ item.tip }}</div>
            </div>
          </div>
          </div>
        </div>
        <div
          v-if="
            channalInforList[channelId].other.echarts &&
            channalInforList[channelId].other.echarts.title
          "
        >
          <div class="titleContainer">
            <div class="titleNews">
              <span
                class="number"
                v-if="channalInforList[channelId].echarts.serialNumber"
                >{{ channalInforList[channelId].echarts.serialNumber
                }}<span class="drop">.</span></span
              >
              <span
                class="name"
                v-html="channalInforList[channelId].echarts.title"
              ></span>
            </div>
            <img src="http://iad.hljyer.com/iad_assets/icon4.png" />
          </div>
          <div class="echartsContainer">
            <div ref="otherEcharts" style="width: 100%; height: 660px"></div>
          </div>
        </div>
      </div>
      <img class="logoBottom" src="@/assets/img/posterLogo.png" />
    </div>
  </div>
</template>

<script>
import config from "@/api/config";
import { $http } from "@/api/http";
import * as echarts from "echarts";
import html2canvas from "html2canvas";

let echartsColor = [
  "#BA1F22",
  "#00A2FF",
  "#97e500",
  "#f10f1b",
  "#ff5a00",
  "#ffba00",
  "#FFE483",
];
export default {
  name: "authManagement.DailyKpi",

  data(vm) {
    return {
      chart: null,
      dataList: [],
      channelId: "",
      postermage: "",
      posterMonth: "",
      uploadDayData: "",
      datafilesList: [],
      authorityIds: null,
      modifyDataForm: {},
      uploadMonthDate: "",
      dataReportStatus: "",
      updateLoading: false,
      previewVisible: false,
      previewLoading: false,
      generateLoading: false,
      batchFilesValidList: [],
      modifyDataVisible: false,
      uploadDayDataReportVisible: false,
      uploadMonthDataReportVisible: false,
      filesHeader: {
        Authorization: "",
      },
      channalInforList: {
        3: {
          id: 13,
          date: "",
          name: "OPPO",
          summary: {
            serialNumber: 1,
            title: "汇总业绩",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              {
                name: "每日消耗",
                field: "cost",
                value: "",
                type: "money",
                width: "50%",
              },
              {
                name: "季度完成率",
                field: "",
                value: "",
                type: "completionRate",
                width: "50%",
              },
              {
                name: "时间进度",
                field: "dateRate",
                value: "",
                type: "percentage",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
              {
                name: "季度日均",
                field: "avgQuarterCost",
                value: "",
                type: "money",
              },
              {
                name: "在投产品数",
                field: "projectCount",
                value: "",
                type: "number",
              },
              {
                name: "季度新客数",
                field: "quarterNewCount",
                value: "",
                type: "number",
              },
              {
                name: "季度新客消耗",
                field: "quarterNewCost",
                value: "",
                type: "money",
              },
            ],
          },
          dataInforIndex: 0,
          dataInforWidth: "190px",
          dataInforWidth_three: "260px",
          dataInforList: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "每日消耗",
              names: "每日新客",
              field: "cost",
              type: "money",
              nameList: ["新客数量"],
            },
            {
              name: "Qquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "month月日均",
              names: "month月累计",
              field: "avgMonthCost",
              fields: "monthCost",
              type: "money",
              nameList: ["新客数量", "新客消耗"],
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInforList_two: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "消耗差异",
              names: "业绩合计",
              field: "cost",
              type: "money",
              nameList: ["合计"],
            },
            {
              name: "Qquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "month月累计",
              field: "monthCost",
              type: "money",
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInfor: {
            list: [],
            title: "分类业绩",
            serialNumber: 2,
            image: "http://iad.hljyer.com/iad_assets/icon2.png",
          },
          dataInfor_two: {
            date: "",
            list: [],
            title: "OV差异汇总",
            serialNumber: 3,
            image: "http://iad.hljyer.com/iad_assets/icon5.png",
          },
          dataText: {
            list: [],
            serialNumber: 4,
            title: "消耗波动情况",
            image: "http://iad.hljyer.com/iad_assets/icon3.png",
            nameList: [
              "昨日对比前日商店",
              "昨日对比前日信息流",
              "昨日对比前日联盟",
            ],
          },
        },
        4: {
          id: 24,
          date: "",
          name: "vivo",
          summary: {
            serialNumber: 1,
            title: "汇总业绩",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              {
                name: "每日消耗",
                field: "cost",
                value: "",
                type: "money",
                width: "50%",
              },
              {
                name: "季度完成率",
                field: "",
                value: "",
                type: "completionRate",
                width: "50%",
              },
              {
                name: "时间进度",
                field: "dateRate",
                value: "",
                type: "percentage",
              },
              { name: "剩余日均GAP", field: "", value: "", type: "residueGap" },
              {
                name: "季度日均",
                field: "avgQuarterCost",
                value: "",
                type: "money",
              },
              {
                name: "在投产品数",
                field: "projectCount",
                value: "",
                type: "number",
              },
              {
                name: "季度新客数",
                field: "quarterNewCount",
                value: "",
                type: "number",
              },
              {
                name: "季度新客消耗",
                field: "quarterNewCost",
                value: "",
                type: "money",
              },
            ],
          },
          dataInforIndex: 0,
          dataInforWidth: "190px",
          dataInforList: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "每日消耗",
              field: "cost",
              type: "money",
            },
            {
              name: "Qquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "month月日均",
              names: "month月累计",
              field: "avgMonthCost",
              fields: "monthCost",
              type: "money",
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInforList_two: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "消耗差异",
              names: "业绩合计",
              field: "cost",
              type: "money",
              nameList: ["合计"],
            },
            {
              name: "Qquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "month月累计",
              field: "monthCost",
              type: "money",
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInforList_three: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "日均消耗",
              names: "日均数量",
              field: "cost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "季度消耗",
              names: "季度数量",
              field: "quarterCost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "月均消耗",
              names: "月均数量",
              field: "monthCost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInforList_four: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "日均消耗",
              names: "日均数量",
              field: "cost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "季度消耗",
              names: "季度数量",
              field: "quarterCost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "月均消耗",
              names: "月均数量",
              field: "monthCost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInforList_five: [
            {
              name: "年度累计",
              field: "yearCost",
              type: "money",
              status: "auxiliary",
            },
            {
              name: "日均消耗",
              names: "日均数量",
              field: "cost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "季度消耗",
              names: "季度数量",
              field: "quarterCost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "季度完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "月均消耗",
              names: "月均数量",
              field: "monthCost",
              type: "money",
              nameList: ["数量"],
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            { name: "剩余日均", field: "gap", type: "money" },
          ],
          dataInfor: {
            list: [],
            title: "分类业绩",
            serialNumber: 2,
            image: "http://iad.hljyer.com/iad_assets/icon2.png",
          },
          dataInfor_two: {
            date: "",
            list: [],
            title: "OV差异汇总",
            serialNumber: 5,
            image: "http://iad.hljyer.com/iad_assets/icon5.png",
          },
          // dataInfor_three: {
          //   list: [],
          //   title: "头腰客户培养-季度维护",
          //   serialNumber: 6,
          //   image: "http://iad.hljyer.com/iad_assets/icon6.png",
          // },
          dataInfor_four: {
            list: [],
            title: "新客完成情况",
            serialNumber: 3,
            image: "http://iad.hljyer.com/iad_assets/icon7.png",
          },
          dataInfor_five: {
            list: [],
            title: "新品完成情况",
            serialNumber: 4,
            image: "http://iad.hljyer.com/iad_assets/icon8.png",
          },
          dataText: {
            list: [],
            serialNumber: 6,
            title: "消耗波动情况",
            image: "http://iad.hljyer.com/iad_assets/icon3.png",
            nameList: [
              "昨日对比前日商店",
              "昨日对比前日信息流",
              "昨日对比前日联盟",
            ],
          },
        },
        5: {
          id: 15,
          date: "",
          name: "小米",
          summary: {
            serialNumber: 1,
            title: "汇总业绩",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              { name: "每日消耗", field: "cost", value: "", type: "money" },
              {
                name: "Qquarter-累计",
                field: "quarterCost",
                value: "",
                type: "money",
              },
              { name: "Qquarter-KPI", field: "kpi", value: "", type: "money" },
              {
                name: "目标完成率",
                field: "",
                value: "",
                type: "completionRate",
              },
              {
                name: "时间进度",
                field: "dateRate",
                value: "",
                type: "percentage",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
            ],
          },
          dataInforWidth: "190px",
          dataInforList: [
            {
              name: "每日消耗",
              names: "每日新客",
              field: "cost",
              type: "money",
              nameList: ["新客数量"],
              hideList: ["新客数量"],
            },
            {
              name: "Qquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "Qquarter-KPI",
              field: "kpi",
              type: "money",
            },
            {
              name: "目标完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "剩余日均",
              field: "gap",
              type: "money",
              hideList: ["新客数量"],
            },
          ],
          dataInfor: {
            list: [],
            title: "分类业绩",
            serialNumber: 2,
            image: "http://iad.hljyer.com/iad_assets/icon2.png",
          },
          dataText: {
            value: "",
            serialNumber: 3,
            title: "重点客户波动说明",
            image: "http://iad.hljyer.com/iad_assets/icon3.png",
          },
          echarts: {
            title: "近八日消耗趋势图",
            options: {
              grid: {
                top: 100,
                left: 38,
                right: 20,
                bottom: 28,
                containLabel: true,
              },
              label: {
                show: true,
                fontSize: 10,
                position: "top",
                color: "#3B4252",
                fontFamily: "FZLTHJW",
                formatter: function (item) {
                  return vm.convertToWan(item.value);
                },
              },
              legend: {
                top: 0,
                left: 0,
                itemGap: 66,
                itemHeight: 22,
                textStyle: {
                  fontSize: 24,
                  color: "#3B4252",
                },
              },
              xAxis: {
                type: "category",
                data: [],
                offset: 28,
                axisLabel: {
                  fontSize: 30,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
              yAxis: {
                type: "value",
                offset: 28,
                axisLabel: {
                  fontSize: 24,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                  formatter: function (item) {
                    return vm.convertToWan(item);
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    type: "dashed",
                    color: "#CACACA",
                  },
                },
                // min: function (value) {
                // let number = String(parseInt(value.min));
                // let text = "";
                // for (let i = 0; i < number.length - 2; i++) {
                //   text += 0;
                // }
                // return parseInt(String(number).slice(0, 2) + text);
                // },
              },
              series: [
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[2],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[2],
                  },
                },
                {
                  type: "bar",
                  data: [],
                 barWidth: 23,
                  itemStyle: {
                    color: echartsColor[3],
                  },
                },
                {
                  type: "bar",
                  data: [],
                 barWidth: 23,
                  itemStyle: {
                    color: echartsColor[4],
                  },
                },
                {
                  type: "bar",
                  data: [],
                 barWidth: 23,
                  itemStyle: {
                    color: echartsColor[5],
                  },
                },
                {
                  type: "bar",
                  data: [],
                 barWidth: 23,
                  itemStyle: {
                    color: echartsColor[6],
                  },
                },
              ],
            },
          },
        },
        6: {
          id: 16,
          date: "",
          name: "华为",
          title: '华为商店',
          summary: {
            serialNumber: 1,
            title: "华为渠道汇总业绩",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              { name: "每日消耗", field: "cost", value: "", type: "money" },
              {
                name: "Qquarter-累计",
                field: "quarterCost",
                value: "",
                type: "money",
              },
              { name: "Qquarter-KPI", field: "kpi", value: "", type: "money" },
              {
                name: "目标完成率",
                field: "",
                value: "",
                type: "completionRate",
              },
              {
                name: "时间进度",
                field: "dateRate",
                value: "",
                type: "percentage",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
            ],
          },
          dataInforWidth: "190px",
          dataInforList: [
            {
              name: "每日消耗",
              field: "cost",
              type: "money",
              hideList: ["星火达标个数（全年）"],
            },
            {
              name: "Qquarter-累计",
              names: "Hquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "Qquarter-KPI",
              names: "Hquarter-KPI",
              field: "kpi",
              type: "money",
            },
            {
              name: "目标完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "剩余日均",
              field: "gap",
              type: "money",
              hideList: ["星火达标个数（全年）"],
            },
          ],
          dataInfor: {
            list: [],
            title: "华为渠道分类业绩",
            serialNumber: 2,
            image: "http://iad.hljyer.com/iad_assets/icon2.png",
          },
          dataText: {
            value: "",
            serialNumber: 3,
            title: "消耗波动情况",
            image: "http://iad.hljyer.com/iad_assets/icon3.png",
          },
          echarts: {
            title: "近八日消耗趋势图",
            options: {
              grid: {
                top: 100,
                left: 38,
                right: 20,
                bottom: 28,
                containLabel: true,
              },
              label: {
                show: true,
                fontSize: 22,
                position: "top",
                color: "#3B4252",
                fontFamily: "FZLTHJW",
                formatter: function (item) {
                  return vm.convertToWan(item.value);
                },
              },
              legend: {
                top: 0,
                left: 0,
                itemGap: 66,
                itemHeight: 22,
                textStyle: {
                  fontSize: 24,
                  color: "#3B4252",
                },
              },
              xAxis: {
                type: "category",
                data: [],
                offset: 28,
                axisLabel: {
                  fontSize: 30,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
              yAxis: {
                type: "value",
                offset: 28,
                axisLabel: {
                  fontSize: 24,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                  formatter: function (item) {
                    return vm.convertToWan(item);
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    type: "dashed",
                    color: "#CACACA",
                  },
                },
                // min: function (value) {
                //   let number = String(parseInt(value.min));
                //   let text = "";
                //   for (let i = 0; i < number.length - 2; i++) {
                //     text += 0;
                //   }
                //   return parseInt(String(number).slice(0, 2) + text);
                // },
              },
              series: [
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[0],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[0],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[1],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[1],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[2],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[2],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[3],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[3],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[5],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[5],
                  },
                },

              ],
            },
          },
          other: {
          id: 16,
          date: "",
          name: "华为",
          title: '华为ADS',
          summary: {
            serialNumber: 1,
            title: "华为渠道汇总业绩",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              { name: "每日消耗", field: "cost", value: "", type: "money" },
              {
                name: "Qquarter-累计",
                field: "quarterCost",
                value: "",
                type: "money",
              },
              { name: "Qquarter-KPI", field: "kpi", value: "", type: "money" },
              {
                name: "目标完成率",
                field: "",
                value: "",
                type: "completionRate",
              },
              {
                name: "时间进度",
                field: "dateRate",
                value: "",
                type: "percentage",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
            ],
          },
          dataInforWidth: "190px",
          dataInforList: [
            {
              name: "每日消耗",
              field: "cost",
              type: "money",
            },
            {
              name: "Qquarter-累计",
              names: "Hquarter-累计",
              field: "quarterCost",
              type: "money",
            },
            {
              name: "Qquarter-KPI",
              names: "Hquarter-KPI",
              field: "kpi",
              type: "money",
            },
            {
              name: "目标完成率",
              field: "completionRate",
              type: "completionRate",
            },
            {
              name: "剩余日均",
              field: "gap",
              type: "money",
            },
          ],
          dataInfor: {
            list: [],
            title: "华为渠道分类业绩",
            serialNumber: 2,
            image: "http://iad.hljyer.com/iad_assets/icon2.png",
          },
          echarts: {
            title: "近八日消耗趋势图",
            options: {
              grid: {
                top: 100,
                left: 38,
                right: 20,
                bottom: 28,
                containLabel: true,
              },
              label: {
                show: true,
                fontSize: 22,
                position: "top",
                color: "#3B4252",
                fontFamily: "FZLTHJW",
                formatter: function (item) {
                  return vm.convertToWan(item.value);
                },
              },
              legend: {
                top: 0,
                left: 0,
                itemGap: 66,
                itemHeight: 22,
                textStyle: {
                  fontSize: 24,
                  color: "#3B4252",
                },
              },
              xAxis: {
                type: "category",
                data: [],
                offset: 28,
                axisLabel: {
                  fontSize: 30,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
              yAxis: {
                type: "value",
                offset: 28,
                axisLabel: {
                  fontSize: 24,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                  formatter: function (item) {
                    return vm.convertToWan(item);
                  },
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    type: "dashed",
                    color: "#CACACA",
                  },
                },
                // min: function (value) {
                //   let number = String(parseInt(value.min));
                //   let text = "";
                //   for (let i = 0; i < number.length - 2; i++) {
                //     text += 0;
                //   }
                //   return parseInt(String(number).slice(0, 2) + text);
                // },
              },
              series: [
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[0],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[0],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[1],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[1],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[2],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[2],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[3],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[3],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[5],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[5],
                  },
                },

              ],
            },
          },
          },
        },
        7: {
          id: 17,
          date: "",
          name: "应用宝",
          summary: {
            serialNumber: 1,
            title: "媒体维度业绩",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              { name: "单日消耗", field: "cost", value: "", type: "money" },
              {
                name: "Qquarter-累计",
                field: "quarterCost",
                value: "",
                type: "money",
              },
              { name: "Qquarter-KPI", field: "kpi", value: "", type: "money" },
              {
                name: "目标完成率",
                field: "",
                value: "",
                type: "completionRate",
              },
              {
                name: "时间进度",
                field: "dateRate",
                value: "",
                type: "percentage",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
            ],
          },
          dataInforWidth: "190px",
          dataInforList: [
            {
              name: "每日消耗",
              field: "cost",
              type: "money",
              hideList: ["新客拓展情况"],
            },
            {
              name: "开启数量",
              field: "newCountOpen",
              type: "newNumber",
              nameList: ["新客拓展情况"],
              hideList: ["合计", "KA+SKA", "中长尾", "项目客户", "销售存续", "销售新客"],
            },
            {
              name: "Qquarter-累计",
              names: "达标数量",
              field: "quarterCost",
              type: "money",
              nameList: ["新客拓展情况"],
            },
            {
              name: "Qquarter-KPI",
              names: "KPI",
              field: "kpi",
              type: "money",
              nameList: ["新客拓展情况"],
            },
            {
              name: "目标完成率",
              names: "达标率",
              field: "completionRate",
              type: "completionRate",
              nameList: ["新客拓展情况"],
            },
            {
              name: "剩余日均",
              field: "gap",
              type: "money",
              hideList: ["新客拓展情况"],
            },
          ],
          dataInfor: {
            list: [],
            title: "内部维度业绩",
            serialNumber: 2,
            image: "http://iad.hljyer.com/iad_assets/icon2.png",
          },
          dataText: {
            value: "",
            serialNumber: 3,
            title: "消耗波动情况",
            image: "http://iad.hljyer.com/iad_assets/icon3.png",
          },
          echarts: {
            title: "近八日消耗趋势图",
            options: {
              grid: {
                top: 70,
                left: 38,
                right: 20,
                bottom: 28,
                containLabel: true,
              },
              label: {
                show: true,
                fontSize: 30,
                position: "top",
                color: "#3B4252",
                fontFamily: "FZLTHJW",
                formatter: function (item) {
                  return vm.commaDivision(item.value, "ceil");
                },
              },
              legend: {
                top: 0,
                left: 0,
                itemGap: 66,
                itemHeight: 22,
                textStyle: {
                  fontSize: 24,
                  color: "#3B4252",
                },
              },
              xAxis: {
                type: "category",
                data: [],
                offset: 28,
                axisLabel: {
                  fontSize: 30,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
              yAxis: {
                type: "value",
                offset: 28,
                axisLabel: {
                  fontSize: 24,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    width: 2,
                    type: "dashed",
                    color: "#CACACA",
                  },
                },
                min: function (value) {
                  let number = String(parseInt(value.min));
                  let text = "";
                  for (let i = 0; i < number.length - 2; i++) {
                    text += 0;
                  }
                  return parseInt(String(number).slice(0, 2) + text);
                },
              },
              series: [
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[0],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[0],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[1],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[1],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[2],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[2],
                  },
                },
                {
                  type: "line",
                  data: [],
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[3],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[3],
                  },
                },


              ],
            },
          },
        },
        8: {
          id: 18,
          date: "",
          name: "苹果",
          names: "苹果asa",
          summary: {
            serialNumber: 1,
            title: "分日数据-国内（人民币）",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              { name: "当日消耗", field: "cost", value: "", type: "money" },
              {
                name: "昨日消耗",
                field: "yesterCost",
                value: "",
                type: "money",
              },
              {
                name: "month月累计",
                field: "monthCost",
                value: "",
                type: "money",
              },
              {
                name: "Qquarter-累计",
                field: "quarterCost",
                value: "",
                type: "money",
              },
              { name: "Qquarter-KPI", field: "kpi", value: "", type: "money" },
              {
                name: "环比",
                field: "",
                value: "",
                type: "chain",
              },
              {
                name: "季度完成度",
                field: "",
                value: "",
                type: "completionRate",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
              {
                name: "在投产品数",
                field: "quarterCount",
                value: "",
                type: "number",
              },
            ],
          },
          summary_two: {
            serialNumber: 2,
            title: "分日数据-出海（美金）",
            image: "http://iad.hljyer.com/iad_assets/icon1.png",
            list: [
              { name: "当日消耗", field: "cost", value: "", type: "money" },
              {
                name: "昨日消耗",
                field: "yesterCost",
                value: "",
                type: "money",
              },
              {
                name: "month月累计",
                field: "monthCost",
                value: "",
                type: "money",
              },
              {
                name: "Qquarter-累计",
                field: "quarterCost",
                value: "",
                type: "money",
              },
              { name: "Qquarter-KPI", field: "kpi", value: "", type: "money" },
              {
                name: "环比",
                field: "",
                value: "",
                type: "chain",
              },
              {
                name: "季度完成度",
                field: "",
                value: "",
                type: "completionRate",
              },
              { name: "剩余日均", field: "gap", value: "", type: "money" },
              {
                name: "在投产品数",
                field: "quarterCount",
                value: "",
                type: "number",
              },
            ],
          },
          echarts: {
            title: "近八日消耗趋势图",
            options: {
              grid: {
                top: 100,
                left: 38,
                right: 38,
                bottom: 28,
                containLabel: true,
              },
              label: {
                show: true,
                fontSize: 22,
                position: "top",
                color: "#3B4252",
                fontFamily: "FZLTHJW",
                formatter: function (item) {
                  return (
                    (item.componentIndex == 0 ? "¥" : "$") +
                    vm.convertToWan(item.value)
                  );
                },
              },
              legend: {
                top: 0,
                left: 0,
                itemGap: 66,
                itemHeight: 22,
                textStyle: {
                  fontSize: 24,
                  color: "#3B4252",
                },
              },
              xAxis: {
                type: "category",
                data: [],
                offset: 28,
                axisLabel: {
                  fontSize: 30,
                  color: "#3B4252",
                  fontFamily: "FZLTHJW",
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
              yAxis: [
                {
                  type: "value",
                  offset: 28,
                  axisLabel: {
                    fontSize: 24,
                    color: "#3B4252",
                    fontFamily: "FZLTHJW",
                    formatter: function (item) {
                      return "¥" + vm.convertToWan(item);
                    },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      width: 2,
                      type: "dashed",
                      color: "#CACACA",
                    },
                  },
                },
                {
                  type: "value",
                  offset: 28,
                  splitNumber: 6,
                  axisLabel: {
                    fontSize: 24,
                    color: "#3B4252",
                    fontFamily: "FZLTHJW",
                    formatter: function (item) {
                      return "$" + vm.convertToWan(item);
                    },
                  },
                  splitLine: {
                    show: false,
                  },
                  max: function (value) {
                    let number = String(parseInt((value.max * 2) / 6));
                    let text = "";
                    for (let i = 0; i < number.length - 1; i++) {
                      text += 0;
                    }
                    return parseInt(String(number).slice(0, 1) + text) * 6;
                  },
                },
              ],
              series: [
                {
                  type: "line",
                  data: [],
                  yAxisIndex: 0,
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[0],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[0],
                  },
                },
                {
                  type: "line",
                  data: [],
                  yAxisIndex: 1,
                  symbolSize: 18,
                  symbol: "circle",
                  itemStyle: {
                    color: echartsColor[1],
                  },
                  lineStyle: {
                    width: 3,
                    color: echartsColor[1],
                  },
                },
              ],
            },
          },
        },
      },
    };
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },

  computed: {
    intercepValue() {
      return function (item, index) {
        if (item) {
          let regex = /[0-9.-]+/g;
          let matches = item.match(regex).join("");
          return index == 0 ? matches : item.replace(matches, "");
        } else {
          return index == 0 ? "- -" : "";
        }
      };
    },
  },

  watch: {
    $route: {
      handler(to) {
        this.filesHeader.Authorization =
          "Bearer " + this.$sessionStorage.getItem(config.USER_TOKEN_KEY);
        this.channelId = to.query.channelId
          ? Number(to.query.channelId)
          : Number(Object.keys(this.channalInforList)[0]);
        if (this.channalInforList[this.channelId].id) {
          this.getRolePermission();
          if (
            this.authorityIds &&
            this.authorityIds.indexOf(this.channelId) > -1
          ) {
            this.getDataList();
          } else if (this.authorityIds != null) {
            this.dataList = [];
            this.$message({
              type: "error",
              showClose: true,
              message: "权限不足，请选择相对应渠道",
            });
          }
        } else {
          this.$router.push({
            path: "/auth-management/DaytimeKpi",
            query: {
              channelId: this.channelId,
            },
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 获取角色权限
    getRolePermission() {
      let _this = this;
      $http.axios.get("/api/system/user/getInfo").then(function (response) {
        if (response.data.code == 200) {
          let deptId = response.data.user.deptId;
          _this.authorityIds = [];
          if (deptId == 300 || deptId == 103) {
            _this.authorityIds.push(3);
          } else if (deptId == 307 || deptId == 103) {
            _this.authorityIds.push(4);
          } else if (deptId == 316 || deptId == 103) {
            _this.authorityIds.push(5);
          } else if (deptId == 325 || deptId == 103) {
            _this.authorityIds.push(6);
          } else if (deptId == 324 || deptId == 103) {
            _this.authorityIds.push(7);
          }
          if (_this.$sessionStorage.getItem(config.ROLES) == "optiperson-17") {
            _this.authorityIds.push(8);
          }
          if (_this.authorityIds.indexOf(_this.channelId) > -1) {
            if (_this.channalInforList[_this.channelId].id) {
              _this.getDataList();
            } else {
              _this.$router.push({
                path: "/auth-management/DaytimeKpi",
                query: {
                  channelId: _this.channelId,
                },
              });
            }
          } else if (_this.authorityIds == null) {
            _this.$message({
              showClose: true,
              message: "权限不足，请选择相对应渠道",
              type: "error",
            });
          }
        } else {
          _this.$message({
            type: "error",
            showClose: true,
            message: response.data.msg,
          });
        }
      });
    },
    // 获取数据列表
    getDataList() {
      let _this = this;
      $http.axios
        .get(
          "/api/dataReport/customReport/kpiReportFill/list?channelId=" +
            _this.channalInforList[_this.channelId].id
        )
        .then(function (response) {
          if (response.data.code == 200) {
            if (_this.authorityIds.indexOf(_this.channelId) > -1) {
              _this.dataList = response.data.data || [];
            } else {
              _this.dataList = [];
            }
          } else {
            _this.dataList = [];
            _this.$message({
              type: "error",
              showClose: true,
              message: response.msg,
            });
          }
        })
        .catch(() => {
          _this.dataList = [];
        });
    },
    // 生成
    generateFunction() {
      let _this = this;
      _this.generateLoading = true;
      $http.axios
        .get("/api/dataReport/mediumReport/updateOfVivoFluctuation")
        .then(function (response) {
          _this.generateLoading = false;
          if (response.data.code == 200) {
            _this.$message({
              type: "success",
              showClose: true,
              message: "重点客户波动描述生成成功",
            });
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: "重点客户波动描述生成失败",
            });
          }
        })
        .catch(() => {
          _this.generateLoading = false;
          _this.$message({
            type: "error",
            showClose: true,
            message: "重点客户波动描述生成失败",
          });
        });
    },
    // 更改日日期
    changeDayData() {
      this.batchFilesValidList = [];
    },
    // 上传文件
    uploadFilesFunction(item, name) {
      let _this = this;
      let interfacePath = "";
      let formData = new FormData();
      $http.axios.defaults.timeout = 500000;
      if (name == "dayData") {
        if (_this.channelId == 6 && _.isEmpty(_this.batchFilesValidList)) {
          return;
        }
        if (_this.dataReportStatus == "ov") {
          formData.append(`file`, item.file);
          interfacePath = "/api/dataReport/mediumReport/uploadDiffOfOv";
        } else {
          formData.append("delFlag", true);
          _this.batchFilesValidList.forEach((item_file) => {
            formData.append(`files`, item_file);
          });
          interfacePath = "/api/dataSync/media/uploadMediumData";
        }
        formData.append("date", _this.uploadDayData);
        formData.append(
          "channelId",
          _this.dataReportStatus == "ads" ? 9 : _this.channelId
        );
      } else {
        _this.$nextTick(() => {
          _this.$refs[name + "Upload"].clearFiles();
        });
        formData.append("file", item.file);
        if (name == "customerInfor") {
          formData.append("channelId", _this.channelId);
          interfacePath = "/api/dataReport/mediumReport/uploadMediumData";
        } else if (name == "monthData") {
          formData.append("date", _this.uploadMonthDate);
          interfacePath = "/api/dataSync/media/mi/uploadMediumDataMonth";
        } else if (name == "newCustomers") {
          if (_this.channelId == 3) {
            interfacePath = "/api/dataReport/mediumReport/uploadNewDataOfOppo";
          } else {
            interfacePath = "/api/dataReport/mediumReport/uploadNewDataOfVivo";
          }
        } else if (name == "daily") {
          interfacePath = "/api/dataReport/mediumReport/uploadDataOfVivo";
        }
      }
      $http.axios.post(interfacePath, formData).then(function (response) {
        if (response.data.code == 200) {
          if (name == "monthData") {
            _this.uploadMonthDataReportVisible = false;
          } else if (name == "dayData" && _this.dataReportStatus == "ov") {
            _this.uploadDayDataReportVisible = false;
          }
          _this.$message({
            type: "success",
            showClose: true,
            message: "上传成功",
          });
        } else {
          _this.$message({
            type: "error",
            showClose: true,
            message: response.data.msg,
          });
        }
      });
    },
    // 数据报表
    dataReportFunction(name, status) {
      let date = new Date();
      if (name == "month") {
        this.uploadMonthDataReportVisible = true;
        this.uploadMonthDate = date.getFullYear() + "-" + date.getMonth() + 1;
      } else if (name == "day") {
        this.datafilesList = [];
        this.batchFilesValidList = [];
        this.dataReportStatus = status || "";
        this.uploadDayDataReportVisible = true;
        if (this.dataReportStatus == "ov") {
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);
        }
        this.uploadDayData =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();
        this.$nextTick(() => {
          this.$refs.dayDataUpload.clearFiles();
        });
      }
    },
    // 更改上传文件
    handleFileChange(file, fileList) {
      // 华为不能重复上传
      if (this.channelId == 6) {
        let existsAllFileFlag = false;
        for (let i = 0; i < this.datafilesList.length; i++) {
          if (file.name === this.datafilesList[i].name) {
            // 如果存在了，赋值true
            existsAllFileFlag = true;
          }
        }
        if (!existsAllFileFlag) {
          this.datafilesList.push(file);
        } else {
          fileList.pop();
          this.$message({
            type: "error",
            showClose: true,
            message: "有重复文件，请刷新页面后重新上传",
          });
          return;
        }
      }
    },
    // 上传文件之前
    handleBeforeUpload(file) {
      let _this = this;
      return new Promise((resolve, reject) => {
        _this.batchFilesValidList.push(file);
        // 校验文件列表
        _this.handleDebounceVarifyFile();
        setTimeout(function () {
          if (!_.isEmpty(_this.batchFilesValidList)) {
            resolve(true);
          } else {
            reject(true);
          }
        }, 100);
      });
    },
    //防抖触发文件上传前，保证批量校验
    handleDebounceVarifyFile: _.debounce(function () {
      let excel_file = {};
      if (_.isEmpty(this.batchFilesValidList)) {
        return;
      }
      excel_file = this.batchFilesValidList.find((item) => {
        return /\.(xls)|\.(xlsx)$/.test(item.name);
      });
      // 如果文件校验不合理，清空合法文件列表，并提示
      if (_.isEmpty(excel_file)) {
        this.batchFilesValidList = [];
        this.$message.warning("至少上传一个xls或者xlsx格式的文件！");
      }
    }, 20),
    // 预览
    previewFunction(name) {
      let _this = this;
      _this.previewLoading = true;
      if (name == "current") {
        _this.updateFunction("preview");
      } else if (name == "final") {
        $http.axios
          .get(
            "/api/dataReport/mediumReport/download?channelId=" +
              _this.channelId,
            { responseType: "blob", dataType: "json", async: false }
          )
          .then(function (response) {
            _this.previewLoading = false;
            if (response.data) {
              _this.previewVisible = true;
              _this.postermage = window.URL.createObjectURL(response.data);
              const m = (e) => {
                e.preventDefault();
              };
              document.body.style.overflow = "hidden";
              document.addEventListener("touchmove", m, false);
            } else {
              _this.$message({
                type: "error",
                showClose: true,
                message: "获取失败，请联系相应人员",
              });
            }
          })
          .catch(() => {
            _this.previewLoading = false;
            _this.$message({
              type: "error",
              showClose: true,
              message: "获取失败，请联系相应人员",
            });
          });
      }
    },
    // 关闭预览
    closePreview() {
      this.previewVisible = false;
      const m = (e) => {
        e.preventDefault();
      };
      document.body.style.overflow = "auto";
      document.removeEventListener("touchmove", m, true);
    },
    // 更新
    updateFunction(name) {
      let _this = this;
      if (name == "preview") {
        _this.previewLoading = true;
      } else {
        _this.updateLoading = true;
      }
      $http.axios
        .get("/api/dataReport/mediumReport/query?channelId=" + _this.channelId)
        .then(function (response) {
          if (response.data.code == 200 || _this.channelId == 3) {
            let data = response.data.data;
            let channalInfor = _this.channalInforList[_this.channelId];
            // 汇总
            let quarter = "";
            let summaryInfor = data.baseMap;
            if (summaryInfor) {
              quarter = summaryInfor.quarter;
              if (summaryInfor.reportDate) {
                channalInfor.date = summaryInfor.reportDate;
                _this.posterMonth = parseInt(
                  summaryInfor.reportDate.substring(
                    summaryInfor.reportDate.indexOf("年") + 1,
                    summaryInfor.reportDate.indexOf("月")
                  )
                );
              }
              _this.obtainSummary(
                "",
                channalInfor,
                data,
                summaryInfor,
                quarter
              );
            }
            let summary_two = data.typeMap;
            if (
              summary_two &&
              summary_two.length > 0 &&
              channalInfor.summary_two
            ) {
              _this.obtainSummary(
                "_two",
                channalInfor,
                data,
                summary_two[0],
                quarter
              );
            }
            // 数据
            let dataList = JSON.parse(JSON.stringify(_this.channelId== 6 ? data.hwTypeMap : _this.channelId== 7 ? data.yybTypeMap: data.typeMap));
            if (dataList && channalInfor.dataInforList) {
              if(_this.channelId== 6 || _this.channelId== 7) {
                channalInfor.dataInfor.list = []
                for (let i =0 ; i< dataList.length; i++){
                  _this.dynamicFieldTranslation("", dataList[i], summaryInfor);
                }
              } else {
              _this.dynamicFieldTranslation("", dataList, summaryInfor);
            }
            }
            let dataList_two = JSON.parse(JSON.stringify(data.diffOfOvMap));
            if (dataList_two && channalInfor.dataInfor_two) {
              if (channalInfor.dataInfor_two.date != undefined) {
                channalInfor.dataInfor_two.date = summaryInfor.dateOfOv;
              }
              _this.dynamicFieldTranslation("_two", dataList_two, summaryInfor);
            }
            let dataList_three = JSON.parse(JSON.stringify(data.bigMap));
            if (dataList_three && channalInfor.dataInfor_three) {
              _this.dynamicFieldTranslation(
                "_three",
                dataList_three,
                summaryInfor
              );
            }
            let dataLists = JSON.parse(JSON.stringify(data.newMap));
            if (dataLists) {
              if (channalInfor.dataInfor_four) {
                _this.dynamicFieldTranslation(
                  "_four",
                  dataLists.slice(0, 2),
                  summaryInfor
                );
              }
              if (channalInfor.dataInfor_five) {
                _this.dynamicFieldTranslation(
                  "_five",
                  dataLists.slice(2, 4),
                  summaryInfor
                );
              }
            }
            // 报表
            let echartsInfor = data.fluctuationMap;
            if (channalInfor.echarts && echartsInfor) {
              let echarts = channalInfor.echarts.options;
              echarts.xAxis.data = echartsInfor.date;
              echarts.series.forEach((item, index) => {
                if (echartsInfor.cost[index] && echartsInfor.cost[index].cost) {
                  item.data = echartsInfor.cost[index].cost;
                  if (echartsInfor.cost.length > 1) {
                    item.name = echartsInfor.cost[index].name;
                  }
                }
              });
            }
            // 其他
            let otherchannalInfor = channalInfor.other;
            if (otherchannalInfor) {
              let otherSummaryInfor = JSON.parse(
                JSON.stringify(data.adsBaseMap)
              );
              let otherDataList = JSON.parse(JSON.stringify(data.hwAdsTypeMap));
            if (otherSummaryInfor) {
              _this.obtainSummary(
                "",
                channalInfor.other,
                otherDataList,
                otherSummaryInfor,
                ''
              );
            }
            // 数据
            if (otherDataList && channalInfor.other.dataInforList) {
              if(_this.channelId== 6) {
                channalInfor.other.dataInfor.list = []
                for (let i =0 ; i< otherDataList.length; i++){
                  _this.dynamicFieldTranslation("", otherDataList[i], otherSummaryInfor, 'other');
                }
              } else {
              _this.dynamicFieldTranslation("", otherDataList, otherSummaryInfor, 'other');
            }
            }
            // 报表
              let otherEchartsInfor = data.adsFluctuationMap;
              if (otherEchartsInfor) {
                let echarts = otherchannalInfor.echarts.options;
                echarts.xAxis.data = otherEchartsInfor.date;
                echarts.series.forEach((item, index) => {
                  if (
                    otherEchartsInfor.cost[index] &&
                    otherEchartsInfor.cost[index].cost
                  ) {
                    item.data = otherEchartsInfor.cost[index].cost;
                    if (otherEchartsInfor.cost.length > 1) {
                      item.name = otherEchartsInfor.cost[index].name;
                    }
                  }
                });
              }
            }
            _this.initCharts();
            setTimeout(() => {
              _this.generateImages(name);
            }, 2000);
          } else {
            _this.updateLoading = false;
            _this.previewLoading = false;
            _this.$message({
              type: "error",
              showClose: true,
              message: "获取失败，请联系相应人员",
            });
          }
        })
        .catch(() => {
          _this.dataList = [];
          _this.updateLoading = false;
        });
    },
    // 获取汇总
    obtainSummary(name, channalInfor, data, summaryInfor, quarter) {
      if (channalInfor["summary" + name]) {
        channalInfor["summary" + name].list.forEach((item) => {
          if (item.name.indexOf("quarter") > -1) {
            item.name = item.name.replace("quarter", quarter);
          }
          if (item.name.indexOf("month") > -1) {
            item.name = item.name.replace("month", this.posterMonth);
          }
          if (item.type == "chain") {
            if (summaryInfor.cost && summaryInfor.yesterCost) {
              item.value =
                (
                  Math.round(
                    ((Number(summaryInfor.cost) -
                      Number(summaryInfor.yesterCost)) /
                      Number(summaryInfor.yesterCost)) *
                      10000
                  ) / 100
                ).toFixed(1) + "%";
            }
          }
          if (item.type == "completionRate") {
            if (summaryInfor.quarterCost && summaryInfor.kpi) {
              item.value =
                (
                  Math.round(
                    (Number(summaryInfor.quarterCost) /
                      Number(summaryInfor.kpi)) *
                      10000
                  ) / 100
                ).toFixed(1) + "%";
            }
          }
          if (item.type == "residueGap") {
            if (summaryInfor.gap && summaryInfor.avgQuarterCost) {
              item.value = this.convertToWan(
                summaryInfor.gap - summaryInfor.avgQuarterCost,
                false,
                this.channelId == 5 && item.name.indexOf("KPI") > -1 ? 0 : 1
              );
            }
          }
          if (item.type == "money" && item.field) {
            item.value = this.convertToWan(
              summaryInfor[item.field],
              false,
              this.channelId == 5 && item.name.indexOf("KPI") > -1 ? 0 : 1
            );
          }
          if (
            (item.type == "number" || item.type == "percentage") &&
            item.field
          ) {
            item.value = summaryInfor[item.field];
          }
        });
      }
      if (channalInfor.dataText) {
        if (channalInfor.dataText.list) {
          channalInfor.dataText.list = data.fluctuationMap.cost;
        } else {
          channalInfor.dataText.value = summaryInfor.desc;
        }
      }
    },
    // 动态字段转译
    dynamicFieldTranslation(name, dataList, summaryInfor, path) {
      let dataLists = JSON.parse(JSON.stringify(this.channelId == 6 || this.channelId == 7? dataList.typeDetail : dataList));
      let channalInfor = path
        ? this.channalInforList[this.channelId][path]
        : this.channalInforList[this.channelId];
      channalInfor["dataInforList" + name].forEach((item) => {
        if (item.name.indexOf("quarter") > -1) {
          item.name = item.name.replace("quarter", summaryInfor.quarter);
        }
        if (item.names && item.names.indexOf("quarter") > -1) {
          item.names = item.names.replace("quarter", summaryInfor.quarter);
        }
        if (this.channelId == 7 && item.names && item.names.indexOf("DD") == -1) {
          item.names = item.names.replace("DD", '');
        }
        if (item.name.indexOf("month") > -1) {
          item.name = item.name.replace("month", this.posterMonth);
          if (item.names) {
            item.names = item.names.replace("month", this.posterMonth);
          }
        }
        (this.channelId == 6 || this.channelId == 7? dataList.typeDetail : dataList).forEach((items, indexs) => {
          let kpi = dataLists[indexs].kpi;
          let quarterCost = dataLists[indexs].quarterCost;
          if (item.type == "completionRate" && quarterCost && kpi) {
            items[item.field] =
              (kpi > 0
                ? (Math.round((quarterCost / kpi) * 10000) / 100).toFixed(1)
                : "0.0") + "%";
          }
          if (
            item.type == "money" &&
            (items[item.field] || (item.fields && items[item.fields]))
          ) {
            if (items.noMoneyFlag) {
              items[item.field] = items[item.field]
                ? items[item.field]
                : item.fields
                ? items[item.fields]
                : items[item.field];
            } else {
              items[item.field] = this.convertToWan(
                items[item.field]
                  ? items[item.field]
                  : item.fields
                  ? items[item.fields]
                  : items[item.field],
                false,
                this.channelId == 5 && item.name.indexOf("KPI") > -1 ? 0 : 1
              );
            }
          }
        });
      });
      if(this.channelId == 6 || this.channelId == 7) {
        channalInfor["dataInfor" + name].list.push(dataList)
      } else {
      channalInfor["dataInfor" + name].list = dataList;
    }
    },
    // 生成图片
    generateImages(name) {
      let _this = this;
      let dom = _this.$refs.posterImage;
      html2canvas(dom, {
        useCORS: true,
        allowTaint: true,
        width: dom.offsetWidth,
        height: dom.offsetHeight,
        scale: window.devicePixelRatio,
      }).then((canvas) => {
        if (name == "preview") {
          _this.previewVisible = true;
          _this.previewLoading = false;
          _this.postermage = canvas.toDataURL("image/png");
          const m = (e) => {
            e.preventDefault();
          };
          document.body.style.overflow = "hidden";
          document.addEventListener("touchmove", m, false);
        } else {
          let formData = new FormData();
          formData.append("channelId", _this.channelId);
          formData.append("file", _this.getBlob(canvas));
          $http.axios
            .post("/api/dataReport/mediumReport/upload", formData)
            .then(function (response) {
              if (response.data.code == 200) {
                _this.updateLoading = false;
                _this.$message({
                  type: "success",
                  showClose: true,
                  message: "更新成功",
                });
              } else {
                _this.$message({
                  type: "error",
                  showClose: true,
                  message: response.data.msg,
                });
              }
            })
            .catch(() => {
              _this.$message({
                type: "error",
                showClose: true,
                message: "更新失败，请联系相应人员",
              });
            });
        }
      });
    },
    // 获取blob对象
    getBlob(canvas) {
      var data = canvas.toDataURL("image/jpeg");
      var arr = data.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], "file", { type: mime });
    },
    // 初始化图表
    initCharts() {
      let _this = this;
      this.$nextTick(() => {
        let dom = _this.$refs.echarts;
        if (dom) {
          let chart = echarts.init(dom[0] || dom);
          chart.setOption(
            _this.channalInforList[_this.channelId].echarts.options
          );
          if (_this.$refs.otherEcharts) {
            let doms = _this.$refs.otherEcharts;
            let charts = echarts.init(doms[0] || doms);
            charts.setOption(
              _this.channalInforList[_this.channelId].other.echarts.options
            );
          }
        }
      });
    },
    // 转换成万
    convertToWan(amount, type, number) {
      if (amount || amount == 0) {
        return String(amount).length > 3 || type
          ? (amount / 10000).toFixed(number || number == 0 ? number : 1) + "万"
          : amount;
      }
      return "";
    },
    // 逗号分割
    commaDivision(amount, type) {
      amount = type == "ceil" ? parseInt(amount) : amount;
      if (amount && amount > 999) {
        let parts = amount.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        amount = parts.join(".");
      }
      return amount;
    },
    // 更改渠道
    changeChannel(id) {
      if (this.channelId != id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            channelId: id,
          },
        });
      }
    },
    // 修改数据
    modifyDataFunction(item) {
      this.modifyDataForm = {
        id: item.id,
        describe: item.answer,
      };
      this.modifyDataVisible = true;
    },
    // 修改提交
    modifyDataSubmit() {
      let _this = this;
      $http.axios
        .get(
          "/api/dataReport/customReport/kpiReportFill/update?id=" +
            _this.modifyDataForm.id +
            "&updater=" +
            _this.$sessionStorage.getItem(config.NICKNAME) +
            "&answer=" +
            encodeURIComponent(_this.modifyDataForm.describe)
        )
        .then(function (response) {
          if (response.data.code == 200) {
            _this.getDataList();
            _this.modifyDataVisible = false;
          } else {
            _this.$message({
              type: "error",
              showClose: true,
              message: response.data.msg,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .cell {
  white-space: pre-wrap !important;
}
.el-card {
  overflow: visible;
  .materiaContent {
    margin-top: 20px;
  }
  ::v-deep.alertContainer {
    width: 406px;
    .el-alert__content {
      display: flex;
      align-items: center;

      p {
        line-height: 1;
        margin-top: 0px;
      }
    }
  }
}

@font-face {
  font-family: "Bilthers";
  src: url("../../../../assets/font/Bilthers.ttf");
}

@font-face {
  font-family: "FZLTXHJW";
  src: url("../../../../assets/font/FZLTXHJW.ttf");
}

@font-face {
  font-family: "FZLTHJW";
  src: url("../../../../assets/font/FZLTHJW.ttf");
}

@font-face {
  font-family: "FZLTTHJW";
  src: url("../../../../assets/font/FZLTTHJW.ttf");
}
#posterContainer {
  position: fixed;
  z-index: -1;
  width: 1440px;
  line-height: 1;
  padding: 0 54px;
  color: #161616;
  box-sizing: border-box;
  background-image: url("../../../../assets/img/posterBanner.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #c9151d;

  * {
    line-height: 1;
    box-sizing: border-box;
    font-weight: 400 !important;
    font-family: "FZLTXHJW" !important;
  }

  .bannerContainer {
    height: 792px;
    text-align: right;
    padding-top: 310px;
    color: #e7e5e5;
    text-shadow: 3px 0px 3px #b6131a;

    .title1,
    .title2,
    .title3 {
      font-family: "FZLTTHJW" !important;
    }

    .title1 {
      font-size: 60px;
      margin-bottom: 32px;
    }

    .title2,
    .title3 {
      font-size: 160px;
      margin-bottom: 20px;
    }
  }

  .classificationTitle {
    padding: 140px 0;
    color: #fff;
    font-size: 130px;
    text-align: center;
    font-family: "FZLTTHJW" !important;
    text-shadow: 3px 0px 3px #b6131a;
  }

  .titleContainer {
    padding-right: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleNews {
      min-width: 833px;
      height: 129px;
      margin-left: 38px;
      position: relative;
      color: #ffffff;
      transform: skewX(34deg);
      padding: 10px 58px 0 20px;
      border-radius: 60px 30px 0 0;
      // box-shadow: 0 -8px 33px -8px #b6131a;
      background: #ffffff linear-gradient(-45deg, #ff5762 0%, #db2525 100%);
      display: flex;
      align-items: center;

      *:not(.number .drop) {
        transform: skewX(-34deg);
      }

      span {
        text-shadow: 3px 0px 3px #b6131a;
      }
      .number {
        font-size: 30px;
        padding-top: 94px;
        margin-right: 46px;
        font-family: "Bilthers" !important;

        .drop {
          font-size: 10px;
          margin-left: 6px;
          vertical-align: top;
          font-family: "Bilthers" !important;
        }
      }
      .name {
        font-size: 72px;
        font-family: "FZLTTHJW" !important;
      }

      .date {
        font-size: 46px;
        margin-left: 80px;
        font-family: "FZLTTHJW" !important;
      }

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -38px;
        width: 80px;
        height: 100%;
        background: #db2525;
        transform: skewX(-34deg);
        border-radius: 30px 0 0 0;
        // box-shadow: -18px -8px 33px -14px #b6131a;
      }
    }
    img {
      height: 56px;
    }
  }
  .posterNewsContainer {
    padding: 53px 48px;
    margin-bottom: 100px;
    background: #ffffff !important;
    border-radius: 0px 30px 30px 30px;
    // box-shadow: 1px 0 33px 0px 20px #b6131a;
  }
  .summaryContainer {
    @extend .posterNewsContainer;
    padding-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .summaryNews {
      width: 394px;
      height: 279px;
      font-size: 44px;
      text-align: center;
      margin-bottom: 25px;
      border-radius: 30px;
      background: #ffeff0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .number {
        width: 100%;
        margin-bottom: 26px;
        .value {
          font-family: "FZLTTHJW" !important;
        }
      }
    }
  }

  .detailedContainer {
    @extend .posterNewsContainer;

    .dataTitle {
      position: relative;
      font-size: 52px;
      margin-bottom: 30px;
      padding-left: 30px;
      font-family: "FZLTTHJW" !important;

      &::before {
        content: "";
        position: absolute;
        top: 6px;
        left: 2px;
        width: 10px;
        height: 48px;
        background: #db2525;
      }
    }
    .dataContainer {
      border-radius: 30px;
      background: #ffeff0;

      .auxiliaryTitle {
        font-size: 52px;
        line-height: 98px;
        padding: 8px 26px 0;
        font-family: "FZLTTHJW" !important;
        border-bottom: 2px solid #c9151d;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .small {
          width: calc(33.33% - 14px);
          font-size: 36px;

          span {
            margin-left: 16px;
            font-family: "FZLTTHJW" !important;
          }
        }
      }

      .dataText {
        font-size: 42px;
        min-height: 360px;
        line-height: 65px;
      }
      .dataTextNews {
        margin: 0 50px;
        padding: 40px 0 30px;
        .name {
          font-size: 42px;
          margin-bottom: 20px;
          font-family: "FZLTTHJW" !important;
        }

        .value {
          font-size: 42px;
          * {
            line-height: 65px;
          }
        }

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }

        &:not(:last-child) {
          border-bottom: 2px dashed #cacaca;
        }
      }

      .dataNews {
        font-size: 36px;
        position: relative;
        padding: 45px 0 10px;
        display: flex;
        flex-wrap: wrap;

        .dataValue {
          width: 33.33%;
          padding-left: 26px;
          margin-bottom: 34px;
          display: flex;
          align-items: flex-start;

          * {
            line-height: 1.2;
          }

          .name {
            flex-shrink: 0;
          }

          .number {
            font-family: "FZLTTHJW" !important;
          }

          &:nth-child(3n - 2) {
            padding-left: 30px;
          }
        }

        &::after,
        &::before {
          content: "";
          position: absolute;
          top: 0;
          width: 1px;
          height: 100%;
          background: #c9151d;
        }
        &::after {
          left: 33.33%;
        }
        &::before {
          right: 33.33%;
        }
      }

      .tips {
        width: 100%;
        font-size: 36px;
        padding: 0 30px;
        line-height: 120px;
        border-top: 1px solid #c9151d;
        font-family: "FZLTTHJW" !important;
      }

      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }

    .dataContainers {
      .dataNews {
        .dataValue {
          width: 50%;
        }
        &::after,
        &::before {
          display: none;
        }
      }
    }
  }

  .echartsContainer {
    @extend .posterNewsContainer;
  }
  .logoBottom {
    height: 134px;
    display: block;
    margin: -52px auto 0;
    padding-bottom: 54px;
    box-sizing: content-box;
  }
}
</style>
