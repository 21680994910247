<template>
  <div class="authority common-page">
    <div class="hl_breadCrumb" style="margin-bottom:20px;margin-top:10px;">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>消息通知</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
     <PocCardTable>
       <template slot="headerTitle">
        <div class="departmentHeader">
          <span class="role_headerTitles">消息通知</span>
          <div class="journal_query" style="margin-top:20px;position:relative;">
            <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">
              <el-form-item label="" lable-width="100%" prop="region">
                <el-select v-model="journalvalue" filterable placeholder="全部消息">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="" prop="region">
                <el-select v-model="journalvalueType" filterable placeholder="消息类型">
                  <el-option
                    v-for="item in type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item>
                <el-button type="primary">全部已读</el-button>
              </el-form-item> -->
            </el-form>
            <el-button type="primary" style="position:absolute;top:0;right:0;">全部已读</el-button>
          </div>
        </div>
        <div class="journaTable messageTable">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="100">
            </el-table-column>
            <el-table-column
              prop="type"
              label="消息类型"
              width="200">
            </el-table-column>
            <el-table-column
              label="时间日期"
              width="200">
              <template #default="scope">{{ scope.row.date }}</template>
            </el-table-column>
            <el-table-column
              prop=""
              label="地址"
              show-overflow-tooltip>
              <el-badge is-dot class="item">
                <div class="message_status">
                  <h3>【公告通知】巨量鲁班账户新开功能关闭</h3>
                  <p>尊敬的广告主，您好！敬请知悉，因巨量鲁班产品调整，自2021年5月中旬起，电商广告（原巨量鲁班）账户的新尊敬的广告主，您好！敬请知悉，因巨量鲁班产的广告主，您好！敬请知悉，因巨量鲁班产的广告主，您好！敬请知悉，因巨量鲁班产的广告主，您好！敬请知悉，因巨量鲁班产品调整，自2021年5月中旬起，电商广告（原巨量鲁班）账户的新</p>
                </div>
              </el-badge>
              
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="footer_paging">
            <div class="footer_totalNum">
              <!-- <span class="footer_totalNums">共{{footerTotalNum}}条</span> -->
              <el-popconfirm
                title="确定全部删除吗？"
              >
              <template #reference>
                <el-button>全部删除</el-button>
                </template>
              </el-popconfirm>
              <div class="footerPaging">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage4"
                  :page-sizes="[100, 200, 300, 400]"
                  :page-size="100"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="400">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>

       </template>
      <template slot="headerGroup">
      </template>
      
    </PocCardTable>
    <accessDialog :visible.sync="showDialog" ></accessDialog>
  </div>
</template>

<script>
import accessDialog from '@/views/dialog/accountDialog'
export default {
  name: 'authority',
  components: {
    accessDialog
  },
  data() {
    return {
      showDialog: false,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        conditions:''
      },
      options: [{
          value: '选项1',
          label: '公告通知'
        }, {
          value: '选项2',
          label: '计划预警'
        }, {
          value: '选项3',
          label: '审核通知'
        }, {
          value: '选项4',
          label: '功能上线'
        }, {
          value: '选项5',
          label: '需求通知'
        }, {
          value: '选项6',
          label: '账户通知'
        }],
        journalvalue: '',
        type_options: [{
          value: '选项1',
          label: '已读'
        }, {
          value: '选项2',
          label: '未读'
        }],
        journalvalueType: '',
        tableData: [{
          type: '公告通知',
          date: '2016-05-02'
        }],
        // 分页
        currentPage1: 5,
        currentPage2: 5,
        currentPage3: 5,
        currentPage4: 4
    }
  },
  computed: {
     
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页
    handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      },
  }
}
</script>

<style lang="scss" scoped>
.role_headerTitles {
  font-size:16px;
  font-weight:700;
  color:#6e7c7c;
  padding-bottom:14px;
}
.departmentHeader{
  margin-bottom:20px;
}
.el-form-item{
  display:inline-block;
  vertical-align:top;
  /* width:20%; */
}
.journal_query .el-form-item__content{
  margin-left:0 !important;
  margin-right:10px!important;
}
.footer_paging{
  margin:20px 0 4px;
}
.footer_totalNums{
  font-size:12px;
  color:#666;
  padding-left:5px;
  padding-right:20px;
}
.footerPaging {
  float:right;
}
.message_status{
  padding-left:5px;
}
.message_status h3{
  color:#333;
  margin:0;
}
.message_status p{
  color:#666;
  margin:0;
  width:500px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.messageTable .el-badge__content.is-fixed{
  top:5px;
  left:-17px;
}

</style>
