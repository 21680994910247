<template>
  <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane 
      v-for="(item,i) in tabsListPart" 
      :key="i"
      :label="item.label" 
      :name="item.name">
      <component :is='item.content' :newTeamVue = "newTeamVue" ref="teamChild" :teamInfo="teamInfo"></component>
    </el-tab-pane>
  </el-tabs> -->
  <div>
    <div class="adGroup"></div>
    <strong class="partTitle fW">广告组</strong>
    <hadTeam v-if="type == '30'&& hadTamList" ref = 'teamChildOne' :key="newPlanVue.teamInfo.planId" :newTeamVue = "newTeamVue" :teamInfo="teamInfo">选择已有广告组</hadTeam>
    <nTeam v-if="!hadTamList" :newTeamVue = "newTeamVue" :key="newPlanVue.teamInfo.extensionType" :teamInfo="teamInfo">新建广告组</nTeam>
  </div>
  
</template>
<script>
import hadTeam from "./adTeampart/haveTeam";//选择已有广告组
import nTeam from "./adTeampart/adTeamHeaderPart";//新建广告组
export default {
  name: "teamTabs",
  props:{
    newPlanVue:Object,
  },
  components:{
    hadTeam,//选择已有广告组
    nTeam,//新建广告组
  },
  data() {
    return {
      newTeamVue:this,//新建的vue
      activeName: '',
      tabsListPart:[],//tabs动态加载的组件内容
      teamInfo:this.newPlanVue.teamInfo,
      activeList:{30:'hadTeam',20:'nTeam',10:'nTeam'},
      tabsList:{10:[{label:"新建广告组",name:"nTeam",content:nTeam}],
        20:[{label:"新建广告组",name:"nTeam",content:nTeam}],
        30:[{label:"选择已有广告组",name:"hadTeam",content:hadTeam},
        {label:"新建广告组",name:"nTeam",content:nTeam}],},
      type:null,
      hadTamList:false,
      // tableData:[],
      // options:[],
      // states:[],

    }
  },
  
  mounted(){
    this.ownerId = Number(this.$route.query.ownerId)
    this.ownerName = this.$route.query.ownerName
    this.type = this.$route.query.type
    this.activeName = this.activeList[this.type]
    this.tabsListPart = this.tabsList[this.type]
    console.log('two')
    if(this.newTeamVue.newPlanVue.states.length>0){
      this.hadTamList = true
    }else{
      this.hadTamList = false
    }
  },
  methods: {
    handleClick(tab, event) {

      let _this = this
                  _this.newPlanVue.creatShowIs = false

      // _this.$refs.child[1].getScreen()
      // _this.$refs.child[1].getPriceType()
      // _this.$refs.child[1].teamShowIs = false
      // this.$store.commit('handleClick', tab.name)//
    }
  }
}
</script>