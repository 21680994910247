import RoleManage from '@/views/auth/RoleManage';
import TitleManage from '@/views/auth/TitleManage';
import StaffManage from '@/views/auth/StaffManage';
import StaffManagepw from '@/views/auth/StaffManagepw';
import StaffManageList from '@/views/auth/StaffManageList';
import JurisdictionManage from '@/views/auth/JurisdictionManage';
import JournalManage from '@/views/auth/JournalManage';
import MessageManage from '@/views/auth/MessageManage';
import HomePage from '@/views/auth/homePage/HomePage';
import DataSjkb from '@/views/auth/homePage/dataSjkb';
import DataSjkbVivoQu from '@/views/auth/homePage/dataSjkbVivoQu';
import DataSjkbOppo from '@/views/auth/homePage/dataSjkbOppo';
import ZzdataSjkb from '@/views/auth/homePage/zzdataSjkb';
import UserList from '@/views/auth/homePage/UserList';
import UserDetail from '@/views/auth/homePage/UserDetail';
import UserDetailOppo from '@/views/auth/homePage/UserDetailOppo';
import UserModify from '@/views/auth/homePage/UserModify';
import UserModifyOppo from '@/views/auth/homePage/UserModifyOppo';
import UserDetailVivo from '@/views/auth/homePage/UserDetailVivo';
import AccountList from '@/views/auth/homePage/AccountList';
import LoginJournal from '@/views/auth/homePage/LoginJournal';
import MaterialList from '@/views/auth/operateManage/MaterialList';
import MaterialListPic from '@/views/auth/operateManage/MaterialListPic';
import WaterMark from '@/views/auth/operateManage/WaterMark';
import VideoDetail from '@/views/auth/operateManage/VideoDetail';
import VideoEdit from '@/views/auth/operateManage/VideoEdit';
import DemandOrder from '@/views/auth/operateManage/DemandOrder';
import SetupOrder from '@/views/auth/operateManage/SetupOrder';
import VideoMaterialList from '@/views/auth/operateManage/VideoMaterialList';
import UploadMaterial from '@/views/auth/operateManage/UploadMaterial';
import UploadMaterialPic from '@/views/auth/operateManage/UploadMaterialPic';
import ConsumptionSum from '@/views/auth/operateManage/ConsumptionSum';
import ConsumptionSumOppo from '@/views/auth/operateManage/ConsumptionSumOppo';
import ConsumptionSumElse from '@/views/auth/operateManage/ConsumptionSumElse';
import VideoHtml from '@/views/auth/operateManage/VideoHtml';
import ConsumptionSumCy from '@/views/auth/operateManage/ConsumptionSumCy';
import ConsumptionSumPlan from '@/views/auth/operateManage/ConsumptionSumPlan';
import MaterialProduct from '@/views/auth/operateManage/materialManage/MaterialProduct';
import MaterialProject from '@/views/auth/operateManage/materialManage/MaterialProject';
import MaterialProjectNum from '@/views/auth/operateManage/materialManage/MaterialProjectNum';
import MaterialTags from '@/views/auth/operateManage/materialManage/MaterialTags';
import MaterialProductTt from '@/views/auth/operateManage/materialManage/MaterialProductTt';
import MaterialProductKs from '@/views/auth/operateManage/materialManage/MaterialProductKs';
import MaterialAddTag from '@/views/auth/operateManage/materialManage/MaterialAddTag';
import MaterialProjectTt from '@/views/auth/operateManage/materialManage/MaterialProjectTt';
import MaterialProjectKs from '@/views/auth/operateManage/materialManage/MaterialProjectKs';
import Daily from '@/views/auth/operateManage/dailyOrtimes/Daily';//日报
import Times from '@/views/auth/operateManage/dailyOrtimes/Times';//时报
import DailyAsa from '@/views/auth/operateManage/dailyOrtimes/DailyAsa';//日报
import TimesAsa from '@/views/auth/operateManage/dailyOrtimes/TimesAsa';//时报
import ReportForms from '@/views/auth/operateManage/dailyOrtimes/ReportForms';//报表
import TimeUnit from '@/views/auth/TimeUnit';//时间选择器
import Faq from '@/views/auth/Faq';//FAQ
import FaqVivo from '@/views/auth/FaqVivo';//FAQ
import Notice from '@/views/auth/Notice';//FAQ
import FaqQuestions from '@/views/auth/FaqQuestions';//FAQ问题创建页面
import FaqQuestionsVivo from '@/views/auth/FaqQuestionsVivo';//FAQ问题创建页面
import UploadMaterialPicOperate from '@/views/auth/operateManage/UploadMaterialPicOperate';//运营上传素材页面
import UploadMaterialPicOperateWait from '@/views/auth/operateManage/UploadMaterialPicOperateWait';//运营上传素材页面新的
import DataDisplay from '@/views/auth/homePage/DataDisplay';
import UploadMaterialPicWait from '@/views/auth/operateManage/UploadMaterialPicWait';
import OPPOFaq from '@/views/auth/OPPOFaq';//FAQ
import DaytimeKpi from '@/views/auth/operateManage/dailyOrtimes/DaytimeKpi';//日报
import DailyKpi from '@/views/auth/operateManage/dailyOrtimes/DailyKpi';
import DataSjkbVivo from '@/views/auth/homePage/dataSjkbVivo';
import DataSjkbAsa from '@/views/auth/homePage/dataSjkbAsa';
import DataSjkboppo2 from '@/views/auth/homePage/dataSjkboppo2';
import Ascribe from '@/views/auth/homePage/ascribe';
import DatabordVivo from '@/views/auth/homePage/databordVivo';


export default {
  routes: [
    {
      path: 'roleManage',
      name: 'authManagement.RoleManage',
      component: RoleManage,
    },
    {
      path: 'titleManage',
      name: 'authManagement.TitleManage',
      component: TitleManage,
    },
    {
      path: 'staffManage',
      name: 'authManagement.StaffManage',
      component: StaffManage,
    },
    {
      path: 'staffManageList',
      name: 'authManagement.staffManageList',
      component: StaffManageList,
    },
    {
      path: 'staffManagepw',
      name: 'authManagement.StaffManagepw',
      component: StaffManagepw,
    },
    {
      path: 'jurisdictionManage',
      name: 'authManagement.JurisdictionManage',
      component: JurisdictionManage,
    },
    {
      path: 'journalManage',
      name: 'authManagement.JournalManage',
      component: JournalManage,
    },
    {
      path: 'messageManage',
      name: 'authManagement.MessageManage',
      component: MessageManage,
    },
    {
      path: 'homePage',
      name: 'authManagement.HomePage',
      component: HomePage,
    },
    {
      path: 'dataSjkb',
      name: 'authManagement.DataSjkb',
      component: DataSjkb,
    }, 
    {
      path: 'dataSjkbVivoQu',
      name: 'authManagement.DataSjkbVivoQu',
      component: DataSjkbVivoQu,
    }, 
     
    {
      path: 'dataSjkbOppo',
      name: 'authManagement.DataSjkbOppo',
      component: DataSjkbOppo,
    },
    {
      path: 'zzdataSjkb',
      name: 'authManagement.ZzdataSjkb',
      component: ZzdataSjkb,
    },
    {
      path: 'userList',
      name: 'authManagement.UserList',
      component: UserList,
    },
    {
      path: 'userDetail/:userId',
      name: 'authManagement.UserDetail',
      component: UserDetail
    },
    {
      path: 'userDetailOppo/:userId',
      name: 'authManagement.UserDetailOppo',
      component: UserDetailOppo
    },
    {
      path: 'userModify/:userId',
      name: 'authManagement.UserModify',
      component: UserModify
    },
    {
      path: 'userModifyOppo/:userId',
      name: 'authManagement.UserModifyOppo',
      component: UserModifyOppo
    },
    {
      path: 'userDetailVivo/:userId',
      name: 'authManagement.UserDetailVivo',
      component: UserDetailVivo
    },
    {
      path: 'accountList',
      name: 'authManagement.AccountList',
      component: AccountList
    },
    {
      path: 'loginJournal',
      name: 'authManagement.LoginJournal',
      component: LoginJournal
    },
    {
      path: 'materialList',
      name: 'authManagement.MaterialList',
      component: MaterialList
    },
    {
      path: 'materialListPic',
      name: 'authManagement.MaterialListPic',
      component: MaterialListPic
    },
    {
      path: 'waterMark',
      name: 'authManagement.WaterMark',
      component: WaterMark
    },
    {
      path: 'videoDetail/:id',
      name: 'authManagement.VideoDetail',
      component: VideoDetail
    },
    {
      path: 'videoEdit/:id',
      name: 'authManagement.VideoEdit',
      component: VideoEdit
    },
    {
      path: 'demandOrder',
      name: 'authManagement.DemandOrder',
      component: DemandOrder
    },
    {
      path: 'setupOrder',
      name: 'authManagement.SetupOrder',
      component: SetupOrder
    },
    {
      path: 'videoMaterialList/:id',
      name: 'authManagement.VideoMaterialList',
      component: VideoMaterialList
    },
    {
      path: 'uploadMaterial/:id',
      name: 'authManagement.UploadMaterial',
      component: UploadMaterial
    },
    {
      path: 'uploadMaterialPic/:id',
      name: 'authManagement.UploadMaterialPic',
      component: UploadMaterialPic
    },
    {
      path: 'consumptionSum',
      name: 'authManagement.ConsumptionSum',
      component: ConsumptionSum
    },
    {
      path: 'consumptionSumOppo',
      name: 'authManagement.ConsumptionSumOppo',
      component: ConsumptionSumOppo
    },
    {
      path: 'consumptionSumElse',
      name: 'authManagement.ConsumptionSumElse',
      component: ConsumptionSumElse
    },
    {
      path: 'videoHtml',
      name: 'authManagement.VideoHtml',
      component: VideoHtml
    },
    {
      path: 'materialProduct',
      name: 'authManagement.MaterialProduct',
      component: MaterialProduct
    },
    {
      path: 'materialProject',
      name: 'authManagement.MaterialProject',
      component: MaterialProject
    },
    {
      path: 'materialTags',
      name: 'authManagement.MaterialTags',
      component: MaterialTags
    },
    {
      path: 'consumptionSumCy/:material_id',
      name: 'authManagement.ConsumptionSumCy',
      component: ConsumptionSumCy
    },
    {
      path: 'consumptionSumPlan/:material_id',
      name: 'authManagement.ConsumptionSumPlan',
      component: ConsumptionSumPlan
    },
    {
      path: 'materialProductTt/:id',
      name: 'authManagement.MaterialProductTt',
      component: MaterialProductTt
    },
    {
      path: 'materialProductKs/:id',
      name: 'authManagement.MaterialProductKs',
      component: MaterialProductKs
    },
    {
      path: 'materialAddTag/:taggingid',
      name: 'authManagement.MaterialAddTag',
      component: MaterialAddTag
    },
    {
      path: 'materialProjectTt/:id',
      name: 'authManagement.MaterialProjectTt',
      component: MaterialProjectTt
    },
    {
      path: 'materialProjectKs/:id',
      name: 'authManagement.MaterialProjectKs',
      component: MaterialProjectKs
    },  
    {
      path: 'daily',
      name: 'authManagement.Daily',
      component: Daily
    },
    {
      path: 'times',
      name: 'authManagement.Times',
      component: Times
    },
    {
      path: 'dailyAsa',
      name: 'authManagement.DailyAsa',
      component: DailyAsa
    },
    
    {
      path: 'timesAsa',
      name: 'authManagement.TimesAsa',
      component: TimesAsa
    },
    
    {
      path: 'reportForms',
      name: 'authManagement.ReportForms',
      component: ReportForms
    },
    
    {
      path: 'timeUnit',
      name: 'authManagement.TimeUnit',
      component: TimeUnit
    },
      {path: 'materialProjectNum/:productId',
      name: 'authManagement.MaterialProjectNum',
      component: MaterialProjectNum
    },
    {
      path: 'faq',
      name: 'authManagement.Faq',
      component: Faq
    },
    {
      path: 'faqQuestions',
      name: 'authManagement.FaqQuestions',
      component: FaqQuestions
    },
    {
      path: 'faqVivo',
      name: 'authManagement.FaqVivo',
      component: FaqVivo
    },
    {
      path: 'faqQuestionsVivo',
      name: 'authManagement.FaqQuestionsVivo',
      component: FaqQuestionsVivo
    },
    {
      path: 'notice',
      name: 'authManagement.Notice',
      component: Notice
    },
    {
      path: 'uploadMaterialPicOperate',
      name: 'authManagement.UploadMaterialPicOperate',
      component: UploadMaterialPicOperate
    },
    {
      path: 'dataDisplay',
      name: 'authManagement.DataDisplay',
      component: DataDisplay,
    },
    {
      path: 'uploadMaterialPicWait/:id',
      name: 'authManagement.UploadMaterialPicWait',
      component: UploadMaterialPicWait
    },
    {
      path: 'uploadMaterialPicOperateWait',
      name: 'authManagement.UploadMaterialPicOperateWait',
      component: UploadMaterialPicOperateWait
    },
    {
      path: 'oPPOFaq',
      name: 'authManagement.OPPOFaq',
      component: OPPOFaq
    },
    {
      path: 'daytimeKpi',
      name: 'authManagement.DaytimeKpi',
      component: DaytimeKpi
    },
    {
      path: 'dailyKpi',
      name: 'authManagement.DailyKpi',
      component: DailyKpi
    },
    {
      path: 'dataSjkbVivo',
      name: 'authManagement.DataSjkbVivo',
      component: DataSjkbVivo,
    },
    {
      path: 'dataSjkbAsa',
      name: 'authManagement.DataSjkbAsa',
      component: DataSjkbAsa,
    },
    {
      path: 'dataSjkboppo2',
      name: 'authManagement.DataSjkboppo2',
      component: DataSjkboppo2,
    },
    {
      path: 'ascribe',
      name: 'authManagement.Ascribe',
      component: Ascribe,
    },
    {
      path: 'databordVivo',
      name: 'authManagement.DatabordVivo',
      component: DatabordVivo,
    },
    

    
    
  ],
};
